// @ts-nocheck
import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { imageOfImage, blackEditIconPng } from "./assets";
import { Typography, Box, Grid } from "@material-ui/core";

export type UploadBoxProps = {
  onSelectFile: (Files: FileList) => void;
  onTouched: () => void;
  multiple: boolean;
  classes: any;
  uploadMsg: string;
  uploadSubMsg: string;
  acceptedFormat: string;
  uploadimg?: any;
};
class UploadBox extends React.Component<UploadBoxProps> {
  fileInputRef = React.createRef<HTMLInputElement>();
  fileInputClicked() {
    this.props.onTouched();
    this.fileInputRef?.current?.click();
  }

  filesSelected() {
    if (this.fileInputRef?.current?.files?.length) {
      this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

  handleFiles(files: FileList) {
    if (
      files[0].name.includes(".png") ||
      files[0].name.includes(".PNG") ||
      files[0].name.includes(".jpg") ||
      files[0].name.includes(".JPG") ||
      files[0].name.includes(".jpeg") ||
      files[0].name.includes(".JPEG")
    ) {
      this.props.onSelectFile(files);
    } else {
      this.props.showToast({
        type: "error",
        message: "invalid file type",
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.whiteCard} onClick={this.fileInputClicked.bind(this)}>
          <Grid item xs={12} container justifyContent="space-between" alignItem="flex-start">
            <img src={imageOfImage} className={classes.ioi} />
            <img src={blackEditIconPng} className={classes.editIconStyles} />
          </Grid>
          <Box>
            <Typography variant="body1" className={classes.uploadMsg}>
              Drop your image here or{" "}
              <Typography
                className={classes.uploadMsg}
                style={{
                  color: "#57AAFF",
                  display: "inline-block",
                }}
              >
                Browse
              </Typography>
            </Typography>
            <Typography className={classes.uploadSubMsg}>Supports JPG, PNG...</Typography>
          </Box>
        </Grid>
        <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          type="file"
          accept={this.props.acceptedFormat}
          multiple={this.props.multiple}
          onChange={this.filesSelected.bind(this)}
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    whiteCard: {
      padding: "1.2rem",
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
    },
    DropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      height: "250px",
      border: "dashed 1px #343a40",
      flexDirection: "column",
      borderRadius: "6px",
      padding: "20px 10px",
      cursor: "pointer",
    },
    uploadIcon: {
      width: "25px",
      height: "30px",
      objectFit: "contain",
      background: `url(${imageOfImage}) no-repeat center center`,
      backgroundSize: "100%",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "30px",
    },
    dropMessage: {
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
      fontFamily: "Public Sans",
      marginTop: "16px",
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    fileInput: {
      display: "none",
    },
    boxOutline: {
      display: "flex",
      padding: 20,
      borderRadius: "10px",
      border: "2px solid #d9d9d9",
      cursor: "pointer",
    },
    ioi: {
      width: "120px",
      height: "100px",
      marginBottom: "1rem",
    },
    uploadMsg: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: theme.palette.text.blue2,
    },
    uploadSubMsg: {
      fontSize: "1rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
    },
    editIconStyles: {
      height: "1.5rem",
    },
  });
export default withStyles(styles, { withTheme: true })(UploadBox);