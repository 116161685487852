//@ts-nocheck
import React from "react";
import ManageDeliveryChecklistController, { Props } from "./ManageDeliveryChecklistController.web";
import { Typography, Button, Grid, withStyles, createStyles } from "@material-ui/core";
import { viewIcon, infoIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { CustomTooltip } from "../../../../components/src/ModifiedComponents.web";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddChecklistDialog from "./AddChecklistDialog.web";
import ViewChecklistDialog from "./ViewChecklistDialog.web";
import UnlinkChecklistDialog from "./UnlinkChecklistDialog.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

export class ManageDeliveryChecklist extends ManageDeliveryChecklistController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getPackages();
        this.getCustomPackagesForChecklistPage();
      }
    );
  }

  openAddChecklistDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          packageItem: data.packageItem,
        },
        width: "35%",
        renderedComponent: AddChecklistDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.getPackages();
          this.getCustomPackagesForChecklistPage();
        }
      });
  };

  openUnlinkChecklistDialog = (outerData) => {
    this.props
      .openDialogBox({
        width: "35%",
        renderedComponent: UnlinkChecklistDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.unlinkChecklist(outerData.checklistId);
        }
      });
  };

  openViewChecklistDialog = (data) => {
    this.props.openDialogBox({
      dataToPass: {
        checklistId: data.checklistId,
      },
      width: "70%",
      renderedComponent: ViewChecklistDialog,
      withCustomDialog: false,
    });
  };

  handleEditIconClick = (packageChecklist) => {
    this.props.history.push({
      pathname: "manageOnboardingChecklist",
      state: {
        checklistId: +packageChecklist?.attributes?.bx_block_checklist_checklist_id,
      },
    });
  };

  handleCreateButtonClick = () => {
    this.props.history.push({
      pathname: "createOnboardingChecklist",
    });
  };

  mapPackageChecklist = (packageChecklist) => {
    const { classes, permissions } = this.props;

    return (
      <Grid key={packageChecklist.id} container alignItems="center" className={classes.nameControlsContainer}>
        <Grid item xs={6}>
          <Typography display="inline" className={classes.checklistName}>
            {packageChecklist?.attributes?.checklist_name}
          </Typography>
        </Grid>
        <Grid item xs={6} container alignItems="center">
          <img
            src={viewIcon}
            className={classes.threeIcons}
            alt="viewIcon"
            onClick={this.openViewChecklistDialog.bind(this, {
              checklistId: packageChecklist?.attributes?.bx_block_checklist_checklist_id,
            })}
          />
          {!permissions.includes("Manage Checklist-Delivery Checklists") ? (
            <NotInterestedIcon />
          ) : (
            <EditIcon color="primary" className={classes.threeIcons} onClick={this.handleEditIconClick.bind(this, packageChecklist)} />
          )}
          <DeleteIcon
            color="primary"
            className={classes.threeIcons}
            onClick={this.openUnlinkChecklistDialog.bind(this, {
              checklistId: packageChecklist?.attributes?.id,
            })}
          />
        </Grid>
      </Grid>
    );
  };

  mapPackageList = (packageItem) => {
    const { classes, permissions } = this.props;

    return (
      <Grid key={packageItem.id} item xs={12} className={classes.tableBody}>
        <Grid container>
          <Grid item xs={6} container alignItems="flex-start">
            <Typography color="textSecondary" className={classes.packageName}>
              {packageItem?.attributes?.name}
              <span className={classes.clientListColorBar} />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {packageItem?.attributes?.package_checklists?.data && packageItem?.attributes?.package_checklists?.data.length > 0 ? (
              packageItem?.attributes?.package_checklists?.data.map(this.mapPackageChecklist)
            ) : (
              <Grid container alignItems="center">
                <Typography display="inline" className={classes.checklistName}>
                  No Checklist Linked
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              <Button
                type="text"
                color="primary"
                className={classes.addChecklistButton}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={this.openAddChecklistDialog.bind(this, {
                  packageItem: packageItem,
                })}
                disabled={!permissions.includes("Assign Checklist (to packages)-Delivery Checklists")}
              >
                + Add Checklist
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, permissions } = this.props;
    return (
      <Grid container>
        <Grid item xs={6} className={classes.pageHeadingContainer} container alignItems="center">
          <Typography variant="h2" color="secondary" display="inline">
            Manage Onboarding Checklist Links
          </Typography>
          <CustomTooltip
            title="Manage which checklists appear on a checklist Onboarding Customization tab (in the Client's Profile) when they enroll in a particular package"
            placement="bottom-start"
          >
            <img src={infoIcon} className={classes.infoIcon} />
          </CustomTooltip>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="text"
            className={classes.manageChecklistButton}
            onClick={this.handleCreateButtonClick}
            disabled={!permissions.includes("Create a Checklist-Delivery Checklists")}
          >
            Create Onboarding Checklist
          </Button>
        </Grid>

        <Grid item xs={12} className={classes.gridSpacing}>
          <Grid container className={classes.whiteCard}>
            <Grid item xs={12} className={classes.tableHead}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="textSecondary" className={classes.packageName}>
                    Package Names
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" className={classes.packageName}>
                    Linked Checklist
                  </Typography>
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.clientListCard}>
              <Grid container>
                {this.state.packageList.map(this.mapPackageList)}
                {this.state.customPackagesList.map(this.mapPackageList)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ManageDeliveryChecklistWithRouter = withRouter(ManageDeliveryChecklist);
const ManageDeliveryChecklistAlertBox = withAlertBox(ManageDeliveryChecklistWithRouter);
const ManageDeliveryChecklistLoader = withLoader(ManageDeliveryChecklistAlertBox);
const ManageDeliveryChecklistToast = withToast(ManageDeliveryChecklistLoader);
const ManageDeliveryChecklistWithDialog = withDialog(ManageDeliveryChecklistToast);
const ManageDeliveryChecklistWithConfirmBox = withConfirmBox(ManageDeliveryChecklistWithDialog);
const ManageDeliveryChecklistWithPermissions = withPermissions(ManageDeliveryChecklistWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: "1rem 0",
      },
      whiteCard: {
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "#ffffff",
        boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
      },
      clientListColorBar: {
        position: "absolute",
        left: "-1rem",
        top: 0,
        width: "4px",
        height: "100%",
        backgroundColor: theme.palette.primary.main,
      },
      packageName: {
        fontWeight: "bold",
        fontSize: "1.1rem",
        position: "relative",
      },
      pageHeadingContainer: {
        paddingLeft: "1rem",
      },
      checklistName: {
        fontWeight: "bold",
        fontSize: "1.1rem",
        marginRight: "0.5rem",
      },
      threeIcons: {
        height: "1.5rem",
        width: "1.5rem",
        margin: "0px 0.5rem",
        cursor: "pointer",
      },
      manageChecklistButton: {
        fontSize: "1.2rem",
        fontWeight: 700,
        paddingRight: "3rem",
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.palette.text.secondary,
        textTransform: "none",
      },
      addChecklistButton: {
        padding: 0,
        margin: 0,
        height: "auto",
      },
      tableHead: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        fontFamily: "Nunito Sans",
        border: "none",
        backgroundColor: "#F3F3F3",
        padding: "1.5rem 1rem",
        borderRadius: "1rem",
        marginBottom: "0.5rem",
      },
      tableBody: {
        backgroundColor: "#fff",
        borderRadius: "1rem",
        fontSize: "1rem",
        color: theme.palette.text.secondary,
        border: "none",
        fontFamily: "Nunito Sans",
        padding: "1.5rem 1rem",
        margin: "0.5rem 0",
        boxShadow: "rgba(149, 157, 165, 0.25) 0px 0px 16px",
      },
      infoIcon: {
        display: "inline-block",
        width: "1.2rem",
        height: "1.2rem",
        marginLeft: "0.5rem",
      },
      nameControlsContainer: {
        marginBottom: "0.6rem",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      clientListCard: {
        maxHeight: "65vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "2px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
    }),
  { withTheme: true }
)(ManageDeliveryChecklistWithPermissions);
