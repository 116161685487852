//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import moment from "moment";
export const configJSON = require("../config");
const configFramework = require('../../../../framework/src/config')


export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  clientsList: any;
  initialClientsList: any;
  isAdvancedViewOpen: boolean;
  isMinimalViewOpen: boolean;
  clientListFilterOpen: boolean;
  selectedClient: object;
  headerCheckbox: boolean;
  prioritiesList: object[];
  clientFilterTooltip: boolean;
  bulkActionsTooltip: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientsListContainerController extends BlockComponent<Props, S, SS> {
  updateProfileApiCallId: string = "";
  getClientsListApiCallId: string = "";
  sendRemindersApiCallId: string = "";
  changeStatusApiCallId: string = "";
  addPriorityApiCallId: string = "";
  getPrioritiesApiCallId: string = "";
  deletePriorityApiCallId: string = "";
  updatePriorityApiCallId: string = "";
  dragPriorityApiCallId: string = "";
  addBulkClientsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SearchClientMsg)
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      clientsList: [],
      initialClientsList: [],
      isAdvancedViewOpen: false,
      isMinimalViewOpen: true,
      clientListFilterOpen: false,
      selectedClient: {},
      headerCheckbox: false,
      prioritiesList: [],
      clientFilterTooltip: false,
      bulkActionsTooltip: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetClientsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      let modifiedClients = responseJson.data.map((item, index) => {
        return {
          ...item,
          selected: false,
          attributes: {
            ...item.attributes,
            profile_status: item.attributes.profile_status ? item.attributes.profile_status : "Contact",
            client_type: item.attributes.client_type ? item.attributes.client_type : "Individual",
            enrolledPackage : item.attributes.packages?.filter((pkg)=>{return pkg?.package_name}).map((pkg)=>{return pkg?.package_name}).join(" | ")
          },
        };
      });
      this.setState(
        {
          clientsList: modifiedClients,
          initialClientsList: modifiedClients,
          selectedClient: modifiedClients[0],
          headerCheckbox: false,
        },
        () => {
          if (modifiedClients[0]?.id) {
            this.getPriorities(+modifiedClients[0]?.id);
          }
        }
      );
    } else {
      this.handleApiError(responseJson)
    }
  };

  handleApiError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleSendRemindersResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson) {
      this.props.showToast({
        type: "success",
        message: "Reminder sent successfully",
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleChangeStatusResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      let modifiedClients = responseJson?.data.map((item) => {
        return {
          ...item,
          selected: true,
          attributes: {
            ...item.attributes,
            profile_status: item.attributes.profile_status ? item.attributes.profile_status : "Contact",
            client_type: item.attributes.client_type ? item.attributes.client_type : "Individual",
          },
        };
      });
      let returnedClients = [...modifiedClients];
      let clients = [...this.state.clientsList];
      let newClients = clients.map((obj) => returnedClients.find((o) => o.id === obj.id) || obj);
      let newClientsForInitials = newClients.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      this.setState({
        clientsList: newClients,
        initialClientsList: newClientsForInitials,
      });
      this.props.showToast({
        type: "success",
        message: "Status changed succssfully",
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handlePrioritiesResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      let prio = responseJson?.data.map((item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            rank: index,
          },
        };
      });

      this.setState({
        prioritiesList: prio,
      });
    } else {
      this.handleApiError(responseJson)
    }
  };

  handleAddPriorityResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      let prioritiesListTemp = [...this.state.prioritiesList];

      let newItem = {
        ...responseJson.data,
        attributes: {
          ...responseJson.data.attributes,
          rank: prioritiesListTemp.length,
        },
      };

      prioritiesListTemp.push(newItem);
      this.setState({
        prioritiesList: prioritiesListTemp,
      });
    } else {
      this.handleApiError(responseJson)
    }
  };

  handleUpdatePriorityResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      // this.setState({
      //   prioritiesList: responseJson?.data
      // })
    } else {
      this.handleApiError(responseJson)
    }
  };

  handleAddBulkClientsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.message === "Clients uploaded sucessfully") {
      this.getClientsList();
    } else if(responseJson && responseJson?.message === "Clients Partially uploaded sucessfully") {
      const invalidClients = responseJson.invalid_clients || {};
      const numberOfInvalidClients = Object.keys(invalidClients).length;
      this.props.showToast({
        type: "error",
        message: `${numberOfInvalidClients} clients not added, please provide valid data.`
      });
      this.getClientsList();
    } else {
      this.handleApiError(responseJson)
    }
  };

  handleResponse = (message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getClientsListApiCallId) {
        this.handleGetClientsResponse(responseJson);
      } else if (apiRequestCallId === this.sendRemindersApiCallId) {
        this.handleSendRemindersResponse(responseJson);
      } else if (apiRequestCallId === this.changeStatusApiCallId) {
        this.handleChangeStatusResponse(responseJson);
      } else if (apiRequestCallId === this.getPrioritiesApiCallId) {
        this.handlePrioritiesResponse(responseJson);
      } else if (apiRequestCallId === this.addPriorityApiCallId) {
        this.handleAddPriorityResponse(responseJson);
      } else if (apiRequestCallId === this.updatePriorityApiCallId) {
        this.handleUpdatePriorityResponse(responseJson);
      } else if (apiRequestCallId === this.deletePriorityApiCallId) {
        this.handlePrioritiesResponse(responseJson);
      } else if (apiRequestCallId === this.dragPriorityApiCallId) {
        this.handlePrioritiesResponse(responseJson);
      } else if (apiRequestCallId === this.addBulkClientsApiCallId) {
        this.handleAddBulkClientsResponse(responseJson);
      } else if(apiRequestCallId == this.searchClientApiCallId){
        this.handleGetClientsResponse(responseJson);
      }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleResponse(message)
      
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    } else if (getName(MessageEnum.SearchClientMsg) === message.id) {
      const search = message.getData(getName(MessageEnum.SearchClientMsgData));
        if(search){
          this.searchClient(search)
        } else {
          this.getClientsList()
        }
        
    }
  }

  getClientsList = (clientType) => {
    let url = clientType ? `bx_block_pricingcalculator/client_informations?client_type=${clientType}` : `bx_block_pricingcalculator/client_informations`;

    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getClientsListApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendReminders = (selectedClientsList) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.sendRemindersApiCallId = requestMessage.messageId;

    const clientIds = selectedClientsList.map((item) => +item.id);

    const httpBody = {
      ids: clientIds,
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/send_reminder_mail`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeStatus = (statusValue, selectedClientsList) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.changeStatusApiCallId = requestMessage.messageId;

    const clientIds = selectedClientsList.map((item) => +item.id);

    const httpBody = {
      ids: clientIds,
      status: statusValue,
      onboarding_date: moment().format("YYYY-MM-DD"),
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/update_status`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPriorities = (clientId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getPrioritiesApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/${clientId}/priorities`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addPriority = (clientId, priorityName) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addPriorityApiCallId = requestMessage.messageId;

    const httpBody = {
      priorities: {
        name: priorityName,
      },
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/${clientId}/priorities`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePriority = (statusValue, clientId, priorityId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updatePriorityApiCallId = requestMessage.messageId;

    const httpBody = {
      priorities: {
        status: statusValue,
      },
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/${clientId}/priorities/${priorityId}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deletePriority = (clientId, priorityId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deletePriorityApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/${clientId}/priorities/${priorityId}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dragPriority = (clientId, prioritiesList) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const priorityIds = prioritiesList.map((item) => {
      return +item.id;
    });

    const httpBody = {
      ids: priorityIds,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.dragPriorityApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_clients/client_informations/${clientId}/priorities/update_ranks`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addBulkClients = (csvFile: any) => {
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addBulkClientsApiCallId = requestMessage.messageId;

    let formData = new FormData();

    if (csvFile !== null) {
      formData.append("files", csvFile.file);
    }

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_pricingcalculator/client_informations/bulk_upload_clients`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchClient = (searchValue:string) => {
    let url = `bx_block_pricingcalculator/client_informations?query=${searchValue}`;

    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.searchClientApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }

  downloadSheet = () => {
    window.location.href = `${configFramework.baseURL}/bx_block_pricingcalculator/client_informations/download_csv_file?token=${this.state.token}`
  }
  
}
