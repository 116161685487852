//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import { RouterProps } from "react-router";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isCalculated: boolean;
  token: string;
  accountId: string;
  pricingBumpsList: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: string;
  password: string;
  unique_auth_id?: string;
};
export default class PricingBumpsController extends BlockComponent<Props, S, SS> {
  getPricingBumpsApiCallId: string = "";
  updateBumpsApiCallId: string = "";
  nf: any = new Intl.NumberFormat("en-US");
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      isCalculated: false,
      token: "",
      accountId: "",
      pricingBumpsList: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetPricingBumpsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        pricingBumpsList: responseJson?.data,
      });
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleUpdateBumpsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message === "Bump price updated Successfully") {
      this.props.showToast({
        type: "success",
        message: "Bumps Price Updated Successfully.",
      });
      this.getPricingBumps();
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getPricingBumpsApiCallId) {
        this.handleGetPricingBumpsResponse(responseJson);
      } else if (apiRequestCallId === this.updateBumpsApiCallId) {
        this.handleUpdateBumpsResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        this.props.history.push("/");
      }
    }
  }

  updateBumps = (values: any) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateBumpsApiCallId = requestMessage.messageId;

    let bumpData = [];

    values.individualPricingBumps.forEach((item) => {
      bumpData.push({
        id: item?.id,
        bump_amount: item?.attributes?.bump_amount,
      });
    });

    values.businessPricingBumps.forEach((item) => {
      bumpData.push({
        id: item?.id,
        bump_amount: item?.attributes?.bump_amount,
      });
    });

    const httpBody = {
      data: bumpData,
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_survey/bump_pricings");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPricingBumps = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getPricingBumpsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_survey/bump_pricings`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
