//@ts-nocheck
// Customizable Area Start
import React, { PureComponent } from "react";
import { RouterProps, withRouter } from "react-router-dom";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  AppBar,
  Toolbar,
  Box,
} from "@material-ui/core";
import { logo } from "../assets";
export type Props = RouterProps;

export interface S {
  client_id?: any;
  account_id?: any;
  loading?: boolean;
  survey_id?: string;
  package_management_id?: string;
  access_token?: string;
}

export class OnBoardingReDirectPage extends PureComponent<Props, S> {
  state = {
    client_id: "",
    account_id: "",
    survey_id: "",
    package_management_id: "",
    access_token: "",
    loading: true,
    custom_package_id: "",
    roadmap_id: "",
    payment_term: "",
  };

  async componentDidMount() {
    const params = new URLSearchParams(this.props.history.location.search);
    const client_id = params.get("client_id");
    const survey_id = params.get("survey_id");
    const account_id = params.get("account_id");
    const package_management_id =
      params.get("package_management_id") || undefined;
    const access_token = params.get("access_token") || undefined;
    const allow_to_change_package_id = params.get("allow_to_change_package_id");
    const custom_package_id = params.get("custom_package_id");
    const payment_term = params.get("payment_term");
    const roadmap_id = params.get("roadmap_id");
    const parent_package_type = params.get("parent_package_type")
    if (client_id && (survey_id || roadmap_id) && account_id) {
      this.setState(
        {
          client_id,
          survey_id,
          account_id,
          access_token,
          package_management_id,
          loading: false,
          allow_to_change_package_id: allow_to_change_package_id,
          custom_package_id: custom_package_id,
          roadmap_id: roadmap_id,
          payment_term: payment_term,
          parent_package_type:parent_package_type
        },
        () => {
          this.props.history.push({
            pathname: "/onboardingSurvey",
            search: `?surveyId=${btoa(this.props.history.location.search)}`,
            state: {
              client_id,
              survey_id,
              account_id,
              access_token,
              package_management_id,
              allow_to_change_package_id,
              custom_package_id: custom_package_id,
              roadmap_id: roadmap_id,
              payment_term: payment_term,
              parent_package_type:parent_package_type
            },
          });
        }
      );
    } else {
      this.setState({
        client_id: undefined,
        survey_id: undefined,
        account_id: undefined,
        access_token: undefined,
        package_management_id: undefined,
        loading: false,
        custom_package_id: undefined,
        roadmap_id: undefined,
        payment_term: undefined,
        parent_package_type:parent_package_type
      });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      !this.state.loading && (
        <div style={{ position: "relative" }}>
          <AppBar
            position="sticky"
            color="default"
            elevation={1}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Box className={classes.toolbarLogo}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    maxHeight: "100%",
                    // minHeight: "3.75rem",
                    maxWidth: "100%",
                    borderRadius: "0.75rem",
                  }}
                />
              </Box>
            </Toolbar>
          </AppBar>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item md={6} xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={8}>
                  <Typography color="error" className={classes.successheading}>
                    Error!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color="textSecondary"
                    className={classes.successSubHeading}
                  >
                    This Link is not valid
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    );
  }
}
export default withStyles((theme) =>
  createStyles({
    logoContainer: {
      textAlign: "center",
      marginBottom: "1rem",
    },
    logoImage: {
      width: "30%",
      height: "auto",
    },
    successheading: {
      fontFamily: "Nunito Sans",
      fontSize: "2.8rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      fontWeight: 700,
      borderBottom: "1px #D3D3D3 solid",
    },
    successSubHeading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.3rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontWeight: 500,
      margin: "2rem 0",
    },
    appBar: {
      width: "100%",
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: "none",
      backgroundColor: " #ffffff",
    },
    toolbar: {
      flexWrap: "nowrap",
      justifyContent: "center",
    },
    toolbarLogo: {
      flex: "0 0 12%",
      position: "absolute",
      top: 10,
      left: 10,
      height: "150px",
      width: "150px",
    },
    mainContainer: {
      height: "calc(100vh - 20vh)",
    },
  })
)(withRouter(OnBoardingReDirectPage));
// Customizable Area End