//@ts-nocheck

import React from "react";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  GridProps,
} from "@material-ui/core";

type DataCardProps = GridProps & {
  primaryText: string;
  secondaryText: string;
  children: React.ReactNode;
};

class DataCard extends React.Component<DataCardProps> {
  constructor(props: DataCardProps) {
    super(props);
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <Grid
        item
        xs={3}
        container
        justifyContent="space-between"
        alignItems="center"
        direction="column"
        className={classes.surveyCard}
      >
        <Typography
          color="textSecondary"
          variant="h4"
          align="center"
          className={classes.secondaryText}
        >
          {this.props.children}
        </Typography>
        <Typography
          color="primary"
          align="center"
          className={classes.primaryText}
        >
          {this.props.primaryText}
        </Typography>
      </Grid>
    );
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      surveyCard: {
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "#ffffff",
        boxShadow: theme.shadows[25],
        minHeight: "9.375rem",
        flexGrow: 0,
        maxWidth: "23%",
        flexBasis: "23%",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "1.5rem",
          maxWidth: "48%",
          flexBasis: "48%",
        }
      },
      primaryText: {
        fontSize: "2.5rem",
        fontWeight: 700,
      },
      secondaryText: {
        fontSize: "1rem",
        fontWeight: 700,
      },
    }),
  { withTheme: true }
)(DataCard);
