import React from "react";

import { withStyles, createStyles, Switch, Tooltip, MenuItem, TextField,TableCell } from "@material-ui/core";

export const PhaseTwoSwitch = withStyles((theme) => ({
  root: {
    width: "2.375rem",
    height: "1.375rem",
    padding: 0,
    display: "flex",
    marginLeft: "1.5rem",
  },
  switchBase: {
    padding: "0.3125rem",
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(1rem)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: "0.75rem",
    height: "0.75rem",
    boxShadow: "none",
  },
  track: {
    borderRadius: "0.65rem",
    opacity: 1,
    backgroundColor: "#D7D7D7",
  },
  checked: {},
}))(Switch);

export const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: "2.375rem",
    height: "1.375rem",
    padding: 0,
    display: "flex",
    marginLeft: "1.5rem",
  },
  switchBase: {
    padding: "0.3125rem",
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(1rem)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: "0.75rem",
    height: "0.75rem",
    boxShadow: "none",
  },
  track: {
    borderRadius: "0.65rem",
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  checked: {},
}))(Switch);

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: "2.625rem",
    height: "1.625rem",
    padding: "0px",
    margin: "0.5rem",
  },
  switchBase: {
    padding: "0.0625rem",
    "&$checked": {
      transform: "translateX(1rem)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "0.375rem solid #fff",
    },
  },
  thumb: {
    width: "1.5rem",
    height: "1.5rem",
  },
  track: {
    borderRadius: "calc(26px/2)",
    border: `0.0625rem solid #E9E9E9`,
    backgroundColor: "#E9E9E9",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: "1.25rem",
    color: theme.palette.text.secondary,
    borderRadius: "1rem",
    boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
    fontFamily: "Nunito Sans",
    fontSize: "1.15rem",
    textAlign: "justify",
    maxWidth: 350,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export const SectionTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: "1.25rem",
    color: theme.palette.text.secondary,
    borderRadius: "0.75rem",
    boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
    fontFamily: "Nunito Sans",
    fontSize: "1.125rem",
    textAlign: "justify",
    // maxWidth: 350,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export const TooltipFullWidth = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: "1rem",
    color: theme.palette.text.secondary,
    borderRadius: "1rem",
    boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
    fontFamily: "Nunito Sans",
    fontSize: "1.125rem",
    maxWidth: "100%",
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export const TooltipFirmFinancials = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FEFEFE",
    borderLeft: `0.625rem solid ${theme.palette.secondary.main}`,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
    borderRadius: "1rem",
    boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
    fontFamily: "Nunito Sans",
    fontSize: "0.9rem",
    textAlign: "justify",
    maxWidth: 700,
  },
  arrow: {
    color: theme.palette.secondary.main,
    marginLeft: "-1.25rem!important",
  },
}))(Tooltip);

export const SmallMenuItem = withStyles((theme) => ({
  root: {
    lineHeight: 1,
    fontSize: "1rem",
    backgroundColor: "#fff!important",
    // "& .MuiListItem-root.Mui-selected": {
    //   backgroundColor: "red!important"
    // }
  },
  gutters: {
    padding: "0.75rem 2rem",
  },
}))(MenuItem);

export const ValueMarginTextField = withStyles((theme) => ({
  root: {
    "& > div": {
      outline: 'none',
      border: `2px solid #f4f4f4`,
      backgroundColor: '#f4f4f4',
      borderRadius: 0,
      'label + &': {
        marginTop: 30,
        marginBottom: 20,
      },
      "& svg": {
        right: "12px",
      },
      '& > fieldset': {
        border: 'none',
        '& active': {
          border: 'none'
        },
        '& focus': {
          border: 'none'
        }
      },
      "&.Mui-focused": {
        border: `2px solid #486484`,
        backgroundColor: '#fff',
      },
      "&.Mui-error": {
        border: `2px solid #FF5C5C`,
        backgroundColor: '#f4f4f4',
        "&.Mui-focused": {
          backgroundColor: '#fff',
        }
      },
      "&.MuiSelect-root": {
        border: "none",
        outline: "none",
      },
      "& + p": {
        "&.MuiFormHelperText-root.Mui-error": {
          color: "#FF5C5C",
          fontSize: "0.8rem"
        }
      }
    }
  },
}))(TextField);

export const TableCellWithoutBorder = withStyles((theme) =>
  createStyles({
    head: {
      border: "none",
    },
    body: {
      border: "none",
    },
  })
)(TableCell);