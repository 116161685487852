//@ts-nocheck
import React from "react";
import {
  Grid,
  withStyles,
  createStyles,
  GridProps,
} from "@material-ui/core";
import clsx from "clsx";

type CustomGridProps = GridProps & {
  width?: React.CSSProperties["width"];
  margin?: React.CSSProperties["margin"];
  padding?: React.CSSProperties["padding"];
  backgroundColor?: React.CSSProperties["backgroundColor"];
  children?: React.ReactNode;
};

class CustomGrid extends React.Component<CustomGridProps> {
  constructor(props: CustomGridProps) {
    super(props);
  }

  render() {
    const { classes, theme, width, margin, padding, backgroundColor, className, ...rest } =
      this.props;

    const classList = [
      width ? classes.width : "",
      margin ? classes.margin : "",
      padding ? classes.padding : "",
      backgroundColor ? classes.backgroundColor : "",
    ];
    return (
      <Grid className={clsx(className, classList)} {...rest}>
        {this.props.children}
      </Grid>
    );
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      width: {
        flexGrow: 0,
        maxWidth: (props) => props?.width,
        flexBasis: (props) => props?.width,
      },
      margin: {
        margin: (props) => props?.margin,
      },
      padding: {
        padding: (props) => props?.padding,
      },
      backgroundColor: {
        backgroundColor: (props) => props?.backgroundColor,
      }
    }),
  { withTheme: true }
)(CustomGrid);
