//@ts-nocheck
import React from "react";
import ClientConversionSurveySettingsController, {
  Props,
} from "./ClientConversionSurveySettingsController.web";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  TextField,
  InputAdornment,
  Hidden,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { infoIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import {
  PhaseTwoSwitch,
  CustomTooltip,
} from "../../../../components/src/ModifiedComponents.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import SearchIcon from "@material-ui/icons/Search";
import SummaryEmailDialog from "../SummaryEmailDialog.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import TernaryCheck from "../TernaryCheck.web";
import Editor from "./Editor";
const validationSchema = yup.object().shape({
  booking_calendar: yup.string().required("Enter value"),
  survey_link: yup.string(),
  // .matches(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, "Link is not valid"),
  self_enrollment: yup.string().required("Enter value"),
  business_bundle: yup.string().required("Enter value"),
  new_business_incubator: yup.string().required("Enter value"),
  reporting_only_business: yup.string().required("Enter value"),
  reporting_only_individual: yup.string().required("Enter value"),
  irs_rapid: yup.string().required("Enter value"),
  personal_bundle: yup.string().required("Enter value"),
  payroll_success: yup.string().required("Enter value"),
  business_tax_and_advisory: yup.string().required("Enter value"),
  
});

export class ClientConversionSurveySettings extends ClientConversionSurveySettingsController {
  constructor(props: Props) {
    super(props);
  }
  openEmailDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          type: "emailneedtobesend",
          values: "sendsurvey",
        },
        width: "60%",
        renderedComponent: SummaryEmailDialog,
        withCustomDialog: true,
      })
      .then((data) => {
        data && this.sendCFSSurvey(data?.email);
      });
  };

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const paymentAccessToken = await StorageProvider.get("paymentAccessToken");
    this.setState(
      {
        token: token,
        accountId: accountId,
        editorLoaded: true,
      },
      () => {
        let survey_link =
          window.location.origin +
          `/surveyLandingPage?account_id=${this.state.accountId}&type=generalCFS`;
        if (paymentAccessToken && paymentAccessToken != "null") {
          survey_link += `&access_token=${paymentAccessToken || ""}`;
        }
        this.setState({
          cfsinitialValues: {
            ...this.state.cfsinitialValues,
            survey_link: survey_link,
          },
        });
        this.getCFSSetting();
        this.getEmailTemplate();
      }
    );
  }

  selfEnrollmentSwitchHandler = (setFieldValue, handleChange, event) => {
    [
      {
        name: "New Business Incubator",
        key: "new_business_incubator",
      },
      {
        name: "Business Bundle",
        key: "business_bundle",
      },
      {
        name: "Business Tax & Advisory",
        key: "business_tax_and_advisory",
      },
      {
        name: "Accounting Success Package",
        key: "accounting_success",
      },
      {
        name: "Payroll Success Package",
        key: "payroll_success",
      },
      {
        name: "Personal Bundle",
        key: "personal_bundle",
      },
      {
        name: "IRS Rapid Relief Package",
        key: "irs_rapid",
      },
      {
        name: "Reporting-Only Package (Individual)",
        key: "reporting_only_individual",
      },
      {
        name: "Reporting-Only Package (Business)",
        key: "reporting_only_business",
      },
    ].forEach((pkg) => {
      setFieldValue(pkg.key, event.target.checked);
    });
    handleChange(event);
  };

  businessPackageSwitchHandler = (
    setFieldValue,
    handleChange,
    values,
    event
  ) => {
    handleChange(event);
    setTimeout(() => {
      const a = [
        {
          name: "New Business Incubator",
          key: "new_business_incubator",
        },
        {
          name: "Business Bundle",
          key: "business_bundle",
        },
        {
          name: "Business Tax & Advisory",
          key: "business_tax_and_advisory",
        },
        {
          name: "Accounting Success Package",
          key: "accounting_success",
        },
        {
          name: "Payroll Success Package",
          key: "payroll_success",
        },
        {
          name: "Personal Bundle",
          key: "personal_bundle",
        },
        {
          name: "IRS Rapid Relief Package",
          key: "irs_rapid",
        },
        {
          name: "Reporting-Only Package (Individual)",
          key: "reporting_only_individual",
        },
        {
          name: "Reporting-Only Package (Business)",
          key: "reporting_only_business",
        },
      ].filter((pkg) => {
        return values[pkg.key] === true;
      });
      if (a.length == 8) {
        setFieldValue("self_enrollment", event.target.checked);
      } else {
        setFieldValue("self_enrollment", false);
      }
    }, 1000);
  };

  individualPackageSwitchHandler = (
    setFieldValue,
    handleChange,
    values,
    event
  ) => {
    handleChange(event);
    setTimeout(() => {
      const a = [
        {
          name: "New Business Incubator",
          key: "new_business_incubator",
        },
        {
          name: "Business Bundle",
          key: "business_bundle",
        },
        {
          name: "Business Tax & Advisory",
          key: "business_tax_and_advisory",
        },
        {
          name: "Accounting Success Package",
          key: "accounting_success",
        },
        {
          name: "Payroll Success Package",
          key: "payroll_success",
        },
        {
          name: "Personal Bundle",
          key: "personal_bundle",
        },
        {
          name: "IRS Rapid Relief Package",
          key: "irs_rapid",
        },
        {
          name: "Reporting-Only Package (Individual)",
          key: "reporting_only_individual",
        },
        {
          name: "Reporting-Only Package (Business)",
          key: "reporting_only_business",
        },
      ].filter((pkg) => {
        return values[pkg.key] === true;
      });
      if (a.length == 8) {
        setFieldValue("self_enrollment", event.target.checked);
      } else {
        setFieldValue("self_enrollment", false);
      }
    }, 1000);
  };

  onKeyPressHandler = (event) => {
    if (
      (event.charCode == 8 || event.charCode != 0) &&
      (event.charCode < 48 || event.charCode > 57)
    ) {
      event.preventDefault();
    }
  };

  xyPriceOnChangeHandler = (handleChange, event) => {
    if (+event.target.value > 0 || event.target.value == "") {
      handleChange(event);
    }
  };
  handleAccordianChange = (panel, event) => {
    this.setState({
      expandedEmailTemplate:
        panel == this.state.expandedEmailTemplate ? null : panel,
    });
  };
  copyTemplateContent = (html, template, event) => {
    event.stopPropagation();
    this.setState(
      {
        isCopied: html,
        isCopiedTemplateId: `template_${template}`,
      },
      () => {
        window.navigator.clipboard.writeText(
          document.getElementById(`template_${template}`)?.innerText
        );
        this.setState({
          isCopied: null,
          isCopiedTemplateId:''
        });
      }
    );
  };
  copySurveyLink = (values) => {
    window.navigator.clipboard.writeText(values.survey_link);
  };
  

  renderExtraDiv = () => {
    return (
      <Grid item xs={12}>
        {this.state.isCopied ? (
          <div
            id={this.state.isCopiedTemplateId}
            dangerouslySetInnerHTML={{ __html: this.state.isCopied }}
          />
        ) : null}
      </Grid>
    );
  };
  render() {
    const { classes, theme, permissions } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Formik
            initialValues={{ ...this.state.cfsinitialValues }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              this.updateCFSSetting(values);
            }}
          >
            {(formikProps) => {
              const {
                values,
                handleChange,
                setFieldValue,
                isValid,
                dirty,
              } = formikProps;
              return (
                <Form autoComplete="off" noValidate>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{
                      position: "relative",
                    }}
                  >
                    <Hidden smUp>
                      <Grid item xs={12} className={[classes.gridSpacing]}>
                        <TextField
                          variant="outlined"
                          placeholder="Search..."
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  paddingRight: 0,
                                }}
                              >
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Hidden>

                    <Grid
                      item
                      xs={11}
                      className={[classes.gridSpacing, classes.alignCenter]}
                    >
                      <Typography
                        variant="h2"
                        display="inline"
                        className={classes.mainHeading}
                      >
                        Magic Client Pricing Settings
                      </Typography>
                      <CustomTooltip
                        title={
                          "Use this section to integrate your calendly or acuity calenders with SmartPath. Clients can see your calender at the end of the Conversion Survey"
                        }
                        placement="right"
                      >
                        <img src={infoIcon} className={classes.infoIcon} />
                      </CustomTooltip>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={[classes.gridSpacing, classes.whiteCard]}
                    >
                      <Grid container justifyContent="space-between">
                        <Grid item xs={12} className={classes.alignCenter}>
                          <Typography className={classes.switchLabelCalendly}>
                            Booking Calendar
                          </Typography>
                          <TernaryCheck
                            condition={!this.state.meeting_link}
                            trueComponent={
                              <CustomTooltip
                                title={
                                  !this.state.meeting_link &&
                                  "You have not integrated calendly yet.please integrate it in integration section"
                                }
                                placement="right"
                              >
                                <img
                                  src={infoIcon}
                                  style={{ marginLeft: "8px" }}
                                  className={classes.infoIcon}
                                />
                              </CustomTooltip>
                            }
                            falseComponent={null}
                          />
                          <Field
                            as={PhaseTwoSwitch}
                            disabled={!this.state.meeting_link}
                            color="primary"
                            name="booking_calendar"
                            onChange={handleChange}
                            checked={values.booking_calendar}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={[
                            classes.alignCenter,
                            classes.surveyLinkContainer,
                          ]}
                        >
                          <CustomTypography
                            padding="0rem 0.5rem 0px 0rem"
                            fontSize="1.3158rem"
                            fontWeight={700}
                            otherColor={theme.palette.text.blue2}
                            display="inline"
                          >
                            Magic Client Pricing Link:
                          </CustomTypography>
                          <CustomTooltip
                            title="Use this link to share your Client Conversion Survey with anyone. Add it to your website or share with client via email."
                            placement="right"
                          >
                            <img src={infoIcon} className={classes.infoIcon} />
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={10}>
                              <Field
                                style={{ borderRadius: "50%" }}
                                component={InputField}
                                name="survey_link"
                                placeholder="Link"
                                onChange={handleChange}
                                disabled
                                value={values.survey_link}
                                fullWidth
                                className={classes.surveyLinkField}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                style={{
                                  marginTop: "0.8rem",
                                }}
                                fullWidth
                                variant="outlined"
                                startIcon={<FileCopyIcon />}
                                color="primary"
                                onClick={this.copySurveyLink.bind(this, values)}
                              >
                                Copy
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          className={[classes.selfEnrollmentContainer]}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.alignCenter}>
                              <Typography
                                color="secondary"
                                className={classes.selfEnrollmentHeading}
                              >
                                Self Enrollment
                              </Typography>
                              <CustomTooltip
                                title={`Self Enrollment allows clients to e-sign and submit payment details without speaking to you first. If this is turning "Off", clients will be forced to schedule time on your calender before you enroll them.`}
                                placement="right"
                              >
                                <img
                                  src={infoIcon}
                                  className={classes.infoIcon}
                                />
                              </CustomTooltip>
                              <FormControlLabel
                                control={
                                  <PhaseTwoSwitch
                                    name="self_enrollment"
                                    onChange={this.selfEnrollmentSwitchHandler.bind(
                                      this,
                                      setFieldValue,
                                      handleChange
                                    )}
                                    checked={values.self_enrollment}
                                    color="primary"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid xs={5} item>
                                  <Grid container justifyContent="center">
                                    <Grid item xs={11}>
                                      <Typography
                                        color="secondary"
                                        className={classes.packageHeading}
                                      >
                                        Business Packages
                                      </Typography>
                                    </Grid>
                                    <Grid xs={11} item>
                                      {[
                                        {
                                          name: "New Business Incubator",
                                          key: "new_business_incubator",
                                        },
                                        {
                                          name: "Business Bundle",
                                          key: "business_bundle",
                                        },
                                        {
                                          name: "Business Tax & Advisory",
                                          key: "business_tax_and_advisory",
                                        },
                                        {
                                          name: "Accounting Success Package",
                                          key: "accounting_success",
                                        },
                                        {
                                          name: "Payroll Success Package",
                                          key: "payroll_success",
                                        },
                                        {
                                          name:
                                            "Reporting-Only Package (Business)",
                                          key: "reporting_only_business",
                                        },
                                      ].map((pkg) => {
                                        return (
                                          <Grid
                                            container
                                            spacing={2}
                                            key={pkg.key}
                                          >
                                            <Grid
                                              item
                                              xs={8}
                                              key={pkg.name}
                                              className={[
                                                classes.alignCenter,
                                                classes.subCheckboxesContainer,
                                              ]}
                                            >
                                              <Typography
                                                color="textSecondary"
                                                className={
                                                  classes.packageNameHeading
                                                }
                                              >
                                                {pkg.name}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={1}
                                              className={classes.alignCenter}
                                            >
                                              <FormControlLabel
                                                control={
                                                  <PhaseTwoSwitch
                                                    name={pkg.key}
                                                    onChange={this.businessPackageSwitchHandler.bind(
                                                      this,
                                                      setFieldValue,
                                                      handleChange,
                                                      values
                                                    )}
                                                    checked={values[pkg.key]}
                                                    color="primary"
                                                  />
                                                }
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid xs={5} item>
                                  <Grid container justifyContent="center">
                                    <Grid item xs={11}>
                                      <Typography
                                        color="secondary"
                                        className={classes.packageHeading}
                                      >
                                        Individual Packages
                                      </Typography>
                                    </Grid>
                                    <Grid xs={11} item>
                                      {[
                                        {
                                          name: "Personal Bundle",
                                          key: "personal_bundle",
                                        },
                                        {
                                          name: "IRS Rapid Relief Package",
                                          key: "irs_rapid",
                                        },
                                        {
                                          name:
                                            "Reporting-Only Package (Individual)",
                                          key: "reporting_only_individual",
                                        },
                                      ].map((pkg) => {
                                        return (
                                          <Grid
                                            container
                                            spacing={1}
                                            key={pkg.key}
                                          >
                                            <Grid
                                              item
                                              xs={8}
                                              key={pkg.name}
                                              className={[
                                                classes.alignCenter,
                                                classes.subCheckboxesContainer,
                                              ]}
                                            >
                                              <Typography
                                                color="textSecondary"
                                                className={
                                                  classes.packageNameHeading
                                                }
                                              >
                                                {pkg.name}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              className={classes.alignCenter}
                                            >
                                              <FormControlLabel
                                                control={
                                                  <PhaseTwoSwitch
                                                    name={pkg.key}
                                                    onChange={this.individualPackageSwitchHandler.bind(
                                                      this,
                                                      setFieldValue,
                                                      handleChange,
                                                      values
                                                    )}
                                                    checked={values[pkg.key]}
                                                    color="primary"
                                                  />
                                                }
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                    <Grid xs={12} item style={{ textAlign: "end" }}>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          justifyContent: "flex-end",
                          margin: "16px",
                        }}
                      >
                        <Button
                          disabled={
                            !permissions.includes(
                              "Send Survey-Client Conversion Survey"
                            )
                          }
                          onClick={() => {
                            this.openEmailDialog();
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Send Survey
                        </Button>
                        <Button
                          type="submit"
                          disabled={
                            !(isValid && dirty) ||
                            !permissions.includes(
                              "Manage Survey Settings-Client Conversion Survey"
                            )
                          }
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Grid container>
            <Grid xs={10} item>
              <Typography
                variant="h3"
                color="primary"
                className={classes.mainHeading}
              >
                Email Templates
              </Typography>
            </Grid>
            <Grid xs={12} item className={[classes.pricingBumpsContainer]}>
              {[
                "template_one",
                "template_two",
                "template_three",
                "template_four",
                "template_five",
              ].map((template, index) => {
                const ww = { ...this.state.emailTemplateTitles };
                const title =
                  ww[template]?.indexOf("<title>") > -1
                    ? ww[template].match("<title>(.*?)</title>")[1]
                    : "";
                return (
                  <Accordion
                    key={template}
                    square
                    expanded={this.state.expandedEmailTemplate === template}
                    onChange={this.handleAccordianChange.bind(this, template)}
                    style={
                      template == "template_five"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={11}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: title,
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={this.copyTemplateContent.bind(
                              this,
                              this.state.emailTemplates[template],
                              template
                            )}
                          >
                            <FileCopyIcon color="primary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Editor
                        name="description"
                        onChange={(data) => {
                          let emailTemplates = { ...this.state.emailTemplates };
                          emailTemplates[template] = data;
                          this.setState({
                            emailTemplates: emailTemplates,
                          });
                        }}
                        value={this.state.emailTemplates[template]}
                        editorLoaded={this.state.editorLoaded}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {this.renderExtraDiv()}
      </Grid>
    );
  }
}

const ClientConversionSurveySettingsWithRouter = withRouter(
  ClientConversionSurveySettings
);
const ClientConversionSurveySettingsAlertBox = withAlertBox(
  ClientConversionSurveySettingsWithRouter
);
const ClientConversionSurveySettingsLoader = withLoader(
  ClientConversionSurveySettingsAlertBox
);
const ClientConversionSurveySettingsToast = withToast(
  ClientConversionSurveySettingsLoader
);
const ClientConversionSurveySettingsWithDialog = withDialog(
  ClientConversionSurveySettingsToast
);
const ClientConversionSurveySettingsWithConfirmBox = withConfirmBox(
  ClientConversionSurveySettingsWithDialog
);
const ClientConversionSurveySettingsWithPermissions = withPermissions(
  ClientConversionSurveySettingsWithConfirmBox
);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      mainHeading: {
        color: theme.palette.text.blue2,
        paddingRight: "0.5rem",
        fontSize: "1.35rem",
      },
      switchLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
        textAlign: "right",
      },
      switchLabelCalendly: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
        textAlign: "right",
        cursor: "pointer",
      },
      switchLabelAcuity: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
        textAlign: "right",
        marginLeft: "1rem",
        cursor: "pointer",
      },
      selfEnrollmentHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.25rem",
        fontWeight: 600,
        // color: theme.palette.text.blue2,
        display: "inline-block",
        marginRight: "0.5rem",
      },
      packageNameHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.25rem",
        fontWeight: 600,
        // color: theme.palette.text.blue2,
        display: "inline-block",
        marginRight: "0.5rem",
      },
      pricingBumpHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.25rem",
        fontWeight: 700,
      },
      pricingBumpHeading1: {
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        fontWeight: 700,
      },
      packageHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3rem",
        fontWeight: 700,
        marginBottom: "1rem",
        textDecoration: "underline",
      },
      surveyLinkContainer: {
        marginTop: "2rem",
      },
      surveyLinkField: {
        paddingLeft: "2rem",
        marginTop: "0.8rem",
      },
      selfEnrollmentContainer: {
        paddingLeft: "2rem",
        margin: "3rem 0px 0px 0px",
      },
      subCheckboxesContainer: {
        paddingLeft: "1.2rem",
        marginTop: "0.5rem",
      },
      alignCenter: {
        display: "flex",
        alignItems: "center",
      },
      pricingBumpsContainer: {
        margin: "1rem",
        padding: "1.2rem",
      },
      fieldContainer: {
        // display: "flex",
        // alignItems: "center",
      },
      fieldHeadingContainer: {
        display: "flex",
        alignItems: "flex-end",
        paddingLeft: "2rem",
      },
      checkboxLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
        textAlign: "right",
      },
      heading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
      },
      infoIcon: {
        display: "inline-block",
        width: "1.2rem",
        height: "1.2rem",
        marginRight: "0.5rem",
      },
      smallField: {
        width: "5rem",
        // height : '3rem',
      },
      businessBumpsHeading: {
        paddingLeft: "2rem",
        marginTop: "1rem",
      },
      greyCard: {
        backgroundColor: "#f4f4f4",
        margin: "0.5rem 0px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
      },
      checkboxroot: {
        paddingLeft: "16px",
        paddingTop: "4.5px",
      },
    }),
  { withTheme: true }
)(ClientConversionSurveySettingsWithPermissions);
