//@ts-nocheck
import React from "react";
import AutomaticRemindersController, { Props } from "./AutomaticRemindersController.web";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  TextField,
  InputAdornment,
  Hidden,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { infoIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { PhaseTwoSwitch, TooltipFullWidth } from "../../../../components/src/ModifiedComponents.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import SearchIcon from "@material-ui/icons/Search";
import StdInputField from "./StanderdTextField.web";

const validationSchema = Yup.object().shape({
  automaticReminders: Yup.boolean(),
  frequencySetting: Yup.string(),
  defaultHours: Yup.string().when("frequencySetting", {
    is: "default",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  defaultDays: Yup.string().when("frequencySetting", {
    is: "default",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  onboardingSignatureHours: Yup.string().when("frequencySetting", {
    is: "custom",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  onboardingSignatureDays: Yup.string().when("frequencySetting", {
    is: "custom",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  onboardingDataCollectionHours: Yup.string().when("frequencySetting", {
    is: "custom",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  onboardingDataCollectionDays: Yup.string().when("frequencySetting", {
    is: "custom",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  clientConversionSurveyHours: Yup.string().when("frequencySetting", {
    is: "custom",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
  clientConversionSurveyDays: Yup.string().when("frequencySetting", {
    is: "custom",
    then: Yup.string().required("required"),
    otherwise: Yup.string(),
  }),
});

export class AutomaticReminders extends AutomaticRemindersController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getReminderSettings();
      }
    );
  }

  handleInputChange = (handleChange, numberMask, e) => handleChange(numberMask(e));

  handleRadioChange = (setFieldValue, setFieldTouched, setFieldError, handleChange, e) => {
    if (e.target.value === "default") {
      setFieldValue("onboardingSignatureHours", "");
      setFieldValue("onboardingSignatureDays", "");
      setFieldValue("onboardingDataCollectionHours", "");
      setFieldValue("onboardingDataCollectionDays", "");
      setFieldValue("clientConversionSurveyHours", "");
      setFieldValue("clientConversionSurveyDays", "");
      //errors
      setFieldError("onboardingSignatureHours", "");
      setFieldError("onboardingSignatureDays", "");
      setFieldError("onboardingDataCollectionHours", "");
      setFieldError("onboardingDataCollectionDays", "");
      setFieldError("clientConversionSurveyHours", "");
      setFieldError("clientConversionSurveyDays", "");
      //touched
      setFieldTouched("onboardingSignatureHours", false);
      setFieldTouched("onboardingSignatureDays", false);
      setFieldTouched("onboardingDataCollectionHours", false);
      setFieldTouched("onboardingDataCollectionDays", false);
      setFieldTouched("clientConversionSurveyHours", false);
      setFieldTouched("clientConversionSurveyDays", false);
    } else {
      setFieldValue("defaultHours", "");
      setFieldValue("defaultDays", "");
      //errors
      setFieldError("defaultHours", "");
      setFieldError("defaultDays", "");
      //touched
      setFieldTouched("defaultHours", false);
      setFieldTouched("defaultDays", false);
    }
    handleChange(e);
  };

  onSubmit = (values) => {
    this.createAndUpdateReminderSettings(values);
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <Formik
        initialValues={{
          automaticReminders: this.state.formValues.automaticReminders || false,
          frequencySetting: this.state.formValues.frequencySetting || "default",
          defaultHours: this.state.formValues.defaultHours || "",
          defaultDays: this.state.formValues.defaultDays || "",
          onboardingSignatureHours: this.state.formValues.onboardingSignatureHours || "",
          onboardingSignatureDays: this.state.formValues.onboardingSignatureDays || "",
          onboardingDataCollectionHours: this.state.formValues.onboardingDataCollectionHours || "",
          onboardingDataCollectionDays: this.state.formValues.onboardingDataCollectionDays || "",
          clientConversionSurveyHours: this.state.formValues.clientConversionSurveyHours || "",
          clientConversionSurveyDays: this.state.formValues.clientConversionSurveyDays || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue, setFieldError, setFieldTouched, isValid, dirty } = formikProps;
          const numberMask = (e) => {
            const value = e.target.value;
            const newValue = value.replace(/^(0+|[^1-9]+)/g, "");
            e.target.value = newValue;
            return e;
          };
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                justifyContent="space-between"
                style={{
                  position: "relative",
                }}
              >
                <Hidden smUp>
                  <Grid item xs={12} className={[classes.gridSpacing]}>
                    <TextField
                      variant="outlined"
                      placeholder="Search..."
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              paddingRight: 0,
                            }}
                          >
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Hidden>

                <Grid item xs={11} className={[classes.gridSpacing, classes.sectionHeadings]}>
                  <Typography
                    variant="h2"
                    style={{
                      color: theme.palette.text.blue2,
                    }}
                    id="automatic-reminders-heading"
                  >
                    Automatic Reminders
                  </Typography>
                </Grid>

                <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                      <FormControlLabel
                        classes={{
                          label: classes.switchLabel,
                        }}
                        control={<PhaseTwoSwitch name="automaticReminders" onChange={handleChange} checked={values.automaticReminders} color="primary" />}
                        label="Automatic Reminders:"
                        labelPlacement="start"
                      />
                      <CustomTypography padding="2rem 0px 0px 1rem" fontSize="1.3158rem" fontWeight={700} otherColor={theme.palette.text.blue2}>
                        Frequency Settings:
                      </CustomTypography>
                    </Grid>

                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="frequencySetting"
                        name="frequencySetting"
                        value={values.frequencySetting}
                        onChange={this.handleRadioChange.bind(this, setFieldValue, setFieldTouched, setFieldError, handleChange)}
                      >
                        <Grid item xs={12} className={classes.innerContainerDefault}>
                          <Grid container>
                            <Grid item xs={12} className={classes.alignCenter}>
                              <FormControlLabel
                                control={<Radio value="default" color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
                                classes={{
                                  label: classes.checkboxLabel,
                                }}
                                label="Default"
                              />
                              <TooltipFullWidth title="Default settings will apply reminder schedule to all options." placement="right">
                                <img src={infoIcon} className={classes.infoIcon} />
                              </TooltipFullWidth>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldContainer}>
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                Reminder will be sent out every
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="defaultHours"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.defaultHours}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "custom"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                hours for
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="defaultDays"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.defaultDays}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "custom"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                days or until the action is complete.
                              </CustomTypography>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item xs={12} className={classes.alignCenter}>
                              <FormControlLabel
                                control={<Radio value="custom" color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
                                classes={{
                                  label: classes.checkboxLabel,
                                }}
                                label="Custom"
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldHeadingContainer}>
                              <CustomTypography fontSize="1.1rem" fontWeight={700} color="textSecondary" display="block">
                                Onboarding - Signatures
                              </CustomTypography>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldContainer}>
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                Reminder will be sent out every
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="onboardingSignatureHours"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.onboardingSignatureHours}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "default"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                hours for
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="onboardingSignatureDays"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.onboardingSignatureDays}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "default"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                days or until the action is complete.
                              </CustomTypography>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldHeadingContainer}>
                              <CustomTypography fontSize="1.1rem" fontWeight={700} color="textSecondary" display="block">
                                Onboarding - Data Collection
                              </CustomTypography>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldContainer}>
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                Reminder will be sent out every
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="onboardingDataCollectionHours"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.onboardingDataCollectionHours}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "default"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                hours for
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="onboardingDataCollectionDays"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.onboardingDataCollectionDays}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "default"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                days or until the action is complete.
                              </CustomTypography>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldHeadingContainer}>
                              <CustomTypography fontSize="1.1rem" fontWeight={700} color="textSecondary" display="block">
                                Client Needs Calculator
                              </CustomTypography>
                            </Grid>
                            <Grid item xs={12} className={classes.fieldContainer}>
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                Reminder will be sent out every
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="clientConversionSurveyHours"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.clientConversionSurveyHours}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "default"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                hours for
                              </CustomTypography>
                              <Field
                                component={StdInputField}
                                name="clientConversionSurveyDays"
                                onChange={this.handleInputChange.bind(this, handleChange, numberMask)}
                                value={values.clientConversionSurveyDays}
                                className={classes.smallField}
                                disabled={values.frequencySetting === "default"}
                              />
                              <CustomTypography fontSize="1.rem" color="textSecondary" display="block">
                                days or until the action is complete.
                              </CustomTypography>
                            </Grid>

                            <Grid item xs={12} className={classes.fieldContainer}>
                              <Button color="primary" variant="contained" type="submit" className={classes.saveButton} disabled={!(isValid && dirty)}>
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const AutomaticRemindersWithRouter = withRouter(AutomaticReminders);
const AutomaticRemindersAlertBox = withAlertBox(AutomaticRemindersWithRouter);
const AutomaticRemindersLoader = withLoader(AutomaticRemindersAlertBox);
const AutomaticRemindersToast = withToast(AutomaticRemindersLoader);
const AutomaticRemindersWithDialog = withDialog(AutomaticRemindersToast);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      switchLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
        textAlign: "right",
      },
      innerContainerDefault: {
        paddingLeft: "3rem",
        margin: "1rem 0px 0px 0px",
      },
      alignCenter: {
        display: "flex",
        alignItems: "center",
      },
      innerContainerCustom: {
        paddingLeft: "3rem",
        margin: "1rem 0px",
        display: "flex",
        alignItems: "center",
      },
      fieldContainer: {
        display: "flex",
        alignItems: "flex-end",
        paddingLeft: "2rem",
        marginBottom: "2rem",
      },
      fieldHeadingContainer: {
        display: "flex",
        alignItems: "flex-end",
        paddingLeft: "2rem",
      },
      checkboxLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
        textAlign: "right",
      },
      heading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
      },
      sectionHeadings: {
        paddingLeft: theme.spacing(2),
      },
      infoIcon: {
        display: "inline-block",
        width: "1rem",
        height: "1rem",
      },
      smallField: {
        width: "8rem",
        margin: "0px 0.5rem",
      },
      formControlLabelRoot: {
        "& > span": {
          padding: 0,
          paddingRight: "0.5rem",
        },
      },
      saveButton: {
        width: "20%",
        marginTop: "3rem",
      },
      smallInputFieldInput: {
        borderRadius: "0.75rem",
        position: "relative",
        backgroundColor: "#f4f4f4",
        fontSize: "16px",
        fontFamily: "Nunito Sans",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.19,
        letterSpacing: "normal",
        padding: "0.5rem 0.875rem",
        border: "none",
        outline: "none",
        "&:focus": {
          border: "none",
          borderRadius: "0.75rem",
          backgroundColor: "#fff",
          "&::placeholder": {
            color: theme.palette.text.blue2,
          },
        },
      },
    }),
  { withTheme: true }
)(withConfirmBox(AutomaticRemindersWithDialog));
