//@ts-nocheck
import React from "react";
import { withStyles, Grid, createStyles, Button } from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import withLoader, {
  withLoaderProps,
} from "../../../../components/src/withLoader.Web";
import Pdf from "../../../../components/src/reactPdf.js";
import RoadmapSaveDialog from "./RoadmapSaveDialog.web";
import RoadmapExportDialog from "./RoadmapExportDialog.web";
import RoadmapCancelDialog from "./RoadmapCancelDialog.web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, {
  withAlertBoxProps,
} from "../../../../components/src/withAlertBox.Web";
import withToast, {
  withToastProps,
} from "../../../../components/src/withSnackBar.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import RoadMapUIWeb from "./RoadMapUI.web";
import moment from "moment";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    selectedPackageList: [];
    // Customizable Area Start
    // Customizable Area End
  };

const options = {
  unit: "pt",
  format: "a4",
};

export class RoadMap extends React.Component {
  constructor(props: Props) {
    super(props);
    this.pdfRef = React.createRef();
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.props.getRoadMap(this.props.history.location.state?.roadmap?.id);
      this.props.getGSuiteData();
    }
  }

  openSaveDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          roadMapName:
            this.props.roadMapName || this.generateRoadmapFilename(false),
        },
        width: "50%",
        renderedComponent: RoadmapSaveDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "cancel") {
          this.openCancelDialog();
        } else if (data !== "cancel") {
          this.props.setState(
            {
              roadMapName: data?.roadMapName,
            },
            () => {
              this.props.updateRoadmapWithName();
            }
          );
        }
      });
  };

  openCancelDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          props: this.props,
        },
        width: "40%",
        renderedComponent: RoadmapCancelDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.props.deleteRoadMap(
            this.props.history.location.state?.roadmap?.id
          );
          this.props.history.replace("/");
        } else if (data === "No") {
          this.openSaveDialog();
        }
      });
  };

  openExportDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          values: {
            fileName: this.generateRoadmapFilename(true),
            element: this.pdfRef,
          },
          googleObj: this.props.googleObj,
        },
        title: "Export",
        width: "50%",
        renderedComponent: RoadmapExportDialog,
        withCustomDialog: false,
      })
      .then(this.exportRoadmpaResponse.bind(this));
  };
  copyRoadmapLink = () => {
    window.navigator.clipboard.writeText(this.props.roadmap_client_link);
  };

  exportRoadmpaResponse = async (data: any) => {
    if (data.msg) {
      if (data.msg == "google not connected") {
        this.props.showToast({
          type: "error",
          message: "Please link your google account in integration section",
        });
      } else {
        this.props.showToast({
          type: "success",
          message: data.msg,
        });
      }
    }
  };

  generateRoadmapFilename = (withExtension) => {
    const clientInfo = this.props.roadmap?.attributes?.client_information?.data
      ?.attributes;
    const firstName = clientInfo?.first_name;
    const lastName = clientInfo?.last_name;
    const currentDate = moment().format("DD-MM-YY | hh:mm");
    const extensionName = withExtension ? ".pdf" : "";

    const capitalizeName = (name) => {
      if (name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
      }
      return name;
    };

    const formattedFirstName = capitalizeName(firstName);
    const formattedLastName = capitalizeName(lastName);

    return formattedFirstName && formattedLastName
      ? `Roadmap: ${formattedFirstName} ${formattedLastName} - ${currentDate}${extensionName}`
      : `Roadmap: ${currentDate}${extensionName}`;
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid
          container
          ref={this.pdfRef}
          style={{ overflowX: "hidden" }}
          id="pdfRef"
          justifyContent="center"
          className={classes.rootChildContainerpdf}
        >
          <RoadMapUIWeb
            logo={this.props.logo}
            roadmap={this.props.roadmap}
            packagePaymentTerms={this.props.selectedPaymentTerms}
            selectedPaymentTerm={null}
            isPackageSelectable={false}
          />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            className={`${classes.marginTop} ${classes.marginBottom}`}
          >
            <Grid container justifyContent="flex-end" spacing={2}>
              {this.props.client_id && (
                <Button
                  style={{ marginRight: "2rem" }}
                  color="primary"
                  onClick={this.copyRoadmapLink.bind(this)}
                  variant="contained"
                >
                  Copy link
                </Button>
              )}

              <Button
                style={{ marginRight: "2rem" }}
                color="primary"
                onClick={() => {
                  this.openExportDialog();
                }}
                variant="outlined"
              >
                Export
              </Button>
              <Button
                color="primary"
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  this.openSaveDialog();
                }}
                variant="contained"
              >
                Save & continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const RoadMapWithRouter = withRouter(RoadMap);
const RoadMapWithToast = withToast(RoadMapWithRouter);
const RoadMapWithLoader = withLoader(RoadMapWithToast);
const RoadMapWithAlertBox = withAlertBox(RoadMapWithLoader);
const RoadMapWithDialogBox = withDialogBox(RoadMapWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    rootChildContainerpdf: {
      [theme.breakpoints.up("md")]: {
        padding: "1.5rem 2rem 2rem 2rem",
      },
      [theme.breakpoints.down("md")]: {
        padding: "1rem",
      },
    },
  })
)(RoadMapWithDialogBox);
