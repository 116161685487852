//@ts-nocheck
import {
    IBlock
  } from "../../../framework/src/IBlock";
  import {
    Message
  } from "../../../framework/src/Message";
  import {
    BlockComponent
  } from "../../../framework/src/BlockComponent";
  import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
  import {
    runEngine
  } from "../../../framework/src/RunEngine";
  import {
    RouterProps
  } from "react-router";
  import {
    withAlertBoxProps
  } from "../../../components/src/withAlertBox.Web";
  import {
    withToastProps
  } from "../../../components/src/withSnackBar.Web";
  import {
    withLoaderProps
  } from "../../../components/src/withLoader.Web";
  import {
    DialogProps
  } from "../../../components/src/DialogContext";
  
  export const configJSON = require("./config");
  
  export type Props = RouterProps &
    DialogProps &
    withAlertBoxProps &
    withToastProps &
    withLoaderProps & {
        id: string;
        // Customizable Area Start
        // Customizable Area End
    };
  
  interface S {
    // Customizable Area Start
    token: string;
    user: any;
    allRolesList: [],
        permissions: [],
        accountId: any;
    currentRole: string;
    // Customizable Area End
  }
  
  interface SS {
    id: any;
  }
  
  export default class CreateRoleController extends BlockComponent <
    Props,
    S,
    SS >
    {
        updateUserPermissionsApiCallId: string = "";
  
        constructor(props: Props) {
            super(props);
            this.receive = this.receive.bind(this);
  
            // Customizable Area Start
            this.subScribedMessages = [
                // Customizable Area Start
                getName(MessageEnum.RestAPIResponceMessage),
                getName(MessageEnum.AlertMessage),
                // Customizable Area End
            ];
  
            this.state = {
                user: {},
                token: "",
                allRolesList: [],
                permissions: [],
                accountId: '',
                currentRole: '',
            };
            // Customizable Area End
            runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        }
  
        async receive(from: string, message: Message) {
            runEngine.debugLog("Message Recived", message);
            if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );
  
                const responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
  
               if (apiRequestCallId === this.updateUserPermissionsApiCallId) {
                    this.props.hideLoader();
                    if (responseJson?.data?.id) {
                        this.props.showToast({message:'User Updated Succssfully',type:'success'})
                        this.props.history.push({
                            pathname: '/adminDashboard/rolesAndPermissions',
                        })
                    } else if(typeof responseJson?.errors === 'string') {
              this.props.showToast({
                type : 'error',
                message : responseJson.errors
              })
                    } else {
                      this.parseApiErrorResponse(responseJson);
                    }
                }
            } else if (getName(MessageEnum.AlertMessage) === message.id) {
                const AlertBodyMessage = message.getData(
                    getName(MessageEnum.AlertBodyMessage)
                );
                this.props.showAlert({
                    title: "Error",
                    message: AlertBodyMessage,
                });
            }
        }
  
        updateUserPermissions = (values: any) => {
            this.props.showLoader();
  
            const headers = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.token,
                skipSubscriptionCheck: true,
            };
  
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
  
            this.updateUserPermissionsApiCallId = requestMessage.messageId;
  
            const data = {
              "data":{
                "attributes":{
                "first_name": values.firstName,
                "last_name": values.lastName,
                "firm_name": values.firmName,
                },
              },
            }
            
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.usersEndpoint}/${this.state.accountId}`
            );
  
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
  
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(data)
            );
  
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                'PATCH'
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };
    }