//@ts-nocheck
import React from "react";
import ClientDocumentUploadController from "./clientDocumentUploadController.web";
import { Button, Grid, withStyles, createStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export class ClientDocumentUpload extends ClientDocumentUploadController {
  pdfRef = React.createRef<any>();
  

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const clientId = await StorageProvider.get("clientId");
    this.setState(
      {
        token: token,
        accountId: accountId,
        clientId: clientId,
      },
      () => {
        this.getSingleClient();
      }
    );
  }
  handleFileSelection = async (files: FileList) => {
    const filePathsPromises: any = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      uploadedImage: filePaths[0] as string,
    });
  };

  

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
          <CustomTypography color="primary" fontSize="1.3rem" fontWeight="bold">
            Documents
          </CustomTypography>
          <Button color="primary" variant="contained" startIcon={<AddCircleOutlineIcon/>}>
            Upload Documents
          </Button>
        </CustomGrid>

        <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center" className={classes.tableContainerStyles}>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.headingRowStyles}>
              Name
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.headingRowStyles, classes.headingMiddleColumn]}>
              Date Modified
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.headingRowStyles, classes.lastColumn]}>
              Size
            </CustomGrid>
          </CustomGrid>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.bodyRowStyles} container alignItems="center">
              <ArrowRightIcon size="large" className={classes.iconMarginRight}/>
              <FolderOpenIcon color="primary" className={classes.iconMarginRight}/>
              Tax Returns
            </CustomGrid>
            <CustomGrid item width="25%" className={classes.bodyRowStyles}>
              March 17, 2023 at 12:30 PM
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.bodyRowStyles, classes.lastColumn]}>
              9.2MB
            </CustomGrid>
          </CustomGrid>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.bodyRowStyles} container alignItems="center">
              <ArrowRightIcon size="large" className={classes.iconMarginRight}/>
              <FolderOpenIcon color="primary" className={classes.iconMarginRight}/>
              Strategy Documents
            </CustomGrid>
            <CustomGrid item width="25%" className={classes.bodyRowStyles}>
              March 17, 2023 at 12:30 PM
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.bodyRowStyles, classes.lastColumn]}>
              9.2MB
            </CustomGrid>
          </CustomGrid>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.bodyRowStyles} container alignItems="center">
              <ArrowRightIcon size="large" className={classes.iconMarginRight}/>
              <FolderOpenIcon color="primary" className={classes.iconMarginRight}/>
              Submitted Documents
            </CustomGrid>
            <CustomGrid item width="25%" className={classes.bodyRowStyles}>
              March 17, 2023 at 12:30 PM
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.bodyRowStyles, classes.lastColumn]}>
              9.2MB
            </CustomGrid>
          </CustomGrid>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.bodyRowStyles} container alignItems="center">
              <ArrowRightIcon size="large" className={classes.iconMarginRight}/>
              <FolderOpenIcon color="primary" className={classes.iconMarginRight}/>
              Accounting Documents
            </CustomGrid>
            <CustomGrid item width="25%" className={classes.bodyRowStyles}>
              March 17, 2023 at 12:30 PM
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.bodyRowStyles, classes.lastColumn]}>
              9.2MB
            </CustomGrid>
          </CustomGrid>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.bodyRowStyles} container alignItems="center">
              <ArrowRightIcon size="large" className={classes.iconMarginRight}/>
              <FolderOpenIcon color="primary" className={classes.iconMarginRight}/>
              Payroll Documents
            </CustomGrid>
            <CustomGrid item width="25%" className={classes.bodyRowStyles}>
              March 17, 2023 at 12:30 PM
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.bodyRowStyles, classes.lastColumn]}>
              9.2MB
            </CustomGrid>
          </CustomGrid>

          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
            <CustomGrid item width="50%" className={classes.bodyRowStyles} container alignItems="center">
              <ArrowRightIcon size="large" className={classes.iconMarginRight}/>
              <FolderOpenIcon color="primary" className={classes.iconMarginRight}/>
              Miscellaneous
            </CustomGrid>
            <CustomGrid item width="25%" className={classes.bodyRowStyles}>
              March 17, 2023 at 12:30 PM
            </CustomGrid>
            <CustomGrid item width="25%" className={[classes.bodyRowStyles, classes.lastColumn]}>
              9.2MB
            </CustomGrid>
          </CustomGrid>

        </CustomGrid>
      </Grid>
    );
  }
}

const ClientDocumentUploadWithRouter = withRouter(ClientDocumentUpload);
const ClientDocumentUploadAlertBox = withAlertBox(ClientDocumentUploadWithRouter);
const ClientDocumentUploadLoader = withLoader(ClientDocumentUploadAlertBox);
const ClientDocumentUploadToast = withToast(ClientDocumentUploadLoader);
const ClientDocumentUploadWithDialog = withDialog(ClientDocumentUploadToast);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      crossOverImage: {
        right: "-5px",
        top: "-5px",
        position: "absolute",
        backgroundColor: "red",
        color: "white",
        padding: "3px",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        cursor: "pointer",
      },
      tableContainerStyles: {
        backgroundColor: "#fff",
        borderRadius: "1rem",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        margin: "1rem 0"
      },
      headingRowStyles: {
        backgroundColor: "#FCFCFC",
        padding: "1rem",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: theme.palette.text.secondary
      },
      bodyRowStyles: {
        backgroundColor: "#fff",
        padding: "1rem",
        fontSize: "1.1rem",
        fontWeight: 500,
        color: theme.palette.text.secondary
      },
      iconMarginRight: {
        marginRight: "0.5rem"
      },
      lastColumn: {
        textAlign: "right",
        paddingRight: "1.5rem"
      },
      headingMiddleColumn: {
        borderLeft: `1px solid ${theme.palette.text.secondary}`,
        borderRight: `1px solid ${theme.palette.text.secondary}`
      }
    }),
  { withTheme: true }
)(withConfirmBox(ClientDocumentUploadWithDialog));
