//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");
import React from "react";
export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: "";
  accountId: "";
  customerGrowthLineData: {
    labels: [];
    datasets: [];
  };
  lastQuaterRevenueLineData: {
    labels: [];
    datasets: [];
  };
  selectedRevenueMonth: string;
  selectedRevenueYear: string;
  averageRevenueSelected: string;
  revenueThisMonth: number;
  revenueLastMonth: number;
  revenueThisYear: number;
  revenueLastYear: number;
  combineRevenue: number;
  individualRevenue: number;
  businessRevenue: number;
  monthlyRecurring: number;
  monthlyOneTime: number;
  revenueGrowthTimePeriod: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FirmPerformanceRevenueController extends BlockComponent<Props, S, SS> {
  getOnBoardedClientDataApiCallId: string = "";
  getRevenueGrowthApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      customerGrowthLineData: {
        labels: [],
        datasets: [],
      },
      lastQuaterRevenueLineData: {
        labels: [],
        datasets: [],
      },
      selectedRevenueMonth: "thisMonth",
      revenueThisMonth: 0,
      revenueLastMonth: 0,
      revenueThisYear: "0",
      revenueLastYear: "0",
      selectedRevenueYear: "thisYear",
      averageRevenueSelected: "combined",
      combineRevenue: 0,
      individualRevenue: 0,
      businessRevenue: 0,
      monthlyRecurring: 0,
      monthlyOneTime: 0,
      revenueGrowthTimePeriod: 12,
      revenueGrowthLineData: {
        labels: [],
        datasets: [],
      },
      current_month_revenue: 0,
      overall_revenue: 0,
    };
    this.revenueChart = React.createRef(null);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  generateLineChartData(labels, data, borderColor) {
    return {
      labels,
      datasets:
        data.length > 0
          ? [
              {
                label: "",
                data,
                borderColor,
                backgroundColor: borderColor,
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 3,
                pointHoverRadius: 4,
                datalabels: {
                  align: "start",
                  anchor: "start",
                  font: {
                    family: "Nunito Sans",
                    size: 8,
                    weight: 400,
                  },
                  padding: 2,
                },
              },
            ]
          : [],
    };
  }

  calculateRevenueData(revenue) {
    return Object.keys(revenue)
      ?.reverse()
      ?.map((pkg) => {
        return Array.isArray(revenue[pkg]) ? revenue[pkg]?.reduce((a, b) => a + b, 0) : revenue[pkg];
      });
  }

  setRevenue = (responseJson) => {
    if (responseJson?.data?.total_revenue) {
      this.setState({
        combineRevenue: responseJson?.data?.total_revenue?.combined || 0,
        individualRevenue: responseJson?.data?.total_revenue?.individual || 0,
        businessRevenue: responseJson?.data?.total_revenue?.business || 0,
      });
    }
    if (responseJson?.data?.annual_revenue) {
      this.setState({
        revenueThisYear: responseJson?.data?.annual_revenue?.this_year || 0,
        revenueLastYear: responseJson?.data?.annual_revenue?.last_year || 0,
      });
    }
    if (responseJson?.data?.monthly_revenue) {
      this.setState({
        revenueThisMonth: responseJson?.data?.monthly_revenue?.this_month || 0,
        revenueLastMonth: responseJson?.data?.monthly_revenue?.last_month || 0,
      });
    }
    this.setState({
      current_month_revenue: parseFloat(responseJson?.data?.current_month_revenue).toFixed(2) || 0,
      overall_revenue: +parseFloat(responseJson?.data?.overall_revenue).toFixed(2) * 2 || 0,
    });
  }

  handleRevenueGrowthApiResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      const is_revenue_growth = responseJson?.data?.revenue_growth && Object.keys(responseJson?.data?.revenue_growth).length > 0;
      if (is_revenue_growth) {
        const labels = Object.keys(responseJson?.data?.revenue_growth);
        const lineChartData = {
          labels,
          datasets:
            Object.values(responseJson?.data?.revenue_growth)?.length > 0
              ? [
                  {
                    label: "",
                    data: Object.values(responseJson?.data?.revenue_growth),
                    borderColor: "#436781",
                    backgroundColor: "#436781",
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    pointHoverRadius: 4,
                    datalabels: {
                      align: "start",
                      anchor: "start",
                      font: {
                        family: "Nunito Sans",
                        size: 8,
                        weight: 400,
                      },
                      padding: 2,
                    },
                  },
                ]
              : [],
        };
        this.setState({
          revenueGrowthLineData: lineChartData,
        });
      }
      this.setRevenue(responseJson);
    } else {
      this.handleError(responseJson);
    }
  };

  handleOnboardedClientApiResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.last_quarter_customer_growth || responseJson?.last_quarter_revenue) {
      if (responseJson?.last_quarter_customer_growth) {
        const labels = Object.keys(responseJson?.last_quarter_customer_growth)?.reverse();
        const lineChartData = this.generateLineChartData(labels, Object.values(responseJson?.last_quarter_customer_growth)?.reverse(), "#9CBE6A");
        this.setState({
          customerGrowthLineData: lineChartData,
        });
      }
      if (responseJson?.last_quarter_revenue) {
        const labels = Object.keys(responseJson?.last_quarter_revenue)?.reverse();
        const data = this.calculateRevenueData(responseJson?.last_quarter_revenue);
        const lineChartData = this.generateLineChartData(labels, data, "rgba(54, 162, 235, 1)");
        this.setState({
          lastQuaterRevenueLineData: lineChartData,
        });
      }
    } else {
      this.handleError(responseJson);
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getOnBoardedClientDataApiCallId) {
        this.handleOnboardedClientApiResponse(responseJson);
      } else if (this.getRevenueGrowthApiCallId === apiRequestCallId) {
        this.handleRevenueGrowthApiResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === "Session TimeOut,Please Login Again." || msg === "User Account Not Found") {
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountId")
        this.props.history.push("/");
      }
    }
  }

  getOnBoardedClientData = (month) => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOnBoardedClientDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/client_onboarded?last_month=${month}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRevenueGrowth = (month) => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRevenueGrowthApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/revenue_growth?last_month=${month}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
