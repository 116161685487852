//@ts-nocheck
import React from "react";
import OnboardingCustomizationController, {
} from "./OnboardingCustomizationController.web";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  RadioGroup,
  Radio,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { checkMarkPhaseTwo, moreIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InputField from "../../../../components/src/DarkFieldPhaseTwo.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Form, FieldArray, FastField } from "formik";
import * as DOMPurify from "dompurify";
import uuid from "react-uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SingleClientImageUpload from "./SingleClientImageUpload.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import TernaryCheck from "../TernaryCheck.web";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { spaceMask } from "../masks";
import { Close } from "@material-ui/icons";
import StanderdTextFieldWeb from "../Settings/StanderdTextField.web";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import WhiteFieldWeb from "../../../../components/src/WhiteField.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const SmallMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: "normal",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "space-between",
    padding: "0.35rem 1rem",
    border: "none",
    "&.Mui-selected": {
      backgroundColor: theme.palette.common.white,
      border: "none",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    border: "none",
  },
}))(MenuItem);

export const ButtonMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: "normal",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "0.35rem 1rem",
    border: "none",
    "&.Mui-selected": {
      backgroundColor: theme.palette.common.white,
      border: "none",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    border: "none",
  },
}))(MenuItem);

const Question = ({
  questionItem,
  indexQuestionItem,
  sectionItem,
  indexSectionItem,
  classes,
  values,
  handleChange,
  setFieldValue,
  questionDraggableProvided,
  survey_status,
}) => {
  const renderInputFieldByType = (question, classes, handleChange, name) => {
    const question_type = question?.question_type || "textfield";
    const current_gathered_details =
      question.question_type == "multiselect" &&
      !question.current_gathered_details
        ? "select"
        : question.current_gathered_details;
    let element = (
      <FastField
        component={TextField}
        placeholder="Your Answer"
        id="questionanswer"
        name={name}
        value={question?.current_gathered_details || ""}
        onChange={handleChange}
        InputProps={{
          classes: {
            input: classes?.input,
            root: classes?.inputfieledroot,
          },
          notched: false,
        }}
        style={{ background: "#fff" }}
        fullWidth
        variant="outlined"
        color="primary"
        disabled
        multiline
        rows={10}
      ></FastField>
    );
    switch (question_type) {
      case "textfield":
        element = (
          <FastField
            component={TextField}
            placeholder="Your Answer"
            id="questionanswer"
            name={name}
            value={question.current_gathered_details || ""}
            onChange={handleChange}
            InputProps={{
              classes: {
                input: classes?.input,
                root: classes?.inputfieledroot,
              },
              notched: false,
            }}
            style={{ background: "#fff" }}
            fullWidth
            variant="outlined"
            color="primary"
            disabled
            multiline
            rows={10}
          ></FastField>
        );
        break;
      case "checkbox":
        element = (
          <FormControl
            classes={{ root: classes.formControlQuestion }}
            component="fieldset"
            className={classes.formControl}
          >
            <FormGroup>
              {question?.question_options?.map((option, index) => {
                return (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={
                          question.current_gathered_details.indexOf(option) >
                            -1 || false
                        }
                        disabled
                        onChange={handleChange}
                        name={name}
                      />
                    }
                    label={option}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
        break;
      case "radio":
        element = (
          <FormControl
            classes={{ root: classes.formControlQuestion }}
            component="fieldset"
          >
            <RadioGroup
              aria-label="gender"
              color="primary"
              name={name}
              value={question.current_gathered_details}
              onChange={handleChange}
            >
              {question?.question_options?.map((option, index) => {
                return (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio disabled color="primary" />}
                    label={option}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
        break;
      case "multiselect":
        element = (
          <FormControl className={classes.formControl}>
            <TextField
              select
              fullWidth
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              name={name}
              value={current_gathered_details}
              disabled
              variant="outlined"
              color="primary"
              onChange={handleChange}
            >
              <MenuItem value={"select"}>Select</MenuItem>
              {question?.question_options?.map((name, index) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        );
        break;
    }
    return element;
  };

  const getIconType = (questionType) => {
    switch (questionType) {
      case "Radio Button":
        return <RadioButtonCheckedIcon />;
      case "Check Box":
        return <CheckCircleOutlineIcon />;
      case "Drop Down":
        return <ArrowDropUpIcon />;
      case "Free Text":
        return <ControlPointIcon />;
      default:
        break;
    }
  };

  const showPossibleAnswersSection =
    values.selectedChecklist.attributes.sections.data[indexSectionItem]
      .attributes.questions.data[indexQuestionItem].attributes
      .question_type !== "textfield" &&
    values.selectedChecklist.attributes.sections.data[indexSectionItem]
      .attributes.questions.data[indexQuestionItem].attributes
      .question_type !== "Select";
console.log("showPossibleAnswersSectionshowPossibleAnswersSection",showPossibleAnswersSection)
console.log("showPossibleAnswersSectionshowPossibleAnswersSectionId",values.selectedChecklist.attributes.sections.data[indexSectionItem]
.attributes.questions.data[indexQuestionItem])
  return (
    <Grid
      xs={12}
      container
      className={classes.lightGreyContainer}
      {...questionDraggableProvided.draggableProps}
      {...questionDraggableProvided.dragHandleProps}
      ref={questionDraggableProvided.innerRef}
    >
      <DragIndicatorIcon className={classes.dragIcon} />
      <img
        src={moreIcon}
        alt="moreIcon"
        className={classes.moreIconInsideContainer}
      />
      <Grid item xs={12}>
        {questionItem.isAdditionalQuestion ? (
          <>
            <FastField
              placeholder="Enter question"
              component={InputField}
              name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.question_name`}
              value={
                values.selectedChecklist.attributes.sections.data[
                  indexSectionItem
                ]?.attributes?.questions?.data[indexQuestionItem]?.attributes
                  ?.question_name
              }
              onChange={handleChange}
              fullWidth
            />
            <Grid item xs={12} container alignItems="center">
              <CustomTypography
                margin="0.5rem 0"
                color="secondary"
                className={classes.fieldLabelSmall}
              >
                Type of Question
              </CustomTypography>
            </Grid>
            <FastField
              component={InputField}
              name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.question_type`}
              value={
                values.selectedChecklist.attributes.sections.data[
                  indexSectionItem
                ]?.attributes?.questions?.data[indexQuestionItem]?.attributes
                  ?.question_type
              }
              onChange={handleChange}
              fullWidth
              select
            >
              <MenuItem value={"Select"} disabled>
                Select
              </MenuItem>
              {[
                { name: "Radio Button", value: "radio" },
                { name: "Check Box", value: "checkbox" },
                { name: "Drop Down", value: "multiselect" },
                { name: "Free Text", value: "textfield" },
              ].map((questionType) => (
                <MenuItem key={questionType.name} value={questionType.value}>
                  <Grid container alignItems="center">
                    {getIconType(questionType.name)}
                    <CustomTypography padding="0 0 0 1rem">
                      {questionType.name}
                    </CustomTypography>
                  </Grid>
                </MenuItem>
              ))}
            </FastField>
            {showPossibleAnswersSection ? (
              <>
                <Grid item xs={12}>
                  <CustomTypography
                    color="textSecondary"
                    padding="0.5rem 1rem 0 1rem"
                    fontSize="1.1rem"
                    fontWeight="bold"
                  >
                    Possible Answers
                  </CustomTypography>
                  <Divider light />
                </Grid>
                <Grid item xs={12} />
                <CustomGrid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  className={classes.possibleAnswersWhiteCard}
                >
                  {values.selectedChecklist.attributes.sections.data[
                    indexSectionItem
                  ].attributes.questions.data[indexQuestionItem].attributes
                    .question_options.length > 0
                    ? values.selectedChecklist.attributes.sections.data[
                        indexSectionItem
                      ].attributes.questions.data[
                        indexQuestionItem
                      ].attributes.question_options.map((queOpt) => (
                        <CustomGrid
                          padding="0 0 0.5rem 0"
                          key={queOpt}
                          item
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <RadioButtonUncheckedIcon />
                          <CustomTypography
                            style={{
                              width: "90%",
                            }}
                            color="primary"
                            fontWeight={700}
                            fontSize="1.2rem"
                            padding="0 0 0 1rem"
                          >
                            {queOpt}
                          </CustomTypography>
                          <Close
                            className={classes.closeIcon}
                            onClick={() => {
                              let newQuestionOptions = [
                                ...values.selectedChecklist.attributes.sections
                                  .data[indexSectionItem].attributes.questions
                                  .data[indexQuestionItem].attributes
                                  .question_options,
                              ];
                              newQuestionOptions = newQuestionOptions.filter(
                                (item) => item !== queOpt
                              );
                              setFieldValue(
                                `selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.question_options`,
                                newQuestionOptions
                              );
                            }}
                          />
                        </CustomGrid>
                      ))
                    : null}
                  <FastField
                    component={StanderdTextFieldWeb}
                    name="tempQuestionOption"
                    value={values.tempQuestionOption}
                    placeholder="Type here"
                    onChange={(e) => handleChange(spaceMask(e))}
                    fullWidth
                  />
                  <Button
                    variant="text"
                    color="primary"
                    disabled={values.tempQuestionOption === ""}
                    onClick={() => {
                      let newQuestionOptions = [
                        ...values.selectedChecklist.attributes.sections.data[
                          indexSectionItem
                        ].attributes.questions.data[indexQuestionItem]
                          .attributes.question_options,
                      ];
                      if (
                        newQuestionOptions.includes(values.tempQuestionOption)
                      ) {
                        showToast({
                          type: "error",
                          message:
                            "option already present, add a different option.",
                        });
                        return;
                      }
                      newQuestionOptions.push(values.tempQuestionOption);
                      setFieldValue(
                        `selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.question_options`,
                        newQuestionOptions
                      );
                      setFieldValue("tempQuestionOption", "");
                    }}
                  >
                    + Add Option
                  </Button>
                </CustomGrid>
              </>
            ) : null}
          </>
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                style={{
                  padding: "0 1rem 0 0",
                  marginTop: "3px",
                }}
                disabled={
                  survey_status == "completed" ||
                  survey_status == "Enrolled" ||
                  survey_status == "Onboarding"
                }
                color="primary"
                name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.checked`}
                checked={
                  values.selectedChecklist.attributes.sections.data[
                    indexSectionItem
                  ].attributes.questions.data[indexQuestionItem].attributes
                    .checked
                }
                onChange={handleChange}
              />
            }
            classes={{
              label: classes.checkboxLabel,
            }}
            style={{
              alignItems: "flex-start",
              margin: 0,
            }}
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    questionItem.attributes.question_name,
                    { ADD_ATTR: ["target"] }
                  ),
                }}
              ></div>
            }
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.verticleMargin}>
        <Grid container justifyContent="space-between">
          <CustomGrid item xs={4} className={classes.capsuleContainer}>
            <Button
              variant="contained"
              color="primary"
              className={
                values.selectedChecklist.attributes.sections.data[
                  indexSectionItem
                ].attributes.questions.data[indexQuestionItem].attributes
                  .client_status === "Internal"
                  ? classes.capsuleButtonSelected
                  : classes.capsuleButton
              }
              disableElevation
              disableRipple
              disableFocusRipple
              disabled={
                survey_status == "completed" ||
                survey_status == "Enrolled" ||
                survey_status == "Onboarding"
              }
              disableTouchRipple
              onClick={() => {
                setFieldValue(
                  `selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.client_status`,
                  "Internal"
                );
              }}
            >
              Internal
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={
                values.selectedChecklist.attributes.sections.data[
                  indexSectionItem
                ].attributes.questions.data[indexQuestionItem].attributes
                  .client_status === "Client"
                  ? classes.capsuleButtonSelected
                  : classes.capsuleButton
              }
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              disabled={
                survey_status == "completed" ||
                survey_status == "Enrolled" ||
                survey_status == "Onboarding"
              }
              onClick={() => {
                setFieldValue(
                  `selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.client_status`,
                  "Client"
                );
              }}
            >
              Client
            </Button>
          </CustomGrid>
          <CustomGrid
            item
            xs={4}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <FastField>
              {({ field, form, meta }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        padding: "0 1rem 0 0",
                        marginTop: "3px",
                      }}
                      disabled={
                        survey_status == "completed" ||
                        survey_status == "Enrolled" ||
                        survey_status == "Onboarding"
                      }
                      color="primary"
                      name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.request_document`}
                      checked={
                        values.selectedChecklist.attributes.sections.data[
                          indexSectionItem
                        ].attributes.questions.data[indexQuestionItem]
                          .attributes.request_document
                      }
                      onChange={handleChange}
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                  style={{
                    alignItems: "flex-start",
                    margin: 0,
                  }}
                  label="Request Document?"
                />
              )}
            </FastField>
          </CustomGrid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.verticleMargin}>
        <FastField
          component={InputField}
          name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.document`}
          value={
            values.selectedChecklist.attributes.sections.data[indexSectionItem]
              .attributes.questions.data[indexQuestionItem].attributes.document
          }
          onChange={handleChange}
          fullWidth
          placeholder="Document.pdf"
          disabled
          InputProps={{
            endAdornment: values.selectedChecklist.attributes.sections.data[
              indexSectionItem
            ].attributes.questions.data[indexQuestionItem].attributes
              .document && (
              <>
                <VisibilityIcon
                  onClick={() => {
                    window.open(
                      values.selectedChecklist.attributes.sections.data[
                        indexSectionItem
                      ].attributes.questions.data[indexQuestionItem].attributes
                        .document,
                      "_target"
                    );
                  }}
                  className={classes.eyeIcon}
                />
                <img
                  src={checkMarkPhaseTwo}
                  className={classes.inputFieldAdornments}
                />
              </>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.verticleMargin}>
        <CustomTypography color="textPrimary" className={classes.fieldLabelTop}>
          Current Gathered Details
        </CustomTypography>
        {renderInputFieldByType(
          values.selectedChecklist.attributes.sections.data[indexSectionItem]
            .attributes.questions.data[indexQuestionItem].attributes,
          classes,
          handleChange,
          `selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data[${indexQuestionItem}].attributes.current_gathered_details`
        )}
      </Grid>
    </Grid>
  );
};

const Section = ({
  classes,
  values,
  handleChange,
  sectionItem,
  indexSectionItem,
  setFieldValue,
  sectionDroppableProvided,
  survey_status,
  questions
  
}) => {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        className={[classes.whiteCard, classes.sectionCard]}
        ref={sectionDroppableProvided.innerRef}
        {...sectionDroppableProvided.droppableProps}
      >
        <CustomGrid xs={12}>
          {sectionItem.isAdditionalSection ? (
            <FastField
              placeholder="Enter Section Name"
              component={InputField}
              name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.section_name`}
              value={
                values.selectedChecklist.attributes.sections.data[
                  indexSectionItem
                ].attributes.section_name
              }
              onChange={handleChange}
              fullWidth
            />
          ) : (
            <CustomTypography
              color="primary"
              fontSize="2rem"
              fontWeight="bold"
              padding="0 0 0 1rem"
            >
              {sectionItem.attributes.section_name}
            </CustomTypography>
          )}
        </CustomGrid>
        <CustomGrid xs={12}>
          <CustomTypography
            fontSize="1.3rem"
            fontWeight="bold"
            padding="0 0 0 1rem"
          >
            Client Questions
          </CustomTypography>
        </CustomGrid>

        <FieldArray
          name={`selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data`}
        >
          {(fieldArrayProps) => {
            const { form, push } = fieldArrayProps;
            const { values } = form;

            return (
              questions.map(
                (questionItem, indexQuestionItem) => {
                  return (
                    <>
                      <Draggable
                        draggableId={`question-${indexQuestionItem}`}
                        index={indexQuestionItem}
                        key={questionItem.attributes.id}
                      >
                        {(provided) => (
                          <Question
                            survey_status={survey_status}
                            questionItem={questionItem}
                            indexQuestionItem={indexQuestionItem}
                            sectionItem={sectionItem}
                            indexSectionItem={indexSectionItem}
                            fieldArrayProps={fieldArrayProps}
                            classes={classes}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            questionDraggableProvided={provided}
                          />
                        )}
                      </Draggable>

                      {indexQuestionItem ===
                      values.selectedChecklist.attributes.sections.data[
                        indexSectionItem
                      ].attributes.questions.data.length -
                        1 ? (
                        <Grid item xs={12} container justifyContent="center">
                          <Button
                            color="primary"
                            disabled={
                              survey_status == "completed" ||
                              survey_status == "Enrolled" ||
                              survey_status == "Onboarding"
                            }
                            onClick={() =>
                              push({
                                id: uuid(),
                                isAdditionalQuestion: true,
                                type: "question",
                                attributes: {
                                  id: uuid(),
                                  question_name: "",
                                  checked: true,
                                  client_status: "Client",
                                  request_document: false,
                                  status: "Requested/Submitted",
                                  current_gathered_details: "",
                                  question_rank: indexQuestionItem + 1,
                                  document: null,
                                  question_options: [],
                                  question_type: "Select",
                                },
                              })
                            }
                          >
                            + Add Question
                          </Button>
                        </Grid>
                      ) : null}
                    </>
                  );
                }
              )
            )
            // values?.selectedChecklist.attributes.sections.data[
            //   indexSectionItem
            // ]?.attributes?.questions?.data ?
            
             
            // : (
            //   <Typography>No Questions Available</Typography>
            // );
          }}
        </FieldArray>
        {sectionDroppableProvided.placeholder}
      </Grid>
    </>
  );
};

export class OnboardingCustomization extends OnboardingCustomizationController {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const client_id = await StorageProvider.get("clientId");
    const package_management_id = await StorageProvider.get(
      "package_management_id"
    );
    const survey_status = this.props.history.location.state.survey_status;
    const surveyId = this.props.history.location.state.surveyId;
    const road_customization_id = this.props.history.location.state
      .road_customization_id;
    const custom_package_management_id = this.props.history.location.state
      .custom_package_management_id;
    const allow_to_change_package_id = this.props.history.location.state
      .allow_to_change_package_id;
    const selected_payment_term = this.props.history.location.state
      .selected_payment_term;
    const parent_package_type = this.props.history.location.state
      .parent_package_type;
    if (token && accountId && client_id) {
      this.setState(
        {
          token: token,
          accountId: accountId,
          client_id: client_id,
          package_management_id: package_management_id,
          surveyId: surveyId,
          allow_to_change_package_id: allow_to_change_package_id,
          survey_status: survey_status,
          road_customization_id: road_customization_id,
          custom_package_management_id: custom_package_management_id,
          selected_payment_term: selected_payment_term,
          parent_package_type: parent_package_type,
        },
        () => {
          if (
            !this.props.permissions.includes(
              "Access Survey Responses-Client Onboarding Survey"
            )
          ) {
            this.props.showToast({
              type: "error",
              message: "AccessDenied for Current User",
            });
            this.props.history.push("/home/clientsList/details/overview", {
              clientId: client_id,
            });
          }
          if (!package_management_id && !custom_package_management_id) {
            this.props.showToast({
              type: "error",
              message: "please select a package first",
            });
            this.props.history.push("/home/clientsList/details/overview", {
              clientId: client_id,
            });
          } else {
            this.getSingleClient();
          }
        }
      );
    }
  }

  openImageUploadDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          package: data,
        },
        width: "60%",
        renderedComponent: SingleClientImageUpload,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.props.showToast({
            type: "success",
            message: "Image updated succssfully",
          });
        }
      });
  };

  renderSectionSideBar = (classes) => {
    return (
      <CustomGrid item width="20%">
        <Grid container>
          <CustomGrid item width="92%" className={classes.mainChecklist}>
            <Typography className={classes.selectedChecklistName}>
              {this.state.selectedChecklist.attributes.checklist_name}
            </Typography>
            {this.state.selectedChecklist.attributes.sections.data.map(
              (sectionItem) => (
                <Typography
                  key={sectionItem?.id}
                  className={classes.checklistOptions}
                >
                  {sectionItem.attributes.section_name}
                </Typography>
              )
            )}
          </CustomGrid>
        </Grid>
      </CustomGrid>
    );
  };
  onDragEnd = (result, indexSectionItem, values, setFieldValue) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newQuestionsList = [
      ...values.selectedChecklist.attributes.sections.data[indexSectionItem]
        .attributes.questions.data,
    ];
    newQuestionsList.splice(source.index, 1);
    newQuestionsList.splice(
      destination.index,
      0,
      values.selectedChecklist.attributes.sections.data[indexSectionItem]
        .attributes.questions.data[source.index]
    );
    const rankUpdatedQuestionsList = newQuestionsList.map((item, index) => {
      return {
        attributes: {
          ...item.attributes,
          question_rank: index,
        },
        id: item.id,
        type: item.type,
      };
    });
    setFieldValue(
      `selectedChecklist.attributes.sections.data[${indexSectionItem}].attributes.questions.data`,
      rankUpdatedQuestionsList
    );
  };

  copyToClipboard = (textToCopy) => {
    window.navigator.clipboard?.writeText(textToCopy);
  };

  handleChecklistChange = (handleChange, event) => {
    if (event.target.value === "manage-checklists") {
      event.preventDefault();
      this.props.history.push("/home/settings/manageDeliveryChecklist");
      return;
    }
    handleChange(event);
    this.setState({
      selectedChecklist: event.target.value,
    });
  };
  goToCreateChecklist = () => {
    this.props.history.push(
      "/home/settings/createOnboardingChecklist"
    );
  }
  addSection = (indexSectionItem,push) =>
  push({
    id: uuid(),
    isAdditionalSection: true,
    type: "section",
    attributes: {
      id: uuid(),
      section_name: "",
      section_rank: indexSectionItem + 1,
      questions: {
        data: [
          {
            id: uuid(),
            isAdditionalQuestion: true,
            type: "question",
            attributes: {
              id: uuid(),
              question_name: "",
              checked: false,
              client_status: "Client",
              request_document: true,
              status:
                "Requested/Submitted",
              current_gathered_details:
                "",
              question_rank: null,
              document: null,
              question_options: ["Yes","No"],
              question_type: "radio",
            },
          },
        ],
      },
    },
  })


  render() {
    const { classes, permissions, theme } = this.props;
    return  (
      <Formik
        initialValues={{
          selectedChecklist: this.state.selectedChecklist,
          tempQuestionOption: "",
          obs_link: this.state.obsUrl,
          client_portal_link: "https://app.mytaxdashboard.com",
        }}
        enableReinitialize
        onSubmit={(values) => {
          this.updateChecklist(values);
        }}
      >
        {(formikProps) => {
          const { handleChange, setFieldValue, values } = formikProps;
          
          return (
            // this.state.checklists?.length > 0 ? null : (
  //     <Grid
  //     container
  //     className={[classes.gridSpacing, classes.whiteCard]}
  //     justifyContent="center"
  //     alignItems="center"
  //   >
  //     <Grid xs={10} item>
  //       <Typography color="textSecondary" align="center" variant="h1">
  //         No Checklist available for selected package
  //       </Typography>
  //     </Grid>
  //   </Grid>
  // )
            <Form autoComplete="off" noValidate>
            <Grid container className={classes.gridSpacing}>
              <CustomGrid
                item
                xs={12}
                className={[classes.alignCenter, classes.surveyLinkContainer]}
              >
                <CustomTypography
                  padding="0rem 0.5rem 0px 0rem"
                  fontSize="1.3158rem"
                  fontWeight={700}
                  otherColor={theme.palette.text.blue2}
                  display="inline"
                >
                  Link to Onboarding Page:
                </CustomTypography>
              </CustomGrid>
              <CustomGrid
                margin="0 0 1rem 0"
                item
                width="50%"
                container
                alignItems="center"
              >
                <FastField
                  style={{ borderRadius: "50%" }}
                  component={InputField}
                  name="obs_link"
                  placeholder="Link"
                  disabled
                  value={values.obs_link}
                  fullWidth
                  className={classes.surveyLinkField}
                />
              </CustomGrid>
              <CustomGrid
                margin="0 0 1rem 1rem"
                width="10%"
                item
                xs={2}
                container
                alignItems="center"
              >
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<FileCopyIcon />}
                  color="primary"
                  onClick={this.copyToClipboard.bind(this, this.state.obsUrl)}
                >
                  Copy
                </Button>
              </CustomGrid>
              <Grid
                item
                xs={12}
                className={[classes.alignCenter, classes.surveyLinkContainer]}
              >
                <CustomTypography
                  padding="0rem 0.5rem 0px 0rem"
                  fontSize="1.3158rem"
                  fontWeight={700}
                  otherColor={theme.palette.text.blue2}
                  display="inline"
                >
                  Client Dashboard:
                </CustomTypography>
              </Grid>
              <CustomGrid item width="50%" container alignItems="center">
                <FastField
                  style={{ borderRadius: "50%" }}
                  component={InputField}
                  name="client_portal_link"
                  placeholder="Link"
                  disabled
                  value={values.client_portal_link}
                  fullWidth
                  className={classes.surveyLinkField}
                />
              </CustomGrid>
              <CustomGrid
                margin="0 0 0 1rem"
                width="10%"
                item
                xs={2}
                container
                alignItems="center"
              >
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<FileCopyIcon />}
                  color="primary"
                  id='copyToClipboard'
                  onClick={this.copyToClipboard.bind(
                    this,
                    values.client_portal_link
                  )}
                >
                  Copy
                </Button>
              </CustomGrid>
              <CustomGrid
                margin="1.75rem 0"
                width="90%"
                xs={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={4}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomGrid item width="65%" container alignItems="center">
                    <FastField
                      component={WhiteFieldWeb}
                      fullWidth
                      select
                      value={values.selectedChecklist}
                      name="selectedChecklist"
                      id='handleChecklistChange'
                      onChange={this.handleChecklistChange.bind(
                        this,
                        handleChange
                      )}
                    >
                      {this.state.checklists.map((opt) => {
                        return (
                          <SmallMenuItem key={opt.attributes.id} value={opt}>
                            {opt.attributes.checklist_name}
                            <TernaryCheck
                              condition={
                                opt.id === this.state.selectedChecklist.id
                              }
                              trueComponent={
                                <CheckCircleIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              }
                              falseComponent={
                                <RadioButtonUncheckedIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              }
                            />
                          </SmallMenuItem>
                        );
                      })}
                      <ButtonMenuItem value={"manage-checklists"}>
                        Manage Checklists
                      </ButtonMenuItem>
                    </FastField>
                  </CustomGrid>
                  <CustomGrid
                    width="30%"
                    item
                    xs={2}
                    container
                    alignItems="center"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={this.goToCreateChecklist}
                    >
                      + Add
                    </Button>
                  </CustomGrid>
                </Grid>
                <TernaryCheck
                  condition={
                    !permissions.includes(
                      "Send Survey-Client Onboarding Survey"
                    )
                  }
                  trueComponent={
                    <Button variant="contained" disabled color="primary">
                      Ship Onboarding Survey
                    </Button>
                  }
                  falseComponent={
                    <Button
                      variant="contained"
                      disabled={
                        this.state.survey_status == "completed" ||
                        this.state.survey_status == "Enrolled" ||
                        this.state.survey_status == "Onboarding"
                      }
                      id='sendOnBoardingSurvey'
                      onClick={this.sendOnBoardingSurvey.bind(this)}
                      color="primary"
                    >
                      Ship Onboarding Survey
                    </Button>
                  }
                />
              </CustomGrid>

              {this.renderSectionSideBar(classes)}

              <CustomGrid item width="70%">
                <FieldArray name="selectedChecklist.attributes.sections.data">
                  {(fieldArrayProps) => {
                    const { form, push } = fieldArrayProps;
                    const { values } = form;
                    return (
                      values?.selectedChecklist?.attributes?.sections?.data.length > 0 &&
                      values.selectedChecklist.attributes.sections.data?.map(
                        (sectionItem, indexSectionItem) => {
                          return (
                            <>
                              <DragDropContext
                                onDragEnd={(result) =>
                                  this.onDragEnd(
                                    result,
                                    indexSectionItem,
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <Droppable
                                  droppableId={`section-${indexSectionItem}`}
                                >
                                  {(provided) => {
                                    return (
                                      <Section
                                        survey_status={
                                          this.state.survey_status
                                        }
                                        key={sectionItem.attributes.id}
                                        sectionItem={sectionItem}
                                        indexSectionItem={indexSectionItem}
                                        fieldArrayProps={fieldArrayProps}
                                        classes={classes}
                                        values={values}
                                        questions={values.selectedChecklist.attributes.sections.data[
                                          indexSectionItem
                                        ]?.attributes?.questions?.data.length > 0 ? values.selectedChecklist.attributes.sections.data[
                                          indexSectionItem
                                        ].attributes.questions.data : [] }
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        sectionDroppableProvided={provided}
                                      />
                                    );
                                  }}
                                </Droppable>
                              </DragDropContext>

                              {indexSectionItem ===
                              values?.selectedChecklist?.attributes?.sections
                                ?.data.length -
                                1 ? (
                                <CustomGrid
                                  xs={12}
                                  container
                                  width="100%"
                                  margin="1.5rem 0"
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      this.state.survey_status ==
                                        "completed" ||
                                      this.state.survey_status ==
                                        "Enrolled" ||
                                      this.state.survey_status == "Onboarding"
                                    }
                                    fullWidth
                                    id="addSection"
                                    onClick={this.addSection.bind(this,indexSectionItem,push)}
                                  >
                                    + Add Section
                                  </Button>
                                </CustomGrid>
                              ) : null}
                            </>
                          );
                        }
                      )
                    );
                  }}
                </FieldArray>
              </CustomGrid>

              <CustomGrid
                xs={12}
                container
                width="70%"
                className={[classes.whiteCard, classes.bottomGrid]}
                alignItems="center"
              >
                <CustomGrid xs={7}>
                  <CustomTypography
                    fontSize="1.1rem"
                    fontWeight="bold"
                    color="textSecondary"
                  >
                    Last edited by:{" "}
                    <CustomTypography
                      fontSize="1.1rem"
                      fontWeight="bold"
                      display="inline"
                      color="primary"
                    >
                      {
                        this.state.selectedChecklist.attributes
                          ?.last_edited_by
                      }
                    </CustomTypography>
                  </CustomTypography>
                  <CustomTypography
                    fontSize="1.1rem"
                    fontWeight="bold"
                    color="textSecondary"
                  >
                    Last edited on:{" "}
                    <CustomTypography
                      fontSize="1.1rem"
                      fontWeight="bold"
                      display="inline"
                      color="primary"
                    >
                      {
                        this.state.selectedChecklist.attributes
                          ?.last_edited_on
                      }
                    </CustomTypography>
                  </CustomTypography>
                </CustomGrid>
                <CustomGrid xs={5} container justifyContent="flex-end">
                  <CustomGrid width="48%">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                      id="updateChecklist"
                      disabled={
                        this.state.client_id === null
                      }
                    >
                      Apply Changes
                    </Button>
                  </CustomGrid>
                </CustomGrid>
              </CustomGrid>
            </Grid>
          </Form> 
            
          );
        }}
      </Formik>
    ) 
    
    
  }
}

const OnboardingCustomizationWithRouter = withRouter(OnboardingCustomization);
const OnboardingCustomizationAlertBox = withAlertBox(
  OnboardingCustomizationWithRouter
);
const OnboardingCustomizationLoader = withLoader(
  OnboardingCustomizationAlertBox
);
const OnboardingCustomizationToast = withToast(OnboardingCustomizationLoader);
const OnboardingCustomizationWithDialog = withDialog(
  OnboardingCustomizationToast
);
const OnboardingCustomizationWithConfirmBox = withConfirmBox(
  OnboardingCustomizationWithDialog
);
const OnboardingCustomizationWithPermissions = withPermissions(
  OnboardingCustomizationWithConfirmBox
);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(3, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: "1.2rem 1.5rem",
        borderRadius: "1rem",
        backgroundColor: "#ffffff",
        boxShadow: theme.shadows[25],
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      sectionCard: {
        marginBottom: "1rem",
      },
      mainChecklist: {
        position: "relative",
        padding: "1.2rem",
        borderRadius: "1.5rem",
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
        border: `2px solid ${theme.palette.common.white}`,
        marginRight: "8%",
        marginBottom: "1rem",
        cursor: "pointer",
      },
      selectedMainChecklist: {
        position: "relative",
        padding: "1.2rem",
        borderRadius: "1.5rem",
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
        border: `2px solid ${theme.palette.primary.main}`,
        marginRight: "8%",
        marginBottom: "1rem",
        cursor: "pointer",
      },
      checklistName: {
        fontSize: "1.1rem",
        color: theme.palette.text.secondary,
      },
      selectedChecklistName: {
        fontSize: "1.1rem",
        fontWeight: 700,
        color: theme.palette.text.primary,
      },
      checklistOptions: {
        fontSize: "1rem",
        fontWeight: 700,
        color: theme.palette.text.secondary,
        position: "relative",
        margin: "0.5rem 0px",
        marginLeft: "2rem",
        display: "block",
        "&:before": {
          content: "''",
          width: "0.9375rem",
          height: "0.9375rem",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          top: "0.25rem",
          left: "-1.5rem",
          display: "block",
          borderRadius: "50%",
        },
      },
      checkmarkImage: {
        position: "absolute",
        right: "2%",
        top: "7%",
        height: "1.5625rem",
        width: "1.5625rem",
      },
      lightGreyContainer: {
        position: "relative",
        cursor: "auto!important",
        backgroundColor: "#f8f8f8",
        borderRadius: "1rem",
        padding: "1.5rem 4rem",
        margin: "1rem 0",
      },
      dragIcon: {
        position: "absolute",
        left: "1%",
        top: "1.5rem",
        color: theme.palette.text.secondary,
        fontSize: "2rem",
        cursor: "pointer",
      },
      moreIconInsideContainer: {
        position: "absolute",
        right: "2.5%",
        top: "2rem",
        color: theme.palette.text.secondary,
        height: "1.5rem",
        width: "1.5rem",
        cursor: "pointer",
      },
      checkboxLabel: {
        fontSize: "1.3rem",
        color: theme.palette.text.secondary,
      },
      verticleMargin: {
        margin: "1rem 0",
      },
      capsuleContainer: {
        backgroundColor: "#EAE8E9",
        borderRadius: "2.5rem",
      },
      capsuleButton: {
        width: "50%",
        borderRadius: "2.5rem",
        backgroundColor: "#EAE8E9",
        color: theme.palette.text.secondary,
        "&:hover": {
          backgroundColor: "#EAE8E9",
        },
      },
      capsuleButtonSelected: {
        width: "50%",
        borderRadius: "2.5rem",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      fieldLabelTop: {
        fontFamily: "Nunito Sans",
        fontSize: "1rem",
        fontWeight: 600,
        marginLeft: "1rem",
        marginBottom: "0.5rem",
      },
      bottomGrid: {
        marginLeft: "20%",
      },
      inputFieldAdornments: {
        height: "1.5625rem",
        width: "1.5625rem",
        cursor: "pointer",
      },
      eyeIcon: {
        fontSize: "1.8rem",
        cursor: "pointer",
        marginRight: "0.5rem",
      },
      closeIcon: {
        cursor: "pointer",
      },
      possibleAnswersWhiteCard: {
        backgroundColor: "#EAE8E9",
        borderRadius: "0.75rem",
        padding: "1rem",
        marginTop: "1rem",
      },
    }),
  { withTheme: true }
)(OnboardingCustomizationWithPermissions);
