//@ts-nocheck
import React from "react";
import SetNewPasswordController, { Props } from "./SetNewPasswordController.web";
import { withStyles, Grid, Typography, createStyles, Button, List, ListItem, ListItemText } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Redirect } from "react-router-dom";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { ForgotPasswordContainer } from "./ForgotPasswordOTP.web";
import { authFlowStyles } from "./Login.web";

const Schema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Invalid Password")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/, "Invalid Password")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
  confirmPassword: Yup.string()
    .min(8, "Invalid Password")
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/, "Invalid Password")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

export class SetNewPassword extends SetNewPasswordController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return this.state.token ? (
      <Redirect to="/home" />
    ) : (
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          this.setNewPassword(values);
        }}
      >
        {(formikProps) => {
          const { isValid, dirty } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <ForgotPasswordContainer {...formikProps} classes={classes} title={"Reset Password?"}>
                <Grid item xs={10}>
                  <Field type="password" component={InputField} variant="outlined" fullWidth name="password" placeholder="New Password" />
                </Grid>

                <Grid item xs={10}>
                  <Field type="password" component={InputField} variant="outlined" fullWidth name="confirmPassword" placeholder="Password" />
                </Grid>

                <Grid item xs={10}>
                  <Typography className={classes.qLabel} color="textSecondary">
                    Must contain all of the followings
                  </Typography>
                  <List dense={true} style={{ paddingLeft: "32px" }}>
                    <ListItem style={{ position: "relative" }}>
                      <ListItemText
                        primary="Min 8 characters"
                        classes={{
                          primary: classes.listItemPrimary,
                        }}
                      />
                    </ListItem>
                    <ListItem style={{ position: "relative" }}>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                        }}
                        primary="One uppercase and lowercase character"
                      />
                    </ListItem>
                    <ListItem style={{ position: "relative" }}>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                        }}
                        primary="One special character"
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={10}>
                  <Button color="primary" type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)}>
                    Reset Password
                  </Button>
                </Grid>
              </ForgotPasswordContainer>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const SetNewPasswordWithRouter = withRouter(SetNewPassword);
const SetNewPasswordWithToast = withToast(SetNewPasswordWithRouter);
const SetNewPasswordWithLoader = withLoader(SetNewPasswordWithToast);
const SetNewPasswordWithAlertBox = withAlertBox(SetNewPasswordWithLoader);
const SetNewPasswordWithDialogBox = withDialog(SetNewPasswordWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
    listItemPrimary: {
      fontSize: "0.9375rem",
      fontFamily: "Nunito Sans",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#666769",
      paddingLeft: theme.spacing(4),
      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "40%",
        width: "10px",
        height: "10px",
        display: "block",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
      },
    },
  })
)(SetNewPasswordWithDialogBox);
