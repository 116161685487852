import React, { Component } from "react";

declare global {
  interface Window {
    Intercom: any;
  }
}

class IntercomStep extends Component {
  affiniPayScript: HTMLScriptElement | null = null;
  intercomScript: HTMLScriptElement | null = null;
  localStorageEventHandler: ((event: StorageEvent) => void) | null = null;

  componentDidMount() {
    this.loadScripts();
    this.setupLocalStorageListener();
  }

  componentWillUnmount() {
    this.cleanupScripts();
  }

  loadScripts() {
    // Load AffiniPay script
    this.affiniPayScript = document.createElement("script");
    this.affiniPayScript.src =
      "https://cdn.affinipay.com/hostedfields/1.1.1/fieldGen_1.1.1.js";
    this.affiniPayScript.integrity =
      "sha384-eHBjEP83qHo1WozZ8XhhBRpSoko3Ub+dHGYK4A1KEMkGhd23Byo67vB2+P6Q9JQZ";
    this.affiniPayScript.crossOrigin = "anonymous";
    document.head.appendChild(this.affiniPayScript);

    this.updateIntercom();
  }

  setupLocalStorageListener() {
    const localStorageEventHandler = (event: StorageEvent) => {
      if (event.key === "currentUser") {
        this.updateIntercom();
      }
    };

    window.addEventListener("storage", localStorageEventHandler);
    this.localStorageEventHandler = localStorageEventHandler;
  }

  cleanupScripts() {
    if (this.affiniPayScript) {
      this.affiniPayScript.remove();
    }
    if (this.intercomScript) {
      this.intercomScript.remove();
    }

    if (this.localStorageEventHandler) {
      window.removeEventListener("storage", this.localStorageEventHandler);
    }
  }

  updateIntercom() {
    const currentUserData = localStorage.getItem("currentUser");

    if (currentUserData) {
      const currentUser = JSON.parse(currentUserData);
      const intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "bqdg29a4", // Replace with your Intercom app_id
        name: currentUser?.data?.attributes?.first_name,
        email: currentUser?.data?.attributes?.primary_email,
        created_at: currentUser?.data?.attributes?.created_at || null,
      };

      // Reset Intercom functionality
      if (window.Intercom) {
        window.Intercom("reattach_activator");
      }

      // Remove the old Intercom script
      if (this.intercomScript) {
        this.intercomScript.remove();
      }

      // Load the updated Intercom script
      this.intercomScript = document.createElement("script");
      this.intercomScript.textContent = `
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', ${JSON.stringify(intercomSettings)});
          } else {
            var d = document;
            var i = function () { i.c(arguments); };
            i.q = []; i.c = function (args) { i.q.push(args); };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/bqdg29a4'; // Replace with your Intercom app_id
              var x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
              l();
            } else if (w.attachEvent) {
              w.attachEvent('onload', l);
            } else {
              w.addEventListener('load', l, false);
            }
          }
        
        })();
      `;
      if (document.head && this.intercomScript instanceof Node) {
        document.head.appendChild(this.intercomScript);
      }
    }
  }

  render() {
    return (
      <div>
        {/* Your JSX content */}
      </div>
    );
  }
}

export default IntercomStep;
