//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Box, Divider } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Redirect } from "react-router-dom";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import CloseIcon from "@material-ui/icons/Close";
import { Props } from "../FirmFinancialsNew.web";
import { authFlowStyles } from "./Login.web";
import TernaryCheck from "../TernaryCheck.web";

export class TermsAndConditions extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      tokenTermsAndConditions: null,
    };
  }

  async componentDidMount() {
    const tokenTermsAndConditions = await StorageProvider.get("authToken");
    if (tokenTermsAndConditions) {
      this.setState({
        tokenTermsAndConditions: tokenTermsAndConditions,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <TernaryCheck
        condition={this.state.tokenTermsAndConditions}
        trueComponent={<Redirect to="/home" />}
        falseComponent={
          <Box className={classes.rootBox}>
            <Grid container justifyContent="center" className={classes.rootContainer}>
              <Grid
                item
                xs={12}
                className={classes.crossButton}
                onClick={() => {
                  this.props.history.push({
                    pathname: "signup",
                    state: {
                      values: this.props.history.location?.state?.values,
                    },
                  });
                }}
              >
                <CloseIcon
                  style={{
                    fontSize: "1rem",
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.secondContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2" align="center" color="primary" className={[classes.bottomMargin]} id="page-heading-termsAndConditions">
                      SmartPath.co
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" align="center" color="textSecondary" className={[classes.bottomMargin]}>
                      Terms of Service Agreement
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={[classes.bottomMargin, classes.topMargin, classes.divider]} />
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography color="textSecondary" gutterBottom className={classes.headerText}>
                      By enrolling in SmartPath you agree to the terms below.
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={classes.headerText}>
                      These terms are designed to benefit us both, so everyone in the SmartPath community can experience the best possible results.
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={classes.headerText}>
                      We've written these terms to be straightforward and easy to understand.
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={classes.headerText}>
                      If you have any questions, please feel free to contact us at - (800) 926-5905
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="primary" className={[classes.primaryColorHeading]} gutterBottom>
                      1 | Payment
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      You must make your Monthly Access payment to access the SmartPath.co training, implementation support, tools, and portal. Your
                      subscription may also include an initial "Enrollment Fee". This feel will be displayed at the time you enroll. The amount of your
                      Enrollment Fee and/or Monthly Access Fee, will be based on the subscription level you choose.
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={[classes.subHeading]}>
                      One-Time Enrollment Payment
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bodyText, classes.bottomMargin]}>
                      Your one-time enrollment Fee is indicated on the checkout page when enrolling in a SmartPath.co Subscription. Your one-time Enrollment
                      payment is non-refundable *EXCEPT* for under the terms of our Refund Guarantee policy outlined below.
                    </Typography>

                    <Typography color="textSecondary" gutterBottom className={[classes.subHeading]}>
                      Monthly Continued Access
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bodyText, classes.bottomMargin]}>
                      If you would like to continue having access to SmartPath.co, any data stored within, and Support, you will pay a monthly fee indicated on
                      the checkout page when you enroll. You can cancel your continued access payment at any time by contacting us at Support@SmartPath.co or by
                      any contact method listed on our website. Monthly Continued Access Fees are completely{" "}
                      <b>
                        <i>non-refundable</i>
                      </b>{" "}
                      (whole or partial).
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="primary" className={[classes.primaryColorHeading]} gutterBottom>
                      2 | What Your Payment Gets You
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      Your payment grants you active status as a SmartPath.co Subscriber. As an active Subscriber, you gain access to specialized training,
                      resources, & the defined level of support based on your subscription level.
                    </Typography>
                    <Box style={{ paddingLeft: "24px", position: "relative" }}>
                      <Typography className={[classes.listItemPrimary]}>
                        <b>Access</b> | Access to the SmartPath.co software for as long as you are paying your continued access fee.
                      </Typography>
                    </Box>
                    <Box style={{ paddingLeft: "24px", position: "relative" }}>
                      <Typography className={[classes.listItemPrimary]}>
                        <b>Support</b> | If your chosen membership level includes Implementation Support, you'll receive SmartPath implementation calls &
                        support to help you implement the SmartPath.co Software and training concepts.
                      </Typography>
                    </Box>
                    <Box style={{ paddingLeft: "24px", position: "relative" }}>
                      <Typography className={[classes.listItemPrimary]}>
                        <b>Resources</b> | Licensed access to the SmartPath.co resources to customize our proprietary tools & templates for use in your firm as
                        long as your membership is in an "active" status.
                      </Typography>
                    </Box>
                    <Typography color="textSecondary" className={[classes.bodyText, classes.bottomMargin]}>
                      We reserve the right to edit, change, and provide substitute components as part of your SmartPath subscription at any time. Any changes
                      made will be designed around the best interest of SmartPath Subscribers and will be focused on helping you achieve the best possible
                      results in your practice.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="primary" className={[classes.primaryColorHeading]} gutterBottom>
                      3 | Refund Guarantee
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      We proudly stand behind the potential results you can achieve by utilizing the training, resources, and support accessed through your
                      membership.
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      We want you to be successful and we will do everything in our power to help you achieve your goals.
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      If you enroll in our Accelerator level subscription, follow the SmartPath training process, implement the resources as prescribed for a{" "}
                      <u>
                        <b>
                          <i>minimum of 90 days</i>
                        </b>
                      </u>
                      , complete the following requirements outlined below, and are not satisfied with your results, you will be eligible to receive a full
                      refund of your ACCELERATOR enrollment fee. The refund policy is NOT applicable to any other membership levels. Enrollment fee refunds are
                      processed within 4-8 weeks of verifying eligibility.
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={[classes.subHeading]}>
                      Refund Guarantee Eligibility Requirements
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={[classes.bodyText]}>
                      To qualify for a refund, you must complete the following 4 steps:
                    </Typography>
                    <Box style={{ paddingLeft: "24px", position: "relative" }}>
                      <Typography component={"pre"} gutterBottom className={[classes.bodyText]}>
                        1. Attain a 100% completion rate on all on-demand study modules inside the SmartPath portal.
                      </Typography>
                      <Typography component={"pre"} gutterBottom className={[classes.bodyText]}>
                        2. Successfully complete all on-demand tests with a passing grade (if applicable to your membership level).
                      </Typography>
                      <Typography component={"pre"} gutterBottom className={[classes.bodyText, classes.bottomMargin]}>
                        3. Follow all implementation instructions for a minimum of 90 days after your completion date of the on-demand curriculum.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="primary" className={[classes.primaryColorHeading]} gutterBottom>
                      4 | Redistribution, Intellectual Property, & Non-Disclosure
                    </Typography>
                    <Typography color="textSecondary" gutterBottom className={[classes.subHeading]}>
                      Redistribution
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      A. By enrolling in SmartPath.co Subscription you agree to{" "}
                      <u>
                        <i>
                          <b>NOT</b>
                        </i>
                      </u>{" "}
                      re-distribute, provide, or sell any SmartPath.co components, materials, or concepts to any outside third party. This includes physical &
                      digital distribution by any means - social media, email, digital copies, screen sharing, or otherwise.
                    </Typography>

                    <Typography color="textSecondary" gutterBottom className={[classes.subHeading]}>
                      Intellectual Property
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      B. All SmartPath.co education, training materials, marketing materials, business methods & concepts, templates, and tools are the sole
                      property of SMARTPATH.CO LLC. ALL RIGHTS RESERVED.
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      C. As an active SmartPath Subscriber, you are receiving a{" "}
                      <u>
                        <b>limited license</b>
                      </u>{" "}
                      to customize and use all SmartPath.co components for your individual business use ONLY.
                    </Typography>

                    <Typography color="textSecondary" gutterBottom className={[classes.subHeading]}>
                      Non-Disclosure
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      D. As part of the SmartPath Process, we may learn, obtain, or view non-public information about you, your business, and your employees. We
                      promise not to disclose this information to any outside third party.
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      E. We{" "}
                      <u>
                        <b>
                          <i>do</i>
                        </b>
                      </u>{" "}
                      reserve the right to use & disclose reasonable details about your SmartPath.co enrollment and results for promotional purposes. These
                      details will not include any private or sensitive information.
                    </Typography>

                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      F. If you obtain or view any private, sensitive, or proprietary information about another SmartPath.co Subscriber at any time while being
                      enrolled in SmartPath, you agree to not disclose this information to any outside third party.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="primary" className={[classes.primaryColorHeading]} gutterBottom>
                      5 | Liability Limits & Indemnification
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      SmartPath.co provides access to software and training. SmartPath.co LLC or any of its' subsidiaries, agents, or employees, CAN NOT and
                      WILL NOT take responsibility for the actions or inactions of our members or the potential liability of those actions or in-actions.
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      This includes and is not limited to ANY licensing requirements with any federal, state, or local licensing agency, or financial,
                      strategic, or consulting advice given to the client's SmartPath.co Subscribers.
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      You explicitly acknowledge and agree that the total liability of SmartPath.co LLC, its' employees & agents, are limited solely to the
                      total amount of your initial enrollment fee, based on the eligibility requirements of our refund policy.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="primary" className={[classes.primaryColorHeading]} gutterBottom>
                      6 | Partners
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      Through the use of the SmartPath.co software & training, you may see tools and services provided by outside third-party companies. If you
                      choose to do so, we may be compensated. You may be charged for use of their products/services. Any charges incurred through the use of the
                      SmartPath.co Software will be displayed on the checkout screen at the time of your subscription. Any partner we work with has been
                      strategically chosen because of the value they provide to our community. We reserve the right to use, stop using, or change any
                      third-party company at our sole discretion.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color="textSecondary" className={[classes.subHeading]} gutterBottom>
                      "I Acknowledge & Agree"
                    </Typography>
                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      By starting my free trial or paid subscription, I fully understand, acknowledge, and explicitly agree to the terms of this agreement as
                      stated above.
                    </Typography>

                    <Typography color="textSecondary" className={[classes.bottomMargin, classes.bodyText]}>
                      Support@smartpath.co | 800-926-5905
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
}

const TermsAndConditionsWithRouter = withRouter(TermsAndConditions);
const TermsAndConditionsWithToast = withToast(TermsAndConditionsWithRouter);
const TermsAndConditionsWithLoader = withLoader(TermsAndConditionsWithToast);
const TermsAndConditionsWithAlertBox = withAlertBox(TermsAndConditionsWithLoader);
const TermsAndConditionsWithDialogBox = withDialog(TermsAndConditionsWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
    listItemPrimary: {
      fontSize: "1rem",
      lineHeight: 1.38,
      letterSpacing: "0.2px",
      fontFamily: "Nunito Sans",
      color: theme.palette.text.secondary,
      paddingBottom: "10px",
      paddingLeft: theme.spacing(4),
      "&:before": {
        content: '""',
        position: "absolute",
        left: "20px",
        top: "7px",
        width: "10px",
        height: "10px",
        display: "block",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
      },
    },
  })
)(TermsAndConditionsWithDialogBox);
