import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { withToastProps } from "../../../components/src/withSnackBar.Web";

export type UploadBoxProps = withToastProps & {
  onSelectFile: (Files: FileList) => {};
  onTouched: () => {};
  multiple: boolean;
  classes: any;
  uploadMsg?: string;
  uploadSubMsg?: string;
  acceptedFormat: string;
  uploadimg?: any;
  height?: React.CSSProperties["height"];
};
export class UploadBoxCSV extends React.Component<UploadBoxProps> {
  fileInputRef = React.createRef<HTMLInputElement>();

  fileInputClickedCsv() {
    this.props.onTouched();
    this.fileInputRef?.current?.click();
  }

  filesSelectedcsv() {
    if (this.fileInputRef?.current?.files?.length) {
      this.handleFilesCsv(this.fileInputRef?.current?.files);
    }
  }

  handleFilesCsv(files: FileList) {
    if (files[0].name.includes(".csv") || files[0].name.includes(".CSV")) {
      this.props.onSelectFile(files);
    } else {
      this.props.showToast({
        type: "error",
        message: "invalid file type",
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Box
          display="flex"
          className={classes.boxOutlineCsv}
          id="boxOutline"
          onClick={this.fileInputClickedCsv.bind(this)}
        >
          <Typography
            style={{
              width: "100%",
            }}
            variant="body1"
            noWrap
            className={classes.boldTextCsv}
          >
            {this.props.uploadMsg ? this.props.uploadMsg : ""}
          </Typography>
          <Typography variant="caption">
            {this.props.uploadSubMsg ? this.props.uploadSubMsg : ""}
          </Typography>
        </Box>
        <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          type="file"
          id="fileInput"
          accept={this.props.acceptedFormat}
          multiple={this.props.multiple}
          onChange={this.filesSelectedcsv.bind(this)}
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    boxOutlineCsv: {
      borderRadius: "0.50rem",
      backgroundColor: "#8F92A1",
      padding: "0.25rem 0",
      cursor: "pointer",
    },
    boldTextCsv: {
      color: theme.palette.common.white,
      textAlign: "center",
      cursor: "pointer",
      padding: "0 1rem",
    },
  });

export default withStyles(styles, { withTheme: true })(UploadBoxCSV);
