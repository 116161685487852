//@ts-nocheck
import React from "react";
import SignUpController, { Props } from "./SignUpController.web";
import { withStyles, Grid, Typography, createStyles, Button, Box, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Redirect } from "react-router-dom";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Logo } from "./CodeVerified.web";
import { authFlowStyles } from "./Login.web";
import TernaryCheck from "../TernaryCheck.web";

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .required("This field is required.")
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field"),
  lastName: Yup.string()
    .required("This field is required.")
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field"),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  firmName: Yup.string().required("This field is required."),
  password: Yup.string()
    .min(8, "Password must contain at least 8 characters, one uppercase, one number and one special character")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
  termsAndConditions: Yup.boolean()
    .oneOf([true], "Accept terms and condtions to continue")
    .required("Accept terms and condtions to continue"),
});

export class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const role = await StorageProvider.get("role");
    if (token) {
      this.setState({
        token: token,
        role: role,
      });
    }
  }

  goToRoute = (pathName, stateValue) => {
    this.props.history.push({
      pathname: pathName,
      state: {
        values: stateValue,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const role = this.state.role;
    let path = "/home";
    if (role === "Admin User" || role === "Super Admin User") {
      path = "/adminDashboard";
    }
    return !this.state.token ? (
      <Formik
        initialValues={{
          firstName: this.props.history.location?.state?.values?.firstName || "",
          lastName: this.props.history.location?.state?.values?.lastName || "",
          email: this.props.history.location?.state?.values?.email || "",
          firmName: this.props.history.location?.state?.values?.firmName || "",
          password: this.props.history.location?.state?.values?.password || "",
          termsAndConditions: this.props.history.location?.state?.values?.termsAndConditions || false,
        }}
        validationSchema={Schema}
        onSubmit={this.handleSignUp}
      >
        {(formikProps) => {
          const { values, isValid, handleChange } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "100vh",
                }}
              >
                <Grid item md={3} xs={12} className={classes.loginBox}>
                  <Box py={3}>
                    <Grid container justifyContent="center" spacing={1}>
                      <Logo />
                      <Grid item xs={12}>
                        <Typography variant="h1" align="center" className={classes.welcomeBack}>
                          Create Your Account
                        </Typography>
                      </Grid>

                      <Grid item xs={5}>
                        <Field component={InputField} variant="outlined" fullWidth name="firstName" placeholder="First Name" />
                      </Grid>

                      <Grid item xs={5}>
                        <Field component={InputField} variant="outlined" fullWidth name="lastName" placeholder="Last Name" />
                      </Grid>

                      <Grid item xs={10}>
                        <Field type="email" component={InputField} variant="outlined" fullWidth name="email" placeholder="Email Address" />
                      </Grid>

                      <Grid item xs={10}>
                        <Field component={InputField} variant="outlined" fullWidth name="firmName" placeholder="Firm Name" />
                      </Grid>

                      <Grid item xs={10}>
                        <Field
                          type={this.state.isPasswordVisible ? "text" : "password"}
                          component={InputField}
                          variant="outlined"
                          fullWidth
                          name="password"
                          placeholder="Set Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={() => {
                                  this.setState((prevState) => ({
                                    isPasswordVisible: !prevState.isPasswordVisible,
                                  }));
                                }}
                              >
                                <TernaryCheck
                                  condition={this.state.isPasswordVisible}
                                  trueComponent={<VisibilityOffIcon className={classes.visibilityIcon} />}
                                  falseComponent={<VisibilityIcon className={classes.visibilityIcon} />}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} container alignItems="center">
                        <div style={{ display: "inline" }}>
                          <FormControlLabel
                            style={{ flexGrow: 1, marginRight: 0 }}
                            control={<Checkbox size="small" />}
                            name="termsAndConditions"
                            checked={values.termsAndConditions}
                            onChange={handleChange}
                            labelPlacement="right"
                            classes={{
                              label: classes.labelText,
                            }}
                          />
                        </div>
                        <Typography style={{ paddingLeft: "0.2rem" }} align="center" display="inline" color="textPrimary" className={classes.withButtonText}>
                          Accept our
                        </Typography>
                        <Typography
                          onClick={this.goToRoute.bind(this, "termsAndConditions", values)}
                          display="inline"
                          color="primary"
                          style={{ paddingLeft: "0.2rem" }}
                          className={classes.termsAndConditionsText}
                        >
                          Terms of Service
                        </Typography>
                        <Typography style={{ paddingLeft: "0.2rem" }} align="center" display="inline" color="textPrimary" className={classes.withButtonText}>
                          and
                        </Typography>
                        <Typography
                          onClick={this.goToRoute.bind(this, "privacyPolicy", values)}
                          display="inline"
                          color="primary"
                          style={{ paddingLeft: "0.2rem" }}
                          className={classes.termsAndConditionsText}
                        >
                          Privacy Policy
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Button color="primary" type="submit" variant="contained" fullWidth disabled={!isValid}>
                          Sign Up
                        </Button>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography align="center">Or</Typography>
                      </Grid>
                      <Grid item md={10} xl={10} container alignItems="center" justifyContent="center">
                        <Typography style={{ paddingLeft: "8px" }} align="center" display="inline" color="textPrimary">
                          Already have an account?
                        </Typography>
                        <Typography
                          onClick={() => {
                            this.props.history.push("/");
                          }}
                          display="inline"
                          color="primary"
                          style={{ paddingLeft: "10px" }}
                          className={classes.buttonText}
                        >
                          Sign In
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    ) : (
      <Redirect to={path} />
    );
  }
}
const SignUpWithRouter = withRouter(SignUp);
const SignUpWithToast = withToast(SignUpWithRouter);
const SignUpWithLoader = withLoader(SignUpWithToast);
const SignUpWithAlertBox = withAlertBox(SignUpWithLoader);
const SignUpWithDialogBox = withDialog(SignUpWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
  })
)(SignUpWithDialogBox);
