//@ts-nocheck
import React from "react";
import { Typography, Button, Grid, Box, withStyles, createStyles, TextField, InputAdornment } from "@material-ui/core";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../../components/src/withSnackBar.Web";
import withLoader, { withLoaderProps } from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { spaceMask, mobileNumberMask } from "../masks";
import { DialogProps } from "../../../../components/src/DialogContext";
import { style } from "../Settings/Branding.web";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    selectedPackageList: [];
    // Customizable Area Start
    // Customizable Area End
  };

export const roadmapSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format").nullable(),
  firm_name: Yup.string(),
  phone_number: Yup.string()
    .matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone Number").nullable(),
  web_url: Yup.string()
    .matches(/^((https?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g, "Invalid URL").nullable(),
});

const swatchColors = ["#6EA6E8", "#74c283", "#f9d949", "#f59540", "#f66c6c", "#f08bb9", "#696cd4", "#f5f5f5", "#6d6d6d"];

export class RoadmapCustomization2 extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");

    if(token) {
      this.props.getRoadMap();
    }
  }

  setColor = () => {
    localStorage.setItem("primaryColor", this.props.primaryColor);
    localStorage.setItem("secondaryColor", this.props.secondaryColor);
  };

  handleSaveBranding = (values) => {
    this.props.createAndUpdateBranding(values);
  };

  handleSaveAndContinue = (values) => {
    this.setColor();
    this.props.updateRoadMap(values);
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          email: this.props?.email || "",
          firm_name: this.props?.firm_name,
          phone_number: this.props?.phone_number,
          web_url: this.props?.web_url,
          confidential_statement: this.props.confidential_statement || "This has been prepared for the purpose of providing tax, accounting and strategic advisory services. This information should not be shared, has not been audited, and does not imply we're providing investment advice."
        }}
        validationSchema={roadmapSchema}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, handleChange, isValid } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12}>
                  <Typography className={classes.title}>Roadmap Customization</Typography>
                  <Typography className={classes.subTitle}>Update your logo, colors, and contact information below.</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" className={classes.customLabel}>
                    Add Logo
                  </Typography>
                  {this.props.showCorrectImage()}
                </Grid>
                <Grid item md={6} />
                <Grid item md={5} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.customLabel}>Add Primary Color</Typography>
                      <Grid container className={classes.whiteCard}>
                        <Typography className={classes.swatchTitle}>PICK A SWATCH:</Typography>
                        <Grid item xs={12}>
                          {swatchColors.map((color) => {
                            return (
                              <Box
                                key={color}
                                className={classes.colorSwatch}
                                onClick={() => this.props.handlePrimaryColorChange(color)}
                                style={{
                                  backgroundColor: color,
                                }}
                              />
                            );
                          })}
                        </Grid>
                        <TextField
                          variant="outlined"
                          name="primaryColor"
                          value={this.props.primaryColor}
                          onChange={this.props.handleColorInputChange}
                          placeholder="Enter Hex Code"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <FiberManualRecordIcon
                                  style={{
                                    fill: this.props.primaryColor,
                                  }}
                                  size="small"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.customLabel}>Add Secondary Color</Typography>
                      <Grid container className={classes.whiteCard}>
                        <Typography className={classes.swatchTitle}>PICK A SWATCH:</Typography>
                        <Grid item xs={12}>
                          {swatchColors.map((color) => {
                            return (
                              <Box
                                key={color}
                                className={classes.colorSwatch}
                                onClick={() => this.props.handleSecondaryColorChange(color)}
                                style={{
                                  backgroundColor: color,
                                }}
                              />
                            );
                          })}
                        </Grid>
                        <TextField
                          variant="outlined"
                          name="secondaryColor"
                          value={this.props.secondaryColor}
                          onChange={this.props.handleColorInputChange}
                          placeholder="Enter Hex Code"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <FiberManualRecordIcon
                                  style={{
                                    fill: this.props.secondaryColor,
                                  }}
                                  size="small"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Field
                    component={InputField}
                    name="firm_name"
                    onChange={(e) => handleChange(spaceMask(e))}
                    value={values.firm_name}
                    fullWidth
                    label="Firm Name"
                    InputProps={{
                      inputProps: {
                        maxlength: "20",
                      },
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <Field component={InputField} name="email" onChange={(e) => handleChange(spaceMask(e))} value={values.email} fullWidth label="Firm Email" />
                </Grid>
                <Grid item md={5} xs={12}>
                  <Field
                    component={InputField}
                    name="phone_number"
                    onChange={(e) => handleChange(mobileNumberMask(e))}
                    value={values.phone_number}
                    fullWidth
                    label="Firm Phone Number"
                    InputProps={{
                      inputProps: {
                        maxlength: "20",
                      },
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <Field
                    component={InputField}
                    name="web_url"
                    onChange={(e) => handleChange(spaceMask(e))}
                    value={values.web_url}
                    fullWidth
                    label="Firm Web URL"
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <Field
                    component={InputField}
                    name="confidential_statement"
                    onChange={(e) => handleChange(spaceMask(e))}
                    value={values.confidential_statement}
                    fullWidth
                    label="Confidentiality & Disclaimer"
                    multiline
                    minRows={4}
                  />
                </Grid>
                <Grid item xs={12} className={classes.bottomSection}>
                  <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                    <Grid item md={4} xs={6} container justifyContent="flex-end">
                      <Button
                        color="primary"
                        disabled={!isValid}
                        onClick={this.handleSaveBranding.bind(this, values)}
                        variant="outlined"
                      >
                        Save to Profile
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={6} container justifyContent="flex-end">
                      <Button
                        color="primary"
                        disabled={!isValid}
                        onClick={this.handleSaveAndContinue.bind(this, values)}
                        variant="contained"
                      >
                        Save & continue
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const RoadmapCustomization2WithRouter = withRouter(RoadmapCustomization2);
const RoadmapCustomization2AlertBox = withAlertBox(RoadmapCustomization2WithRouter);
const RoadmapCustomization2Loader = withLoader(RoadmapCustomization2AlertBox);
const RoadmapCustomization2Toast = withToast(RoadmapCustomization2Loader);
const RoadmapCustomization2WithDialog = withDialog(RoadmapCustomization2Toast);

export const RoadmapCustomization2WithStyles = withStyles((theme) =>
  createStyles({
    ...style(theme)
  })
)(withConfirmBox(RoadmapCustomization2WithDialog));

export default RoadmapCustomization2WithStyles;