//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";

const InputField = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  ...props
}: MuiTextFieldProps) => {
  return (
    <MuiTextField
      {...props}
      variant="outlined"
      margin="none"
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
        classes: {
          asterisk: classes?.astriklabel,
          outlined: classes?.outlinedLabel,
          shrink: classes?.shrink,
          root: classes?.lableroot,
        },
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: classes?.input,
          root: classes?.inputfieledroot,
          multiline: classes.multiline,
          inputMultiline: classes.inputMultiline
        },
        notched: false,
      }}
      SelectProps={{
        MenuProps:{
          getContentAnchorEl: null,
          anchorOrigin:{
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin:{
            vertical: 'top',
            horizontal: 'right',
          },
        }
      }}
    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot: {
        outline: 'none',
        border: `2px solid ${theme.palette.text.blue2}`,
        borderRadius: '100vmax',
        backgroundColor: '#fff',
        'label + &': {
          marginTop: 30,
          marginBottom: 20,
        },
        '& > fieldset': {
          border: 'none',
          '& active': {
            border: 'none'
          },
          '& focus': {
            border: 'none'
          }
        },
        "&.Mui-focused": {
          border: `2px solid ${theme.palette.text.blue2}`,
          backgroundColor: '#fff',
        },
        "&.Mui-error": {
          border: `2px solid #FF5C5C`,
          backgroundColor: '#f4f4f4',
          "&.Mui-focused": {
            backgroundColor: '#fff',
          }
        },
        "&.MuiSelect-root": {
          border: "none",
          outline: "none",
        }
      },
      input: {
        borderRadius: '100vmax',
        position: 'relative',
        color: theme.palette.text.blue2,
        fontWeight: 600,
        backgroundColor: '#fff',
        fontSize: '0.9rem',
        paddingLeft: "1rem",
        fontFamily: "Nunito Sans",
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.5rem',
        border: "none",
        outline: "none",
        "&:focus": {
          border: "none",
          borderRadius: '100vmax',
          backgroundColor: '#fff',
          "&::placeholder": {
            color: theme.palette.text.blue2,
          }
        },
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        borderRadius: '100vmax',
          position: 'relative',
          backgroundColor: '#f4f4f4',
          border: 'none',
          fontSize: '1rem',
          fontFamily: 'Nunito Sans',
          // fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.19,
          letterSpacing: 'normal',
          padding: '0.75rem 0.75rem',
          "&:focus": {
              border: "none",
              borderRadius: '100vmax',
              backgroundColor: '#fff',
              "&::placeholder": {
                color: theme.palette.text.blue2,
              }
            },
      },
      astriklabel: {
        fontFamily: "Nunito Sans",
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#959ead'
      },
      lableroot: {
        fontFamily: "Nunito Sans",
        fontSize: '0.875rem',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: theme.palette.text.blue,
        "&.Mui-focused": {
          color: theme.palette.text.blue2
        },
      },
      outlinedLabel: {
        transform: 'translate(3px, 9px) scale(1)!important'
      },
      shrink: {
        transform: 'translate(3px, 9px) scale(1)!important'
      }
  })
)(InputField);
