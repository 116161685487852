//@ts-nocheck
import React from "react";
import { Grid, withStyles, createStyles, TextField, InputAdornment, Hidden } from "@material-ui/core";
import { withRouter, Route, Switch, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import SearchIcon from "@material-ui/icons/Search";
import DefaultFirmFinancials from "./DefaultFirmFinancials.web";
import FirmPricing from "./FirmPricing.web";
import PricingBumps from "./PricingBumps.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import { Props } from "../FirmFinancialsNew.web";
import TernaryCheck from "../TernaryCheck.web";

export class PricingContainer extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, permissions } = this.props;
    const navLinks = [
      {
        title: "Firm Financials",
        url: "/home/settings/pricing/firmFinancials",
      },
      {
        title: "Firm Pricing",
        url: "/home/settings/pricing/firmPricing",
      },
      {
        title: "Pricing Bumps",
        url: "/home/settings/pricing/pricingBumps",
      },
    ];

    return (
      <Grid
        container
        justifyContent="space-between"
        style={{
          position: "relative",
        }}
      >
        <Hidden smUp>
          <Grid item xs={12} className={[classes.gridSpacing]}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      paddingRight: 0,
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Hidden>

        <Grid
          item
          xs={10}
          style={{
            marginBottom: "1rem",
          }}
        >
          {navLinks.map((archive) => {
            return (
              <NavLink key={archive.url} to={archive.url} className={classes.sideLink} activeClassName={classes.activeSideLink}>
                {archive.title}
              </NavLink>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Switch>

            <Route path="/home/settings/pricing/firmFinancials" exact>
              <DefaultFirmFinancials />
            </Route>
            <Route path="/home/settings/pricing/firmPricing" exact>
              <FirmPricing />
            </Route>
            <Route path="/home/settings/pricing/pricingBumps" exact>
              <PricingBumps />
            </Route>

            <TernaryCheck
              condition={permissions.includes("View/Edit Firm Financials-Firm Financials")}
              trueComponent={<Redirect exact from="/home/settings/pricing" to="/home/settings/pricing/firmFinancials" />}
              falseComponent={<Redirect exact from="/home/settings/pricing" to="/home/settings/pricing/firmPricing" />}
            />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

const PricingContainerWithRouter = withRouter(PricingContainer);
const PricingContainerAlertBox = withAlertBox(PricingContainerWithRouter);
const PricingContainerLoader = withLoader(PricingContainerAlertBox);
const PricingContainerToast = withToast(PricingContainerLoader);
const PricingContainerWithDialog = withDialog(PricingContainerToast);
const PricingContainerWithConfirmBox = withConfirmBox(PricingContainerWithDialog);
const PricingContainerWithPermissions = withPermissions(PricingContainerWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      sideLink: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        color: theme.palette.text.secondary,
        cursor: "pointer",
        textAlign: "left",
        textDecoration: "none",
        fontWeight: "bold",
        borderBottom: "3px solid #f4f4f4",
        padding: "0px 10px",
        "&:first-of-type": {
          paddingLeft: "0px",
        },
      },
      activeSideLink: {
        color: theme.palette.text.blue2,
        textTransform: "none",
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "1.3158rem",
        fontWeight: "bold",
        borderBottom: `3px solid ${theme.palette.text.blue2}`,
        padding: "0px 10px",
      },
      stylesMoreIcon: {
        height: "1.5rem",
        width: "1.5rem",
        cursor: "pointer",
        marginRight: "1.5rem",
      },
    }),
  { withTheme: true }
)(PricingContainerWithPermissions);
