//@ts-nocheck
import React from "react";
import CreateRoleController, { Props } from "./CreateRoleController.web";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import { Formik, Field, Form } from "formik";

export class CreateRole extends CreateRoleController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
          user: this.props.history.location.state.user,
          accountId: this.props.history?.location?.state?.user?.id,
        }
      );
    }
  }

  saveInfo = () => {
    this.props.showToast({
      type: "success",
      message: "Role created successfully",
    });
  };

  render() {
    return (
      <Formik
        initialValues={{
          firstName:
            this.props.location.state.user?.attributes?.first_name ||
            "",
          lastName:
            this.props.location.state.user?.attributes?.last_name || "",
          firmName:
            this.props.location.state.user?.attributes?.firm_name || "",
        }}
        onSubmit={this.updateUserPermissions}
      >
        {(formikProps) => {
          const { values, handleChange } =
            formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                direction="row"
                spacing={2}
              >
                <Grid item xs={11}>
                  <Typography color="primary" variant="h2">
                    Manage User
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={6} xs={10}>
                      <Field
                        component={InputField}
                        name="firstName"
                        placeholder="First Name"
                        onChange={handleChange}
                        value={values.firstName}
                        fullWidth
                        label="First Name"
                      />
                    </Grid>
                    <Grid item md={6} xs={10}>
                      <Field
                        component={InputField}
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={values.lastName}
                        fullWidth
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item md={6} xs={10}>
                      <Field
                        component={InputField}
                        name="firmName"
                        placeholder="Firm Name"
                        onChange={handleChange}
                        value={values.firmName}
                        fullWidth
                        label="Firm Name"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-end">
                    <Grid item xs={2}>
                      <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const CreateRoleWithRouter = withRouter(CreateRole);
const CreateRoleAlertBox = withAlertBox(CreateRoleWithRouter);
const CreateRoleLoader = withLoader(CreateRoleAlertBox);
const CreateRoleToast = withToast(CreateRoleLoader);
const CreateRoleWithDialog = withDialog(CreateRoleToast);

export default withStyles((theme) =>
  createStyles({
    root: {
      maxHeight: "calc(100vh - 60px)",
      overflowY: "auto",
    },
  })
)(withConfirmBox(CreateRoleWithDialog));
