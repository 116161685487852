//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Button,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";

export class CategoryFilterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: this.props.dataToPass?.categories || [],
      selectedObjectives: this.props.dataToPass?.objectives || [],
    };
  }
  objectiveData = [
    {
      name: "isOwnsABusiness",
      displayName: "Business",
      value: "Business",
    },
    {
      name: "isOwnsABusiness",
      displayName: "Individual",
      value: "Individual",
    },
    {
      name: "isWithAccounting",
      displayName: "Accounting",
      value: "Accounting",
    },
    {
      name: "isWithPayroll",
      displayName: "Payroll",
      value: "Payroll",
    },
    {
      name: "isForNewBusiness",
      displayName: "New Business",
      value: "New Business",
    },
    {
      name: "isForIRSIssues",
      displayName: "IRS Issues",
      value: "IRS Issues",
    },
    {
      name: "isForStrategicAdvisory",
      displayName: "Strategic Help",
      value: "Strategic Help",
    },
  ];
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  handleCategoryChange = (event) => {
    if (event.target.checked) {
      this.setState({
        selectedCategories: [
          ...this.state.selectedCategories,
          event.target.value,
        ],
      });
    } else {
      this.setState({
        selectedCategories: this.state.selectedCategories?.filter((cat) => {
          return cat != event.target.value;
        }),
      });
    }
  };
  handleObjectivesChange = (event) => {
    const value = this.objectiveData.find((ob) => {
      return ob.value == event.target.value;
    });
    if (event.target.checked) {
      if (value?.name == "isOwnsABusiness") {
        const nonValue = value?.value == 'Individual' ? 'Business' : 'Individual'
        this.setState({
          selectedObjectives: this.state.selectedObjectives?.filter((cat) => {
            return cat?.value != nonValue;
          })
        },()=>{
          this.setState({
            selectedObjectives: [...this.state.selectedObjectives, value],
          });  
        });
      } else {
        this.setState({
          selectedObjectives: [...this.state.selectedObjectives, value],
        });
      }
    } else {
      this.setState({
        selectedObjectives: this.state.selectedObjectives?.filter((cat) => {
          return cat?.value != event.target.value;
        }),
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ width: "30vw" }} justifyContent="center">
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Category</FormLabel>
            <FormGroup row>
              {[
                "Expert Historic Work",
                "Setup Help",
                "Done-For-You Management",
                "Tax Reduction Planning",
                "Strategic Planning",
              ].map((name) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      color="primary"
                      id="selectedCategories"
                      checked={this.state.selectedCategories.indexOf(name) > -1}
                      onChange={this.handleCategoryChange.bind(this)}
                      name={"categories"}
                      value={name}
                    />
                  }
                  label={name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Objectives</FormLabel>
            <FormGroup row>
              {this.objectiveData.map((tag) => (
                <FormControlLabel
                  key={tag?.displayName}
                  control={
                    <Checkbox
                      color="primary"
                      id="selectedObjectives"
                      checked={
                        this.state.selectedObjectives
                          .map((ob) => {
                            return ob?.value;
                          })
                          .indexOf(tag?.value) > -1
                      }
                      onChange={this.handleObjectivesChange.bind(this)}
                      name={"objectives"}
                      value={tag?.value}
                    />
                  }
                  label={tag?.displayName}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            id="closeDialog"
            onClick={this.closeDialog.bind(this,{
              categories: this.state.selectedCategories,
              objectives : this.state.selectedObjectives
            })}
            className={classes.uploadNowButton}
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const CategoryFilterDialogWithRouter = withRouter(CategoryFilterDialog);
const CategoryFilterDialogWithToast = withToast(CategoryFilterDialogWithRouter);
const CategoryFilterDialogWithLoader = withLoader(
  CategoryFilterDialogWithToast
);
const CategoryFilterDialogWithAlertBox = withAlertBox(
  CategoryFilterDialogWithLoader
);
const CategoryFilterDialogWithDialogBox = withDialogBox(
  CategoryFilterDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    dialogsubHeading: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    uploadNowButton: {
      margin: "1.5rem 0px",
      width: "40%",
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })
)(CategoryFilterDialogWithDialogBox);
