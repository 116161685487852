//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import { surveyStyles } from "./surveyStyles";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
export const CashFlowSvgIcon = ({ iconColor }) => {
  const defaultColor = '#54a4a6';
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 146 146"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2
      }}
    >
      <g id="Group">
        <path
          id="Shape"
          d="M100.26,136.719c20.136,-0 36.459,-16.323 36.459,-36.459c-0,-20.135 -16.323,-36.458 -36.459,-36.458c-20.135,0 -36.458,16.323 -36.458,36.458c0,20.136 16.323,36.459 36.458,36.459Zm45.573,-36.459c0,25.17 -20.403,45.573 -45.573,45.573c-25.169,0 -45.572,-20.403 -45.572,-45.573c-0,-25.169 20.403,-45.572 45.572,-45.572c25.17,-0 45.573,20.403 45.573,45.572Z"
          style={{ fill: iconColor || defaultColor, fillRule: 'nonzero' }}
        />
        <path
          id="Shape1"
          d="M86.023,108.865c0.429,5.432 4.722,9.661 12.424,10.171l-0,4.011l3.418,-0l-0,-4.038c7.975,-0.556 12.632,-4.821 12.632,-11.001c0,-5.633 -3.554,-8.531 -9.935,-10.026l-2.697,-0.638l-0,-10.938c3.427,0.392 5.596,2.261 6.115,4.849l5.998,0c-0.429,-5.241 -4.922,-9.333 -12.113,-9.78l-0,-4.001l-3.418,-0l-0,4.102c-6.809,0.665 -11.439,4.757 -11.439,10.554c-0,5.123 3.445,8.386 9.178,9.716l2.261,0.556l-0,11.594c-3.5,-0.529 -5.825,-2.461 -6.344,-5.131l-6.089,-0l0.009,-0Zm12.396,-12.342c-3.363,-0.774 -5.186,-2.369 -5.186,-4.757c0,-2.68 1.969,-4.685 5.214,-5.269l-0,10.026l-0.028,0Zm3.938,6.8c4.092,0.948 5.97,2.479 5.97,5.186c-0,3.09 -2.343,5.205 -6.462,5.596l-0,-10.891l0.492,0.109Z"
          style={{ fill: iconColor || defaultColor, fillRule: 'nonzero' }}
        />
        <path
          id="Path"
          d="M9.115,0c-5.034,0 -9.115,4.081 -9.115,9.115l0,72.916c0,5.034 4.081,9.115 9.115,9.115l37.214,-0c0.529,-3.136 1.322,-6.18 2.352,-9.115l-21.337,0c-0,-10.067 -8.162,-18.229 -18.229,-18.229l-0,-36.458c10.067,-0 18.229,-8.162 18.229,-18.229l91.146,-0c-0,10.067 8.161,18.229 18.229,18.229l-0,32.156c3.463,3.099 6.535,6.635 9.114,10.518l0,-60.903c0,-5.034 -4.08,-9.115 -9.114,-9.115l-127.604,-0Z"
          style={{ fill: iconColor || defaultColor, fillRule: 'nonzero' }}
        />

        <path
          id="Path1"
          d="M91.128,46.329l0.018,-0.756c-0.005,-8.667 -6.112,-16.131 -14.605,-17.854c-8.494,-1.724 -17.026,2.772 -20.408,10.751c-3.382,7.979 -0.679,17.237 6.466,22.142c7.873,-7.499 17.808,-12.473 28.529,-14.283Z"
          style={{ fill: iconColor || defaultColor, fillRule: 'nonzero' }}
        />
      </g>
    </svg>
  );
};
class CashFlowStep extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    let stepNo = this.props.taxPayerType === "individual" ? '6.2' : '6.1';
    this.props.getQuestionAndOptionsByStepNo(stepNo);
  }
  render() {
    const { classes,iconColor } = this.props;
    return (
      <Grid container justifyContent="space-around" >
        <Grid xs={11} sm={3} item>
          <Box my={5}>
            <div style={{paddingLeft : '3rem'}} className={classes.leftSideImageContainer}>
              <CashFlowSvgIcon iconColor={iconColor} />
            </div>
            <Typography
              className={classes.sidebarHeadingWithoutMargin}
              color="primary"
            >
              Cash Flow
            </Typography>
            <Typography className={classes.sidebarDescription}>
              If you have more cash left over at the end of each month, you can
              achieve your goals faster & easier.
            </Typography>

            <Typography style={{marginBottom : '0.5rem'}} className={classes.sidebarDescription}>
              If you want more cash left at the end of the month, we need to
              know: 
            </Typography>
            <Typography gutterBottom style={{marginBottom : '0.5rem'}} className={classes.sidebarDescription}>
                a. How much you're making 
            </Typography>
            <Typography gutterBottom style={{marginBottom : '0.5rem'}} className={classes.sidebarDescription}>
                b. What it's being spent on currently
            </Typography>
            <Typography gutterBottom className={classes.sidebarDescription}>
              c. What exactly can be adjusted based on your unique goals
            </Typography>
            <Typography style={{marginBottom : 0}} className={classes.sidebarDescription}>
              Most of us don't track our income spending to the penny but by
              creating a simple cash flow plan, anyone can make their money work harder for them each month. 
            </Typography>
            
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid xs={8} item>
          <Grid container justifyContent="center">
            <Grid item xs={9}>
              <Typography className={classes.pageHeading} gutterBottom>
                 {this.props?.stepContent?.description}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container justifyContent="flex-start">
              {this.props?.stepContent?.options?.data?.map((option) => {
                        return (
                          <Grid
                            key={option.id}
                            item
                            xs={12}
                            md={4}
                            className={
                              this.props.cashflowSelection
                                ?.map((step) => {
                                  return step?.id;
                                })
                                .filter((opt) => {
                                  return opt === option.id;
                                }).length > 0
                                ? [
                                    classes.selectedPackageCard1,
                                    classes.minHeight10rem,
                                  ]
                                : [classes.packageCard1, classes.minHeight10rem]
                            }
                            onClick={() => {
                              this.props.setCashflowSelection(option);
                            }}
                          >
                            <Typography
                              className={classes.cardDescription}
                              color="textPrimary"
                            >
                              {option?.attributes?.displaydescription}
                            </Typography>
                          </Grid>
                        );
                      })}
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.buttonContainer}>
              <Grid container justifyContent="flex-end">
                <Grid item sm={4} xs={11} container justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      this.props.handleBack();
                    }}
                    startIcon={<ArrowBackOutlinedIcon/>}
                    className={classes.backButton}
                    variant="text"
                    color="primary"
                  >
                    Back
                  </Button>
                  <Button
                    endIcon={<ArrowForwardOutlinedIcon />}
                    color="primary"
                    disabled={!this.props.cashflowSelection.length > 0}
                    onClick={() => {
                      this.props.handleNext();
                    }}
                    variant="text"
                    className={classes.nextButton}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const CashFlowStepWithRouter = withRouter(CashFlowStep);
const CashFlowStepAlertBox = withAlertBox(CashFlowStepWithRouter);
const CashFlowStepLoader = withLoader(CashFlowStepAlertBox);
const CashFlowStepToast = withToast(CashFlowStepLoader);
const CashFlowStepWithDialog = withDialog(CashFlowStepToast);

export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    incomeContainer: {
      width: "60%",
      margin: "auto",
      height: "50vh",
    },
  })
)(withConfirmBox(CashFlowStepWithDialog));
