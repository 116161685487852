//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import { dialogStyle } from "./RoadmapDialogStyle.web";

export const DialogFooter = (props) => {
  const { closeDialog, classes } = props;
  return (
    <Grid item container justifyContent="space-between" xs={12}>
      <Grid item xs={3}>
        <Button
          variant="contained"
          fullWidth
          className={classes.deactivateButton}
          onClick={() => {
            closeDialog("Yes");
          }}
        >
          Yes
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            closeDialog("No");
          }}
        >
          No
        </Button>
      </Grid>
    </Grid>
  );
};

export class RoadmapCancelDialog extends React.Component {
 

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        className={classes.rootContainer}
      >
        <Grid item xs={12}>
          <Typography color="primary" variant="h1">
            Cancel
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" id="roadmapcanceltext" variant="h4">
            This will completely erase your Roadmap progress and everything will
            be lost. <br /> Do you want to continue.
          </Typography>
        </Grid>
        <DialogFooter closeDialog={this.closeDialog.bind(this)} classes={classes} /> 
      </Grid>
    );
  }
}

const RoadmapCancelDialogWithRouter = withRouter(RoadmapCancelDialog);
const RoadmapCancelDialogWithToast = withToast(RoadmapCancelDialogWithRouter);
const RoadmapCancelDialogWithLoader = withLoader(RoadmapCancelDialogWithToast);
const RoadmapCancelDialogWithAlertBox = withAlertBox(
  RoadmapCancelDialogWithLoader
);

export default withStyles((theme) =>
  createStyles({
    ...dialogStyle(theme)
  })
)(RoadmapCancelDialogWithAlertBox);
