//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import { surveyStyles } from "./surveyStyles";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { ReportingSvgIcon } from "./Reporting.web";



class SavingStep extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.props.getQuestionAndOptionsByStepNo("8.2.1");
  }
  render() {
    const { classes,iconColor } = this.props;
    return (
      <Grid container justifyContent="space-around" alignItems="start">
        <Grid xs={11} sm={3} item>
        <div style={{paddingLeft : '3rem'}} className={classes.leftSideImageContainer}>
              <ReportingSvgIcon iconColor={iconColor} />
            </div>
          <Box my={8}>
            <Typography
              gutterBottom
              className={classes.sidebarHeadingWithoutMargin}
              color="primary"
            >
              Savings
            </Typography>
            <Typography className={classes.sidebarDescription}>
              How much you pay in taxes can impact every dollar you earn.
            </Typography>
            <Typography className={classes.sidebarDescription}>
              If you're earning money but pay more in taxes than you should,
              that impacts how much money you have to invest and save for your
              future
            </Typography>
            <Typography className={classes.sidebarDescription}>
            Having a proactive plan for minimizing your taxes, so you can maximize how much you're saving and investing, can help you achieve your future goals faster & easier.
            </Typography>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid xs={8} item>
          <Grid container justifyContent="center">
            <Grid item xs={9}>
              <Typography className={classes.pageHeading} gutterBottom>
                  {this.props?.stepContent?.description}
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Grid container>
              {this.props?.stepContent?.options?.data?.map((option) => {
                        return (
                          <Grid
                            key={option.id}
                            item
                            xs={12}
                            md={4}
                            className={
                              this.props.savingSelection
                                ?.map((step) => {
                                  return step?.id;
                                })
                                .filter((opt) => {
                                  return opt === option.id;
                                }).length > 0
                                ? [
                                    classes.selectedPackageCard1,
                                    classes.minHeight10rem,
                                  ]
                                : [classes.packageCard1, classes.minHeight10rem]
                            }
                            onClick={() => {
                              this.props.setSavingSelection(option);
                            }}
                          >
                            <Typography
                              className={classes.cardDescription}
                              color="textPrimary"
                            >
                              {option?.attributes?.displaydescription}
                            </Typography>
                          </Grid>
                        );
                      })} 
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.buttonContainer}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={11} sm={4} container justifyContent="flex-end">
                  <Button
                    startIcon={<ArrowBackOutlinedIcon />}
                    color="primary"
                    onClick={() => {
                      this.props.handleBack();
                    }}
                    variant="text"
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    endIcon={<ArrowForwardOutlinedIcon />}
                    color="primary"
                    disabled={!this.props.savingSelection.length > 0}
                    onClick={() => {
                      this.props.handleNext();
                    }}
                    variant="text"
                    className={classes.nextButton}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const SavingStepWithRouter = withRouter(SavingStep);
const SavingStepAlertBox = withAlertBox(SavingStepWithRouter);
const SavingStepLoader = withLoader(SavingStepAlertBox);
const SavingStepToast = withToast(SavingStepLoader);
const SavingStepWithDialog = withDialog(SavingStepToast);

export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    incomeContainer: {
      width: "60%",
      margin: "auto",
      height: "50vh",
    },
  })
)(withConfirmBox(SavingStepWithDialog));
