//@ts-nocheck
import React from "react";
import ClientsListMinimalController, {
  Props,
} from "./ClientsListMinimalController.web";
import {
  Typography,
  Button,
  Grid,
  Box,
  withStyles,
  createStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { moreIcon, deleteIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { Formik, Form, FieldArray, FastField } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddIcon from "@material-ui/icons/Add";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { smallChartOptions } from "../chartOptions";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AddPrioritiesDialog from "./AddPrioritiesDialog.web";
import DeleteDialog from "./DeleteDialog.web";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const data = {
  datasets: [
    {
      label: "# of Votes",
      data: [62, 38],
      backgroundColor: ["#8386F1", "#91CFCC"],
      borderWidth: 1,
    },
  ],
};

const labels = [];

export const lineChartData = {
  labels,
  datasets: [],
};

export const lineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    x: {
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        font: {
          size: 9,
        },
      },
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
    y: {
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        stepSize: 20,
        font: {
          size: 9,
        },
      },
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
  },
};

const StyledTableCell = withStyles((theme) => ({
  root: {
    width: (props) => (props.width ? props.width : "auto"),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: theme.spacing(2),
      borderBottomLeftRadius: theme.spacing(2),
    },
    "&:last-of-type": {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
  },
  head: {
    color: theme.palette.text.blue2,
    fontSize: "1.2rem",
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
    border: "none",
    backgroundColor: "#F3F3F3",
    padding: "1.5rem 0rem",
  },
  body: {
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    border: "none",
    fontFamily: "Nunito Sans",
    backgroundColor: "#fff",
    padding: "1.5rem 0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}))(TableCell);

export class ClientsListAdvanced extends ClientsListMinimalController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState({
      token: token,
      accountId: accountId,
    });
  }

  openAddPrioritesDialog = (outerData) => {
    this.props
      .openDialogBox({
        width: "45%",
        renderedComponent: AddPrioritiesDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data?.priority) {
          this.props.addPriority(outerData.clientId, data.priority);
        }
      });
  };

  openDeleteDialog = (outerData) => {
    this.props
      .openDialogBox({
        dataToPass: {
          dialogHeading: "Delete",
          description: "Are you sure you want to delete this priority item?",
        },
        width: "45%",
        renderedComponent: DeleteDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.props.deletePriority(outerData.clientId, outerData.priorityId);
        }
      });
  };

  checkedOrNot = () => {
    this.props.clientsList.every((item) => item.selected === true);
  };

  renderPhone = (item) => {
    if (item.attributes.primary_phone && item.attributes.primary_email) {
      return `${item.attributes.primary_phone} | ${item.attributes.primary_email}`;
    } else if (item.attributes.primary_phone) {
      return `${item.attributes.primary_phone}`;
    } else if (item.attributes.primary_email) {
      return `${item.attributes.primary_email}`;
    } else {
      return "NA";
    }
  };

  renderName = (item) => {
    if (item?.attributes?.first_name && item?.attributes?.last_name) {
      return `${item.attributes.first_name} ${item.attributes.last_name}`;
    } else if (item?.attributes?.first_name) {
      return `${item.attributes.first_name}`;
    } else {
      return "NA";
    }
  };

  renderNoClient = (classes) => {
    return (
      <Grid
        item
        xs={12}
        style={{
          position: "relative",
        }}
        className={classes.clientRowLeft}
      >
        <Grid container justifyContent="center">
          <Typography
            align="center"
            color="textSecondary"
            className={classes.packagesEnrolledByUser}
          >
            No Clients Available
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderProfileLogo = (item) => {
    if (item?.attributes.first_name && item?.attributes.last_name) {
      return `${item?.attributes.first_name.charAt(
        0
      )}${item?.attributes.last_name.charAt(0)}`;
    } else if (item?.attributes.first_name) {
      return `${item?.attributes.first_name.charAt(0)}`;
    } else {
      return null;
    }
  };

  onDragEnd = (values, result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const todoList = [...values.prioritiesList];
    todoList.splice(source.index, 1);
    todoList.splice(destination.index, 0, values.prioritiesList[source.index]);

    const listWithDragIndex = todoList.map((item, index) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          rank: index,
        },
      };
    });

    this.props.dragPriority(+this.props.selectedClient.id, listWithDragIndex);
  };

  renderLogo = () => {
    if (
      this.props.selectedClient?.attributes?.first_name &&
      this.props.selectedClient?.attributes?.last_name
    ) {
      return `${this.props.selectedClient?.attributes?.first_name.charAt(
        0
      )}${this.props.selectedClient?.attributes?.last_name.charAt(0)}`;
    } else if (this.props.selectedClient?.attributes?.first_name) {
      return `${this.props.selectedClient?.attributes?.first_name.charAt(0)}`;
    } else {
      return null;
    }
  };

  renderRightSection = (classes, theme, values, handleChange) => {
    return (
      <Grid
        item
        xs={6}
        className={[classes.gridSpacing, classes.rightSectionContainer]}
      >
        <Grid
          container
          className={[classes.rightSectionInnerContainer, classes.clientRow]}
        >
          <Grid
            item
            xs={2}
            style={{
              paddingRight: "1rem",
            }}
            className={classes.rightSectionImageGrid}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0.75rem",
                backgroundImage: `url(${this.props.selectedClient?.attributes?.avatar})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              className={classes.nameInitialsContainer}
            >
              {!this.props.selectedClient?.attributes?.avatar ? (
                <Typography className={classes.nameIntialsLogo}>
                  {this.renderLogo()}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.rightSectionUserDetailsContainer}
          >
            <Grid container alignItems="flex-start">
              <Typography
                display="inline"
                style={{
                  color: theme.palette.text.blue2,
                  margin: "auto 0",
                  maxWidth: "12.5rem",
                  display: "inline-block",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
                noWrap
              >
                {this.props.selectedClient?.attributes?.first_name
                  ? `${this.props.selectedClient?.attributes?.first_name} ${this.props.selectedClient?.attributes?.last_name}`
                  : "NA"}
              </Typography>
              <Box className={classes.individualTypography}>
                {this.props.selectedClient?.attributes?.client_type || "NA"}
              </Box>
            </Grid>
            <Typography
              color="textSecondary"
              style={{
                marginTop: "0.5rem",
              }}
              className={classes.packagesEnrolledByUser}
            >
              {this.props.selectedClient?.attributes?.primary_phone
                ? `${this.props.selectedClient?.attributes?.primary_phone}`
                : "NA"}
            </Typography>
            <Typography
              color="textSecondary"
              style={{
                marginTop: "0.5rem",
                maxWidth: "25rem",
              }}
              className={classes.packagesEnrolledByUser}
              noWrap
            >
              {this.props.selectedClient?.attributes?.primary_email
                ? `${this.props.selectedClient?.attributes?.primary_email}`
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <img
              src={moreIcon}
              alt="moreIcon"
              className={classes.stylesMoreIcon}
              onClick={() => {
                StorageProvider.set("clientId", +this.props.selectedClient?.id);
                this.props.history.push({
                  pathname: "/home/clientsList/details/overview",
                  state: { clientId: +this.props.selectedClient?.id },
                });
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography
              className={classes.primaryPointsKeys}
              style={{
                marginTop: "1.5rem",
              }}
            >
              Client Conversion Survey
            </Typography>
            <Typography
              className={classes.primaryPointsKeys}
              style={{
                marginBottom: "1rem",
              }}
            >
              Onboarding Survey
            </Typography>
            <Typography
              className={classes.primaryPointsKeys}
              style={{
                marginTop: "1.5rem",
              }}
            >
              Total Revenue
            </Typography>
            <Typography className={classes.secondaryPointsKeys}>
              Monthly Revenue
            </Typography>
            <Typography className={classes.secondaryPointsKeys}>
              One Time Payments (2)
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classes.primaryPointsValues}
              style={{
                marginTop: "1.5rem",
              }}
            >
              Yes
            </Typography>
            <Typography
              className={classes.primaryPointsValues}
              style={{
                marginBottom: "1rem",
              }}
            >
              Yes
            </Typography>
            <Typography
              className={classes.primaryPointsValues}
              style={{
                marginTop: "1.5rem",
              }}
            >
              $ 0
            </Typography>
            <Typography className={classes.secondaryPointsValues}>
              $ 0/m
            </Typography>
            <Typography className={classes.secondaryPointsValues}>
              $ 0
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            className={[classes.gridSpacing, classes.whiteCard]}
          >
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.cardHeading}
              >
                Revenue (Last 12 Months)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  minHeight: "18.75rem",
                }}
              >
                <Line data={lineChartData} options={smallChartOptions} />
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={9}
            style={{
              marginTop: "0.75rem",
              marginBottom: "0.75rem",
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: theme.palette.text.blue2,
              }}
            >
              Priorities
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            container
            justifyContent="flex-end"
            style={{
              marginTop: "0.75rem",
              marginBottom: "0.75rem",
            }}
          >
            <Button
              variant="text"
              startIcon={<AddIcon />}
              size="small"
              color="primary"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              style={{
                fontSize: "1rem",
                height: "auto",
              }}
              id='openAddPrioritesDialog'
              onClick={this.openAddPrioritesDialog.bind(this,{
                clientId: +this.props.selectedClient?.id,
              })}
            >
              Add New Item
            </Button>
          </Grid>
          {this.renderPriority(values, classes, theme, handleChange)}
        </Grid>
      </Grid>
    );
  };
  renderPriority = (values, classes, theme, handleChange) => {
    return (
      <DragDropContext onDragEnd={this.onDragEnd.bind(this, values)}>
        <Droppable droppableId="prioritiesList">
          {(provided) => {
            return (
              <Grid
                item
                xs={12}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Grid container alignItems="center">
                  <FieldArray name={`prioritiesList`}>
                    {(fieldArrayProps) => {
                      const { form } = fieldArrayProps;
                      const { values } = form;
                      return values.prioritiesList &&
                        values.prioritiesList.length > 0 ? (
                        values.prioritiesList.map((todo, index) => {
                          return (
                            <Draggable
                              draggableId={todo.id}
                              index={index}
                              key={todo.id}
                            >
                              {(provided) => (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    className={[classes.todoItemGrid]}
                                    container
                                    alignItems="center"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <DragIndicatorIcon
                                      style={{
                                        color: theme.palette.text.secondary,
                                      }}
                                    />
                                    <FastField>
                                      {({ field, form, meta }) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              name={`prioritiesList[${index}].attributes.status`}
                                              checked={
                                                values.prioritiesList[index]
                                                  .attributes.status
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                this.props.updatePriority(
                                                  e.target.checked,
                                                  +this.props.selectedClient
                                                    ?.id,
                                                  +todo.id
                                                );
                                              }}
                                            />
                                          }
                                          classes={{
                                            label: values.prioritiesList[index]
                                              .attributes.status
                                              ? classes.checkedTodoCheckboxLabel
                                              : classes.todoCheckboxLabel,
                                          }}
                                          style={{
                                            alignItems: "center",
                                            margin: 0,
                                          }}
                                          label={todo.attributes.name}
                                        />
                                      )}
                                    </FastField>
                                    <img
                                      src={deleteIcon}
                                      className={classes.todoDeleteIcon}
                                      onClick={() => {
                                        this.openDeleteDialog({
                                          clientId: +this.props.selectedClient
                                            ?.id,
                                          priorityId: +todo.id,
                                        });
                                      }}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        <Grid
                          item
                          xs={12}
                          className={[classes.todoItemGrid]}
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            className={classes.todoCheckboxLabel}
                            align="center"
                          >
                            No priorities available
                          </Typography>
                        </Grid>
                      );
                    }}
                  </FieldArray>
                  {provided.placeholder}
                </Grid>
              </Grid>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };
  renderClientRow = (classes, item, theme) => {
    return (
      <Grid
        key={item.id}
        item
        xs={12}
        style={{
          position: "relative",
        }}
        className={
          item.selected ? classes.selectedClientRowLeft : classes.clientRowLeft
        }
        onClick={() => {
          this.props.setSelectedClient(item);
        }}
      >
        <Grid container>
          <Grid item xs={1} className={classes.clientList1} />
          <Grid
            item
            xs={1}
            container
            alignItems="flex-start"
            justifyContent="center"
            className={classes.clientListCheckbox}
          >
            <Checkbox
              classes={{
                root: classes.checkboxRoot,
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon color="primary" />}
              checked={item.selected}
              onChange={() => {
                let newClientList = this.props.clientsList.map(
                  (mapClient: any) => {
                    if (mapClient.id === item.id) {
                      return {
                        ...mapClient,
                        selected: !mapClient.selected,
                      };
                    }
                    return mapClient;
                  }
                );
                this.props.setClientsList(newClientList);
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.clientList2}>
            <Box
              className={classes.clientListColorBar}
              style={{
                backgroundColor:
                  item?.attributes.profile_status.includes("Pending") ||
                  item?.attributes.profile_status.includes("Declined")
                    ? "#ed1867"
                    : theme.palette.text.blue2,
              }}
            />

            <Box
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0.75rem",
                backgroundImage: `url(${item?.attributes.avatar})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              className={classes.nameInitialsContainer}
            >
              {!item?.attributes.avatar ? (
                <Typography className={classes.nameIntialsLogo}>
                  {this.renderProfileLogo(item)}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={10} className={classes.clientList10}>
            <Grid container alignItems="flex-start">
              <Typography
                display="inline"
                color="textSecondary"
                className={classes.clientName}
                onClick={() => {
                  StorageProvider.set("clientId", +item.id);
                  StorageProvider.set("surveyId", "");
                  StorageProvider.set("surveyType", "");
                  StorageProvider.set("package_management_id", "");
                  StorageProvider.set("roadMapId", null);
                  this.props.history.push({
                    pathname: "/home/clientsList/details/overview",
                    state: { clientId: +item.id },
                  });
                }}
                noWrap
              >
                {this.renderName(item)}
              </Typography>
              <Box className={classes.individualTypography}>
                {item?.attributes.client_type}
              </Box>
              <Typography
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  position: "absolute",
                  top: 28,
                  right: 30,
                  color:
                    item?.attributes.profile_status.includes("Pending") ||
                    item?.attributes.profile_status.includes("Declined")
                      ? theme.palette.error.main
                      : theme.palette.text.blue2,
                }}
              >
                {item?.attributes.profile_status}
              </Typography>
            </Grid>
            <Typography
              color="textSecondary"
              className={classes.numberAndEmailTogether}
              style={{
                maxWidth: "30rem",
              }}
              noWrap
            >
              {this.renderPhone(item)}
            </Typography>
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  color="textSecondary"
                  className={classes.packagesEnrolledByUser}
                >
                  Enrolled In:{" "}
                  {item?.attributes?.enrolledPackage || "Not available"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <Formik
        initialValues={{
          prioritiesList: this.props.prioritiesList,
        }}
        enableReinitialize
        onSubmit={(values) => {
          this.updateChecklist(values);
        }}
      >
        {(formikProps) => {
          const { values, handleChange } = formikProps;

          return (
            <Form autoComplete="off" noValidate>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  className={[classes.gridSpacing]}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <TableContainer>
                    <Table
                      aria-label="client-list-table"
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0px 12px",
                      }}
                    >
                      <TableHead>
                        <TableRow
                          style={{
                            width: "100%",
                          }}
                        >
                          <StyledTableCell width="2%">
                            <Checkbox
                              classes={{
                                root: classes.headerCheckboxRoot,
                              }}
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon color="primary" />}
                              checked={this.props.headerCheckbox}
                              onChange={(event) => {
                                this.props.setHeaderCheckbox(event);
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell width="75%">{`Client Name (${this.props.clientsList.length} Clients)`}</StyledTableCell>
                          <StyledTableCell
                            width="23%"
                            align="right"
                            style={{
                              paddingRight: "30px",
                            }}
                          >
                            Status
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <Grid container className={[classes.leftSectionContainer]}>
                    {this.props.clientsList && this.props.clientsList.length > 0
                      ? this.props.clientsList.map((item, clientListIndex) => {
                          return this.renderClientRow(classes, item, theme);
                        })
                      : this.renderNoClient(classes)}
                  </Grid>
                </Grid>

                {/* RIGHT SECTION */}

                {this.renderRightSection(classes, theme, values, handleChange)}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ClientsListAdvancedWithRouter = withRouter(ClientsListAdvanced);
const ClientsListAdvancedAlertBox = withAlertBox(ClientsListAdvancedWithRouter);
const ClientsListAdvancedLoader = withLoader(ClientsListAdvancedAlertBox);
const ClientsListAdvancedToast = withToast(ClientsListAdvancedLoader);
const ClientsListAdvancedWithDialog = withDialog(ClientsListAdvancedToast);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: "1rem 0",
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      cardHeading: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        letterSpacing: 1,
      },
      clientRowLeft: {
        margin: "0.5rem 0",
        padding: "1.25rem 0",
        borderRadius: "1rem",
        backgroundColor: "#fff",
        boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.09)",
        cursor: "pointer",
        border: `2px solid ${theme.palette.common.white}`,
      },
      selectedClientRowLeft: {
        margin: "0.5rem 0",
        padding: "1.25rem 0",
        borderRadius: "1rem",
        backgroundColor: "#fff",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
        cursor: "pointer",
        border: `2px solid ${theme.palette.primary.main}`,
        overflowX: "hidden",
      },
      clientRow: {
        margin: theme.spacing(1.5, 0),
        padding: "1.25rem 0",
        borderRadius: "1rem",
        backgroundColor: "#fff",
        boxShadow: "rgba(149, 157, 165, 0.25) 0px 0px 16px",
        overflowX: "hidden",
      },
      clientListColorBar: {
        position: "absolute",
        left: "-2px",
        top: "15%",
        width: "4px",
        height: "70%",
      },
      individualTypography: {
        display: "inline-block",
        backgroundColor: "#fff",
        border: `1px solid ${theme.palette.text.blue2}`,
        borderRadius: "100vmax",
        marginLeft: "0.5rem",
        padding: "0.25rem 0.5rem",
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: theme.palette.text.blue2,
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9rem",
        },
      },
      clientName: {
        display: "inline-block",
        fontSize: "1.3rem",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        maxWidth: "12rem",
        [theme.breakpoints.down("md")]: {
          maxWidth: "8.5rem",
        },
      },
      packagesEnrolledByUser: {
        fontSize: "1.1rem",
        fontWeight: 600,
        color: theme.palette.text.secondary,
      },
      leftSectionContainer: {
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "62vh",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none", width: 0 },
      },
      rightSectionContainer: {
        borderRadius: theme.spacing(2),
        padding: "40px 20px 20px 20px",
        marginBottom: 0,
      },
      rightSectionInnerContainer: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1.5),
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: "68vh",
        paddingRight: "1rem",
        "&::-webkit-scrollbar": {
          width: "10px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          marginTop: "15px",
          marginBottom: "10px",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
      surveyNavLinkContainer: {
        marginTop: "1rem",
        marginBottom: "1.5rem",
      },
      surveyNavLink: {
        color: theme.palette.text.secondary,
        fontSize: "1.2rem",
      },
      primaryPointsKeys: {
        color: theme.palette.text.secondary,
        fontSize: "1.1rem",
        marginBottom: "1rem",
        fontWeight: "bold",
        paddingLeft: theme.spacing(1),
      },
      primaryPointsValues: {
        color: theme.palette.primary.main,
        fontSize: "1.1rem",
        marginBottom: "1rem",
        fontWeight: "bold",
      },
      secondaryPointsKeys: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        marginBottom: "0.9rem",
        fontWeight: "bold",
        paddingLeft: theme.spacing(3),
      },
      secondaryPointsValues: {
        color: theme.palette.primary.main,
        fontSize: "1rem",
        marginBottom: "0.9rem",
        fontWeight: "bold",
      },
      todoItemGrid: {
        backgroundColor: "#f8f8f8",
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
        borderRadius: theme.spacing(2),
      },
      todoTitle: {
        fontSize: "1.0625rem",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
      },
      numberAndEmailTogether: {
        margin: "0.5rem 0px",
        fontSize: "1rem",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#797979",
      },
      clientList1: {
        flexGrow: 0,
        maxWidth: "1%",
        flexBasis: "1%",
      },
      clientListCheckbox: {
        flexGrow: 0,
        maxWidth: "4%",
        flexBasis: "4%",
      },
      clientList2: {
        flexGrow: 0,
        maxWidth: "18%",
        flexBasis: "18%",
        marginLeft: "1%",
        marginRight: "2%",
      },
      clientList10: {
        flexGrow: 0,
        maxWidth: "74%",
        flexBasis: "74%",
        padding: "0.5rem 0",
      },
      checkboxRoot: {
        padding: 0,
        marginTop: "0.5rem",
        marginLeft: "0.2rem",
      },
      headerCheckboxRoot: {
        paddingLeft: "1rem",
      },
      nameInitialsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f3f3f3",
        borderRadius: "1rem",
      },
      nameIntialsLogo: {
        fontSize: "3rem",
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
      },
      rightSectionImageGrid: {
        flexGrow: 0,
        maxWidth: "20%",
        flexBasis: "20%",
      },
      rightSectionUserDetailsContainer: {
        flexGrow: 0,
        maxWidth: "62%",
        flexBasis: "62%",
        padding: "1rem 0",
      },
      stylesMoreIcon: {
        height: "1.5rem",
        width: "1.5rem",
        cursor: "pointer",
      },
      todoCheckboxLabel: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
      },
      checkedTodoCheckboxLabel: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
        textDecoration: "line-through",
      },
      todoDeleteIcon: {
        height: "22px",
        cursor: "pointer",
        marginLeft: "auto",
        marginRight: "1rem",
      },
    }),
  { withTheme: true }
)(withConfirmBox(ClientsListAdvancedWithDialog));
