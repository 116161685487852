//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
  Omit<MuiTextFieldProps, "name" | "value" | "error"> { }

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const InputFieldWithFormik = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      {...props}
      variant="outlined"
      InputLabelProps={{
        ...InputLabelProps, shrink: true, classes: {
          asterisk: classes?.astriklabel,
          outlined: classes?.outlinedLabel,
          shrink: classes?.shrink,
          root: classes?.lableroot,
        }
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: !props.multiline && classes?.input,
          root: classes?.inputfieledroot,
          multiline: classes.multiline,
          inputMultiline: classes.inputMultiline
        },
        notched: false,
      }}

    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot: {
        border: `2px solid #EAE8E9`,
        outline: "none",
        backgroundColor: '#EAE8E9',
        borderRadius: '12px',
      'label + &': {
        marginTop: 30,
        marginBottom: 20,
      },
      '& > fieldset': {
        border: 'none',
        '& active': {
          border: 'none'
        },
        '& focus': {
          border: 'none'
        }
      },
      "&.Mui-focused": {
        border: `2px solid ${theme.palette.text.blue2}`,
        backgroundColor: '#fff',
      },
      "&.Mui-error": {
        border: `2px solid #FF5C5C`,
        backgroundColor: '#EAE8E9',
        "&.Mui-focused": {
          backgroundColor: '#fff',
        }
      },
      "&.MuiSelect-root": {
        border: "none",
        outline: "none",
      },
      "& + p": {
        "&.MuiFormHelperText-root.Mui-error": {
          color: "#FF5C5C",
          fontSize: "0.8rem"
        }
      }
    },
    input: {
      borderRadius: '12px',
      position: 'relative',
      // backgroundColor: '#EAE8E9',
      fontSize: '1rem',
      fontFamily: "Nunito Sans",
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '0.875rem 0.875rem',
      border: "none",
      outline: "none",
      "&:focus": {
        border: "none",
        borderRadius: '12px',
        backgroundColor: '#fff',
        "&::placeholder": {
          color: theme.palette.text.blue2,
        }
      },
    },
    multiline: {
        padding: 0
    },
    inputMultiline: {
      borderRadius: '12px',
        position: 'relative',
        backgroundColor: '#EAE8E9',
        border: 'none',
        fontSize: '1rem',
        fontFamily: 'Nunito Sans',
        // fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        "&:focus": {
            border: "none",
            borderRadius: '12px',
            backgroundColor: '#fff',
            "&::placeholder": {
              color: theme.palette.text.blue2,
            }
          },
    },
    astriklabel: {
      fontFamily: "Nunito Sans",
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#959ead'
    },
    lableroot: {
      fontFamily: "Nunito Sans",
      fontSize: '0.875rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: theme.palette.text.blue,
      "&.Mui-focused": {
        color: theme.palette.text.blue2
      },
    },
    outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important'
    },
    shrink: {
      transform: 'translate(3px, 9px) scale(1)!important'
    }
  })
)(InputFieldWithFormik);
