//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Box, Divider } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Redirect } from "react-router-dom";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import CloseIcon from "@material-ui/icons/Close";
import { Props } from "../FirmFinancialsNew.web";
import { authFlowStyles } from "./Login.web";
import TernaryCheck from "../TernaryCheck.web";

export class PrivacyPolicy extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      tokenPrivacyPolicy: null,
    };
  }

  async componentDidMount() {
    const tokenPrivacyPolicy = await StorageProvider.get("authToken");
    if (tokenPrivacyPolicy) {
      this.setState({
        tokenPrivacyPolicy: tokenPrivacyPolicy,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <TernaryCheck
        condition={this.state.tokenPrivacyPolicy}
        trueComponent={<Redirect to="/home" />}
        falseComponent={
          <Box className={classes.rootBox}>
            <Grid container justifyContent="center" className={classes.rootContainer}>
              <Grid
                item
                xs={12}
                className={classes.crossButton}
                onClick={() => {
                  this.props.history.push({
                    pathname: "signup",
                    state: {
                      values: this.props.history.location?.state?.values,
                    },
                  });
                }}
              >
                <CloseIcon
                  style={{
                    fontSize: "1rem",
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.secondContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2" align="center" color="primary" className={[classes.bottomMargin]} id="page-heading-privacy-policy">
                      SmartPath.co
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" align="center" color="textSecondary" className={[classes.bottomMargin]}>
                      Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={[classes.bottomMargin, classes.topMargin, classes.divider]} />
                  </Grid>
                  <Grid item xs={11} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.introText}>
                      This privacy policy tells you how we use personal information collected. Please read this privacy policy before using the site or
                      submitting any personal information. By using the site, you are accepting the practices described in this privacy policy. These practices
                      may be changed, but any changes will be posted and changes will only apply to activities and information on a going forward, not
                      retroactive, basis. You are encouraged to review the privacy policy whenever you visit the site to make sure that you understand how any
                      personal information you provide will be used.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={[classes.orderedHeadings]}>
                      1 | Collection of Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.termsText}>
                      We collect personally identifiable information, like names, postal addresses, email addresses, etc., when voluntarily submitted by our
                      visitors. The information you provide is used to fulfill your specific request and to assist in the processing of your requests and/or
                      uses of the Site. This information is only used to fulfill your specific requests, unless you give us permission to use it in another
                      manner, for example, to add you to one of our mailing lists.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={[classes.orderedHeadings]}>
                      2 | Subscriber Client Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.termsText}>
                      To the extent that client data is provided by the Subscriber in using the Site, we will not use Subscriber client data for any reason
                      other than the affiliation between Subscriber and the Site. We will not sell the Subscriber’s client data, we will not market to
                      Subscriber’s clients, and we will not provide client information to any outside third party, except for when expressly allowed.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={[classes.orderedHeadings]}>
                      3 | Cookie/Tracking Technology
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.termsText}>
                      The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for gathering
                      information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how visitors use the
                      Site. Cookies can also help customize the Site for visitors. Personal information cannot be collected via cookies and other tracking
                      technology, however, if you previously provided personally identifiable information, cookies may be tied to such information. Aggregate
                      cookie and tracking information may be shared with third parties.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={[classes.orderedHeadings]}>
                      4 | Distribution of Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.termsText}>
                      We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do so
                      when: (1) permitted or required by law; or, (2) trying to protect against or prevent actual or potential fraud or unauthorized
                      transactions; or, (3) investigating fraud that has already taken place. The information is not provided to these companies for marketing
                      purposes.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={[classes.orderedHeadings]}>
                      5 | Commitment to Data Security
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.termsText}>
                      Your personally identifiable information is kept secure using data security best practices & encryption where applicable. Only authorized
                      employees, agents, and contractors (who have agreed to keep information secure and confidential) have access to this information. All
                      emails and newsletters from this site allow you to opt-out of further mailings.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" className={[classes.orderedHeadings]}>
                      5 | Privacy Contact Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={[classes.bottomMargin]}>
                    <Typography variant="h6" color="textSecondary" gutterBottom className={classes.termsText}>
                      If you have any questions, concerns, or comments about our privacy policy you may contact us using the contact information on our website.
                      We reserve the right to make changes to this policy. Any changes to this policy will be posted.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
      />
    );
  }
}

const PrivacyPolicyWithRouter = withRouter(PrivacyPolicy);
const PrivacyPolicyWithToast = withToast(PrivacyPolicyWithRouter);
const PrivacyPolicyWithLoader = withLoader(PrivacyPolicyWithToast);
const PrivacyPolicyWithAlertBox = withAlertBox(PrivacyPolicyWithLoader);
const PrivacyPolicyWithDialogBox = withDialog(PrivacyPolicyWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
  })
)(PrivacyPolicyWithDialogBox);
