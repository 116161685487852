//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasswordOTPController extends BlockComponent<Props, S, SS> {
  verifyOtpApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleVerifyOptResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.message === "Code Verified!") {
      this.props.history.push({
        pathname: "/codeVerified",
        state: {
          email: this.props?.history?.location?.state?.email,
        },
      });
    } else {
      this.props.showAlert({
        title: "Error",
        message: responseJson?.message,
      });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.verifyOtpApiCallId) {
        this.handleVerifyOptResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      this.props.showAlert({
        title: "System Error",
        message: AlertBodyMessage,
      });
    }
  }

  verifyOtp = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.verifyOtpApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        attributes: {
          primary_email: this.props?.history?.location?.state?.email,
          email_otp: +values.otp,
        },
      },
    };
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/verify_pin_forgot_password");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
