import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import PrivateRoute from '../../components/src/PrivateRoute.web';
import withSnackBar from '../../components/src/withSnackBar.Web';
import Login from '../../blocks/LandingPage2/src/Auth/Login.web';
import SignUp from '../../blocks/LandingPage2/src/Auth/SignUp.web';
import ForgotPassword from '../../blocks/LandingPage2/src/Auth/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/LandingPage2/src/Auth/ForgotPasswordOTP.web';
import CodeVerified from '../../blocks/LandingPage2/src/Auth/CodeVerified.web';
import SetNewPassword from '../../blocks/LandingPage2/src/Auth/SetNewPassword.web';
import TermsAndConditions from '../../blocks/LandingPage2/src/Auth/TermsAndConditions.web';
import PrivacyPolicy from '../../blocks/LandingPage2/src/Auth/PrivacyPolicy.web';
import SurveyStepper from '../../blocks/LandingPage2/src/cfsSurvey/SurveySteper.web';
import SurveyLandingPage from '../../blocks/LandingPage2/src/cfsSurvey/SurveylandingPage.web';
import NewUserDashboard from '../../blocks/LandingPage2/src/NewUserDashboard';
import OnBoardingSurveyLandingPage from '../../blocks/LandingPage2/src/onBoardingSurvey/OnBoardingSurveyLandingPage.web';
import OnBoardingSurvey from '../../blocks/LandingPage2/src/onBoardingSurvey/onBoardingSurvey.web';
import RoadmapPackageSelectionByClient  from "../../blocks/LandingPage2/src/roadmap/RoadmapPackageSelectionByClient.web";
import AdminPrivateRoute from '../../components/src/AdminPrivateRoute.web';
import AdminDashboard from '../../blocks/AdminConsole3/src/AdminDashboard.web';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme2';
import StorageProvider from "../../framework/src/StorageProvider.web";
import moment from 'moment';

let isSubscriptionEnded = false;
const { fetch: originalFetch } = window;
const isMakeRequest = (config,currentUser)=>{
    let trialPeriodEndTime;
    const currentTime = moment(new Date(new Date().setHours(0,0,0)));
    if(currentUser){
      currentUser = JSON.parse(currentUser);
        let createdAt = new Date(new Date(currentUser?.data?.attributes?.created_at).setHours(23));
        trialPeriodEndTime = moment(createdAt).add(14,'days');
    }
    let trialPeriod = trialPeriodEndTime?.diff(currentTime,'days');
    let canMakeARequest = false;
    let msg = '';
    if(!config?.headers?.token){
      canMakeARequest = true;
    }
    
    if(!canMakeARequest && config?.headers?.skipSubscriptionCheck){
      canMakeARequest = true; 
    }
    if(!canMakeARequest && trialPeriodEndTime && (trialPeriod > 0 || currentUser?.data?.attributes?.current_subscription?.data.length > 0 && currentUser?.data?.attributes?.current_subscription?.data[0].attributes.subscription_status == 'active')){
      canMakeARequest = true;
    }else {
      msg = 'Your trial period has been ended,please buy a subscription';
    }
    return {canMakeARequest,msg}
}
window.fetch = async (...args) => {
    let [resource, config ] = args;
    let currentUser = await StorageProvider?.get('currentUser');
    const {canMakeARequest,msg} = isMakeRequest(config,currentUser);
    let response;
    if(canMakeARequest){
      response = await originalFetch(resource, config);
    } else {
      if(window.location.pathname != '/home/dashboard' && !isSubscriptionEnded){
        isSubscriptionEnded = true;
        // alert(msg)
        window?.showToast({type:'error',message:msg})
        window.location.href = '/home/dashboard';
        return false;
      }
    }
    return response;
};

class RouteConfig extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute path="/home">
          <ThemeProvider theme={theme}>
            <NewUserDashboard />
          </ThemeProvider>
        </PrivateRoute>
        <AdminPrivateRoute path="/adminDashboard">
          <AdminDashboard />
        </AdminPrivateRoute>
        
        <Route exact path="/">
          <ThemeProvider theme={theme}>
            <Login />
          </ThemeProvider>
        </Route>
        <Route exact path="/signup">
          <ThemeProvider theme={theme}>
            <SignUp />
          </ThemeProvider>
        </Route>
        <Route exact path="/termsAndConditions">
          <ThemeProvider theme={theme}>
            <TermsAndConditions />
          </ThemeProvider>
        </Route>
        <Route exact path="/privacyPolicy">
          <ThemeProvider theme={theme}>
            <PrivacyPolicy />
          </ThemeProvider>
        </Route>
        <Route exact path="/surveyLandingPage">
          <ThemeProvider theme={theme}>
            <SurveyLandingPage />
          </ThemeProvider>
        </Route>
        <Route exact path="/startSurveySteps">
          <ThemeProvider theme={theme}>
            <SurveyStepper />
          </ThemeProvider>
        </Route>
        <Route exact path="/onboardingSurveyLandingPage">
          <ThemeProvider theme={theme}>
            <OnBoardingSurvey />
          </ThemeProvider>
        </Route>
        <Route exact path="/onboardingSurvey">
          <ThemeProvider theme={theme}>
            <OnBoardingSurveyLandingPage />
          </ThemeProvider>
        </Route>

        {/* phase 2 routes */}

        <Route exact path="/forgotPassword">
          <ThemeProvider theme={theme}>
            <ForgotPassword />
          </ThemeProvider>
        </Route>
        <Route exact path="/forgotPasswordOtp">
          <ThemeProvider theme={theme}>
            <ForgotPasswordOTP />
          </ThemeProvider>
        </Route>

        <Route exact path="/codeVerified">
          <ThemeProvider theme={theme}>
            <CodeVerified title="Code Verified!" />
          </ThemeProvider>
        </Route>

        <Route exact path="/setNewPassword">
          <ThemeProvider theme={theme}>
            <SetNewPassword />
          </ThemeProvider>
        </Route>

        <Route exact path="/passwordResetSuccessfully">
          <ThemeProvider theme={theme}>
            <CodeVerified title="Password Reset Successfully!" />
          </ThemeProvider>
        </Route>
        <Route exact path="/roadmapPackageSelectionByClient">
          <ThemeProvider theme={theme}>
            <RoadmapPackageSelectionByClient />
          </ThemeProvider>
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
}
export default withSnackBar(RouteConfig);