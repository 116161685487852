//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Theme,
  MenuItem,
  createStyles,
  WithStyles,
  Select,
} from "@material-ui/core";
import {
  CustomPackageFromScratch,
  CustomPackagefromSelectedPackage,
  CustomPackageFromScratchSelected,
  CustomPackagefromSelectedPackageSelected
} from "../assets";
import { withRouter, RouteComponentProps } from "react-router";
import withAlertBox, {
  withAlertBoxProps,
} from "../../../../components/src/withAlertBox.Web";
import withToast, {
  withToastProps,
} from "../../../../components/src/withSnackBar.Web";
import withLoader, {
  withLoaderProps,
} from "../../../../components/src/withLoader.Web";

import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

export const getComponentStyle = (theme: Theme): any => {
  return createStyles({
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.625rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginBottom: "1rem",
    },
    buttonContainer: {
      display: "flex",
      gap: "1.5rem",
    },
    bottomText: {
      fontFamily: "Nunito Sans",
      fontSize: "1.1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontWeight: 600,
      marginTop: "1rem",
    },
    packageBox: {
      backgroundColor: "#fff",
      boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      borderRadius: "1.125rem",
      padding: "2rem",
    },
    packageBoxText: {
      color: theme.palette.text.secondary,
      fontSize: "1.5rem",
      fontWeight: 600,
      marginTop: "0.75rem",
    },
    packageBoxSelected: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      borderRadius: "1.125rem",
      padding: "2rem",
      position: "relative",
      "&::after": {
        position: "absolute",
        content: "'✓'",
        color: theme.palette.primary.main,
        fontSize: "20px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `#fff no-repeat center center`,
        backgroundSize: "cover",
        right: "6%",
        top: "6%",
        height: "2rem",
        width: "2rem",
      },
    },
    packageBoxSelectedText: {
      color: "#fff",
      fontSize: "1.5rem",
      fontWeight: 600,
      marginTop: "0.75rem",
    },
    img: {
      height: "10rem",
    },
    packageSelectionHeading: {
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      fontWeight: 600,
      margin: "1rem 0",
    },
    breadcumLink: {
      fontSize: "1.325rem",
      cursor: "pointer",
    },
    breadcumLastLink: {
      fontSize: "1.325rem",
    },
  });
};
export type Props = RouteComponentProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  WithStyles<typeof getComponentStyle> & {
    id: string;
    packageList: any[];
    getPackages: () => void;
    goToPreviousStep: () => void;
    goToNextStep: () => void;
    handleCreatedPackageType: (type: boolean) => void;
    isCreateFromDefaultTemplate: boolean;
    setDefaultPackageTemplate: (event: any) => void;
    defaultPackageTemplate: any;
    goToFirmPricingCustomization: () => void;
    goToFirmPricing: () => void;

    // Customizable Area Start
    // Customizable Area End
  };

export class CustomPackageCreationLandingPage extends React.PureComponent<
  Props
> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.packageList?.length == 0) {
      this.props.getPackages();
    }
  }

  render() {
    const { classes } = this.props;
    let isContinueButtonDisable = true;
    if (!!this.props.isCreateFromDefaultTemplate) {
      if (
        this.props.defaultPackageTemplate &&
        this.props.defaultPackageTemplate !== "select"
      ) {
        isContinueButtonDisable = false;
      }
    } else {
      isContinueButtonDisable = false;
    }
    return (
      <Grid container alignItems="center" justifyContent="center">
       {
         !this.props.isFromRoadMap && <Grid xs={12} item>
         <Breadcrumbs separator=">" aria-label="breadcrumb">
           <Typography
             className={classes.breadcumLink}
             onClick={this.props.goToFirmPricingCustomization}
             color="primary"
           >
             Pricing Customization
           </Typography>
           <Typography
             className={classes.breadcumLink}
             onClick={this.props.goToFirmPricing}
             color="primary"
           >
             Firm Pricing
           </Typography>
           <Typography
             className={classes.breadcumLastLink}
             color="textSecondary"
           >
             Create Custom Package
           </Typography>
         </Breadcrumbs>
       </Grid>
       } 

        <Grid xs={12} item>
          <Typography className={classes.heading}>
            Create Custom Package
          </Typography>
        </Grid>
        <CustomGrid
          onClick={() => {
            this.props.handleCreatedPackageType(false);
          }}
          item
          width="30%"
          margin="5rem 3% 0 0"
          className={
            !this.props.isCreateFromDefaultTemplate
              ? classes.packageBoxSelected
              : classes.packageBox
          }
        >
          <img
            className={classes.img}
            src={!this.props.isCreateFromDefaultTemplate ? CustomPackageFromScratchSelected : CustomPackageFromScratch}
          />
          <Typography
            className={
              !this.props.isCreateFromDefaultTemplate
                ? classes.packageBoxSelectedText
                : classes.packageBoxText
            }
          >
            Create Custom Package
            <br /> From Scratch
          </Typography>
        </CustomGrid>

        <CustomGrid
          item
          width="30%"
          margin="5rem 0 0 3%"
          onClick={() => {
            this.props.handleCreatedPackageType(true);
          }}
          className={
            this.props.isCreateFromDefaultTemplate
              ? classes.packageBoxSelected
              : classes.packageBox
          }
        >
          <img
            className={classes.img}
            src={!this.props.isCreateFromDefaultTemplate ? CustomPackagefromSelectedPackageSelected : CustomPackagefromSelectedPackage}
          />
          <Typography
            className={
              this.props.isCreateFromDefaultTemplate
                ? classes.packageBoxSelectedText
                : classes.packageBoxText
            }
          >
            Create Custom Package
            <br /> Default Template
          </Typography>
        </CustomGrid>
        {this.props.isCreateFromDefaultTemplate && (
          <CustomGrid width="30%" margin="0 0 0 36%" item>
            <CustomTypography className={classes.packageSelectionHeading}>
              Pick which package you want to use as a starting point
            </CustomTypography>

            
              <Select
                labelId="label"
                displayEmpty
                renderValue={
                  this.props.defaultPackageTemplate !== "select"
                    ? undefined
                    : () => "Select Package"
                }
                name="defaultPackageTemplate"
                value={this.props.defaultPackageTemplate}
                onChange={(event) => {
                  this.props.setDefaultPackageTemplate(event);
                }}
                variant="outlined"
                size="small"
                fullWidth
              >
                {this.props.packageList.map((packageDetail: any) => {
                  return (
                    <MenuItem key={packageDetail.id} value={packageDetail.id}>
                      {packageDetail?.attributes?.name || packageDetail?.name}
                    </MenuItem>
                  );
                })}
                </Select>
             </CustomGrid>
        )}

        <CustomGrid width="10%" margin="3rem 0 0 76%" item>
          <Button
            fullWidth
            variant="outlined"
            onClick={this.props.goToFirmPricing}
            color="primary"
          >
            Back
          </Button>
        </CustomGrid>

        <CustomGrid width="10%" margin="3rem 2% 0 2%" item>
          <Button
            fullWidth
            variant="contained"
            disabled={isContinueButtonDisable}
            onClick={this.props.goToNextStep}
            color="primary"
          >
            Continue
          </Button>
        </CustomGrid>
      </Grid>
    );
  }
}
const CustomPackageCreationLandingPageWithRouter = withRouter(
  CustomPackageCreationLandingPage
);
const CustomPackageCreationLandingPageWithToast = withToast(
  CustomPackageCreationLandingPageWithRouter
);
const CustomPackageCreationLandingPageWithLoader = withLoader(
  CustomPackageCreationLandingPageWithToast
);
const CustomPackageCreationLandingPageWithAlertBox = withAlertBox(
  CustomPackageCreationLandingPageWithLoader
);

export default withStyles((theme: Theme) => getComponentStyle(theme))(
  CustomPackageCreationLandingPageWithAlertBox
);
