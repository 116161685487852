import React, { PureComponent, createRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

class ReactToPdf extends PureComponent {
  constructor(props) {
    super(props);
    this.targetRef = createRef();
  }

  toPdf = (uploadonly) => {
    return new Promise((resolve, reject) => {
      const {
        targetRef,
        filename,
        x,
        options,
      } = this.props;

      const source = targetRef || this.targetRef;
      const targetComponent = source.current || source;

      if (!targetComponent) {
        throw new Error('Target ref must be used or informed. See https://github.com/ivmarcos/react-to-pdf#usage.');
      }

      html2canvas(targetComponent, {
        logging: true,
        useCORS: true,
        scale: this.props.scale,
        quality: 4,
      }).then((canvas) => {
        console.log("canvas console", canvas)
        const imgData = canvas.toDataURL('image/png', 1.0);
        options.format = [canvas.width * 0.75, canvas.height * 0.8];
        const pdf = new jsPDF(options);
        pdf.addImage(imgData, 'PNG', x, 50);
        if (!uploadonly) {
          pdf.save(filename);
        }
        resolve(pdf.output('blob'));
      });
    });
  }

  render() {
    const { children } = this.props;
    return children({ toPdf: this.toPdf, targetRef: this.targetRef });
  }
}

ReactToPdf.defaultProps = {
  filename: 'download.pdf',
  options: undefined,
  x: 0,
  y: 0,
  scale: 1,
  onComplete: undefined,
  targetRef: undefined,
};

export default ReactToPdf;
