//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";

export class DeleteAdditionalDialog extends React.Component {
  

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ width: "30vw" }} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.dialogHeading}
          >
            Do you want to remove this field for all clients?
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <div>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.dialogsubHeading}
            >
              (This will automatically delete any data in this field for all
              other clients as well)
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} >
          <Grid spacing={1} container justifyContent="center">
          <Grid xs={6} item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!this.props.dataToPass?.field?.allow_field}
            className={classes.uploadNowButton}
            onClick={this.closeDialog.bind(this,'all')}
            id='yesButton'
          >
            Remove Field from All
          </Button>
          </Grid>
          <Grid xs={6} item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.successButton}
            id="noButton"
            onClick={this.closeDialog.bind(this,'onlyone')}
          >
            Remove Just for {this.props.dataToPass?.name || "this client"}
          </Button>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const DeleteAdditionalDialogWithRouter = withRouter(DeleteAdditionalDialog);
const DeleteAdditionalDialogWithToast = withToast(
  DeleteAdditionalDialogWithRouter
);
const DeleteAdditionalDialogWithLoader = withLoader(
  DeleteAdditionalDialogWithToast
);
const DeleteAdditionalDialogWithAlertBox = withAlertBox(
  DeleteAdditionalDialogWithLoader
);
const DeleteAdditionalDialogWithDialogBox = withDialogBox(
  DeleteAdditionalDialogWithAlertBox
);
export const dialogStyle = () => {
  return {
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    dialogsubHeading: {
      margin: "1rem 0",
      textAlign: "center",
    },
    uploadNowButton: {
      margin: "1rem 8px 1rem",
      background : '#F34635',
      color : '#fff',
      textTransform : 'none',
      '&:hover':{
        background : '#F34635',
      color : '#fff',
      }
    },
    successButton : {
      margin: "1rem 8px 1rem",
      color : '#fff',
      textTransform : 'none'
    }
  }
}
export default withStyles((theme) =>
  createStyles({
    ...dialogStyle()
  })
)(DeleteAdditionalDialogWithDialogBox);
