// @ts-nocheck
import React from "react";
import ManageUsersController, { Props } from "./ManageUsersController.web";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router-dom";
import withDialog from "../../../../components/src/withDialog.web";
import { Typography, Button, Grid, InputAdornment, withStyles, createStyles } from "@material-ui/core";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import { IOSSwitch } from "../../../../components/src/ModifiedComponents.web";
import { nameMask, spaceMask, mobileNumberMask } from "../masks";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ManageUsersCommonDialog from "./ManageUsersCommonDialog.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import TernaryCheck from "../TernaryCheck.web";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .required("This field is required"),
  last_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .required("This field is required"),
  primary_email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required"),
  phone_number: Yup.string()
    .matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone Number")
    .required("This field is required"),
  password: Yup.string()
    .min(8, "Password must contain at least 8 characters, one uppercase, one number and one special character")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special character"
    )
    .required("This field is required"),
  permissions: Yup.array(
    Yup.object({
      name: Yup.string(),
      permissions: Yup.array(
        Yup.object({
          id: Yup.number(),
          category: Yup.string(),
          permission: Yup.string(),
          is_permitted: Yup.boolean(),
        })
      ),
    })
  ),
});

export class CreateAndManageUser extends ManageUsersController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState({
      token: token,
      accountId: accountId,
    });
    if (this.props.type === "manage") {
      this.getSingleUser(this.props.history.location.state.singleUserId);
    }
  }

  openCreateUserDialog = (values) => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want to create this user?",
          yesButtonText: "Save",
          noButtonText: "Cancel",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.createUser(values);
        }
      });
  };

  openUpdateUserDialog = (values, singleUserId) => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want to save the changes?",
          yesButtonText: "Save",
          noButtonText: "Cancel",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.updateUser(values, singleUserId);
        }
      });
  };

  openDeleteDialog = (userId) => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want to delete this user?",
          yesButtonText: "Yes",
          noButtonText: "No",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.deleteUser(userId);
        }
      });
  };

  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  handleSwitchChange = (categoryIndex, option, optionIndex, setFieldValue, handleChange, e) => {
    if (option.permission === "Send Survey" && option.category === "Client Onboarding Survey") {
      setFieldValue(`permissions[${categoryIndex}].permissions[${optionIndex}].is_permitted`, e.target.checked);
      setFieldValue(`permissions[${categoryIndex}].permissions[${optionIndex + 1}].is_permitted`, e.target.checked);
    } else if (option.permission === "Access Survey Responses" && option.category === "Client Onboarding Survey") {
      setFieldValue(`permissions[${categoryIndex}].permissions[${optionIndex}].is_permitted`, e.target.checked);
      setFieldValue(`permissions[${categoryIndex}].permissions[${optionIndex - 1}].is_permitted`, e.target.checked);
    } else {
      handleChange(e);
    }
  };

  render(): React.ReactNode {
    const { classes, permissions } = this.props;

    return (
      <Formik initialValues={this.state.singleUser?.attributes} enableReinitialize validationSchema={validationSchema}>
        {(formikProps) => {
          const { values, handleChange, setFieldValue, isValid, dirty } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container justifyContent="center">
                <CustomGrid item container justifyContent="space-between" xs={11}>
                  <Typography className={classes.pageHeading} color="primary" id="createAndMangeUser-heading">
                    {this.props.type === "create" ? "Create User" : "Edit User"}
                  </Typography>
                </CustomGrid>
                <CustomGrid
                  item
                  xs={11}
                  style={{
                    marginTop: "1.5rem",
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <CustomGrid item xs={5} width="45%">
                      <Field
                        component={InputField}
                        name="first_name"
                        value={values?.first_name}
                        label="First Name"
                        onChange={(e) => handleChange(nameMask(e))}
                        fullWidth
                      />
                    </CustomGrid>
                    <CustomGrid item xs={5} width="45%">
                      <Field
                        component={InputField}
                        name="last_name"
                        value={values?.last_name}
                        label="Last Name"
                        onChange={(e) => handleChange(nameMask(e))}
                        fullWidth
                      />
                    </CustomGrid>
                    <CustomGrid item xs={5} width="45%">
                      <Field
                        component={InputField}
                        name="primary_email"
                        value={values?.primary_email}
                        label="Email ID"
                        onChange={(e) => handleChange(spaceMask(e))}
                        fullWidth
                        disabled={this.props.type === "manage"}
                      />
                    </CustomGrid>
                    <CustomGrid item xs={5} width="45%">
                      <Field
                        component={InputField}
                        name="phone_number"
                        value={values?.phone_number}
                        label="Phone Number"
                        onChange={(e) => handleChange(mobileNumberMask(e))}
                        fullWidth
                      />
                    </CustomGrid>
                    <CustomGrid item xs={5} width="45%">
                      <Field
                        component={InputField}
                        name="password"
                        type={this.state.isPasswordVisible ? "text" : "password"}
                        value={values?.password}
                        label="Password"
                        onChange={(e) => handleChange(spaceMask(e))}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={this.handlePasswordToggle}
                            >
                              <TernaryCheck
                                condition={this.state.isPasswordVisible}
                                trueComponent={<VisibilityOffIcon className={classes.visibilityIcon} />}
                                falseComponent={<VisibilityIcon className={classes.visibilityIcon} />}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CustomGrid>

                    <CustomGrid item xs={12}>
                      <CustomTypography padding="1rem 0 0 0" className={classes.pageHeading} color="primary">
                        Permission
                      </CustomTypography>
                    </CustomGrid>

                    {this.state.singleUser?.attributes?.permissions?.map((category, categoryIndex) => (
                      <React.Fragment key={category.name}>
                        <CustomGrid item xs={12}>
                          <CustomTypography padding="1.5rem 0" className={classes.pageHeading} color="secondary">
                            {category.name}
                          </CustomTypography>
                        </CustomGrid>
                        <CustomGrid item container xs={12} className={classes.whiteCard}>
                          {category.permissions.map((option, optionIndex) => (
                            <CustomGrid item xs={12} key={option.id}>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <CustomTypography color="textSecondary" fontSize="1.1rem" fontWeight={600}>
                                  {option.permission}
                                </CustomTypography>
                                <IOSSwitch
                                  name={`permissions[${categoryIndex}].permissions[${optionIndex}].is_permitted`}
                                  checked={values?.permissions[categoryIndex]?.permissions[optionIndex]?.is_permitted}
                                  onChange={this.handleSwitchChange.bind(this, categoryIndex, option, optionIndex, setFieldValue, handleChange)}
                                />
                              </Grid>
                            </CustomGrid>
                          ))}
                        </CustomGrid>
                      </React.Fragment>
                    ))}

                    <TernaryCheck
                      condition={this.props.type === "manage"}
                      trueComponent={
                        <CustomGrid item container justifyContent="flex-end" xs={12} margin="4rem 0px 1rem 0px">
                          <CustomGrid item xs={2} width="20%">
                            <Button
                              fullWidth
                              color="primary"
                              variant="outlined"
                              onClick={this.openDeleteDialog.bind(this, this.state.singleUser.id)}
                              disabled={!permissions.includes("Delete sub account-Account Creation")}
                            >
                              Delete User
                            </Button>
                          </CustomGrid>
                          <CustomGrid item xs={2} width="20%" margin="0 0 0 1rem">
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              disabled={!isValid}
                              onClick={this.openUpdateUserDialog.bind(this, values, this.state.singleUser.id)}
                            >
                              Save Changes
                            </Button>
                          </CustomGrid>
                        </CustomGrid>
                      }
                      falseComponent={
                        <CustomGrid item container justifyContent="flex-end" xs={12} margin="4rem 0px 1rem 0px">
                          <CustomGrid item xs={2} width="20%">
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              disabled={!(isValid && dirty)}
                              onClick={this.openCreateUserDialog.bind(this, values)}
                            >
                              Create User
                            </Button>
                          </CustomGrid>
                        </CustomGrid>
                      }
                    />
                  </Grid>
                </CustomGrid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const CreateAndManageUserWithRouter = withRouter(CreateAndManageUser);
const CreateAndManageUserAlertBox = withAlertBox(CreateAndManageUserWithRouter);
const CreateAndManageUserLoader = withLoader(CreateAndManageUserAlertBox);
const CreateAndManageUserToast = withToast(CreateAndManageUserLoader);
const CreateAndManageUserWithDialog = withDialog(CreateAndManageUserToast);
const CreateAndManageUserWithPermissions = withPermissions(CreateAndManageUserWithDialog);

export default withStyles((theme) =>
  createStyles({
    center: {
      textAlign: "center",
    },
    marginTop: {
      marginTop: "3rem",
    },
    gridSpacing: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 0),
      },
    },
    whiteCard: {
      padding: "1rem 2rem",
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      boxShadow: `0px 4px 8px 0px rgba(0,0,0,0.2)`,
    },
    pageHeading: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    visibilityIcon: {
      color: "#9c9c9c",
      cursor: "pointer",
    },
  })
)(CreateAndManageUserWithPermissions);
