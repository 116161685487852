//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  role: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SignUpController extends BlockComponent<Props, S, SS> {
  signUpApiCallId: string = "";
  getProfileDetailApiCallID: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      role: "",
      isPasswordVisible: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.signUpApiCallId) {
        this.handleSingupResponse(responseJson);
      } else if (this.getProfileDetailApiCallID === apiRequestCallId) {
        if (responseJson?.data?.id) {
          this.handleGetProfileResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "System Error",
        message: AlertBodyMessage,
      });
    }
  }
  handleSingupResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.meta?.token) {
      this.props.showToast({
        type: "success",
        message: "Signed Up successfully.",
      });
    await StorageProvider.set("currentUser", JSON.stringify(responseJson));
      await StorageProvider.set("authToken", responseJson?.meta?.token);
      await StorageProvider.set("accountId", +responseJson?.data?.id);
      await StorageProvider.set(
        "role",
        responseJson?.data?.attributes?.role?.name
      );
      this.setState(
        {
          token: responseJson?.meta?.token,
          accountId: +responseJson?.data?.id,
          role: responseJson?.data?.attributes?.role?.name,
        },
        () => {
          this.getProfile();
        }
      );
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };
  handleGetProfileResponse = async (responseJson: any) => {
    this.props.hideLoader();
    await StorageProvider.set("currentUser", JSON.stringify(responseJson));
    if (
      this.state.role === "Firm Employee User" ||
      this.state.role === "Firm Owner User"
    ) {
      this.props.history.push("/home");
    } else if (
      this.state.role === "Admin User" ||
      this.state.role === "Super Admin User"
    ) {
      this.props.history.push("/adminDashboard");
    }
  };

  handleSignUp = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          primary_email: values.email,
          password: values.password,
          first_name: values.firstName,
          last_name: values.lastName,
          firm_name: values.firmName,
          role_id: "Firm Owner User",
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getProfile = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
      skipSubscriptionCheck: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"account_block/accounts"}/${this.state.accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
