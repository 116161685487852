//@ts-nocheck
import React from "react";

import {
  Grid,
  withStyles,
  createStyles,
  TextField,
  InputAdornment,
  Hidden,
} from "@material-ui/core";

import {  moreIcon } from "../assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import SearchIcon from "@material-ui/icons/Search";

import SingleClient from "./SingleClient.web";
import OnboardingCustomization from "./OnboardingCustomization.web";
import ValueRoadmap from "./ValueRoadmap.web";
import ClientDocumentUpload from "./clientDocumentUpload.web";
export class ClientDetailsContainer extends React.Component {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    
    this.setState(
      {
        token: token,
        accountId: accountId,
      }
    );
  }
  goToClient = () => {
    StorageProvider.set("clientId", +this.props.selectedClient.id);
    this.props.history.push({
      pathname: "/home/clientsList/details/overview",
      state: { clientId: +this.props.selectedClient.id },
    });
  }

  render() {
    const { classes } = this.props;
    const navLinks = [
      {
        title: "Client Overview",
        url: "/home/clientsList/details/overview",
      },
      {
        title: "Value Roadmap",
        url: "/home/clientsList/details/valueRoadmap",
      },
      {
        title: "Onboarding Customization",
        url: "/home/clientsList/details/onboardingCustomization",
      },
      {
        title: "Documents",
        url: "/home/clientsList/details/documents",
      },
    ];
    return (
      <Grid
        container
        justifyContent="space-between"
        style={{
          position: "relative",
        }}
      >
        <Hidden smUp>
          <Grid item xs={12} className={[classes.gridSpacing]}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      paddingRight: 0,
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Hidden>

        <Grid
          item
          xs={10}
          style={{
            marginBottom: "1rem",
          }}
        >
          {navLinks.map((archive) => {
            return (
              <NavLink
                key={archive.url}
                to={archive.url}
                className={classes.sideLink}
                activeClassName={classes.activeSideLink}
              >
                {archive.title}
              </NavLink>
            );
          })}
        </Grid>

        <Grid
          item
          xs={2}
          container
          justifyContent="flex-end"
          style={{
            marginBottom: "1rem",
          }}
        >
          <img
            src={moreIcon}
            alt="moreIcon"
            className={classes.stylesMoreIcon}
            onClick={this.goToClient}
          />
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route path="/home/clientsList/details/overview" exact>
              <SingleClient />
            </Route>
            <Route path="/home/clientsList/details/valueRoadmap" exact>
              <ValueRoadmap />
            </Route>
            <Route
              path="/home/clientsList/details/onboardingCustomization"
              exact
            >
              <OnboardingCustomization />
            </Route>
            <Route path="/home/clientsList/details/documents" exact>
              <ClientDocumentUpload />
            </Route>

            {/* redirects */}
            <Redirect
              exact
              from="/home/clientsList/details"
              to="/home/clientsList/details/overview"
            />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

const ClientDetailsContainerWithRouter = withRouter(ClientDetailsContainer);
const ClientDetailsContainerAlertBox = withAlertBox(
  ClientDetailsContainerWithRouter
);
const ClientDetailsContainerLoader = withLoader(ClientDetailsContainerAlertBox);
const ClientDetailsContainerToast = withToast(ClientDetailsContainerLoader);
const ClientDetailsContainerWithDialog = withDialog(
  ClientDetailsContainerToast
);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      sideLink: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        color: theme.palette.text.secondary,
        cursor: "pointer",
        textAlign: "left",
        textDecoration: "none",
        fontWeight: "bold",
        borderBottom: "3px solid #f4f4f4",
        padding: "0px 10px",
        "&:first-of-type": {
          paddingLeft: "0px",
        },
      },
      activeSideLink: {
        color: theme.palette.text.blue2,
        textTransform: "none",
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "1.3158rem",
        fontWeight: "bold",
        borderBottom: `3px solid ${theme.palette.text.blue2}`,
        padding: "0px 10px",
      },
      stylesMoreIcon: {
        height: "1.5rem",
        width: "1.5rem",
        cursor: "pointer",
        marginRight: "1.5rem",
      },
    }),
  { withTheme: true }
)(withConfirmBox(ClientDetailsContainerWithDialog));
