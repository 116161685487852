//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Theme,
  TextField,
  Divider,
  IconButton,
  Box,
  Tooltip,
  TooltipProps,
  InputAdornment,
  makeStyles,
  createStyles,
  WithStyles,
} from "@material-ui/core";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withRouter, RouteComponentProps } from "react-router";
import withAlertBox, {
  withAlertBoxProps,
} from "../../../../components/src/withAlertBox.Web";
import withToast, {
  withToastProps,
} from "../../../../components/src/withSnackBar.Web";
import withLoader, {
  withLoaderProps,
} from "../../../../components/src/withLoader.Web";
import withDialog, {
  DialogProps,
} from "../../../../components/src/withDialog.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import TernaryCheck from "../TernaryCheck.web";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CategoryFilterDialog from "./CategoryFilterDialog.web";

export const formatCurrency = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  roundingMode:'ceil'

});

export const formatField = new Intl.NumberFormat("en-US");

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  tooltip: {
    fontFamily: "Lato",
    fontSize: "0.8rem",
    fontWeight: 600,
    backgroundColor: "#fff",
    color: (props) => {
      return props.color ? props.color : theme.palette.text.blue2;
    },
    border: (props) => {
      return props.color
        ? `1px solid ${props.color}`
        : `1px solid ${theme.palette.text.blue2}`;
    },
    borderRadius: "8px",
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap(props);

  return <Tooltip classes={classes} {...props} />;
}

export const getComponentStyle = (theme: Theme): any => {
  return createStyles({
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.625rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: theme.palette.primary.main,
      fontWeight: 600,
      margin: "1rem 0",
    },
    buttonContainer: {
      display: "flex",
      gap: "1.5rem",
    },
    bottomText: {
      fontFamily: "Nunito Sans",
      fontSize: "1.1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontWeight: 600,
      marginTop: "1rem",
    },
    ObjectivesBox: {
      backgroundColor: "#fff",
      borderRadius: "0.5rem",
      border: "1px solid lightgray",
      padding: "1rem",
    },
    packageBox: {
      backgroundColor: "#fff",
      boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      borderRadius: "1.125rem",
      padding: "2rem",
    },
    packageBoxText: {
      color: theme.palette.text.secondary,
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    packageBoxSelected: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      borderRadius: "1.125rem",
      padding: "2rem",
      position: "relative",
      "&::after": {
        position: "absolute",
        content: "''",
        color: theme.palette.primary.main,
        fontSize: "20px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `#fff no-repeat center center`,
        backgroundSize: "cover",
        right: "3%",
        top: "3%",
        height: "2rem",
        width: "2rem",
      },
    },
    packageBoxSelectedText: {
      color: "#fff",
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    img: {
      width: "10rem",
      height: "10rem",
    },
    packageSelectionHeading: {
      color: theme.palette.text.secondary,
      fontSize: "1.3rem",
      fontWeight: 600,
    },
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: theme.spacing(1, 0),
    },
    chip: {
      margin: theme.spacing(0.5),
      color: "#fff",
    },
    deleteIconColorPrimary: {
      color: "#fff",
    },
    sectionDragIcon: {
      color: "#c8c8c8",
      fontSize: "2rem",
      cursor: "pointer",
      display: "inline-block",
    },
    paper: {
      padding: theme.spacing(1),
    },
    infoBoxContainer: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      fontSize: "1rem",
      padding: theme.spacing(0.5),
      width: "100%",
      borderRadius: "1.125rem",
    },
    dividerRoot: {
      width: "94%",
      margin: "0 auto",
    },
    allFeatureContainer: {
      boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.088)",
      borderRadius: "0.5rem",
      minHeight: "22rem",
      maxHeight: "22rem",
      overflowY: "auto",
      padding: "1rem",
      "&::-webkit-scrollbar": {
        width: "8px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    selectedFeaturesScroll: {
      boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.088)",
      borderRadius: "0.5rem",
      minHeight: "22rem",
      maxHeight: "22rem",
      overflowY: "auto",
      padding: "1rem",
      marginTop: "1rem",
      "&::-webkit-scrollbar": {
        width: "8px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    selectedFeatureContainer: {
      minHeight: "100%",
      padding: "1rem",
    },
    draggableCardLeft: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "1rem",
      padding: "0.5rem",
      margin: "1rem 0",
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
    },
    draggableCardRight: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "1rem",
      padding: "1rem 0.5rem",
      margin: "0.5rem 0",
      position: "relative",
    },
    cancelIcon: {
      top: "-12px",
      right: "-8px",
      position: "absolute",
      pointer: "cursor",
    },
    customDivider: {
      height: "1px",
      backgroundColor: "lightgray",
      width: "100%",
    },
    packageNameInput: {
      color: theme.palette.primary.main,
      fontSize: "1.425rem",
    },
    filterIconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${theme.palette.primary.main}`,
      padding: "4px 8px",
      cursor: "pointer",
      borderRadius: "6px"
    },
    breadcumLink: {
      fontSize: "1.325rem",
      cursor: "pointer",
    },
    breadcumLastLink: {
      fontSize: "1.325rem",
    },
    noFeatureAvailable: {
      textAlign: "center",
      lineHeight: "15rem",
    },
    autoCompletedRadius: {
      borderRadius: "100vmax",
      paddingRight: "1.5rem!important",
      "& input": {
        borderRadius: "100vmax",
        "&:focus": {
          borderRadius: "100vmax",
        }
      }
    },
  });
};
export type Props = RouteComponentProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  WithStyles<typeof getComponentStyle> & {
    id: string;
    // Customizable Area Start
    goToPreviousStep: () => void;
    goToNextStep: () => void;
    getAllFeatures: () => void;
    getAllFeaturesofSelectedPackage: () => void;
    selectedPackageDetail: any;
    setAllFeatureList: () => void;
    setSelectedFeatureList: () => void;
    selectedFeatureList: any[];
    allFeatureList: any[];
    previousQuestion: () => void;
    nextQuestion: () => void;
    handleQuestionAnswerChange: () => void;
    handleFilterDelete: () => void;
    defaultPackageTemplate: any;
    filterQuestionList: any[];
    currentQuestionIndex: number;
    chipData: [];
    removeSelectedFeatures: () => void;
    setSelectedFeatureAndRemoveFromAllFilterList: () => void;
    removeFeatureFromSelectedFeatureListAndAddtoAllFilterList: () => void;
    totalOneTimeFess: number;
    totalRecurringFess: number;
    goToFirmPricingCustomization: () => void;
    goToFirmPricing: () => void;
    goToStep0: () => void;
    // Customizable Area End
  };
export class CustomPackageCreationSelectFeaturesPage extends React.PureComponent<
  Props
> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    if (
      this.props.defaultPackageTemplate &&
      this.props.defaultPackageTemplate !== "select"
    ) {
      if (this.props.isCustomPackageTemplate) {
        if (this.props.selectedFeatureList.length == 0) {
          this.props.getAllFeaturesofSelectedCustomPackage();
        }
      } else {
        if (this.props.selectedFeatureList.length == 0) {
          this.props.getAllFeaturesofSelectedPackage();
        }
      }
    } else {
      this.props.getAllFeatures();
    }
  }

  openFilterDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          categories: this.props.selectedCategory,
          objectives: this.props.chipData
            .filter((chip) => {
              return !chip.type;
            })
            .map((chip) => {
              return {
                name: chip.key,
                displayName: chip.label,
                value: chip.label,
              };
            }),
        },
        width: "60%",
        renderedComponent: CategoryFilterDialog,
        withCustomDialog: false,
      })
      .then((filterData: any) => {
        this.props.handleChipWhenFilterApplied(filterData);
      });
  };
  getAllFeatures = () => {
    const features = [];
    this.props.allFeatureList.forEach((cat) => {
      cat?.attributes?.features?.data.forEach((fet) => {
        features.push(fet);
      });
    });
    return features;
  };
  renderBreadcrub = () => {
    const { classes } = this.props;
    return (
      !this.props.isFromRoadMap && (
        <Grid xs={12} item>
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            <Typography
              className={classes.breadcumLink}
              onClick={this.props.goToFirmPricingCustomization}
              color="primary"
            >
              Pricing Customization
            </Typography>
            <Typography
              className={classes.breadcumLink}
              onClick={this.props.goToFirmPricing}
              color="primary"
            >
              Firm Pricing
            </Typography>
            {!this.props.packageType && (
              <Typography
                onClick={this.props.goToStep0}
                className={classes.breadcumLink}
                color="primary"
              >
                Create Custom Package
              </Typography>
            )}

            <Typography
              className={classes.breadcumLastLink}
              color="textSecondary"
            >
              Select Custom Package Features
            </Typography>
          </Breadcrumbs>
        </Grid>
      )
    );
  };
  sort = (a, b) => {
    if (
      (a?.attributes?.features?.data?.length || 0) >
      (b?.attributes?.features?.data?.length || 0)
    ) {
      return -1;
    } else if (
      (b?.attributes?.features?.data?.length || 0) >
      (a?.attributes?.features?.data?.length || 0)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <DragDropContext onDragEnd={this.props.onFeatureDragEnd.bind(this)}>
        <Grid container justifyContent="space-between">
          {this.renderBreadcrub()}

          <CustomGrid xs={5} width="48%" item>
            <Grid container>
              <Grid xs={12} item>
                <Typography className={classes.heading}>
                  Package Objectives
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <div className={classes.ObjectivesBox}>
                  <Grid
                    container

                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid xs={9} item>
                      <Typography color="textSecondary">
                        {
                          this.props.filterQuestionList[
                            this.props.currentQuestionIndex
                          ].questionName
                        }
                      </Typography>
                    </Grid>
                    <Grid xs={3} item>
                      <Box
                        display={"flex"}
                        style={{
                          alignItems: "center",
                          justifyContent: "flex-end",
                          marginTop: "-2px",
                        }}
                      >
                        <ChevronLeftIcon
                          onClick={this.props.previousQuestion}
                          style={{ cursor: "pointer" }}
                          color="primary"
                        />
                        <Typography color="primary">
                          {this.props.currentQuestionIndex + 1} of 6
                        </Typography>
                        <ChevronRightIcon
                          color="primary"
                          style={{ cursor: "pointer" }}
                          onClick={this.props.nextQuestion}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} item>
                      <RadioGroup
                        row
                        aria-label="position"
                        onChange={this.props.handleQuestionAnswerChange}
                        name={
                          this.props.filterQuestionList[
                            this.props.currentQuestionIndex
                          ].name
                        }
                        value={
                          this.props.filterQuestionList[
                            this.props.currentQuestionIndex
                          ].value
                        }
                      >
                        {this.props.filterQuestionList[
                          this.props.currentQuestionIndex
                        ].options.map((option, index) => {
                          return (
                            <FormControlLabel
                              key={option.label}
                              value={option.value}
                              control={<Radio color="primary" />}
                              label={option.label}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid xs={12} item>
                <CustomTypography
                  margin="0.75rem 0"
                  className={classes.heading}
                >
                  Available Features
                </CustomTypography>
              </Grid>
              <Grid xs={12} item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CustomGrid width="90%" xs={10} item>
                    <Autocomplete
                      id="free-solo-2-demo"
                      noOptionsText="No feature found"
                      fullWidth
                      options={this.getAllFeatures().map(
                        (option) => option?.attributes?.feature_name
                      )}
                      value={this.props.selectedSearchField}
                      onChange={this.props.searchFeature.bind(this)}
                      classes={{
                        inputRoot: classes.autoCompletedRadius,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          placeholder="Search.."
                          margin="none"
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                {!this.props.selectedSearchField ? (
                                  <SearchIcon />
                                ) : (
                                  <CancelRoundedIcon
                                    onClick={this.props.cancelSearch.bind(this)}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </CustomGrid>
                  <CustomGrid width="7%" item xs={1} className={classes.filterIconContainer}>
                    <Box
                      onClick={this.openFilterDialog.bind(this)}
                      id="filterBox"
                      
                    >
                      <FilterListRoundedIcon color="primary" />
                    </Box>
                  </CustomGrid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box component="ul" className={classes.chipContainer}>
                  {this.props.chipData?.map((data) => {
                    return (
                      <li key={data.key}>
                        <Chip
                          color="primary"
                          variant="default"
                          size="small"
                          label={data.label}
                          onDelete={() => this.props.handleFilterDelete(data)}
                          className={classes.chip}
                          classes={{
                            deleteIconColorPrimary:
                              classes.deleteIconColorPrimary,
                          }}
                        />
                      </li>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">
                  Showing {this.props.totalAvailalbeFeatures} Features
                </Typography>
              </Grid>
              <Grid
                item
                style={{ marginTop: "1rem" }}
                xs={12}
                className={classes.allFeatureContainer}
              >
                <Droppable droppableId="allfeaturesDroppable">
                  {(allfeaturesDroppableProvided) => {
                    return (
                      <Grid
                        container
                        ref={allfeaturesDroppableProvided.innerRef}
                        {...allfeaturesDroppableProvided.droppableProps}
                      >
                        {this.props.allFeatureList.length > 0 ? (
                          this.props.allFeatureList
                            .sort(this.sort.bind(this))
                            .map((feature, featureIndex) => {
                              return (
                                <>
                                  <Grid key={feature.id} item xs={12}>
                                    <Grid container>
                                      <Grid
                                        xs={12}
                                        style={{ textAlign: "center" }}
                                        item
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="h2"
                                        >
                                          {feature?.attributes?.category_name}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={12} item>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                        >
                                          {feature?.attributes?.features?.data
                                            .length > 0 ? (
                                            feature?.attributes?.features?.data.map(
                                              (
                                                subFeatures: any,
                                                sectionIndex: number
                                              ) => {
                                                const newIndex = +(
                                                  "" +
                                                  featureIndex +
                                                  sectionIndex
                                                );
                                                return (
                                                  <Draggable
                                                    draggableId={
                                                      subFeatures?.id +
                                                      "_" +
                                                      feature.id
                                                    }
                                                    index={newIndex}
                                                    key={subFeatures?.id}
                                                  >
                                                    {(
                                                      sectionDraggableProvided
                                                    ) => {
                                                      return (
                                                        <Grid
                                                          item
                                                          xs={6}
                                                          container
                                                          alignItems="center"
                                                          justifyContent="space-between"
                                                          {...sectionDraggableProvided.draggableProps}
                                                          {...sectionDraggableProvided.dragHandleProps}
                                                          ref={
                                                            sectionDraggableProvided.innerRef
                                                          }
                                                          className={
                                                            classes.draggableCardLeft
                                                          }
                                                        >
                                                          <Grid xs={1} item>
                                                            <DragIndicatorIcon
                                                              className={
                                                                classes.sectionDragIcon
                                                              }
                                                            />
                                                          </Grid>
                                                          <Grid xs={10} item>
                                                            <div>
                                                              <Typography color="textSecondary">
                                                                {
                                                                  subFeatures
                                                                    ?.attributes
                                                                    ?.feature_name
                                                                }
                                                              </Typography>
                                                              <Box
                                                                display={"flex"}
                                                                style={{
                                                                  gap: "1rem",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              >
                                                                <Typography
                                                                  color="primary"
                                                                  display="inline"
                                                                >
                                                                  ${" "}
                                                                  {formatCurrency.format(
                                                                    subFeatures
                                                                      ?.attributes
                                                                      ?.default_cost
                                                                  )}
                                                                </Typography>
                                                                <Typography
                                                                  display="inline"
                                                                  color="textSecondary"
                                                                >
                                                                  {`${subFeatures?.attributes?.payment_period}`}{" "}
                                                                  {subFeatures
                                                                    ?.attributes
                                                                    ?.payment_frequency &&
                                                                    `(${subFeatures?.attributes?.payment_frequency})`}
                                                                </Typography>
                                                              </Box>
                                                            </div>
                                                          </Grid>
                                                        </Grid>
                                                      );
                                                    }}
                                                  </Draggable>
                                                );
                                              }
                                            )
                                          ) : (
                                            <Grid item xs={12}>
                                              <Grid
                                                justifyContent="center"
                                                container
                                              >
                                                <Grid xs={10} item>
                                                  <Typography align="center">
                                                    No features available under
                                                    this category
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <TernaryCheck
                                    condition={
                                      featureIndex <
                                      this.props.allFeatureList.length - 1
                                    }
                                    trueComponent={
                                      <Box
                                        className={classes.customDivider}
                                        style={{
                                          marginBottom: "1rem",
                                        }}
                                      />
                                    }
                                    falseComponent={null}
                                  />
                                </>
                              );
                            })
                        ) : (
                          <Grid item xs={12}>
                            <Grid justifyContent="center" container>
                              <Grid xs={10} item>
                                <Typography align="center">
                                  No data available
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {allfeaturesDroppableProvided.placeholder}
                      </Grid>
                    );
                  }}
                </Droppable>
              </Grid>
            </Grid>
          </CustomGrid>
          <Divider flexItem orientation="vertical" />
          <CustomGrid xs={6} item>
            <Grid container>
              <Grid xs={12} item>
                <TextField
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.packageNameInput,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <BorderColorOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  name="name"
                  onChange={(event) => {
                    this.props.handleNameChange(event.target.value);
                  }}
                  variant="outlined"
                  value={this.props.name}
                />
              </Grid>
              <Grid
                xs={12}
                item
                className={classes.selectedFeaturesScroll}
              >
                <Droppable droppableId="selectedfeaturesDroppable">
                  {(selectedfeaturesDroppableProvided) => {
                    return (
                      <Grid
                        container
                        justifyContent="center"
                        ref={selectedfeaturesDroppableProvided.innerRef}
                        {...selectedfeaturesDroppableProvided.droppableProps}
                        className={classes.selectedFeatureContainer}
                        style={{
                          alignContent: this.props.selectedFeatureList?.length == 0 ? "center" : "flex-start"
                        }}
                      >
                        {this.props.selectedFeatureList.length > 0 ? (
                          this.props.selectedFeatureList
                            .filter((s: any) => {
                              return !s?.attributes?._destroy;
                            })
                            .sort((a, b) => {
                              let fa = a?.attributes?.order_number,
                                fb = b?.attributes?.order_number;

                              if (fa < fb) {
                                return -1;
                              }
                              if (fa > fb) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((subFeatures: any, sectionIndex: number) => {
                              return (
                                <Draggable
                                  draggableId={
                                    subFeatures.id +
                                    "_" +
                                    subFeatures?.attributes?.package_category
                                      .category_id +
                                    "_" +
                                    subFeatures?.attributes?.feature_name +
                                    "_" +
                                    subFeatures.attributes.unique_feature_id
                                  }
                                  index={sectionIndex}
                                  key={subFeatures.attributes.feature_id + "_" + subFeatures.attributes.id + "_" + sectionIndex}
                                >
                                  {(selectedFeatureDraggableProvided) => {
                                    return (
                                      <Grid
                                        item
                                        xs={12}
                                        {...selectedFeatureDraggableProvided.draggableProps}
                                        {...selectedFeatureDraggableProvided.dragHandleProps}
                                        ref={
                                          selectedFeatureDraggableProvided.innerRef
                                        }
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            className={
                                              classes.draggableCardRight
                                            }
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <CancelRoundedIcon
                                              className={classes.cancelIcon}
                                              color="error"
                                              onClick={() => {
                                                this.props.removeSelectedFeatures(
                                                  subFeatures
                                                );
                                              }}
                                            />
                                            <Grid
                                              xs={1}
                                              item
                                              container
                                              alignItems="center"
                                            >
                                              <DragIndicatorIcon
                                                className={
                                                  classes.sectionDragIcon
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              xs={11}
                                              item
                                              container
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <Typography
                                                style={{
                                                  width: "40%",
                                                }}
                                                color="textSecondary"
                                              >
                                                {
                                                  subFeatures?.attributes
                                                  ?.feature_name_new || subFeatures?.attributes
                                                    ?.feature_name
                                                }
                                              </Typography>
                                              <Typography
                                                color="primary"
                                                display="inline"
                                              >
                                                ${" "}
                                                {formatCurrency.format(
                                                  subFeatures?.attributes
                                                    ?.default_cost
                                                )}
                                              </Typography>
                                              <Typography
                                                color="textSecondary"
                                                display="inline"
                                              >
                                                {
                                                  subFeatures?.attributes
                                                    ?.payment_period
                                                }
                                              </Typography>
                                              <CustomTypography
                                                margin="0 0.75rem 0 0"
                                                color="textSecondary"
                                                display="inline"
                                              >
                                                {subFeatures?.attributes
                                                  ?.payment_frequency &&
                                                  `(${subFeatures?.attributes?.payment_frequency})`}
                                              </CustomTypography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  }}
                                </Draggable>
                              );
                            })
                        ) : (
                          <div className={classes.noFeatureAvailable}>
                            <Typography>
                              Drag Features from your list here to start
                            </Typography>
                            <Typography>
                              building your Custom Package.
                            </Typography>
                          </div>
                        )}
                        {selectedfeaturesDroppableProvided.placeholder}
                      </Grid>
                    );
                  }}
                </Droppable>
              </Grid>
              <Box className={classes.customDivider} mt={5} mb={2} />
              <Grid xs={12} item>
                <Grid container>
                  <Grid xs={12} item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CustomGrid item xs={5} container alignItems="center">
                        <Typography color="textSecondary">
                          Total Recurring Fees:
                        </Typography>
                        <BootstrapTooltip
                          title="Recuring Fees are calcutated over a 12 month period."
                          interactive
                          placement="right-end"
                          color={theme.palette.primary.main}
                        >
                          <IconButton
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            aria-label="delete"
                          >
                            <InfoOutlinedIcon
                              style={{
                                color: theme.palette.primary.main,
                                fontSize: "1.3rem",
                              }}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      </CustomGrid>
                      <CustomGrid item xs={5}>
                        <Typography color="textSecondary" align="right">
                          ${" "}
                          {formatCurrency.format(this.props.totalRecurringFess)}
                        </Typography>
                      </CustomGrid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CustomGrid item xs={5} container alignItems="center">
                        <Typography color="textSecondary">
                          Total One-Time Fees:
                        </Typography>
                        <BootstrapTooltip
                          title="One-time fees are services you will render only once. Ex: Accounting Setup or Entity Formation."
                          interactive
                          placement="right-end"
                          color={theme.palette.primary.main}
                        >
                          <IconButton
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            aria-label="delete"
                          >
                            <InfoOutlinedIcon
                              style={{
                                color: theme.palette.primary.main,
                                fontSize: "1.3rem",
                              }}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      </CustomGrid>
                      <CustomGrid item xs={5} align="right">
                        <Typography color="textSecondary">
                          $ {formatCurrency.format(this.props.totalOneTimeFess)}
                        </Typography>
                      </CustomGrid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CustomTypography color="primary" fontWeight={700}>
                        Total Fees:
                      </CustomTypography>
                      <CustomTypography
                        color="primary"
                        fontWeight={700}
                        align="right"
                      >
                        ${" "}
                        {formatCurrency.format(
                          this.props.totalRecurringFess +
                            this.props.totalOneTimeFess
                        )}
                      </CustomTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box className={classes.customDivider} mt={1} mb={2} />
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.buttonContainer}
                  justifyContent="flex-end"
                >
                  <Grid xs={2} container item>
                    {!this.props.packageType && (
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={this.props.goToPreviousStep}
                        color="primary"
                      >
                        Back
                      </Button>
                    )}
                  </Grid>
                  <Grid xs={2} container item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={this.props.selectedFeatureList?.length == 0}
                      onClick={this.props.moveToNextStep}
                      color="primary"
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CustomGrid>
        </Grid>
      </DragDropContext>
    );
  }
}
const CustomPackageCreationSelectFeaturesPageWithRouter = withRouter(
  CustomPackageCreationSelectFeaturesPage
);
const CustomPackageCreationSelectFeaturesPageWithToast = withToast(
  CustomPackageCreationSelectFeaturesPageWithRouter
);
const CustomPackageCreationSelectFeaturesPageWithLoader = withLoader(
  CustomPackageCreationSelectFeaturesPageWithToast
);
const CustomPackageCreationSelectFeaturesPageWithAlertBox = withAlertBox(
  CustomPackageCreationSelectFeaturesPageWithLoader
);
const CustomPackageCreationSelectFeaturesPageWithDialogBox = withDialog(
  CustomPackageCreationSelectFeaturesPageWithAlertBox
);

export default withStyles((theme: Theme) => getComponentStyle(theme), {
  withTheme: true,
})(CustomPackageCreationSelectFeaturesPageWithDialogBox);
