//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  public_key: null | string;
  cpa_secret_id: "";
  cpa_client_id: "";
  googleObj: any;
  googleId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ThirdPartyIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAccessTokenOfCPAChargeCallId: string = "";
  connectToGoogleCallId: string = "";
  ForTpi: string = "";
  getCPADetialbyAccountIdCallId: string = "";
  disconnectFromCPAChargeCallId: string = "";
  updateCFSSettingApiForTPICallId: string = "";
  disconnectGoogleCallId: string = "";
  getGoogleCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      public_key: null,
      cpa_secret_id: "",
      cpa_client_id: "",
      googleObj: null,
      googleId: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.connectToGoogleCallId == apiRequestCallId) {
        this.handleConnectToGoogleResponse(responseJson);
      } else if (apiRequestCallId === this.getAccessTokenOfCPAChargeCallId) {
        this.handleAccessTokenResponse(responseJson);
      } else if (this.updateCFSSettingApiForTPICallId === apiRequestCallId) {
        this.handleCFSUpdateResponse(responseJson);
      } else if (this.getCPADetialbyAccountIdCallId === apiRequestCallId) {
        this.handleCpaDetailResponse(responseJson);
      } else if (this.getCFSSettingForTpiCallId == apiRequestCallId) {
        this.handleCFSSettingResponse(responseJson);
      } else if (this.disconnectFromCPAChargeCallId === apiRequestCallId) {
        this.handleConnectCpaChargeResponse(responseJson);
      } else if (this.getGoogleCallId == apiRequestCallId) {
        this.handleGsuiteData(responseJson);
      } else if (this.disconnectGoogleCallId == apiRequestCallId) {
        this.handleDisconnectGoogleResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (AlertBodyMessage === 'Session TimeOut,Please Login Again.' || AlertBodyMessage === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }
  handleCFSUpdateResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.props.showToast({
        type: "success",
        message: "Integration updated successfully",
      });
      this.setState({
        survey_id: responseJson?.data?.id,
        meeting_link: responseJson?.data?.attributes?.meeting_link,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };
  handleDisconnectGoogleResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message == "Gsuite deleted sucessfully") {
      this.setState(
        {
          googleId: null,
          googleObj: null,
        },
        () => {
          this.props.showToast({
            message: "Google disconnected successfully",
            type: "success",
          });
        }
      );
    } else {
      this.handleApiError(responseJson);
    }
  };
  handleGsuiteData = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setState({
        googleObj: responseJson?.data?.attributes?.gsuite_data,
        googleId: responseJson?.data.id,
      });
    }
  };
  handleCpaDetailResponse = (responseJson) => {
    if (responseJson.data) {
      if (responseJson.data.attributes.cpa_charge_access_token) {
        this.setState(
          {
            payment_access_token:
              responseJson.data.attributes.cpa_charge_access_token,
            public_key:
              responseJson.data.attributes.cpa_charge_info.test_accounts &&
              responseJson.data.attributes.cpa_charge_info.test_accounts
                .length > 0 &&
              responseJson.data.attributes.cpa_charge_info.test_accounts[0]
                .public_key,
          },
          () => {
            localStorage.setItem(
              "paymentAccessToken",
              responseJson?.data?.attributes?.cpa_charge_access_token
            );
          }
        );
      }
    } else {
      this.handleApiError(responseJson);
    }
  };
  handleConnectCpaChargeResponse = (responseJson) => {
    this.props.hideLoader();
    if (
      responseJson?.data?.id &&
      !responseJson?.data?.attributes?.cpa_charge_access_token
    ) {
      StorageProvider.set("paymentAccessToken", null);
      this.setState({
        payment_access_token: null,
      });
      this.props.showToast({
        type: "success",
        message: "CPACharge disconnected succssfully",
      });
    } else if (responseJson?.errors?.error) {
      this.props.showToast({
        type: "error",
        message: responseJson?.errors?.error_description,
      });
      this.handleApiError(responseJson);
    }
  };
  handleCFSSettingResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data && responseJson?.data?.id) {
      this.setState({
        survey_id: responseJson?.data?.id,
        meeting_link: responseJson?.data?.attributes?.meeting_link,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };
  handleAccessTokenResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes?.cpa_charge_access_token) {
      StorageProvider.set(
        "paymentAccessToken",
        responseJson?.data?.attributes?.cpa_charge_access_token
      );
      this.setState({
        payment_access_token:
          responseJson?.data?.attributes?.cpa_charge_access_token,
        public_key:
          responseJson?.data?.attributes?.cpa_charge_info?.test_accounts &&
          responseJson?.data?.attributes?.cpa_charge_info?.test_accounts
            ?.length > 0 &&
          responseJson?.data?.attributes?.cpa_charge_info?.test_accounts[0]
            .public_key,
      });
      this.props.showToast({
        type: "success",
        message: "CPACharge Authentication Successfully Completed",
      });
    } else if (responseJson?.errors?.error) {
      this.props.showToast({
        type: "error",
        message: responseJson?.errors?.error_description,
      });
      this.handleApiError(responseJson);
    }
  };
  handleApiError = (responseJson: any) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleConnectToGoogleResponse = (responseJSON) => {
    this.props.hideLoader();
    if (responseJSON?.data?.id) {
      this.setState({
        googleObj: responseJSON?.data?.attributes?.gsuite_data,
        googleId: responseJSON?.data.id,
      });
      this.props.showToast({
        message: "Google account linked sucessfully",
        type: "success",
      });
    } else {
      this.handleApiError(responseJSON);
    }
  };

  getAccessTokenOfCPACharge = (code) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccessTokenOfCPAChargeCallId = requestMessage.messageId;

    const httpBody = {
      code: code,
      redirect_uri: `${window.location.origin}/home/settings/integrations`,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegration8/cpa_charge/callback`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateCFSSetting = (data, isCPA) => {
    this.setState(
      {
        iscpaupdate: isCPA,
      },
      () => {
        this.props.showLoader();

        const headers = {
          "Content-Type": configJSON.ApiContentType,
          token: this.state.token,
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        let httpBodyFORTPI = {};
        httpBodyFORTPI = {
          data: {
            attributes: {
              meeting_link: data?.meetingLink || "",
            },
          },
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          this.state.survey_id
            ? `bx_block_survey/survey_setting/${this.state.survey_id}`
            : "bx_block_survey/survey_setting"
        );
        this.updateCFSSettingApiForTPICallId = requestMessage.messageId;
        
        

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBodyFORTPI)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          this.state.survey_id ? "PUT" : "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    );
  };

  getCFSSettingFortpi = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCFSSettingForTpiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/survey_setting`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCPADetialbyAccountId = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const data = {
      data: {
        attributes: {
          access_token: "",
          account_id: this.state.accountId || "",
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCPADetialbyAccountIdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/access_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  disconnectFromCPACharge = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.disconnectFromCPAChargeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_apiintegration8/cpa_charge/disconnect`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        public_key: this.state.public_key,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  connectToGoogle = (googleData) => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.connectToGoogleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gsuiteintegration/gsuites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        gsuite_data: {
          profileObj: googleData?.profileObj,
          tokenObj: googleData?.tokenObj,
        },
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  disconnectGoogle = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.disconnectGoogleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gsuiteintegration/gsuites/${this.state.googleId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getGoogle = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGoogleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
