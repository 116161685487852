//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import AddNewClientDialogController, {
  Props,
} from "./AddNewClientDialogController.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import UploadBoxProfilePicture from "../UploadBoxProfilePicture.web";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import DeleteAdditionalDialog from "./DeleteAdditionalDialog.web";
import ChangeClientTypeConfirmDialog from "./ChangeClientTypeConfirmDialog.web";
import uuid from "react-uuid";
import { nameMask, mobileNumberMask, addressMask, noSpaceMask } from "../masks";
import withPermissions from "../../../../components/src/withPermissions.web";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field")
    .required("This field is required."),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field")
    .required("This field is required."),
  address: Yup.string(),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  mobileNumber: Yup.string().matches(
    /^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/,
    "Invalid phone number"
  ),
  businessName: Yup.string().when("clientType", {
    is: "Business",
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string(),
  }),
  clientType: Yup.string().required("This field is required."),
  custom_fields: Yup.array(
    Yup.object({
      key: Yup.string()
        .nullable()
        .required("This field is required."),
      value: Yup.string()
        .nullable()
        .required("This field is required."),
      allow_field: Yup.boolean().nullable(),
    })
  ),
});

export class AddNewClientDialog extends AddNewClientDialogController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = this.props.dataToPass?.authToken;
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getCustomField();
        }
      );
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  removeThumbnailImage = () => {
    this.setState({
      thumbnailImage: null,
    });
  };

  handleThumbnailImage = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      thumbnailImage: filePaths[0],
    });
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };

  openDeleteFieldDialog = (index, remove, field) => {
    if (field.id) {
      this.props
        .openDialogBox({
          dataToPass: {
            showToast: this.props.showToast.bind(this),
            field: field,
          },
          width: "45%",
          renderedComponent: DeleteAdditionalDialog,
          withCustomDialog: false,
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        })
        .then((data) => {
          if (data !== "all") {
            remove(index);
            if (this.props.dataToPass?.clientInfo?.id) {
              this.deleteCustomFieldForAllClient(
                field.id,
                this.props.dataToPass.clientInfo.id
              );
            }
          } else {
            if (field.id) remove(index);
            this.deleteCustomFieldForAllClient(field.id);
          }
        });
    } else {
      remove(index);
    }
  };

  openChangeStatusDialog = (values) => {
    this.props
      .openDialogBox({
        dataToPass: {
          showToast: this.props.showToast.bind(this),
        },
        width: "45%",
        renderedComponent: ChangeClientTypeConfirmDialog,
        withCustomDialog: false,
      })
      .then((data) => {
        if (data) {
          this.editClient(values);
        }
      });
  };

  getInitialValues = () => {
    return {
      firstName:
        this.props?.dataToPass?.clientInfo?.attributes?.first_name || "",
      lastName: this.props?.dataToPass?.clientInfo?.attributes?.last_name || "",
      mobileNumber:
        this.props?.dataToPass?.clientInfo?.attributes?.primary_phone || "",
      email:
        this.props?.dataToPass?.clientInfo?.attributes?.primary_email || "",
      businessName:
        this.props?.dataToPass?.clientInfo?.attributes?.firm_name || "",
      clientType:
        this.props?.dataToPass?.clientInfo?.attributes?.client_type ||
        "Individual",
      custom_fields:
        this.state.customFields?.length > 0 ? this.state.customFields : [],
    };
  };

  onSubmit = (values) => {
    if (this.props?.dataToPass?.clientInfo?.id) {
      if (
        values?.clientType !=
        this.props?.dataToPass?.clientInfo?.attributes?.client_type
      ) {
        this.openChangeStatusDialog(values);
      } else {
        this.editClient(values);
      }
    } else {
      this.addNewClient(values);
    }
  };

  render() {
    const { classes, permissions } = this.props;

    return (
      <Formik
        initialValues={this.getInitialValues()}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            setFieldValue,
            isValid,
            dirty,
          } = formikProps;

          return (
            <Form autoComplete="off" noValidate>
              <Grid container style={{ width: "25vw", padding: "1rem" }}>
                <Grid item xs={12}>
                  <Typography variant="h3" className={classes.dialogHeading}>
                    {this.props?.dataToPass?.title || "Add Client"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Typography className={classes.fieldLabels}>
                        Add a Profile Picture
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Box>
                        <UploadBoxProfilePicture
                          multiple={false}
                          acceptedFormat={".png, .jpg, .jpeg"}
                          uploadMsg={
                            this.state.thumbnailImage?.file?.name
                              ? this.state.thumbnailImage?.file?.name
                              : "Select File"
                          }
                          onTouched={() => {}}
                          onSelectFile={(files: any) => {
                            this.handleThumbnailImage(files);
                          }}
                          {...this.props.dataToPass}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Typography className={classes.fieldLabels}>
                        Client Type
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={[
                        classes.halfWidthGrid,
                        classes.capsuleContainer,
                      ]}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={
                          values.clientType === "Individual"
                            ? classes.capsuleButtonSelected
                            : classes.capsuleButton
                        }
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => {
                          setFieldValue("clientType", "Individual");
                        }}
                      >
                        Individual
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={
                          values.clientType === "Business"
                            ? classes.capsuleButtonSelected
                            : classes.capsuleButton
                        }
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => {
                          setFieldValue("clientType", "Business");
                        }}
                      >
                        Business
                      </Button>
                    </Grid>

                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Field
                        component={InputField}
                        name="firstName"
                        fullWidth
                        placeholder="First Name"
                        onChange={(e) => handleChange(nameMask(noSpaceMask(e)))}
                        id="firstNameInput"
                      />
                    </Grid>

                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Field
                        component={InputField}
                        name="lastName"
                        fullWidth
                        placeholder="Last Name"
                        onChange={(e) => handleChange(nameMask(noSpaceMask(e)))}
                        id="lastNameInput"
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.bottomMargin}>
                      <Field
                        component={InputField}
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        fullWidth
                        onChange={(e) => handleChange(mobileNumberMask(e))}
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.bottomMargin}>
                      <Field
                        disabled={this.props?.dataToPass?.clientInfo?.id}
                        component={InputField}
                        name="email"
                        placeholder="Email"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>

                    {values.clientType === "Business" ? (
                      <Grid item xs={12} className={classes.bottomMargin}>
                        <Field
                          component={InputField}
                          name="businessName"
                          placeholder="Business Name"
                          fullWidth
                          onChange={(e) => handleChange(addressMask(e))}
                        />
                      </Grid>
                    ) : null}
                    {!this.props?.dataToPass.isNotShowCustomField && (
                      <FieldArray name="custom_fields">
                        {({ insert, remove, push }) => (
                          <Grid
                            item
                            xs={12}
                            className={classes.bottomMargin}
                            justifyContent="space-between"
                            container
                            alignItems="center"
                          >
                            {values.custom_fields.length > 0 &&
                              values.custom_fields.map((field, index) => (
                                <>
                                  <Grid
                                    item
                                    xs={5}
                                    className={classes.bottomMargin}
                                  >
                                    <Field
                                      component={InputField}
                                      value={field?.key}
                                      disabled={field?.id}
                                      name={`custom_fields[${index}].key`}
                                      placeholder={`Field Name ${index + 1}`}
                                      fullWidth
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5}
                                    className={classes.bottomMargin}
                                  >
                                    <Field
                                      component={InputField}
                                      value={field?.value}
                                      name={`custom_fields[${index}].value`}
                                      placeholder={`Field Value ${index + 1}`}
                                      fullWidth
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    className={classes.bottomMargin}
                                  >
                                    <IconButton
                                      onClick={(e) =>
                                        this.openDeleteFieldDialog(
                                          index,
                                          remove,
                                          field
                                        )
                                      }
                                    >
                                      <DeleteRoundedIcon
                                        style={{ color: "red" }}
                                      />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <FormControlLabel
                                      key={uuid()}
                                      classes={{
                                        label: classes.options,
                                      }}
                                      control={
                                        <Checkbox
                                          onChange={handleChange}
                                          checked={field.allow_field}
                                          disabled={field?.id}
                                          name={`custom_fields[${index}].allow_field`}
                                          color="primary"
                                        />
                                      }
                                      label={"Add fields to all client"}
                                    />
                                  </Grid>
                                </>
                              ))}

                            <Grid item xs={12} className={classes.bottomMargin}>
                              <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                  push({
                                    key: "",
                                    value: "",
                                    allow_field: false,
                                  })
                                }
                                className={classes.uploadNowButton}
                                disabled={
                                  !permissions.includes("Add custom fields-CRM")
                                }
                              >
                                Add Field
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </FieldArray>
                    )}

                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          this.closeDialog();
                        }}
                        className={classes.uploadNowButton}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        className={classes.uploadNowButton}
                        disabled={!(isValid && dirty)}
                      >
                        {this.props?.dataToPass?.clientInfo?.id
                          ? "Update"
                          : "Save"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const AddNewClientDialogWithRouter = withRouter(AddNewClientDialog);
const AddNewClientDialogWithToast = withToast(AddNewClientDialogWithRouter);
const AddNewClientDialogWithLoader = withLoader(AddNewClientDialogWithToast);
const AddNewClientDialogWithAlertBox = withAlertBox(
  AddNewClientDialogWithLoader
);
const AddNewClientDialogWithDialogBox = withDialogBox(
  AddNewClientDialogWithAlertBox
);
const AddNewClientDialogWithPermissions = withPermissions(
  AddNewClientDialogWithDialogBox
);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
      marginBottom: "1.25rem",
    },
    bottomMargin: {
      marginBottom: "1rem",
    },
    capsuleContainer: {
      backgroundColor: "#EAE8E9",
      borderRadius: "2.5rem",
    },
    capsuleButton: {
      width: "50%",
      borderRadius: "2.5rem",
      padding: 0,
      height: "2.2rem",
      backgroundColor: "#EAE8E9",
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#EAE8E9",
      },
    },
    capsuleButtonSelected: {
      width: "50%",
      borderRadius: "2.5rem",
      padding: 0,
      height: "2.2rem",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    fieldLabels: {
      color: theme.palette.text.secondary,
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "1rem",
    },
  })
)(AddNewClientDialogWithPermissions);
