// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import LandingPage2 from "../../blocks/LandingPage2/src/LandingPage2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ThinkificIntegration2 from "../../blocks/ThinkificIntegration2/src/ThinkificIntegration2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import TrackingToolUtilization from "../../blocks/TrackingToolUtilization/src/TrackingToolUtilization";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import AnimationsAndTransition3 from "../../blocks/AnimationsAndTransition3/src/AnimationsAndTransition3";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import ImageManagement from "../../blocks/ImageManagement/src/ImageManagement";
import GSuiteIntegration from "../../blocks/GSuiteIntegration/src/GSuiteIntegration";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import PricingCalculator from "../../blocks/PricingCalculator/src/PricingCalculator";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import RouteConfig from "./routeConfig";
import { Chart as ChartJS } from "chart.js";


const routeMap = {
LandingPage2:{
 component:LandingPage2,
path:"/LandingPage2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
ThinkificIntegration2:{
 component:ThinkificIntegration2,
path:"/ThinkificIntegration2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
TrackingToolUtilization:{
 component:TrackingToolUtilization,
path:"/TrackingToolUtilization"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
AnimationsAndTransition3:{
 component:AnimationsAndTransition3,
path:"/AnimationsAndTransition3"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
ImageManagement:{
 component:ImageManagement,
path:"/ImageManagement"},
GSuiteIntegration:{
 component:GSuiteIntegration,
path:"/GSuiteIntegration"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
PricingCalculator:{
 component:PricingCalculator,
path:"/PricingCalculator"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
    component: HomeScreen,
    path: '/Homee',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

ChartJS.register({
  id: 'counter',
	afterDraw: function(chart) {
  	if (chart?.data?.datasets?.length === 0) {
    	// No data is present
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height
      chart.clear();
      
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = "20px Arial";
      ctx.fillStyle = "#6d6d6d";
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  }
});


class App extends Component {
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      // <View style={{ height: '100vh', width: '100vw' }}>
      //   <TopNav />
      //   {WebRoutesGenerator({ routeMap })}
      //   <ModalContainer />
         <RouteConfig/>
      //  </View>
    );
  }
}

export default App;