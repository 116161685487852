//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, {
  withAlertBoxProps,
} from "../../../../components/src/withAlertBox.Web";
import withToast, {
  withToastProps,
} from "../../../../components/src/withSnackBar.Web";
import withLoader, {
  withLoaderProps,
} from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { DialogProps } from "../../../../components/src/DialogContext";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

export class DeleteDialog extends React.Component {
  

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
       this.setState({
        token: token,
      });
    }
  }

  

  closeDialogFordelete = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center" style={{ padding: "1rem" }}>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h3"
            className={classes.dialogHeading}
          >
            {this.props.dataToPass.dialogHeading}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            align="center"
            variant="h3"
            className={classes.dialogDescription}
          >
            {this.props.dataToPass.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} className={classes.halfWidthGrid}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                
                id="noButton"
                onClick={this.closeDialogFordelete.bind(this,null)}
                className={classes.deleteNowButton}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.halfWidthGrid}>
              <Button
                
                className={[classes.deleteNowButton, classes.deactivateButton]}
                id="yesButton"
                onClick={this.closeDialogFordelete.bind(this,"Yes")}
                variant="contained"
                color="primary"
                fullWidth
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const DeleteDialogWithRouter = withRouter(DeleteDialog);
const DeleteDialogWithToast = withToast(DeleteDialogWithRouter);
const DeleteDialogWithLoader = withLoader(DeleteDialogWithToast);
const DeleteDialogWithAlertBox = withAlertBox(
  DeleteDialogWithLoader
);
const DeleteDialogWithDialogBox = withDialogBox(
  DeleteDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "1rem",
    },
    dialogDescription: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      // marginBottom: "1rem",
    },
    deleteNowButton: {
      marginTop: "2rem",
      marginBottom: "1rem",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
      marginBottom: "1rem",
    },
    fieldLabels: {
      color: theme.palette.text.secondary,
      fontFamily: "Nunito Sans",
      fontWeight: 600,
    },
    deactivateButton: {
      borderRadius: "0.5rem",
      backgroundColor: theme.palette.alert.main,
      color: theme.palette.white,
      fontWeight: "bold",
      fontFamily: "Lato",
      textTransform: "capitalize",
      "&:hover": {
        borderRadius: "0.5rem",
        backgroundColor: "#b40505",
        fontWeight: "bold",
        fontFamily: "Lato",
        textTransform: "capitalize",
      },
    },
  })
)(DeleteDialogWithDialogBox);
