//@ts-nocheck
import React, { useEffect, useMemo } from "react";

export const PermissionsContext = React.createContext<{
  permissions: string[];
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>;
}>({
  permissions: [],
  setPermissions: () => {},
});

export const DEFAULT_PERMISSIONS_ARRAY = [
  "Create another's account-Account Creation",
  "Modify my account-Account Creation",
  "Modify another's account-Account Creation",
  "Delete sub account-Account Creation",
  "Send Survey-Client Conversion Survey",
  "Access Survey Responses-Client Conversion Survey",
  "Manage Survey Settings-Client Conversion Survey",
  "Send Survey-Client Onboarding Survey",
  "Access Survey Responses-Client Onboarding Survey",
  "Add custom fields-CRM",
  "Create a Checklist-Delivery Checklists",
  "Manage Checklist-Delivery Checklists",
  "Assign Checklist (to packages)-Delivery Checklists",
  "View Documents (pre signature)-eSignatures",
  "View Documents (post signature)-eSignatures",
  "View/Edit Firm Financials-Firm Financials",
  "Make payment (CPA Charge)-Payments",
  "Subscribe-Subscriptions",
  "Manage Subscription-Subscriptions",
  "Cancel Subscription-Subscriptions",
];

export const PermissionsProvider = ({ children }: any) => {
  const [permissions, setPermissions] = React.useState<string[]>([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    if (permissions) {
      setPermissions(permissions);
    } else {
      setPermissions(DEFAULT_PERMISSIONS_ARRAY);
    }
  }, []);

  return (
    <PermissionsContext.Provider
      value={useMemo(() => {
        return {
          permissions: permissions,
          setPermissions: setPermissions,
        };
      }, [permissions])}
    >
      {children}
    </PermissionsContext.Provider>
  );
};