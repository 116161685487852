import React from "react";
import {
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';


import { InlineWidget, useCalendlyEventListener } from "react-calendly";
const MeetingSchrdulePage = (props: { cpaDetail: any,goToSummaryPage:any }) => {
  useCalendlyEventListener({
    onDateAndTimeSelected: (data) => {},
    onEventScheduled: (data) => {},
  });
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid xs={11}  sm={4} item>
        <Typography align="center" color="primary" variant="h1">
          Book a 15-minute call to confirm your selection and get started!
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <InlineWidget
          url={props?.cpaDetail?.survey_setting?.data?.attributes?.meeting_link}
        />
      </Grid>
     
        <Box
          style={{
            position: "fixed",
            right: "40px",
            bottom: "80px",
            width: "50px",
            height: "50px",
            cursor: "pointer",
          }}
          id="faqbutton"
          onClick={props.goToSummaryPage}
        >
          <HelpIcon color="primary" style={{ fontSize: "3.8rem" }} />
        </Box>
    </Grid>
  );
};
export default MeetingSchrdulePage;
