export const largeChartOptions = {
  pointBackgroundColor: "rgba(72, 100, 132, 1)",
  pointBorderColor: "rgba(72, 100, 132, 1)",
  pointBorderWidth: 4,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItems, data) {
          return `${tooltipItems.formattedValue}`;
        },
      },
    },
    datalabels: {
      backgroundColor: 'transparent',
      borderRadius: 4,
      color: "rgba(72, 100, 132, 1)",
      font: {
        weight: 'bold'
      },
      formatter: function (value, index, values) {
        return parseFloat(Number((value)).toString()); //pass tick values as a string into Number function
      },
      padding: 8
    }
  },
  scales: {
    x: {
      offset : true,
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 18,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
    y: {
      ticks: {
        callback: function (value, index, values) {
          return Number((value).toString()); //pass tick values as a string into Number function
        },
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 18,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderDash: [5, 5],
        lineWidth: 2,
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        dash: true,
        color: function(context) {
          if (context.tick.value === 140) {
            return "#e4e4e4";
          } else {
            return "transparent";
          }
        },
      },
    },
  },
};

export const smallChartOptions = {
  pointBackgroundColor: "rgba(72, 100, 132, 1)",
  pointBorderColor: "rgba(72, 100, 132, 1)",
  pointBorderWidth: 2,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    x: {
      offset: true,
      tickBorderDashOffset : 0,
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 5,
      },
      grid: {
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDashOffset : 0,
        tickBorderDashOffset : 0
      },
    },
    y: {
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        stepSize: 20,
        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 5,
      },
      grid: {
        borderDash: [5, 5],
        lineWidth: 2,
        borderColor: "#979797",
        display: true,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        dash: true,
        color: function(context) {
          if (context.tick.value === 140) {
            return "#e4e4e4";
          } else {
            return "transparent";
          }
        },
      },
    },
  },
};
