//@ts-check
import React, { PureComponent } from "react";
import { Redirect, RouterProps, Route } from "react-router-dom";
import StorageProvider from "../../framework/src/StorageProvider.web";

export type Props = RouterProps & {
  role: string;
};

export interface S {
  token: any;
  loading: boolean;
  role: string;
}

export default class AdminPrivateRoute extends PureComponent<Props, S> {
  state = {
    token: "",
    loading: true,
    role: "",
  };

  async componentDidMount() {
    let token = await StorageProvider.get("authToken");
    let role = await StorageProvider.get("role");
    if (token) {
      this.setState({
        token: token,
        loading: false,
        role,
      });
    } else {
      this.setState({
        token: null,
        loading: false,
      });
    }
  }
  render() {
    let componentToRender = null;

    let ifCondition = this.state.token !== null && (this.state.role === "Super Admin User" || this.state.role === "Admin User");

    if (!this.state.loading) {
      if (ifCondition) {
        componentToRender = <Route {...this.props} />;
      } else {
        componentToRender = <Redirect to="/" />;
      }
    }

    return componentToRender;
  }
}
