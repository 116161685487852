//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
  Omit<MuiTextFieldProps, "name" | "value" | "error"> { }

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const WhiteFieldWithFormik = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      // {...fieldToTextField(props)}
      {...props}
      variant="outlined"
      InputLabelProps={{
        ...InputLabelProps, shrink: true, classes: {
          asterisk: classes?.astriklabel,
          outlined: classes?.outlinedLabel,
          shrink: classes?.shrink,
          root: classes?.lableroot,
        }
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: !props.multiline && classes?.input,
          root: classes?.inputfieledroot,
          multiline: classes.multiline,
          inputMultiline: classes.inputMultiline
        },
        notched: false,
      }}

    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot: {
        border: `2px solid #fff`,
        outline: "none",
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: theme.shadows[25],
      'label + &': {
        marginTop: 30,
        marginBottom: 20,
      },
      '& > fieldset': {
        border: 'none',
        '& active': {
          border: 'none'
        },
        '& focus': {
          border: 'none'
        }
      },
      "&.Mui-focused": {
        border: `2px solid ${theme.palette.common.white}`,
        backgroundColor: '#fff',
      },
      "&.Mui-error": {
        border: `2px solid #FF5C5C`,
        backgroundColor: '#fff',
        "&.Mui-focused": {
          backgroundColor: '#fff',
        }
      },
      "&.MuiSelect-root": {
        border: "none",
        outline: "none",
      }
    },
    input: {
      borderRadius: '12px',
      position: 'relative',
      fontSize: '1rem',
      fontFamily: "Nunito Sans",
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '0.85rem 0.85rem',
      border: "none",
      outline: "none",
      color: theme.palette.text.primary,
      fontWeight: 600,
      "&::placeholder": {
        color: theme.palette.text.primary,
        fontWeight: 600,
      },
      "&:focus": {
        border: "none",
        borderRadius: '12px',
        backgroundColor: '#fff',
        "&::placeholder": {
          color: theme.palette.text.primary,
          fontWeight: 600,
        }
      },
      "&::-webkit-file-upload-button": {
        display: "none",
      }
    },
    multiline: {
      padding: 0
    },
    inputMultiline: {
      borderRadius: '12px',
      position: 'relative',
      backgroundColor: '#fff',
      border: 'none',
      fontSize: '1rem',
      fontFamily: 'Nunito Sans',
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '0.75rem 0.75rem',
      "&:focus": {
          border: "none",
          borderRadius: '12px',
          backgroundColor: '#fff',
          "&::placeholder": {
            color: theme.palette.text.primary,
            fontWeight: 600,
          }
        },
    },
    astriklabel: {
      fontFamily: "Nunito Sans",
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#959ead'
    },
    lableroot: {
      fontFamily: "Nunito Sans",
      fontSize: '0.875rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: theme.palette.text.blue,
      "&.Mui-focused": {
        color: theme.palette.common.white
      },
    },
    outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important'
    },
    shrink: {
      transform: 'translate(3px, 9px) scale(1)!important'
    }
  })
)(WhiteFieldWithFormik);
