//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button } from "@material-ui/core";
import AddChecklistDialogController, { Props } from "./AddChecklistDialogController.web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { ReactComponent as RedCross } from "../../assets/svg/redCross.svg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withPermissions from "../../../../components/src/withPermissions.web";

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    borderRadius: "12px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "8px",
    },
    backgroundColor: "#f8f8f8",
    padding: "0rem 1rem",
    "&.Mui-expanded": {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}))(AccordionSummary);

export class AddChecklistDialog extends AddChecklistDialogController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getChecklistsForAddPage();
        }
      );
    }
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  handleCrossButtonClick = (checklistItem) => {
    let newCheck = [...this.state.selectedChecklists];
    let filteredChecklist = newCheck.filter((filterItem) => filterItem.id !== checklistItem.id);

    let tempChecklistNamesAndIds = [...this.state.checklistNamesAndIds].map((item) => {
      if (item.id === checklistItem.id) {
        return {
          ...item,
          selected: !item.selected,
        };
      }
      return item;
    });

    this.setState({
      selectedChecklists: filteredChecklist,
      checklistNamesAndIds: tempChecklistNamesAndIds,
    });
  };

  createNewChecklistHandler = () => {
    this.props.history.push({
      pathname: "createOnboardingChecklist",
    });
    this.props.onSubmit(null);
  };

  checklistNameClickHandler = (name) => {
    let tempChecklistNamesAndIds = [...this.state.checklistNamesAndIds].map((item) => {
      if (item.id === name.id) {
        return {
          ...item,
          selected: !item.selected,
        };
      }
      return item;
    });

    let ind = this.state.selectedChecklists.findIndex((item) => item.id === name.id);
    if (ind > -1) {
      let temp = [...this.state.selectedChecklists];
      temp.splice(ind, 1);
      this.setState({
        selectedChecklists: temp,
        checklistNamesAndIds: tempChecklistNamesAndIds,
      });
      return;
    }
    let temp = [...this.state.selectedChecklists];
    let tempCheck = {
      ...name,
      selected: true,
    };
    temp.push(tempCheck);
    this.setState({
      selectedChecklists: temp,
      checklistNamesAndIds: tempChecklistNamesAndIds,
    });
  };

  mapChecklistNames = (name) => {
    return (
      <Grid item xs={12} key={name.id}>
        <Typography
          style={{
            padding: "0.5rem",
            cursor: "pointer",
            textAlign: "left",
            backgroundColor: name.selected ? "#EAE8E9" : "",
          }}
          onClick={this.checklistNameClickHandler.bind(this, name)}
        >
          {name.checklistName}
        </Typography>
      </Grid>
    );
  };

  mapSelectedChecklists = (checklistItem) => {
    const { classes } = this.props;
    return (
      <Grid key={checklistItem?.id} item container justifyContent="space-between" xs={12} className={classes.checklistContainer}>
        <Typography>{checklistItem.checklistName}</Typography>
        <RedCross onClick={this.handleCrossButtonClick.bind(this, checklistItem)} className={classes.crossButton} />
      </Grid>
    );
  };

  render() {
    const { classes, permissions } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        style={{
          padding: "1rem",
        }}
      >
        <Grid item xs={12}>
          <Typography align="center" className={classes.dialogHeading}>
            {this.props.dataToPass.packageItem.attributes.name}
          </Typography>
          <Typography variant="h3" align="center" color="textSecondary" className={classes.subHeading}>
            + Add Checklist
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            style={{
              borderRadius: "12px",
              boxShadow: "none",
            }}
            expanded={true}
          >
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon size="small" />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography
                style={{
                  padding: "1rem 0.5rem",
                }}
              >
                Select Checklist
              </Typography>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.tooltipAccordionDetails}>
              <Grid container>
                {this.state.checklistNamesAndIds.length > 0 ? (
                  this.state.checklistNamesAndIds.map(this.mapChecklistNames)
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        padding: "0.5rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      All checklists are already assigned
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.scrollableContainer}>
            {this.state.selectedChecklists.map(this.mapSelectedChecklists.bind(this))}
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            className={classes.uploadNowButton}
            onClick={this.createNewChecklistHandler}
            disabled={!permissions.includes("Create a Checklist-Delivery Checklists")}
          >
            Create New Checklist
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className={classes.uploadNowButton}
            disabled={this.state.selectedChecklists.length === 0 || !permissions.includes("Assign Checklist (to packages)-Delivery Checklists")}
            onClick={this.assignChecklits.bind(this, this.props.dataToPass.packageItem?.id, this.props.dataToPass.packageItem?.type)}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const AddChecklistDialogWithRouter = withRouter(AddChecklistDialog);
const AddChecklistDialogWithToast = withToast(AddChecklistDialogWithRouter);
const AddChecklistDialogWithLoader = withLoader(AddChecklistDialogWithToast);
const AddChecklistDialogWithAlertBox = withAlertBox(AddChecklistDialogWithLoader);
const AddChecklistDialogWithDialogBox = withDialogBox(AddChecklistDialogWithAlertBox);
const AddChecklistDialogWithPermissions = withPermissions(AddChecklistDialogWithDialogBox);

export default withStyles((theme) =>
  createStyles({
    dialogHeading: {
      fontSize: "1.8rem",
      // fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "0.5rem",
    },
    subHeading: {
      fontWeight: "bold",
      fontSize: "1.2rem",
      marginBottom: "1rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
    scrollableContainer: {
      // backgroundColor: "#f4f4f4",
      // borderRadius: "1rem",
      marginTop: "1rem",
      maxHeight: "30vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c9c9c9",
        borderRadius: "0.5rem",
      },
    },
    popoverPaper: {
      backgroundColor: "#f8f8f8",
      marginTop: "2px",
      maxHeight: "12.5rem",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c9c9c9",
        borderRadius: "0.5rem",
      },
    },
    checklistContainer: {
      padding: "1rem",
    },
    crossButton: {
      cursor: "pointer",
    },
    tooltipAccordionDetails: {
      backgroundColor: "#f8f8f8",
      marginTop: "10px",
      padding: "0.5rem 1rem",
      borderRadius: "8px",
      maxHeight: "150px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c9c9c9",
        borderRadius: "0.5rem",
      },
    },
  })
)(AddChecklistDialogWithPermissions);