//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";

export class SendSurveyDialog extends React.Component {
  
  state = {
    surveyTypeList: [
      {
        title: "Client survey",
        value: "clientsurvey",
        checked: false,
      },
      {
        title: "Onboarding surey",
        value: "orboardingsurvey",
        checked: false,
      },
    ],
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  handleChange = (e) => {
    const surveyTypeList = [...this.state.surveyTypeList];
    let surveyTypeListNew = surveyTypeList.map((ss) => {
      return { ...ss, checked: false };
    });
    const index = surveyTypeList.findIndex((ss) => {
      return ss.value === e.target.name;
    });
    surveyTypeListNew[index] = {
      ...surveyTypeListNew[index],
      checked: e.target.checked,
    };
    this.setState({
      surveyTypeList: surveyTypeListNew,
    });
  }
  render() {
    const { classes } = this.props;

    const isSelected = this.state.surveyTypeList.filter((ss) => {
      return ss.checked;
    });
    return (
      <Grid
        container
        justifyContent="center"
        style={{
          width: "25vw",
          padding: "1rem",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            align="center"
            className={classes.dialogHeading}
          >
            Send Survey
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.scrollableContainer}>
            {this.state.surveyTypeList.map((survey, surveyIndex) => (
              <Grid
                key={survey.title}
                item
                xs={12}
                className={classes.checkboxGrid}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      id={survey.value}
                      checked={survey.checked}
                      disabled={"orboardingsurvey" === survey.value}
                      onChange={this.handleChange}
                      name={survey.value}
                    />
                  }
                  label={survey.title}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.closeDialog.bind(this,isSelected)}
            disabled={(isSelected.length <= 0)}
            fullWidth
            className={classes.uploadNowButton}
          >
            Send Survey
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const SendSurveyDialogWithRouter = withRouter(SendSurveyDialog);
const SendSurveyDialogWithToast = withToast(SendSurveyDialogWithRouter);
const SendSurveyDialogWithLoader = withLoader(SendSurveyDialogWithToast);
const SendSurveyDialogWithAlertBox = withAlertBox(SendSurveyDialogWithLoader);
const SendSurveyDialogWithDialogBox = withDialogBox(
  SendSurveyDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    dialogHeading: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
      marginBottom: "1.25rem",
    },
    checkboxGrid: {
      backgroundColor: "#f8f8f8",
      padding: "0.5rem 1rem",
      borderRadius: "1rem",
      marginBottom: "1rem",
      marginRight: "0.25rem",
    },
    scrollableContainer: {
      maxHeight: "50vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0.5rem",
      },
    },
  })
)(SendSurveyDialogWithDialogBox);
