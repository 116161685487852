// @ts-nocheck
import React from "react";

import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Button,
  TextField,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Fade from "@material-ui/core/Fade";
import { surveyStyles,aboutUsStyle } from "./surveyStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const BusinessSchema = Yup.object().shape({
  industryType: Yup.string()
    .required("This field is required")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .nullable(),
  currentAccountingSystem: Yup.string().required("This field is required"),
  annualRevenue: Yup.string().required("This field is required"),
  otherBusinessPartner: Yup.string().required("This field is required"),
  currentEntityStructure: Yup.string().required("This field is required"),
  noOfEmployee: Yup.string()
    .required("This field is required")
    .matches(/^\d*$/, "Employee should contain only positive numbers"),
  currentPayRollSystem: Yup.string(),
  isManagePayroll: Yup.boolean(),
});
export const BusinessCompnent = (props) => {
  const { classes, titleMessage, gotoBackStep, gotoNextStep } = props;
  const checkIfNumber = (e, setFieldValue) => {
    const regex = /\d+(\.\d\d?)?/;
    if (
      e.target.value === "" ||
      (regex.test(e.target.value) && e.target.value.length < 12)
    ) {
      let t = e.target.value;
      t =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
          : t;
      let nf = new Intl.NumberFormat("en-US");
      setFieldValue(
        "annualRevenue",
        nf.format(t.replace(/,/g, "")) === "0"
          ? ""
          : nf.format(t.replace(/,/g, ""))
      );
    }
  };
  const getHelpText = (errors, touched, key) => {
    return touched[key] && errors[key] ? errors[key] : "";
  };
  const getError = (errors, touched, key) => {
    return touched[key] && errors[key];
  };

  return (
    <Fade in={true} timeout={3000}>
      <Formik
        initialValues={{
          industryType: props.industryType,
          currentAccountingSystem: props.currentAccountingSystem,
          annualRevenue: props.annualRevenue,
          otherBusinessPartner: props.otherBusinessPartner,
          currentEntityStructure: props.currentEntityStructure,
          noOfEmployee: props.noOfEmployee,
          currentPayRollSystem: props.currentPayRollSystem,
          isManagePayroll: props.isManagePayrol,
        }}
        validationSchema={BusinessSchema}
        // enableReinitialize
        onSubmit={() => {
          gotoNextStep();
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            isValid,
            errors,
            touched,
          } = formikProps;
          const handleInputChange = (event) => {
            props.setValueByDynamicName(event.target.name, event.target.value);
            handleChange(event);
          };
          const handleNumberChange = (event) => {
            props.setValueByDynamicName("annualRevenue", event.target.value);
            checkIfNumber(event, setFieldValue);
          };
          const handleKeyPress = (event) => {
            if (
              (event.charCode != 8 && event.charCode == 0) ||
              (event.charCode >= 48 && event.charCode <= 57)
            ) {
              console.log(event);
            } else {
              event.preventDefault();
            }
          };
          const hamdleEmplyeeChg = (event) => {
            props.setValueByDynamicName("noOfEmployee", event.target.value);
            handleChange(event);
            if (event.target.value == 0) {
              props.setValueByDynamicName("currentPayRollSystem", "");
              setFieldValue("currentPayRollSystem", "");
            }
          };
          const handlePayrollChange = (event) => {
            props.setValueByDynamicName(
              "isManagePayroll",
              event.target.checked
            );
            handleChange(event);
          }
          const goBack = (m) => {
            setFieldValue("industryType", "");
            setFieldValue("currentAccountingSystem", "None");
            setFieldValue("annualRevenue", "");
            setFieldValue("otherBusinessPartner", 0);
            setFieldValue("currentEntityStructure", "S-Corp");
            setFieldValue("noOfEmployee", "");
            setFieldValue("currentPayRollSystem", "");
            setFieldValue("isManagePayroll", false);
            props.setValueByDynamicName("industryType", "");
            props.setValueByDynamicName(
              "currentAccountingSystem",
              "None"
            );
            props.setValueByDynamicName("annualRevenue", "");
            props.setValueByDynamicName(
              "otherBusinessPartner",
              0
            );
            props.setValueByDynamicName(
              "currentEntityStructure",
              "S-Corp"
            );
            props.setValueByDynamicName("noOfEmployee", "");
            props.setValueByDynamicName(
              "currentPayRollSystem",
              ""
            );
            props.setValueByDynamicName("isManagePayroll", false);

            gotoBackStep();
          }
           return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-start"
                className={classes.maingrid2}
              >
                <Grid item sm={8} xs={11}>
                  <Typography className={classes.pageHeading} gutterBottom>
                    {titleMessage}
                  </Typography>
                  <Typography
                    className={[
                      classes.pageSubHeading,
                      classes.bottomMargin1rem,
                    ].join(" ")}
                  >
                    In order to show you the right feature options, we need a
                    few more details about your business
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    alignItems="flex-start"
                  >
                    <Grid item xs={10} md={6}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            margin: "0.5rem 0",
                          }}
                        >
                          <Accordion
                            classes={{
                              root: classes.accordionWhite,
                              rounded: getError(errors, touched, "industryType")
                                ? classes.accordionRoundError
                                : classes.accordionRound,
                            }}
                          >
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.accordionExpandIcon,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionHeading}>
                                Industry
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TextField
                                fullWidth
                                color="primary"
                                name="industryType"
                                placeholder="Industry"
                                id="industryType"
                                variant="outlined"
                                onBlur={handleBlur}
                                helperText={getHelpText(
                                  errors,
                                  touched,
                                  "industryType"
                                )}
                                error={getError(
                                  errors,
                                  touched,
                                  "industryType"
                                )}
                                value={values.industryType}
                                onChange={handleInputChange}
                                label=""
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            margin: "0.5rem 0",
                          }}
                        >
                          <Accordion
                            classes={{
                              root: classes.accordionWhite,
                              rounded: getError(
                                errors,
                                touched,
                                "annualRevenue"
                              )
                                ? classes.accordionRoundError
                                : classes.accordionRound,
                            }}
                          >
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.accordionExpandIcon,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionHeading}>
                                Annual Revenue
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TextField
                                // type="number"
                                color="primary"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Typography
                                        className={classes.dollarSignSmall}
                                      >
                                        $
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                  inputProps: { min: 1 },
                                }}
                                placeholder="Total Annual Revenue"
                                fullWidth
                                name="annualRevenue"
                                variant="outlined"
                                onBlur={handleBlur}
                                helperText={getHelpText(
                                  errors,
                                  touched,
                                  "annualRevenue"
                                )}
                                id="annualRevenue"
                                error={getError(
                                  errors,
                                  touched,
                                  "annualRevenue"
                                )}
                                value={values.annualRevenue}
                                onKeyPress={handleKeyPress}
                                onChange={handleNumberChange}
                                label=""
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            margin: "0.5rem 0",
                          }}
                        >
                          <Accordion
                            classes={{
                              root: classes.accordionWhite,
                              rounded: getError(
                                errors,
                                touched,
                                "currentEntityStructure"
                              )
                                ? classes.accordionRoundError
                                : classes.accordionRound,
                            }}
                          >
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.accordionExpandIcon,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionHeading}>
                                Current entity structure
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl className={classes.formControl}>
                                <Select
                                  name="currentEntityStructure"
                                  onBlur={handleBlur}
                                  helperText={getHelpText(
                                    errors,
                                    touched,
                                    "currentEntityStructure"
                                  )}
                                  error={getError(
                                    errors,
                                    touched,
                                    "currentEntityStructure"
                                  )}
                                  value={values.currentEntityStructure}
                                  onChange={handleInputChange}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  {[
                                    "S-Corp",
                                    "C-Corp",
                                    "Sole Proprietorship",
                                    "LLC",
                                    "Partnership",
                                  ].map((status) => {
                                    return (
                                      <MenuItem key={status} value={status}>
                                        {status}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            margin: "0.5rem 0",
                          }}
                        >
                          <Accordion
                            classes={{
                              root: classes.accordionWhite,
                              rounded: getError(
                                errors,
                                touched,
                                "currentAccountingSystem"
                              )
                                ? classes.accordionRoundError
                                : classes.accordionRound,
                            }}
                          >
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.accordionExpandIcon,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionHeading}>
                                Current accounting system
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl className={classes.formControl}>
                                <Select
                                  onBlur={handleBlur}
                                  helperText={getHelpText(
                                    errors,
                                    touched,
                                    "currentAccountingSystem"
                                  )}
                                  error={getError(
                                    errors,
                                    touched,
                                    "currentAccountingSystem"
                                  )}
                                  value={values.currentAccountingSystem}
                                  onChange={handleInputChange}
                                  variant="outlined"
                                  name="currentAccountingSystem"
                                  fullWidth
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  {[
                                    "None",
                                    "Quickbooks Online",
                                    "Quickbooks Desktop",
                                    "Xero",
                                    "Zoho",
                                    "Freshbooks",
                                    "Other",
                                  ].map((status) => {
                                    return (
                                      <MenuItem key={status} value={status}>
                                        {status}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            margin: "0.5rem 0",
                          }}
                        >
                          <Accordion
                            classes={{
                              root: classes.accordionWhite,
                              rounded: getError(
                                errors,
                                touched,
                                "otherBusinessPartner"
                              )
                                ? classes.accordionRoundError
                                : classes.accordionRound,
                            }}
                          >
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.accordionExpandIcon,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionHeading}>
                                Other Business partner
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl className={classes.formControl}>
                                <Select
                                  variant="outlined"
                                  name="otherBusinessPartner"
                                  onBlur={handleBlur}
                                  helperText={getHelpText(
                                    errors,
                                    touched,
                                    "otherBusinessPartner"
                                  )}
                                  error={getError(
                                    errors,
                                    touched,
                                    "otherBusinessPartner"
                                  )}
                                  value={values.otherBusinessPartner}
                                  onChange={handleInputChange}
                                  fullWidth
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value={0}>None</MenuItem>
                                  {[1, 2, 3, 4, 5].map((status) => {
                                    return (
                                      <MenuItem key={status} value={status}>
                                        {status}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    color="primary"
                    placeholder="Number of Employees"
                    id="noOfEmployee"
                    onChange={hamdleEmplyeeChg}
                    onBlur={handleBlur}
                    helperText={getHelpText(errors, touched, "noOfEmployee")}
                    error={getError(errors, touched, "noOfEmployee")}
                    value={values.noOfEmployee}
                    name="noOfEmployee"
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    color="primary"
                    disabled={props.noOfEmployee <= 0}
                    placeholder="Current Payroll System"
                    variant="outlined"
                    onBlur={handleBlur}
                    helperText={getHelpText(
                      errors,
                      touched,
                      "currentPayRollSystem"
                    )}
                    error={getError(errors, touched, "currentPayRollSystem")}
                    value={values.currentPayRollSystem}
                    name="currentPayRollSystem"
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={props.noOfEmployee < 1}
                        checked={values.isManagePayroll}
                        helperText={getHelpText(
                          errors,
                          touched,
                          "isManagePayroll"
                        )}
                        id="isManagePayroll"
                        error={getError(errors, touched, "isManagePayroll")}
                        onChange={handlePayrollChange}
                        name="isManagePayroll"
                        color="primary"
                      />
                    }
                    label="I want help managing my payroll moving forward."
                  />
                  
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Grid container justifyContent="flex-end">
                    <Grid item sm={3} xs={11}>
                      <Button
                        color="default"
                        style={{ marginRight: "16px" }}
                        variant="text"
                        startIcon={<ArrowBackOutlinedIcon />}
                        className={classes.backButton}
                        id="backButton"
                        onClick={goBack}
                      >
                        Back
                      </Button>
                      
                      <Button
                        color="primary"
                        type="submit"
                        endIcon={<ArrowForwardOutlinedIcon />}
                        disabled={!isValid}
                        variant="text"
                        id="statucNextButton"
                        className={[
                          classes.nextButton,
                          "statucNextButton",
                        ].join(" ")}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Fade>
  );
};
export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    ...aboutUsStyle(theme)
  })
)(BusinessCompnent);
