//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button, MenuItem } from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form } from "formik";

export class ChangeStatusDialog extends React.Component {
  

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
       this.setState({
        token: token,
      });
    }
  }

  

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;

    const clientStatusOptions = ["Select Status", "Declined", "Archived"];

    return (
      <Formik
        initialValues={{
          status: "Select Status",
        }}
        enableReinitialize
        onSubmit={(values) => {
          this.closeDialog({
            value: values.status,
          });
        }}
      >
        {(formikProps) => {
          const { values } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container style={{ width: "25vw", padding: "1rem" }}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h3" className={classes.dialogHeading}>
                    Change Status
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                      <Field component={InputField} id="selectStart" name="status" fullWidth select>
                        {clientStatusOptions.map((opt) => {
                          return (
                            <MenuItem key={opt} value={opt} disabled={opt === "Select Status"}>
                              {opt}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>

                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={this.closeDialog.bind(this)}
                        className={classes.uploadNowButton}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.halfWidthGrid}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        className={classes.uploadNowButton}
                        disabled={values.status === "Select Status"}
                        id='noButton'
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ChangeStatusDialogWithRouter = withRouter(ChangeStatusDialog);
const ChangeStatusDialogWithToast = withToast(ChangeStatusDialogWithRouter);
const ChangeStatusDialogWithLoader = withLoader(ChangeStatusDialogWithToast);
const ChangeStatusDialogWithAlertBox = withAlertBox(ChangeStatusDialogWithLoader);
const ChangeStatusDialogWithDialogBox = withDialogBox(ChangeStatusDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      marginTop: "2rem",
      marginBottom: "1rem",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
      marginBottom: "1rem",
    },
    bottomMargin: {
      marginBottom: "1rem",
    },
    capsuleContainer: {
      backgroundColor: "#EAE8E9",
      borderRadius: "2.5rem",
    },
    capsuleButton: {
      width: "50%",
      borderRadius: "2.5rem",
      padding: 0,
      height: "2.2rem",
      backgroundColor: "#EAE8E9",
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#EAE8E9",
      },
    },
    capsuleButtonSelected: {
      width: "50%",
      borderRadius: "2.5rem",
      padding: 0,
      height: "2.2rem",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    fieldLabels: {
      color: theme.palette.text.secondary,
      fontFamily: "Nunito Sans",
      fontWeight: 600,
    },
  })
)(ChangeStatusDialogWithDialogBox);