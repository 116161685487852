//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button } from "@material-ui/core";
import { withRouter, RouterProps, RouteComponentProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../../components/src/withSnackBar.Web";
import withLoader, { withLoaderProps } from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { DialogProps } from "../../../../components/src/DialogContext";
import CustomGrid from "../../../../components/src/CustomGrid.web";

export type Props = RouterProps &
  RouteComponentProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    classes: any;
    dataToPass: object;
    // Customizable Area Start
    // Customizable Area End
  };

class AddPrioritiesDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  closeDialog = (data: string | null) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center" style={{ padding: "1rem" }}>
        {this.props.dataToPass?.dialogHeading ? (
          <Grid item xs={12}>
            <Typography align="center" variant="h3" className={classes.dialogHeading}>
              {this.props.dataToPass?.dialogHeading}
            </Typography>
          </Grid>
        ) : null}
        {this.props.dataToPass?.description ? (
          <Grid item xs={10}>
            <Typography align="center" variant="h3" className={classes.dialogDescription}>
              {this.props.dataToPass?.description}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <CustomGrid item xs={6} width="25%" margin="0 2%">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.uploadNowButton}
                onClick={() => {
                  this.closeDialog("Yes");
                }}
              >
                {this.props.dataToPass?.yesButtonText || "Yes"}
              </Button>
            </CustomGrid>
            <CustomGrid item xs={6} width="25%" margin="0 2%">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  this.closeDialog(null);
                }}
                className={classes.uploadNowButton}
              >
                {this.props.dataToPass?.noButtonText || "Cancel"}
              </Button>
            </CustomGrid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const AddPrioritiesDialogWithRouter = withRouter(AddPrioritiesDialog);
const AddPrioritiesDialogWithToast = withToast(AddPrioritiesDialogWithRouter);
const AddPrioritiesDialogWithLoader = withLoader(AddPrioritiesDialogWithToast);
const AddPrioritiesDialogWithAlertBox = withAlertBox(AddPrioritiesDialogWithLoader);
const AddPrioritiesDialogWithDialogBox = withDialogBox(AddPrioritiesDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.text.primary,
      marginBottom: "1rem",
    },
    dialogDescription: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    },
    uploadNowButton: {
      marginTop: "2rem",
      marginBottom: "1rem",
    },
  })
)(AddPrioritiesDialogWithDialogBox);
