const packageList = [
    {
      name: "New Business Incubator",
      key: "new_business_incubator",
      isAvailableForEnrollment: true,
    },
    {
      name: "Business Bundle",
      key: "business_bundle",
      isAvailableForEnrollment: true,
    },
    {
      name: "Business Tax & Advisory",
      key: "business_tax_and_advisory",
      isAvailableForEnrollment: true,
    },
    {
      name: "Accounting Success Package",
      key: "accounting_success",
      isAvailableForEnrollment: true,
    },
    {
      name: "Payroll Success Package",
      key: "payroll_success",
      isAvailableForEnrollment: true,
    },
    {
      name: "Personal Bundle",
      key: "personal_bundle",
      isAvailableForEnrollment: true,
    },
    {
      name: "IRS Rapid Relief Package",
      key: "irs_rapid",
      isAvailableForEnrollment: true,
    },
    {
      name: "Reporting-Only Package (Individual)",
      key: "reporting_only_individual",
      isAvailableForEnrollment: true,
    },
    {
      name: "Reporting-Only Package (Business)",
      key: "reporting_only_business",
      isAvailableForEnrollment: true,
    },
  ]
  export const intitalQuestion = [
    {
      questionName: "Is this package designed for someone that owns a business?",
      name: "isOwnsABusiness",
      value: "",
      options: [
        {
          label: "Yes",
          value: "Business",
        },
        {
          label: "No",
          value: "Individual",
        },
      ],
    },
    {
      questionName: "Should this package help with accounting?",
      name: "isWithAccounting",
      value: null,
      options: [
        {
          label: "Yes",
          value: "Accounting",
        },
        {
          label: "No",
          value: "",
        },
      ],
    },
    {
      questionName: "Should this package help with payroll?",
      name: "isWithPayroll",
      value: null,
      options: [
        {
          label: "Yes",
          value: "Payroll",
        },
        {
          label: "No",
          value: "",
        },
      ],
    },
    {
      questionName: "Should this package help someone start a new business?",
      name: "isForNewBusiness",
      value: null,
      options: [
        {
          label: "Yes",
          value: "New Business",
        },
        {
          label: "No",
          value: "",
        },
      ],
    },
    {
      questionName: "Should this package help with IRS issues?",
      name: "isForIRSIssues",
      value: null,
      options: [
        {
          label: "Yes",
          value: "IRS Issues",
        },
        {
          label: "No",
          value: "",
        },
      ],
    },
    {
      questionName: "Should this package provide strategic advisory help?",
      name: "isForStrategicAdvisory",
      value: null,
      options: [
        {
          label: "Yes",
          value: "Strategic Help",
        },
        {
          label: "No",
          value: "",
        },
      ],
    },
  ];
  export default packageList;