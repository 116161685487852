//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button } from "@material-ui/core";
import { withRouter, RouterProps, RouteComponentProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../components/src/withSnackBar.Web";
import withLoader, { withLoaderProps } from "../../../components/src/withLoader.Web";
import withDialogBox from "../../../components/src/withDialog.web";
import { DialogProps } from "../../../components/src/DialogContext";
import CustomGrid from "../../../components/src/CustomGrid.web";
import { LightenDarkenColor } from "./utils.js";
import CloseIcon from "@material-ui/icons/Close";

export type Props = RouterProps &
  RouteComponentProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    classes: any;
    dataToPass: object;
    // Customizable Area Start
    // Customizable Area End
  };

export class DisclaimerDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  closeDialog = (data: string | null = null) => {
    const { onSubmit } = this.props;
    onSubmit({result : data});
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center" style={{ padding: "1rem" }} className={classes.disclaimerDialogPaper}>
        <Grid item xs={12} container justifyContent="flex-end">
          <CloseIcon onClick={this.closeDialog.bind(this,"")} className={classes.closeIcon} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3" className={classes.dialogHeading}>
            This is our best price estimate.
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography align="center" variant="h3" className={classes.dialogDescription}>
            The price could change in the future if we learn new details regarding your needs or other services are required. We will communicate with you
            directly if the price changes.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" direction="column">
            <CustomGrid item xs={12} width="50%" margin="0 2%">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.uploadNowButton}
                onClick={this.closeDialog.bind(this,"Yes")}
              >
                I Understand and Agree - Continue
              </Button>
            </CustomGrid>
            <CustomGrid item xs={3} margin="0 2%">
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={this.closeDialog.bind(this,"")}
                className={classes.goBackButton}
              >
                Go Back
              </Button>
            </CustomGrid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const DisclaimerDialogWithRouter = withRouter(DisclaimerDialog);
const DisclaimerDialogWithToast = withToast(DisclaimerDialogWithRouter);
const DisclaimerDialogWithLoader = withLoader(DisclaimerDialogWithToast);
const DisclaimerDialogWithAlertBox = withAlertBox(DisclaimerDialogWithLoader);
const DisclaimerDialogWithDialogBox = withDialogBox(DisclaimerDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    dialogHeading: {
      fontSize: "2.2rem",
      fontWeight: "bold",
      color: theme.palette.common.white,
      marginBottom: "2rem",
    },
    dialogDescription: {
      fontSize: "1.1rem",
      color: theme.palette.common.white,
      fontWeight: "normal",
    },
    uploadNowButton: {
      marginTop: "2rem",
      marginBottom: "1rem",
      textTransform: "none",
      backgroundColor: localStorage.getItem('secondaryColor') || "#FF1A69",
      color: "white",
      padding: "2rem",
      "&:hover": {
        backgroundColor: LightenDarkenColor((localStorage.getItem('secondaryColor') || "#FF1A69"), -20),
      },
    },
    goBackButton: {
      color: "white!important",
    },
    closeIcon: {
      cursor: "pointer",
      fontSize: "1rem",
    },
    disclaimerDialogPaper: {
      backgroundColor: localStorage.getItem('primaryColor') || theme.palette.primary.main
    }
  })
)(DisclaimerDialogWithDialogBox);
