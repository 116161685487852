//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles } from "@material-ui/core";
import ViewChecklistDialogController from "./ViewChecklistDialogController.web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import * as DOMPurify from "dompurify";

export class ViewChecklistDialog extends ViewChecklistDialogController {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getSingleChecklist(this.props.dataToPass.checklistId);
        }
      );
    }
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        className={classes.rootContainer}
      >
        <Grid item xs={12}>
          <Typography align="center" className={classes.dialogHeading}>
            {this.state.singleChecklist?.attributes?.checklist_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.scrollableContainer}>
            {this.state.singleChecklist?.attributes?.sections?.data.length > 0
              ? this.state.singleChecklist?.attributes?.sections?.data?.map(
                  (sectionItem) => {
                    return (
                      <>
                        <Grid item xs={12} className={classes.sectionName}>
                          {sectionItem.attributes.section_name}
                        </Grid>
                        {sectionItem?.attributes?.questions?.data.length > 0 ? (
                          sectionItem?.attributes?.questions?.data?.map(
                            (questionItem) => (
                              <Grid
                                item
                                xs={12}
                                className={classes.questionName}
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    questionItem.attributes.question_name,
                                    { ADD_ATTR: ["target"] }
                                  ),
                                }}
                              ></Grid>
                            )
                          )
                        ) : (
                          <Grid item xs={12} className={classes.noQuestionName}>
                            No questions available.
                          </Grid>
                        )}
                      </>
                    );
                  }
                )
              : this.state.singleChecklist?.attributes?.sections?.data && (
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    className={classes.noSectionName}
                  >
                    No sections available.
                  </Grid>
                )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ViewChecklistDialogWithRouter = withRouter(ViewChecklistDialog);
const ViewChecklistDialogWithToast = withToast(ViewChecklistDialogWithRouter);
const ViewChecklistDialogWithLoader = withLoader(ViewChecklistDialogWithToast);
const ViewChecklistDialogWithAlertBox = withAlertBox(
  ViewChecklistDialogWithLoader
);
const ViewChecklistDialogWithDialogBox = withDialogBox(
  ViewChecklistDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      width: "60vw",
      padding: "1rem",
      paddingRight: 0,
      paddingBottom: "2rem",
    },
    dialogHeading: {
      fontSize: "1.8rem",
      // fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "0.5rem",
    },
    subHeading: {
      fontWeight: "bold",
      fontSize: "1.2rem",
      marginBottom: "1rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
      marginBottom: "1.25rem",
    },
    checkboxGrid: {
      backgroundColor: "#f8f8f8",
      padding: "0.5rem 1rem",
      borderRadius: "1rem",
      marginBottom: "1rem",
      marginRight: "0.25rem",
    },
    scrollableContainer: {
      marginTop: "1rem",
      maxHeight: "70vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c9c9c9",
        borderRadius: "0.5rem",
      },
    },
    checklistContainer: {
      padding: "1rem",
    },
    sectionName: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: theme.palette.text.secondary,
      position: "relative",
      margin: "0.5rem 0px",
      marginLeft: "2rem",
      display: "block",
      "&:before": {
        content: "''",
        width: "12px",
        height: "12px",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: "0.5rem",
        left: "-1.5rem",
        display: "block",
        borderRadius: "50%",
      },
    },
    noSectionName: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#FF5C5C",
      margin: "0.5rem 0px",
      display: "block",
      textAlign: "center",
    },
    questionName: {
      fontSize: "1.4rem",
      color: "#9a9a9a",
      position: "relative",
      margin: "0.8rem 0px",
      marginLeft: "5rem",
      display: "block",
      paddingRight: "1rem",
      "&:before": {
        content: "''",
        width: "8px",
        height: "8px",
        backgroundColor: theme.palette.text.secondary,
        position: "absolute",
        top: "0.8rem",
        left: "-1.5rem",
        display: "block",
        borderRadius: "50%",
      },
    },
    noQuestionName: {
      fontSize: "1.4rem",
      color: "#FF5C5C",
      position: "relative",
      margin: "0.8rem 0px",
      marginLeft: "5rem",
      display: "block",
      paddingRight: "1rem",
      "&:before": {
        content: "''",
        width: "8px",
        height: "8px",
        backgroundColor: "#FF5C5C",
        position: "absolute",
        top: "0.8rem",
        left: "-1.5rem",
        display: "block",
        borderRadius: "50%",
      },
    },
  })
)(ViewChecklistDialogWithDialogBox);
