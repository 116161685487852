//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import { processPaymentTerms } from "../ClientList/ValueRoadmapController.web";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  packageList: [];
  accountId: any;
  clientId: any;
  selectedPackageForRoadmap: any;
  roadMap: any;
  roadmapId: any;
  clientDetail: any;
  selectedPaymentTerms: [];
  isAlreadySelectedByClient: boolean;
  isLoadingPage: boolean;
  isEmailSent: boolean;
  currentStep: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RoadmapPackageSelectionByClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  getRoadMapForAPiCallId: string;
  getClientDetailApiCallId: string;
  createChecklistforSelectedPackageInRoadmapApiCallId: string;
  linkRoadmapToClientApiCallId: string;
  sendOnBoardingSurveyApiCallId: string;
  createClientForNewPortalRoadmapApiCallId: string;
  getCPADetialbyAccessTokenForRoadmapCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      packageList: [],
      accountId: null,
      clientId: null,
      selectedPackageForRoadmap: null,
      roadMap: null,
      roadmapId: null,
      clientDetail: null,
      selectedPaymentTerms: [],
      isAlreadySelectedByClient: false,
      isLoadingPage: true,
      isEmailSent: false,
      currentStep: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleAPiErrorResponse = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getClientDetailApiCallId === apiRequestCallId) {
        this.handleSingleClient(responseJson);
      } else if (this.getRoadMapForAPiCallId == apiRequestCallId) {
        this.handelRoadMapResponse(responseJson);
      } else if (
        this.createChecklistforSelectedPackageInRoadmapApiCallId ==
        apiRequestCallId
      ) {
        this.handleCreateChecklistForRoadmap(responseJson);
      } else if (this.sendOnBoardingSurveyApiCallId === apiRequestCallId) {
        this.sendOnBoardingSurveyResponse(responseJson);
      } else if (
        apiRequestCallId == this.createClientForNewPortalRoadmapApiCallId
      ) {
        this.handleCreateClientResponse(responseJson);
      } else if (apiRequestCallId == this.linkRoadmapToClientApiCallId) {
        this.linkRoadmapToClientResponse(responseJson);
      } else if (
        this.getCPADetialbyAccessTokenForRoadmapCallId == apiRequestCallId
      ) {
        this.handleCPADetialbyAccessTokenResponseForRoadmap(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
    }
  }
  handleCPADetialbyAccessTokenResponseForRoadmap = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      this.setState(
        {
          cpaDetail: responseJson.data.attributes,
        },
        () => {
          this.setDocumentTitleAndIconForRoadmap();
          this.getRoadMapById(this.state.roadmapId);
        }
      );
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  setDocumentTitleAndIconForRoadmap = () => {
    if (this.state.cpaDetail?.theme?.logo) {
      const canvas = document.createElement("canvas");
      canvas.width = 160;
      canvas.height = 160;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.src = this.state.cpaDetail?.theme?.logo;

      img.onload = this.onImageLoad.bind(this, ctx, img, canvas);
    }
    if (this.state.cpaDetail.theme?.firm_name) {
      document.title = this.state.cpaDetail.theme.firm_name;
    }
  };
  onImageLoad = function(ctx, img, canvas) {
    ctx.drawImage(img, 0, 0);
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = canvas.toDataURL("image/x-icon");
  };
  linkRoadmapToClientResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson.id) {
      this.createChecklistforSelectedPackageInRoadmap(responseJson);
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleCreateClientResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message && responseJson?.success) {
      this.props.showToast({
        message: responseJson?.message,
        type: "success",
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };

  handleCreateChecklistForRoadmap = async (responseJson) => {
    this.props.hideLoader();
    this.sendOnBoardingSurvey();
  };
  handleSingleClient = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      this.setState({
        clientDetail: responseJson.data.attributes,
        isLoadingPage: false,
      });
    } else {
      this.handleAPiErrorResponse(responseJson);
    }
  };
  getSelectedPaymentTerms = () => {
    const selectedPaymentTerms = [];
    for (const roadmappkg of this.state.roadMap.attributes.custom_packages
      .data) {
      let paymentTemrs = processPaymentTerms({ data: roadmappkg });
      paymentTemrs.forEach((paymentterm: any) => {
        if (!paymentterm.id) {
          selectedPaymentTerms.push({
            ...roadmappkg,
            attributes: {
              ...roadmappkg.attributes,
              selectedPaymentTerm: {
                ...paymentterm,
                id: roadmappkg.id,
              },
            },
          });
        }
      });
    }
    const selectedPackageForRoadMap = selectedPaymentTerms.find((pkg) => {
      const paymentTerm = this.state.roadMap?.attributes?.obs?.payment_term;
      return (
        pkg.attributes.selectedPaymentTerm.paymentTermKey +
          "_" +
          pkg.attributes.selectedPaymentTerm.id ==
        paymentTerm +
          "_" +
          this.state.roadMap?.attributes?.obs?.custom_package_management_id
      );
    });
    return { selectedPaymentTerms, selectedPackageForRoadMap };
  };

  handelRoadMapResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      localStorage.setItem(
        "primaryColor",
        responseJson.data.attributes.add_primary_color
      );
      localStorage.setItem(
        "secondaryColor",
        responseJson.data.attributes.add_secondary_color
      );
      this.setState(
        {
          roadMap: responseJson.data,
        },
        () => {
          const {
            selectedPaymentTerms,
            selectedPackageForRoadMap,
          } = this.getSelectedPaymentTerms();

          if (selectedPackageForRoadMap) {
            if (selectedPackageForRoadMap.id) {
              this.setState(
                {
                  selectedPaymentTerms: selectedPaymentTerms,
                  selectedPackageForRoadmap: selectedPackageForRoadMap,
                  isAlreadySelectedByClient: !!selectedPackageForRoadMap.id
                },
                this.a
              );
            }
          } else {
            this.setState(
              {
                selectedPaymentTerms: selectedPaymentTerms,
              },
              this.a
            );
          }
        }
      );
    } else {
      this.handleAPiErrorResponse(responseJson);
    }
  };
  a = () => {
    if (this.state.clientId) {
      this.getClientDetail();
    }
  };
  sendOnBoardingSurveyResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message) {
      if (responseJson.message.toLowerCase().indexOf("success") > -1) {
        this.setState(
          {
            isEmailSent: true,
          },
          this.createClientForNewPortalRoadmap
        );
      }
      this.props.showToast({
        message: responseJson.message,
        type:
          responseJson.message.toLowerCase().indexOf("success") > -1
            ? "success"
            : "error",
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };

  getClientDetail = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getClientDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}/get_client_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendOnBoardingSurvey = () => {
    this.props.history.push({
      pathname: "/onboardingSurveyLandingPage",
      search: `?survey_id=&package_management_id=&client_id=${this.state.clientId}&account_id=${this.state.accountId}&selected_package_id=${this.state.selectedPackageForRoadmap?.id}&allow_to_change_package_id=false&roadmap_id=${this.state.roadmapId}&payment_term=${this.state.selectedPackageForRoadmap?.attributes?.selectedPaymentTerm?.paymentTermKey}&custom_package_id=${this.state.selectedPackageForRoadmap?.id}&parent_package_type=${this.state.selectedPackageForRoadmap?.attributes?.parent_package_type}`,
    });
    
  };

  createChecklistforSelectedPackageInRoadmap = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.createChecklistforSelectedPackageInRoadmapApiCallId =
      requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}/create_checklists_for_roadmap`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        custotom_package_id: this.state.selectedPackageForRoadmap?.id,
        roadmap_id: this.state.selectedPackageForRoadmap?.attributes
          ?.road_customization_id,
        parent_package_type: this.state.selectedPackageForRoadmap?.attributes
          ?.parent_package_type,
        account_id: this.state.accountId,
        id: this.state.clientId,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRoadMapById = (id: any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.getRoadMapForAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/road_customizations/${this.state.roadmapId}/copy_link?account_id=${this.state.accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createClientForNewPortalRoadmap = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.createClientForNewPortalRoadmapApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          primary_email: this.state?.clientDetail?.primary_email,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  linkRoadmapToClient = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.linkRoadmapToClientApiCallId = requestMessage.messageId;

    const httpBody = {
      custotom_package_id: this.state.selectedPackageForRoadmap?.id,
      roadmap_id: this.state.selectedPackageForRoadmap?.attributes
        ?.road_customization_id,
      client_id: this.state.clientId,
      account_id: this.state.accountId,

      selected_payment_term: this.state.selectedPackageForRoadmap?.attributes
        ?.parent_package_type,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/linkRoadmapToClient`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCPADetialbyAccessTokenForRoadmap = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };

    const data = {
      data: {
        attributes: {
          access_token: "",
          account_id: this.state.accountId || "",
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/access_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.getCPADetialbyAccessTokenForRoadmapCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
