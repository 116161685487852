//@ts-nocheck
import React from "react";
import NewDashboardController from "./NewDashboardController.web";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  TextField,
  InputAdornment,
  LinearProgress,
  Hidden,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { zoomIcon, priceDown } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { commonStyles } from "./commonStyles.js";
import { smallChartOptions } from "./chartOptions";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Line } from "react-chartjs-2";
import SearchIcon from "@material-ui/icons/Search";
import withPermissions from "../../../components/src/withPermissions.web";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StyledTableCell = withStyles((theme) => ({
  root: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: theme.spacing(2),
      borderBottomLeftRadius: theme.spacing(2),
    },
    "&:last-of-type": {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
  },
  head: {
    color: theme.palette.text.blue2,
    fontSize: "1.2rem",
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
    border: "none",
    backgroundColor: "#f9f9f9",
    padding: "1.5rem 0rem",
    position: "sticky",
    top: 0,
  },
  body: {
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    border: "none",
    fontFamily: "Nunito Sans",
    padding: "1.5rem 0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}))(TableCell);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export const pieChartData = {
  datasets: [
    {
      label: "# of Votes",
      data: [0, 100],
      backgroundColor: ["#8386F1", "#91CFCC"],
      borderWidth: 1,
    },
  ],
};

const labels = [];

export const lineChartData = {
  labels,
  datasets: [],
};

export const lineChartOptions = {
  pointBackgroundColor: "rgba(72, 100, 132, 1)",
  pointBorderColor: "rgba(72, 100, 132, 1)",
  pointBorderWidth: 2,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItems, data) {
          return tooltipItems.yLabel + " %";
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function(value, index, ticks) {
          return value + "k";
        },
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 5,
      },
      grid: {
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
    y: {
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        stepSize: 20,
        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 5,
      },
      grid: {
        borderDash: [5, 5],
        lineWidth: 2,
        borderColor: "#979797",
        display: true,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        dash: true,
        color: function(context) {
          if (context.tick.value === 140) {
            return "#e4e4e4";
          } else {
            return "transparent";
          }
        },
      },
    },
  },
};

export class NewDashboard extends NewDashboardController {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getProfileForDashboard();
      }
    );
  }

  openChartDialog = (data) => {};

  render() {
    const { classes, theme } = this.props;
    return (
      <>
        <Grid container justifyContent="space-between">
          {/* fist line */}

          <Hidden smUp>
            <Grid item xs={12} className={[classes.gridSpacing]}>
              <TextField
                variant="outlined"
                placeholder="Search..."
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        paddingRight: 0,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Hidden>
          {/* second line */}

          <Grid item xs={12} className={[classes.gridSpacing]}>
            <Typography
              variant="h1"
              style={{
                color: theme.palette.text.blue2,
                fontSize: "1.6rem",
                paddingLeft: "1rem",
              }}
            >
              {this.state.profile?.employee_name
                ? `Hi, ${this.state.profile?.employee_name}`
                : `Hi, ${this.state?.profile?.first_name} ${this.state?.profile?.last_name}`}
            </Typography>
          </Grid>

          {/* third line */}

          <Grid
            item
            xs={12}
            md={5}
            container
            justifyContent="flex-end"
            className={[classes.gridSpacing, classes.whiteCard]}
          >
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.cardHeading}
              >
                Revenue Goal
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.revenueGoalPrice}
              >
                $0
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.revenueGoalPrice}
                align="right"
              >
                $0
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.revenueGoalPrice}
                align="right"
              >
                $0
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BorderLinearProgress variant="determinate" value={0} />
            </Grid>
            <Grid item xs={7}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.revenueGoalPriceText}
                align="right"
              >
                Current Revenue
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.revenueGoalPriceText}
                align="right"
              >
                Company Revenue <br /> Goal
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            justifyContent="flex-end"
            alignItems="center"
            className={[classes.gridSpacing, classes.greenCard]}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.cardHeadingWhite}
              >
                Average Revenue per Client
              </Typography>
              <Typography
                display="inline"
                className={classes.cardOptionsWhiteSelected}
              >
                Combined
              </Typography>
              <Typography display="inline" className={classes.cardOptionsWhite}>
                Business
              </Typography>
              <Typography display="inline" className={classes.cardOptionsWhite}>
                Individual
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              className={classes.cardOptionsPriceContainer}
            >
              <img
                src={priceDown}
                className={classes.priceUpDownIconStyle}
                alt="priceUp"
              />
              <Typography display="inline" className={classes.cardOptionsPrice}>
                $ 0
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            justifyContent="flex-end"
            alignItems="center"
            className={[classes.gridSpacing, classes.blueCard]}
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.cardHeadingWhite}
              >
                Revenue
              </Typography>
              <Typography
                display="inline"
                className={classes.cardOptionsWhiteLarge}
              >
                This Month | Last Month
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              className={classes.cardOptionsPriceContainer}
            >
              <img
                src={priceDown}
                className={classes.priceUpDownIconStyle}
                alt="priceDown"
              />
              <Typography display="inline" className={classes.cardOptionsPrice}>
                -0%
              </Typography>
            </Grid>
          </Grid>

          {/* fourth line */}

          <Grid
            item
            xs={12}
            md={4}
            container
            className={[
              classes.gridSpacing,
              classes.whiteCard,
              classes.gridSize40Percent,
            ]}
          >
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.cardHeading}
              >
                Revenue Breakdown
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <Box
                    style={{
                      height: "6.25rem",
                      width: "6.25rem",
                      position: "absolute",
                      top: "20%",
                      left: "10%",
                    }}
                  >
                    <Pie data={pieChartData} />
                  </Box>
                  <Typography
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    className={classes.revenueBreakdownKeys}
                  >
                    Recurring Payment <br /> (0%)
                  </Typography>
                  <Typography
                    style={{
                      position: "absolute",
                      bottom: "-30%",
                      right: "20%",
                    }}
                    className={classes.revenueBreakdownKeys}
                  >
                    One Time <br /> Payment (100%)
                  </Typography>
                </Grid>
                <Box className={classes.verticalDevider} />
                <Grid item xs={4}>
                  {/* <Typography className={classes.revenueBreakdownKeys}>Survey Completed</Typography> */}
                  <Typography className={classes.revenueBreakdownKeys}>
                    Total Revenue
                  </Typography>
                  <Typography className={classes.revenueBreakdownKeys}>
                    Monthly Revenue
                  </Typography>
                  <Typography className={classes.revenueBreakdownKeys}>
                    One Time Payments (2)
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {/* <Typography color="primary" className={classes.revenueBreakdownValues}>
                    Yes
                  </Typography> */}
                  <Typography
                    color="primary"
                    className={classes.revenueBreakdownValues}
                  >
                    $0
                  </Typography>
                  <Typography
                    color="primary"
                    className={classes.revenueBreakdownValues}
                  >
                    $0/m
                  </Typography>
                  <Typography
                    color="primary"
                    className={classes.revenueBreakdownValues}
                  >
                    $0
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    style={{
                      fontSize: "8px",
                      marginTop: "35px",
                    }}
                  >
                    Recurring Payment vs One Time Payment
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            justifyContent="flex-end"
            alignItems="center"
            className={[
              classes.gridSpacing,
              classes.whiteCard,
              classes.gridSize30Percent,
            ]}
          >
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.cardHeading}
                display="inline"
              >
                Revenue (Last Quarter)
              </Typography>
              <img
                src={zoomIcon}
                alt="zoomIcon"
                onClick={this.openChartDialog}
                className={classes.zoomIconStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  height: "15rem",
                  width: "100%",
                }}
              >
                <Line data={lineChartData} options={smallChartOptions} />
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            container
            justifyContent="flex-end"
            alignItems="center"
            className={[
              classes.gridSpacing,
              classes.whiteCard,
              classes.gridSize30Percent,
            ]}
          >
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color="textSecondary"
                variant="h4"
                gutterBottom
                className={classes.cardHeading}
              >
                Customer Growth (Last Quarter)
              </Typography>
              <img
                src={zoomIcon}
                className={classes.zoomIconStyle}
                alt="zoomIcon"
                onClick={this.openChartDialog}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  height: "15rem",
                  width: "100%",
                }}
              >
                <Line data={lineChartData} options={smallChartOptions} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* fifth line */}
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={[classes.gridSpacing, classes.whiteCard]}
        >
          <Grid item xs={12}>
            <Grid container>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 0.5rem",
                    position: "relative",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="2%" />
                      <StyledTableCell width="19%">Client Name</StyledTableCell>
                      <StyledTableCell width="19%">Date | Time</StyledTableCell>
                      <StyledTableCell width="20%">
                        More Details
                      </StyledTableCell>
                      <StyledTableCell width="20%">Notify</StyledTableCell>
                      <StyledTableCell width="20%">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid item xs={12} className={[classes.clientListCard]}>
            <Grid container>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 0.5rem",
                    position: "relative",
                  }}
                >
                  <TableBody>
                    {[].length > 0 ? (
                      [].map((item, index) => {
                        return (
                          <TableRow key={item} className={classes.clientRow}>
                            <StyledTableCell width="2%">
                              <Box
                                className={classes.clientListColorBar}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#ed1867" : "#54A4A6",
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell width="19%">
                              <Typography
                                className={[classes.weight700]}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.props.history.push(
                                    "/home/clientsList/details/overview"
                                  )
                                }
                              >
                                Gabriel Soares
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell
                              width="19%"
                              className={[classes.weight600]}
                            >
                              02/03/2022 | 12:32
                            </StyledTableCell>
                            <StyledTableCell
                              width="20%"
                              className={[classes.linkText, classes.weight600]}
                            >
                              View Survey Submission
                            </StyledTableCell>
                            <StyledTableCell
                              width="20%"
                              className={[classes.linkText, classes.weight600]}
                            >
                              Send Reminder Email
                            </StyledTableCell>
                            <StyledTableCell
                              width="20%"
                              style={{
                                color: index % 2 === 0 ? "#ed1867" : "#54A4A6",
                                fontWeight: "bold",
                              }}
                            >
                              {index % 2 === 0
                                ? "Payment Pending"
                                : "New Prospect"}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6}>
                          <Typography align="center">
                            No data to display
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const NewDashboardWithRouter = withRouter(NewDashboard);
const NewDashboardAlertBox = withAlertBox(NewDashboardWithRouter);
const NewDashboardLoader = withLoader(NewDashboardAlertBox);
const NewDashboardToast = withToast(NewDashboardLoader);
const NewDashboardWithDialog = withDialog(NewDashboardToast);
const NewDashboardWithConfirmBox = withConfirmBox(NewDashboardWithDialog);
const NewDashboardWithPermissions = withPermissions(NewDashboardWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      ...commonStyles(theme),
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        // boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
        boxShadow: theme.shadows[25],
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      greenCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#9CBE6A",
        boxShadow: theme.shadows[25],
      },
      blueCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#476484",
        boxShadow: theme.shadows[25],
      },
      cardHeading: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        letterSpacing: 1,
      },
      cardHeadingWhite: {
        fontSize: "1.2rem",
        color: "#f1f1f1",
        fontWeight: "bold",
        letterSpacing: 1,
      },
      cardOptionsWhiteSelected: {
        display: "inline-block",
        fontSize: "1rem",
        color: "#f1f1f1",
        fontWeight: "bold",
        letterSpacing: 1,
        borderBottom: "2px solid #f1f1f1",
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(0.5),
      },
      cardOptionsWhiteLarge: {
        display: "inline-block",
        fontSize: "1rem",
        color: "#f1f1f1",
        fontWeight: "bold",
        letterSpacing: 1,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(0.5),
      },
      cardOptionsPriceContainer: {
        margin: theme.spacing(3, 0, 1, 0),
      },
      cardOptionsPrice: {
        fontSize: "2rem",
        color: "#f1f1f1",
        fontWeight: "bold",
        letterSpacing: 1,
      },
      cardOptionsWhite: {
        display: "inline-block",
        fontSize: "0.8rem",
        color: "#f1f1f1",
        fontWeight: "bold",
        letterSpacing: 1,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        cursor: "pointer",
      },
      clientListCard: {
        maxHeight: "30vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "2px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
      revenueBreakdownKeys: {
        fontSize: "0.7rem",
        color: theme.palette.text.secondary,
        marginBottom: "2em",
        marginTop: "0.2em",
        fontWeight: "bold",
      },
      revenueBreakdownValues: {
        fontSize: "0.7rem",
        marginBottom: "2em",
        marginTop: "0.2em",
        fontWeight: "bold",
      },
      clientRow: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 0),
        borderRadius: theme.spacing(2),
        backgroundColor: "#fff",
        boxShadow: theme.shadows[25],
      },
      clientListColorBar: {
        position: "absolute",
        left: 0,
        top: "30%",
        width: "4px",
        height: "40%",
      },
      gridSize40Percent: {
        flexGrow: 0,
        maxWidth: "38%",
        flexBasis: "38%",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
          flexGrow: 0,
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },
      gridSize45Percent: {
        flexGrow: 0,
        maxWidth: "45%",
        flexBasis: "45%",
        [theme.breakpoints.down("xs")]: {
          flexGrow: 0,
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },
      gridSize25Percent: {
        flexGrow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
        [theme.breakpoints.down("xs")]: {
          flexGrow: 0,
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },
      gridSize30Percent: {
        flexGrow: 0,
        maxWidth: "28%",
        flexBasis: "28%",
        minHeight: "270px",
        [theme.breakpoints.down("xs")]: {
          flexGrow: 0,
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },
      zoomIconStyle: {
        height: "1.875rem",
        width: "1.875rem",
        cursor: "pointer",
      },
      priceUpDownIconStyle: {
        height: "1.875rem",
        width: "1.875rem",
        marginRight: "0.625rem",
      },
      revenueGoalPrice: {
        fontSize: "1.1rem",
        fontWeight: "bold",
        letterSpacing: 1,
        color: theme.palette.text.blue2,
      },
      revenueGoalPriceText: {
        fontSize: "0.8rem",
        fontWeight: "bold",
        letterSpacing: 1,
        color: theme.palette.text.secondary,
      },
      verticalDevider: {
        position: "absolute",
        height: "60%",
        width: "1px",
        backgroundColor: "rgb(224, 224, 224, 0.6)",
        left: "47%",
        top: "20%",
      },
    }),
  { withTheme: true }
)(NewDashboardWithPermissions);
