//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
  Omit<MuiTextFieldProps, "name" | "value" | "error"> { }

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const InputFieldWithFormik = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      {...props}
      variant="standard"
      margin="10px"
      // InputLabelProps={{
      //   ...InputLabelProps, shrink: true, classes: {
      //     asterisk: classes?.astriklabel,
      //     outlined: classes?.outlinedLabel,
      //     shrink: classes?.shrink,
      //     root: classes?.lableroot
      //   }
      // }}
      InputProps={{
        ...InputProps,
        classes: {
          input: classes?.input,
          
        },
        notched: false,
      }}

    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot: {
      outline: 'none',
      border: `2px solid #f4f4f4`,
      borderRadius: '0.75rem',
      backgroundColor: '#f4f4f4',
      // transition: "all 200ms ease",
      'label + &': {
        marginTop: 30,
        marginBottom: 20,
      },
      "& svg": {
        right: "12px",
      },
      '& > fieldset': {
        border: 'none',
        '& active': {
          border: 'none'
        },
        '& focus': {
          border: 'none'
        }
      },
      "&.Mui-focused": {
        border: `2px solid ${theme.palette.text.blue2}`,
        backgroundColor: '#fff',
        boxShadow: theme.shadows[25]
      },
      "&.Mui-error": {
        border: `2px solid #FF5C5C`,
        backgroundColor: '#f4f4f4',
        "&.Mui-focused": {
          backgroundColor: '#fff',
          boxShadow: theme.shadows[25]
        }
      },
      "&.MuiSelect-root": {
        border: "none",
        outline: "none",
      },
      "& + p": {
        "&.MuiFormHelperText-root.Mui-error": {
          color: "#FF5C5C",
          fontSize: "2rem"
        }
      },
    },
    input: {
      fontSize: '1.2rem',
      fontFamily: "Nunito Sans",
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '2px',
      "&:focus": {
        padding : '2px 2px',
        fontSize : '1.2rem'
      },
    },
    multiline: {
      padding: 0
    },
    inputMultiline: {
      borderRadius: '0.75rem',
        position: 'relative',
        backgroundColor: '#f4f4f4',
        border: 'none',
        fontSize: '1rem',
        fontFamily: 'Nunito Sans',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        "&:focus": {
            border: "none",
            borderRadius: '0.75rem',
            backgroundColor: '#fff',
            "&::placeholder": {
              color: theme.palette.text.blue2,
            }
          },
    },
    astriklabel: {
      fontFamily: "Nunito Sans",
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#959ead'
    },
    lableroot: {
      fontFamily: "Nunito Sans",
      fontSize: '0.875rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: theme.palette.text.blue,
      "&.Mui-focused": {
        color: theme.palette.text.blue2
      },
    },
    outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important'
    },
    shrink: {
      transform: 'translate(3px, 9px) scale(1)!important'
    }
  })
)(InputFieldWithFormik);
