//@ts-nocheck
import React from "react";
import FirmPerformanceSurveyController, { Props } from "./FirmPerformanceSurveyController.web";
import { Typography, Grid, withStyles, createStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import DataCard from "../../../../components/src/DataCard.web";
import { firmPerformanceStyles } from "./FirmPerformanceRevenue.web";

export class FirmPerformanceSurvey extends FirmPerformanceSurveyController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getSurveyCount();
      }
    );
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          style={{
            position: "relative",
          }}
        >
          <Grid item xs={11} className={[classes.gridSpacing, classes.sectionHeadings]}>
            <Typography
              variant="h2"
              style={{
                color: theme.palette.text.blue2,
              }}
              id="page-heading"
            >
              Survey
            </Typography>
          </Grid>

          <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard, classes.surveyCardsContainer]}>
            <Grid container justifyContent="space-between">
              <DataCard primaryText={this.state.link_sent}>Links Sent</DataCard>
              <DataCard primaryText={this.state.custom_link_count}>
                People accessed the <br /> custom link
              </DataCard>
              <DataCard primaryText={this.state.not_compeleted}>
                People who started, but <br /> didn't complete
              </DataCard>
              <DataCard primaryText={this.state.submitted}>People who completed</DataCard>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const FirmPerformanceSurveyWithRouter = withRouter(FirmPerformanceSurvey);
const FirmPerformanceSurveyAlertBox = withAlertBox(FirmPerformanceSurveyWithRouter);
const FirmPerformanceSurveyLoader = withLoader(FirmPerformanceSurveyAlertBox);
const FirmPerformanceSurveyToast = withToast(FirmPerformanceSurveyLoader);
const FirmPerformanceSurveyWithDialog = withDialog(FirmPerformanceSurveyToast);

export default withStyles(
  (theme) =>
    createStyles({
      ...firmPerformanceStyles(theme),
    }),
  { withTheme: true }
)(withConfirmBox(FirmPerformanceSurveyWithDialog));
