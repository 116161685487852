//@ts-nocheck
import React from "react";
import FirmPerformanceRevenueController, {
  Props,
} from "./FirmPerformanceRevenueController.web";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { priceDown, priceUp, zoomIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import MediumBlueField from "../../../../components/src/MediumBlueField.web";
import CustomerGrowthChartDialog from "./CustomerGrowthChartDialog.web";
import RevenueGrowthChartDialog from "./RevenueGrowthChartDialog.web";
import { Line } from "react-chartjs-2";
import { calculateTernary } from "../TernaryCheck.web";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const doubleLineChartData = {
  labels : [],
  datasets: [
  ],
};

export const revenueGrowthChartoptions = {
  pointBackgroundColor: "rgba(72, 100, 132, 1)",
  pointBorderColor: "rgba(72, 100, 132, 1)",
  pointBorderWidth: 4,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItems, data) {
          return `${tooltipItems.formattedValue}`;
        },
      },
    },
    datalabels: {
      backgroundColor: 'transparent',
      borderRadius: 4,
      color: "rgba(72, 100, 132, 1)",
      font: {
        weight: 'bold'
      },
      formatter: function (value, index, values) {
        return (Number((value)).toString()); //pass tick values as a string into Number function
      },
      padding: 8
    }
  },
  gridLines: {
    lineWidth: 1,
    zeroLineWidth: 1
  },
  scales: {
    x: {
      offset: true,
      tickLength : 300,
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        lineWidth: 0,
        drawBorder: true,
        borderDash: [5, 5],
      },
    },
    y: {
      ticks: {
        callback: function (value, index, values) {
          return Number((value).toString()); //pass tick values as a string into Number function
        },
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 12,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderDash: [5, 5],
        lineWidth: context => context.tick.value == 0 ? 1 : 0 //Set only 0 line visible
      },
    },
  },
};

export const revenueChartoptions = {
  pointBackgroundColor: "rgba(72, 100, 132, 1)",
  pointBorderColor: "rgba(72, 100, 132, 1)",
  pointBorderWidth: 4,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItems, data) {
          return `$${tooltipItems.formattedValue}`;
        },
      },
    },
    datalabels: {
      backgroundColor: 'transparent',
      borderRadius: 4,
      color: "rgba(72, 100, 132, 1)",
      font: {
        weight: 'bold'
      },
      formatter: function (value, index, values) {
        return '$ ' +  parseFloat(Number((value/1000)).toFixed(2).toString())+'K'; //pass tick values as a string into Number function
      },
      padding: 8
    }
  },
  scales: {
    x: {
      offset: true,
      tickLength : 300,
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
    y: {
      ticks: {
        callback: function (value, index, values) {
          return Number((value/1000).toString())+'K'; //pass tick values as a string into Number function
        },
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 12,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderDash: [5, 5],
        lineWidth: 2,
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        dash: true,
        color: function(context) {
          if (context.tick.value === 140) {
            return "#e4e4e4";
          } else {
            return "transparent";
          }
        },
      },
    },
  },
};

export const customerGrowthChartOptions = {
  pointBackgroundColor: "#9CBE6A",
  pointBorderColor: "#9CBE6A",
  pointBorderWidth: 3,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItems, data) {
          return `${tooltipItems.formattedValue}`;
        },
      },
    },
    datalabels: {
      backgroundColor: 'transparent',
      borderRadius: 4,
      color: "rgba(72, 100, 132, 1)",
      font: {
        weight: 'bold'
      },
      formatter: function (value, index, values) {
        return parseFloat(Number((value)).toFixed(2).toString()); //pass tick values as a string into Number function
      },
      padding: 8
    }
  },
  scales: {
    x: {
      offset: true,
      tickLength : 300,
      ticks: {
        color: "rgba(72, 100, 132, 1)",

        font: {
          family: "Nunito Sans",
          size: 10,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
    y: {
      ticks: {
        callback: function (value, index, values) {
          return Number((value).toString()); //pass tick values as a string into Number function
        },
        color: "rgba(72, 100, 132, 1)",
        font: {
          family: "Nunito Sans",
          size: 12,
          weight: 600,
        },
        padding: 20,
      },
      grid: {
        borderDash: [5, 5],
        lineWidth: 2,
        borderColor: "#979797",
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: false,
        dash: true,
        color: function(context) {
          if (context.tick.value === 140) {
            return "#e4e4e4";
          } else {
            return "transparent";
          }
        },
      },
    },
  },
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const revenueGrowthTimePeriodOptions = [
  {name : "Last 12 Months",value : 12},
  {name : "Last 9 Months",value : 9},
  {name : "Last 6 Months",value : 6},
  {name : "Last 3 Months",value : 3},
];

export class FirmPerformanceRevenue extends FirmPerformanceRevenueController {
  constructor(props: Props) {
    super(props);
  }
  
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");


    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
          this.getOnBoardedClientData(12);
          this.getRevenueGrowth(this.state.revenueGrowthTimePeriod)
      }
    );
  }

  openChartDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          lineChartData: this.state.lastQuaterRevenueLineData,
          lineChartOptions: revenueChartoptions,
        },
        width: "80%",
        renderedComponent: RevenueGrowthChartDialog,
        withCustomDialog: false,
      })
  };

  openCustomerChartDialog = (data)=>{
    this.props
      .openDialogBox({
        dataToPass: {
          lineChartData: this.state.customerGrowthLineData,
          lineChartOptions: customerGrowthChartOptions,
        },
        width: "80%",
        renderedComponent: CustomerGrowthChartDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.props.showToast({
            type: "success",
            message: "Image updated succssfully",
          });
        }
      });
  }

  getAverageRevenue = () => {
    const revenueMappings = {
      combined: 'combineRevenue',
      business: 'businessRevenue',
      individual: 'individualRevenue',
    };

    const selectedRevenueProperty = revenueMappings[this.state.averageRevenueSelected];

    return this.state[selectedRevenueProperty];
  }

  setRevenueState = (stateName, stateValue) => {
    this.setState({
      [stateName]: stateValue
    })
  }

  render() {
    const { classes, theme } = this.props;
    const yearlyRevenue = calculateTernary({
      condition: this.state.selectedRevenueYear === 'thisYear',
      trueValue: this.state.revenueThisYear,
      falseValue: this.state.revenueLastYear
    });
    const monthlyRevenue = calculateTernary({
      condition: this.state.selectedRevenueMonth === 'thisMonth',
      trueValue: this.state.revenueThisMonth,
      falseValue: this.state.revenueLastMonth
    });
    let averageRevenue = this.getAverageRevenue();
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          style={{
            position: "relative",
          }}
        >
          <Grid
            item
            xs={11}
            className={[classes.gridSpacing, classes.sectionHeadings]}
          >
            <Typography
              variant="h2"
              style={{
                color: theme.palette.text.blue2,
              }}
              id="page-heading"
            >
              Revenue
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            className={[classes.gridSpacing, classes.whiteCard]}
          >
            <Grid container justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={4}
                container
                className={[
                  classes.gridSpacing,
                  classes.blueCard,
                  classes.threeCards,
                ]}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={classes.cardHeadingWhite}
                  >
                    Annual Revenue
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "selectedRevenueYear", "thisYear")}
                    className={calculateTernary({
                      condition: this.state.selectedRevenueYear === 'thisYear',
                      trueValue: classes.cardOptionsWhiteLargeSelected,
                      falseValue: classes.cardOptionsWhiteLarge
                    })}
                  >
                    This Year 
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "selectedRevenueYear", "lastYear")}
                    className={calculateTernary({
                      condition: this.state.selectedRevenueYear === 'lastYear',
                      trueValue: classes.cardOptionsWhiteLargeSelected,
                      falseValue: classes.cardOptionsWhiteLarge
                    })}
                  >
                    | Last Year
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  className={classes.cardOptionsPriceContainer}
                >
                  <img
                    src={calculateTernary({
                      condition: yearlyRevenue >= 0,
                      trueValue: priceUp,
                      falseValue: priceDown
                    })}
                    className={classes.priceUpDownIconStyle}
                    alt="priceUp"
                  />
                  <Typography
                    display="inline"
                    className={classes.cardOptionsPrice}
                  >
                    {yearlyRevenue}%
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                container
                alignItems="center"
                className={[
                  classes.gridSpacing,
                  classes.greenCard,
                  classes.threeCards,
                ]}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={classes.cardHeadingWhite}
                  >
                    Average Revenue per Client
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "averageRevenueSelected", "combined")}
                    className={calculateTernary({
                      condition: this.state.averageRevenueSelected === 'combined',
                      trueValue: classes.cardOptionsWhiteSelected,
                      falseValue: classes.cardOptionsWhite
                    })}
                  >
                    Combined
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "averageRevenueSelected", "business")}
                    className={calculateTernary({
                      condition: this.state.averageRevenueSelected === 'business',
                      trueValue: classes.cardOptionsWhiteSelected,
                      falseValue: classes.cardOptionsWhite
                    })}
                  >
                    Business
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "averageRevenueSelected", "individual")}
                    className={calculateTernary({
                      condition: this.state.averageRevenueSelected === 'individual',
                      trueValue: classes.cardOptionsWhiteSelected,
                      falseValue: classes.cardOptionsWhite
                    })}
                  >
                    Individual
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  className={classes.cardOptionsPriceContainer}
                >
                  <img
                    src={calculateTernary({
                      condition: averageRevenue >= 0,
                      trueValue: priceUp,
                      falseValue: priceDown
                    })}
                    className={classes.priceUpDownIconStyle}
                    alt="priceUp"
                  />
                  <Typography
                    display="inline"
                    className={classes.cardOptionsPrice}
                  >
                    $ {averageRevenue}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                container
                alignItems="center"
                className={[
                  classes.gridSpacing,
                  classes.blueCard,
                  classes.threeCards,
                ]}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={classes.cardHeadingWhite}
                  >
                    Monthly Revenue
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "selectedRevenueMonth", "thisMonth")}
                    className={calculateTernary({
                      condition: this.state.selectedRevenueMonth === 'thisMonth',
                      trueValue: classes.cardOptionsWhiteLargeSelected,
                      falseValue: classes.cardOptionsWhiteLarge
                    })}
                  >
                    This Month 
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={this.setRevenueState.bind(this, "selectedRevenueMonth", "lastMonth")}
                    className={calculateTernary({
                      condition: this.state.selectedRevenueMonth === 'lastMonth',
                      trueValue: classes.cardOptionsWhiteLargeSelected,
                      falseValue: classes.cardOptionsWhiteLarge
                    })}
                  >
                    | Last Month
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  className={classes.cardOptionsPriceContainer}
                >
                  <img
                    src={calculateTernary({
                      condition: monthlyRevenue >= 0,
                      trueValue: priceUp,
                      falseValue: priceDown
                    })}
                    className={classes.priceUpDownIconStyle}
                    alt="priceUp"
                  />
                  <Typography
                    display="inline"
                    className={classes.cardOptionsPrice}
                  >
                    {monthlyRevenue}%
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={3}
                container
                justifyContent="space-between"
                alignItems="center"
                direction="column"
                className={[classes.whiteCard]}
              >
                <Typography
                  color="primary"
                  align="center"
                  className={classes.primaryText}
                >
                  $ {this.state.monthlyRecurring}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h4"
                  gutterBottom
                  align="center"
                  className={classes.secondaryText}
                >
                  Monthly Recurring
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                container
                justifyContent="space-between"
                alignItems="center"
                direction="column"
                className={[classes.whiteCard]}
              >
                <Typography
                  color="primary"
                  align="center"
                  className={classes.primaryTextBlue}
                >
                  $ {this.state.monthlyOneTime}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h4"
                  gutterBottom
                  align="center"
                  className={classes.secondaryText}
                >
                  Monthly One Time Fees
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
                container
                className={[classes.whiteCard, classes.revenueGoalCard]}
              >
                <Grid item xs={12}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.cardHeading}
                  >
                    Revenue Goal
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.revenueGoalPrice}
                  >
                    $0
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.revenueGoalPrice}
                    align="right"
                  >
                    $ {this.state.current_month_revenue}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.revenueGoalPrice}
                    align="right"
                  >
                    $ {this.state.overall_revenue}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <BorderLinearProgress variant="determinate" value={0} />
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.revenueGoalPriceText}
                    align="right"
                  >
                    Current Revenue
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.revenueGoalPriceText}
                    align="right"
                  >
                    Company Revenue <br /> Goal
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={[classes.sectionHeadings, classes.gridSpacing]}
                container
                justifyContent="space-between"
              >
                <Typography
                  variant="h2"
                  style={{
                    color: theme.palette.text.blue2,
                  }}
                >
                  Revenue Growth
                </Typography>
                <MediumBlueField
                  onChange={(e) => {
                    this.setRevenueState.bind(this, "revenueGrowthTimePeriod", e.target.value);
                    this.getRevenueGrowth(e.target.value);
                  }}
                  select
                  value={this.state.revenueGrowthTimePeriod}
                  style={{
                    width: "12%",
                    marginRight: "2rem",
                  }}
                >
                  {revenueGrowthTimePeriodOptions.map((opt) => {
                    return (
                      <MenuItem key={opt} value={opt?.value}>
                        {opt?.name}
                      </MenuItem>
                    );
                  })}
                </MediumBlueField>
              </Grid>
              <Grid
                item
                xs={9}
                className={[classes.gridSpacing]}
                style={{
                  paddingLeft: theme.spacing(6),
                }}
              >
                <Box
                  style={{
                    height: "25rem",
                  }}
                >
                  <Line
                    ref={this.revenueGrowthChart}
                    plugins={[ChartDataLabels]}
                    data={this.state.revenueGrowthLineData}
                    options={revenueGrowthChartoptions}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="flex-end"
                >
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box className={classes.pieChartGreenBox} />
                    <Typography
                      display="inline"
                      color="textSecondary"
                      className={classes.pieChartText}
                    >
                      Recurring Payment (0%)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box className={classes.pieChartBlueBox} />
                    <Typography
                      display="inline"
                      color="textSecondary"
                       style={{paddingRight : '0.8rem'}}
                      className={classes.pieChartText}
                    >
                      One Time Payment (100%)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
                container
                className={[classes.whiteCard, classes.twoEqualCards]}
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.cardHeading}
                  >
                    Revenue (Last Quarter)
                  </Typography>
                  <img
                    src={zoomIcon}
                    alt="zoomIcon"
                    onClick={this.openChartDialog}
                    className={classes.zoomIconStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      height: "18rem",
                    }}
                  >
                    <Line plugins={[ChartDataLabels]} data={this.state.lastQuaterRevenueLineData} options={revenueChartoptions} />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
                container
                className={[classes.whiteCard, classes.twoEqualCards]}
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    gutterBottom
                    className={classes.cardHeading}
                  >
                    Customer Growth (Last Quarter)
                  </Typography>
                  <img
                    src={zoomIcon}
                    alt="zoomIcon"
                    onClick={this.openCustomerChartDialog}
                    className={classes.zoomIconStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      height: "18rem",
                    }}
                  >
                    <Line
                      data={this.state.customerGrowthLineData}
                      options={customerGrowthChartOptions}
                      plugins={[ChartDataLabels]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const FirmPerformanceRevenueWithRouter = withRouter(FirmPerformanceRevenue);
const FirmPerformanceRevenueAlertBox = withAlertBox(
  FirmPerformanceRevenueWithRouter
);
const FirmPerformanceRevenueLoader = withLoader(FirmPerformanceRevenueAlertBox);
const FirmPerformanceRevenueToast = withToast(FirmPerformanceRevenueLoader);
const FirmPerformanceRevenueWithDialog = withDialog(
  FirmPerformanceRevenueToast
);

export const firmPerformanceStyles = (theme) => {
  return {
    gridSpacing: {
      margin: "1rem 0",
      [theme.breakpoints.down("md")]: {
        margin: "0.5rem 0",
      },
    },
    whiteCard: {
      padding: "1rem",
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
      [theme.breakpoints.down("md")]: {
        padding: "0.75rem",
      },
    },
    greenCard: {
      padding: "1rem",
      borderRadius: "1rem",
      backgroundColor: "#9CBE6A",
    },
    blueCard: {
      padding: "1rem",
      borderRadius: "1rem",
      backgroundColor: "#476484",
    },
    threeCards: {
      flexBasis: "32%",
      maxWidth: "32%",
    },
    twoEqualCards: {
      flexBasis: "48%",
      maxWidth: "48%",
    },
    cardHeading: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      letterSpacing: 1,
    },
    cardHeadingWhite: {
      fontSize: "1.2rem",
      color: "#f1f1f1",
      fontWeight: "bold",
      letterSpacing: 1,
    },
    cardOptionsWhiteSelected: {
      display: "inline-block",
      fontSize: "1rem",
      color: "#f1f1f1",
      fontWeight: "bold",
      letterSpacing: 1,
      borderBottom: "2px solid #f1f1f1",
      marginRight: "0.5rem",
      marginTop: "0.75rem",
      paddingBottom: "0.25rem",
      cursor : 'pointer'
    },
    cardOptionsWhiteLarge: {
      display: "inline-block",
      fontSize: "1rem",
      color: "#f1f1f1",
      fontWeight: "bold",
      letterSpacing: 1,
      marginRight: "0.5rem",
      marginTop: "0.75rem",
      paddingBottom: "0.25rem",
      cursor : 'pointer',
    },
    cardOptionsWhiteLargeSelected: {
      display: "inline-block",
      fontSize: "1rem",
      color: "#f1f1f1",
      fontWeight: "bold",
      letterSpacing: 1,
      marginRight: "0.5rem",
      marginTop: "0.75rem",
      paddingBottom: "0.25rem",
      cursor : 'pointer',
      borderBottom: "2px solid #f1f1f1",
    },
    cardOptionsPriceContainer: {
      margin: theme.spacing(3, 0, 1, 0),
    },
    cardOptionsPrice: {
      fontSize: "2rem",
      color: "#f1f1f1",
      fontWeight: "bold",
      letterSpacing: 1,
    },
    priceUpDownIconStyle: {
      height: "1.875rem",
      width: "1.875rem",
      marginRight: "0.625rem",
    },
    cardOptionsWhite: {
      display: "inline-block",
      fontSize: "0.8rem",
      color: "#f1f1f1",
      fontWeight: "bold",
      letterSpacing: 1,
      marginRight: "0.5rem",
      marginTop: "0.75rem",
      cursor: "pointer",
    },
    clientListCard: {
      maxHeight: "20rem",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "2px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F6",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BBBEC6",
        borderRadius: "0.5rem",
      },
    },
    clientListCardWithoutPadding: {
      maxHeight: "45vh",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F6",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BBBEC6",
        borderRadius: "0.5rem",
      },
    },
    cardNumberLarge: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    clientRowHeading: {
      margin: theme.spacing(1.5, 0),
      padding: theme.spacing(1, 0),
      borderRadius: "1rem",
      backgroundColor: "#f3f3f3",
    },
    clientListColorBar: {
      position: "absolute",
      left: 0,
      top: "30%",
      width: "4px",
      height: "40%",
    },
    clientRowLeft: {
      margin: theme.spacing(1.5, 0),
      padding: theme.spacing(2.5, 0),
      borderRadius: "1rem",
      backgroundColor: "#fff",
      boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
      cursor: "pointer",
    },
    individualTypography: {
      display: "inline-block",
      backgroundColor: "#fff",
      border: `1px solid ${theme.palette.text.blue2}`,
      borderRadius: "20% / 50%",
      marginLeft: "0.5rem",
      padding: theme.spacing(0.5, 1),
      fontSize: "0.8rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      [theme.breakpoints.down("md")]: {
        fontSize: "0.9rem",
      }
    },
    clientName: {
      fontSize: "1.3rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    },
    primaryText: {
      fontSize: "3rem",
      fontWeight: 700,
      paddingBottom: "1rem",
    },
    primaryTextBlue: {
      fontSize: "3rem",
      fontWeight: 700,
      paddingBottom: "1rem",
      color: theme.palette.text.blue2,
    },
    secondaryText: {
      fontSize: "1.2rem",
      fontWeight: 700,
    },
    packagesEnrolledByUser: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    },
    leftSectionContainer: {
      overflowY: "auto",
      maxHeight: "100vh",
      "&::-webkit-scrollbar": { display: "none" },
      scrollbarWidth: "none",
    },
    rightSectionContainer: {
      borderRadius: "1rem",
      padding: "40px 20px 20px 20px",
    },
    rightSectionInnerContainer: {
      borderRadius: "1rem",
      padding: "0.75rem",
    },
    verticalBarContainer: {
      flexGrow: 0,
      flexBasis: "12.50%",
      maxWidth: "12.50%",
    },
    stepsBarTitle: {
      fontSize: "0.8rem",
    },
    todoItemGrid: {
      backgroundColor: "#f8f8f8",
      padding: "0.5rem",
      margin: "8px 1%",
      borderRadius: "1rem",
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
    },
    revenueGoalCard: {
      minHeight: "10.625rem",
    },
    revenueGoalPrice: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      letterSpacing: 1,
      color: theme.palette.text.blue2,
    },
    revenueGoalPriceText: {
      fontSize: "0.8rem",
      fontWeight: "bold",
      letterSpacing: 1,
      color: theme.palette.text.secondary,
    },
    zoomIconStyle: {
      height: "1.875rem",
      width: "1.875rem",
      cursor: "pointer",
    },
    pieChartBlueBox: {
      height: "1.25rem",
      width: "1.25rem",
      borderRadius: "0.3125rem",
      backgroundColor: theme.palette.text.blue2,
      display: "inline-block",
      marginRight: "10px",
      marginBottom: "0.5rem",
    },
    pieChartGreenBox: {
      height: "1.25rem",
      width: "1.25rem",
      borderRadius: "0.3125rem",
      backgroundColor: theme.palette.success.main,
      display: "inline-block",
      marginRight: "10px",
      marginBottom: "0.5rem",
    },
    pieChartText: {
      color: theme.palette.text.secondary,
      fontSize: "0.9rem",
      paddingRight: "2rem",
      marginBottom: "0.5rem",
    },
    textCard: {
      padding: "0.5rem 1rem",
      borderRadius: "0.75rem",
      backgroundColor: "#ffffff",
      boxShadow: theme.shadows[25],
    },
    sectionHeadings: {
      paddingLeft: "1rem",
    },
    clientRow: {
      margin: "0.25rem 0",
      padding: "0.5rem 0",
      backgroundColor: "#fff",
      position: "relative",
      borderRadius: "0.75rem",
      fontFamily: "Nunito Sans",
      boxShadow: theme.shadows[25],
    },
    onboardingTableHeading: {
      color: theme.palette.text.blue2,
      fontWeight: 700,
      fontSize: "1.3158rem",
      paddingLeft: "1.75rem",
    },
    surveyCardsContainer: {
      padding: "1.5rem 3rem",
    },
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      ...firmPerformanceStyles(theme),
    }),
  { withTheme: true }
)(withConfirmBox(FirmPerformanceRevenueWithDialog));
