//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");
import { themeProps } from "../../../../web/src/theme2";
import packageList from "../../../../components/src/PackageList.web";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  token: any;
  activeStep: number;
  taxPayerType: string;
  token: null | string;
  activeStep: number;
  taxPayerType: "business" | "individual";
  currentAboutUsStep: number;
  stepContent: any;
  step1Selection: null | {};
  step2Selection: null | {};
  step3Selection: {}[];
  totalAnnualIncome: string;
  taxFillingStatus: string;
  isRealStateBusinessOutSideResidense: boolean;
  isTradeOnMonthlyBasis: boolean;
  isTradeCrypto: boolean;
  isOwnerShipInAnotherBusiness: boolean;
  isIncomeInAnotherStateOrCountary: boolean;
  isActiveIssueWithIRS: boolean;
  isAllIncomeinOneState: boolean;
  noOfStateIncomeEarnIn: number;
  no_of_countries_earn: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  currentAccountingSystem: string;
  currentEntityStructure: string;
  currentPayRollSystem: string;
  industryType: string;
  annualRevenue: string;
  otherBusinessPartner: number;
  noOfEmployee: string;
  isManagePayroll: boolean;
  isHelpNeededinAccounting: boolean;
  noOFBankAccount: number;
  isManageInvoice: boolean;
  noOFInvoice: number;
  isBalanceSheetAccurate: boolean;
  noOfmonthForBalanceSheet: 1;
  aboutUsCashflowSelection: [];
  cashflowSelection: [];
  taxSavingSelection: [];
  reportSelection: [];
  businessNeedSelection: [];
  savingSelection: [];
  reportSelection: [];
  steps: [];
  currentSummaryStep: number;
  selectedPackage: null | {};
  isLoaderStep: boolean;
  packageList: {}[];
  paymentType: "creditCard" | "eCheck";
  survey_id: number | null;
  cpaData: null | any;
  paymentAccessToken: string;
  isPaymentEnable: boolean;
  aboutUsEnable: boolean;
  self_enrollment: boolean;
  package_self_enrollment: {
    name: string;
    key: string;
    isAvailableForEnrollment: boolean;
  }[];
  isEcheckPaymentAvailable: boolean;
  isPaymentSuccess: boolean;
  theme: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
export const annual_packages = [
  "New Business Incubator",
  "IRS Rapid Relief Package",
  "Reporting-Only Package (Individual)",
  "Reporting-Only Package (Business)",
];
export const monthly_packages = [
  "Business Bundle",
  "Business Tax & Advisory",
  "Accounting Success Package",
  "Payroll Success Package",
  "Personal Bundle",
];

export default class SurveySteperController extends BlockComponent<
  Props,
  S,
  SS
> {
  getQuestionAndSOptionsByStepNoAPiCallId: string;
  saveSurveyApiCallId: string;
  collectPaymentApiCallId: string;
  sendEmailTemplateApiCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: null,
      activeStep: 0,
      taxPayerType: "individual",
      currentAboutUsStep: 0,
      stepContent: null,
      step1Selection: null,
      step2Selection: null,
      step3Selection: [],
      totalAnnualIncome: "",
      taxFillingStatus: "Single",
      isRealStateBusinessOutSideResidense: "",
      isTradeOnMonthlyBasis: "",
      isTradeCrypto: "",
      isOwnerShipInAnotherBusiness: "",
      isIncomeInAnotherStateOrCountary: "",
      isActiveIssueWithIRS: "",
      isAllIncomeinOneState: "",
      noOfStateIncomeEarnIn: 0,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentAccountingSystem: "None",
      currentEntityStructure: "S-Corp",
      currentPayRollSystem: "",
      industryType: "",
      annualRevenue: "",
      otherBusinessPartner: 0,
      noOfEmployee: "",
      isManagePayroll: "",
      isHelpNeededinAccounting: "",
      noOFBankAccount: 0,
      isManageInvoice: "",
      noOFInvoice: 0,
      isBalanceSheetAccurate: "",
      noOfmonthForBalanceSheet: 1,
      aboutUsCashflowSelection: [],
      cashflowSelection: [],
      taxSavingSelection: [],
      reportSelection: [],
      businessNeedSelection: [],
      savingSelection: [],
      steps: [
        "About You",
        "Cash Flow",
        "Tax Savings",
        "Business Needs",
        "Savings",
        "Reporting",
        "Summary",
      ],
      currentSummaryStep: 0,
      selectedPackage: null,
      isLoaderStep: false,
      packageList: [],
      paymentType: "creditCard",
      survey_id: null,
      cpaData: {},
      paymentAccessToken: "",
      isPaymentEnable: false,
      aboutUsEnable: true,
      surveyDetail: {},
      self_enrollment: true,
      package_self_enrollment: packageList,
      isEcheckPaymentAvailable: false,
      isPaymentSuccess: false,
      theme: themeProps,
      no_of_countries_earn: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleApiError = (responseJson) => {
    if (responseJson && typeof responseJson.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handlePaymentError = (responseJson) => {
    if (responseJson && typeof responseJson.errors === "string") {
      this.props.showToast({
        type: "error",
        message:
          responseJson.errors === "ActiveRecord::Rollback"
            ? "Transaction Declined"
            : responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  sortQuestion = (a, b) => {
    let fa = a.attributes.order_no,
      fb = b.attributes.order_no;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };
  handleStep1Content = (stepContent, options) => {
    if (this.state.currentAboutUsStep === 0) {
      stepContent.description =
        "To make sure we're asking the right questions, please verify your current situation";
      options = options.map((option) => {
        if (
          option.attributes.description ===
          "I am an existing client of {Practice}"
        ) {
          const tempOpt = { ...option };
          tempOpt.attributes.displaydescription = `I am an existing client of ${this.state.cpaData.firm_name}`;
          return tempOpt;
        } else {
          return option;
        }
      });
    }
    return options;
  };
  trimOptionContent = (option) => {
    let tempOpt = { ...option };
    tempOpt = {
      ...tempOpt,
      attributes: {
        ...tempOpt.attributes,
        description: option.attributes.description.trim(),
      },
    };
    if (
      tempOpt.attributes.description.charAt(
        tempOpt.attributes.description.length - 1
      ) !== "."
    ) {
      tempOpt.attributes.displaydescription = tempOpt.attributes
        .displaydescription
        ? tempOpt.attributes.displaydescription + `.`
        : tempOpt.attributes.description + `.`;
      return tempOpt;
    } else {
      tempOpt.attributes.displaydescription = tempOpt.attributes
        .displaydescription
        ? tempOpt.attributes.displaydescription
        : tempOpt.attributes.description;
      return tempOpt;
    }
  };
  rr = (element) => {
    if (
      element.attributes.description.indexOf("trouble(in my business).") > -1
    ) {
      element.attributes.description = element.attributes.description.replace(
        "trouble(in my business).",
        "trouble (in my business)."
      );
    }
  }; 
  handleQuestionListResponse = (responseJson) => {
    if (responseJson.data.length > 0) {
      let stepContent = responseJson.data[0].attributes;
      let options = stepContent.options.data.sort(this.sortQuestion);
      options = this.handleStep1Content(stepContent, options);
      options = options.map(this.trimOptionContent);
      options.forEach(this.rr);
      stepContent.options = { data: options };
      console.log(stepContent,"stepContent")
      this.setState(
        {
          stepContent: stepContent,
        },

        () => {
          if (
            ["business", "individual"].indexOf(stepContent.question_type) > -1
          ) {
            this.setTaxPerType(stepContent.question_type);
          }
        }
      );
    }
  };
  handleQuestionList = (responseJson) => {
    this.props.hideLoader();
    if (responseJson) {
      if (responseJson.data) {
        this.handleQuestionListResponse(responseJson);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };
  handleSaveSurveyResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson) {
      if (responseJson.data) {
        if (responseJson.data.id) {
          this.setState(
            {
              isLoaderStep: false,
              survey_id: responseJson.data.id,
              surveyDetail: responseJson.data.attributes,
              clientId: responseJson.data.attributes.client_info_s?.id,
            },
            () => {
              this.setState(
                {
                  packageList: responseJson.data.attributes.packages,
                },
                () => {
                  this.handleNext();
                  if (this.state.type != "generalCFS")
                    this.updateSurveyLog("pending", this.state.survey_id);
                }
              );
            }
          );
        }
      } else {
        this.handleApiError(responseJson);
      }
    }
  };
  handleRecurringPayment = (responseJson) => {
    if (responseJson?.data?.attributes?.recurring_charge?.schedule_id) {
      this.props.showToast({
        type: "success",
        message: "payment scheduled successfully",
      });
      if (this.state.type != "generalCFS")
        this.updateSurveyLog("submitted", this.state.survey_id);

      return true;
    }
    return false;
  };
  handlePaymentSuccess = (responseJson) => {
    this.props.hideLoader();
    if (!this.handleRecurringPayment(responseJson)) {
      if (responseJson?.data?.attributes?.transaction_id) {
        this.props.showToast({
          type: "success",
          message: "payment completed successfully",
        });
        if (this.state.type != "generalCFS")
          this.updateSurveyLog("submitted", this.state.survey_id);
       this.createChecklistforSelectedPackage()
      } else {
        this.handlePaymentError(responseJson);
      }
    } else {
      this.createChecklistforSelectedPackage()
    }
  };
  handleEmailTemplate = (responseJson) => {
    this.props.hideLoader();
    if (responseJson.message) {
      this.props.showToast({
        type: "success",
        message: "Email sent successfully",
      });
    } else {
      this.handleApiError(responseJson);
    }
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getQuestionAndSOptionsByStepNoAPiCallId) {
        this.handleQuestionList(responseJson);
      } else if (this.saveSurveyApiCallId === apiRequestCallId) {
        this.handleSaveSurveyResponse(responseJson);
      } else if (this.collectPaymentApiCallId === apiRequestCallId) {
        this.handlePaymentSuccess(responseJson);
      } else if (this.sendEmailTemplateApiCallId === apiRequestCallId) {
        this.handleEmailTemplate(responseJson);
      } else if (this.updateSurveyLogCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson?.id) {
          console.log(responseJson?.id);
        } else {
          this.handleApiError(responseJson);
        }
      } else if (
        this.createChecklistforSelectedPackageApiCallId == apiRequestCallId
      ) {
        this.handleCheckListResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
    }
  }
  handleCheckListResponse = (responseJson) => {
    this.props.hideLoader();
    this.props.history.replace({
      pathname: "/onboardingSurveyLandingPage",
      search: `?survey_id=${this.state.survey_id}&package_management_id=${this.state.selectedPackage.id}&client_id=${this.state.clientId}&account_id=${this.state.accountId}&selected_package_id=${this.state.selectedPackage.id}&allow_to_change_package_id=false&roadmap_id=&payment_term=&custom_package_id=&parent_package_type=`,
    });
  };
  setCurrentSummaryStep = (step) => {
    this.setState({
      currentSummaryStep: step,
    });
  };
  handleNext = () => {
    this.setState(
      {
        activeStep: this.state.activeStep + 1,
        openFaq: false,
      },
      () => {
        this.rootRef.current?.scrollTo(0, 0);
      }
    );
  };
  setTaxPerType = (taxPayerType) => {
    this.setState(
      {
        taxPayerType: taxPayerType,
      },
      () => {
        if (
          this.state.stepNo == "8.2" &&
          this.state.taxPayerType === "business"
        ) {
          if (
            this.state.step2Selection?.attributes?.description ===
            "I want to start a business"
          ) {
            const businessSetting = this.state.stepContent?.options?.data.find(
              (step) => {
                return (
                  step?.attributes?.description ===
                  "I want to start a new/side business the right way so I can increase my earning potential."
                );
              }
            );
            this.setState(
              {
                businessNeedSelection: [
                  ...this.state.businessNeedSelection,
                  businessSetting,
                ],
              },
              () => {
                this.setManagePayroll(!!this.state.isManagePayroll);
              }
            );
          } else {
            this.setManagePayroll(!!this.state.isManagePayroll);
          }
        }
        if (
          this.state.stepNo == "9.2" &&
          this.state.taxPayerType === "business"
        ) {
          this.setManagePayroll(!!this.state.isManagePayroll);
        }
      }
    );
  };
  setManagePayrollForStep8 = (isChecked) => {
    if (this.state.stepNo == "8.2" && this.state.taxPayerType === "business") {
      const businessSetting = this.state.stepContent?.options?.data.find(
        (step) => {
          return (
            step?.attributes?.description ===
            "I want to automate & improve my payroll so I can spend less time doing paperwork or worrying about HR."
          );
        }
      );
      if (isChecked) {
        this.setState(
          {
            businessNeedSelection: [
              ...this.state.businessNeedSelection,
              businessSetting,
            ],
          },
          () => {
            this.setManageAccounting(
              this.isTrue(this.state.isHelpNeededinAccounting),
              this.isTrue(this.state.isManageInvoice)
            );
          }
        );
      } else {
        this.setManageAccounting(
          this.isTrue(this.state.isHelpNeededinAccounting),
          this.isTrue(this.state.isManageInvoice)
        );
      }
    }
  };
  isTrue = (value) => {
    return value === "true";
  };
  setManagePayroll = (isChecked) => {
    this.setManagePayrollForStep8(isChecked);
    if (this.state.stepNo == "9.2" && this.state.taxPayerType === "business") {
      const reportSelection = this.state.stepContent?.options?.data.find(
        (step) => {
          return (
            step?.attributes?.description ===
            "Help Automating & Streamlining Payroll for my business"
          );
        }
      );
      if (isChecked) {
        this.setState(
          {
            reportSelection: [...this.state.reportSelection, reportSelection],
          },
          () => {
            this.setManageAccounting(
              this.isTrue(this.state.isHelpNeededinAccounting),
              this.isTrue(this.state.isManageInvoice)
            );
          }
        );
      } else {
        this.setManageAccounting(
          this.isTrue(this.state.isHelpNeededinAccounting),
          this.isTrue(this.state.isManageInvoice)
        );
      }
    }
  };
  setManageAccounting = (isChecked, isManageInvoice) => {
    if (this.state.stepNo == "8.2" && this.state.taxPayerType === "business") {
      const businessSetting = this.state.stepContent?.options?.data.find(
        (step) => {
          return (
            step?.attributes?.description ===
            "I want to automate & improve my accounting so I can spend more time growing my business."
          );
        }
      );
      if (isChecked || isManageInvoice) {
        this.setState({
          businessNeedSelection: [
            ...this.state.businessNeedSelection,
            businessSetting,
          ],
        });
      }
    }
    if (this.state.stepNo == "9.2" && this.state.taxPayerType === "business") {
      const reportSelection = this.state.stepContent?.options?.data.find(
        (step) => {
          return (
            step?.attributes?.description ===
            "Help Automating & Streamlining Accounting for my business"
          );
        }
      );
      if (isChecked || isManageInvoice) {
        this.setState({
          reportSelection: [...this.state.reportSelection, reportSelection],
        });
      }
    }
  };
  getQuestionAndOptionsByStepNo = (stepNo: any) => {
    this.setState(
      {
        stepNo: stepNo,
      },
      () => {
        this.props.showLoader();
        const headers = {
          "content-type": configJSON.validationApiContentType,
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getQuestionAndSOptionsByStepNoAPiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_survey/questions?questions[title_step]=${stepNo}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    );
  };
  getStepno3byStep1AndStep2 = () => {
    const ALERADY_EXISTING_CLIENT = "I am an existing client of {Practice}";
    const WORKING_WITH_ANOTHER_ACCOUNTANT =
      "I am currently working with another tax professional/accountant";
    const WORKING_WITH_OWN = "I handle my own tax and accounting work";

    if (this.state.step1Selection && this.state.step2Selection) {
      const step1 = this.state.step1Selection?.attributes?.description;
      const step2 = this.state.step2Selection?.attributes?.description;
      let newStep = 0;
      switch (step1) {
        case ALERADY_EXISTING_CLIENT:
          switch (step2) {
            case "I want to start a business":
            case "I earn some income from my employer and have some business income on the side.":
            case "I am self-employed":
              newStep = 1.6;
              break;
            case "All of my income is earned through my employer":
            case "I'm retired/ Social Security":
            case "Passive income (Investments, real estate,etc.)":
              newStep = 1.3;
              break;
          }
          break;
        case WORKING_WITH_ANOTHER_ACCOUNTANT:
          switch (step2) {
            case "I want to start a business":
            case "I am self-employed":
            case "I earn some income from my employer and have some business income on the side.":
              newStep = 1.4;
              break;
            case "All of my income is earned through my employer":
            case "Passive income (Investments, real estate,etc.)":
            case "I'm retired/ Social Security":
              newStep = 1.1;
              break;
          }
          break;
        case WORKING_WITH_OWN:
          switch (step2) {
            case "I want to start a business":
            case "I earn some income from my employer and have some business income on the side.":
            case "I am self-employed":
              newStep = 1.5;
              break;
            case "All of my income is earned through my employer":
            case "I'm retired/ Social Security":
            case "Passive income (Investments, real estate,etc.)":
              newStep = 1.2;
              break;
          }
          break;
      }
      return newStep;
    }
  };
  getCashFlowAttr = () => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: this.state.cashflowSelection.map((cashflow) => {
        return cashflow?.attributes.id;
      }),
      question_id:
        this.state.cashflowSelection.length > 0 &&
        this.state.cashflowSelection[0]?.attributes?.question_id,
      title_step: this.state.taxPayerType === "individual" ? "6.2" : "6.1",
    };
  };
  getTaxSavingAttr = () => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: this.state.taxSavingSelection.map((cashflow) => {
        return cashflow?.attributes.id;
      }),
      question_id:
        this.state.taxSavingSelection.length > 0 &&
        this.state.taxSavingSelection[0]?.attributes?.question_id,
      title_step: this.state.taxPayerType === "individual" ? "7.2" : "7.1",
    };
  };
  getCarrerNeedAttr = () => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: this.state.businessNeedSelection.map((cashflow) => {
        return cashflow?.attributes.id;
      }),
      question_id:
        this.state.businessNeedSelection.length > 0 &&
        this.state.businessNeedSelection[0]?.attributes?.question_id,
      title_step: this.state.taxPayerType === "individual" ? "8.1" : "8.2",
    };
  };
  getBusinessNeedAttr = () => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: this.state.businessNeedSelection.map((cashflow) => {
        return cashflow?.attributes.id;
      }),
      question_id:
        this.state.businessNeedSelection.length > 0 &&
        this.state.businessNeedSelection[0]?.attributes?.question_id,
      title_step: this.state.taxPayerType === "individual" ? "8.1" : "8.2",
    };
  };
  getSavingAttr = () => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: this.state.savingSelection.map((cashflow) => {
        return cashflow?.attributes.id;
      }),
      question_id:
        this.state.savingSelection.length > 0 &&
        this.state.savingSelection[0]?.attributes?.question_id,
      title_step: "8.2.1",
    };
  };
  getReportingAttr = () => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: this.state.reportSelection.map((cashflow) => {
        return cashflow?.attributes.id;
      }),
      question_id:
        this.state.reportSelection.length > 0 &&
        this.state.reportSelection[0]?.attributes?.question_id,
      title_step: this.state.taxPayerType === "individual" ? "9.1" : "9.2",
    };
  };
  getaboutusOtherAttr = (aboutUsAnswer) => {
    return {
      survey_type: this.state.taxPayerType === "individual" ? 1 : 0,
      answer: aboutUsAnswer,
      annual_house_hold_income:
        this.state.totalAnnualIncome?.replace(/,/g, "") || "",
      status: this.state.taxFillingStatus,
      own_real_estate:
        this.state.isRealStateBusinessOutSideResidense === "true" ? 1 : 0,
      trade_investments: this.state.isTradeOnMonthlyBasis === "true" ? 1 : 0,
      trade_cryptocurrence: this.state.isTradeCrypto === "true" ? 1 : 0,
      ownership_in_business:
        this.state.isOwnerShipInAnotherBusiness === "true" ? 1 : 0,
      income_in_multiple_state:
        this.state.isIncomeInAnotherStateOrCountary === "true" ? 1 : 0,
      active_issue_irs: this.state.isActiveIssueWithIRS === "true" ? 1 : 0,
      first_name: this.state.firstName || "",
      last_name: this.state.lastName || "",
      email: this.state.email || "",
      phone: this.state.phoneNumber || "",
      industry: this.state.industryType || "",
    };
  };
  getAboutUsAttr = (aboutUsAnswer) => {
    const otherAttr = this.getaboutusOtherAttr(aboutUsAnswer);
    return {
      ...otherAttr,
      annual_revenue: this.state.annualRevenue?.replace(/,/g, "") || "",
      current_entity_structure: this.state.currentEntityStructure || "",
      other_business_partners: this.state.otherBusinessPartner || "",
      current_account_system: this.state.currentAccountingSystem || "",
      number_of_employee: this.state.noOfEmployee || "",
      current_payroll: this.state.currentPayRollSystem || "",
      manage_my_payroll: this.state.isManagePayroll ? 1 : 0,
      setting_up_accounting:
        this.state.isHelpNeededinAccounting === "true" ? 1 : 0,
      no_of_credit_card: this.state.noOFBankAccount,
      sending_invoice: this.state.isManageInvoice === "true" ? 1 : 0,
      no_of_invoice: this.state.noOFInvoice,
      balance_sheet_accounting: this.state.isBalanceSheetAccurate ? 1 : 0,
      no_of_accounting: this.state.noOfmonthForBalanceSheet,
      earned_in_one_state: this.state.isAllIncomeinOneState === "true" ? 1 : 0,
      no_of_states_earn: this.state.noOfStateIncomeEarnIn,
      no_of_countries_earn: this.state.no_of_countries_earn,
    };
  };
  getAboutUsAllStepData = () => {
    const aboutUsAnswer = [];
    if (this.state.step1Selection) {
      aboutUsAnswer.push({
        question_id: this.state.step1Selection?.attributes?.question_id,
        answer_id: this.state.step1Selection?.attributes?.id,
        title_step: "1",
      });
    }
    if (this.state.step2Selection) {
      aboutUsAnswer.push({
        question_id: this.state.step2Selection?.attributes?.question_id,
        answer_id: this.state.step2Selection?.attributes?.id,
        title_step: "2",
      });
    }
    if (this.state.step3Selection.length > 0) {
      const step = this.getStepno3byStep1AndStep2();
      this.state.step3Selection.forEach((step3) => {
        aboutUsAnswer.push({
          question_id: step3?.attributes?.question_id,
          answer_id: step3?.attributes?.id,
          title_step: "" + step,
        });
      });
    }
    if (this.state.aboutUsCashflowSelection.length > 0) {
      this.state.aboutUsCashflowSelection.map((step3) => {
        aboutUsAnswer.push({
          question_id: step3?.attributes?.question_id,
          answer_id: step3?.attributes?.id,
          title_step: 5,
        });
      });
    }
    return aboutUsAnswer;
  };
  saveSurvey = () => {
    if (!this.state?.survey_id) {
      this.props.showLoader();
      this.setState(
        {
          isLoaderStep: true,
        },
        () => {
          const headers = {
            "content-type": configJSON.validationApiContentType,
            token: this.state.token,
          };
          const aboutUsAnswer = this.getAboutUsAllStepData();
          const data = {
            about_us_attributes: this.getAboutUsAttr(aboutUsAnswer),
            cash_flow_attributes: this.getCashFlowAttr(),
            tax_saving_attributes: this.getTaxSavingAttr(),
            career_need_attributes: this.getCarrerNeedAttr(),
            business_need_attributes: this.getBusinessNeedAttr(),
            saving_attributes: this.getSavingAttr(),
            reporting_attributes: this.getReportingAttr(),
            access_token: this.state.paymentAccessToken || undefined,
            account_id: this.state.accountId || undefined,
          };
          if (this.state.taxPayerType === "individual") {
            delete data.saving_attributes;
            delete data.business_need_attributes;
          } else {
            delete data.career_need_attributes;
          }
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );

          this.saveSurveyApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_survey/surveys`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
      );
    } else {
      this.props.showToast({
        type: "error",
        message: "Survey already created",
      });
      this.handleNext();
    }
  };
  collectPayment = (methodId) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      // token: lo,
    };
    const data =
      monthly_packages.indexOf(this.state.selectedPackage?.name) > -1
        ? {
            data: {
              attributes: {
                package_id: this.state.selectedPackage?.id,
                amount: Math.round(this.state.selectedPackage.price / 12),
                survey_id: this.state.survey_id,
                method: methodId,
                account_id: this.state.accountId,
                payment_term: "MONTH",
              },
            },
          }
        : {
            data: {
              attributes: {
                package_id: this.state.selectedPackage?.id,
                amount: Math.round(this.state.selectedPackage?.price),
                survey_id: this.state.survey_id,
                access_token: this.state.paymentAccessToken,
                method: methodId,
                account_id: this.state.accountId,
              },
            },
          };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.collectPaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      monthly_packages.indexOf(this.state.selectedPackage?.name) > -1
        ? `bx_block_survey/create_payment_for_cfs`
        : `bx_block_survey/payments`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sendEmailTemplate = (email, templatebase64) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
    };
    const data = {
      data: {
        attributes: {
          email: email,
          package_ids: this.state.packageList.map((pkg) => {
            return pkg.id;
          }),
          survey_id: this.state.survey_id,
          account_id: this.state.accountId,
          email_template: templatebase64,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendEmailTemplateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/surveys/email_packages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateSurveyLog = (state, survey_id) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const data = {
      access_token: "",
      account_id: this.state.accountId || "",
      type: this.state.type || undefined,
      state: state,
      survey_id: survey_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSurveyLogCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `survey_log/${this.state.surveyLogId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createChecklistforSelectedPackage = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChecklistforSelectedPackageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}/create_checklists`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        package_management_id: this.state.selectedPackage?.id,
        survey_id: this.state.survey_id,
        account_id: this.state.accountId,
        accountId: this.state.accountId,
        id: this.state.clientId,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
