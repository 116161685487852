//@ts-nocheck
import React from "react";
import CreateOnboardingChecklistController, { Props } from "./CreateOnboardingChecklistController.web";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  Checkbox,
  FormControlLabel,
  Collapse,
  MenuItem,
  ClickAwayListener,
  Divider,
} from "@material-ui/core";
import { moreIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InputField from "../../../../components/src/DarkFieldPhaseTwo.web";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import * as DOMPurify from "dompurify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SectionTooltip } from "../../../../components/src/ModifiedComponents.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import { Formik, Field, Form } from "formik";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DeleteIcon from "@material-ui/icons/Delete";
import { Close } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { spaceMask } from "../masks";
import StanderdTextFieldWeb from "./StanderdTextField.web";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import withPermissions from "../../../../components/src/withPermissions.web";
import TernaryCheck from "../TernaryCheck.web";

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    borderRadius: "12px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "8px",
    },
    backgroundColor: "#f8f8f8",
    padding: "0rem 1rem",
    "&.Mui-expanded": {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}))(AccordionSummary);

const Question = ({
  questionDraggableProvided,
  values,
  setFieldValue,
  classes,
  state,
  setState,
  moveQuestionToSection,
  updateChecklist,
  sectionItem,
  questionItem,
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.questionsContainer}
      {...questionDraggableProvided.draggableProps}
      {...questionDraggableProvided.dragHandleProps}
      ref={questionDraggableProvided.innerRef}
    >
      <Grid item xs={1}>
        <DragIndicatorIcon className={classes.questionDragIcon} />
      </Grid>
      <Grid item xs={8}>
        <Typography
          className={classes.questionNames}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(questionItem?.attributes?.question_name, {
              ADD_ATTR: ["target"],
            }),
          }}
        ></Typography>
      </Grid>
      <Grid item xs={3} container justifyContent="flex-end">
        <ClickAwayListener
          onClickAway={() => {
            let tooltipObj = {
              ...state.tooltipObj,
            };
            tooltipObj[`${sectionItem.id}-${questionItem.id}`] = false;
            setState({
              tooltipObj: tooltipObj,
            });
          }}
        >
          <div>
            <SectionTooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => {
                let tooltipObj = {
                  ...state.tooltipObj,
                };
                tooltipObj[`${sectionItem.id}-${questionItem.id}`] = false;
                setState({
                  tooltipObj: tooltipObj,
                });
              }}
              open={state.tooltipObj[`${sectionItem.id}-${questionItem.id}`]}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
              placement="bottom-start"
              interactive
              title={
                <Grid container justifyContent="space-between">
                  {values.checklist?.attributes?.sections?.data.length > 1 ? (
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <Typography color="primary" className={classes.tooltipOptionsTitle}>
                        Move To Section:{" "}
                      </Typography>
                      {state.isCollapseExpanded ? (
                        <ExpandLessIcon
                          onClick={() =>
                            setState({
                              isCollapseExpanded: false,
                            })
                          }
                        />
                      ) : (
                        <ExpandMoreIcon
                          onClick={() =>
                            setState({
                              isCollapseExpanded: true,
                            })
                          }
                        />
                      )}
                      <Collapse
                        in={state.isCollapseExpanded}
                        timeout="auto"
                        unmountOnExit
                        style={{
                          width: "100%",
                        }}
                      >
                        <Accordion
                          style={{
                            borderRadius: "12px",
                            boxShadow: "none",
                          }}
                          expanded={true}
                        >
                          <StyledAccordionSummary expandIcon={<ExpandMoreIcon size="small" />} aria-controls="panel1a-content" id="panel1a-header">
                            Select Section
                          </StyledAccordionSummary>
                          <AccordionDetails className={classes.tooltipAccordionDetails}>
                            <Grid container>
                              {values.checklist?.attributes?.sections?.data
                                .filter((item) => item.id !== sectionItem.id)
                                .map((mapInnerSection) => (
                                  <Grid item xs={12} key={mapInnerSection?.id}>
                                    <Typography
                                      style={{
                                        padding: "0.5rem",
                                        borderRadius: "0.5rem",
                                        textAlign: "left",
                                        cursor: "pointer",
                                        backgroundColor: values.move?.toSection?.id === mapInnerSection.id ? "#EAE8E9" : "",
                                      }}
                                      onClick={() => {
                                        setFieldValue("move", {
                                          fromSection: sectionItem,
                                          toSection: mapInnerSection,
                                          questionToMove: questionItem,
                                        });
                                      }}
                                    >
                                      {mapInnerSection?.attributes?.section_name}
                                    </Typography>
                                  </Grid>
                                ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Grid container justifyContent="center">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              moveQuestionToSection(values);
                            }}
                            disabled={!values?.move?.toSection || values?.move?.toSection?.id === sectionItem?.id}
                            style={{
                              width: "60%",
                            }}
                          >
                            Select
                          </Button>
                        </Grid>
                      </Collapse>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    style={{
                      minWidth: "9.375rem",
                    }}
                  >
                    <Grid container justifyContent="space-between">
                      <CustomTypography color="primary" className={classes.tooltipOptionsTitle}>
                        Remove
                      </CustomTypography>
                      <DeleteIcon
                        color="primary"
                        size="small"
                        onClick={() => {
                          updateChecklist(values.checklist, questionItem);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }
            >
              <img
                src={moreIcon}
                alt="moreIcon"
                className={classes.stylesMoreIcon}
                onClick={() => {
                  let tooltipObj = {
                    ...state.tooltipObj,
                  };
                  tooltipObj[`${sectionItem.id}-${questionItem.id}`] = true;
                  setState({
                    tooltipObj: tooltipObj,
                  });
                }}
              />
            </SectionTooltip>
          </div>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
};

const RightSection = ({ values, setFieldValue, classes, updateChecklist, showToast, handleChange, getIconType, permissions }) => {
  const showPossibleAnswersSection = values.newQuestion.questionType !== "textfield" && values.newQuestion.questionType !== "Select";
  const isQuestionButtonDisabled = (values) => {
    if (values.newQuestion.questionType === "textfield") {
      return values.newQuestion.questionName === "" || values.newQuestion.sectionName === "Select";
    } else {
      return values.newQuestion.questionName === "" || values.newQuestion.sectionName === "Select" || values.newQuestion.questionOptions.length <= 0;
    }
  };

  return (
    <Grid xs={8}>
      <Grid container>
        <Grid item xs={12} className={classes.rightSection}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.verticleMargin}>
                <Grid item xs={12}>
                  <Typography color="textPrimary" className={classes.addSectionHeading}>
                    Add Section
                  </Typography>
                </Grid>
                <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                  <Typography color="secondary" className={classes.fieldLabelSmall}>
                    Section Title
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    component={InputField}
                    name="newSection.sectionTitle"
                    value={values.newSection.sectionTitle}
                    placeholder="Type here"
                    onChange={(e) => handleChange(spaceMask(e))}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} />

            <Grid item xs={8}>
              <Grid container className={classes.verticleMargin}>
                <Grid item xs={12} className={classes.extraVerticalMargin} container justifyContent="space-between">
                  <CustomGrid item xs={6} width="48%">
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        setFieldValue("newSection", {
                          sectionTitle: "",
                          requestDocument: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </CustomGrid>
                  <CustomGrid item xs={6} width="48%">
                    <TernaryCheck
                      condition={!permissions.includes("Create a Checklist-Delivery Checklists")}
                      trueComponent={
                        <Button variant="contained" color="primary" fullWidth disabled={true}>
                          + Add
                        </Button>
                      }
                      falseComponent={
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            let sectionNames = [];
                            values.checklist.attributes.sections.data.map((sectionItem) => {
                              sectionNames.push(sectionItem?.attributes?.section_name);
                            });

                            if (sectionNames.includes(values.newSection.sectionTitle)) {
                              showToast({
                                type: "error",
                                message: "Section with this name is already present",
                              });
                              return;
                            }
                            let newSection = {
                              id: "",
                              isAdditionalSection: true,
                              type: "section",
                              attributes: {
                                id: "",
                                section_name: values.newSection.sectionTitle,
                                section_rank: values.checklist.attributes.sections.data.length,
                                questions: {
                                  data: [],
                                },
                              },
                            };

                            let sections = [...values.checklist.attributes.sections.data];
                            sections.push(newSection);

                            let newChecklist = {
                              ...values.checklist,
                              attributes: {
                                ...values.checklist.attributes,
                                sections: {
                                  data: sections,
                                },
                              },
                            };
                            updateChecklist(newChecklist);
                          }}
                          disabled={values.newSection.sectionTitle === ""}
                        >
                          + Add
                        </Button>
                      }
                    />
                  </CustomGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} className={classes.rightSection}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.verticleMargin}>
                <Grid item xs={12}>
                  <Typography color="textPrimary" className={classes.addSectionHeading}>
                    Add Question
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <Typography color="secondary" className={classes.fieldLabelSmall}>
                    Select Section
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <Field component={InputField} name="newQuestion.sectionName" value={values.newQuestion.sectionName} onChange={handleChange} fullWidth select>
                    <MenuItem value={"Select"} disabled>
                      Select
                    </MenuItem>
                    {values.checklist?.attributes?.sections?.data.map((sectionItem) => (
                      <MenuItem key={sectionItem?.attributes?.section_name} value={sectionItem?.attributes?.section_name}>
                        {sectionItem?.attributes?.section_name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                  <Typography color="secondary" className={classes.fieldLabelSmall}>
                    Question
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    component={InputField}
                    name="newQuestion.questionName"
                    value={values.newQuestion.questionName}
                    placeholder="Type here"
                    onChange={(e) => handleChange(spaceMask(e))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} />
                <CustomGrid margin="1rem 0" item xs={8}>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" name="newQuestion.requestDocument" checked={values.newQuestion.requestDocument} onChange={handleChange} />
                    }
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                    label="Request Document?"
                  />
                </CustomGrid>
                <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                  <Typography color="secondary" className={classes.fieldLabelSmall}>
                    Type of Question
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    component={InputField}
                    name="newQuestion.questionType"
                    value={values.newQuestion.questionType}
                    onChange={handleChange}
                    fullWidth
                    select
                  >
                    <MenuItem value={"Select"} disabled>
                      Select
                    </MenuItem>
                    {[
                      { name: "Radio Button", value: "radio" },
                      { name: "Check Box", value: "checkbox" },
                      { name: "Drop Down", value: "multiselect" },
                      { name: "Free Text", value: "textfield" },
                    ].map((questionType) => (
                      <MenuItem key={questionType?.name} value={questionType?.value}>
                        <Grid container alignItems="center">
                          {getIconType(questionType?.name)}
                          <CustomTypography padding="0 0 0 1rem">{questionType?.name}</CustomTypography>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                {showPossibleAnswersSection ? (
                  <>
                    <Grid item xs={3} />
                    <Grid item xs={8}>
                      <CustomTypography color="textSecondary" padding="0.5rem 1rem 0 1rem" fontSize="1.1rem" fontWeight="bold">
                        Possible Answers
                      </CustomTypography>
                      <Divider light />
                    </Grid>
                    <Grid item xs={3} />
                    <CustomGrid item xs={8} container justifyContent="flex-end" className={classes.possibleAnswersWhiteCard}>
                      {values.newQuestion.questionOptions.length > 0
                        ? values.newQuestion.questionOptions.map((queOpt) => (
                            <CustomGrid padding="0 0 0.5rem 0" key={queOpt} item xs={12} container justifyContent="space-between" alignItems="center">
                              <RadioButtonUncheckedIcon />
                              <CustomTypography
                                style={{
                                  width: "90%",
                                }}
                                color="primary"
                                fontWeight={700}
                                fontSize="1.2rem"
                                padding="0 0 0 1rem"
                              >
                                {queOpt}
                              </CustomTypography>
                              <Close
                                className={classes.closeIcon}
                                onClick={() => {
                                  let newQuestionOptions = [...values.newQuestion.questionOptions];
                                  newQuestionOptions = newQuestionOptions.filter((item) => item !== queOpt);
                                  setFieldValue("newQuestion", {
                                    ...values.newQuestion,
                                    questionOptions: newQuestionOptions,
                                  });
                                }}
                              />
                            </CustomGrid>
                          ))
                        : null}
                      <Field
                        component={StanderdTextFieldWeb}
                        name="tempQuestionOption"
                        value={values.tempQuestionOption}
                        placeholder="Type here"
                        onChange={(e) => handleChange(spaceMask(e))}
                        fullWidth
                      />
                      <Button
                        variant="text"
                        color="primary"
                        disabled={values.tempQuestionOption === ""}
                        onClick={() => {
                          let newQuestionOptions = [...values.newQuestion.questionOptions];
                          if (newQuestionOptions.includes(values.tempQuestionOption)) {
                            showToast({
                              type: "error",
                              message: "option already present, add a different option.",
                            });
                            return;
                          }
                          newQuestionOptions.push(values.tempQuestionOption);
                          setFieldValue("newQuestion", {
                            ...values.newQuestion,
                            questionOptions: newQuestionOptions,
                          });
                          setFieldValue("tempQuestionOption", "");
                        }}
                      >
                        + Add Option
                      </Button>
                    </CustomGrid>
                  </>
                ) : null}
              </Grid>
            </Grid>

            <Grid item xs={3} />

            <Grid item xs={8}>
              <Grid container className={classes.verticleMargin}>
                <Grid item xs={12} className={classes.extraVerticalMargin} container justifyContent="space-between">
                  <CustomGrid item xs={6} width="48%">
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        setFieldValue("newQuestion", {
                          sectionName: "Select",
                          questionName: "",
                          requestDocument: false,
                          questionType: "",
                          questionOptions: [],
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </CustomGrid>
                  <CustomGrid item xs={6} width="48%">
                    <TernaryCheck
                      condition={!permissions.includes("Create a Checklist-Delivery Checklists")}
                      trueComponent={
                        <Button variant="contained" color="primary" fullWidth disabled={true}>
                          + Add
                        </Button>
                      }
                      falseComponent={
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            let questionNames = [];

                            let newQuestion = {
                              id: "",
                              isAdditionalQuestion: true,
                              type: "question",
                              attributes: {
                                id: "",
                                question_name: values.newQuestion.questionName,
                                checked: true,
                                client_status: "Client",
                                request_document: values.newQuestion.requestDocument,
                                status: "Requested/Submitted",
                                current_gathered_details: "",
                                question_rank: null,
                                question_options: values.newQuestion.questionOptions,
                                question_type: values.newQuestion.questionType,
                                document: null,
                              },
                            };

                            let newChecklist = {
                              ...values.checklist,
                              attributes: {
                                ...values.checklist.attributes,
                                sections: {
                                  data: values.checklist.attributes.sections.data.map((sectionItem, sectionIndex) => {
                                    if (sectionItem.attributes.section_name === values.newQuestion.sectionName) {
                                      sectionItem.attributes.questions.data.map((questionItem) => {
                                        questionNames.push(questionItem?.attributes?.question_name);
                                      });
                                      return {
                                        ...sectionItem,
                                        attributes: {
                                          ...sectionItem.attributes,
                                          questions: {
                                            data: [...values.checklist.attributes.sections.data[sectionIndex].attributes.questions.data, newQuestion],
                                          },
                                        },
                                      };
                                    } else return sectionItem;
                                  }),
                                },
                              },
                            };
                            if (questionNames.includes(values.newQuestion.questionName)) {
                              showToast({
                                type: "error",
                                message: "Question with this name is already present",
                              });
                              return;
                            }
                            updateChecklist(newChecklist);
                          }}
                          disabled={isQuestionButtonDisabled(values)}
                        >
                          + Add
                        </Button>
                      }
                    />
                  </CustomGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export class CreateOnboardingChecklist extends CreateOnboardingChecklistController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        if (this.props.type === "manage") {
          this.getChecklist(this.props?.history?.location?.state?.checklistId);
        } else if (this.props.type === "create") {
          this.setState({
            checklist: {
              attributes: {
                id: undefined,
                checklist_name: "",
                account_id: this.state.accountId,
                is_completed: false,
                sections: {
                  data: [],
                },
              },
            },
          });
        }
      }
    );
  }

  onSectionDragEnd = (values, setFieldValue, result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newSections = [...values.checklist.attributes.sections.data];
    newSections.splice(source.index, 1);
    newSections.splice(destination.index, 0, values.checklist.attributes.sections.data[source.index]);

    let newChecklist = {
      ...values.checklist,
      attributes: {
        ...values.checklist.attributes,
        sections: {
          data: newSections,
        },
      },
    };

    this.updateChecklist(newChecklist);

    setFieldValue("checklist", newChecklist);
  };

  getIconType = (questionType) => {
    switch (questionType) {
      case "Radio Button":
        return <RadioButtonCheckedIcon />;
      case "Check Box":
        return <CheckCircleOutlineIcon />;
      case "Drop Down":
        return <ArrowDropUpIcon />;
      case "Free Text":
        return <ControlPointIcon />;
      default:
        break;
    }
  };

  render() {
    const { classes, theme, permissions } = this.props;
    return (
      <Formik
        initialValues={{
          checklist: this.state.checklist,
          newSection: {
            sectionTitle: "",
            requestDocument: false,
          },
          newQuestion: {
            sectionName: "Select",
            questionName: "",
            requestDocument: false,
            questionOptions: [],
            questionType: "Select",
          },
          move: {},
          tempQuestionOption: "",
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue } = formikProps;

          const pageHeading = this.props.type === "manage" ? "Manage Onboarding Checklist" : "Create An Onboarding Checklist";

          const onQuestionDragEnd = (result, sectionIndex, sectionId) => {
            const { destination, source } = result;

            if (!destination) {
              return;
            }

            if (destination.droppableId === source.droppableId && destination.index === source.index) {
              return;
            }

            const newQuestions = [...values.checklist.attributes.sections.data[sectionIndex].attributes.questions.data];
            newQuestions.splice(source.index, 1);
            newQuestions.splice(destination.index, 0, values.checklist.attributes.sections.data[sectionIndex].attributes.questions.data[source.index]);

            let newChecklist = {
              ...values.checklist,
              attributes: {
                ...values.checklist.attributes,
                sections: {
                  data: values.checklist.attributes.sections.data.map((sectionItem) => {
                    if (sectionItem.id === sectionId) {
                      return {
                        ...sectionItem,
                        attributes: {
                          ...sectionItem.attributes,
                          questions: {
                            data: newQuestions,
                          },
                        },
                      };
                    } else return sectionItem;
                  }),
                },
              },
            };

            this.updateChecklist(newChecklist);

            setFieldValue("checklist", newChecklist);
          };

          return (
            <Form autoComplete="off" noValidate>
              <Grid container>
                <Grid item xs={12} className={classes.pageHeadingContainer}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<ChevronLeftIcon />}
                    className={classes.backButton}
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.pageHeadingContainer} container justifyContent="space-between" alignItems="center">
                  <Typography variant="h2" color="secondary">
                    {pageHeading}
                  </Typography>
                  <TernaryCheck
                    condition={!permissions.includes("Create a Checklist-Delivery Checklists")}
                    trueComponent={
                      <Button variant="outlined" color="primary" className={classes.saveChecklistButton} disabled={true}>
                        Save Checklist
                      </Button>
                    }
                    falseComponent={
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          this.updateChecklist(values.checklist);
                        }}
                        className={classes.saveChecklistButton}
                        disabled={values.checklist?.attributes?.checklist_name === ""}
                      >
                        Save Checklist
                      </Button>
                    }
                  />
                </Grid>

                <Grid item xs={12} className={[classes.gridSpacing]}>
                  <Grid container justifyContent="space-between" className={classes.whiteCard}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={2} style={{ maxWidth: "10%", flexBasis: "10%", flexGrow: 0 }}>
                          <Typography display="inline" color="secondary" variant="h3" className={classes.fieldLabel}>
                            Checklist Name:
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Field
                            component={InputField}
                            color="primary"
                            name="checklist.attributes.checklist_name"
                            value={values.checklist?.attributes?.checklist_name}
                            onChange={(e) => handleChange(spaceMask(e))}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <DragDropContext onDragEnd={this.onSectionDragEnd.bind(this, values, setFieldValue)}>
                      <Droppable droppableId="sectionsDroppable">
                        {(sectionDroppableProvided) => {
                          return (
                            <Grid xs={4} ref={sectionDroppableProvided.innerRef} {...sectionDroppableProvided.droppableProps}>
                              <Grid container>
                                {values.checklist?.attributes?.sections?.data.map((sectionItem, sectionIndex) => {
                                  return (
                                    <Draggable draggableId={sectionItem.id} index={sectionIndex} key={sectionItem.id}>
                                      {(sectionDraggableProvided) => {
                                        return (
                                          <DragDropContext onDragEnd={(result) => onQuestionDragEnd(result, sectionIndex, sectionItem.id)}>
                                            <Droppable droppableId="questionsDroppable">
                                              {(questionDroppableProvided) => {
                                                return (
                                                  <Grid item xs={12} ref={questionDroppableProvided.innerRef} {...questionDroppableProvided.droppableProps}>
                                                    <Grid
                                                      container
                                                      alignItems="flex-start"
                                                      className={classes.mainChecklist}
                                                      onClick={() => {
                                                        this.setState({
                                                          selectedSection: sectionItem,
                                                        });
                                                      }}
                                                      {...sectionDraggableProvided.draggableProps}
                                                      {...sectionDraggableProvided.dragHandleProps}
                                                      ref={sectionDraggableProvided.innerRef}
                                                    >
                                                      <CustomGrid width="5%" container alignItems="flex-start" item xs={1}>
                                                        <DragIndicatorIcon className={classes.sectionDragIcon} />
                                                      </CustomGrid>
                                                      <CustomGrid width="87%" container alignItems="flex-start" item xs={10}>
                                                        <Typography display="inline" className={classes.checklistNameStyles}>
                                                          {sectionItem?.attributes?.section_name}
                                                        </Typography>
                                                      </CustomGrid>
                                                      <CustomGrid width="8%" container alignItems="center" justifyContent="flex-end" item xs={1}>
                                                        <DeleteIcon
                                                          className={classes.sectionDeleteIcon}
                                                          onClick={() => {
                                                            this.updateChecklist(values.checklist, {}, sectionItem);
                                                          }}
                                                        />
                                                      </CustomGrid>
                                                      <Grid item xs={12}>
                                                        <Collapse
                                                          in={this.state.selectedSection?.attributes?.section_name === sectionItem?.attributes?.section_name}
                                                          timeout="auto"
                                                          unmountOnExit
                                                        >
                                                          {sectionItem?.attributes?.questions?.data.length > 0 ? (
                                                            sectionItem?.attributes?.questions?.data.map((questionItem, questionIndex) => (
                                                              <Draggable draggableId={questionItem.id} index={questionIndex} key={questionItem.id}>
                                                                {(questionDraggableProvided) => {
                                                                  return (
                                                                    <Question
                                                                      questionDraggableProvided={questionDraggableProvided}
                                                                      values={values}
                                                                      setFieldValue={setFieldValue}
                                                                      classes={classes}
                                                                      state={this.state}
                                                                      setState={(value) => {
                                                                        this.setState(value);
                                                                      }}
                                                                      moveQuestionToSection={this.moveQuestionToSection}
                                                                      updateChecklist={this.updateChecklist}
                                                                      sectionItem={sectionItem}
                                                                      questionItem={questionItem}
                                                                    />
                                                                  );
                                                                }}
                                                              </Draggable>
                                                            ))
                                                          ) : (
                                                            <Grid container justifyContent="space-between" className={classes.questionsContainer}>
                                                              <Grid item xs={1}></Grid>
                                                              <Grid item xs={8}>
                                                                <Typography
                                                                  className={classes.questionNames}
                                                                  style={{
                                                                    color: theme.palette.error.main,
                                                                  }}
                                                                >
                                                                  No questions available
                                                                </Typography>
                                                              </Grid>
                                                              <Grid item xs={3} container justifyContent="flex-end"></Grid>
                                                            </Grid>
                                                          )}
                                                          {questionDroppableProvided.placeholder}
                                                        </Collapse>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                );
                                              }}
                                            </Droppable>
                                          </DragDropContext>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {sectionDroppableProvided.placeholder}
                              </Grid>
                            </Grid>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>

                    <RightSection
                      values={values}
                      setFieldValue={setFieldValue}
                      classes={classes}
                      updateChecklist={this.updateChecklist}
                      showToast={this.props.showToast}
                      handleChange={handleChange}
                      getIconType={this.getIconType}
                      permissions={permissions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const CreateOnboardingChecklistWithRouter = withRouter(CreateOnboardingChecklist);
const CreateOnboardingChecklistAlertBox = withAlertBox(CreateOnboardingChecklistWithRouter);
const CreateOnboardingChecklistLoader = withLoader(CreateOnboardingChecklistAlertBox);
const CreateOnboardingChecklistToast = withToast(CreateOnboardingChecklistLoader);
const CreateOnboardingChecklistWithDialog = withDialog(CreateOnboardingChecklistToast);
const CreateOnboardingChecklistWithConfirmBox = withConfirmBox(CreateOnboardingChecklistWithDialog);
const CreateOnboardingChecklistWithPermissions = withPermissions(CreateOnboardingChecklistWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: "1.5rem",
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: "0px 4px 18px 12px rgba(149, 157, 165, 0.1)",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      rightSection: {
        padding: "1rem 2rem 0rem 2rem",
        backgroundColor: "#F8F8F8",
        flexGrow: 0,
        flexBasis: "92%",
        maxWidth: "92%",
        marginTop: "1.5rem",
        marginLeft: "4%",
        marginRight: "4%",
        borderRadius: "1rem",
      },
      saveChecklistButton: {
        width: "20%",
      },
      addSectionHeading: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        paddingLeft: "1.3rem",
        marginBottom: "1.5rem",
      },
      pageHeadingContainer: {
        paddingLeft: "1rem",
      },
      manageChecklistButton: {
        paddingRight: "1rem",
        cursor: "pointer",
      },
      addChecklistButton: {
        padding: 0,
        margin: 0,
        height: "auto",
      },
      fieldLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1rem",
        color: theme.palette.text.primary,
        display: "inline-block",
      },
      fieldLabelSmall: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
        marginRight: "0.5rem",
      },
      verticleMargin: {
        margin: "0.5rem 0rem",
      },
      extraVerticalMargin: {
        margin: "3rem 0rem",
        marginTop: "5rem",
      },
      backButton: {
        padding: 0,
        fontSize: "1.1rem",
        margin: "1rem 0",
        height: "auto",
        "&& svg": {
          fontSize: "1.5rem",
        },
      },
      mainChecklist: {
        position: "relative",
        padding: "1rem",
        borderRadius: "1.5rem",
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
        border: `2px solid ${theme.palette.primary.main}`,
        marginRight: "8%",
        cursor: "pointer",
        marginTop: "1.5rem",
      },
      checklistNameStyles: {
        paddingLeft: "1rem",
        fontSize: "1.1rem",
        fontWeight: 700,
        color: theme.palette.primary.main,
        display: "inline-block",
        marginTop: "0.25rem",
      },
      sectionDragIcon: {
        color: "#c8c8c8",
        fontSize: "2rem",
        cursor: "pointer",
        display: "inline-block",
      },
      questionDragIcon: {
        color: "#c8c8c8",
        fontSize: "1.5rem",
        cursor: "pointer",
      },
      questionsContainer: {
        paddingLeft: "1.5rem",
        padding: "0.5rem 0",
      },
      questionNames: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
      },
      stylesMoreIcon: {
        height: "1.3rem",
        width: "1.2rem",
        cursor: "pointer",
        marginRight: "0.5rem",
      },
      tooltipOptionsTitle: {
        fontSize: "1.2rem",
        fontWeight: "bold",
      },
      tooltipAccordionDetails: {
        backgroundColor: "#f8f8f8",
        marginTop: "10px",
        padding: "0.5rem 1rem",
        borderRadius: "8px",
        maxHeight: "150px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#ECECEC",
          borderRadius: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#c9c9c9",
          borderRadius: "0.5rem",
        },
      },
      possibleAnswersWhiteCard: {
        backgroundColor: "#fff",
        borderRadius: "0.75rem",
        padding: "1rem",
        marginTop: "1rem",
      },
      closeIcon: {
        cursor: "pointer",
      },
      sectionDeleteIcon: {
        color: theme.palette.primary.main,
        fontSize: "1.3rem",
        cursor: "pointer",
        display: "inline-block",
        marginTop: "0.3rem",
      },
    }),
  { withTheme: true }
)(CreateOnboardingChecklistWithPermissions);