//@ts-nocheck
import React from "react";
import PricingBumpsController, { Props } from "./PricingBumpsController.web";
import { Grid, withStyles, createStyles, Button, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Formik, Form, FastField } from "formik";
import * as yup from "yup";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import InputFieldPhaseTwoWeb from "../../../../components/src/InputFieldPhaseTwo.web";
import { StyledTableCell } from "../FirmFinancialsNew.web";

const validationSchema = yup.object().shape({
  individualPricingBumps: yup.array(
    yup.object({
      attributes: yup.object({
        id: yup.string(),
        account_id: yup.number(),
        bump_name: yup.string(),
        bump_type: yup.string(),
        bump_amount: yup
          .string()
          .required("Required.")
          .nullable(),
        feature_name: yup.string(),
        bump_for: yup.string(),
        bump_description: yup.string(),
      }),
      id: yup.string(),
      type: yup.string(),
    })
  ),
  businessPricingBumps: yup.array(
    yup.object({
      attributes: yup.object({
        id: yup.string(),
        account_id: yup.number(),
        bump_name: yup.string(),
        bump_type: yup.string(),
        bump_amount: yup
          .string()
          .required("Required.")
          .nullable(),
        feature_name: yup.string(),
        bump_for: yup.string(),
        bump_description: yup.string(),
      }),
      id: yup.string(),
      type: yup.string(),
    })
  ),
});

const PricingBumpTable = ({ classes, theme, values, setFieldValue, currencyChangeHandler, bumpListName }) => {
  return (
    <Grid item xs={12} className={classes.cardPaper}>
      <Grid container direction="row" justifyContent="space-around">
        <Grid item xs={12}>
          <TableContainer
            style={{ width: "100%", borderRadius: "16px", boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)", border: `1px solid ${theme.palette.text.secondary}` }}
          >
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" width="45%" className={classes.columnsHeading}>
                    Information
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" width="12%" className={classes.columnsHeading}>
                    Amount
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" width="18%" className={classes.columnsHeading}></StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" width="25%" className={classes.columnsHeading}>
                    Bump Name
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values[bumpListName].length > 0 ? (
                  values[bumpListName].map((singleBump, singleBumpIndex) => {
                    return (
                      <TableRow key={singleBump?.id}>
                        <StyledTableCell className={classes.textForGoals}>{singleBump?.attributes?.bump_description}</StyledTableCell>
                        <StyledTableCell className={classes.textForGoals}>
                          <Grid container alignItems="center">
                            <FastField
                              component={InputFieldPhaseTwoWeb}
                              className={classes.blueTextField}
                              variant="outlined"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              name={`${bumpListName}[${singleBumpIndex}].attributes.bump_amount`}
                              value={values[bumpListName][singleBumpIndex]?.attributes?.bump_amount}
                              onChange={(e) => {
                                currencyChangeHandler(e, setFieldValue);
                              }}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell className={classes.textForGoals}>{singleBump?.attributes?.bump_for}</StyledTableCell>
                        <StyledTableCell className={classes.textForGoals}>{singleBump?.attributes?.bump_name}</StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center" className={classes.textForGoals}>
                      No Bumps Available
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export class PricingBumps extends PricingBumpsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    if (token) {
      this.setState(
        {
          token: token,
          accountId: accountId,
        },
        () => {
          this.getPricingBumps();
        }
      );
    }
  }

  currencyChangeHandler = (e, setFieldValue) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, "");
    e.target.value = inputValue;

    const regex = /\d+(\.\d\d?)?/;
    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length < 9)) {
      let t = e.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      t ? setFieldValue(e.target.name, t) : setFieldValue(e.target.name, "");
    }
  };

  render() {
    const { classes, theme } = this.props;

    const individualPricingBumps = [...this.state.pricingBumpsList].filter((item) => item.attributes.bump_type === "individual");
    const businessPricingBumps = [...this.state.pricingBumpsList].filter((item) => item.attributes.bump_type === "business");

    return (
      <Formik
        initialValues={{
          individualPricingBumps: individualPricingBumps,
          businessPricingBumps: businessPricingBumps,
        }}
        validationSchema={validationSchema}
        onSubmit={this.updateBumps}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, setFieldValue, isValid, dirty, resetForm } = formikProps;

          const commonPropsForBumpTable = {
            classes: classes,
            theme: theme,
            values: values,
            setFieldValue: setFieldValue,
            currencyChangeHandler: this.currencyChangeHandler,
          };

          return (
            <Form autoComplete="off" noValidate>
              <Grid container direction="row" alignItems="center">
                <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
                  <CustomTypography color="primary" fontSize="1.625rem" margin="1rem 0" fontWeight={600}>
                    Individual
                  </CustomTypography>
                </Grid>

                <PricingBumpTable {...commonPropsForBumpTable} bumpListName="individualPricingBumps" />

                <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
                  <CustomTypography color="primary" fontSize="1.625rem" margin="1rem 0" fontWeight={600}>
                    Business
                  </CustomTypography>
                </Grid>

                <PricingBumpTable {...commonPropsForBumpTable} bumpListName="businessPricingBumps" />
              </Grid>

              <CustomGrid container justifyContent="flex-end" margin="2rem 0">
                <CustomGrid alignItems="center" width="9%" item xs={2} container justifyContent="space-between" margin="0 2rem 0 auto">
                  <Button color="primary" variant="outlined" fullWidth onClick={resetForm} disabled={!dirty}>
                    Cancel
                  </Button>
                </CustomGrid>

                <CustomGrid alignItems="center" width="19%" item xs={2} container justifyContent="space-between">
                  <Button color="primary" fullWidth variant="contained" type="submit" disabled={!isValid}>
                    Save Changes
                  </Button>
                </CustomGrid>
              </CustomGrid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const PricingBumpsWithRouter = withRouter(PricingBumps);
const PricingBumpsAlertBox = withAlertBox(PricingBumpsWithRouter);
const PricingBumpsLoader = withLoader(PricingBumpsAlertBox);
const PricingBumpsToast = withToast(PricingBumpsLoader);
const PricingBumpsWithDialog = withDialog(PricingBumpsToast);

export default withStyles(
  (theme) =>
    createStyles({
      input: {
        "&:invalid": {
          border: "red solid 2px",
        },
      },
      pushingLeft: {
        paddingRight: 20,
        color: theme.palette.text.secondary,
      },
      blueTextField: {
        fontSize: "0.875rem",
        "& input": {
          color: theme.palette.text.secondary,
          fontSize: "0.875rem",
          paddingLeft: 0,
        },
      },
      textForGoals: {
        fontSize: "0.9375rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      cardPaper: {
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(2, 0),
        },
        margin: theme.spacing(1, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      columnsHeading: {
        fontFamily: "Lato",
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
      },
    }),
  { withTheme: true }
)(withConfirmBox(PricingBumpsWithDialog));
