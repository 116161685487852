//@ts-nocheck
import React from "react";
import { Props } from "./SummaryController.web";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpIcon from "@material-ui/icons/Help";
import { CheckIcon } from "../assets";
import handShakeIcon from "../../assets/handshake.png";
import callIcon from "../../assets/phone-call.png";
import decisionIcon from "../../assets/directions.png";
import addImg from "../../assets/0937e2df00dec93b5a6feecbdcc621dc27832c38.png";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import { surveyStyles } from "./surveyStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SummaryEmailDialog from "../SummaryEmailDialog.web";
import Pdf from "../../../../components/src/reactPdf.js";
import { formatCurrency } from "../CustomPackage/CustomPackageCreationSelectFeaturesPage.web";
import TernaryCheck from "../TernaryCheck.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import {
  monthly_packages,
  annual_packages,
} from "./SurveySteperController.web";
import {
  templateStyle,
  formatPhoneNumber,
  DateComponent,
  EmplyLogo,
  getProity,
} from "../ClientList/ValueRoadmap.web";
import DisclaimerDialog from "../DisclaimerDialog.web";

const pricingAccordions = [
  {
    title: 'Is the "Example Price" the Actual Price I will Pay?',
    options: [
      "It depends on the specifics of your situation.",
      "Everyone's needs can be different, so the Example Price is our best estimate based on the information you provided.",
      "As soon as we confirm your needs on our end, we can confirm your actual price and let you know if any adjustments are needed.",
      "Once your final price is confirmed, we'll both can choose to move forward and get started!",
    ],
  },
  {
    title: "Why are your prices different than other Tax Accountants?",
    options: [
      `Most accountants charge you for filing your taxes or for help "doing your books", but don't follow a process to ensure you're paying the least tax possible based on your situation.`,
      "We can make sure your filings and financials are taken care of, but also offer packages designed to; Lower your tax bill, make sure you have more money left over each month for saving or investing, or help grow your business faster.",
      "Offering this level of help is more valuable to you and requires more specialized knowledge from us. Our pricing reflects that year-round help we can uniquely provide.",
    ],
  },
  {
    title: "Why do some options have upfront fees and monthly fees?",
    options: [
      "Upfront fees cover costs for getting historical work caught up, covering specific project work, or engaging specialized expertise you may need.",
      "Monthly fees cover our work together throughout the year and may adjust based on the level and amount of help you or your business needs.",
    ],
  },
  {
    title: "What happens if I want to cancel my monthly fee?",
    options: [
      "You would still be responsible for any work performed for you up to that point, so there could be money owed to close out your account.",
      "The Terms of Service Agreement will specify any fees required at cancellation if that applies to you.",
    ],
  },
  {
    title: "Are Fees Refundable?",
    options: [
      `No. Any paid fees are non-refundable. We have limited resources and by accepting you as a client, that means we're saying "no" to someone else.`,
      "You can cancel your engagement with us at any time, once your account is paid in full (subject to any cancellation fees outlined in your agreement) you will no longer be charged moving forward.",
    ],
  },
];

const packagesAccordions = [
  {
    title:
      "Do you have other packages available besides the options presented?",
    options: [
      "Possibly. Our tool is designed to match your needs with the best possible options.",
      "If you need something one of the options doesn't provide - contact us and we can talk!",
    ],
  },
  {
    title:
      "I already have a financial planner/advisor - do I still need year-round help?",
    options: [
      "Yes. Everything you do financially can affect your taxes. By working with a tax professional, you're looking at the whole picture and avoiding threats other professionals in your life may not see.",
      "You never want to pay too much in tax or make financial decisions that could cause unwanted tax consequences&this could increase the time it takes to reach your financial goals.",
      "Many of our clients work with an investment advisor, but still, receive value from us. When you work with us, we can help you put together an unbiased & objective action plan that takes your unique tax situation into account as well.",
      "As your tax strategy is implemented, we can work with other specialists that help with your investments or any other aspect of your financial life.",
    ],
  },
  {
    title: "I love one of the options - how do I get started?",
    options: [
      <>
        First, book a call through our Calendar to confirm your package with us,{" "}
        <i>
          <u>
            <b>or</b>
          </u>
        </i>{" "}
        if you see the option to Enroll Now, you can do that right online!
      </>,
      "As soon as we confirm your package, you e-Sign your enrollment documents & make your payment, we'll assign your work and begin onboarding.",
      "We'll work with you during onboarding to answer any questions you have, help with any critical issues you want to address, and do everything we can to make your life easier right away.",
    ],
  },
  {
    title: "Someone I know would love these packages, can I refer them to you?",
    options: [
      "We love referrals! Send them the link to our website or have them contact us directly.",
      "We'll treat them with the utmost care and verify we're a good fit.",
      "If we end up working together, we'll let you know and send you a thank you!",
    ],
  },
];
const svgCode = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"  /></svg>`;

export const ConfidentialityStatement = (props) => {
  const { classes } = props;

  return (
    <>
      <CustomGrid item xs={10} width="88%" margin="2rem 6% 0 0">
        <CustomTypography
          className={classes.secondaryColorText}
          fontSize="1.7rem"
          fontWeight={700}
        >
          Confidentiality & Disclaimer
        </CustomTypography>
      </CustomGrid>
      <CustomGrid
        item
        xs={10}
        width="90%"
        margin="0 5% 1rem 0.5rem"
        className={classes.innerWhiteCard}
      >
        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.disclaimerStyle}>
            {props.roadMap?.attributes?.confidential_statement ||
              "This has been prepared for the purpose of providing tax, accounting and strategic advisory services. This information should not be shared, has not been audited, and does not imply we're providing investment advice."}
          </Typography>
        </Grid>
      </CustomGrid>
    </>
  );
};
export const EmailTemplateStaticContent = (props) => {
  const { classes } = props;
  return (
    <>
      <CustomGrid item xs={10} width="88%" margin="2rem 6% 0 0">
        <CustomTypography
          className={classes.secondaryColorText}
          fontSize="1.7rem"
          fontWeight={700}
        >
          Execution
        </CustomTypography>
      </CustomGrid>

      <CustomGrid
        item
        xs={10}
        width="90%"
        margin="0 5% 1rem 0"
        className={classes.innerWhiteCard}
      >
        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.executionFeatures}>
            <span>
              <div
                dangerouslySetInnerHTML={{ __html: svgCode }}
                className={classes.iconStyle2}
                style={{ width: "9px", marginRight: "10px" }}
              />
            </span>
            We will work with you to define & prioritize work items based on
            your goals.
          </Typography>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.executionFeatures}>
            <span>
              <div
                dangerouslySetInnerHTML={{ __html: svgCode }}
                className={classes.iconStyle2}
                style={{ width: "9px", marginRight: "10px" }}
              />
            </span>
            We will execute as many work items as are feasible for the
            engagement period & package chosen.
          </Typography>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Typography className={classes.executionFeatures}>
            <span>
              <div
                dangerouslySetInnerHTML={{ __html: svgCode }}
                className={classes.iconStyle2}
                style={{ width: "9px", marginRight: "10px" }}
              />
            </span>
            As work items are completed, we will update and re-prioritize action
            items to ensure our efforts are always focused on your immediate
            needs.
          </Typography>
        </Grid>
      </CustomGrid>

      <CustomGrid item xs={10} width="88%" margin="2rem 6% 0">
        <CustomTypography
          className={classes.secondaryColorText}
          fontSize="1.7rem"
          fontWeight={700}
          style={{ textAlign: "center" }}
        >
          Next Steps
        </CustomTypography>
      </CustomGrid>

      <CustomGrid
        item
        xs={10}
        width="90%"
        margin="1rem 5%"
        className={classes.innerWhiteCard}
        style={{ display: "flex", gap: "50px" }}
      >
        <CustomGrid
          item
          xs={12}
          margin="0 0 0.75rem 0"
          className={classes.boxStyle}
        >
          <Grid container alignItems="center" className={classes.textBox}>
            <Grid item style={{ width: "100%" }}>
              <Box className={classes.iconBox}>
                <img src={decisionIcon} />
              </Box>
            </Grid>
            <Grid item>
              <Typography className={classes.nextStepsPrimaryText}>
                Decision
              </Typography>
              <Typography className={classes.nextStepsSecondaryText}>
                Choose the option that best fits your needs.
              </Typography>
            </Grid>
          </Grid>
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          margin="0 0 0.75rem 0"
          className={classes.boxStyle}
        >
          <Grid container alignItems="center" className={classes.textBox}>
            <Grid item style={{ width: "100%" }}>
              <Box className={classes.iconBox}>
                <img src={handShakeIcon} />
              </Box>
            </Grid>
            <Grid item>
              <Typography className={classes.nextStepsPrimaryText}>
                Onboarding
              </Typography>
              <Typography className={classes.nextStepsSecondaryText}>
                eSign your enrollment documents, make your payment, & we will
                designate resources for your work.
              </Typography>
            </Grid>
          </Grid>
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          margin="0 0 0.75rem 0"
          className={classes.boxStyle}
        >
          <Grid container alignItems="center" className={classes.textBox}>
            <Grid item style={{ width: "100%" }}>
              <Box className={classes.iconBox}>
                <img src={callIcon} />
              </Box>
            </Grid>
            <Grid item>
              <Typography className={classes.nextStepsPrimaryText}>
                Kickoff Call
              </Typography>
              <Typography className={classes.nextStepsSecondaryText}>
                We can schedule a kickoff call to get started!
              </Typography>
            </Grid>
          </Grid>
        </CustomGrid>
      </CustomGrid>
    </>
  );
};
export const getPhoneNumber = (props) => {
  let number = "";
  if (props.cpaDetail.theme.phone_number && props.cpaDetail.theme.web_url) {
    number = ` | ${props.cpaDetail.theme.phone_number}`;
  } else if (props.cpaDetail.theme.phone_number) {
    number = `${props.cpaDetail.theme.phone_number}`;
  }

  if (props.cpaDetail.theme.phone_number) {
    return formatPhoneNumber(number);
  } else {
    return null;
  }
};
const packageViewSectionList = (
  paymentTerm,
  index,
  classes,
  props,
  total_fees
) => {
  return (
    <div
      className={
        props.selectedPackageForSurvey?.pkg?.id &&
        props.selectedPackageForSurvey?.pkg?.id === paymentTerm?.pkg?.id &&
        props?.selectedPackageForSurvey?.paymentTermKey ==
          paymentTerm?.paymentTermKey
          ? classes.selectedPackageTrueStyle
          : classes.selectedPackageStyle
      }
      key={`${index}`}
    >
      <CustomGrid
        key={index}
        item
        container
        direction="column"
        margin="0 1%"
        className={classes.packageCardHeadingBox}
      >
        <CustomTypography
          fontSize="1.5rem"
          fontWeight={700}
          margin="1rem 0 0.5rem 0"
          className={classes.primaryColorText}
        >
          Package - {index + 1}
        </CustomTypography>
        <CustomTypography
          fontSize="1.2rem"
          fontWeight={700}
          color="black"
          margin="0 0 0.25rem 0"
        >
          {paymentTerm.name}
        </CustomTypography>
        <CustomTypography fontSize="1.2rem" color="black" margin="0 0 0.5rem 0">
          {total_fees} UpFront
        </CustomTypography>

        <Grid container style={{ paddingBlock: "10px" }}>
          <CustomGrid item margin="0 1%" key={index}>
            <Box
              style={{
                overflow: "hidden",
              }}
            >
              <div className={classes.featureListStyle}>
                {paymentTerm.feature_name_list.map((category) => {
                  return (
                    <Typography
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        textAlign: "start",
                      }}
                      key={category}
                      color="textSecondary"
                      className={classes.featureText2}
                    >
                      <span className={classes.checkedIcon}>
                        <img src={CheckIcon} style={{ width: "12px" }} />
                      </span>
                      {category}
                    </Typography>
                  );
                })}
              </div>
            </Box>
          </CustomGrid>
        </Grid>
      </CustomGrid>
    </div>
  );
};

export const SurveyEmailTemplate = React.forwardRef((props, refs) => {
  const { classes } = props;
  const theme = props?.cpaDetail?.theme || {};
  const surveyDetail = props?.surveyDetail?.about_us?.data?.attributes || {};
  return (
    <Grid
      container
      ref={refs}
      style={{ overflowX: "hidden", marginTop: "1000px" }}
      justifyContent="center"
      className={classes.rootChildContainerpdf}
    >
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <TernaryCheck
            condition={theme.logo}
            trueComponent={
              <Grid item xs={2} className={classes.imageBox}>
                <img
                  src={theme.logo}
                  alt="No logo available"
                  className={classes.logoImage}
                />
              </Grid>
            }
            falseComponent={<EmplyLogo classes={classes} />}
          />
          <DateComponent />
        </Grid>
      </Grid>
      <CustomGrid className={classes.whiteCardRoadmap} container>
        <Grid
          container
          className={classes.mainheadingStyle}
          item
          xs={12}
          direction="row"
        >
          <Grid item container xs={12}>
            <Typography
              className={`${classes.pageTitle} ${classes.primaryColorText}`}
              align="left"
            >
              My Roadmap
            </Typography>
          </Grid>
          <Typography
            style={{ color: "#4b4244", marginBottom: "15px" }}
            className={classes.pageTitle}
            align="left"
          >
            Roadmap Prepared for{" "}
            <span
              className={classes.primaryColorText}
              align="center"
              fontSize="1.7rem"
              fontWeight={700}
            >
              {surveyDetail.first_name} {surveyDetail.last_name}
            </span>
          </Typography>
        </Grid>
        {getProity(props, classes)}
        <CustomGrid
          style={{ textAlign: "center" }}
          margin="1rem 6% 2rem 6%"
          width="88%"
        >
          <CustomTypography
            fontWeight={900}
            className={classes.secondaryColorText}
            fontSize="1.7rem"
          >
            Your Package Options
          </CustomTypography>
        </CustomGrid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            {props.packageList.map((paymentterm, index) => {
              let total_fees = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                roundingMode: "ceil",
              }).format(paymentterm.price + "");
              return packageViewSectionList(
                paymentterm,
                index,
                props.classes,
                props,
                total_fees
              );
            })}
          </Grid>
        </Grid>

        {EmailTemplateStaticContent(props)}
        {ConfidentialityStatement(props)}
        <CustomGrid
          item
          xs={10}
          width="90%"
          margin="1rem 5% 1rem 0"
          className={classes.innerWhiteCard}
          style={{
            boxShadow: "none",
          }}
        >
          <CustomGrid
            container
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            margin="0 0 0.75rem 0"
          >
            <Grid item xs={1} container justifyContent="flex-start">
              {theme.logo ? (
                <img
                  src={theme.logo}
                  alt="No logo available"
                  style={{
                    width: "30px",
                  }}
                />
              ) : (
                <Box className={classes.logoBox}>
                  <img
                    src={addImg}
                    style={{
                      width: "30%",
                    }}
                  />
                  <Typography variant="body2" display="block">
                    Your logo here
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={11}>
              {theme.web_url ? (
                <Link
                  href={
                    theme.web_url.indexOf("https") === 0
                      ? theme.web_url
                      : `https://${props.roadmap?.attributes?.web_url}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <Typography
                    className={`${classes.primaryColorText} ${classes.urlAndPhoneText}`}
                    align="center"
                    display="inline"
                  >
                    {theme.web_url}
                  </Typography>
                  {theme.web_url && theme.phone_number ? (
                    <Typography
                      className={`${classes.primaryColorText} ${classes.urlAndPhoneText} ${classes.middleBar}`}
                      align="center"
                      display="inline"
                    >
                      |
                    </Typography>
                  ) : null}
                </Link>
              ) : null}
              <Typography
                className={`${classes.primaryColorText} ${classes.urlAndPhoneText}`}
                align="center"
                display="inline"
              >
                {Object.keys(theme)?.length > 0 && getPhoneNumber(props)}
              </Typography>
            </Grid>
          </CustomGrid>
        </CustomGrid>
      </CustomGrid>
    </Grid>
  );
});

const options = {
  unit: "pt",
  format: "a4",
};

export class SummaryStep extends React.Component {
  constructor(props: Props) {
    super(props);
    this.pdfRef = React.createRef();
    this.state = {
      openfaqs: this.props.openFaq,
    };
  }

  openEmailDialog = (data) => {
    this.setState(
      {
        templateShow: true,
      },
      () => {
        this.props
          .openDialogBox({
            dataToPass: {
              values: data,
              type: "emailneedtobesend",
            },
            catchOnCancel: true,
            width: "60%",
            renderedComponent: SummaryEmailDialog,
            withCustomDialog: true,
          })
          .then(async (emaildata: any) => {
            this.setState(
              {
                templateShow: false,
              },
              () => {
                if (emaildata) {
                  this.props.sendEmailTemplate(
                    emaildata.email,
                    emaildata.base64
                  );
                }
              }
            );
          });
      }
    );
  };

  handlePackageSelect = (m) => {
    this.props.setSelectedPackage(m);
  };
  openDisclaimerDialog = (m: any) => {
    const package_self_enrollment = [...this.props.package_self_enrollment];
    const package_enroll = package_self_enrollment.find((pkg) => {
      return pkg.name === m?.name;
    });
    if (package_enroll?.isAvailableForEnrollment) {
      this.props
        .openDialogBox({
          width: "40%",
          renderedComponent: DisclaimerDialog,
          withCustomDialog: true,
          catchOnCancel: true,
        })
        .then(async (data: any) => {
          if (data.result === "Yes") {
            this.handlePackageSelect(m);
          }
        });
    } else if (
      this.props?.cpaDetail?.survey_setting?.data?.attributes?.meeting_link
    ) {
      this.setState(
        {
          openfaqs: false,
        },
        () => {
          if (this.props.taxPayerType == "business") {
            this.props.updateStep(8);
          } else {
            this.props.updateStep(7);
          }
        }
      );
    } else {
      this.props.showToast({
        type: "error",
        message: "Please reach out to your Tax Pro to discuss your options!",
      });
    }
  };
  handleConfirmSelecrion = () => {
    this.setState(
      {
        openfaqs: false,
      },
      () => {
        if (this.props.isPaymentEnable) {
          if (this.props.taxPayerType == "business") {
            this.props.updateStep(7);
          } else {
            this.props.updateStep(6);
          }
        } else {
          this.props.createChecklistforSelectedPackage();
        }
      }
    );
  };
  openFaqPage = () => {
    this.setState({
      openfaqs: true,
    });
  };
  backToPkgList = () => {
    this.setState({ openfaqs: false }, () =>
      this.props.setCurrentSummaryStep(0)
    );
  };
  bookACall = () => {
    this.setState(
      {
        openfaqs: false,
      },
      () => {
        if (this.props.taxPayerType == "business") {
          this.props.updateStep(8);
        } else {
          this.props.updateStep(7);
        }
      }
    );
  };

  applyBoxShadow = (id) => {
    return id === this.props.selectedPackage?.id
      ? [this.props.classes?.cardPackageSelected, "staticCardPackage"]
      : [this.props.classes?.cardPackage, "staticCardPackage"];
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="space-around" alignItems="start">
        <Grid
          xs={12}
          item
          style={{
            position: "relative",
          }}
        >
          {!this.state.openfaqs && this.props.currentSummaryStep === 0 && (
            <Grid container justifyContent="center">
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 900,
                    marginTop: "2rem",
                  }}
                  color="secondary"
                  align="center"
                >
                  Your Recommended Package Options
                </Typography>
                <Typography
                  className={classes.packagePageSubHeading}
                  align="center"
                  style={{ marginBottom: "2rem" }}
                  gutterBottom
                >
                  Select the option that best suits your needs.
                </Typography>
                <Typography
                  align="center"
                  style={{ fontWeight: 700, fontSixe: "14px" }}
                  color="secondary"
                >
                  RECOMMENDED
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container justifyContent="center">
                  {this.props.packageList?.map((m, index) => {
                    let price = null;
                    let price_ferq = null;
                    if (annual_packages.indexOf(m.name) > -1) {
                      price = formatCurrency.format(Math.round(m.price));
                      price_ferq = "One Time";
                    } else if (monthly_packages.indexOf(m.name) > -1) {
                      price = formatCurrency.format(Math.round(m.price / 12));
                      price_ferq = "Monthly";
                    }
                    return (
                      <Grid
                        key={m.id}
                        item
                        xs={12}
                        md={4}
                        className={this.applyBoxShadow(m.id)}
                      >
                        <Grid container alignItems="space-between">
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontSize: "24px",
                                fontWeight: 900,
                                marginBottom: "10px",
                                textAlign: "center",
                              }}
                              color="primary"
                            >
                              Package {` ${index + 1}`}
                            </Typography>
                            <Typography
                              className={classes.optionPriceLarge}
                              align="center"
                            >
                              $ {price}
                              <Typography
                                className={classes.optionPriceSmall}
                                align="center"
                                display="inline"
                              >
                                {price_ferq}
                              </Typography>
                              <Typography
                                className={classes.optionTitle}
                                color="textSecondary"
                                align="center"
                              >
                                {m.name}
                              </Typography>
                            </Typography>
                            <Button
                              // onClick={this.handlePackageSelect.bind(this, m)}
                              onClick={this.openDisclaimerDialog.bind(this, m)}
                              className={classes.selectButton}
                            >
                              Select this Package
                            </Button>
                            <Typography
                              style={{
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                              color="primary"
                            >
                              More Details
                            </Typography>
                            <Divider />
                            <div className={classes.featureListContainer}>
                              {m?.feature_name_list?.map((feature) => {
                                return (
                                  <Typography
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      paddingTop: "10px",
                                      alignItems: "center",
                                    }}
                                    key={feature}
                                    color="textSecondary"
                                    className={classes.featureText2}
                                  >
                                    <span className={classes.checkedIcon}>
                                      <img
                                        src={CheckIcon}
                                        style={{ width: "12px" }}
                                      />
                                    </span>
                                    {feature}
                                  </Typography>
                                );
                              })}
                            </div>
                          </Grid>
                          {!m.name.includes("Reporting") ? (
                            <Grid
                              item
                              xs={12}
                              container
                              alignItems="flex-end"
                              justifyContent="center"
                            >
                              {/* <Box className={classes.bottomDivider} /> */}
                              <Typography
                                color="primary"
                                align="center"
                                id="potentialSavings"
                                className={classes.cardPackageBottomText}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.props.openPotentialSavings();
                                }}
                              >
                                See Potential Savings = $36,500
                              </Typography>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Box className={classes.faqQustionText}>
                {this.props.currentSummaryStep === 0 ? (
                  <Box
                    className={classes.openFaqPagetext}
                    id="faqbutton"
                    onClick={this.openFaqPage}
                  >
                    <HelpIcon color="primary" style={{ fontSize: "3.8rem" }} />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "rgb(118, 182, 183) !important",
                        paddingTop: "10px",
                      }}
                    >
                      Questions? We have answers.
                    </span>
                  </Box>
                ) : null}
                <Box style={{ flex: 1 }}>
                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      variant="contained"
                      id="confirmButton"
                      className={classes.confirmButton}
                      disabled={this.props.selectedPackage == null}
                      onClick={this.handleConfirmSelecrion}
                    >
                      Confirm Your Selection
                    </Button>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Pdf
                      targetRef={this.pdfRef}
                      filename="roadmap.pdf"
                      options={options}
                      scale={0.7}
                    >
                      {({ toPdf, ...a }) => (
                        <Typography
                          color="secondary"
                          style={{
                            marginTop: "1rem",
                            fontWeight: 900,
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          id="emailpackages"
                          onClick={this.openEmailDialog.bind(this, {
                            toPdf: toPdf,
                            extra: a,
                          })}
                        >
                          Email The Packages
                        </Typography>
                      )}
                    </Pdf>
                  </Grid>
                </Box>
                <Box style={{ flex: 1 }}></Box>
              </Box>
              {this.state.templateShow ? (
                <Grid item xs={10}>
                  <SurveyEmailTemplate ref={this.pdfRef} {...this.props} />
                </Grid>
              ) : null}
            </Grid>
          )}

          {this.state.openfaqs && (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid xs={9} item>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={this.backToPkgList}
                      startIcon={<ArrowBackIosIcon />}
                      style={{
                        padding: 0,
                        marginLeft: "3rem",
                        height: "2rem",
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid xs={12} item>
                    <Typography
                      className={classes.packagePageHeading}
                      color="primary"
                      align="center"
                      gutterBottom
                    >
                      Got Questions?
                    </Typography>
                    <Typography
                      className={classes.packagePageSubHeading}
                      align="center"
                      gutterBottom
                    >
                      We're here to help!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} className={classes.accordionGrid}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <Typography
                      className={classes.accordionHeading}
                      color="primary"
                    >
                      Pricing
                    </Typography>
                  </Grid>
                  {pricingAccordions.map((pricingFaq, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={pricingFaq.title + index}
                        style={{
                          margin: "0.5rem 0",
                        }}
                      >
                        <Accordion
                          classes={{
                            root: classes.accordionWhite,
                            rounded: classes.accordionRound,
                          }}
                        >
                          <AccordionSummary
                            classes={{
                              expandIcon: classes.accordionExpandIcon,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.accordionTitle}>
                              {pricingFaq.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              flexDirection: "column",
                            }}
                          >
                            {pricingFaq.options.map((faqOption, index1) => (
                              <Typography
                                key={index1}
                                color="textSecondary"
                                className={classes.accordionOption}
                              >
                                {faqOption}
                              </Typography>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} className={classes.accordionGrid}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <Typography
                      className={classes.accordionHeading}
                      color="primary"
                    >
                      {" "}
                      Packages
                    </Typography>
                  </Grid>
                  {packagesAccordions.map((packageFaq, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={packageFaq.title + index}
                        style={{
                          margin: "0.5rem 0",
                        }}
                      >
                        <Accordion
                          classes={{
                            root: classes.accordionWhite,
                            rounded: classes.accordionRound,
                          }}
                        >
                          <AccordionSummary
                            classes={{
                              expandIcon: classes.accordionExpandIcon,
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.accordionTitle}>
                              {packageFaq.title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              flexDirection: "column",
                            }}
                          >
                            {packageFaq.options.map((faqOption) => (
                              <Typography
                                key={faqOption}
                                color="textSecondary"
                                className={classes.accordionOption}
                              >
                                {faqOption}
                              </Typography>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    !this.props?.cpaDetail?.survey_setting?.data?.attributes
                      ?.meeting_link
                  }
                  style={{
                    color: "#fff",
                  }}
                  id="bookaCall"
                  onClick={this.bookACall}
                >
                  Book a call
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
const SummaryStepWithRouter = withRouter(SummaryStep);
const SummaryStepAlertBox = withAlertBox(SummaryStepWithRouter);
const SummaryStepLoader = withLoader(SummaryStepAlertBox);
const SummaryStepToast = withToast(SummaryStepLoader);
const SummaryStepWithDialog = withDialog(SummaryStepToast);

export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    ...templateStyle(theme),
    accordionGrid: {
      flexGrow: 0,
      maxWidth: "35%",
      flexBasis: "35%",
      margin: "2rem 1%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "97%",
        flexBasis: "97%",
        margin: "2rem 1.5%",
      },
    },
    root: {
      width: "100%",
      maxHeight: "calc(100vh - 190px)",
      overflowY: "scroll",
    },
    selectButton: {
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "25px",
      padding: "10px 20px",
      fontWeight: 700,
      textAlign: "center",
      display: "block",
      textTransform: "inherit",
      margin: "15px auto 10px ",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    confirmButton: {
      textTransform: "inherit",
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "25px",
      padding: "10px 20px",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  })
)(withConfirmBox(SummaryStepWithDialog));
