//@ts-nocheck
import React from "react";
import bulbIcon from "../../assets/13b944b8c44d78a19696d4183e2f4b0edab1b89f.png";
import WarningIcon from "@material-ui/icons/Warning";
import { Typography, Grid, withStyles, createStyles, makeStyles, IconButton, Button, Snackbar } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../../components/src/withSnackBar.Web";
import Tooltip from "@material-ui/core/Tooltip";
import withLoader, { withLoaderProps } from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import PackageDetail from "./PackageDetail.web";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import {
  packageSelect,
  accountingSuccess,
  businessBundle,
  businessTaxAdvisory,
  irsRapidRelief,
  newBusinessIncubator,
  payrollSuccess,
  personalBundle,
  reportingOnly,
  customPackage,
} from "../assets";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import StarIcon from "@material-ui/icons/Star";
import { formatCurrency } from "../CustomPackage/CustomPackageCreationSelectFeaturesPage.web";
import { DialogProps } from "../../../../components/src/DialogContext";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    selectedPackageList: [];
    // Customizable Area Start
    // Customizable Area End
  };

const QuizTooltip = withStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: "#fff",
    },
    tooltip: {
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
      padding: "24px",
      fontFamily: "Lato",
      fontSize: "1.2rem",
      backgroundColor: "#FEFEFE",
      borderRadius: "12px",
    },
  })
)(Tooltip);

const useCustomColorTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: (props) => {
      return props.color ? props.color : "#FCC65A";
    },
    marginLeft: "-1.20em!important",
  },
  popper: {
    width: "35%",
  },
  tooltip: {
    fontFamily: "Lato",
    fontSize: "0.8rem",
    fontWeight: 600,
    backgroundColor: "#fff",
    color: (props) => {
      return props.color ? props.color : theme.palette.text.blue2;
    },
    border: (props) => {
      return props.color ? `1px solid ${props.color}` : `1px solid ${theme.palette.text.blue2}`;
    },
    maxWidth: 1200,
    borderRadius: "8px",
  },
}));

function CustomColorTooltip(props: TooltipProps) {
  const classes = useCustomColorTooltipStyles(props);

  return <Tooltip classes={classes} {...props} />;
}

const QuizDialog = ({ classes }) => {
  return (
    <Grid container justifyContent="space-between" className={classes.dialogBox}>
      <Grid xs={8}>
        <Typography className={classes.tooltipHeading}>Need help selecting a package for this client?</Typography>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center" xs={4}>
        <img className={classes.bulbIcon} src={bulbIcon} />
      </Grid>
    </Grid>
  );
};

export class PackageList extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.props.getPackages();
    }
  }

  openDialogDetail = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          name: data.name,
          logo: this.getPackageImage(data.name),
          description: data.description,
          price: data.price,
          id: data.id,
          selectedCurrentPackage: this.props.selectedCurrentPackage,
          package: data.package,
        },
        renderedComponent: PackageDetail,
        withCustomDialog: false,
      })
      .then((pkg) => {
        if (pkg) {
          this.selectCurrentPackage(pkg);
        }
      });
  };
  selectCurrentPackage = (pkg) => {
    const slctPkg = pkg?.attributes?.custom_package_managements?.data || pkg
    this.props.setState(
      {
        selectedCurrentPackage: slctPkg,
      },
      () => {
        this.props.setSelectedPackageList(this.props.selectedCurrentPackage);
      }
    );
  };

  getPackageImage = (packageName) => {
    switch (packageName) {
      case "New Business Incubator": {
        return newBusinessIncubator;
      }
      case "Business Bundle": {
        return businessBundle;
      }
      case "Business Tax & Advisory": {
        return businessTaxAdvisory;
      }
      case "Accounting Success Package": {
        return accountingSuccess;
      }
      case "Payroll Success Package": {
        return payrollSuccess;
      }
      case "Personal Bundle": {
        return personalBundle;
      }
      case "IRS Rapid Relief Package": {
        return irsRapidRelief;
      }
      case "Reporting-Only Package (Individual)": {
        return reportingOnly;
      }
      case "Reporting-Only Package (Business)": {
        return reportingOnly;
      }
      default: {
        return packageSelect;
      }
    }
  };

  

  getPackagePrices = (pkg) => {
    if (pkg?.attributes?.custom_package_managements?.data?.id) {
      return `$ ${(formatCurrency.format(Math.round(pkg?.attributes?.custom_package_managements?.data?.attributes?.payment_term?.total_amount)))}`;
    } else {
      return `$ ${formatCurrency.format(Math.round(pkg?.attributes?.total_contract))}`;
    }
  };

  getPackageCardClass = (outerPkg) => {
    const package_ids = this.props.selectedPaymentTerms.map((aa) => {
      return aa.attributes.package_id;
    });
    const { classes } = this.props;
    if (package_ids.indexOf(+outerPkg.id) > -1) {
      return classes.disabledPackageCard;
    } else {
      if (this.props.selectedCurrentPackage?.id === outerPkg?.id) {
        return classes.selectedPackageCard;
      } else {
        return classes.packageCard;
      }
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container alignItems="stretch">
            <Grid item xs={12} container>
              <Grid item xs={12} container alignItems="center">
                <CustomTypography className={classes.headingText}>Package Overview</CustomTypography>
                <CustomColorTooltip
                  title="Client roadmaps can only have a total of 3 options. Options are comprised of a package and its payment. If you want more than one payment term for a single package it will be shown as two options on the Client Roadmap. Example: Business Bundle with monthly payments, Business Bundle with quarterly payments would equal two options on the Client Roadmap. This would leave you with only one space available to add an additional package or payment option."
                  interactive
                  placement="right-end"
                  color={theme.palette.primary.main}
                >
                  <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
                    <InfoOutlinedIcon
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: "1.3rem",
                      }}
                    />
                  </IconButton>
                </CustomColorTooltip>
              </Grid>
              <Grid item xs={12}>
                <CustomTypography className={classes.headingHelper}>Select up to three packages to include in your Client Roadmap.</CustomTypography>
              </Grid>
            </Grid>

            <Snackbar
              open={this.props.isErrorPresent}
              autoHideDuration={6000}
              onClose={(reason) => {
                if (reason === "clickaway") {
                  return;
                }
                this.props.setState({
                  isErrorPresent: false,
                });
              }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              style={{
                marginTop: "80px",
              }}
            >
              <Grid item md={6} xs={12}>
                <div className={classes.topRightYellowBox}>
                  <WarningIcon />
                  <Typography color="#8C8730" align="center">
                    Client roadmap can only have a total of 3 options. Options are comprised of a package and its' payment term. If you want more than one
                    payment term for a single package it will be shown as two options on the Client Roadmap.
                  </Typography>
                </div>
              </Grid>
            </Snackbar>
          </Grid>
        </Grid>

        {/* DEFAULT PACKAGES */}

        <Grid item xs={12}>
          <Grid container justifyContent="space-between" className={classes.body}>
            {this.props.packageList.map((m) => {
              return (
                <CustomGrid key={m.id} item xs={12} md={4} width="31.5%" margin="0 1.5% 0 0">
                  <Grid item container className={this.getPackageCardClass(m)} xs={12} md={12}>
                    <Grid container item md={4} className={classes.gridSpacing}>
                      <Grid item xs={12}>
                        <img className={classes.imageBox} src={this.getPackageImage(m?.attributes?.name)} />
                      </Grid>
                    </Grid>
                    <Grid item xs={8} className={classes.gridSpacing}>
                      <Typography className={classes.boxTitle} color="secondary" gutterBottom>
                        {m?.attributes?.name}
                      </Typography>
                      <Typography className={classes.descriptionText} color="textPrimary">
                        {m?.one_liner_description}
                      </Typography>
                      <Typography
                        variant="h4"
                        color="secondary"
                        display="inline"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {this.getPackagePrices(m)}
                        <Typography color="textPrimary" display="inline" className={classes.perUnitText}>
                          {" "}
                          per unit
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="space-between"
                      className={classes.gridSpacing}
                      style={{
                        marginTop: "1.5rem",
                      }}
                    >
                      <Grid item xs={8} container alignItems="center">
                        <Button
                          size="small"
                          variant="contained"
                          id="selectPackage"
                          color="primary"
                          disabled={this.props.selectedPaymentTerms.length == 3}
                          onClick={this.selectCurrentPackage.bind(this, m)}
                          style={{
                            height: "2.5rem",
                            borderRadius: "8px",
                            width: "40%",
                          }}
                        >
                          {"Select"}
                        </Button>
                        {this.props.recommendedPackages.includes(m?.id) ? (
                          <>
                            <StarIcon
                              style={{
                                color: "red",
                                margin: "0 0.5rem 0 0.75rem",
                              }}
                            />
                            <Typography
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                              }}
                            >
                              Recommended
                            </Typography>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={4} container justifyContent="flex-end">
                        <IconButton
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          id="dialogDetail"
                          onClick={
                            this.openDialogDetail.bind(this,{
                              name: m?.attributes?.name,
                              description: m?.attributes?.description,
                              price: m?.attributes?.total_fees,
                              id: m?.id,
                              package: m,
                            })
                          }
                          aria-label="delete"
                        >
                          <InfoOutlinedIcon style={{ color: "#54A4A6" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomGrid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            startIcon={<ControlPointIcon size="small" />}
            fullWidth
            onClick={() => {
              this.props.createCustomPackage();
            }}
            variant="contained"
          >
            Create Custom Package
          </Button>
        </Grid>

        {/* CUSTOM PACKAGES */}

        <Grid item xs={12}>
          <Grid container className={classes.body}>
            {this.props.customPackages.map((customP) => {
              return (
                <CustomGrid key={customP.id} item xs={12} md={4} width="31.5%" margin="0 1.5% 0 0">
                  <Grid item container className={this.getPackageCardClass(customP)} xs={12} md={12}>
                    <Grid container item md={4} className={classes.gridSpacing}>
                      <Grid item xs={12}>
                        <img className={classes.imageBox} src={customPackage} />
                      </Grid>
                    </Grid>
                    <Grid item xs={8} className={classes.gridSpacing}>
                      <Typography className={classes.boxTitle} color="secondary" gutterBottom>
                        {customP?.attributes?.name}
                      </Typography>
                      <Typography className={classes.descriptionText} color="textPrimary">
                        {customP?.attributes?.description}
                      </Typography>
                      <Typography
                        variant="h4"
                        color="secondary"
                        display="inline"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  roundingMode:'ceil'
                        }).format(customP?.attributes?.payment_term?.total_amount || 0)}{" "}
                        <Typography color="textPrimary" display="inline" className={classes.perUnitText}>
                          {" "}
                          per unit
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="space-between"
                      className={classes.gridSpacing}
                      style={{
                        marginTop: "1.5rem",
                      }}
                    >
                      <Grid item xs={3}>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={this.props.selectedPaymentTerms.length == 3}
                          onClick={this.selectCurrentPackage.bind(this, customP)}
                          style={{
                            height: "2.5rem",
                            borderRadius: "8px",
                          }}
                        >
                          {"Select"}
                        </Button>
                      </Grid>
                      <Grid item xs={4} container justifyContent="flex-end">
                        <IconButton
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          onClick={() => {
                            this.openDialogDetail({
                              name: customP?.attributes?.name,
                              description: customP?.attributes?.description,
                              price: customP?.attributes?.payment_term?.total_amount,
                              id: customP?.id,
                              package: customP,
                            });
                          }}
                          aria-label="delete"
                        >
                          <InfoOutlinedIcon style={{ color: "#54A4A6" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomGrid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.bottomSection}>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item md={2} xs={12} container justifyContent="flex-end">
              <QuizTooltip title={<QuizDialog classes={classes} />} arrow placement="top-start">
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.openTakeQuiz({});
                  }}
                  variant="outlined"
                >
                  {this.props.getQuizText()}
                </Button>
              </QuizTooltip>
            </Grid>
            <Grid item md={2} xs={12} container justifyContent="flex-end">
              <Button
                color="primary"
                onClick={() => {
                  this.props.moveToRoadCustomizationPage();
                }}
                variant="contained"
                disabled={this.props.selectedPaymentTerms.length <= 0}
              >
                Save & Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const PackageListWithRouter = withRouter(PackageList);
const PackageListAlertBox = withAlertBox(PackageListWithRouter);
const PackageListLoader = withLoader(PackageListAlertBox);
const PackageListToast = withToast(PackageListLoader);
const PackageListWithDialog = withDialog(PackageListToast);

export default withStyles(
  (theme) =>
    createStyles({
      body: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down("md")]: {
          margin: 0,
          marginBottom: theme.spacing(1.4),
        },
      },
      packageCard: {
        padding: theme.spacing(1),
        margin: "1.5rem 0",
        borderRadius: "1rem",
        backgroundColor: "#ffffff",
        boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      },
      disabledPackageCard: {
        padding: theme.spacing(1),
        margin: "1.5rem 0",
        borderRadius: "1rem",
        backgroundColor: "#ccc",
        pointerEvents: "none",
        opacity: 0.4,
        boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      },
      selectedPackageCard: {
        padding: theme.spacing(1),
        margin: "1.5rem 0",
        borderRadius: "1rem",
        backgroundColor: "#F1F1F1",
        boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
      },
      boxTitle: {
        fontSize: "1rem",
        fontWeight: 700,
      },
      descriptionText: {
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
        marginBottom: "0.75rem",
      },
      imageBox: {
        width: "calc(100% - 8px)",
        backgroundColor: "#f9f9f9",
        backgroundPosition: "center",
        borderRadius: "0.75rem",
        padding: "1rem",
      },
      dialogBox: {
        width: "250px",
      },
      tooltipHeading: {
        color: theme.palette.text.blue2,
        fontSize: "1rem",
      },
      bulbIcon: {
        width: "45px",
        height: "50px",
      },
      topRightYellowBox: {
        backgroundColor: "#FEF9A2",
        borderRadius: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(2),
        "& p": {
          fontSize: "0.9rem",
        },
        "& svg": {
          fill: "#BFB428",
          fontSize: "1.875rem",
        },
      },
      gridSpacing: {
        padding: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(0.5),
        },
      },
      bottomSection: {
        [theme.breakpoints.up("md")]: {
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(2),
        },
      },
      perUnitText: {
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
      },
      headingText: {
        fontFamily: "Lato",
        fontSize: "1.75rem",
        color: theme.palette.primary.main,
        letterSpacing: 1.5,
        marginBottom: "0.5rem",
      },
      headingHelper: {
        fontFamily: "Lato",
        fontSize: "1rem",
        color: theme.palette.text.secondary,
      },
    }),
  { withTheme: true }
)(withConfirmBox(PackageListWithDialog));
