const BEGININNG_SPACE = /^\s+/g;
const NUMBERS = /\d/g;

export const nameMask = (e) => {
  let value = e.target.value;
  value = value.replace(BEGININNG_SPACE, "");
  value = value.replace(NUMBERS, "");
  value = value.replace(/[^a-zA-Z ]/g, "");
  e.target.value = value;
  return e;
};

export const spaceMask = (e) => {
  let value = e.target.value;
  value = value.replace(BEGININNG_SPACE, "");
  e.target.value = value;
  return e;
};

export const noSpaceMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\s+/g, "");
  value = value.trim();
  e.target.value = value;
  return e;
};


export const mobileNumberMask = (e) => {
  let value = e.target.value;
  value = value.replace(BEGININNG_SPACE, "");
  value = value.replace(/[^\d()-+]/g, "");
  e.target.value = value;
  return e;
};

export const addressMask = (e) => {
  let value = e.target.value;
  value = value.replace(/^\s+/g, "");
  e.target.value = value;
  return e;
};

export const hexMask = (e) => {
  let value = e.target.value;

  // Only allow # as first character
  if (value.length === 1 && value !== "#") {
    value = "";
  } else {
    value = value.replace(/[^0-9a-fA-F#]/g, "");
    // Remove any # symbols after the first character
    if (value.indexOf("#") > 0) {
      value = value.replace(/#/g, "");
      value = "#" + value;
    }
    // Truncate value to 7 characters
    if (value.length > 7) {
      value = value.slice(0, 7);
    }
  }

  e.target.value = value;
  return e;
};
