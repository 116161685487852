//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import { DialogFooter } from "./RoadmapCancelDialog.web";
import { dialogStyle } from "./RoadmapDialogStyle.web";

class StartRoadmapDeleteDialog extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        className={classes.rootContainer}
      >
        <Grid item xs={12}>
          <Typography color="primary" variant="h1">
            Go Back
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h4">
            This will completely erase your Roadmap progress and everything will
            be lost. <br /> Do you want to go to Home Page?
          </Typography>
        </Grid>
        <DialogFooter
          closeDialog={this.closeDialog.bind(this)}
          classes={classes}
        />
      </Grid>
    );
  }
}

const StartRoadmapDeleteDialogWithRouter = withRouter(StartRoadmapDeleteDialog);
const StartRoadmapDeleteDialogWithToast = withToast(
  StartRoadmapDeleteDialogWithRouter
);
const StartRoadmapDeleteDialogWithLoader = withLoader(
  StartRoadmapDeleteDialogWithToast
);
const StartRoadmapDeleteDialogWithAlertBox = withAlertBox(
  StartRoadmapDeleteDialogWithLoader
);

export default withStyles((theme) =>
  createStyles({
    ...dialogStyle(theme)
  })
)(StartRoadmapDeleteDialogWithAlertBox);
