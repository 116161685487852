//@ts-nocheck
import React from "react";
import { Props } from "./AboutUsSurveyStepController.web";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import Fade from "@material-ui/core/Fade";
import { surveyStyles, aboutUsStyle } from "./surveyStyles";
import TaxFillingComponent from "./SurveyTaxFilling.web";
import BusinessCompnent from "./BusinessDetail.web";
import AboutUsComponent from "./CfsClientDetail.web";
import AccountingComponent from "./CFSAccounting.web";
const ALERADY_EXISTING_CLIENT = "I am an existing client of {Practice}";

export class AboutUsSurveyStep extends React.Component {
  constructor(props: Props) {
    super(props);
  }
  checkIfNumber = (e, setFieldValue) => {
    const regex = /\d+(\.\d\d?)?/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      let t = e.target.value;
      t =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
          : t;
      let nf = new Intl.NumberFormat("en-US");
      return nf.format(t?.replace(/,/g, "")) === "0"
        ? ""
        : nf.format(t?.replace(/,/g, ""));
    }
  };

  getStep3Content = (classes) => {
    const step1 = this.props.step1Selection?.attributes?.description;
    const taxPayerType = this.props.taxPayerType;
    console.log(step1, taxPayerType, "step1,taxPayerType");
    const callbackToPass = () => {
      const newStep = this.props.getStepno3byStep1AndStep2();
      if (newStep) {
        this.props.getQuestionAndOptionsByStepNo(newStep);
      }
    };

    const textFieldOnKeyPressHandler = (event) => {
      if (
        !(
          (event.charCode != 8 && event.charCode == 0) ||
          (event.charCode >= 48 && event.charCode <= 57)
        )
      ) {
        event.preventDefault();
      }
    };

    const textFieldOnChangeHandler = (event) => {
      this.props.setValueByDynamicName(
        "totalAnnualIncome",
        this.checkIfNumber(event)
      );
    };

    const gotoBackStepHandler = () => {
      this.props.setCurrentAboutUsStep(2, callbackToPass);
    };

    const handleNext = () => this.props.setCurrentAboutUsStep(4);
    if (step1 === ALERADY_EXISTING_CLIENT && taxPayerType === "individual") {
      return (
        <TaxFillingComponent
          {...this.props}
          gotoBackStep={gotoBackStepHandler}
          gotoNextStep={handleNext}
          titleMessage={"Tell us about your most recent tax filling"}
        />
      );
    } else if (
      step1 !== ALERADY_EXISTING_CLIENT &&
      taxPayerType === "individual"
    ) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={4} xs={11}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography className={classes.pageHeading} gutterBottom>
                  What is your Annual Household Income?
                </Typography>
                <Typography className={classes.fieldPageSubHeading}>
                  In order to show you the right feature options, we need to
                  know your annual income
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  color="primary"
                  variant="standard"
                  fullWidth
                  id="totalAnnualIncome"
                  value={this.props.totalAnnualIncome}
                  onKeyPress={textFieldOnKeyPressHandler}
                  onChange={textFieldOnChangeHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography className={classes.dollarSign}>
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    inputProps: { min: 1, pattern: "[1-9]d*" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.includeText}>
                  Include all sources, wages, investments, part-time gigs for
                  you and anyone in your household.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.buttonContainer}
                style={{
                  padding: 0,
                }}
              >
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                      color="default"
                      style={{ marginRight: "16px" }}
                      startIcon={<ArrowBackOutlinedIcon />}
                      variant="text"
                      className={[classes.backButton, "statucBackButton"].join(
                        " "
                      )}
                      onClick={gotoBackStepHandler}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      disabled={!this.props.totalAnnualIncome}
                      endIcon={<ArrowForwardOutlinedIcon />}
                      onClick={this.props.setCurrentAboutUsStep.bind(
                        this,
                        4,
                        undefined
                      )}
                      variant="text"
                      className={[classes.nextButton, "statucNextButton"].join(
                        " "
                      )}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (
      step1 === ALERADY_EXISTING_CLIENT &&
      taxPayerType === "business"
    ) {
      return (
        <BusinessCompnent
          {...this.props}
          gotoBackStep={gotoBackStepHandler}
          gotoNextStep={this.props.setCurrentAboutUsStep.bind(
            this,
            4,
            undefined
          )}
          titleMessage={"Provide any updates about your business."}
        />
      );
    } else if (
      step1 !== ALERADY_EXISTING_CLIENT &&
      taxPayerType === "business"
    ) {
      return (
        <BusinessCompnent
          {...this.props}
          gotoBackStep={gotoBackStepHandler}
          gotoNextStep={this.props.setCurrentAboutUsStep.bind(
            this,
            4,
            undefined
          )}
          titleMessage={"Tell us a little bit about your business."}
        />
      );
    }
  };
  getStep4Content = (classes) => {
    const step1 = this.props.step1Selection?.attributes?.description;
    const taxPayerType = this.props.taxPayerType;
    if (step1 === ALERADY_EXISTING_CLIENT && taxPayerType === "individual") {
      return (
        <AboutUsComponent
          {...this.props}
          gotoBackStep={this.props.setCurrentAboutUsStep.bind(
            this,
            3,
            undefined
          )}
          gotoNextStep={this.props.handleNext.bind(this)}
        />
      );
    } else if (
      step1 !== ALERADY_EXISTING_CLIENT &&
      taxPayerType === "individual"
    ) {
      const gotoBackStep4 = () => {
        this.props.setCurrentAboutUsStep(3, () => {
          const newStep = this.props.getStepno3byStep1AndStep2();
          if (newStep) {
            this.props.getQuestionAndOptionsByStepNo(newStep);
          }
        });
      };
      return (
        <TaxFillingComponent
          {...this.props}
          gotoBackStep={gotoBackStep4}
          gotoNextStep={this.props.setCurrentAboutUsStep.bind(
            this,
            5,
            undefined
          )}
          titleMessage={"Tell us about your most recent tax filing"}
        />
      );
    } else if (
      (step1 === ALERADY_EXISTING_CLIENT && taxPayerType === "business") ||
      (step1 !== ALERADY_EXISTING_CLIENT && taxPayerType === "business")
    ) {
      return (
        <AccountingComponent
          {...this.props}
          gotoBackStep={this.props.setCurrentAboutUsStep.bind(
            this,
            3,
            undefined
          )}
          gotoNextStep={this.props.setCurrentAboutUsStep.bind(
            this,
            5,
            undefined
          )}
          titleMessage={"Tell us about your most recent tax filing"}
        />
      );
    }
  };
  getStep5Content = (classes) => {
    const step1 = this.props.step1Selection?.attributes?.description;
    const taxPayerType = this.props.taxPayerType;
    if (step1 !== ALERADY_EXISTING_CLIENT && taxPayerType === "individual") {
      return (
        <AboutUsComponent
          {...this.props}
          gotoBackStep={this.props.setCurrentAboutUsStep.bind(
            this,
            4,
            undefined
          )}
          gotoNextStep={this.props.handleNext.bind(this)}
        />
      );
    } else if (
      step1 === ALERADY_EXISTING_CLIENT &&
      taxPayerType === "business"
    ) {
      return (
        <TaxFillingComponent
          {...this.props}
          gotoBackStep={this.props.setCurrentAboutUsStep.bind(
            this,
            4,
            undefined
          )}
          gotoNextStep={this.props.setCurrentAboutUsStep.bind(
            this,
            6,
            undefined
          )}
          titleMessage={
            "Provide us with any updates about your next tax filing"
          }
        />
      );
    } else if (
      step1 !== ALERADY_EXISTING_CLIENT &&
      taxPayerType === "business"
    ) {
      return (
        <TaxFillingComponent
          {...this.props}
          gotoBackStep={this.props.setCurrentAboutUsStep.bind(
            this,
            4,
            undefined
          )}
          gotoNextStep={this.props.setCurrentAboutUsStep.bind(
            this,
            6,
            undefined
          )}
          titleMessage={"Tell us about your most recent tax filing"}
        />
      );
    }
  };
  getStep6Content = (classes) => {
    const step1 = this.props.step1Selection?.attributes?.description;
    const taxPayerType = this.props.taxPayerType;
    if (
      (step1 === ALERADY_EXISTING_CLIENT && taxPayerType === "business") ||
      (step1 !== ALERADY_EXISTING_CLIENT && taxPayerType === "business")
    ) {
      return (
        <AboutUsComponent
          {...this.props}
          gotoBackStep={this.props.setCurrentAboutUsStep.bind(
            this,
            5,
            undefined
          )}
          gotoNextStep={this.props.handleNext.bind(this)}
        />
      );
    }
  };

  async componentDidMount() {
    const step = this.props.currentAboutUsStep > 2 ? 5 : 1;
    this.props.getQuestionAndOptionsByStepNo(step);
  }

  getSideContentOfStep2 = () => {
    const ALERADY_EXISTING_CLIENT = `I am an existing client of {Practice}`;
    const WORKING_WITH_ANOTHER_ACCOUNTANT =
      "I am currently working with another tax professional/accountant";
    const WORKING_WITH_OWN = "I handle my own tax and accounting work";

    if (this.props.step1Selection && this.props.step2Selection) {
      const step1 = this.props.step1Selection?.attributes?.description;
      let content = {
        title: "",
        subTitle: "",
      };
      switch (step1) {
        case ALERADY_EXISTING_CLIENT:
          content.title =
            "We always strive to do our best but realize no one is perfect.";
          content.subTitle =
            "If there is a way we can refocus our efforts, we want to know.";
          break;
        case WORKING_WITH_ANOTHER_ACCOUNTANT:
          content.title =
            "Taxation affects every dollar you make. Even though you might have had your existing tax relationship for a long time, this is such an important part of your financial success.";
          content.subTitle =
            "The only way to get what you want is to work with a tax pro that aligns with your needs.";
          break;
        case WORKING_WITH_OWN:
          content.title =
            "Doing your own taxes has a time and place, and we even recommend it for some clients. ";
          content.subTitle =
            "However, if you're missing oppotunities or paying more tax than you should be, perhaps your situation no longer aligns with going about it on your own. ";
          break;
      }
      return content;
    }
  };
  handleStep1 = () => {
    this.props.setCurrentAboutUsStep(1, () => {
      this.props.getQuestionAndOptionsByStepNo(2);
    });
  };
  handleStep2 = (m) => {
    this.props.setCurrentAboutUsStep(2, () => {
      const newStep = this.props.getStepno3byStep1AndStep2();
      if (newStep) {
        this.props.getQuestionAndOptionsByStepNo(newStep);
      }
    });
  };
  handleStep3 = (m) => {
    this.props.setCurrentAboutUsStep(3);
  };
  handleStep2Back = () => {
    this.props.setCurrentAboutUsStep(0, () => {
      this.props.getQuestionAndOptionsByStepNo(1);
    });
  };
  handleStep3Back = () => {
    this.props.setCurrentAboutUsStep(1, () => {
      this.props.getQuestionAndOptionsByStepNo(2);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.currentAboutUsStep === 0 && (
          <Grid container justifyContent="center">
            <Grid xs={7} item>
              <Typography
                style={{ fontSize: "1.7rem" }}
                className={[classes.pageHeading, classes.bottomMargin2rem]}
              >
                {this.props?.stepContent?.description}
              </Typography>
            </Grid>
            <Grid xs={7} item>
              <Grid container justifyContent="space-between">
                {this.props?.stepContent?.options?.data?.map((option) => {
                  return (
                    <Grid
                      key={option.id}
                      item
                      xs={12}
                      md={3}
                      className={
                        this.props.step1Selection?.id === option.id
                          ? [
                              classes.selectedPackageCard1,
                              "staticPakcageCard",
                              classes.minHeight12rem,
                            ]
                          : [
                              classes.packageCard1,
                              "staticPakcageCard",
                              classes.minHeight12rem,
                            ].join(" ")
                      }
                      onClick={this.props.setStep1Selection.bind(this, option)}
                    >
                      <Typography
                        className={classes.cardDescription}
                        color="textPrimary"
                      >
                        {option.attributes.displaydescription ||
                          option.attributes.description}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={7} className={classes.buttonContainer}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    color="default"
                    style={{ marginRight: "16px" }}
                    startIcon={<ArrowBackOutlinedIcon />}
                    variant="text"
                    onClick={this.props.history.goBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    endIcon={<ArrowForwardOutlinedIcon />}
                    disabled={!this.props.step1Selection}
                    onClick={this.handleStep1}
                    variant="text"
                    className={[classes.nextButton, "statucNextButton"].join(
                      " "
                    )}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {this.props.currentAboutUsStep === 1 && (
          <Fade in={this.props.currentAboutUsStep === 1} timeout={3000}>
            <Grid container justifyContent="center">
              <Grid xs={7} item>
                <Typography className={classes.pageHeading} gutterBottom>
                  {this.props?.stepContent?.description}
                </Typography>
                <Typography
                  className={[classes.pageSubHeading, classes.bottomMargin1rem]}
                >
                  Select the one that matches your situation the best
                </Typography>
              </Grid>
              <Grid xs={7} item>
                <Grid container justifyContent="space-between">
                  {this.props?.stepContent?.options?.data?.map((option) => {
                    return (
                      <Grid
                        key={option.id}
                        item
                        xs={12}
                        md={4}
                        className={
                          this.props.step2Selection?.id === option.id
                            ? [
                                classes.selectedPackageCard1,
                                classes.minHeight12rem,
                                "staticPakcageCard",
                              ].join(" ")
                            : [
                                classes.packageCard1,
                                classes.minHeight12rem,
                                "staticPakcageCard",
                              ].join(" ")
                        }
                        onClick={this.props.setStep2Selection.bind(
                          this,
                          option
                        )}
                      >
                        <Typography
                          className={classes.cardDescription}
                          color="textPrimary"
                        >
                          {option.attributes.displaydescription}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={7} className={classes.buttonContainer}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                      color="default"
                      style={{ marginRight: "16px" }}
                      startIcon={<ArrowBackOutlinedIcon />}
                      variant="text"
                      onClick={this.handleStep2Back.bind(this)}
                      className={[classes.backButton, "statucBackButton"].join(
                        " "
                      )}
                    >
                      Back
                    </Button>
                    <Button
                      color="primary"
                      endIcon={<ArrowForwardOutlinedIcon />}
                      disabled={!this.props.step2Selection}
                      onClick={this.handleStep2}
                      variant="text"
                      className={[classes.nextButton, "statucNextButton"].join(
                        " "
                      )}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        )}
        {this.props.currentAboutUsStep === 2 && (
          <Fade in={this.props.currentAboutUsStep === 2} timeout={3000}>
            <Grid container justifyContent="space-around">
              <Grid sm={3} xs={11} item>
                <Box my={12}>
                  <Typography
                    gutterBottom
                    variant="h2"
                    className={classes.sidebarHeading}
                    color="primary"
                  >
                    Education
                  </Typography>
                  <Typography className={classes.sidebarDescription}>
                    {this.getSideContentOfStep2().title}
                  </Typography>
                  <Typography className={classes.sidebarDescription}>
                    {this.getSideContentOfStep2().subTitle}
                  </Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid xs={8} item>
                <Grid container justifyContent="center">
                  <Grid item xs={9}>
                    <Typography className={classes.pageHeading} gutterBottom>
                      {this.props.getHeadingOfStep3(
                        this.props.getStepno3byStep1AndStep2()
                      )}
                    </Typography>
                    <Typography
                      className={[
                        classes.pageSubHeading,
                        classes.bottomMargin1rem,
                      ]}
                    >
                      Select all that apply
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container justifyContent="flex-start">
                      {this.props?.stepContent?.options?.data?.map((option) => {
                        return (
                          <Grid
                            key={option.id}
                            item
                            xs={12}
                            md={4}
                            className={
                              this.props.step3Selection
                                ?.map((step) => {
                                  return step?.id;
                                })
                                .filter((opt) => {
                                  return opt === option.id;
                                }).length > 0
                                ? [
                                    classes.selectedPackageCard1,
                                    classes.minHeight10rem,
                                    "staticPakcageCard",
                                  ]
                                : [
                                    classes.packageCard1,
                                    classes.minHeight10rem,
                                    "staticPakcageCard",
                                  ]
                            }
                            onClick={this.props.setStep3Selection.bind(
                              this,
                              option
                            )}
                          >
                            <Typography
                              className={classes.cardDescription}
                              color="textPrimary"
                            >
                              {option.attributes.displaydescription}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={9} className={classes.buttonContainer}>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={12} container justifyContent="flex-end">
                        <Button
                          color="default"
                          style={{ marginRight: "16px" }}
                          startIcon={<ArrowBackOutlinedIcon />}
                          variant="text"
                          className={[
                            classes.backButton,
                            "statucBackButton",
                          ].join(" ")}
                          onClick={this.handleStep3Back}
                        >
                          Back
                        </Button>
                        <Button
                          color="primary"
                          disabled={!this.props.step3Selection}
                          endIcon={<ArrowForwardOutlinedIcon />}
                          onClick={this.handleStep3}
                          variant="text"
                          className={[
                            classes.nextButton,
                            "statucNextButton",
                          ].join(" ")}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        )}
        {this.props.currentAboutUsStep === 3 && this.getStep3Content(classes)}
        {this.props.currentAboutUsStep === 4 && this.getStep4Content(classes)}
        {this.props.currentAboutUsStep === 5 && this.getStep5Content(classes)}
        {this.props.currentAboutUsStep === 6 && this.getStep6Content(classes)}
      </>
    );
  }
}
const AboutUsSurveyStepWithRouter = withRouter(AboutUsSurveyStep);
const AboutUsSurveyStepAlertBox = withAlertBox(AboutUsSurveyStepWithRouter);
const AboutUsSurveyStepLoader = withLoader(AboutUsSurveyStepAlertBox);
const AboutUsSurveyStepToast = withToast(AboutUsSurveyStepLoader);
const AboutUsSurveyStepWithDialog = withDialog(AboutUsSurveyStepToast);

export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    ...aboutUsStyle(theme),
  })
)(withConfirmBox(AboutUsSurveyStepWithDialog));
