import React from "react";

export type TernaryCheckProps = {
  condition: boolean;
  trueComponent: React.ReactNode;
  falseComponent: React.ReactNode | null;
};

const TernaryCheck = ({ condition, trueComponent, falseComponent }: TernaryCheckProps) => {
  return condition ? trueComponent : falseComponent;
};

export default TernaryCheck;

type CalculateTernaryProps = {
  condition: boolean;
  trueValue: any;
  falseValue: any;
};

export const calculateTernary = ({ condition, trueValue, falseValue }: CalculateTernaryProps) => (
  condition ? trueValue : falseValue
);