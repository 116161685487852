//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";

export class ArchivedClientDialog extends React.Component {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ width: "25vw", padding: "1rem" }}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            align="center"
            className={classes.dialogHeading}
          >
            Are you sure you want <br /> to archive this client?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} className={classes.halfWidthGrid}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                id="noButton"
                onClick={this.closeDialog.bind(this, null)}
                className={classes.uploadNowButton}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.halfWidthGrid}>
              <Button
                variant="contained"
                color="primary"
                id="yesButton"
                fullWidth
                onClick={this.closeDialog.bind(this, "Yes")}
                className={classes.uploadNowButton}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ArchivedClientDialogWithRouter = withRouter(ArchivedClientDialog);
const ArchivedClientDialogWithToast = withToast(ArchivedClientDialogWithRouter);
const ArchivedClientDialogWithLoader = withLoader(
  ArchivedClientDialogWithToast
);
const ArchivedClientDialogWithAlertBox = withAlertBox(
  ArchivedClientDialogWithLoader
);
const ArchivedClientDialogWithDialogBox = withDialogBox(
  ArchivedClientDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "1.3rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      marginBottom: "2rem",
      marginTop: "2rem",
      lineHeight: "2rem",
    },
    uploadNowButton: {
      borderRadius: "0.75rem",
      marginBottom: "1.5rem",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
    },
    fieldLabels: {
      color: theme.palette.text.secondary,
      fontFamily: "Nunito Sans",
      fontWeight: 600,
    },
  })
)(ArchivedClientDialogWithDialogBox);
