//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  employee_id: any;
  profile: any;
  employeeProfile: any;
  isEmployee: boolean;
  profileImageAsFile: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProfilePhaseTwoController extends BlockComponent<Props, S, SS> {
  updateProfileApiCallId: string = "";
  getProfileApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      employee_id: null,
      profile: {
        profile_image_url: null,
        first_name: "",
        last_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        job_title: "Choose one",
      },
      employeeProfile: {
        first_name: "",
        last_name: "",
        phone_number: "",
        primary_email: "",
        employee_profile_image_url: null,
      },
      isEmployee: false,
      profileImageAsFile: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  toDataURLProfilePage = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = this.loadedndProfilePage.bind(this, reader, resolve);
    });
  };

  loadedndProfilePage = (reader, resolve) => {
    const base64data = reader.result;
    resolve(base64data);
  };

  dataUrlToFileProfilePage = async (dataUrl: string, fileName: string): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  handleUpdateProfileResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.props.showToast({ type: "success", message: "Profile updated successfully" });
      const profilePictureMessage = new Message(getName(MessageEnum.ProfilePictureUpdatedMsg));
      profilePictureMessage.addData(getName(MessageEnum.ProfilePictureUpdateData), { imageUrl: responseJson?.data?.attributes?.profile_image_url || null, type: "profile_image_url"});
      runEngine.sendMessage(profilePictureMessage.id, profilePictureMessage);
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleGetProfileResposne = async (responseJson) => {
    if (responseJson?.data?.attributes) {

      if(responseJson.data.attributes.profile_image_url) {
        const dataUrl = await this.toDataURLProfilePage(responseJson.data.attributes.profile_image_url);
        const fileData = await this.dataUrlToFileProfilePage(dataUrl, "imageName.jpg");
        this.setState({
          profileImageAsFile: fileData
        });
      }

      this.setState({
        profile: {
          profile_image_url: responseJson.data.attributes.profile_image_url || null,
          first_name: responseJson?.data?.attributes?.first_name,
          last_name: responseJson?.data?.attributes?.last_name,
          primary_email: responseJson?.data?.attributes?.primary_email,
          secondary_email: responseJson?.data?.attributes?.secondary_email || "",
          primary_phone: responseJson?.data?.attributes?.primary_phone || "",
          secondary_phone: responseJson?.data?.attributes?.secondary_phone || "",
          job_title: responseJson?.data?.attributes?.job_title || "Choose one",
        },
      });
      if (responseJson?.data?.attributes?.employee_name !== null && responseJson?.data?.attributes?.employee_permission?.length > 0) {
        this.setState({
          isEmployee: true,
        });
      }
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.props.hideLoader();
  };

  handleGetSingleUserResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && responseJson?.data?.attributes) {

      if(responseJson.data.attributes.employee_profile_image_url) {
        const dataUrl = await this.toDataURLProfilePage(responseJson.data.attributes.employee_profile_image_url);
        const fileData = await this.dataUrlToFileProfilePage(dataUrl, "imageName.jpg");
        this.setState({
          profileImageAsFile: fileData
        });
      }

      this.setState({
        employeeProfile: {
          first_name: responseJson?.data?.attributes?.first_name,
          last_name: responseJson?.data?.attributes?.last_name,
          phone_number: responseJson?.data?.attributes?.phone_number,
          primary_email: responseJson?.data?.attributes?.primary_email,
          employee_profile_image_url: responseJson?.data?.attributes?.employee_profile_image_url
        },
      });
    } else {
      this.props.showToast({ type: "error", message: responseJson?.message });
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleUpdateUserResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
      this.props.showToast({
        type: "success",
        message: "Profile Updated Successfully.",
      });
      const profilePictureMessage = new Message(getName(MessageEnum.ProfilePictureUpdatedMsg));
      profilePictureMessage.addData(getName(MessageEnum.ProfilePictureUpdateData), { imageUrl: responseJson?.data?.attributes?.employee_profile_image_url || null, type: "employee_profile_image_url"});
      runEngine.sendMessage(profilePictureMessage.id, profilePictureMessage);
    } else if (responseJson?.message === "AccessDenied for current user") {
      this.props.showToast({ type: "error", message: responseJson?.message });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.updateProfileApiCallId) {
        this.handleUpdateProfileResponse(responseJson);
      } else if (apiRequestCallId === this.getProfileApiCallId) {
        this.handleGetProfileResposne(responseJson);
      } else if (apiRequestCallId === this.getSingleUserForProfilePageApiCallId) {
        this.handleGetSingleUserResponse(responseJson);
      } else if (apiRequestCallId === this.updateUserApiCallId) {
        this.handleUpdateUserResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }

  updateProfile = (values: any) => {
    this.props.showLoader();

    const headers = {
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileApiCallId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("account[first_name]", values.first_name);
    formData.append("account[last_name]", values.last_name);
    formData.append("account[primary_email]", values.primary_email);
    formData.append("account[secondary_email]", values.secondary_email);
    formData.append("account[primary_phone]", values.primary_phone);
    formData.append("account[secondary_phone]", values.secondary_phone);
    formData.append("account[job_title]", values.job_title);

    if (this.state.profile.profile_image_url?.file) {
      formData.append("account[profile_image]", this.state.profile.profile_image_url.file);
    } else if (this.state.profileImageAsFile) {
      formData.append("account[profile_image]", this.state.profileImageAsFile);
    }

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.profileCustomizationEndpoint}/${this.state.accountId}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProfile = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${"account_block/accounts"}/${this.state.accountId}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSingleUser = (id) => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getSingleUserForProfilePageApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.additionalUserEndpoint}?id=${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateUser = (values: any) => {
    this.props.showLoader();
    const headers = {
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateUserApiCallId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("employee[first_name]", values.first_name);
    formData.append("employee[last_name]", values.last_name);
    formData.append("employee[primary_email]", values.primary_email);
    formData.append("employee[phone_number]", values.phone_number);

    if (this.state.employeeProfile.employee_profile_image_url?.file) {
      formData.append("employee[employee_profile_image]", this.state.employeeProfile.employee_profile_image_url.file);
    } else if (this.state.profileImageAsFile) {
      formData.append("employee[employee_profile_image]", this.state.profileImageAsFile);
    }

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_admin/update_employee?id=${this.state.employee_id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}