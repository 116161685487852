//@ts-nocheck
import React from "react";
import {
  Button,
  withStyles,
  createStyles,
  ButtonProps,
} from "@material-ui/core";
import clsx from "clsx";

type SmallButtonProps = ButtonProps & {
  width?: React.CSSProperties["width"];
  margin?: React.CSSProperties["margin"];
  padding?: React.CSSProperties["padding"];
  backgroundColor?: React.CSSProperties["backgroundColor"];
  children?: React.ReactNode;
};

class SmallButton extends React.Component<SmallButtonProps> {
  constructor(props: SmallButtonProps) {
    super(props);
  }

  render() {
    const { classes, theme, width, margin, padding, backgroundColor, className, ...rest } =
      this.props;

    const classList = [
      classes.root,
      width ? classes.width : "",
      margin ? classes.margin : "",
      padding ? classes.padding : "",
      backgroundColor ? classes.backgroundColor : "",
    ];
    return (
      <Button variant="contained" color="primary" className={clsx(className, classList)} {...rest}>
        {this.props.children}
      </Button>
    );
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      root: {
        height: "auto",
        padding: "0 0.75rem",
        fontSize: "0.9rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "-0.17px",
        borderRadius: "0.5rem"
      },
      width: {
        width: (props) => props?.width,
      },
      margin: {
        margin: (props) => props?.margin,
      },
      padding: {
        padding: (props) => props?.padding,
      },
      backgroundColor: {
        backgroundColor: (props) => props?.backgroundColor,
      }
    }),
  { withTheme: true }
)(SmallButton);
