//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  profile : any;
  todoList: object[],
  selectedTab: string;
  checklists: object[],
  selectedChecklist: object
  client_id : number | null,
  package_management_id : number | null,
  singleClient : any;
  obsUrl: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingCustomizationController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateChecklistApiCallId: string = "";
  getChecklistsApiCallId : string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      profile : {},
      todoList: [{
        label: "Item to-do 1",
        value: "anything",
      }, {
        label: "Item to-do 2",
        value: "anything",
      }, {
        label: "Item to-do 3",
        value: "anything",
      },{
        label: "Item to-do 4",
        value: "anything",
      },{
        label: "Item to-do 5",
        value: "anything",
      }],
      selectedTab: "clientOverview",
      checklists: [],
      selectedChecklist: {
        attributes: {
          checklist_name: "",
          sections: {
            data: [
              {
                attributes: {
                  questions: {
                    data: []
                  }
                }
              }
            ]
          }
        }
      },
      client_id : null,
      package_management_id : null,
      singleClient : {},
      obsUrl: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId === this.updateChecklistApiCallId) {
        this.props.hideLoader();
        this.processUpdatedChecklist(responseJson)
      } else if (apiRequestCallId === this.getChecklistsApiCallId) {
        this.handleCheckListReponse(responseJson);
      } else if(this.sendOnBoardingSurveyApiCallId === apiRequestCallId){
          this.sendOnBoardingSurveyResponse(responseJson)
      } else if(apiRequestCallId == this.createClientForNewPortalApiCallId){
          this.handleCreateClientResponse(responseJson)
      } else if(this.getSingleClientApiCallId === apiRequestCallId){
          this.handleSingleClient(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountId")  
        this.props.history.push("/")
      }
    }
  }
  sendOnBoardingSurveyResponse = (responseJson) => {
    this.props.hideLoader();
    if(responseJson?.survey_url) {
      this.setState({
        obsUrl: responseJson.survey_url
      });
    } else if(responseJson?.message){
    if(responseJson?.message?.toLowerCase()?.indexOf('success') > - 1){
      this.createClientForNewPortal();
    }
    this.props.showToast({
      message : responseJson?.message,
      type : responseJson?.message?.toLowerCase()?.indexOf('success') > - 1 ? 'success' : 'error'
    })
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  }
  handleCreateClientResponse = (responseJson)=>{
    this.props.hideLoader();
    if(responseJson?.message && responseJson?.success){
      this.props.showToast({
        message : responseJson?.message,
        type : 'success'
      })
    } else {
       this.handleApiErrorResponse(responseJson);
    }
  }
  handleSingleClient = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data && responseJson?.data?.attributes) {
      this.setState(
        {
          singleClient: responseJson?.data?.attributes,
        },this.getChecklists
      );
    } else {
      this.handleApiErrorResponse(responseJson);
   }
  };
  getQuestions = (sectionItem) => {
    return sectionItem.attributes.questions?.data || [];    
  }
  
  handleCheckListReponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.length > 0) {
      const checklists = responseJson?.data?.map((checklistItem) => {
        const sections = checklistItem?.attributes?.sections?.data;
        return {
          attributes: {
            ...checklistItem.attributes,
            checklist_name: checklistItem.attributes.checklist_name,
            id: checklistItem.attributes.id,
            sections: {
              data: sections?.map((sectionItem, indexSectionItem) => {
                const questions = this.getQuestions(sectionItem);
                return {
                  attributes: {
                    id: sectionItem.attributes.id,
                    questions: {
                      data: questions?.map((questionItem, indexQuestionItem) => {
                        return {
                          attributes: {
                            checked: questionItem.attributes.checked,
                            client_status: questionItem.attributes.client_status,
                            current_gathered_details: questionItem.attributes.current_gathered_details,
                            document: questionItem.attributes.document,
                            id: questionItem.attributes.id,
                            question_name: questionItem.attributes.question_name,
                            question_rank: indexQuestionItem,
                            request_document: questionItem.attributes.request_document,
                            status: questionItem.attributes.status,
                            question_type : questionItem.attributes.question_type,
                            question_options : questionItem.attributes.question_options,
                          },
                          id: questionItem.id,
                          type: questionItem.type,
                        }
                      }) 
                    },
                    section_name: sectionItem.attributes.section_name,
                    section_rank: indexSectionItem,
                  },
                  id: sectionItem.id,
                  type: sectionItem.type,
                }
              })
            }
          },
          id: checklistItem.id,
          type: checklistItem.type
        }
      })
      this.setState({
        checklists : checklists,
        selectedChecklist: checklists[0]
      },()=>{this.sendOnBoardingSurvey({getObsLink: true})})
    } else {
      this.setState({
        checklists : [],
        selectedChecklist: {
          attributes: {
            sections: {
              data: [
                {
                  attributes: {
                    questions: {
                      data: []
                    }
                  }
                }
              ]
            }
          }
        }
      },()=>{
        this.handleApiErrorResponse(responseJson);  
      })
      
    } 
  }
  handleApiErrorResponse = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  processUpdatedChecklist = (responseJson)=>{
    if (responseJson?.data) {
      const checklist = {
        attributes: {
          ...responseJson.data.attributes,
          checklist_name: responseJson.data.attributes.checklist_name,
          id: responseJson.data.attributes.id,
          sections: {
            data: responseJson.data.attributes.sections.data?.map((sectionItem, indexSectionItem) => {
              return {
                attributes: {
                  id: sectionItem.attributes.id,
                  questions: {
                    data: sectionItem.attributes.questions?.data?.map((questionItem, indexQuestionItem) => {
                      return {
                        attributes: {
                          checked: questionItem.attributes.checked,
                          client_status: questionItem.attributes.client_status,
                          current_gathered_details: questionItem.attributes.current_gathered_details,
                          document: questionItem.attributes.document,
                          id: questionItem.attributes.id,
                          question_name: questionItem.attributes.question_name,
                          question_rank: indexQuestionItem,
                          request_document: questionItem.attributes.request_document,
                          status: questionItem.attributes.status,
                          question_type : questionItem.attributes.question_type,
                          question_options : questionItem.attributes.question_options,
                        },
                        id: questionItem.id,
                        type: questionItem.type,
                      }
                    }) 
                  },
                  section_name: sectionItem.attributes.section_name,
                  section_rank: indexSectionItem,
                },
                id: sectionItem.id,
                type: sectionItem.type,
              }
            })
          }
        },
        id: responseJson.data.id,
        type: responseJson.data.type
      }
      this.setState({
        selectedChecklist : checklist,
      })
      this.props.showToast({type : 'success',message : 'Checklist updated successfully.'})
    } else {
      if(typeof responseJson?.message === 'string'){
        this.props.showToast({
          type : 'error',
          message : responseJson.message
        })
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

   getChecklists = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token : this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChecklistsApiCallId = requestMessage.messageId;
    let url = `bx_block_checklist/checklists?client_id=${this.state.client_id}`;
    if(this.state.surveyId){
      url += `&package_management_id=${this.state.package_management_id}`
    } else if(this.state.road_customization_id){
      url += `&custom_package_id=${this.state.custom_package_management_id}&parent_package_type=${this.state.parent_package_type}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   updateChecklist = (values: any) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateChecklistApiCallId = requestMessage.messageId;

    const httpBody = { 
      "checklists": {
        "checklist_name": values.selectedChecklist.attributes.checklist_name,
        "sections_attributes": values.selectedChecklist.attributes.sections.data.map((sectionItem, indexSectionItem) => {
          let tempSection = {
            "section_name": sectionItem.attributes.section_name,
            "section_rank": indexSectionItem,
            "id": sectionItem.attributes.id,
            "questions_attributes": sectionItem.attributes.questions.data.map((questionItem, indexQuestionItem) => {
              let tempQuestion = {
                "checked": questionItem.attributes.checked,
                "client_status": questionItem.attributes.client_status,
                "current_gathered_details": questionItem.attributes.current_gathered_details,
                "document": questionItem.attributes.document,
                "id": questionItem.attributes.id,
                "question_name": questionItem.attributes.question_name,
                "question_rank": indexQuestionItem,
                "request_document": questionItem.attributes.request_document,
                "status": questionItem.attributes.status,
                "question_options": questionItem?.attributes?.question_options,
                "question_type": questionItem?.attributes?.question_type === "Select" ? "textfield" : questionItem?.attributes?.question_type,
              }
              if(questionItem.isAdditionalQuestion) {
                delete tempQuestion.id
              }
              return tempQuestion
            })
          }
          if(sectionItem.isAdditionalSection) {
            delete tempSection.id
          }
          return tempSection
        })
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_checklist/checklists/${values.selectedChecklist.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sendOnBoardingSurvey = ({getObsLink = false})=>{
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const data = {
      survey_id:this.state.surveyId,
      package_management_id : this.state.package_management_id || undefined,
      client_id:this.state.client_id,
      account_id:this.state.accountId,
      selected_package_id:this.state.package_management_id || undefined,
      allow_to_change_package_id:this.state.allow_to_change_package_id,
      survey_link:window.location.origin + "/onboardingSurveyLandingPage",
      roadmap_id : this.state.road_customization_id,
      custom_package_id : this.state.custom_package_management_id,
      payment_term : this.state.selected_payment_term,
      parent_package_type:this.state.parent_package_type,
    }   
    this.sendOnBoardingSurveyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getObsLink ? `bx_block_clients/client_informations/send_onbaording_survey?get_obs_url=${getObsLink}` : `bx_block_clients/client_informations/send_onbaording_survey`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createClientForNewPortal = ()=>{

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createClientForNewPortalApiCallId = requestMessage.messageId;

    const httpBody = {
      "data":{
          "type":"email_account",
          "attributes": {
              "primary_email": this.state?.singleClient?.primary_email
          }
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getSingleClient = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.client_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
