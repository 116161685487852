//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../../components/src/withSnackBar.Web";
import withLoader, { withLoaderProps } from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { DialogProps } from "../../../../components/src/DialogContext";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

export class UnlinkChecklistDialog extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        className={classes.rootContainer}
      >
        <Grid item xs={12}>
          <Typography align="center" className={classes.dialogHeading}>
            Are you sure you want to unlink this checklist? <br /> You can
            always add it back if needed.
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            className={classes.uploadNowButton}
            onClick={this.closeDialog.bind(this, null)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className={classes.uploadNowButton}
            onClick={this.closeDialog.bind(this, "Yes")}
          >
            Yes
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const UnlinkChecklistDialogWithRouter = withRouter(UnlinkChecklistDialog);
const UnlinkChecklistDialogWithToast = withToast(
  UnlinkChecklistDialogWithRouter
);
const UnlinkChecklistDialogWithLoader = withLoader(
  UnlinkChecklistDialogWithToast
);
const UnlinkChecklistDialogWithAlertBox = withAlertBox(
  UnlinkChecklistDialogWithLoader
);
const UnlinkChecklistDialogWithDialogBox = withDialogBox(
  UnlinkChecklistDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      // width: "25vw",
      padding: "1rem",
    },
    dialogHeading: {
      fontSize: "1.35rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      marginBottom: "1.2rem",
      marginTop: "1rem",
    },
    buttonContainer: {
      padding: "0 1.5rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
  })
)(UnlinkChecklistDialogWithDialogBox);
