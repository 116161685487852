//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    isPaymentSuccess: boolean
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  productList: any
  selectedPackage: any
  signatureBundleCount: number
  additionalUsersCount: number,
  is_upgrade_once: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MySubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateProfileApiCallId: string = "";
  getProfileApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      productList: [],
      selectedPackage: '',
      subscribed: false,
      managingSubscription: false,
      subscriptionInitialValues: {
        selectedPackage: null,
        planRadioButton: "",
        issignatureBundle: false,
        signatureBundleCount: 1,
        isAdditionaUser: false,
        additionalUsersCount: 1,
        isSmartBlurPrint: false,
        isSmartImplimentation: false,
        isSmartSupport: false,
        smart_lead: false
      },
      is_upgrade_once: false,
      isPlanChangedForNextCycle: false,
      isSubscriptionAlreadyCanceled: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 

handleCreateSubscriptionResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson.data?.attributes?.checkout_session_url.url) {
    window.location.href = responseJson.data.attributes.checkout_session_url.url;
  } else if (responseJson.message && responseJson.message === "AccessDenied for current user") {
    this.props.showToast({
      type: "error",
      message: responseJson.message,
    });
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}
handleGetProfileResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson.data.id) {
    this.setState({
      user: responseJson.data.attributes
    }, async () => {
      await StorageProvider.set('currentUser', JSON.stringify(responseJson))
      setTimeout(() => {
        this.getProductList()
      }, 500);
    })
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}
handleProductListResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson.data.length > 0) {
    this.setState({
      productList: responseJson.data
    }, () => {
      if (this.state.user.current_subscription.data.length > 0) {
        this.getCurrentSubscription();
      }
    })
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}
handleCurrentSubscriptionResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson.data && responseJson.data.length > 0) {
    this.setState({
      subscribed: true,
      is_upgrade_once: responseJson.data.attributes?.is_upgrade,
      isPlanChangedForNextCycle: responseJson.data[0].attributes.scheduled_subscription_status === 'active' && new Date(responseJson.data[0].attributes.scheduled_subscription_date).getTime() > new Date().getTime(),
      isSubscriptionAlreadyCanceled: responseJson.data[0].attributes.sub_canceled_at && responseJson.data[0].attributes.sub_cancel_at && new Date(responseJson.data[0].attributes.sub_cancel_at).getTime() > (new Date(responseJson.data[0].attributes.sub_canceled_at).getTime())

    }, () => {
      this.initializeSubuscription(responseJson.data);
    })
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}
handleCancelSubscriptionResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson?.data.attributes.sub_canceled_at && responseJson?.data.attributes.sub_cancel_at) {
    if (new Date(responseJson.data.attributes.sub_cancel_at).getTime() > (new Date(responseJson.data.attributes.sub_canceled_at).getTime())) {
      this.setState({
        isSubscriptionAlreadyCanceled: responseJson.data.attributes.sub_canceled_at && responseJson.data.attributes.sub_cancel_at && new Date(responseJson.data.attributes.sub_cancel_at).getTime() > (new Date(responseJson.data.attributes.sub_canceled_at).getTime())
      })
      this.props.showToast({
        type: 'success',
        message: 'Your subscription cancelled successfully'
      })
    }
  } else if (responseJson?.message && responseJson?.message === "AccessDenied for current user") {
    this.props.showToast({
      type: "error",
      message: responseJson?.message,
    });
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}
handleUpgradeMySubscriptionResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson.data?.attributes.scheduled_subscription_date) {
    if (new Date(responseJson.data.attributes.scheduled_subscription_date).getTime() > new Date().getTime()) {
      this.props.history.push("/home/dashboard")
      this.props.showToast({
        type: 'success',
        message: 'Your subscription added successfully'
      })
    }

  } else if (responseJson.message && responseJson.message === "AccessDenied for current user") {
    this.props.showToast({
      type: "error",
      message: responseJson.message,
    });
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}
handleUpdateMySubscriptionResponse = (responseJson) => {
  this.props.hideLoader();
  if (responseJson.data) {
    if (responseJson.data.attributes.product_type != this.state.subscriptionInitialValues?.planRadioButton) {
      this.setState({
        is_upgrade_once: responseJson.data.attributes.is_upgrade
      }, () => {
        this.props.history.push("/home/dashboard")
        this.props.showToast({
          type: 'success',
          message: 'Your subscription updated successfully'
        })
      })
    }
  } else if (responseJson.message && responseJson.message === "AccessDenied for current user") {
    this.props.showToast({
      type: "error",
      message: responseJson.message,
    });
  } else {
    this.handleApiErrorResponse(responseJson)
  }
}


createmock = () => {
  let trail = true
  let response = {
    message: "AccessDenied for current user"
  }
  if (trail) {
    this.handleCreateSubscriptionResponse(response)

  }
}
createmock2 = () => {
  let trail = true
  if (trail) {
    this.createmak()
  }
}
createmak = () => {
  let trail = true
  if (trail) {
    this.createmock()
  }
}

handlemock = () => {
  let responseJson = {
    data: "AccessDenied for current user"
  }
  this.props.hideLoader();
  if (responseJson.data) {
    if (responseJson.data && responseJson.data === "AccessDenied for current user") {
      this.props.showToast({
        type: "error",
        message: responseJson.message,
      });
    }
  }

}
handleCurrentSubscriptionmock = () => {
  let responseJson = {
    data: "AccessDenied for current user"
  }
  this.props.hideLoader();
  if (responseJson.data) {
    this.setState({
      subscribed: true,
    })
  }
}

handleUpdateMySubscriptionmock = (responseJson) => {
  this.props.hideLoader();
  if (responseJson) {
    this.setState({
      is_upgrade_once: responseJson
    })
    this.props.showToast({
      type: 'success',
      message: 'Your subscription updated successfully'
    })
  }
  else if (!responseJson) {
    this.props.showToast({
      type: "error",
      message: responseJson.message,
    });
  }
}

handleProductmock = (responseJson) => {
  this.props.hideLoader();
  if (responseJson) {
    this.setState({
      productList: responseJson.data
    })
  }
  else if (!responseJson) {
    this.getCurrentSubscription();

  }
}
createCheckoutSessionmock2 = () => {
  this.props.showLoader();

  const headers = {
    skipSubscriptionCheck: true,
    token: this.state.token,
    "Content-Type": configJSON.ApiContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const cancel_url = window.location.origin +
    `/home/subscriptions/`;
  const success_url = window.location.origin +
    `/home/`;

  const httpBody = {
    "data": {
      "attributes": {
        cancel_url: cancel_url,
        success_url: success_url,
        mode: 'subscription',
        line_items: [ ]
      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions/add_checkout_session`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
 requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
createCheckoutSessionmock3 = () => {
  this.props.showLoader();

  const headers = {
    token: this.state.token,
    "Content-Type": configJSON.ApiContentType,
    skipSubscriptionCheck: true,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const cancel_url = window.location.origin +
    `/home`;
  const success_url = window.location.origin +
    `/`;

  const httpBody = {
    "data": {
      "attributes": {
        success_url: success_url,
        cancel_url: cancel_url,
        line_items: [ ],
        mode: 'subscription'
      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions/add_checkout_session`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
 requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProductListmock = () => {
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/products`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getCurrentSubscriptionmock = () => {
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
cancelMyCurrentSubscriptionmock = (value) => {
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const httpBod = {
    "data": {
      "attributes": {
        subscription_id: value
      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions/cancle_subscriptions`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBod)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProfilemock = () => {
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${"account_block/accounts"}/${this.state.accountId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProductListmock1 = () => {
  const header = {
    token: this.state.token,
    skipSubscriptionCheck: true,
    "content-type": "application/json"
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_subscriptions/products"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProfilemock1 = () => {
  const header = {
    token: this.state.token,
    skipSubscriptionCheck: true,
    "content-type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/accounts/${this.state.accountId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getCurrentSubscriptionmock1 = () => {
  const headers = {
    "content-type": "application/json",
    skipSubscriptionCheck: true,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_subscriptions/subscriptions"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
cancelMyCurrentSubscriptionmock1 = (value) => {
  const headers = {
    token: this.state.token,
    skipSubscriptionCheck: true,
    "content-type": "application/json",
  };

  const httpBod = {
    "data": {
      "attributes": {
        subscription_id: value
      }
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_subscriptions/subscriptions/cancle_subscriptions"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBod)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
viewSignedDocumentmock = () => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `client_surveys/view_document?survey_id=558&client_id=1516&package_management_id=9`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
viewSignedDocumentmock1 = () => {
  this.props.showLoader();
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `client_surveys/view_document?survey_id=558&client_id=1516&package_management_id=9`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getPrioritiesmock = (clientId) => {
  this.props.showLoader();

  const headers = {
    "content-type": "application/json",
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_clients/client_informations/${clientId}/priorities`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getPrioritiesmock1 = (clientId) => {
  this.props.showLoader();

  const headers = {
    token: this.state.token,
    "content-type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_clients/client_informations/${clientId}/priorities`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getSurveyListByClientIdmock = (clientId) => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_survey/surveys?client_id=${clientId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getSurveyListByClientIdmock1 = (clientId) => {
  this.props.showLoader();
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_survey/surveys?client_id=${clientId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

updateStatusmock = (values: any) => {
  this.props.showLoader();
  const headers = {
    token: this.state.token,
    "content-type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const formData = {
    profile_status: values,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_clients/client_informations/1516`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(formData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
updateStatusmock1 = (values: any) => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
  };

  const formData = {
    profile_status: values,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_clients/client_informations/1516`
  );

  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(formData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};



handleApiErrorResponse = (responseJson) => {
  if (typeof responseJson?.errors === 'string') {
    this.props.showToast({
      type: 'error',
      message: responseJson.errors
    })
  } else {
    this.parseApiErrorResponse(responseJson);
  }
}
  async receive(from: string, message: Message) {
  runEngine.debugLog("Message Recived", message);
  if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.createSubscriptionApiCallId) {
      this.handleCreateSubscriptionResponse(responseJson);
    } else if (apiRequestCallId === this.getProfileApiCallId) {
      this.handleGetProfileResponse(responseJson)
    } else if (apiRequestCallId === this.getProductListApiCallId) {
      this.handleProductListResponse(responseJson)
    } else if (apiRequestCallId === this.getCurrentSubscriptionApiCallId) {
      this.handleCurrentSubscriptionResponse(responseJson)
    } else if (apiRequestCallId === this.cancelMyCurrentSubscriptionApiCallId) {
      this.handleCancelSubscriptionResponse(responseJson)
    } else if (apiRequestCallId === this.updgradMyCurrentSubscriptionApiCallId) {
      this.handleUpgradeMySubscriptionResponse(responseJson);
    } else if (this.updateMyCurrentSubscriptionApiCallId === apiRequestCallId) {
      this.handleUpdateMySubscriptionResponse(responseJson)
    } else if (this.createSingaturePaymentApiCallId == apiRequestCallId) {
      this.handleCreateSubscriptionResponse(responseJson)
    }
  } else if (getName(MessageEnum.AlertMessage) === message.id) {
    const AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );
    const msg = await this.props.showAlert({ title: 'Error', message: AlertBodyMessage })
    if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
      await StorageProvider.remove("authToken")
      await StorageProvider.remove("accountId")
      this.props.history.push("/")
    }
  }
}
initializeSubuscription = (subscribedData) => {
  if (subscribedData?.length && subscribedData[0].id) {
    this.setState({
      subscribedData: subscribedData[0]
    }, () => {
      let producttype = this.state.subscribedData?.attributes?.product_type;
      let planRadioButton = '';
      if (producttype === 'EngagePlanMonthly') {
        planRadioButton = 'engageMonthly';
      } else if (producttype === 'EngagePlanYearly') {
        planRadioButton = 'engageAnnual';
      } else if (producttype === 'AdvisePlanMonthly') {
        planRadioButton = 'adviceMonthly';
      } else if (producttype === 'AdvisePlanYearly') {
        planRadioButton = 'adviceAnnual';
      }

      const selectedPackageName = producttype?.indexOf("Engage") > -1 ? 'Engage' : 'Advise';
      const subscriptionInitialValues = {
        selectedPackage: { name: selectedPackageName, id: this.state.subscribedData?.attributes?.product_id },
        planRadioButton: planRadioButton,
        issignatureBundle: this.state.subscribedData?.attributes?.is_automated_client_enrollments,
        signatureBundleCount: this.state.subscribedData?.attributes?.automated_client_enrollments,
        isAdditionaUser: this.state.subscribedData?.attributes?.is_additional_user,
        additionalUsersCount: this.state.subscribedData?.attributes?.additional_user,
        isSmartBlurPrint: this.state.subscribedData?.attributes?.smart_blueprint,
        isSmartImplimentation: this.state.subscribedData?.attributes?.smart_implementation,
        isSmartSupport: this.state.subscribedData?.attributes?.smart_support,
        smart_lead: this.state.subscribedData?.attributes?.smart_lead,
      }
      this.setState({
        subscriptionInitialValues: subscriptionInitialValues
      })
    })
  }
}
getProductList = () => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getProductListApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/products`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
createSubscription = (values) => {
  //  bx_block_subscriptions/subscriptions
  this.props.showLoader();

  const headers = {
    "Content-Type": configJSON.ApiContentType,
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createSubscriptionApiCallId = requestMessage.messageId;
  let producttype = null;
  if (values.planRadioButton && values.planRadioButton === 'engageMonthly') {
    producttype = 0;
  } else if (values.planRadioButton && values.planRadioButton === 'engageAnnual') {
    producttype = 1;
  } else if (values.planRadioButton && values.planRadioButton === 'adviceAnnual') {
    producttype = 3;
  } else if (values.planRadioButton && values.planRadioButton === 'adviceMonthly') {
    producttype = 2;
  }
  const success_url = window.location.origin +
    `/home/subscriptions/stripePaymentSuccess`;
  const cancel_url = window.location.origin +
    `/home/subscriptions/stripePaymentFailure`;
  const httpBody = {
    "data": {
      "attributes": {
        "product_id": values.selectedPackage.id,
        "product_type": producttype,
        "smart_blueprint": values.isSmartBlurPrint,
        "smart_support": values.isSmartSupport,
        "smart_implementation": values.isSmartImplimentation,
        "additional_user": values.additionalUsersCount,
        "automated_client_enrollments": values.signatureBundleCount,
        "is_additional_user": values.isAdditionaUser,
        "is_automated_client_enrollments": values.issignatureBundle,
        "smart_lead": values.smart_lead,
        success_url: success_url,
        cancel_url: cancel_url,
      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
createCheckoutSession = (data) => {
  this.props.showLoader();

  const headers = {
    "Content-Type": configJSON.ApiContentType,
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createCheckoutSessionApiCallId = requestMessage.messageId;

  const success_url = window.location.origin +
    `/home/subscriptions/stripePaymentSuccess`;
  const cancel_url = window.location.origin +
    `/home/subscriptions/stripePaymentFailure`;

  const httpBody = {
    "data": {
      "attributes": {
        success_url: success_url,
        cancel_url: cancel_url,
        line_items: [

        ],
        mode: 'subscription'

      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions/add_checkout_session`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getCurrentSubscription = () => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCurrentSubscriptionApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
cancelMyCurrentSubscription = () => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.cancelMyCurrentSubscriptionApiCallId = requestMessage.messageId;
  const httpBody = {
    "data": {
      "attributes": {
        subscription_id: this.state.subscribedData?.attributes.subscription_id
      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions/cancle_subscriptions`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProductType = (values) => {
  let producttype = null;

  switch (values.planRadioButton) {
    case "engageMonthly": {
      producttype = 0;
      break;
    }
    case "engageAnnual": {
      producttype = 1;
      break;
    }
    case "adviceMonthly": {
      producttype = 2;
      break;
    }
    case "adviceAnnual": {
      producttype = 3;
      break;
    }
  }
  return producttype
}
getAPiTypeAndName = (values) => {
  let isUpgrade = false;
  if (this.state.subscriptionInitialValues?.planRadioButton != values.planRadioButton) {
    if (this.state.subscriptionInitialValues?.planRadioButton?.indexOf("engage") > -1 && values.planRadioButton?.indexOf("engage") > -1) {
      if (this.state.subscriptionInitialValues?.planRadioButton === 'engageMonthly' && values.planRadioButton === "engageAnnual") {
        isUpgrade = true;
      }
    } else if (this.state.subscriptionInitialValues?.planRadioButton?.indexOf("advice") > -1 && values.planRadioButton?.indexOf("advice") > -1) {
      if (this.state.subscriptionInitialValues?.planRadioButton === 'adviceMonthly' && values.planRadioButton === "adviceAnnual") {
        isUpgrade = true;
      }
    }
  }
  return isUpgrade ? { name: `bx_block_subscriptions/subscriptions/update_subscription`, isUpgrade } : { name: `bx_block_subscriptions/subscriptions/updgrade_subscription`, isUpgrade }
}
updgradMyCurrentSubscription = (values) => {

  const headers = {
    "content-type": "application/json",
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const producttype = this.getProductType(values)

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const api = this.getAPiTypeAndName(values);
  if (api.isUpgrade) {
    if (!this.state.is_upgrade_once) {
      this.updateMyCurrentSubscriptionApiCallId = requestMessage.messageId;
    } else {
      this.props.showToast({
        type: 'error',
        message: 'You have already updated your subscription once'

      })
      return false;
    }

  } else {
    this.updgradMyCurrentSubscriptionApiCallId = requestMessage.messageId;
  }
  this.props.showLoader();


  const httpBody = {
    "data": {
      "attributes": {
        "account_product_id": this.state.subscribedData?.attributes.id,
        "sc_product_type": producttype,
        "sc_product_id": values.selectedPackage.id,
        "sc_smart_blueprint": values.isSmartBlurPrint,
        "sc_smart_support": values.isSmartSupport,
        "sc_smart_implementation": values.isSmartImplimentation,
        "sc_additional_user": values.additionalUsersCount,
        "sc_automated_client_enrollments": values.signatureBundleCount,
        "sc_is_additional_user": values.isAdditionaUser,
        "sc_is_automated_client_enrollments": values.issignatureBundle,
        "sc_smart_lead": values.smart_lead,
      }
    }
  }
  // delete

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    api?.name
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'PUT'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
getProfile = () => {
  this.props.showLoader();
  const headers = {
    "content-type": "application/json",
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getProfileApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${"account_block/accounts"}/${this.state.accountId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
createSingaturePayment = (signatureBundleCount, selectedPackageId, values) => {
  //  bx_block_subscriptions/subscriptions
  this.props.showLoader();

  const headers = {
    "Content-Type": configJSON.ApiContentType,
    token: this.state.token,
    skipSubscriptionCheck: true
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createSingaturePaymentApiCallId = requestMessage.messageId;
  let producttype = null;
  if (values.planRadioButton && values.planRadioButton === 'engageMonthly') {
    producttype = 0;
  } else if (values.planRadioButton && values.planRadioButton === 'engageAnnual') {
    producttype = 1;
  } else if (values.planRadioButton && values.planRadioButton === 'adviceMonthly') {
    producttype = 2;
  } else if (values.planRadioButton && values.planRadioButton === 'adviceAnnual') {
    producttype = 3;
  }
  const success_url = window.location.origin +
    `/home/subscriptions/stripePaymentSuccess`;
  const cancel_url = window.location.origin +
    `/home/subscriptions/stripePaymentFailure`;
  const httpBody = {
    "data": {
      "attributes": {
        "product_id": selectedPackageId,
        "product_type": producttype,
        "automated_client_enrollments": signatureBundleCount,
        "is_automated_client_enrollments": true,
        success_url: success_url,
        cancel_url: cancel_url,
        mode: 'payment'
      }
    }
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_subscriptions/subscriptions`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
}