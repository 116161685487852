//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialogBox from "../../../components/src/withDialog.web";
import { LightenDarkenColor } from "./utils";

export class CancelSubscriptionDialog extends React.Component {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;

    const features = [
      "Proposals",
      "Outstanding eSignatures",
      "Client Data",
      "Client Conversion Link",
      "Firm Performance Data",
      "Marketing Templates",
      "Advisor Training",
    ];

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            align="center"
            className={classes.headingText}
          >
            Are you sure you want to cancel your subscription?
          </Typography>
          <Typography
            variant="h3"
            align="center"
            className={classes.subHeadingText}
          >
            You will lose access to:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            {features.map((feature) => {
              return (
                <Grid
                  item
                  container
                  alignItems="center"
                  key={feature}
                  xs={3}
                  className={classes.featureCard}
                >
                  <Grid item xs={2} container alignItems="center">
                    <CancelIcon className={classes.redCloseIcon} />
                  </Grid>

                  <Grid item xs={10}>
                    <Typography align="center" className={classes.featureName}>
                      {feature}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          xs={4}
          container
          justifyContent="center"
          className={classes.buttonContainer}
        >
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.uploadNowButton}
            onClick={this.closeDialog.bind(this)}
          >
            No, Go Back
          </Button>
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            id="yesButton"
            className={[classes.uploadNowButton, classes.redButton]}
            onClick={this.closeDialog.bind(this,{})}
          >
            Yes, Cancel
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const CancelSubscriptionDialogWithRouter = withRouter(CancelSubscriptionDialog);
const CancelSubscriptionDialogWithToast = withToast(
  CancelSubscriptionDialogWithRouter
);
const CancelSubscriptionDialogWithLoader = withLoader(
  CancelSubscriptionDialogWithToast
);
const CancelSubscriptionDialogWithAlertBox = withAlertBox(
  CancelSubscriptionDialogWithLoader
);
const CancelSubscriptionDialogWithDialogBox = withDialogBox(
  CancelSubscriptionDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    featureCard: {
      flexGrow: 0,
      maxWidth: "30%",
      flexBasis: "30%",
      margin: "0.5rem 1.5%",
      backgroundColor: "#fff",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      borderRadius: "1.25rem",
      padding: "1.1rem",
    },
    redCloseIcon: {
      display: "inline-block",
      color: "#FC5A64",
    },
    featureName: {
      fontSize: "1.1rem",
      paddingRight: "16.66%",
      color: theme.palette.text.secondary,
    },
    headingText: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      marginBottom: "2rem",
      marginTop: "3rem",
    },
    subHeadingText: {
      fontSize: "1.6rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      marginTop: "3rem",
      marginBottom: "3rem",
      height: "4rem",
      borderRadius: "1rem",
      fontSize: "1.2rem",
    },
    redButton: {
      backgroundColor: "#FC5A64",
      "&:hover": {
        backgroundColor: LightenDarkenColor("#FC5A64", -20),
      },
    },
    buttonContainer: {
      margin: "0 0.5rem",
    },
  })
)(CancelSubscriptionDialogWithDialogBox);
