// @ts-nocheck
import React from "react";
import { PermissionsContext } from "./PermissionsContext";

function withPermissions(OriginalComponent) {
  return (props) => {
    const { permissions, setPermissions } = React.useContext(PermissionsContext);
    return <OriginalComponent {...props} permissions={permissions} setPermissions={setPermissions} />;
  };
}
export default withPermissions;
