//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  link_sent : number,
  custom_link_count : number,
  not_compeleted : number,
  submitted : number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FirmPerformanceSurveyController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSurveyCountApiCallId : string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      link_sent : 0,
      custom_link_count : 0,
      not_compeleted : 0,
      submitted : 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetSurveyCountResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson.custom_link_count) {
       this.setState({
        link_sent : responseJson?.link_sent || 0,
        custom_link_count : responseJson?.custom_link_count || 0,
        not_compeleted : responseJson?.not_compeleted || 0,
        submitted : responseJson?.submitted || 0
       })
    } else if(responseJson?.errors && typeof responseJson?.errors === 'string') {
      this.props.showToast({
        type : 'error',
        message : responseJson.errors
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getSurveyCountApiCallId) {
        this.handleGetSurveyCountResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found'){
        await StorageProvider.clearStorage();
        this.props.history.push("/")
      }
    }
  }

  getSurveyCount = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token : this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSurveyCountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/surveys/survey_dashboard`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
}
