//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import PhaseTwoField from "../../../../components/src/PhaseTwoField.web";
import { CustomTooltip } from "../../../../components/src/ModifiedComponents.web";
import { infoIcon } from "../assets";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  meetingLink: Yup.string()
    .required("This field is required.")
    .matches(/^https:\/\/calendly\.com\/[a-zA-Z0-9_/-]+$/, "Please enter valid calendly meeting url"),
});

export class CalendlyIntegrationDialog extends React.Component {
  

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          meetingLink: this.props.dataToPass.meetingLink,
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          this.closeDialog({
            meetingLink: values.meetingLink,
          });
        }}
      >
        {(formikProps) => {
          const { values, handleBlur, handleChange, errors, touched, isValid } = formikProps;
          return (
            <Form autoComplete="off" noValidate style={{ margin: 0 }}>
              <Grid container style={{ width: "30vw" }} spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h3" color="primary" className={classes.dialogHeading}>
                    Calendly
                  </Typography>
                </Grid>
                <Grid item xs={10} className={[classes.alignCenter, classes.surveyLinkContainer]}>
                  <CustomTypography padding="0rem 0.5rem 0px 0rem" fontSize="1.3158rem" fontWeight={700} display="inline">
                    Calendly Meeting Link:
                  </CustomTypography>
                  <CustomTooltip title={`Paste your Calendly meeting link so that you can easily connected with your on going client.`} placement="right">
                    <img src={infoIcon} className={classes.infoIcon} />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={10}>
                  <PhaseTwoField
                    placeholder="Enter Link"
                    name="meetingLink"
                    value={values.meetingLink}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='meetingLink'
                    error={errors.meetingLink && touched.meetingLink}
                    helperText={errors.meetingLink && touched.meetingLink ? errors.meetingLink : ""}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Button variant="contained" id="meetingLinkSubmit" color="primary" type="submit" disabled={!isValid} className={classes.uploadNowButton}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const CalendlyIntegrationDialogWithRouter = withRouter(CalendlyIntegrationDialog);
const CalendlyIntegrationDialogWithToast = withToast(CalendlyIntegrationDialogWithRouter);
const CalendlyIntegrationDialogWithLoader = withLoader(CalendlyIntegrationDialogWithToast);
const CalendlyIntegrationDialogWithAlertBox = withAlertBox(CalendlyIntegrationDialogWithLoader);
const CalendlyIntegrationDialogWithDialogBox = withDialogBox(CalendlyIntegrationDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    dialogsubHeading: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    uploadNowButton: {
      margin: "1.5rem 0px",
      width: "40%",
    },
    infoIcon: {
      display: "inline-block",
      width: "1.2rem",
      height: "1.2rem",
      marginRight: "0.5rem",
    },
  })
)(CalendlyIntegrationDialogWithDialogBox);