export const commonStyles = (theme) => {
  return {
    linkText: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    //font family
    nunitoSansFont: {
      fontFamily: "Nunito Sans",
    },
    dmSansFont: {
      fontFamily: "DM Sans",
    },
    latoFont: {
      fontFamily: "Lato",
    },

    //font weight
    weight400: {
      fontWeight: 400,
    },
    weight500: {
      fontWeight: 500,
    },
    weight600: {
      fontWeight: 600,
    },
    weight700: {
      fontWeight: 700,
    },

    //font size
    size12: {
      fontSize: "0.75rem",
    },
    size14: {
      fontSize: "0.875rem",
    },
    size16: {
      fontSize: "1rem",
    },
    size17: {
      fontSize: "1.0625rem",
    },
    size18: {
      fontSize: "1.125rem",
    },
    size20: {
      fontSize: "1.25rem",
    },
    size22: {
      fontSize: "1.375rem",
    },
    size24: {
      fontSize: "1.5rem",
    },
    size26: {
      fontSize: "1.625rem",
    },
    size28: {
      fontSize: "1.75rem",
    },

    //padding
    p25: {
      padding: "0.25rem",
    },
    p50: {
      padding: "0.5rem",
    },
    p75: {
      padding: "0.75rem",
    },
    p100: {
      padding: "1rem",
    },
    p125: {
      padding: "1.25rem",
    },
    p150: {
      padding: "1.5rem",
    },
    p175: {
      padding: "1.75rem",
    },
    p200: {
      padding: "2rem",
    },
    p225: {
      padding: "2.25rem",
    },
    p250: {
      padding: "2.5rem",
    },
    p275: {
      padding: "2.75rem",
    },
    p300: {
      padding: "3rem",
    },
    p325: {
      padding: "3.25rem",
    },
    p350: {
      padding: "3.5rem",
    },
    p375: {
      padding: "3.75rem",
    },
    p400: {
      padding: "4rem",
    },
    p425: {
      padding: "4.25rem",
    },
    p450: {
      padding: "4.5rem",
    },
    p475: {
      padding: "4.75rem",
    },
    p500: {
      padding: "5rem",
    },
    p525: {
      padding: "5.25rem",
    },
    p550: {
      padding: "5.5rem",
    },
    p575: {
      padding: "5.75rem",
    },
    p600: {
      padding: "6rem",
    },
    p625: {
      padding: "6.25rem",
    },
    p650: {
      padding: "6.5rem",
    },
    p675: {
      padding: "6.75rem",
    },
    p700: {
      padding: "7rem",
    },
    p725: {
      padding: "7.25rem",
    },
    p750: {
      padding: "7.5rem",
    },
    p775: {
      padding: "7.75rem",
    },
    p800: {
      padding: "8rem",
    },
    p825: {
      padding: "8.25rem",
    },
    p850: {
      padding: "8.5rem",
    },
    p875: {
      padding: "8.75rem",
    },
    p900: {
      padding: "9rem",
    },
    p925: {
      padding: "9.25rem",
    },
    p950: {
      padding: "9.5rem",
    },
    p975: {
      padding: "9.75rem",
    },
    p1000: {
      padding: "10rem",
    },
    //padding top
    pt25: {
      paddingTop: "0.25rem",
    },
    pt50: {
      paddingTop: "0.5rem",
    },
    pt75: {
      paddingTop: "0.75rem",
    },
    pt100: {
      paddingTop: "1rem",
    },
    pt125: {
      paddingTop: "1.25rem",
    },
    pt150: {
      paddingTop: "1.5rem",
    },
    pt175: {
      paddingTop: "1.75rem",
    },
    pt200: {
      paddingTop: "2rem",
    },
    pt225: {
      paddingTop: "2.25rem",
    },
    pt250: {
      paddingTop: "2.5rem",
    },
    pt275: {
      paddingTop: "2.75rem",
    },
    pt300: {
      paddingTop: "3rem",
    },
    pt325: {
      paddingTop: "3.25rem",
    },
    pt350: {
      paddingTop: "3.5rem",
    },
    pt375: {
      paddingTop: "3.75rem",
    },
    pt400: {
      paddingTop: "4rem",
    },
    pt425: {
      paddingTop: "4.25rem",
    },
    pt450: {
      paddingTop: "4.5rem",
    },
    pt475: {
      paddingTop: "4.75rem",
    },
    pt500: {
      paddingTop: "5rem",
    },
    pt525: {
      paddingTop: "5.25rem",
    },
    pt550: {
      paddingTop: "5.5rem",
    },
    pt575: {
      paddingTop: "5.75rem",
    },
    pt600: {
      paddingTop: "6rem",
    },
    pt625: {
      paddingTop: "6.25rem",
    },
    pt650: {
      paddingTop: "6.5rem",
    },
    pt675: {
      paddingTop: "6.75rem",
    },
    pt700: {
      paddingTop: "7rem",
    },
    pt725: {
      paddingTop: "7.25rem",
    },
    pt750: {
      paddingTop: "7.5rem",
    },
    pt775: {
      paddingTop: "7.75rem",
    },
    pt800: {
      paddingTop: "8rem",
    },
    pt825: {
      paddingTop: "8.25rem",
    },
    pt850: {
      paddingTop: "8.5rem",
    },
    pt875: {
      paddingTop: "8.75rem",
    },
    pt900: {
      paddingTop: "9rem",
    },
    pt925: {
      paddingTop: "9.25rem",
    },
    pt950: {
      paddingTop: "9.5rem",
    },
    pt975: {
      paddingTop: "9.75rem",
    },
    pt1000: {
      paddingTop: "10rem",
    },
    //padding right
    pr25: {
      paddingRight: "0.25rem",
    },
    pr50: {
      paddingRight: "0.5rem",
    },
    pr75: {
      paddingRight: "0.75rem",
    },
    pr100: {
      paddingRight: "1rem",
    },
    pr125: {
      paddingRight: "1.25rem",
    },
    pr150: {
      paddingRight: "1.5rem",
    },
    pr175: {
      paddingRight: "1.75rem",
    },
    pr200: {
      paddingRight: "2rem",
    },
    pr225: {
      paddingRight: "2.25rem",
    },
    pr250: {
      paddingRight: "2.5rem",
    },
    pr275: {
      paddingRight: "2.75rem",
    },
    pr300: {
      paddingRight: "3rem",
    },
    pr325: {
      paddingRight: "3.25rem",
    },
    pr350: {
      paddingRight: "3.5rem",
    },
    pr375: {
      paddingRight: "3.75rem",
    },
    pr400: {
      paddingRight: "4rem",
    },
    pr425: {
      paddingRight: "4.25rem",
    },
    pr450: {
      paddingRight: "4.5rem",
    },
    pr475: {
      paddingRight: "4.75rem",
    },
    pr500: {
      paddingRight: "5rem",
    },
    pr525: {
      paddingRight: "5.25rem",
    },
    pr550: {
      paddingRight: "5.5rem",
    },
    pr575: {
      paddingRight: "5.75rem",
    },
    pr600: {
      paddingRight: "6rem",
    },
    pr625: {
      paddingRight: "6.25rem",
    },
    pr650: {
      paddingRight: "6.5rem",
    },
    pr675: {
      paddingRight: "6.75rem",
    },
    pr700: {
      paddingRight: "7rem",
    },
    pr725: {
      paddingRight: "7.25rem",
    },
    pr750: {
      paddingRight: "7.5rem",
    },
    pr775: {
      paddingRight: "7.75rem",
    },
    pr800: {
      paddingRight: "8rem",
    },
    pr825: {
      paddingRight: "8.25rem",
    },
    pr850: {
      paddingRight: "8.5rem",
    },
    pr875: {
      paddingRight: "8.75rem",
    },
    pr900: {
      paddingRight: "9rem",
    },
    pr925: {
      paddingRight: "9.25rem",
    },
    pr950: {
      paddingRight: "9.5rem",
    },
    pr975: {
      paddingRight: "9.75rem",
    },
    pr1000: {
      paddingRight: "10rem",
    },
    //padding bottop
    pb25: {
      paddingBottom: "0.25rem",
    },
    pb50: {
      paddingBottom: "0.5rem",
    },
    pb75: {
      paddingBottom: "0.75rem",
    },
    pb100: {
      paddingBottom: "1rem",
    },
    pb125: {
      paddingBottom: "1.25rem",
    },
    pb150: {
      paddingBottom: "1.5rem",
    },
    pb175: {
      paddingBottom: "1.75rem",
    },
    pb200: {
      paddingBottom: "2rem",
    },
    pb225: {
      paddingBottom: "2.25rem",
    },
    pb250: {
      paddingBottom: "2.5rem",
    },
    pb275: {
      paddingBottom: "2.75rem",
    },
    pb300: {
      paddingBottom: "3rem",
    },
    pb325: {
      paddingBottom: "3.25rem",
    },
    pb350: {
      paddingBottom: "3.5rem",
    },
    pb375: {
      paddingBottom: "3.75rem",
    },
    pb400: {
      paddingBottom: "4rem",
    },
    pb425: {
      paddingBottom: "4.25rem",
    },
    pb450: {
      paddingBottom: "4.5rem",
    },
    pb475: {
      paddingBottom: "4.75rem",
    },
    pb500: {
      paddingBottom: "5rem",
    },
    pb525: {
      paddingBottom: "5.25rem",
    },
    pb550: {
      paddingBottom: "5.5rem",
    },
    pb575: {
      paddingBottom: "5.75rem",
    },
    pb600: {
      paddingBottom: "6rem",
    },
    pb625: {
      paddingBottom: "6.25rem",
    },
    pb650: {
      paddingBottom: "6.5rem",
    },
    pb675: {
      paddingBottom: "6.75rem",
    },
    pb700: {
      paddingBottom: "7rem",
    },
    pb725: {
      paddingBottom: "7.25rem",
    },
    pb750: {
      paddingBottom: "7.5rem",
    },
    pb775: {
      paddingBottom: "7.75rem",
    },
    pb800: {
      paddingBottom: "8rem",
    },
    pb825: {
      paddingBottom: "8.25rem",
    },
    pb850: {
      paddingBottom: "8.5rem",
    },
    pb875: {
      paddingBottom: "8.75rem",
    },
    pb900: {
      paddingBottom: "9rem",
    },
    pb925: {
      paddingBottom: "9.25rem",
    },
    pb950: {
      paddingBottom: "9.5rem",
    },
    pb975: {
      paddingBottom: "9.75rem",
    },
    pb1000: {
      paddingBottom: "10rem",
    },
    //padding left
    pl25: {
      paddingLeft: "0.25rem",
    },
    pl50: {
      paddingLeft: "0.5rem",
    },
    pl75: {
      paddingLeft: "0.75rem",
    },
    pl100: {
      paddingLeft: "1rem",
    },
    pl125: {
      paddingLeft: "1.25rem",
    },
    pl150: {
      paddingLeft: "1.5rem",
    },
    pl175: {
      paddingLeft: "1.75rem",
    },
    pl200: {
      paddingLeft: "2rem",
    },
    pl225: {
      paddingLeft: "2.25rem",
    },
    pl250: {
      paddingLeft: "2.5rem",
    },
    pl275: {
      paddingLeft: "2.75rem",
    },
    pl300: {
      paddingLeft: "3rem",
    },
    pl325: {
      paddingLeft: "3.25rem",
    },
    pl350: {
      paddingLeft: "3.5rem",
    },
    pl375: {
      paddingLeft: "3.75rem",
    },
    pl400: {
      paddingLeft: "4rem",
    },
    pl425: {
      paddingLeft: "4.25rem",
    },
    pl450: {
      paddingLeft: "4.5rem",
    },
    pl475: {
      paddingLeft: "4.75rem",
    },
    pl500: {
      paddingLeft: "5rem",
    },
    pl525: {
      paddingLeft: "5.25rem",
    },
    pl550: {
      paddingLeft: "5.5rem",
    },
    pl575: {
      paddingLeft: "5.75rem",
    },
    pl600: {
      paddingLeft: "6rem",
    },
    pl625: {
      paddingLeft: "6.25rem",
    },
    pl650: {
      paddingLeft: "6.5rem",
    },
    pl675: {
      paddingLeft: "6.75rem",
    },
    pl700: {
      paddingLeft: "7rem",
    },
    pl725: {
      paddingLeft: "7.25rem",
    },
    pl750: {
      paddingLeft: "7.5rem",
    },
    pl775: {
      paddingLeft: "7.75rem",
    },
    pl800: {
      paddingLeft: "8rem",
    },
    pl825: {
      paddingLeft: "8.25rem",
    },
    pl850: {
      paddingLeft: "8.5rem",
    },
    pl875: {
      paddingLeft: "8.75rem",
    },
    pl900: {
      paddingLeft: "9rem",
    },
    pl925: {
      paddingLeft: "9.25rem",
    },
    pl950: {
      paddingLeft: "9.5rem",
    },
    pl975: {
      paddingLeft: "9.75rem",
    },
    pl1000: {
      paddingLeft: "10rem",
    },
    //padding vertical
    py25: {
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
    },
    py50: {
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
    py75: {
      paddingTop: "0.75rem",
      paddingBottom: "0.75rem",
    },
    py100: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    py125: {
      paddingTop: "1.25rem",
      paddingBottom: "1.25rem",
    },
    py150: {
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    },
    py175: {
      paddingTop: "1.75rem",
      paddingBottom: "1.75rem",
    },
    py200: {
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
    py225: {
      paddingTop: "2.25rem",
      paddingBottom: "2.25rem",
    },
    py250: {
      paddingTop: "2.5rem",
      paddingBottom: "2.5rem",
    },
    py275: {
      paddingTop: "2.75rem",
      paddingBottom: "2.75rem",
    },
    py300: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
    },
    py325: {
      paddingTop: "3.25rem",
      paddingBottom: "3.25rem",
    },
    py350: {
      paddingTop: "3.5rem",
      paddingBottom: "3.5rem",
    },
    py375: {
      paddingTop: "3.75rem",
      paddingBottom: "3.75rem",
    },
    py400: {
      paddingTop: "4rem",
      paddingBottom: "4rem",
    },
    py425: {
      paddingTop: "4.25rem",
      paddingBottom: "4.25rem",
    },
    py450: {
      paddingTop: "4.5rem",
      paddingBottom: "4.5rem",
    },
    py475: {
      paddingTop: "4.75rem",
      paddingBottom: "4.75rem",
    },
    py500: {
      paddingTop: "5rem",
      paddingBottom: "5rem",
    },
    py525: {
      paddingTop: "5.25rem",
      paddingBottom: "5.25rem",
    },
    py550: {
      paddingTop: "5.5rem",
      paddingBottom: "5.5rem",
    },
    py575: {
      paddingTop: "5.75rem",
      paddingBottom: "5.75rem",
    },
    py600: {
      paddingTop: "6rem",
      paddingBottom: "6rem",
    },
    py625: {
      paddingTop: "6.25rem",
      paddingBottom: "6.25rem",
    },
    py650: {
      paddingTop: "6.5rem",
      paddingBottom: "6.5rem",
    },
    py675: {
      paddingTop: "6.75rem",
      paddingBottom: "6.75rem",
    },
    py700: {
      paddingTop: "7rem",
      paddingBottom: "7rem",
    },
    py725: {
      paddingTop: "7.25rem",
      paddingBottom: "7.25rem",
    },
    py750: {
      paddingTop: "7.5rem",
      paddingBottom: "7.5rem",
    },
    py775: {
      paddingTop: "7.75rem",
      paddingBottom: "7.75rem",
    },
    py800: {
      paddingTop: "8rem",
      paddingBottom: "8rem",
    },
    py825: {
      paddingTop: "8.25rem",
      paddingBottom: "8.25rem",
    },
    py850: {
      paddingTop: "8.5rem",
      paddingBottom: "8.5rem",
    },
    py875: {
      paddingTop: "8.75rem",
      paddingBottom: "8.75rem",
    },
    py900: {
      paddingTop: "9rem",
      paddingBottom: "9rem",
    },
    py925: {
      paddingTop: "9.25rem",
      paddingBottom: "9.25rem",
    },
    py950: {
      paddingTop: "9.5rem",
      paddingBottom: "9.5rem",
    },
    py975: {
      paddingTop: "9.75rem",
      paddingBottom: "9.75rem",
    },
    py1000: {
      paddingTop: "10rem",
      paddingBottom: "10rem",
    },
    //padding horizontal
    px25: {
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem",
    },
    px50: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    px75: {
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
    },
    px100: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    px125: {
      paddingLeft: "1.25rem",
      paddingRight: "1.25rem",
    },
    px150: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    px175: {
      paddingLeft: "1.75rem",
      paddingRight: "1.75rem",
    },
    px200: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    px225: {
      paddingLeft: "2.25rem",
      paddingRight: "2.25rem",
    },
    px250: {
      paddingLeft: "2.5rem",
      paddingRight: "2.5rem",
    },
    px275: {
      paddingLeft: "2.75rem",
      paddingRight: "2.75rem",
    },
    px300: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    px325: {
      paddingLeft: "3.25rem",
      paddingRight: "3.25rem",
    },
    px350: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    px375: {
      paddingLeft: "3.75rem",
      paddingRight: "3.75rem",
    },
    px400: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
    },
    px425: {
      paddingLeft: "4.25rem",
      paddingRight: "4.25rem",
    },
    px450: {
      paddingLeft: "4.5rem",
      paddingRight: "4.5rem",
    },
    px475: {
      paddingLeft: "4.75rem",
      paddingRight: "4.75rem",
    },
    px500: {
      paddingLeft: "5rem",
      paddingRight: "5rem",
    },
    px525: {
      paddingLeft: "5.25rem",
      paddingRight: "5.25rem",
    },
    px550: {
      paddingLeft: "5.5rem",
      paddingRight: "5.5rem",
    },
    px575: {
      paddingLeft: "5.75rem",
      paddingRight: "5.75rem",
    },
    px600: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
    px625: {
      paddingLeft: "6.25rem",
      paddingRight: "6.25rem",
    },
    px650: {
      paddingLeft: "6.5rem",
      paddingRight: "6.5rem",
    },
    px675: {
      paddingLeft: "6.75rem",
      paddingRight: "6.75rem",
    },
    px700: {
      paddingLeft: "7rem",
      paddingRight: "7rem",
    },
    px725: {
      paddingLeft: "7.25rem",
      paddingRight: "7.25rem",
    },
    px750: {
      paddingLeft: "7.5rem",
      paddingRight: "7.5rem",
    },
    px775: {
      paddingLeft: "7.75rem",
      paddingRight: "7.75rem",
    },
    px800: {
      paddingLeft: "8rem",
      paddingRight: "8rem",
    },
    px825: {
      paddingLeft: "8.25rem",
      paddingRight: "8.25rem",
    },
    px850: {
      paddingLeft: "8.5rem",
      paddingRight: "8.5rem",
    },
    px875: {
      paddingLeft: "8.75rem",
      paddingRight: "8.75rem",
    },
    px900: {
      paddingLeft: "9rem",
      paddingRight: "9rem",
    },
    px925: {
      paddingLeft: "9.25rem",
      paddingRight: "9.25rem",
    },
    px950: {
      paddingLeft: "9.5rem",
      paddingRight: "9.5rem",
    },
    px975: {
      paddingLeft: "9.75rem",
      paddingRight: "9.75rem",
    },
    px1000: {
      paddingLeft: "10rem",
      paddingRight: "10rem",
    },

    //margin
    m25: {
      margin: "0.25rem",
    },
    m50: {
      margin: "0.5rem",
    },
    m75: {
      margin: "0.75rem",
    },
    m100: {
      margin: "1rem",
    },
    m125: {
      margin: "1.25rem",
    },
    m150: {
      margin: "1.5rem",
    },
    m175: {
      margin: "1.75rem",
    },
    m200: {
      margin: "2rem",
    },
    m225: {
      margin: "2.25rem",
    },
    m250: {
      margin: "2.5rem",
    },
    m275: {
      margin: "2.75rem",
    },
    m300: {
      margin: "3rem",
    },
    m325: {
      margin: "3.25rem",
    },
    m350: {
      margin: "3.5rem",
    },
    m375: {
      margin: "3.75rem",
    },
    m400: {
      margin: "4rem",
    },
    m425: {
      margin: "4.25rem",
    },
    m450: {
      margin: "4.5rem",
    },
    m475: {
      margin: "4.75rem",
    },
    m500: {
      margin: "5rem",
    },
    m525: {
      margin: "5.25rem",
    },
    m550: {
      margin: "5.5rem",
    },
    m575: {
      margin: "5.75rem",
    },
    m600: {
      margin: "6rem",
    },
    m625: {
      margin: "6.25rem",
    },
    m650: {
      margin: "6.5rem",
    },
    m675: {
      margin: "6.75rem",
    },
    m700: {
      margin: "7rem",
    },
    m725: {
      margin: "7.25rem",
    },
    m750: {
      margin: "7.5rem",
    },
    m775: {
      margin: "7.75rem",
    },
    m800: {
      margin: "8rem",
    },
    m825: {
      margin: "8.25rem",
    },
    m850: {
      margin: "8.5rem",
    },
    m875: {
      margin: "8.75rem",
    },
    m900: {
      margin: "9rem",
    },
    m925: {
      margin: "9.25rem",
    },
    m950: {
      margin: "9.5rem",
    },
    m975: {
      margin: "9.75rem",
    },
    m1000: {
      margin: "10rem",
    },
    //margin top
    mt25: {
      marginTop: "0.25rem",
    },
    mt50: {
      marginTop: "0.5rem",
    },
    mt75: {
      marginTop: "0.75rem",
    },
    mt100: {
      marginTop: "1rem",
    },
    mt125: {
      marginTop: "1.25rem",
    },
    mt150: {
      marginTop: "1.5rem",
    },
    mt175: {
      marginTop: "1.75rem",
    },
    mt200: {
      marginTop: "2rem",
    },
    mt225: {
      marginTop: "2.25rem",
    },
    mt250: {
      marginTop: "2.5rem",
    },
    mt275: {
      marginTop: "2.75rem",
    },
    mt300: {
      marginTop: "3rem",
    },
    mt325: {
      marginTop: "3.25rem",
    },
    mt350: {
      marginTop: "3.5rem",
    },
    mt375: {
      marginTop: "3.75rem",
    },
    mt400: {
      marginTop: "4rem",
    },
    mt425: {
      marginTop: "4.25rem",
    },
    mt450: {
      marginTop: "4.5rem",
    },
    mt475: {
      marginTop: "4.75rem",
    },
    mt500: {
      marginTop: "5rem",
    },
    mt525: {
      marginTop: "5.25rem",
    },
    mt550: {
      marginTop: "5.5rem",
    },
    mt575: {
      marginTop: "5.75rem",
    },
    mt600: {
      marginTop: "6rem",
    },
    mt625: {
      marginTop: "6.25rem",
    },
    mt650: {
      marginTop: "6.5rem",
    },
    mt675: {
      marginTop: "6.75rem",
    },
    mt700: {
      marginTop: "7rem",
    },
    mt725: {
      marginTop: "7.25rem",
    },
    mt750: {
      marginTop: "7.5rem",
    },
    mt775: {
      marginTop: "7.75rem",
    },
    mt800: {
      marginTop: "8rem",
    },
    mt825: {
      marginTop: "8.25rem",
    },
    mt850: {
      marginTop: "8.5rem",
    },
    mt875: {
      marginTop: "8.75rem",
    },
    mt900: {
      marginTop: "9rem",
    },
    mt925: {
      marginTop: "9.25rem",
    },
    mt950: {
      marginTop: "9.5rem",
    },
    mt975: {
      marginTop: "9.75rem",
    },
    mt1000: {
      marginTop: "10rem",
    },
    //margin right
    mr25: {
      marginRight: "0.25rem",
    },
    mr50: {
      marginRight: "0.5rem",
    },
    mr75: {
      marginRight: "0.75rem",
    },
    mr100: {
      marginRight: "1rem",
    },
    mr125: {
      marginRight: "1.25rem",
    },
    mr150: {
      marginRight: "1.5rem",
    },
    mr175: {
      marginRight: "1.75rem",
    },
    mr200: {
      marginRight: "2rem",
    },
    mr225: {
      marginRight: "2.25rem",
    },
    mr250: {
      marginRight: "2.5rem",
    },
    mr275: {
      marginRight: "2.75rem",
    },
    mr300: {
      marginRight: "3rem",
    },
    mr325: {
      marginRight: "3.25rem",
    },
    mr350: {
      marginRight: "3.5rem",
    },
    mr375: {
      marginRight: "3.75rem",
    },
    mr400: {
      marginRight: "4rem",
    },
    mr425: {
      marginRight: "4.25rem",
    },
    mr450: {
      marginRight: "4.5rem",
    },
    mr475: {
      marginRight: "4.75rem",
    },
    mr500: {
      marginRight: "5rem",
    },
    mr525: {
      marginRight: "5.25rem",
    },
    mr550: {
      marginRight: "5.5rem",
    },
    mr575: {
      marginRight: "5.75rem",
    },
    mr600: {
      marginRight: "6rem",
    },
    mr625: {
      marginRight: "6.25rem",
    },
    mr650: {
      marginRight: "6.5rem",
    },
    mr675: {
      marginRight: "6.75rem",
    },
    mr700: {
      marginRight: "7rem",
    },
    mr725: {
      marginRight: "7.25rem",
    },
    mr750: {
      marginRight: "7.5rem",
    },
    mr775: {
      marginRight: "7.75rem",
    },
    mr800: {
      marginRight: "8rem",
    },
    mr825: {
      marginRight: "8.25rem",
    },
    mr850: {
      marginRight: "8.5rem",
    },
    mr875: {
      marginRight: "8.75rem",
    },
    mr900: {
      marginRight: "9rem",
    },
    mr925: {
      marginRight: "9.25rem",
    },
    mr950: {
      marginRight: "9.5rem",
    },
    mr975: {
      marginRight: "9.75rem",
    },
    mr1000: {
      marginRight: "10rem",
    },
    //margin bottom
    mb25: {
      marginBottom: "0.25rem",
    },
    mb50: {
      marginBottom: "0.5rem",
    },
    mb75: {
      marginBottom: "0.75rem",
    },
    mb100: {
      marginBottom: "1rem",
    },
    mb125: {
      marginBottom: "1.25rem",
    },
    mb150: {
      marginBottom: "1.5rem",
    },
    mb175: {
      marginBottom: "1.75rem",
    },
    mb200: {
      marginBottom: "2rem",
    },
    mb225: {
      marginBottom: "2.25rem",
    },
    mb250: {
      marginBottom: "2.5rem",
    },
    mb275: {
      marginBottom: "2.75rem",
    },
    mb300: {
      marginBottom: "3rem",
    },
    mb325: {
      marginBottom: "3.25rem",
    },
    mb350: {
      marginBottom: "3.5rem",
    },
    mb375: {
      marginBottom: "3.75rem",
    },
    mb400: {
      marginBottom: "4rem",
    },
    mb425: {
      marginBottom: "4.25rem",
    },
    mb450: {
      marginBottom: "4.5rem",
    },
    mb475: {
      marginBottom: "4.75rem",
    },
    mb500: {
      marginBottom: "5rem",
    },
    mb525: {
      marginBottom: "5.25rem",
    },
    mb550: {
      marginBottom: "5.5rem",
    },
    mb575: {
      marginBottom: "5.75rem",
    },
    mb600: {
      marginBottom: "6rem",
    },
    mb625: {
      marginBottom: "6.25rem",
    },
    mb650: {
      marginBottom: "6.5rem",
    },
    mb675: {
      marginBottom: "6.75rem",
    },
    mb700: {
      marginBottom: "7rem",
    },
    mb725: {
      marginBottom: "7.25rem",
    },
    mb750: {
      marginBottom: "7.5rem",
    },
    mb775: {
      marginBottom: "7.75rem",
    },
    mb800: {
      marginBottom: "8rem",
    },
    mb825: {
      marginBottom: "8.25rem",
    },
    mb850: {
      marginBottom: "8.5rem",
    },
    mb875: {
      marginBottom: "8.75rem",
    },
    mb900: {
      marginBottom: "9rem",
    },
    mb925: {
      marginBottom: "9.25rem",
    },
    mb950: {
      marginBottom: "9.5rem",
    },
    mb975: {
      marginBottom: "9.75rem",
    },
    mb1000: {
      marginBottom: "10rem",
    },
    //margin left
    ml25: {
      marginLeft: "0.25rem",
    },
    ml50: {
      marginLeft: "0.5rem",
    },
    ml75: {
      marginLeft: "0.75rem",
    },
    ml100: {
      marginLeft: "1rem",
    },
    ml125: {
      marginLeft: "1.25rem",
    },
    ml150: {
      marginLeft: "1.5rem",
    },
    ml175: {
      marginLeft: "1.75rem",
    },
    ml200: {
      marginLeft: "2rem",
    },
    ml225: {
      marginLeft: "2.25rem",
    },
    ml250: {
      marginLeft: "2.5rem",
    },
    ml275: {
      marginLeft: "2.75rem",
    },
    ml300: {
      marginLeft: "3rem",
    },
    ml325: {
      marginLeft: "3.25rem",
    },
    ml350: {
      marginLeft: "3.5rem",
    },
    ml375: {
      marginLeft: "3.75rem",
    },
    ml400: {
      marginLeft: "4rem",
    },
    ml425: {
      marginLeft: "4.25rem",
    },
    ml450: {
      marginLeft: "4.5rem",
    },
    ml475: {
      marginLeft: "4.75rem",
    },
    ml500: {
      marginLeft: "5rem",
    },
    ml525: {
      marginLeft: "5.25rem",
    },
    ml550: {
      marginLeft: "5.5rem",
    },
    ml575: {
      marginLeft: "5.75rem",
    },
    ml600: {
      marginLeft: "6rem",
    },
    ml625: {
      marginLeft: "6.25rem",
    },
    ml650: {
      marginLeft: "6.5rem",
    },
    ml675: {
      marginLeft: "6.75rem",
    },
    ml700: {
      marginLeft: "7rem",
    },
    ml725: {
      marginLeft: "7.25rem",
    },
    ml750: {
      marginLeft: "7.5rem",
    },
    ml775: {
      marginLeft: "7.75rem",
    },
    ml800: {
      marginLeft: "8rem",
    },
    ml825: {
      marginLeft: "8.25rem",
    },
    ml850: {
      marginLeft: "8.5rem",
    },
    ml875: {
      marginLeft: "8.75rem",
    },
    ml900: {
      marginLeft: "9rem",
    },
    ml925: {
      marginLeft: "9.25rem",
    },
    ml950: {
      marginLeft: "9.5rem",
    },
    ml975: {
      marginLeft: "9.75rem",
    },
    ml1000: {
      marginLeft: "10rem",
    },
    //margin vertical
    my25: {
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
    },
    my50: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
    },
    my75: {
      marginTop: "0.75rem",
      marginBottom: "0.75rem",
    },
    my100: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    my125: {
      marginTop: "1.25rem",
      marginBottom: "1.25rem",
    },
    my150: {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
    my175: {
      marginTop: "1.75rem",
      marginBottom: "1.75rem",
    },
    my200: {
      marginTop: "2rem",
      marginBottom: "2rem",
    },
    my225: {
      marginTop: "2.25rem",
      marginBottom: "2.25rem",
    },
    my250: {
      marginTop: "2.5rem",
      marginBottom: "2.5rem",
    },
    my275: {
      marginTop: "2.75rem",
      marginBottom: "2.75rem",
    },
    my300: {
      marginTop: "3rem",
      marginBottom: "3rem",
    },
    my325: {
      marginTop: "3.25rem",
      marginBottom: "3.25rem",
    },
    my350: {
      marginTop: "3.5rem",
      marginBottom: "3.5rem",
    },
    my375: {
      marginTop: "3.75rem",
      marginBottom: "3.75rem",
    },
    my400: {
      marginTop: "4rem",
      marginBottom: "4rem",
    },
    my425: {
      marginTop: "4.25rem",
      marginBottom: "4.25rem",
    },
    my450: {
      marginTop: "4.5rem",
      marginBottom: "4.5rem",
    },
    my475: {
      marginTop: "4.75rem",
      marginBottom: "4.75rem",
    },
    my500: {
      marginTop: "5rem",
      marginBottom: "5rem",
    },
    my525: {
      marginTop: "5.25rem",
      marginBottom: "5.25rem",
    },
    my550: {
      marginTop: "5.5rem",
      marginBottom: "5.5rem",
    },
    my575: {
      marginTop: "5.75rem",
      marginBottom: "5.75rem",
    },
    my600: {
      marginTop: "6rem",
      marginBottom: "6rem",
    },
    my625: {
      marginTop: "6.25rem",
      marginBottom: "6.25rem",
    },
    my650: {
      marginTop: "6.5rem",
      marginBottom: "6.5rem",
    },
    my675: {
      marginTop: "6.75rem",
      marginBottom: "6.75rem",
    },
    my700: {
      marginTop: "7rem",
      marginBottom: "7rem",
    },
    my725: {
      marginTop: "7.25rem",
      marginBottom: "7.25rem",
    },
    my750: {
      marginTop: "7.5rem",
      marginBottom: "7.5rem",
    },
    my775: {
      marginTop: "7.75rem",
      marginBottom: "7.75rem",
    },
    my800: {
      marginTop: "8rem",
      marginBottom: "8rem",
    },
    my825: {
      marginTop: "8.25rem",
      marginBottom: "8.25rem",
    },
    my850: {
      marginTop: "8.5rem",
      marginBottom: "8.5rem",
    },
    my875: {
      marginTop: "8.75rem",
      marginBottom: "8.75rem",
    },
    my900: {
      marginTop: "9rem",
      marginBottom: "9rem",
    },
    my925: {
      marginTop: "9.25rem",
      marginBottom: "9.25rem",
    },
    my950: {
      marginTop: "9.5rem",
      marginBottom: "9.5rem",
    },
    my975: {
      marginTop: "9.75rem",
      marginBottom: "9.75rem",
    },
    my1000: {
      marginTop: "10rem",
      marginBottom: "10rem",
    },
    //margin horizontal
    mx25: {
      marginLeft: "0.25rem",
      marginRight: "0.25rem",
    },
    mx50: {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
    mx75: {
      marginLeft: "0.75rem",
      marginRight: "0.75rem",
    },
    mx100: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    mx125: {
      marginLeft: "1.25rem",
      marginRight: "1.25rem",
    },
    mx150: {
      marginLeft: "1.5rem",
      marginRight: "1.5rem",
    },
    mx175: {
      marginLeft: "1.75rem",
      marginRight: "1.75rem",
    },
    mx200: {
      marginLeft: "2rem",
      marginRight: "2rem",
    },
    mx225: {
      marginLeft: "2.25rem",
      marginRight: "2.25rem",
    },
    mx250: {
      marginLeft: "2.5rem",
      marginRight: "2.5rem",
    },
    mx275: {
      marginLeft: "2.75rem",
      marginRight: "2.75rem",
    },
    mx300: {
      marginLeft: "3rem",
      marginRight: "3rem",
    },
    mx325: {
      marginLeft: "3.25rem",
      marginRight: "3.25rem",
    },
    mx350: {
      marginLeft: "3.5rem",
      marginRight: "3.5rem",
    },
    mx375: {
      marginLeft: "3.75rem",
      marginRight: "3.75rem",
    },
    mx400: {
      marginLeft: "4rem",
      marginRight: "4rem",
    },
    mx425: {
      marginLeft: "4.25rem",
      marginRight: "4.25rem",
    },
    mx450: {
      marginLeft: "4.5rem",
      marginRight: "4.5rem",
    },
    mx475: {
      marginLeft: "4.75rem",
      marginRight: "4.75rem",
    },
    mx500: {
      marginLeft: "5rem",
      marginRight: "5rem",
    },
    mx525: {
      marginLeft: "5.25rem",
      marginRight: "5.25rem",
    },
    mx550: {
      marginLeft: "5.5rem",
      marginRight: "5.5rem",
    },
    mx575: {
      marginLeft: "5.75rem",
      marginRight: "5.75rem",
    },
    mx600: {
      marginLeft: "6rem",
      marginRight: "6rem",
    },
    mx625: {
      marginLeft: "6.25rem",
      marginRight: "6.25rem",
    },
    mx650: {
      marginLeft: "6.5rem",
      marginRight: "6.5rem",
    },
    mx675: {
      marginLeft: "6.75rem",
      marginRight: "6.75rem",
    },
    mx700: {
      marginLeft: "7rem",
      marginRight: "7rem",
    },
    mx725: {
      marginLeft: "7.25rem",
      marginRight: "7.25rem",
    },
    mx750: {
      marginLeft: "7.5rem",
      marginRight: "7.5rem",
    },
    mx775: {
      marginLeft: "7.75rem",
      marginRight: "7.75rem",
    },
    mx800: {
      marginLeft: "8rem",
      marginRight: "8rem",
    },
    mx825: {
      marginLeft: "8.25rem",
      marginRight: "8.25rem",
    },
    mx850: {
      marginLeft: "8.5rem",
      marginRight: "8.5rem",
    },
    mx875: {
      marginLeft: "8.75rem",
      marginRight: "8.75rem",
    },
    mx900: {
      marginLeft: "9rem",
      marginRight: "9rem",
    },
    mx925: {
      marginLeft: "9.25rem",
      marginRight: "9.25rem",
    },
    mx950: {
      marginLeft: "9.5rem",
      marginRight: "9.5rem",
    },
    mx975: {
      marginLeft: "9.75rem",
      marginRight: "9.75rem",
    },
    mx1000: {
      marginLeft: "10rem",
      marginRight: "10rem",
    },
  };
};
