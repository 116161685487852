//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
export class PackageDetail extends React.Component {
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        className={classes.rootContainer}
      >
        <Grid item xs={12} md={12}>
          <Typography className={classes.title}>
            {this.props.dataToPass?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img
                className={classes.imageBox}
                src={this.props.dataToPass?.logo}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography
                className={classes.descriptionText}
                color="textSecondary"
              >
                {this.props.dataToPass?.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container justifyContent="space-between">
            <Grid item md={6} xs={12} container alignItems="center">
              <Typography
                display="inline"
                variant="h1"
                className={classes.title}
              >
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  roundingMode: "ceil",
                }).format(this.props.dataToPass.price || 0)}
              </Typography>
              <Typography
                display="inline"
                color="textSecondary"
                className={classes.descriptionText}
              >
                per unit
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.closeDialog(this.props.dataToPass.package);
                }}
                fullWidth
              >
                {"Select"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const PackageDetailWithRouter = withRouter(PackageDetail);
const PackageDetailWithToast = withToast(PackageDetailWithRouter);
const PackageDetailWithLoader = withLoader(PackageDetailWithToast);
const PackageDetailWithAlertBox = withAlertBox(PackageDetailWithLoader);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      padding: theme.spacing(4),
      width: "800",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2),
      },
    },
    imageBox: {
      width: "100%",
      borderRadius: "1rem",
      backgroundColor: "#f9f9f9",
      padding: "2rem",
    },
    price: {
      marginRight: theme.spacing(1),
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginRight: theme.spacing(1),
      color: theme.palette.text.blue2,
    },
    descriptionText: {
      fontSize: "1.125rem",
    },
  })
)(PackageDetailWithAlertBox);
