//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  profile: any;
  churnsTimePeriod: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FirmPerformanceChurnsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCancellationDataApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      profile: {},
      cancellation_monthly_count: {
        labels: [],
        datasets: [],
      },
      cancelationCount : [],
      churnsTimePeriod: 12,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCancellationDataApiCallId) {
        this.handleGetCancellationApiResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.clearStorage();
        this.props.history.push("/");
      }
    }
  }

  handleGetCancellationApiResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.cancellation_monthly_count) {
      const is_clients_onboarded_exists =
        Object.keys(responseJson?.cancellation_monthly_count).length > 0;
      if (is_clients_onboarded_exists) {
        const labels = Object.keys(
          responseJson?.cancellation_monthly_count
        );

        const lineChartData = {
          labels,
          datasets:
            Object.values(responseJson?.cancellation_monthly_count)
              ?.length > 0
              ? [
                  {
                    label: "",
                    data: Object.values(
                      responseJson?.cancellation_monthly_count
                    ),
                    borderColor: "#9CBE6A",
                    backgroundColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    pointHoverRadius: 4,
                  },
                ]
              : [],
        };
        this.setState({
          lineChartData: lineChartData,
        });
      } else if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  getCancellationData = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancellationDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/cancellation?last_month=${this.state.churnsTimePeriod}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
