// @ts-nocheck
import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { uploadBoxImage } from "./assets";
import {
    Typography,
    Box,
  } from "@material-ui/core";

export type UploadBoxProps = {
    onSelectFile : (Files : FileList) => {},
    onTouched : () => {},
    multiple : boolean,
    classes : any,
    uploadMsg?: string,
    uploadSubMsg?: string,
    acceptedFormat : string,
    uploadimg? : any,
    height?: React.CSSProperties["height"]
}
export class UploadBoxPhaseTwo extends React.Component<UploadBoxProps> {
  fileInputRef = React.createRef<HTMLInputElement>();
  


  fileInputClicked() {
    this.props.onTouched();
    this.fileInputRef?.current?.click();
  }

  filesSelected() {
    if (this.fileInputRef?.current?.files?.length) {
        this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

  handleFiles(files: FileList){
    if(files[0].name.includes(".png") || files[0].name.includes(".jpg") || files[0].name.includes(".jpeg") || files[0].name.includes(".PNG") || files[0].name.includes(".JPG") || files[0].name.includes(".JPEG")) {
      this.props.onSelectFile(files);
    } else {
      this.props.showToast({
        type: "error",
        message: "invalid file type"
      })
    }
  }
  
  render() {
    const {classes} = this.props;
    return (
      <>
        <Box
          display="flex"
          className={classes.boxOutline}
          id='boxOutline'
          style={{
            height: this.props.height ? this.props.height: ""
          }}
          onClick={this.fileInputClicked.bind(this)}
        >
            <img src={uploadBoxImage} className={classes.ioi} />
            <Box mx={2}>
                <Typography variant="body1" className={classes.boldText}>{this.props.uploadMsg ? this.props.uploadMsg : ''}</Typography>
                <Typography variant="caption">{this.props.uploadSubMsg ? this.props.uploadSubMsg : ''}</Typography>
            </Box>
        </Box>
        <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          id='fileInput'
          type="file"
          accept={this.props.acceptedFormat}
          multiple={this.props.multiple}
          onChange={this.filesSelected.bind(this)}
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    DropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      height: "150px",
      border:"dashed 1px #343a40",
      flexDirection:"column",
      borderRadius:'6px',
      padding:'20px 10px'
    },
    uploadIcon: {
      width: '25px',
      height: '30px',
      objectFit: 'contain',
      background: `url(${uploadBoxImage}) no-repeat center center`,
      backgroundSize: "100%",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "30px",
    },
    dropMessage: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: "#343a40",
      fontFamily: "Public Sans",
      marginTop : '16px'
    },
    marginTop : {
      marginTop : theme.spacing(2)
    },
    fileInput : {
        display: 'none'
    },
    boxOutline: {
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "30px",
      borderRadius: "16px",
      border: "3px dashed #d9d9d9",
      backgroundColor: "#f7f7f7",
    },
    ioi: {
        margin: "0 5px",
        width: "60px",
        height: "50px"
    },
    boldText: {
      fontWeight: 600,
      color: "#adadad",
      textAlign:"center"
    },
  });
  export default withStyles(styles, { withTheme: true })(UploadBoxPhaseTwo);