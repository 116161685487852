//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button, Box } from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import UploadBoxCsv from "../UploadBoxCsv.web";

export class AddNewClientDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      csvFile: "",
    };
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  handleCsvFile = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      csvFile: filePaths[0],
    });
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };
  onTouched = () => {
    console.log("");
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center" style={{ width: "25vw", padding: "1rem" }}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" className={classes.dialogHeading}>
            Add Multiple Clients
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Box>
            <UploadBoxCsv
              multiple={false}
              acceptedFormat={".csv"}
              uploadMsg={this.state.csvFile?.file?.name ? this.state.csvFile?.file?.name : "Select a CSV file"}
              onTouched={this.onTouched}
              onSelectFile={this.handleCsvFile.bind(this)}
              {...this.props.dataToPass}
            />
          </Box>
          <Typography
            align="center"
            color="textSecondary"
            style={{
              margin: "0.5rem 0",
            }}
          >
            <a
              style={{
                textDecoration: "underline",
                color: "blue",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={this.props.dataToPass.downloadSheet}
            >
              Click here
            </a>{" "}
            to download a sheet template. (Ensure the sheet contains valid data adhering to the following criteria)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.listItemPrimary_ClietDialog}>1. Only alphabets are permissible in the name field.</Typography>
          <Typography className={classes.listItemPrimary_ClietDialog}>2. Email addresses must adhere to a valid format.</Typography>
          <Typography className={classes.listItemPrimary_ClietDialog}>
            3. The client type should be specified as either "business" or "individual" with correct spelling.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} className={classes.halfWidthGrid}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                id="cancelDialogmultipleFile"
                onClick={this.closeDialog.bind(this, null)}
                className={classes.uploadNowButton}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.halfWidthGrid}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                id="UploadButton"
                className={classes.uploadNowButton}
                disabled={this.state.csvFile === ""}
                onClick={this.closeDialog.bind(this, {
                  submitValue: "Yes",
                  csvFile: this.state.csvFile,
                })}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const AddNewClientDialogWithRouter = withRouter(AddNewClientDialog);
const AddNewClientDialogWithToast = withToast(AddNewClientDialogWithRouter);
const AddNewClientDialogWithLoader = withLoader(AddNewClientDialogWithToast);
const AddNewClientDialogWithAlertBox = withAlertBox(AddNewClientDialogWithLoader);
const AddNewClientDialogWithDialogBox = withDialogBox(AddNewClientDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "1rem",
    },
    subHeading: {
      margin: "1rem 0",
      marginBottom: "2rem",
    },
    uploadNowButton: {
      margin: "0.5rem 0px",
      marginTop: "2rem",
    },
    halfWidthGrid: {
      flexGrow: 0,
      maxWidth: "48%",
      flexBasis: "48%",
      marginBottom: "1.25rem",
    },
    listItemPrimary_ClietDialog: {
      fontSize: "0.9rem",
      fontFamily: "Nunito Sans",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#666769",
      paddingLeft: "1rem",
      margin: "0.5rem 0",
    },
  })
)(AddNewClientDialogWithDialogBox);
