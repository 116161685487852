//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  reminderSettings: any;
  formValues: any;
  reminderId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AutomaticRemindersController extends BlockComponent<Props, S, SS> {
  getReminderSettingsApiCallId: string = "";
  createAndUpdateReminderSettingsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      formValues: {},
      reminderId: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetReminderSettingsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      this.setState({
        formValues: {
          automaticReminders: responseJson?.data?.attributes?.automatic_reminder,
          frequencySetting: responseJson?.data?.attributes?.default_frequency ? "default" : "custom",
          defaultHours: responseJson?.data?.attributes?.default_hour,
          defaultDays: responseJson?.data?.attributes?.default_day,
          onboardingSignatureHours: responseJson?.data?.attributes?.onboarding_signature_hour,
          onboardingSignatureDays: responseJson?.data?.attributes?.onboarding_signature_day,
          onboardingDataCollectionHours: responseJson?.data?.attributes?.onboarding_data_collection_hour,
          onboardingDataCollectionDays: responseJson?.data?.attributes?.onboarding_data_collection_day,
          clientConversionSurveyHours: responseJson?.data?.attributes?.cfs_hour,
          clientConversionSurveyDays: responseJson?.data?.attributes?.cfs_day,
        },
        reminderId: responseJson?.data?.id,
      });
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  handleCreateAndUpdateReminderSettingsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      this.setState({
        formValues: {
          automaticReminders: responseJson?.data?.attributes?.automatic_reminder,
          frequencySetting: responseJson?.data?.attributes?.default_frequency ? "default" : "custom",
          defaultHours: responseJson?.data?.attributes?.default_hour,
          defaultDays: responseJson?.data?.attributes?.default_day,
          onboardingSignatureHours: responseJson?.data?.attributes?.onboarding_signature_hour,
          onboardingSignatureDays: responseJson?.data?.attributes?.onboarding_signature_day,
          onboardingDataCollectionHours: responseJson?.data?.attributes?.onboarding_data_collection_hour,
          onboardingDataCollectionDays: responseJson?.data?.attributes?.onboarding_data_collection_day,
          clientConversionSurveyHours: responseJson?.data?.attributes?.cfs_hour,
          clientConversionSurveyDays: responseJson?.data?.attributes?.cfs_day,
        },
        reminderId: responseJson?.data?.id,
      });
      this.props.showToast({
        type: "success",
        message: "Settings updated successfully",
      });
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getReminderSettingsApiCallId) {
        this.handleGetReminderSettingsResponse(responseJson);
      } else if (apiRequestCallId === this.createAndUpdateReminderSettingsApiCallId) {
        this.handleCreateAndUpdateReminderSettingsResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }

  getReminderSettings = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getReminderSettingsApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_survey/frequency_setting`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createAndUpdateReminderSettings = (values: any) => {
    this.props.showLoader();

    let mothod = "POST";
    let url = `bx_block_survey/frequency_setting`;

    if (this.state.reminderId !== null) {
      mothod = "PUT";
      url = `bx_block_survey/frequency_setting/${this.state.reminderId}`;
    }

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.createAndUpdateReminderSettingsApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        attributes: {
          default_hour: +values.defaultHours,
          default_day: +values.defaultDays,
          onboarding_signature_hour: +values.onboardingSignatureHours,
          onboarding_signature_day: +values.onboardingSignatureDays,
          onboarding_data_collection_hour: +values.onboardingDataCollectionHours,
          onboarding_data_collection_day: +values.onboardingDataCollectionDays,
          cfs_hour: +values.clientConversionSurveyHours,
          cfs_day: +values.clientConversionSurveyDays,
          automatic_reminder: values.automaticReminders,
          custom_frequency: values.frequencySetting === "custom",
          default_frequency: values.frequencySetting === "default",
        },
      },
    };

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), mothod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
