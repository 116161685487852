//@ts-nocheck
import React from "react";
import ChangePasswordController, { Props } from "./ChangePasswordController.web";
import { Typography, Button, Grid, withStyles, createStyles, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import InputField from "../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import TernaryCheck, { calculateTernary } from "./TernaryCheck.web";

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "Password must contain at least 8 characters, one uppercase, one number and one special character")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special character"
    )
    .required("This field is required"),
  newPassword: Yup.string()
    .required("This field is required")
    .when("oldPassword", {
      is: undefined,
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string()
        .min(8, "Password must contain at least 8 characters, one uppercase, one number and one special character")
        .matches(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special character"
        )
        .notOneOf([Yup.ref("oldPassword"), null], "New password can't be same as current password.")
        .required("This field is required"),
    }),
  confirmNewPassword: Yup.string()
    .min(8, "Password must contain at least 8 characters, one uppercase, one number and one special character")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special character"
    )
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("This field is required"),
});

export class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");

    this.setState({
      token: token,
    });
  }

  handlePasswordVisibility = (stateName) => {
    this.setState((prevState) => ({
      [stateName]: !prevState[stateName],
    }));
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        enableReinitialize
        validationSchema={changePasswordSchema}
        onSubmit={this.changePassword}
      >
        {(formikProps) => {
          const { values, handleChange, isValid, dirty } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container justifyContent="space-between">
                <Grid item md={12}>
                  <Typography className={classes.title} id="change-password-heading">Change Password</Typography>
                </Grid>
                <Grid item xs={9} className={[classes.whiteCard, classes.gridSpacing]}>
                  <Grid container>
                    <Grid item md={3} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12} className={[classes.gridSpacing, classes.oldPasswordBottomMargin]}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Enter Old Password
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing, classes.oldPasswordBottomMargin]}>
                      <Field
                        component={InputField}
                        type={calculateTernary({
                          condition: this.state.isOldPasswordVisible,
                          trueValue: "text",
                          falseValue: "password",
                        })}
                        name="oldPassword"
                        placeholder="Enter Password"
                        onChange={handleChange}
                        value={values.oldPassword}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" onClick={this.handlePasswordVisibility.bind(this, "isOldPasswordVisible")}>
                              <TernaryCheck
                                condition={this.state.isOldPasswordVisible}
                                trueComponent={<VisibilityOffIcon className={classes.visibilityIcon} />}
                                falseComponent={<VisibilityIcon className={classes.visibilityIcon} />}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item md={3} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Enter New Password
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      <Field
                        component={InputField}
                        type={calculateTernary({
                          condition: this.state.isNewPasswordVisible,
                          trueValue: "text",
                          falseValue: "password",
                        })}
                        name="newPassword"
                        placeholder="Enter Password"
                        onChange={handleChange}
                        value={values.newPassword}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" onClick={this.handlePasswordVisibility.bind(this, "isNewPasswordVisible")}>
                              <TernaryCheck
                                condition={this.state.isNewPasswordVisible}
                                trueComponent={<VisibilityOffIcon className={classes.visibilityIcon} />}
                                falseComponent={<VisibilityIcon className={classes.visibilityIcon} />}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item md={3} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Re-enter New Password
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      <Field
                        component={InputField}
                        type={calculateTernary({
                          condition: this.state.isConfirmNewPasswordVisible,
                          trueValue: "text",
                          falseValue: "password",
                        })}
                        name="confirmNewPassword"
                        placeholder="Enter Password"
                        onChange={handleChange}
                        value={values.confirmNewPassword}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" onClick={this.handlePasswordVisibility.bind(this, "isConfirmNewPasswordVisible")}>
                              <TernaryCheck
                                condition={this.state.isConfirmNewPasswordVisible}
                                trueComponent={<VisibilityOffIcon className={classes.visibilityIcon} />}
                                falseComponent={<VisibilityIcon className={classes.visibilityIcon} />}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item md={3} />
                    <Grid
                      item
                      md={5}
                      style={{
                        marginTop: "48px",
                      }}
                    >
                      <Button color="primary" type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ChangePasswordWithRouter = withRouter(ChangePassword);
const ChangePasswordAlertBox = withAlertBox(ChangePasswordWithRouter);
const ChangePasswordLoader = withLoader(ChangePasswordAlertBox);
const ChangePasswordToast = withToast(ChangePasswordLoader);
const ChangePasswordWithDialog = withDialog(ChangePasswordToast);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        paddingBottom: "3rem",
        paddingRight: "3rem",
      },
      title: {
        fontFamily: "DM Sans",
        fontSize: "1.75rem",
        color: "#54A4A6",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginBottom: theme.spacing(2),
      },
      fieldLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        fontWeight: 600,
        color: theme.palette.text.blue2,
        display: "block",
        paddingRight: theme.spacing(3),
        textAlign: "right",
      },
      oldPasswordBottomMargin: {
        marginBottom: theme.spacing(6),
      },
      visibilityIcon: {
        color: "#9c9c9c",
        cursor: "pointer",
      },
    }),
  { withTheme: true }
)(withConfirmBox(ChangePasswordWithDialog));
