// @ts-nocheck
import React from "react";
import ManageUsersController, { Props } from "./ManageUsersController.web";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router-dom";
import withDialog from "../../../../components/src/withDialog.web";
import { Typography, Button, Grid, withStyles, createStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import { blackEditIconPng } from "../assets";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import withPermissions from "../../../../components/src/withPermissions.web";
import TernaryCheck from "../TernaryCheck.web";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1.1rem",
    },
    head: {
      backgroundColor: "#f9f9f9",
      color: theme.palette.text.secondary,
      textAlign: (props) => (props.align ? props.align : "start"),
      fontSize: "1rem",
      fontWeight: "bold",
    },
    body: {
      backgroundColor: "#fff",
      color: theme.palette.text.secondary,
      textAlign: (props) => (props.align ? props.align : "start"),
      fontSize: "0.875rem",
      fontWeight: 600,
    },
  })
)(TableCell);

export const TableRowCustomized = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-of-type": {
        "& td": {
          border: "none",
        },
        "& th": {
          border: "none",
        },
      },
    },
  })
)(TableRow);

export class ManageUsers extends ManageUsersController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getAllUsers();
      }
    );
  }

  createUserClickHandler = () => {
    this.props.history.push("/home/settings/manageUsers/createNewUser");
  };

  editUserClickHandler = (singleUserId) => {
    this.props.history.push({
      pathname: "/home/settings/manageUsers/editUser",
      state: {
        singleUserId: singleUserId,
      },
    });
  }

  render(): React.ReactNode {
    const { classes, permissions } = this.props;
    return (
      <Grid container justifyContent="center">
        <CustomGrid item container justifyContent="space-between" xs={11}>
          <Typography className={classes.pageHeading} color="primary" id="manage-users-heading">
            Manage Users
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={this.createUserClickHandler}
            disabled={!permissions.includes("Create another's account-Account Creation")}
          >
            Create User
          </Button>
        </CustomGrid>
        <CustomGrid
          item
          xs={11}
          style={{
            marginTop: "1.5rem",
          }}
        >
          <TableContainer
            style={{
              width: "100%",
              borderRadius: "16px",
              boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
            }}
          >
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRowCustomized>
                  <StyledTableCell width="90%">User List</StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    Manage User
                  </StyledTableCell>
                </TableRowCustomized>
              </TableHead>
              <TableBody>
                {this.state.allUsersList.length > 0 ? (
                  this.state.allUsersList.map((singleUser) => (
                    <TableRowCustomized key={singleUser?.id}>
                      <StyledTableCell className={classes.nameOfUser}>
                        {singleUser?.attributes?.first_name} {singleUser?.attributes?.last_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TernaryCheck
                          condition={permissions.includes("Modify another's account-Account Creation")}
                          trueComponent={
                            <img
                              src={blackEditIconPng}
                              alt="blackEditIconPng"
                              className={classes.editIconStyles}
                              onClick={this.editUserClickHandler.bind(this, singleUser?.id)}
                            />
                          }
                          falseComponent={<NotInterestedIcon />}
                        />
                      </StyledTableCell>
                    </TableRowCustomized>
                  ))
                ) : (
                  <TableRowCustomized>
                    <StyledTableCell colSpan={2} align="center">
                      No users available
                    </StyledTableCell>
                  </TableRowCustomized>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomGrid>
      </Grid>
    );
  }
}

const ManageUsersWithRouter = withRouter(ManageUsers);
const ManageUsersAlertBox = withAlertBox(ManageUsersWithRouter);
const ManageUsersLoader = withLoader(ManageUsersAlertBox);
const ManageUsersToast = withToast(ManageUsersLoader);
const ManageUsersWithDialog = withDialog(ManageUsersToast);
const ManageUsersWithPermissions = withPermissions(ManageUsersWithDialog);

export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    editIconStyles: {
      cursor: "pointer",
      height: "1.5rem",
    },
    nameOfUser: {
      fontSize: "1rem",
    },
  })
)(ManageUsersWithPermissions);
