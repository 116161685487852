//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import { surveyStyles } from "./surveyStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

export const ReportingSvgIcon = ({ iconColor }) => {
  const defaultColor = "#54a4a6";
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 834 834"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 1.5,
      }}
    >
      <g>
        <path
          d="M364.43,404.894c-60.294,0 -109.172,48.878 -109.172,109.172c0,60.253 48.918,109.171 109.172,109.171c20.457,0 40.503,-5.748 57.852,-16.588l-57.852,-92.583l-0,-109.172Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M507.092,495.545c-0,-60.294 -48.878,-109.171 -109.172,-109.171l0,109.171l109.172,0Z"
          style={{
            fill: "none",
            stroke: iconColor || defaultColor,
            strokeWidth: "16.67px",
          }}
        />
        <path
          d="M449.684,608.478c28.32,-17.529 45.846,-48.198 46.569,-81.496l-98.333,-2.137l51.764,83.633Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M684.504,178.047l26.077,0c38.976,0 70.62,30.069 70.62,67.105l-0,504.828c-0,37.037 -31.644,67.105 -70.62,67.105c-0,0 -436.921,0 -436.921,0c-38.976,0 -70.62,-30.068 -70.62,-67.105l0,-35.136l38.458,0l0,35.136c0,16.867 14.411,30.562 32.162,30.562l436.921,-0c17.75,-0 32.162,-13.695 32.162,-30.562l-0,-504.828c-0,-16.867 -14.412,-30.561 -32.162,-30.561l-26.077,0l-0,-36.544Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M684.504,95.343c-0,-40.117 -32.57,-72.686 -72.686,-72.686c-0,-0 -449.706,-0 -449.706,-0c-40.117,-0 -72.687,32.569 -72.687,72.686l0,546.815c0,40.117 32.57,72.686 72.687,72.686c-0,0 449.706,0 449.706,0c40.116,0 72.686,-32.569 72.686,-72.686l-0,-546.815Zm-39.583,233.285l-0,313.53c-0,18.27 -14.833,33.103 -33.103,33.103l-449.706,0c-18.27,0 -33.103,-14.833 -33.103,-33.103l-0,-313.53l515.912,-0Zm-0,-40.386l-515.912,0l-0,-192.899c-0,-18.27 14.833,-33.103 33.103,-33.103l449.706,0c18.27,0 33.103,14.833 33.103,33.103l-0,192.899Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <rect
          x="203.04"
          y="130.995"
          width="36.971"
          height="126.605"
          style={{ fill: iconColor || defaultColor }}
        />
        <rect
          x="284.662"
          y="108.099"
          width="36.971"
          height="149.501"
          style={{ fill: iconColor || defaultColor }}
        />
        <rect
          x="368.28"
          y="170.833"
          width="36.971"
          height="86.767"
          style={{ fill: iconColor || defaultColor }}
        />
        <rect
          x="532.445"
          y="170.833"
          width="36.971"
          height="86.767"
          style={{ fill: iconColor || defaultColor }}
        />
        <rect
          x="450.363"
          y="108.099"
          width="36.971"
          height="149.501"
          style={{ fill: iconColor || defaultColor }}
        />
      </g>
    </svg>
  );
};
class ReportingStep extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    let stepNo = this.props.taxPayerType === "individual" ? "9.1" : "9.2";
    this.props.getQuestionAndOptionsByStepNo(stepNo);
  }
  render() {
    const { classes, isLoaderStep, iconColor } = this.props;
    return (
      <Grid container justifyContent="space-around" alignItems="start">
        {isLoaderStep ? (
          <Grid item xs={12} >
            <Grid
              style={{ width: "22%", margin: "0 auto" }}
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid xs={12} item>
                <Typography
                  classes={classes.loadingText}
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                  color="primary"
                  gutterBottom
                >
                  Analyzing your needs..
                </Typography>
                <Typography
                  color="primary"
                  classes={classes.loadingText}
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  Customizing your packages...
                </Typography>
                <Typography
                  color="primary"
                  classes={classes.loadingText}
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  Gathering your pricing..
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <LinearProgress
                  style={{ borderRadius: "24px", height: "12px" }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid xs={11} sm={3} item>
              <Box my={8}>
                <div
                  style={{ paddingLeft: "3rem" }}
                  className={classes.leftSideImageContainer}
                >
                  {/* <img src={Reporting} style={{display:'block',width : '100%',height : '100%'}} /> */}
                  <ReportingSvgIcon iconColor={iconColor} />
                </div>
                <Typography
                  gutterBottom
                  className={classes.sidebarHeadingWithoutMargin}
                  color="primary"
                >
                  Reporting
                </Typography>
                <Typography
                  style={{ marginBottom: "1rem" }}
                  className={classes.sidebarDescription}
                >
                  It's critical to know Tax work consists of two main elements:
                </Typography>
                <Typography
                  style={{ marginBottom: "1rem" }}
                  className={classes.sidebarDescription}
                >
                  - Reporting to the IRS what happened in your finances during
                  the last year (Tax Preparation).
                </Typography>
                <Typography className={classes.sidebarDescription}>
                  - Creating a plan to help minimize your taxes in your next
                  filing (Tax Planning).
                </Typography>
                <Typography className={classes.sidebarDescription}>
                  "Tax Preparation" is an activity that focuses on documenting
                  what happened in the past, not helping you get better results
                  in the future.
                </Typography>
                <Typography className={classes.sidebarDescription}>
                  Unlike most tax & accounting firms, we want to focus our time
                  on getting you better results in the future, and then as part
                  of doing that work, automatically take care of all the
                  historic reporting required so you don't get in trouble.
                </Typography>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid xs={8} item>
              <Grid container justifyContent="center">
                <Grid item xs={9}>
                  <Typography className={classes.pageHeading} gutterBottom>
                    {this.props?.stepContent?.description}
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Grid container>
                    {this.props?.stepContent?.options?.data?.map((option) => {
                      return (
                        <Grid
                          key={option.id}
                          item
                          xs={12}
                          md={4}
                          className={
                            this.props.reportSelection
                              ?.map((step) => {
                                return step?.id;
                              })
                              .filter((opt) => {
                                return opt === option.id;
                              }).length > 0
                              ? [
                                  classes.selectedPackageCard1,
                                  classes.minHeight11rem,
                                ]
                              : [classes.packageCard1, classes.minHeight11rem]
                          }
                          onClick={() => {
                            this.props.setReportingSelection(option);
                          }}
                        >
                          <Typography
                            className={classes.cardDescription}
                            color="textPrimary"
                          >
                            {option?.attributes?.displaydescription}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={9} className={classes.buttonContainer}>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={11} sm={4} container justifyContent="flex-end">
                      <Button
                        startIcon={<ArrowBackOutlinedIcon />}
                        color="primary"
                        onClick={() => {
                          this.props.handleBack();
                        }}
                        variant="text"
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                      <Button
                        endIcon={<ArrowForwardOutlinedIcon />}
                        color="primary"
                        disabled={(this.props.reportSelection.length <= 0)}
                        onClick={this.props.saveSurvey.bind(this)}
                        variant="text"
                        className={classes.nextButton}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}
const ReportingStepWithRouter = withRouter(ReportingStep);
const ReportingStepAlertBox = withAlertBox(ReportingStepWithRouter);
const ReportingStepLoader = withLoader(ReportingStepAlertBox);
const ReportingStepToast = withToast(ReportingStepLoader);
const ReportingStepWithDialog = withDialog(ReportingStepToast);

export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    incomeContainer: {
      width: "60%",
      margin: "auto",
      height: "50vh",
    },
    loadingText: {
      fontSize: "1.5rem",
      fontWeight: 600,
      marginBottom: "1rem",
    },
  })
)(withConfirmBox(ReportingStepWithDialog));
