//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
  Omit<MuiTextFieldProps, "name" | "value" | "error"> { }

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const InputFieldWithFormik = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      {...props}
      variant="outlined"
      InputLabelProps={{
        ...InputLabelProps, shrink: true, classes: {
          asterisk: classes?.astriklabel,
          outlined: classes?.outlinedLabel,
          shrink: classes?.shrink,
          root: classes?.lableroot
        }
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: !props.multiline && classes?.input,
          root: classes?.inputfieledroot,
          multiline: classes.multiline,
          inputMultiline: classes.inputMultiline
        },
        notched: false,
      }}
      SelectProps={{
        MenuProps:{
          disablePortal: true,
          getContentAnchorEl: null,
          anchorOrigin:{
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin:{
            vertical: 'top',
            horizontal: 'right',
          },        
          PopoverClasses: {
            // root: classes.popoverRoot,
            paper: classes.popoverPaper
          }
        }
      }}
    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot: {
      outline: 'none',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '100vmax',
      backgroundColor: '#fff',
      // transition: "all 200ms ease",
      'label + &': {
        marginTop: 30,
        marginBottom: 20,
      },
      "& svg": {
        color: theme.palette.secondary.main,
        right: 0,
        top: 0,
        fontSize: "1.4em",
      },
      '& > fieldset': {
        border: 'none',
        '& active': {
          border: 'none'
        },
        '& focus': {
          border: 'none'
        }
      },
      "&.Mui-focused": {
        border: `1px solid ${theme.palette.text.blue2}`,
        backgroundColor: '#fff',
        // boxShadow: theme.shadows[25]
      },
      // "&:hover": {
      //   border: `1px solid ${theme.palette.text.blue2}`,
      //   backgroundColor: '#fff',
      //   boxShadow: theme.shadows[25]
      // },
      "&.Mui-error": {
        border: `1px solid #FF5C5C`,
        backgroundColor: '#fff',
        "&.Mui-focused": {
          backgroundColor: '#fff',
        //   boxShadow: theme.shadows[25]
        }
      },
      "&.MuiSelect-root": {
        border: "none",
        outline: "none",
      }
    },
    input: {
      borderRadius: '100vmax',
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: '0.9rem',
      paddingLeft: "1rem",
      fontFamily: "Nunito Sans",
      fontWeight: "bold",
      fontStretch: 'normal',
      fontStyle: 'normal',
      color: theme.palette.text.blue2,
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '0.30rem',
      border: "none",
      outline: "none",
      "&:focus": {
        border: "none",
        borderRadius: '100vmax',
        backgroundColor: '#fff',
        "&::placeholder": {
          color: theme.palette.text.blue2,
        }
      },
    },
    multiline: {
      padding: 0
    },
    inputMultiline: {
      borderRadius: '100vmax',
        position: 'relative',
        backgroundColor: '#fff',
        border: 'none',
        fontSize: '1rem',
        fontFamily: 'Nunito Sans',
        // fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        "&:focus": {
            border: "none",
            borderRadius: '100vmax',
            backgroundColor: '#fff',
            "&::placeholder": {
              color: theme.palette.text.blue2,
            }
          },
    },
    astriklabel: {
      fontFamily: "Nunito Sans",
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#959ead'
    },
    lableroot: {
      fontFamily: "Nunito Sans",
      fontSize: '0.875rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: theme.palette.text.blue,
      "&.Mui-focused": {
        color: theme.palette.text.blue2
      },
    },
    outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important'
    },
    shrink: {
      transform: 'translate(3px, 9px) scale(1)!important'
    },
    popoverPaper: {
      borderRadius: "1rem",
      // boxShadow: "0 8px 18px 0 rgba(0, 0, 0, 0.09)",
      backgroundColor: "#fff",
      marginTop: "2px",
      padding: "1.25rem 0.5rem",
      boxShadow: "none",
      backgroundColor: "transparent",
      "& ul": {
        boxShadow: theme.shadows[25],
        position: "relative",
        borderRadius: "1rem",
        backgroundColor: "#fff",
        "&::before": {
          position: "absolute",
          content: "''",
          height: "0.9375rem",
          width: "0.9375rem",
          borderRadius: "2px",
          boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.09)",
          backgroundColor: "white",
          top: "-6%",
          right: "10%",
          transform: "rotate(45deg)"
        }
      },
      // "&.MuiListItem-root.Mui-selected": {
      //   backgroundColor: "#fff",
      //   "&:hover": {
      //     backgroundColor: "#f4f4f4",
      //   }
      // },
    }
  })
)(InputFieldWithFormik);
