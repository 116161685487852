//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import { dialogStyle } from "./RoadmapDialogStyle.web";
export class PastRoadmapDeleteDialog extends React.Component {
  state = {};

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        className={classes.rootContainer}
      >
        <Grid item xs={12}>
          <Typography color="primary" variant="h1">
            Delete:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h4">
            Are you sure you want to delete the Roadmap.
          </Typography>
        </Grid>

        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              className={classes.deactivateButton}
              onClick={this.closeDialog.bind(this, {
                message: "Yes",
                roadmapId: this.props?.dataToPass?.row?.id,
              })}
            >
              Yes
            </Button>
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.closeDialog.bind(this, {
                message: "No",
              })}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const PastRoadmapDeleteDialogWithRouter = withRouter(PastRoadmapDeleteDialog);
const PastRoadmapDeleteDialogWithToast = withToast(
  PastRoadmapDeleteDialogWithRouter
);
const PastRoadmapDeleteDialogWithLoader = withLoader(
  PastRoadmapDeleteDialogWithToast
);
const PastRoadmapDeleteDialogWithAlertBox = withAlertBox(
  PastRoadmapDeleteDialogWithLoader
);

export default withStyles((theme) =>
  createStyles({
    ...dialogStyle(theme),
  })
)(PastRoadmapDeleteDialogWithAlertBox);
