//@ts-nocheck
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import {client} from "../../../../components/src/HelloSignComponent.web"
export const configJSON = require("../config");
export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  packageList: object[];
  allDocumentTemplates: any;
  selectedDocument: any;
  documentTemplate: any;
  assignToPackage: string;
  customPackagesListDocumentPage: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddNewTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPackagesApiCallId : string = "";
  getDocumentTemplatesCallId: string = "";
  createDocumentTemplateApiCallId: string = "";
  updateDocumentTemplateApiCallId: string = "";
  deleteDocumentTemplateApiCallId: string = "";
  assignAllPackagesApiCallId: string = "";
  unassignAllPackagesApiCallId: string = "";
  unassignSinglePackageApiCallId: string = "";
  updateDocumentTemplateWithTemplateIdApiCallId: string = "";
  getCustomPackagesForDocumentPageAPiCallId: string = "";
  createSignatureTemplateApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      packageList: [],
      allDocumentTemplates: [],
      selectedDocument: "",
      documentTemplate: {},
      assignToPackage: "Packages",
      customPackagesListDocumentPage: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetPackagesResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        packageList : responseJson?.data,
      });
    } else if(responseJson?.errors?.error) {
      this.props.showToast({type : 'error',message : responseJson?.errors?.error_description})
      this.handleError(responseJson);
    }
    this.getCustomPackagesForDocumentPage();
  }

  handleGetDocumentTemplatesResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      let allDocumentTemplates = responseJson.data.map(docuTemp => {
        return {
          ...docuTemp,
          isTooltipOpen: false,
          isCollapseOpen: false,
          selectedPackageToAssign: "Packages",
        }
      });
      this.setState({
        allDocumentTemplates: allDocumentTemplates,
        assignToPackage: "Packages"
      },()=>{
        this.getPackages();
      })
      } else {
        this.handleError(responseJson);
      }
  }

  handleCreateDocumentTemplatesResponse = (responseJson) => {
    this.props.hideLoader();
    if(responseJson?.data){
      this.setState({
        documentTemplate: responseJson.data,
        assignToPackage: "Packages"
      })
      this.props.showToast({
        type : 'success',
        message : "Template created successfully."
      })
    } else {
      this.handleError(responseJson);
    }
  }

  handleUpdateDocumentTemplateResponse = (responseJson) => {
    this.props.hideLoader();
    if(responseJson?.data){
      this.updateDocumentTemplates(responseJson, "Template updated successfully.");
    } else {
      this.handleError(responseJson);
    }
  }

  handleDeleteDocumentTemplateResponse = (responseJson) => {
    this.props.hideLoader();
    if(responseJson?.message){
      this.props.showToast({
        type : 'success',
        message : "Document template deleted successfully."
      })
      this.getDocumentTemplates();
    } else {
      this.handleError(responseJson);
    }
  }

  updateDocumentTemplates = (responseJson, successMessage) => {
    let allDocumentTemplates = [...this.state.allDocumentTemplates].map(docuTemp => {
      if (docuTemp.id === responseJson?.data.id) {
        return {
          ...responseJson.data,
          isTooltipOpen: false,
          isCollapseOpen: false,
          selectedPackageToAssign: "Packages",
        };
      } else return {
        ...docuTemp,
        isTooltipOpen: false,
        isCollapseOpen: false,
        selectedPackageToAssign: "Packages",
      };
    });

    this.setState({
      documentTemplate: responseJson.data,
      allDocumentTemplates: allDocumentTemplates,
      assignToPackage: "Packages",
    });

    this.props.showToast({
      type: 'success',
      message: successMessage,
    });
  }

  handleAssignAllPackagesResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.updateDocumentTemplates(responseJson, "All packages assigned successfully.");
    } else {
      this.handleError(responseJson)
    }
  }

  handleUnassignAllPackagesResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.updateDocumentTemplates(responseJson, "All packages unassigned successfully.");
    } else {
      this.handleError(responseJson)
    }
  }

  handleUnassignSinglePackageResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.updateDocumentTemplates(responseJson, "Package unassigned successfully.");
    } else {
      this.handleError(responseJson)
    }
  }

  handleCustomizeTemplateResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.length > 0 ) {
      client?.open(responseJson?.data[2]);
    }
  }

  handleError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleCustomPackageResponseForDocumentPage = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        customPackagesListDocumentPage: responseJson?.data,
      });
    } else {
      this.handleError(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.createSignatureTemplateApiCallId:
          this.handleCustomizeTemplateResponse(responseJson);
          break;
        case this.getPackagesApiCallId:
          this.handleGetPackagesResponse(responseJson);
          break;
        case this.getDocumentTemplatesCallId:
          this.handleGetDocumentTemplatesResponse(responseJson);
          break;
        case this.createDocumentTemplateApiCallId:
          this.handleCreateDocumentTemplatesResponse(responseJson);
          break;
        case this.updateDocumentTemplateApiCallId:
          this.handleUpdateDocumentTemplateResponse(responseJson);
          break;
        case this.deleteDocumentTemplateApiCallId:
          this.handleDeleteDocumentTemplateResponse(responseJson);
          break;
        case this.assignAllPackagesApiCallId:
          this.handleAssignAllPackagesResponse(responseJson);
          break;
        case this.unassignAllPackagesApiCallId:
          this.handleUnassignAllPackagesResponse(responseJson);
          break;
        case this.unassignSinglePackageApiCallId:
          this.handleUnassignSinglePackageResponse(responseJson);
          break;
        case this.updateDocumentTemplateWithTemplateIdApiCallId:
          this.handleUpdateDocumentTemplateResponse(responseJson);
          break;
        case this.getCustomPackagesForDocumentPageAPiCallId:
          this.handleCustomPackageResponseForDocumentPage(responseJson);
          break;
        default:
          break;
      }      
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountId")  
        this.props.history.push("/")
      }
    }
  }

  getPackages = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token : this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPackagesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contentmanagement/package_managements?data=package_checklists`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomPackagesForDocumentPage = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getCustomPackagesForDocumentPageAPiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.customPackageEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDocumentTemplates = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDocumentTemplatesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contentmanagement/document_templates`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createDocumentTemplate = (values) => {
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDocumentTemplateApiCallId = requestMessage.messageId;

    let formData = new FormData();

    formData.append(`document_templates[title]`, values.title);
    if (this.state.selectedDocument?.file) {
      formData.append(`document_templates[document]`, this.state.selectedDocument.file);
    }
    formData.append(`document_templates[document_template_packages_attributes][0][package_management_id]`, this.state.assignToPackage);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contentmanagement/document_templates`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateDocumentTemplate = (values, documentToUpdate, packageId) => {
    this.props.showLoader();

    const [id, packageType] = packageId.split("-");

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDocumentTemplateApiCallId = requestMessage.messageId;

    let formData = new FormData();

    let url = `bx_block_contentmanagement/document_templates/${+this.state.documentTemplate.id}`

    if(values !== null) {
      if(packageType === "package_management") {
        formData.append(`document_templates[title]`, values.title);
        formData.append(`document_templates[document_template_packages_attributes][0][package_management_id]`, this.state.assignToPackage);
      } else {
        formData.append(`document_templates[title]`, values.title);
        formData.append(`document_templates[document_template_packages_attributes][0][custom_package_management_id]`, this.state.assignToPackage);
      }
    } else {
      if(packageType === "package_management") {
        formData.append(`document_templates[title]`, documentToUpdate.attributes.title);
        formData.append(`document_templates[document_template_packages_attributes][0][package_management_id]`, +id);
      } else {
        formData.append(`document_templates[title]`, documentToUpdate.attributes.title);
        formData.append(`document_templates[document_template_packages_attributes][0][custom_package_management_id]`, +id);
      }
      url = `bx_block_contentmanagement/document_templates/${+documentToUpdate.id}`
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteDocumentTemplate = (templateId) => {
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDocumentTemplateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contentmanagement/document_templates/${+templateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  assignAllPackages = (values, documentTemplate) => {
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignAllPackagesApiCallId = requestMessage.messageId;

    let documentTemplateId = documentTemplate?.id || this.state.documentTemplate.id;

    let selectedDocumentTemplate = documentTemplate || this.state.documentTemplate;

    let method = "PUT";
    let url = `bx_block_contentmanagement/document_templates/${+documentTemplateId}`;
    let formData = new FormData();

    let documentTitle = values?.title || documentTemplate?.attributes?.title;

    formData.append(`document_templates[title]`, documentTitle);

    let packageListTemp = [...this.state.packageList, ...this.state.customPackagesListDocumentPage];

    packageListTemp = packageListTemp.filter((packageItem) => {
      let index = selectedDocumentTemplate.attributes?.document_template_packages?.data.findIndex(doc => +packageItem.id === +doc.attributes.package_management_id || +packageItem.id === +doc.attributes.custom_package_management_id);
      return index == -1
    });
    

    if(packageListTemp.length === 0) {
      this.props.hideLoader();
      this.props.showToast({
        type: "error",
        message: "All packages are already assigned."
      })
      return
    } else {
      for (const [index, packageItem] of packageListTemp.entries()) {
        if (packageItem.type === "package_management") {
          formData.append(`document_templates[document_template_packages_attributes][${index}][package_management_id]`, packageItem.attributes.id);
        } else {
          formData.append(`document_templates[document_template_packages_attributes][${index}][custom_package_management_id]`, packageItem.attributes.id);
        }
      }        
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unassignAllPackages = (values, documentTemplate) => {
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unassignAllPackagesApiCallId = requestMessage.messageId;

    let method = "POST";
    let url = `bx_block_contentmanagement/document_templates`;

    let formData = new FormData();

    if(values !== null) {
      if(this.state.documentTemplate?.id) {
        method = "PUT";
        url = `bx_block_contentmanagement/document_templates/${+this.state.documentTemplate.id}`
      }

      formData.append(`document_templates[title]`, values.title);
      this.state.documentTemplate?.attributes?.document_template_packages?.data.map((packageItem, index) => {
        formData.append(`document_templates[document_template_packages_attributes][${index}][id]`, packageItem.attributes.id);
        formData.append(`document_templates[document_template_packages_attributes][${index}][_destroy]`, 1);
      })

    } else {
      if(documentTemplate?.id) {
        method = "PUT";
        url = `bx_block_contentmanagement/document_templates/${+documentTemplate.id}`
      }

      formData.append(`document_templates[title]`, documentTemplate.attributes.title);

      if(documentTemplate?.attributes?.document_template_packages?.data.length === 0) {
        this.props.hideLoader();
        this.props.showToast({
          type: "error",
          message: "No packages available to unassign."
        })
        return
      } else {
        documentTemplate?.attributes?.document_template_packages?.data.map((packageItem, index) => {
          formData.append(`document_templates[document_template_packages_attributes][${index}][id]`, packageItem.attributes.id);
          formData.append(`document_templates[document_template_packages_attributes][${index}][_destroy]`, 1);
        })
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unassignSinglePackage = (packageToRemove, templateId) => {
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unassignSinglePackageApiCallId = requestMessage.messageId;

    let formData = new FormData();

    formData.append(`document_templates[document_template_packages_attributes][0][id]`, packageToRemove.attributes.id);
    formData.append(`document_templates[document_template_packages_attributes][0][_destroy]`, 1);

    let url = `bx_block_contentmanagement/document_templates/${+this.state.documentTemplate.id}`

    if(templateId) {
      url = `bx_block_contentmanagement/document_templates/${+templateId}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
 
  updateDocumentTemplateWithTemplateId = (templateId)=>{
    this.props.showLoader();

    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDocumentTemplateWithTemplateIdApiCallId = requestMessage.messageId;

    

    let url = `bx_block_contentmanagement/document_templates/${+this.state.selectedDocumentId}`

    if(templateId) {
      let formData = new FormData();
      formData.append(`document_templates[template_id]`, templateId);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage)
    }
       
  }
}
// Customizable Area End