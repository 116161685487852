//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import UploadBox from "../UploadBoxPhaseTwo.web";

export class SingleClientImageUpload extends React.Component {
  
  state = {
    thumbnail_image: null,
    serverImage: this.props.dataToPass.singleClient.attributes.avatar,
  };

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
       this.setState({
        token: token,
      });
    }
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  removeThumbnailImage = () => {
    this.setState({
      thumbnail_image: null,
    });
  };
  removeServerImage = () => {
    this.setState({
      serverImage: null,
    });
  };
  handleThumbnailImage = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      thumbnail_image: filePaths[0],
    });
  };
  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };
  
  showCorrectImage = () => {
    const { classes } = this.props;
    if (this.state.thumbnail_image) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs="auto" style={{ position: "relative", height: "300px" }}>
            <CloseIcon
              onClick={this.removeThumbnailImage}
              className={classes.crossOverImage}
            />
            <img
              src={this.state.thumbnail_image?.base64}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "6px",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      );
    } else if (this.state.serverImage) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs="auto" style={{ position: "relative", height: "300px" }}>
            <CloseIcon
              onClick={this.removeServerImage}
              className={classes.crossOverImage}
            />
            <img
              src={this.state.serverImage}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "6px",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <UploadBox
          multiple={false}
          uploadMsg={
            <>
              <p>
                Upload an image <br /> from your device
              </p>
              <Typography className={classes.imageSubMsg}>Please select a 1:1 image for better visibility.</Typography>
            </>
          }
          acceptedFormat={".png, .jpg, .jpeg"}
          onTouched={() => {}}
          height="18rem"
          onSelectFile={this.handleThumbnailImage}
          {...this.props.dataToPass}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ width: "40vw" }}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.dialogHeading}>
            Upload Image
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {this.showCorrectImage()}
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.closeDialog.bind(this,this.state.thumbnail_image?.base64)}
            disabled={this.state.thumbnail_image === null}
            className={classes.uploadNowButton}
          >
            Upload Now
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const SingleClientImageUploadWithRouter = withRouter(SingleClientImageUpload);
const SingleClientImageUploadWithToast = withToast(SingleClientImageUploadWithRouter);
const SingleClientImageUploadWithLoader = withLoader(SingleClientImageUploadWithToast);
const SingleClientImageUploadWithAlertBox = withAlertBox(SingleClientImageUploadWithLoader);
const SingleClientImageUploadWithDialogBox = withDialogBox(SingleClientImageUploadWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
    imageSubMsg: {
      fontSize: "0.8rem",
      color: theme.palette.text.secondary,
    },
  })
)(SingleClientImageUploadWithDialogBox);
