const selectedPackageCard = {
  position: "relative",
 
  borderRadius: "1.875rem",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
  padding: "0.5rem 3rem",
  margin: "0.7rem 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  flexGrow: 0,
  maxWidth: "30%",
  flexBasis: "30%",
  marginRight: "3%",
  "&::after": {
    position: "absolute",
    content: "'✓'",
    color: '#fff',
    fontSize: '16px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: "cover",
    right: "3%",
    top: "3%",
    height: "1.4875rem",
    width: "1.4875rem",
  },
  
};
const packageCard = {
  border: `2px solid white`,
  borderRadius: "1.875rem",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
  padding: "0.5rem 3rem",
  margin: "0.7rem 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  flexGrow: 0,
  maxWidth: "30%",
  flexBasis: "30%",
  marginRight: "3%",
  
}
export const surveyStyles = (theme) => {
  return {
    svgRoot: {
      "& #Group": {
        fill: "red",
      },
    },
    packageCard: {...packageCard,[theme.breakpoints.down("sm")]: {
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      marginRight: 0,
    }},
    maingrid2: {
      width: "55%",
      margin: "0 auto",
      "@media(max-width:600px)": {
        width: "unset",
      }
    },
    faqQustionText: {
      display: "flex",
      justifyContent: 'space-between',
      width: '100%',
      "@media(max-width:600px)": {
        display: "block"
      }
    },
    maingrid3: {
      width: "56%",
      margin: "0 auto",
      "@media(max-width:600px)": {
        width: "100%"
      }
    },
    openFaqPagetext: {
      display: "flex",
      gap: "7px",
      cursor: "pointer",
      marginLeft: 50,
      flex: 1,
      justifyContent: "end",
      "@media(max-width:600px)": {
        justifyContent: "center",
        marginLeft: "unset",
      }
    },
    selectedPackageCard: {...selectedPackageCard,border: `2px solid ${theme.palette.primary.main}`},
    packageCard1: {
      ...packageCard,
      padding: "2rem",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      }
      
    },
    selectedPackageCard1: {
      ...selectedPackageCard,
      boxSizing: "border-",
      border: `2px solid ${theme.palette.primary.main}`,
      padding: "2rem",
    
    
    },
    minHeight10rem: {
      minHeight: "10rem",
    },
    minHeight11rem: {
      minHeight: "11rem",
    },
    minHeight12rem: {
      minHeight: "12rem",
    },
    minHeight13rem: {
      minHeight: "13rem",
    },
    minHeight14rem: {
      minHeight: "14rem",
    },
    minHeight15rem: {
      minHeight: "15rem",
    },
    minHeight16rem: {
      minHeight: "16rem",
    },
    minHeight17rem: {
      minHeight: "17rem",
    },
    minHeight18rem: {
      minHeight: "18rem",
    },
    pageHeading: {
      fontSize: "1.75rem",
      fontWeight: 700,
      color: theme.palette.text.primary,
      marginTop: "2rem",
    },
    pageSubHeading: {
      fontSize: "1.2rem",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("md")]: {
        fontSize: "1.2rem",
      },
    },
    question: {
      fontSize: "1.3rem",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("md")]: {
        fontSize: "1.3rem",
      },
    },
    cardDescription: {
      fontSize: "1rem",
      color: theme.palette.text.secondary,
      textAlign: "center",
      fontWeight: 600,
      // fontWeight: "bold"
    },
    cardDescriptionHighlight: {
      fontSize: "1rem",
      color: "red",
      textAlign: "center",
      fontWeight: 600,
    },
    cardDescriptionLarge: {
      fontSize: "1.3rem",
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
    sidebarHeading: {
      fontSize: "3rem",
      fontWeight: "bold",
      paddingLeft: "3rem",
      marginTop: "8rem",
    },
    sidebarHeadingWithoutMargin: {
      fontSize: "3rem",
      fontWeight: "bold",
      paddingLeft: "3rem",
    },
    sidebarDescription: {
      fontSize: "1.2rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      marginBottom: "2rem",
      textAlign: "justify",
      paddingLeft: "3rem",
    },
    sidebarDescriptionSmall: {
      fontSize: "1.1rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      marginBottom: "1rem",
      textAlign: "justify",
      paddingLeft: "3rem",
    },
    buttonContainer: {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      paddingRight: "3%",
    },
    backButton: {
      marginRight: "3rem",
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: "1.3rem",
      textTransform: "capitalize",
      color: theme.palette.text.secondary,
      "&:hover": {
        color: theme.palette.text.secondary,
      },
    },
    nextButton: {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: "1.3rem",
      textTransform: "capitalize",
    },
    bottomMargin2rem: {
      marginBottom: "2rem",
    },
    bottomMargin1rem: {
      marginBottom: "1rem",
    },
    leftSideImageContainer: {
      width: "112px",
      height: "auto",
      paddingLeft: "2rem",
    },
  };
};

export const aboutUsStyle = (theme) => {
  return {
    accordionHeading: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    mainGrid: {
      width: "55%",
      margin: "0 auto",
      "@media(max-width : 600px)": {
        width: "unset",
      },
    },
    accordionWhite: {
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      borderRadius: "2rem",
      padding: "0.5rem 0",
    },
    accordionRound: {
      borderRadius: `${theme.spacing(2)}px!important`,
      border: "2px solid #fff",
    },
    accordionRoundError: {
      borderRadius: `${theme.spacing(2)}px!important`,
      border: "2px solid red",
    },
    accordionExpandIcon: {
      background: theme.palette.primary.main,
      borderRadius: "50%",
      color: "#fff",
      padding: "2px",
      marginRight: "-6px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    buttonRoot: {
      borderRadius: theme.spacing(1),
      padding: '1rem',
      boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "70%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    fieldPageSubHeading: {
      marginBottom: "2rem",
      color: theme.palette.text.secondary,
      fontSize: "1.2rem",
      fontWeight: 600,
    },
    includeText: {
      marginTop: "1.5rem",
      color: theme.palette.text.secondary,
    },
    dollarSign: {
      fontSize: "1.7rem",
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    statusHeading: {
      fontSize: "1.5rem",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    sliderLabel: {
      fontSize: "1rem",
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    yesNoText: {
      fontSize: "1rem",
      color: theme.palette.text.secondary,
      fontFamily: "Nunito Sans",
      fontWeight: 600,
    },
    textFieldBottomMargin: {
      marginBottom: "1rem",
    },
  };
};