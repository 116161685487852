// @ts-nocheck
// Customizable Area Start
import React from "react";
import DocumentTemplatesController, { Props } from "./AddNewTemplateController.web";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router-dom";
import withDialog from "../../../../components/src/withDialog.web";
import { Typography, Button, Grid, withStyles, createStyles, Collapse, MenuItem } from "@material-ui/core";
import { moreIcon } from "../assets";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import InputField from "../../../../components/src/PhaseTwoField.web";
import { SectionTooltip } from "../../../../components/src/ModifiedComponents.web";
import DeleteDialogWeb from "../ClientList/DeleteDialog.web";
import SearchIcon from "@material-ui/icons/Search";
import ViewDocumentDialogWeb from "./ViewDocumentDialog.web";
import DocViewer from "@cyntler/react-doc-viewer";
import { client } from "../../../../components/src/HelloSignComponent.web";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TernaryCheck from "../TernaryCheck.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import DeleteIcon from '@material-ui/icons/Delete';

const MyCustomDocRenderer: any = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div style={{ width: "100%", height: 300 }} id="my-png-renderer">
      <iframe style={{ width: "100%", height: 300 }} src={`https://docs.google.com/viewer?url=${currentDocument.uri}&embedded=true`} />
    </div>
  );
};
MyCustomDocRenderer.fileTypes = ["doc", "docx"];
MyCustomDocRenderer.weight = 1;
class DocViewerContianer extends React.PureComponent {
  shouldComponentUpdate(nextProps) {
    if (this.props?.documentItem?.attributes?.document != nextProps?.documentItem?.attributes?.document) {
      return true;
    }
    return false;
  }
  render(): React.ReactNode {
    return (
      <DocViewer
        documents={[{ uri: this.props.documentItem?.attributes?.document, fileType: "doc" }]}
        prefetchMethod="GET"
        config={{
          header: {
            disableHeader: true,
          },
        }}
        pluginRenderers={[MyCustomDocRenderer]}
        style={{ width: "100%", height: 300 }}
      />
    );
  }
}

export class DocumentTemplates extends DocumentTemplatesController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { permissions } = this.props;
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        if (permissions.includes("View Documents (pre signature)-eSignatures")) {
          this.getDocumentTemplates();
        }
      }
    );
    client.on("createTemplate", (data) => {
      console.log(data);
      this.updateDocumentTemplateWithTemplateId(data?.templateId);
    });
  }

  openDeleteTemplateDialog = (templateToDelete) => {
    this.props
      .openDialogBox({
        dataToPass: {
          dialogHeading: "Delete Template",
          description: (
            <>
              Are you sure you want to delete this template? <br /> This can not be undone.
            </>
          ),
        },
        width: "35%",
        renderedComponent: DeleteDialogWeb,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.deleteDocumentTemplate(templateToDelete.id);
        }
      });
  };

  openUnassignSinglePackageDialog = (documentPackage, templateId) => {
    this.props
      .openDialogBox({
        dataToPass: {
          dialogHeading: "Unassign Package",
          description: "Are you sure you want to unassign this package?",
        },
        width: "35%",
        renderedComponent: DeleteDialogWeb,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.unassignSinglePackage(documentPackage, templateId);
        }
      });
  };

  openViewDocumentTemplateDialog = (documentItem) => {
    this.props
      .openDialogBox({
        dataToPass: {
          documentItem,
        },
        width: "100%",
        renderedComponent: ViewDocumentDialogWeb,
        withCustomDialog: false,
      })
      .then(async (data: any) => { });
  };

  mapMenuItem = (packageItem) => {
    return (
      <MenuItem key={packageItem.id} value={`${packageItem.attributes.id}-${packageItem.type}`}>
        {packageItem.attributes.name}
      </MenuItem>
    );
  };

  addNewTemplateHandler = () => {
    this.setState(
      {
        documentTemplate: null,
      },
      () => {
        this.props.history.push("documentTemplates/addNew");
      }
    );
  };

  deleteDocumentHandler = (documentItem, indexDocumentItem) => {
    const allDocumentTemplates = [...this.state.allDocumentTemplates];
    allDocumentTemplates[indexDocumentItem] = { ...allDocumentTemplates[indexDocumentItem], isTooltipOpen: false };
    this.setState({
      allDocumentTemplates: allDocumentTemplates,
    });
    this.openDeleteTemplateDialog(documentItem);
  };

  showHideDocument = (indexDocumentItem) => {
    const allDocumentTemplates = [...this.state.allDocumentTemplates];
    allDocumentTemplates[indexDocumentItem] = {
      ...allDocumentTemplates[indexDocumentItem],
      isCollapseOpen: !allDocumentTemplates[indexDocumentItem].isCollapseOpen,
    };
    this.setState({
      allDocumentTemplates: allDocumentTemplates,
    });
  };

  selectFieldHandler = (indexDocumentItem, event) => {
    const allDocumentTemplates = [...this.state.allDocumentTemplates];
    allDocumentTemplates[indexDocumentItem] = {
      ...allDocumentTemplates[indexDocumentItem],
      selectedPackageToAssign: event.target.value,
    };
    this.setState({
      allDocumentTemplates: allDocumentTemplates,
    });
  };

  render(): React.ReactNode {
    const { classes, permissions } = this.props;
    return (
      <Grid container justifyContent="center">
        <CustomGrid item container justifyContent="space-between" xs={12}>
          <Typography className={classes.pageHeading} color="primary">
            Document Templates
          </Typography>
          <TernaryCheck
            condition={!permissions.includes("View Documents (pre signature)-eSignatures")}
            trueComponent={
              <Button color="primary" variant="contained" disabled>
                + Add New Template
              </Button>
            }
            falseComponent={
              <Button color="primary" variant="contained" onClick={this.addNewTemplateHandler}>
                + Add New Template
              </Button>
            }
          />
        </CustomGrid>
        <CustomGrid item xs={12}>
          <TernaryCheck
            condition={!permissions.includes("View Documents (pre signature)-eSignatures")}
            trueComponent={
              <Grid container justifyContent="flex-start" alignItems="flex-start" className={[classes.gridSpacing, classes.whiteCard]}>
                <CustomTypography align="center" fontSize="1.5rem" color="error">
                  Permission denied for current user.
                </CustomTypography>
              </Grid>
            }
            falseComponent={
              <Grid container justifyContent="flex-start" alignItems="flex-start" className={[classes.gridSpacing, classes.whiteCard]}>
                {this.state.allDocumentTemplates &&
                  this.state.allDocumentTemplates.length > 0 &&
                  this.state.allDocumentTemplates.map((documentItem, indexDocumentItem) => {
                    let packageListTemp = [...this.state.packageList, ...this.state.customPackagesListDocumentPage];

                    packageListTemp = packageListTemp.filter((packageItem) => {
                      let index = documentItem.attributes?.document_template_packages?.data.findIndex(
                        (doc) => +packageItem.id === +doc.attributes.package_management_id || +packageItem.id === +doc.attributes.custom_package_management_id
                      );
                      return index == -1;
                    });

                    return (
                      <CustomGrid item key={documentItem?.id} width="48%" margin="0 1% 0 1%">
                        <Grid
                          container
                          justifyContent="center"
                          className={documentItem?.attributes?.template_id ? classes.templateCardWithCustomizeTemplate : classes.templateCard}
                        >
                          <div style={{display:"flex", justifyContent:"end", width:"100%", alignItems:"center"}}>
                          <CustomGrid item xs={12}>
                            <CustomTypography fontSize="1.3rem" color="secondary" align="center">
                              {documentItem?.attributes?.title || "NA"}
                            </CustomTypography>
                          </CustomGrid>
                          <DeleteIcon color = "error" onClick={this.deleteDocumentHandler.bind(this, documentItem, indexDocumentItem)} />
                          </div>
                          <CustomGrid item xs={12} container justifyContent="center">
                            <CustomTypography
                              color="textSecondary"
                              onClick={this.showHideDocument.bind(this, indexDocumentItem)}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {documentItem?.isCollapseOpen ? "Hide Document" : "Preview Document"}
                            </CustomTypography>
                          </CustomGrid>
                          <Collapse
                            in={documentItem?.isCollapseOpen}
                            mountOnEnter
                            unmountOnExit
                            style={{
                              width: "100%",
                            }}
                          >
                            <CustomGrid item xs={12} className={classes.innerTemplateCard}>
                              <DocViewerContianer documentItem={documentItem} />
                            </CustomGrid>
                          </Collapse>
                          <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center">
                            <CustomTypography margin="0.5rem 0" fontWeight={600} color="secondary" fontSize="1.1rem">
                              Assigned Packages
                            </CustomTypography>
                            <SearchIcon className={classes.searchIconStyles} onClick={this.openViewDocumentTemplateDialog.bind(this, documentItem)} />
                          </CustomGrid>
                          <Grid container justifyContent="center" alignItems="flex-start">
                            <CustomGrid item xs={12} container justifyContent="space-between" alignItems="center" alignContent="flex-start">
                              <CustomGrid width="48%">
                                <InputField
                                  value={this.state.allDocumentTemplates[indexDocumentItem].selectedPackageToAssign}
                                  name={`allDocumentTemplates[${indexDocumentItem}].selectedPackageToAssign`}
                                  select
                                  onChange={this.selectFieldHandler.bind(this, indexDocumentItem)}
                                  fullWidth
                                >
                                  <MenuItem value={"Packages"} disabled>
                                    Packages
                                  </MenuItem>
                                  {packageListTemp.map(this.mapMenuItem)}
                                </InputField>
                              </CustomGrid>

                              <CustomGrid item width="48%" container alignItems="center" justifyContent="center">
                                <CustomTypography
                                  fontWeight={600}
                                  fontSize="1rem"
                                  color="secondary"
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.assignAllPackages.bind(this, null, documentItem)}
                                >
                                  Assign to All{" "}
                                </CustomTypography>
                                <CustomTypography
                                  fontWeight={600}
                                  fontSize="1rem"
                                  color="secondary"
                                  style={{
                                    padding: "0 0.25rem",
                                  }}
                                >
                                  /
                                </CustomTypography>
                                <CustomTypography
                                  fontWeight={600}
                                  fontSize="1rem"
                                  color="secondary"
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.unassignAllPackages.bind(this, null, documentItem)}
                                >
                                  Unassign All
                                </CustomTypography>
                              </CustomGrid>
                            </CustomGrid>

                            <CustomGrid item xs={12} className={classes.scrollableContainer}>
                              {documentItem?.attributes?.document_template_packages?.data.length > 0 ? (
                                documentItem?.attributes?.document_template_packages?.data.map((documentPackage) => {
                                  return (
                                    <Grid
                                      key={documentPackage.id}
                                      item
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      xs={12}
                                      className={classes.checklistContainer}
                                    >
                                      <CustomTypography color="textSecondary" padding="0.5rem 0">
                                        {documentPackage.attributes.package_management_name}
                                      </CustomTypography>
                                      <RemoveCircleIcon
                                        onClick={() => this.openUnassignSinglePackageDialog(documentPackage, documentItem.id)}
                                        className={classes.crossButton}
                                      />
                                    </Grid>
                                  );
                                })
                              ) : (
                                <Grid item container justifyContent="space-between" xs={12} className={classes.checklistContainer}>
                                  <CustomTypography color="textSecondary" padding="0.5rem 0">
                                    No packages assigned
                                  </CustomTypography>
                                </Grid>
                              )}
                            </CustomGrid>

                            <CustomGrid item width="40%">
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={this.state.allDocumentTemplates[indexDocumentItem].selectedPackageToAssign === "Packages"}
                                onClick={this.updateDocumentTemplate.bind(
                                  this,
                                  null,
                                  documentItem,
                                  this.state.allDocumentTemplates[indexDocumentItem].selectedPackageToAssign
                                )}
                              >
                                Done
                              </Button>
                            </CustomGrid>
                          </Grid>
                        </Grid>
                      </CustomGrid>
                    );
                  })}
              </Grid>
            }
          />
        </CustomGrid>
      </Grid>
    );
  }
}

const DocumentTemplatesWithRouter = withRouter(DocumentTemplates);
const DocumentTemplatesAlertBox = withAlertBox(DocumentTemplatesWithRouter);
const DocumentTemplatesLoader = withLoader(DocumentTemplatesAlertBox);
const DocumentTemplatesWithToast = withToast(DocumentTemplatesLoader);
const DocumentTemplatesWithDialog = withDialog(DocumentTemplatesWithToast);
const DocumentTemplatesWithPermissions = withPermissions(DocumentTemplatesWithDialog);

export default withStyles((theme) =>
  createStyles({
    center: {
      textAlign: "center",
    },
    templateCard: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      padding: "1rem",
      position: "relative",
      marginBottom: "1rem",
    },
    innerTemplateCard: {
      margin: "0.75rem 0",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "0.75rem",
      backgroundColor: "#ffffff",
      padding: "0.75rem",
      position: "relative",
    },
    moreButtonIcon: {
      cursor: "pointer",
      position: "absolute",
      height: "1.3rem",
      width: "1.3rem",
      top: "8px",
      right: "8px",
    },
    marginTop: {
      marginTop: "3rem",
    },
    gridSpacing: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 0),
      },
    },
    whiteCard: {
      padding: theme.spacing(4),
      borderRadius: theme.spacing(2),
      backgroundColor: "#ffffff",
      boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1.5),
      },
    },
    heading: {
      fontWeight: 600,
      fontSize: "1.2rem",
    },
    pageHeading: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    scrollableContainer: {
      marginTop: "1rem",
      minHeight: "20vh",
      maxHeight: "20vh",
      overflowY: "auto",
      paddingRight: "0.5rem",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "100px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
        borderRadius: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c9c9c9",
        borderRadius: "0.5rem",
      },
    },
    crossButton: {
      cursor: "pointer",
      color: theme.palette.error.main,
    },
    tooltipOptions: {
      padding: "0.25rem 0.5rem",
      borderRadius: "0.5rem",
      fontSize: "1rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ececec",
      },
    },
    searchIconStyles: {
      cursor: "pointer",
      color: theme.palette.text.secondary,
    },
    templateCardWithCustomizeTemplate: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      padding: "1rem",
      position: "relative",
      marginBottom: "1rem",
    },
  })
)(DocumentTemplatesWithPermissions);
// Customizable Area End