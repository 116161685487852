//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Box,
  IconButton,
} from "@material-ui/core";

type PlusMinusButtonProps = {
  initialValue: number;
  onDecrement: () => void;
  onIncrement: () => void;
  isDisable: boolean;
};

class PlusMinusButton extends React.Component<PlusMinusButtonProps> {
  constructor(props: PlusMinusButtonProps) {
    super(props);
  }

  render() {
    const {
      classes,
      theme,
      initialValue,
      onDecrement,
      onIncrement,
      disableValue = 0,
      isDisable = false,
      iconStyle = {},
      padding = "2px",
    } = this.props;
    return (
      <Box style={{ padding: padding }} className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <IconButton
            onClick={(event)=>{
              event.stopPropagation();
              if(initialValue > disableValue){
                onDecrement(event);
              }
            }}
            // disabled={initialValue <= disableValue || isDisable}
            style={iconStyle}
            className={classes.iconButton}
          >
            -
          </IconButton>

          <Typography
            display="inline"
            align="center"
            className={classes.number}
          >
            {initialValue}
          </Typography>

          <IconButton
            style={iconStyle}
            onClick={onIncrement}
            disabled={isDisable}
            className={classes.iconButton}
          >
            +
          </IconButton>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      root: {
        border: `1px solid ${theme.palette.primary.main}`,
        boxSizing: "border-box",
        padding: "2px",
        borderRadius: "4px",
        display: "inline-block",
        backgroundColor: "#ffffff",
      },
      iconButton: {
        height: "1.375rem",
        width: "1.375rem",
        borderRadius: "4px",
        fontSize: "0.9rem",
        fontWeight: "bold",
        color: "#fff",
        display: "inline-block",
        textAlign: "center",
        padding: 0,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          color: "#fff",
          backgroundColor: theme.palette.primary.main,
        },
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
          boxShadow: "none",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      },
      number: {
        display: "inline-block",
        fontFamily: "Nunito Sans",
        fontSize: "0.9rem",
        padding: "0px 0.5rem",
      },
    }),
  { withTheme: true }
)(PlusMinusButton);
