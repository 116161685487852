// @ts-nocheck
import React from "react";
import ThirdPartyIntegrationController, {
  Props,
} from "./ThirdPartyIntegrationController.web";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router-dom";
import withDialog from "../../../../components/src/withDialog.web";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
  Icon,
} from "@material-ui/core";
import {
  cpaChargeIntegrations,
  googleDriveIntegrations,
  calendlyIntegrations,
} from "../assets";
import CalendlyIntegration from "./calendlyIntegration.web";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/CheckCircle";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import { GoogleLogin } from "react-google-login";

export class ThirdPartyIntegration extends ThirdPartyIntegrationController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get("code");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        if (code) {
          this.getAccessTokenOfCPACharge(code);
        }
        this.getCFSSettingFortpi();
        this.getCPADetialbyAccountId();
        this.getGoogle();
      }
    );
  }

  checkIfClientSecrentPresent = (url) => {
    let clientId = "";
    if(url == 'https://smartpathco-82651-react-native.b82651.dev.eastus.az.svc.builder.cafe'){
      clientId = "f20a83b0edc00446fd3393674215899effa680d1b43aa88420ba3f1c1a7f473a";
    } else if(url == 'https://smartpathco-82651-react-native.b82651.stage.eastus.az.svc.builder.ai'){
      clientId = "49a665dc2e06c9837a1bc9de872229406698d000b9f0ed8c5165cbab8814b8fd";
    } else if(url == 'https://app.smartpath.co'){
      clientId = "06e274c0dcdc4ee60ba01209c75e30a6505c7d7a3420d9d8fb7ecee1af18f2fb";
    } else {
      clientId = "f20a83b0edc00446fd3393674215899effa680d1b43aa88420ba3f1c1a7f473a";
    }
    window.location.href = `https://secure.cpacharge.com/oauth/authorize?redirect_uri=${
      url
    }/home/settings/integrations&client_id=${clientId}&scope=payments&response_type=code`;
  };

  openCaliendlylDialog = () => {
    window.open("https://calendly.com/event_types/user/me", "_target",'noopener');
    this.props
      .openDialogBox({
        dataToPass: {
          meetingLink: this.state.meeting_link,
        },
        width: "60%",
        renderedComponent: CalendlyIntegration,
        withCustomDialog: false,
      })
      .then((data) => {
        this.updateCFSSetting({ meetingLink: data.meetingLink }, false);
      });
  };
  responseGoogleSuccess = async (data) => {
    const profileData = data?.profileObj;
    if (profileData?.email && profileData?.googleId) {
      this.connectToGoogle(data);
    }
  };
  responseGoogleFailure = (data) => {};
  render(): React.ReactNode {
    const { classes, permissions } = this.props;
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = this.state.googleObj?.tokenObj?.expires_at / 1000;
    return (
      <Grid justifyContent="center" container>
        <Grid item xs={12}>
          <Typography className={classes.pageHeading} color="primary">
            Integrations
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            className={[classes.gridSpacing, classes.whiteCard]}
          >
            <Grid item xs={12}>
              <Typography className={classes.heading} color="textPrimary">
                Available Integrations
              </Typography>
            </Grid>
            <CustomGrid
              item
              xs={3}
              width="31%"
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              className={classes.packageCard}
            >
              {this.state.payment_access_token ? (
                <Icon className={classes.icon}>
                  <DoneIcon color="primary" />
                </Icon>
              ) : (
                <Icon className={classes.icon}>
                  <CancelIcon color="error" />
                </Icon>
              )}
              <Grid
                item
                container
                alignItems="flex-start"
                justifyContent="center"
                style={{
                  minHeight: "150px",
                }}
              >
                <img style={{ width: "160px" }} src={cpaChargeIntegrations} />
              </Grid>
              <Typography className={classes.integrationNames}>
                CPA Charge
              </Typography>
              <Typography className={classes.integrationDescription}>
                Automatically collect payments and manage monthly billing
                through SmartPath.
              </Typography>
              <Button
                variant="contained"
                color={
                  this.state.payment_access_token ? "secondary" : "primary"
                }
                onClick={() => {
                  if (this.state.payment_access_token) {
                    this.disconnectFromCPACharge();
                  } else {
                    this.checkIfClientSecrentPresent(window.location.origin);
                  }
                }}
                className={classes.connectDisconnectButton}
                disabled={
                  !permissions.includes("Make payment (CPA Charge)-Payments")
                }
              >
                {this.state.payment_access_token ? "Disconnect" : "Connect"}
              </Button>
              <Typography className={classes.createAccountText}>
                Don't have an account? Create one{" "}
                <a
                  href="https://www.cpacharge.com/partners/smartpath/"
                  target="_blank"
                  rel="noopener"
                >
                  here
                </a>
              </Typography>
            </CustomGrid>
            <CustomGrid
              item
              width="31%"
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              className={classes.packageCard}
            >
              {this.state.meeting_link ? (
                <Icon className={classes.icon}>
                  <DoneIcon color="primary" />
                </Icon>
              ) : (
                <Icon className={classes.icon}>
                  <CancelIcon color="error" />
                </Icon>
              )}
              <Grid
                item
                container
                alignItems="flex-start"
                justifyContent="center"
                style={{
                  minHeight: "150px",
                }}
              >
                <img style={{ width: "110px" }} src={calendlyIntegrations} />
              </Grid>
              <Typography className={classes.integrationNames}>
                Calendly
              </Typography>
              <Typography className={classes.integrationDescription}>
                Allow clients to book a roadmap review meeting directly from
                your Conversion Survey
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.openCaliendlylDialog();
                }}
                className={classes.connectDisconnectButton}
              >
                {this.state.meeting_link ? "Connected" : "Connect"}
              </Button>
            </CustomGrid>
            <CustomGrid
              item
              width="31%"
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              className={classes.packageCard}
            >
              {this.state.googleObj ? (
                <Icon className={classes.icon}>
                  <DoneIcon color="primary" />
                </Icon>
              ) : (
                <Icon className={classes.icon}>
                  <CancelIcon color="error" />
                </Icon>
              )}
              <Grid
                item
                container
                alignItems="flex-start"
                justifyContent="center"
                style={{
                  minHeight: "150px",
                }}
              >
                <img style={{ width: "110px" }} src={googleDriveIntegrations} />
              </Grid>
              <Typography className={classes.integrationNames}>
                G Suite
              </Typography>
              <Typography className={classes.integrationDescription}>
                Integrate your Firm's G Suite with SmarthPath
              </Typography>
              {!this.state.googleObj || expirationTime < currentTime ? (
                <GoogleLogin
                  clientId="870709959925-tstesujqeqfhstl71euckvhfor6e45f7.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={renderProps.onClick}
                      className={classes.connectDisconnectButton}
                    >
                      Connect
                    </Button>
                  )}
                  buttonText="Login"
                  scope="https://www.googleapis.com/auth/drive"
                  onSuccess={this.responseGoogleSuccess}
                  onFailure={this.responseGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.disconnectGoogle}
                  className={classes.connectDisconnectButton}
                >
                  Disconnect
                </Button>
              )}
            </CustomGrid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ThirdPartyIntegrationWithRouter = withRouter(ThirdPartyIntegration);
const ThirdPartyIntegrationAlertBox = withAlertBox(
  ThirdPartyIntegrationWithRouter
);
const ThirdPartyIntegrationLoader = withLoader(ThirdPartyIntegrationAlertBox);
const ThirdPartyIntegrationToast = withToast(ThirdPartyIntegrationLoader);
const ThirdPartyIntegrationWithDialog = withDialog(ThirdPartyIntegrationToast);
const ThirdPartyIntegrationWithPermissions = withPermissions(
  ThirdPartyIntegrationWithDialog
);

export default withStyles((theme) =>
  createStyles({
    center: {
      textAlign: "center",
    },
    packageCard: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      padding: "1.5rem 3rem",
      marginTop: "1rem",
      position: "relative",
    },
    icon: {
      position: "absolute",
      top: "8px",
      right: "8px",
    },
    marginTop: {
      marginTop: "3rem",
    },
    gridSpacing: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 0),
      },
    },
    whiteCard: {
      padding: theme.spacing(4),
      borderRadius: theme.spacing(2),
      backgroundColor: "#ffffff",
      boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1.5),
      },
    },
    heading: {
      fontWeight: 600,
      fontSize: "1.3rem",
      color: theme.palette.secondary.main,
    },
    pageHeading: {
      fontSize: "1.5rem",
      fontFamily: "DM Sans",
    },
    crossButton: {
      cursor: "pointer",
    },
    integrationNames: {
      fontSize: "1.2rem",
      color: theme.palette.secondary.main,
      margin: "1rem 0",
      textAlign: "center",
    },
    integrationDescription: {
      fontSize: "1.2rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      marginBottom: "1rem",
      textAlign: "center",
    },
    connectDisconnectButton: {
      marginTop: "auto",
      width: "75%",
    },
    createAccountText: {
      fontSize: "1rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      marginTop: "1rem",
      textAlign: "center",
      "& > a": {
        color: theme.palette.secondary.main,
      },
    },
    driveImg: {
      width: "110px",
    },
  })
)(ThirdPartyIntegrationWithPermissions);
