//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import { RouterProps } from "react-router";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: string;
  activeTab: string;
  defaultPackages: any;
  customPackages: any;
  defaultFirmFinancialsData: any;
  packageFirmPrices: {};
  defaultStrategicRates : {
    strategic_rate : string;
    contract_period : string;
  };
  disableStrategicRateField : boolean;
  disableContractPeriodField : boolean;
  strategicRateError : boolean;
  contractPeriodError : boolean;
  errorMsg : string;
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: string;
  password: string;
  unique_auth_id?: string;
};
export default class FirmPricingController extends BlockComponent<Props, S, SS> {
  getDefaultPackagesApiCallId: string = "";
  getCustomPackageAPiCallId: string = "";
  getDefaultFirmFinancialsByAccountAPiCallId: string = "";
  deleteCustomPackageAPiCallId: string = "";
  revertPackagePriceApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      activeTab: "Default",
      defaultPackages: [],
      customPackages: [],
      defaultFirmFinancialsData: {},
      packageFirmPrices: {},
      defaultStrategicRates : {
        strategic_rate : "175",
        contract_period : "12",
      },
      disableStrategicRateField : true,
      disableContractPeriodField : true,
      strategicRateError : false,
      contractRateError : false,
      errorMsg : "This Field is required",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleDefaultPackageResponse = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      this.setState({
        defaultPackages: responseJson?.data,
      });
      this.getCustomPackage();
    } else {
      this.handleError(responseJson);
    }
  };

  handleCustomPackageResponse = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.setState({
        customPackages: responseJson?.data,
      });
    } else {
      this.handleError(responseJson);
    }
  };

  setFirmPricePackages = (responseJson) => {
    let packageFirmPrices = {};
    responseJson?.data.attributes.default_firm_finance.data.attributes.pricing_value.data.forEach((item) => {
      if (item?.attributes?.package_name === "Accounting Success") {
        packageFirmPrices["Accounting Success Package"] = item.attributes.strategic_price;
      } else if (item?.attributes?.package_name === "Payroll Success") {
        packageFirmPrices["Payroll Success Package"] = item.attributes.strategic_price;
      } else if (item?.attributes?.package_name === "Reporting-Only Package" && item?.attributes?.package_type === "Individual") {
        packageFirmPrices["Reporting-Only Package (Individual)"] = item.attributes.strategic_price;
      } else if (item?.attributes?.package_name === "IRS Rapid Relief") {
        packageFirmPrices["IRS Rapid Relief Package"] = item.attributes.strategic_price;
      } else if (item?.attributes?.package_name === "Reporting-Only Package" && item?.attributes?.package_type === "business") {
        packageFirmPrices["Reporting-Only Package (Business)"] = item.attributes.strategic_price;
      } else {
        packageFirmPrices[item?.attributes?.package_name] = item.attributes.strategic_price;
      }
    });
    this.setState({
      defaultFirmFinancialsData: responseJson?.data?.attributes?.default_firm_finance?.data,
      packageFirmPrices: packageFirmPrices,
    });
  };

  handleGetDefaultFirmFinancialsByAccountResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes?.default_firm_finance?.data?.id) {
      if (responseJson?.data?.attributes?.default_firm_finance?.data?.attributes?.firm_labor_expenses?.data.length > 0) {
        this.setFirmPricePackages(responseJson);
      } else {
        this.handleError(responseJson);
      }
    }
  };

  handleDeleteCustomPackageResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message[0] === "Custom Package deleted successfully") {
      this.getCustomPackage();
      this.props.showToast({
        type: "success",
        message: "Custom Package deleted successfully",
      });
    } else {
      this.handleError(responseJson);
    }
  };

  handleRevertPackagePriceResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message[0] === "Custom Package deleted successfully") {
      this.getCustomPackage();
      this.props.showToast({
        type: "success",
        message: "Prices reverted back to system default successfully",
      });
      this.getDefaultPackages();
    } else {
      this.handleError(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (this.getDefaultPackagesApiCallId === apiRequestCallId) {
        this.handleDefaultPackageResponse(responseJson);
      } else if (this.getCustomPackageAPiCallId === apiRequestCallId) {
        this.handleCustomPackageResponse(responseJson);
      } else if (apiRequestCallId === this.getDefaultFirmFinancialsByAccountAPiCallId) {
        this.handleGetDefaultFirmFinancialsByAccountResponse(responseJson);
      } else if (apiRequestCallId === this.deleteCustomPackageAPiCallId) {
        this.handleDeleteCustomPackageResponse(responseJson);
      } else if (apiRequestCallId === this.revertPackagePriceApiCallId) {
        this.handleRevertPackagePriceResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      const msg = await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        this.props.history.push("/");
      }
    }
  }

  getDefaultPackages = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDefaultPackagesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_contentmanagement/package_managements?data=package_checklists");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomPackage = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getCustomPackageAPiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_packages/custom_package_creation`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDefaultFirmFinancialsByAccount = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDefaultFirmFinancialsByAccountAPiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/${this.state.accountId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteCustomPackage = (packageId) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCustomPackageAPiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_packages/custom_package_creation/${packageId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  revertPackagePrice = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.revertPackagePriceApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_custom_packages/delete_custom_packages`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDefaultStrategicRateChange = (event) => {
    if(event.target.value === "") {
      this.setState({
        strategicRateError : true,
      })
    }
    else {
      this.setState({
        strategicRateError : false,
      })
    }
    this.setState({
      defaultStrategicRates : {
        ...this.state.defaultStrategicRates,
        strategic_rate : event.target.value,
      }
    })
  }


  handleDefaultContractPeriodChange = (event) => {
    if(event.target.value === "") {
      this.setState({
        contractPeriodError : true,
      })
    }
    else {
      this.setState({
        contractPeriodError : false
      })
    }
    this.setState({
      defaultStrategicRates : {
        ...this.state.defaultStrategicRates,
        contract_period : event.target.value,
      }
    })
  }

handleDisableStrategicRateField = () => {
  this.setState({
    disableStrategicRateField : false,
  })
}

handleDisableContractPeriodField = () => {
  this.setState({
    disableContractPeriodField: false,
  })
}

handleStrategicRateKeydown = (event) => {
  if(event.key === "Enter") {
    this.setState({
      disableStrategicRateField : true,
    })
  }
}

handleContractPeriodKeydown = (event) => {
  if(event.key === "Enter") {
    this.setState({
      disableContractPeriodField : true,
    })
  }
}

}
