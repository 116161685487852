//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { dialogStyle } from "./DeleteAdditionalDialog.web";

export class ChangeClientTypeConfirmDialog extends React.Component {
  

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ width: "30vw" }} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            color="textPrimary"
            className={classes.dialogHeading}
          >
            Are you sure you want to convert this client to a different client type?
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Grid spacing={1} container justifyContent="center">
          <Grid xs={6} item>
          <Button
            variant="contained"
            color="default"
            fullWidth
            id='noButton'
            className={classes.uploadNowButton}
            onClick={this.closeDialog.bind(this,null)}
          >
             No
          </Button>
          </Grid>
          <Grid xs={6} item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            id='yesButton'
            className={classes.successButton}
            onClick={this.closeDialog.bind(this,'Yes')}
          >
             Yes
          </Button>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ChangeClientTypeConfirmDialogWithRouter = withRouter(ChangeClientTypeConfirmDialog);
const ChangeClientTypeConfirmDialogWithToast = withToast(
  ChangeClientTypeConfirmDialogWithRouter
);
const ChangeClientTypeConfirmDialogWithLoader = withLoader(
  ChangeClientTypeConfirmDialogWithToast
);
const ChangeClientTypeConfirmDialogWithAlertBox = withAlertBox(
  ChangeClientTypeConfirmDialogWithLoader
);
const ChangeClientTypeConfirmDialogWithDialogBox = withDialogBox(
  ChangeClientTypeConfirmDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    ...dialogStyle()
  })
)(ChangeClientTypeConfirmDialogWithDialogBox);
