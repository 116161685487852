//@ts-nocheck
import React from "react";
import ForgotPasswordOTPController, { Props } from "./ForgotPasswordOTPController.web";
import { withStyles, Grid, Typography, createStyles, Button, Box } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import OtpInput from "react-otp-input";
import { Logo } from "./CodeVerified.web";
import { authFlowStyles } from "./Login.web";
import TernaryCheck from "../TernaryCheck.web";
import { Redirect } from "react-router-dom";

const Schema = Yup.object().shape({
  otp: Yup.number(),
});

export const ForgotPasswordContainer = (props) => {
  const { classes, children, title } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: "100vh",
      }}
    >
      <Grid item md={3} xs={12} className={classes.loginBox}>
        <Box py={3}>
          <Grid container justifyContent="center" spacing={2}>
            <Logo />
            <Grid item xs={12}>
              <Typography variant="h1" align="center" className={classes.welcomeBack}>
                {title || "Forgot Password?"}
              </Typography>
            </Grid>
            {children}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export class ForgotPasswordOTP extends ForgotPasswordOTPController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  handleOTPChange = (setFieldValue, otp) => setFieldValue("otp", otp);

  onSubmit = (values) => {
    this.verifyOtp(values);
  };

  render() {
    const { classes } = this.props;
    return (
      <TernaryCheck
        condition={this.state.token}
        trueComponent={<Redirect to="/home" />}
        falseComponent={
          <Formik
            initialValues={{
              otp: this.props.history.location?.state?.values?.otp || "",
            }}
            validationSchema={Schema}
            onSubmit={this.onSubmit.bind(this)}
          >
            {(formikProps) => {
              const { values, setFieldValue, isValid, dirty } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <ForgotPasswordContainer {...formikProps} classes={classes}>
                    <Grid item xs={10}>
                      <OtpInput
                        value={values.otp}
                        onChange={this.handleOTPChange.bind(this, setFieldValue)}
                        numInputs={4}
                        placeholder="----"
                        containerStyle={classes.otpContainerStyle}
                        inputStyle={classes.otpInputStyle}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Button color="primary" type="submit" variant="contained" fullWidth disabled={!(isValid && dirty) || values.otp.length < 4}>
                        Verify Code
                      </Button>
                    </Grid>
                  </ForgotPasswordContainer>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

const ForgotPasswordOTPWithRouter = withRouter(ForgotPasswordOTP);
const ForgotPasswordOTPWithToast = withToast(ForgotPasswordOTPWithRouter);
const ForgotPasswordOTPWithLoader = withLoader(ForgotPasswordOTPWithToast);
const ForgotPasswordOTPWithAlertBox = withAlertBox(ForgotPasswordOTPWithLoader);
const ForgotPasswordOTPWithDialogBox = withDialog(ForgotPasswordOTPWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
  })
)(ForgotPasswordOTPWithDialogBox);
