import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & { 
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  token : any
  isValidSession : boolean,
  usersList: [],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RolePermissionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUsersApiCallId : string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: '',
      isValidSession : false,
      usersList: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(this.getUsersApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
               usersList : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Session TimeOut,Please Login Again.'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountId")  
        this.props.history.push("/")
      }
    }
  }

  formatPlan = (plan: string) => {
    if(plan !== null && plan !== undefined) {
        const splitPlan = plan.split(/(?=[A-Z])/);
        const formattedPlan = `${splitPlan[0]} ${splitPlan[1]} (${splitPlan[2]})`;
        return formattedPlan;
    }
  };
  

  getUsers = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token : this.state.token,
      skipSubscriptionCheck: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUsersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.usersEndpoint}?user_type=Firm Owner User`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }  
}
