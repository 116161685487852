//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");
import { themeProps } from "../../../../web/src/theme2";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  paymentaccesstoken: null;
  accountId: null;
  clientId: null;
  cpaDetail: null;
  clientDetail: null;
  isEnable: boolean;
  surveyLogId: number | null;
  type: any;
  IseCheckPaymentAvailable: boolean;
  theme: any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SurveylandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCPADetialbyAccessTokenForCFSCallId: string;
  updateSurveyLogCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      paymentaccesstoken: null,
      accountId: null,
      clientId: null,
      cpaDetail: null,
      clientDetail: null,
      isEnable: false,
      surveyLogId: null,
      type: undefined,
      isEcheckPaymentAvailable: false,
      theme: themeProps,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  setDocumentTitleAndIcon = (theme={}) => {
    if (theme.logo) {
      if (theme.firm_name) {
        document.title = theme.firm_name;
      }
      const canvasCFS = document.createElement("canvas");
      canvasCFS.width = 160;
      canvasCFS.height = 160;
      const ctx = canvasCFS.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = theme.logo;
      img.onload = this.onImageLoadForCFS.bind(this, ctx, img, canvasCFS);
    }
  };
  onImageLoadForCFS = function(ctx, img, canvas) {
    ctx.drawImage(img, 0, 0);
    let linkCFS = document.querySelector("link[rel~='icon']");
    if (!linkCFS) {
      linkCFS = document.createElement("link");
      linkCFS.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(linkCFS);
    }
    linkCFS.href = canvas.toDataURL("image/x-icon");
  };
  handleCPADetialbyAccessTokenResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      this.setState(
        {
          cpaDetail: responseJson.data.attributes,
          cpaSurveySetting:
            responseJson.data.attributes?.custom_survey_setting?.data
              ?.attributes,
        },
        () => {
          this.setTheme(responseJson.data.attributes?.theme);
        }
      );
    } else {
      this.handleApiError(responseJson);
    }
  };
  setTheme = (theme1={}) => {
    localStorage.setItem(
      "themePrimaryColor",
      theme1.primary_color || themeProps.palette.primary.main
    );
    localStorage.setItem(
      "themeSecondaryColor",
      theme1.secondary_color || themeProps.palette.secondary.main
    );
    localStorage.setItem(
      "primaryColor",
      theme1.primary_color || themeProps.palette.primary.main
    );
    localStorage.setItem(
      "secondaryColor",
      theme1.secondary_color || themeProps.palette.secondary.main
    );

    const theme = {
      ...themeProps,
      palette: {
        ...themeProps.palette,
        primary: {
          main: theme1.primary_color || themeProps.palette.primary.main,
        },
        secondary: {
          main: theme1.secondary_color || themeProps.palette.secondary.main,
        },
      },
    };
    this.setState({
      theme: theme,
    },()=>{
      console.log("theme1",theme1)
      this.setDocumentTitleAndIcon(theme1);
    });
  };
  handleUpdateSurveyLogSuccess = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      StorageProvider.set("surveyLogId", responseJson?.data?.id);
      this.setState({
        surveyLogId: responseJson?.data?.id,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };
  handleApiError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: "Survey link is not valid",
      });
      this.setState({
        isEnable: true,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCPADetialbyAccessTokenForCFSCallId) {
        this.handleCPADetialbyAccessTokenResponse(responseJson);
      } else if (this.updateSurveyLogCallId === apiRequestCallId) {
        this.handleUpdateSurveyLogSuccess(responseJson);
      } else if (apiRequestCallId == this.isEcheckPaymentAvailableCallId) {
        this.isEcheckPaymentAvailableResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
  }
  isEcheckPaymentAvailableResponse = (responseJson) => {
    if (
      responseJson.status &&
      responseJson.message == "CPA accept payment using e-Check"
    ) {
      this.setState({
        IseCheckPaymentAvailable: true,
      });
    }
  };
  getCPADetialbyAccessToken = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const data = {
      data: {
        attributes: {
          access_token: "",
          account_id: this.state.accountId,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/access_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getCPADetialbyAccessTokenForCFSCallId = requestMessage.messageId;

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateSurveyLog = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const data = {
      access_token: "",
      account_id: this.state.accountId,
      type: this.state.type,
      state: "pending",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSurveyLogCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `survey_log/${this.state.surveyLogId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  isEcheckPaymentAvailable = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.isEcheckPaymentAvailableCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/payments/accept_e_check?account_id=${this.state.accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
