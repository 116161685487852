// @ts-nocheck
import React from "react";

import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import Fade from "@material-ui/core/Fade";
import { surveyStyles,aboutUsStyle } from "./surveyStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
export const AboutYouSvgIcon = ({ iconColor }) => {
  const defaultColor = "#54a4a6";
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 555 555"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g id="Page-1">
        <g id="CFS:-Step-4">
          <g id="fluent:target-arrow-24-filled">
            <path
              id="Shape"
              d="M548.071,132.446c5.935,-5.943 7.71,-14.875 4.496,-22.635c-3.213,-7.761 -10.782,-12.824 -19.182,-12.831l-76.198,-0l0,-76.198c-0.007,-8.4 -5.07,-15.969 -12.831,-19.182c-7.76,-3.214 -16.692,-1.439 -22.635,4.496l-69.271,69.271c-3.899,3.894 -6.091,9.176 -6.096,14.685l-0,78.581l-54.918,54.918c-16.64,-4.427 -34.393,-0.88 -48.054,9.602c-13.662,10.482 -21.684,26.711 -21.715,43.931c0.009,21.531 12.49,41.108 32.007,50.204c19.516,9.095 42.534,6.063 59.029,-7.777c16.494,-13.84 23.48,-35.981 17.913,-56.78l54.918,-54.918l78.58,-0c5.51,-0.005 10.792,-2.198 14.686,-6.096l69.271,-69.271Zm-270.988,-132.446c32.355,-0.042 64.467,5.595 94.873,16.653l-39.124,39.124c-1.718,1.718 -3.325,3.602 -4.738,5.542c-96.445,-22.778 -196.291,21.226 -244.553,107.778c-48.262,86.552 -33.212,194.622 36.861,264.695c70.073,70.073 178.143,85.123 264.695,36.861c86.553,-48.261 130.556,-148.108 107.779,-244.553c1.939,-1.441 3.796,-3.048 5.541,-4.766l39.069,-39.124c11.067,30.406 16.713,62.517 16.681,94.874c-0,153.033 -124.051,277.083 -277.084,277.083c-153.033,-0 -277.083,-124.05 -277.083,-277.083c0,-153.033 124.05,-277.084 277.083,-277.084Zm0,110.834c14.353,-0 28.263,1.828 41.563,5.264l-0,41.064l-11.472,11.471c-0.46,0.454 -0.913,0.916 -1.357,1.386c-33.286,-8.877 -68.807,-1.792 -96.139,19.175c-27.333,20.968 -43.379,53.441 -43.428,87.89c0.03,43.059 24.998,82.204 64.031,100.388c39.032,18.183 85.064,12.113 118.048,-15.567c32.985,-27.68 46.952,-71.959 35.819,-113.555l1.386,-1.358l11.471,-11.471l41.091,0c17.77,68.822 -10.179,141.329 -69.546,180.417c-59.366,39.088 -137.016,36.11 -193.215,-7.409c-56.198,-43.52 -78.513,-117.954 -55.525,-185.213c22.989,-67.26 86.194,-112.464 157.273,-112.482Z"
              style={{ fill: iconColor || defaultColor, fillRule: "nonzero" }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format.")
    .required("This field is required.")
    .nullable(),
  firstName: Yup.string()
    .required("This field is required.")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .nullable(),
  lastName: Yup.string()
    .required("This field is required.")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .nullable(),
  phoneNumber: Yup.string()
    .matches(
      /^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/,
      "Invalid Phone number"
    )
    .required("This field is required")
    .nullable(),
});
export const AboutUsComponent = (props) => {
  const { classes, gotoBackStep, gotoNextStep, iconColor } = props;
  const getHelpText = (errors, touched, key) => {
    return touched[key] && errors[key] ? errors[key] : "";
  };
  const getError = (errors, touched, key) => {
    return touched[key] && errors[key];
  };
  return (
    <Fade in={true} timeout={3000}>
      <Grid container justifyContent="space-around">
        <Grid xs={11} sm={3} item>
          <Box my={12}>
            <div className={classes.leftSideImageContainer}>
              {/* <img
                  src={Goals}
                  style={{ display: "block", width: "100%", height: "100%" }}
                /> */}
              <AboutYouSvgIcon iconColor={iconColor} />
            </div>
            <Typography
              gutterBottom
              variant="h2"
              className={classes.sidebarHeadingWithoutMargin}
              color="primary"
            >
              About you
            </Typography>
            <Typography className={classes.sidebarDescription}>
              Entering your details allow us to work our magic and put together
              the recommended features for your perfact engagement.
            </Typography>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid xs={11} sm={8} item>
          <Formik
            initialValues={{
              firstName: props.firstName,
              lastName: props.lastName,
              email: props.email,
              phoneNumber: props.phoneNumber,
            }}
            validationSchema={Schema}
            enableReinitialize
            validateOnMount={true}
            onSubmit={(values) => {
              gotoNextStep();
            }}
          >
            {(formikProps) => {
              const {
                values,
                handleBlur,
                handleChange,
                errors,
                touched,
                isValid,
              } = formikProps;
              const handleInputChange = (event) => {
                props.setValueByDynamicName(
                  event.target.name,
                  event.target.value
                );
                handleChange(event);
              };
              return (
                <Form autoComplete="off" noValidate style={{ margin: 0 }}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={7}>
                      <Typography className={classes.pageHeading} gutterBottom>
                        What is the best way to reach you?
                      </Typography>
                      <Typography className={classes.pageSubHeading}>
                        Entering your details allows us to work our magic and
                        put together the recommended features for your perfect
                        engagement.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Grid container spacing={1} justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.textFieldBottomMargin}
                        >
                          <TextField
                            color="primary"
                            variant="outlined"
                            placeholder="First Name"
                            fullWidth
                            value={values.firstName}
                            onBlur={handleBlur}
                            helperText={getHelpText(
                              errors,
                              touched,
                              "firstName"
                            )}
                            error={getError(errors, touched, "firstName")}
                            onChange={handleInputChange}
                            id="firstName"
                            name="firstName"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.textFieldBottomMargin}
                        >
                          <TextField
                            variant="outlined"
                            color="primary"
                            placeholder="Last Name"
                            value={values.lastName}
                            onBlur={handleBlur}
                            helperText={getHelpText(
                              errors,
                              touched,
                              "lastName"
                            )}
                            error={getError(errors, touched, "lastName")}
                            onChange={handleInputChange}
                            id="lastName"
                            fullWidth
                            name="lastName"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={classes.textFieldBottomMargin}
                        >
                          <TextField
                            disabled={props.isAboutusEnable}
                            variant="outlined"
                            color="primary"
                            type="email"
                            value={values.email}
                            onBlur={handleBlur}
                            helperText={getHelpText(errors, touched, "email")}
                            error={getError(errors, touched, "email")}
                            onChange={handleInputChange}
                            id="email"
                            placeholder="Enter Email"
                            fullWidth
                            name="email"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={classes.textFieldBottomMargin}
                        >
                          <TextField
                            variant="outlined"
                            color="primary"
                            placeholder="Enter Phone Number"
                            fullWidth
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            helperText={getHelpText(
                              errors,
                              touched,
                              "phoneNumber"
                            )}
                            error={getError(errors, touched, "phoneNumber")}
                            onChange={handleInputChange}
                            id="phoneNumber"
                            name="phoneNumber"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      sm={7}
                      className={classes.buttonContainer}
                    >
                      <Grid container justifyContent="flex-end">
                        <Button
                          color="default"
                          style={{ marginRight: "16px" }}
                          startIcon={<ArrowBackOutlinedIcon />}
                          variant="text"
                          className={classes.backButton}
                          id='backButton'
                          onClick={gotoBackStep}
                        >
                          Back
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          endIcon={<ArrowForwardOutlinedIcon />}
                          disabled={!isValid}
                          variant="text"
                          id='nextButton'
                          className={[
                            classes.nextButton,
                            "statucNextButton",
                          ].join(" ")}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Fade>
  );
};
export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    ...aboutUsStyle(theme)
  })
)(AboutUsComponent);
