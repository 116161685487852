//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  survey_id: null | number;
  cfsinitialValues: any;
  expandedEmailTemplate: any;
  emailTemplates: any;
  emailTemplateId: any;
  emailTemplateTitles:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientConversionSurveySettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateCFSSettingApiCallId: string = "";
  getEmailTemplateCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      survey_id: null,
      cfsinitialValues: {
        booking_calendar: false,
        survey_link: "",
        meeting_link: "",
        self_enrollment: false,
        business_bundle: false,
        new_business_incubator: false,
        reporting_only_business: false,
        reporting_only_individual: false,
        irs_rapid: false,
        personal_bundle: false,
        payroll_success: false,
        accounting_success: false,
        business_tax_and_advisory: false,
        expandedEmailTemplate: null,
      },
      emailTemplates: {  },
      emailTemplateId: null,
      emailTemplateTitles:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleUpdateCFSSetting = (responseJson) => {
    this.props.hideLoader();
    if (responseJson.data) {
      this.setState(
        {
          survey_id: responseJson?.data?.id,
        },
        () => {
          this.props.showToast({
            type: "success",
            message: "Client survey setting updated successfully",
          });
        }
      );
    } else if (
      responseJson?.message &&
      responseJson?.message === "AccessDenied for current user"
    ) {
      this.props.showToast({
        type: "error",
        message: responseJson?.message,
      });
    } else {
      this.handleAPiError(responseJson);
    }
  };

  handleSendSurvetResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.message) {
      this.props.showToast({
        type: "success",
        message: "Survey sent successfully",
      });
    } else {
      this.handleSendSurveyApiErrorResponse(responseJson);
    }
  };

  handleSendSurveyApiErrorResponse = (responseJson) => {
    const errors = responseJson?.errors;
    if (errors?.checklists?.length > 0) {
      this.props.showToast({
        type: "error",
        message: `please assign checklist for ${errors?.checklists[0]}`,
      });
      this.props.history.push("/home/settings/manageDeliveryChecklist");
    } else if (errors?.documents?.length > 0) {
      this.props.showToast({
        type: "error",
        message: `please assign documents for ${errors?.documents[0]}`,
      });
      this.props.history.push("/home/settings/documentTemplates");
      return false;
    } else {
      this.handleAPiError(responseJson);
    }
  };

  handleGetCFSSettingResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data && responseJson?.data?.id) {
      this.setState(
        {
          survey_id: responseJson?.data?.id,
        },
        () => {
          this.initializeSurveySetting(responseJson?.data?.attributes);
        }
      );
    } else {
      this.handleAPiError(responseJson);
    }
  };

  handleAPiError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.updateCFSSettingApiCallId) {
        this.handleUpdateCFSSetting(responseJson);
      } else if (this.sendCFSSurveyCallId === apiRequestCallId) {
        this.handleSendSurvetResponse(responseJson);
      } else if (this.getCFSSettingCallId === apiRequestCallId) {
        this.handleGetCFSSettingResponse(responseJson);
      } else if (apiRequestCallId == this.getEmailTemplateCallId) {
        this.handleGetEmailTemplateResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (
        msg === "Session TimeOut,Please Login Again." ||
        msg === "User Account Not Found"
      ) {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }
  handleGetEmailTemplateResponse = (responseJSON) => {
    if (responseJSON?.data?.length > 0) {
      const templates = { ...responseJSON.data[0].attributes };
      delete templates.id;
      this.setState({
        emailTemplates: {...templates,"template_five":"aa"},
        emailTemplateTitles: {...templates,"template_five":"aa"},
        emailTemplateId: responseJSON?.id,
      });
    } else {
      this.handleAPiError(responseJSON);
    }
  };

  

  initializeSurveySetting = (setting) => {
    let survey_link =
      this.state.cfsinitialValues.survey_link ||
      window.location.origin +
        `/surveyLandingPage?account_id=${this.state.accountId}&type=generalCFS`;
    if (setting.cpa_charge_access_token) {
      const paymentAccessToken = setting.cpa_charge_access_token;
      survey_link += `&access_token=${paymentAccessToken}`;
    }
    const business_bumps = setting?.business_bumps.map((pkg) => {
      return {
        ...pkg,
        name: Object.keys(pkg).filter((key) => {
          return typeof pkg[key] === "boolean";
        })[0],
      };
    });
    const individual_bumps = setting.individual_bumps.map((pkg) => {
      return {
        ...pkg,
        name: Object.keys(pkg).filter((key) => {
          return typeof pkg[key] === "boolean";
        })[0],
      };
    });

    

    const initialValues = {
      booking_calendar: setting.booking_calendar || false,
      survey_link: survey_link,
      meeting_link: setting.meeting_link || "",
      self_enrollment: setting.self_enrollment || false,
      business_bundle: setting.business_bundle || false,
      new_business_incubator: setting.new_business_incubator || false,
      reporting_only_business: setting.reporting_only_business || false,
      reporting_only_individual: setting.reporting_only_individual || false,
      irs_rapid: setting.irs_rapid || false,
      personal_bundle: setting.personal_bundle || false,
      payroll_success: setting.payroll_success || false,
      accounting_success: setting.accounting_success || false,
      business_tax_and_advisory: setting.business_tax_and_advisory || false,
      
    };
    this.setState({
      cfsinitialValues: initialValues,
      meeting_link: setting.meeting_link,
    });
  };

  updateCFSSetting = (values: any) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCFSSettingApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        attributes: {
          booking_calendar: values.booking_calendar,
          survey_link: values.survey_link,
          meeting_link: values.meeting_link || "",
          self_enrollment: values.self_enrollment,
          business_bundle: values.business_bundle,
          new_business_incubator: values.new_business_incubator,
          reporting_only_business: values.reporting_only_business,
          reporting_only_individual: values.reporting_only_individual,
          irs_rapid: values.irs_rapid,
          personal_bundle: values.personal_bundle,
          payroll_success: values.payroll_success,
          accounting_success: values.accounting_success,
          business_tax_and_advisory: values.business_tax_and_advisory,
          
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.survey_id
        ? `bx_block_survey/survey_setting/${this.state.survey_id}`
        : "bx_block_survey/survey_setting"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.survey_id ? "PUT" : "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCFSSetting = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCFSSettingCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/survey_setting`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendCFSSurvey = (email) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    const survey = {
      email: email,
      survey_link: window.location.origin + "/surveyLandingPage",
      access_token:
        JSON.parse(localStorage.getItem("paymentAccessToken")) || undefined,
      account_id: this.state.accountId,
      type: null,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendCFSSurveyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/send_survey`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(survey)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getEmailTemplate = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmailTemplateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `survey_setting/insert_template_data`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
