//@ts-nocheck
import React from "react";
import ProfilePhaseTwoController, { Props } from "./ProfileP2Controller.web";
import { Typography, Button, Grid, withStyles, createStyles, MenuItem } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import InputField from "../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import withPermissions from "../../../components/src/withPermissions.web";
import { nameMask, spaceMask, mobileNumberMask } from "./masks";
import UploadBoxPhaseTwoWeb from "./UploadBoxPhaseTwo.web";
import CloseIcon from "@material-ui/icons/Close";

const validationSchemaOwner = Yup.object().shape({
  first_name: Yup.string().matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field"),
  last_name: Yup.string().matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field"),
  primary_email: Yup.string().email("Email must be in valid format!"),
  secondary_email: Yup.string().when("primary_email", (primary_email) => {
    if (primary_email) {
      return Yup.string()
        .email("Email must be in valid format!")
        .notOneOf([Yup.ref("primary_email"), null], "Secondary email can't be same as primary email.");
    }
  }),
  primary_phone: Yup.string().matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone number"),
  secondary_phone: Yup.string().when("primary_phone", (primary_phone) => {
    if (primary_phone) {
      return Yup.string()
        .notOneOf([Yup.ref("primary_phone"), null], "Secondary phone can't be same as primary phone.")
        .matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone number");
    }
  }),
  job_title: Yup.string(),
});

const validationSchemaUser = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .required("This field is required."),
  last_name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this field")
    .required("This field is required."),
  primary_email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  phone_number: Yup.string()
    .matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone number")
    .required("This field is required."),
});
export class ProfilePhaseTwo extends ProfilePhaseTwoController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const employee_id = await StorageProvider.get("employee_id");

    this.setState(
      {
        token: token,
        accountId: accountId,
        employee_id: employee_id,
      },
      () => {
        if (employee_id) {
          this.getSingleUser(employee_id);
        } else {
          this.getProfile();
        }
      }
    );
  }

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };

  removeLogo = () => {
    this.setState({
      profile: {
        ...this.state.profile,
        profile_image_url: null,
      },
      employeeProfile: {
        ...this.state.employeeProfile,
        employee_profile_image_url: null,
      },
      profileImageAsFile: null,
    });
  };

  handleLogoImage = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      profile: {
        ...this.state.profile,
        profile_image_url: filePaths[0],
      },
      employeeProfile: {
        ...this.state.employeeProfile,
        employee_profile_image_url: filePaths[0],
      },
    });
  };

  showCorrectImage = (values) => {
    const { classes } = this.props;
    if (values?.profile_image_url?.base64 || values?.employee_profile_image_url?.base64) {
      return (
        <div style={{ position: "relative", width: "25%" }}>
          <CloseIcon onClick={this.removeLogo} className={classes.crossOverImage} />
          <img
            src={values?.profile_image_url?.base64 || values?.employee_profile_image_url?.base64}
            style={{
              width: "100%",
              borderRadius: "6px",
            }}
          />
        </div>
      );
    } else if (values?.profile_image_url || values?.employee_profile_image_url) {
      return (
        <div style={{ position: "relative", width: "25%" }}>
          <CloseIcon onClick={this.removeLogo} className={classes.crossOverImage} />
          <img
            src={values.profile_image_url || values.employee_profile_image_url}
            style={{
              width: "100%",
              borderRadius: "6px",
            }}
          />
        </div>
      );
    } else {
      return (
        <UploadBoxPhaseTwoWeb
          multiple={false}
          acceptedFormat={"image/*"}
          onTouched={() => {}}
          onSelectFile={this.handleLogoImage}
          showToast={this.props.showToast.bind(this)}
        />
      );
    }
  };

  render() {
    const { classes, permissions } = this.props;

    const jobTitleOptions = [
      "Choose one",
      "Firm Manager",
      "Tax Manager",
      "Accounting Manager",
      "Payroll Manager",
      "Advisory Manager",
      "Advisory Support",
      "Tax Support",
      "Payroll Support",
      "Accounting Support",
      "Client Support",
      "Internal Support",
    ];

    return (
      <Formik
        initialValues={this.state.employee_id ? this.state.employeeProfile : this.state.profile}
        enableReinitialize
        validationSchema={this.state.employee_id ? validationSchemaUser : validationSchemaOwner}
        onSubmit={this.state.employee_id ? this.updateUser : this.updateProfile}
      >
        {(formikProps) => {
          const { values, isValid, handleChange } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container justifyContent="space-between">
                <Grid item md={12}>
                  <Typography className={classes.title}>Profile</Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  className={[classes.whiteCard, classes.gridSpacing]}
                  style={{
                    minHeight: "65vh",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.subTitle}>Basic Info</Typography>
                    </Grid>

                    <Grid item md={2} container xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Profile Picture
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      {this.showCorrectImage(values)}
                    </Grid>
                    <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          First Name
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      <Field
                        component={InputField}
                        name="first_name"
                        value={values.first_name}
                        onChange={(e) => handleChange(nameMask(e))}
                        placeholder="Enter First Name"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Last Name
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      <Field
                        component={InputField}
                        name="last_name"
                        value={values.last_name}
                        onChange={(e) => handleChange(nameMask(e))}
                        placeholder="Enter Last Name"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Primary Email
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      <Field
                        component={InputField}
                        name="primary_email"
                        value={values.primary_email}
                        onChange={(e) => handleChange(spaceMask(e))}
                        disabled
                        placeholder="Enter Email"
                        fullWidth
                      />
                    </Grid>

                    {!this.state.employee_id ? (
                      <>
                        <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                          <Grid item xs={12}>
                            <Typography align="right" className={classes.fieldLabel}>
                              Secondary Email
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                          <Field
                            component={InputField}
                            name="secondary_email"
                            value={values.secondary_email}
                            onChange={(e) => handleChange(spaceMask(e))}
                            placeholder="Enter Email"
                            fullWidth
                          />
                        </Grid>
                      </>
                    ) : null}

                    <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.fieldLabel}>
                          Primary Phone
                        </Typography>
                      </Grid>
                    </Grid>

                    {!this.state.employee_id ? (
                      <>
                        <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                          <Field
                            component={InputField}
                            name="primary_phone"
                            value={values.primary_phone}
                            onChange={(e) => handleChange(mobileNumberMask(e))}
                            placeholder="Enter Phone Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                          <Grid item xs={12}>
                            <Typography align="right" className={classes.fieldLabel}>
                              Secondary Phone
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                          <Field
                            component={InputField}
                            name="secondary_phone"
                            value={values.secondary_phone}
                            onChange={(e) => handleChange(mobileNumberMask(e))}
                            placeholder="Enter Phone Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]}>
                          <Grid item xs={12}>
                            <Typography align="right" className={classes.fieldLabel}>
                              Job Title
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                          <Field component={InputField} name="job_title" fullWidth select value={values.job_title}>
                            {jobTitleOptions.map((opt) => {
                              return (
                                <MenuItem key={opt} value={opt} disabled={opt === "Choose one"}>
                                  {opt}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </Grid>
                      </>
                    ) : (
                      <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                        <Field
                          component={InputField}
                          name="phone_number"
                          value={values.phone_number}
                          onChange={(e) => handleChange(mobileNumberMask(e))}
                          placeholder="Enter Phone Number"
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid item md={2} container alignItems="center" xs={12} className={[classes.gridSpacing]} />

                    <Grid item md={9} xs={12} className={[classes.gridSpacing]}>
                      <Button
                        id="profilephase2-save"
                        type="submit"
                        fullWidth
                        disabled={!isValid || !permissions.includes("Modify my account-Account Creation")}
                        color="primary"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ProfilePhaseTwoWithRouter = withRouter(ProfilePhaseTwo);
const ProfilePhaseTwoAlertBox = withAlertBox(ProfilePhaseTwoWithRouter);
const ProfilePhaseTwoLoader = withLoader(ProfilePhaseTwoAlertBox);
const ProfilePhaseTwoToast = withToast(ProfilePhaseTwoLoader);
const ProfilePhaseTwoWithDialog = withDialog(ProfilePhaseTwoToast);
const ProfilePhaseTwoWithConfirmBox = withConfirmBox(ProfilePhaseTwoWithDialog);
const ProfilePhaseTwoWithPermissions = withPermissions(ProfilePhaseTwoWithConfirmBox);

export default withStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3),
      maxHeight: "calc(100vh - 60px)",
      overflowY: "auto",
    },
    gridSpacing: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 0),
      },
    },
    whiteCard: {
      padding: "2rem",
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1.5),
      },
    },
    title: {
      fontFamily: "DM Sans",
      fontSize: "1.75rem",
      color: "#54A4A6",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      marginBottom: "1rem",
    },
    subTitle: {
      fontFamily: "Nunito Sans",
      fontSize: "1.2rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: theme.palette.text.blue2,
    },
    fieldLabel: {
      fontFamily: "Nunito Sans",
      fontSize: "1.1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontWeight: 600,
      color: theme.palette.text.blue2,
      display: "block",
      paddingRight: theme.spacing(3),
      textAlign: "right",
    },
    footerMargin: {
      marginTop: theme.spacing(7),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
  })
)(ProfilePhaseTwoWithPermissions);
