//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  ThemeProvider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialogBox from "../../../components/src/withDialog.web";
import PhaseTwoField from "../../../components/src/PhaseTwoField.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { themeProps } from "../../../web/src/theme2";
import { createTheme } from "@material-ui/core/styles";
const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format.")
    .required("This field is required."),
});

export class SummaryEmailDialog extends React.Component {
  state = {
    email: "",
    theme: themeProps,
  }
  componentDidMount() {
    this.setState(
      {
        theme: {},
      },
      () => {
        this.setTheme();
      }
    );
  }
  setTheme = () => {
    this.setState({
      theme: {
        ...themeProps,
        palette: {
          ...themeProps?.palette,
          primary: {
            main: localStorage.getItem("themePrimaryColor") || "#54A4A6",
          },
          secondary: {
            main: "#444040",
          },
        },
        overrides: {
          ...themeProps.overrides,
          MuiButton: {
            textPrimary: {
              ...themeProps.overrides.MuiButton.textPrimary,
              color: localStorage.getItem("themePrimaryColor") || "#54A4A6",
            },
          },
          MuiOutlinedInput: {
            root: {
              ...themeProps.overrides.MuiOutlinedInput.root,
              "&.Mui-focused": {
                border:
                  `2px solid ${localStorage.getItem("themePrimaryColor") || "#54A4A6"}`
                  ,
                backgroundColor: "#fff",
              },
            },
          },
        },
      },
    });
  };

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={createTheme(this.state.theme)}>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Schema}
          validateOnMount={true}
          onSubmit={async (values) => {
            let base64;
            if ("sendsurvey" !== this.props?.dataToPass?.values) {
              const data = await this.props?.dataToPass?.values?.toPdf(true);
              base64 = await this.toBase64(data);
            }

            this.closeDialog({
              email: values.email,
              base64: base64,
            });
          }}
        >
          {(formikProps) => {
            const { values, handleChange,errors,isValid,touched } = formikProps;
            return (
              <Form autoComplete="off" noValidate style={{ margin: 0 }}>
                <Grid
                  container
                  style={{ width: "30vw" }}
                  justifyContent="center"
                >
                  <Grid item xs={12} container justifyContent="flex-end">
                    <CloseIcon
                      onClick={this.closeDialog.bind(this, "")}
                      className={classes.closeIcon}
                    />
                  </Grid>
                  {this.props?.dataToPass.type !== "emailsent" && (
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        color="primary"
                        className={classes.dialogHeading}
                      >
                        Send to This Email
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={9}>
                    {this.props?.dataToPass.type === "emailsent" ? (
                      <div>
                        <Typography
                          color="textSecondary"
                          className={classes.dialogsubHeading}
                        >
                          {" "}
                          Email Sent
                        </Typography>
                      </div>
                    ) : (
                      <PhaseTwoField
                        placeholder="Enter Email"
                        type="email"
                        name="email"
                        color="primary"
                        value={values.email}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        error={errors.email && touched.email }
                        helperText={
                          errors.email && touched.email ? errors.email : ""
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    {this.props?.dataToPass.type === "emailsent" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.uploadNowButton}
                        onClick={() => this.closeDialog({})}
                      >
                        Back To Home
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid}
                        className={classes.uploadNowButton}
                      >
                        Send
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
    );
  }
}

const SummaryEmailDialogWithRouter = withRouter(SummaryEmailDialog);
const SummaryEmailDialogWithToast = withToast(SummaryEmailDialogWithRouter);
const SummaryEmailDialogWithLoader = withLoader(SummaryEmailDialogWithToast);
const SummaryEmailDialogWithAlertBox = withAlertBox(
  SummaryEmailDialogWithLoader
);
const SummaryEmailDialogWithDialogBox = withDialogBox(
  SummaryEmailDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    dialogsubHeading: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    uploadNowButton: {
      margin: "1.5rem 0px",
      width: "40%",
    },
    closeIcon: {
      cursor: "pointer",
      fontSize: "1.5rem",
      margin : '1rem'
    },
  })
)(SummaryEmailDialogWithDialogBox);
