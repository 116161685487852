// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';

import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';

import { AlertDialogProvider } from '../../components/src/AlertBoxContext';
import { ToastProvider } from '../../components/src/ToastContext';
import { DialogProvider } from '../../components/src/DialogContext';
import { PermissionsProvider } from '../../components/src/PermissionsContext';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  '@global': {
    html: {
      fontSize: 10,
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 10
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 11.5
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16
      }
    }
  }
});

const getUserConfirmation = (message, callback) => {
  // this is the default behavior
  const allowTransition = window.confirm(message);
  callback(allowTransition);
};

const App1 = withStyles(styles)(App);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router getUserConfirmation={getUserConfirmation}>
      <>
        <CssBaseline />
        <PermissionsProvider>
          <AlertDialogProvider>
            <DialogProvider>
              <ToastProvider>
                <App1 />
              </ToastProvider>
            </DialogProvider>
          </AlertDialogProvider>
        </PermissionsProvider>
      </>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();
