//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  isOldPasswordVisible: boolean;
  isNewPasswordVisible: boolean;
  isConfirmNewPasswordVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  changePasswordApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      isOldPasswordVisible: false,
      isNewPasswordVisible: false,
      isConfirmNewPasswordVisible: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.changePasswordApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.message === "Password Updated Successfully") {
          this.props.showToast({
            type: "success",
            message: "Password Changed Successfully",
          });
          await StorageProvider.remove("authToken");
          await StorageProvider.remove("accountId");
          this.props.history.push("/");
        } else {
          this.props.showAlert({
            title: "Error",
            message: responseJson?.message,
          });
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }

  changePassword = (values: any) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCallId = requestMessage.messageId;

    const httpBody = {
      "old_password": values.oldPassword,
      "new_password": values.newPassword
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'accounts/change_password'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
