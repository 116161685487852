//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ThemeProvider,
} from "@material-ui/core";

import withDialogBox, {
  dialogBoxProps,
} from "../../../components/src/withDialog.web";
import { themeProps } from "../../../web/src/theme2";
import { createTheme } from "@material-ui/core/styles";
export class PotentialSavings extends React.Component<null, dialogBoxProps> {
  state = {
    theme : themeProps
  }
  componentDidMount() {
    this.setState({
      theme : {}
    },()=>{
      this.setTheme();
    })
  }
  setTheme = () => {
    this.setState({
      theme: {
        ...themeProps,
        palette: {
          ...themeProps?.palette,
          primary: {
            main: localStorage.getItem('themePrimaryColor') || '#54A4A6',
          },
          secondary: {
            main: "#444040",
          },
        },
        overrides: {
          ...themeProps.overrides,
          MuiButton: {
            textPrimary: {
              ...themeProps.overrides.MuiButton.textPrimary,
              color: localStorage.getItem('themePrimaryColor'),
            },
          },
          MuiOutlinedInput: {
            root: {
              ...themeProps.overrides.MuiOutlinedInput.root,
              "&.Mui-focused": {
                border: `2px solid ${localStorage.getItem('themePrimaryColor')}`,
                backgroundColor: "#fff",
              },
            },
          },
        },
      },
    });
  };
  
  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider
        theme={createTheme(this.state.theme)}
      >
        <Grid container spacing={2} style={{ margin: "1rem 0" }}>
          <Grid xs={12} item style={{ textAlign: "center" }}>
            <Typography color="primary" className={classes.dialogHeading}>
              Potential Savings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.dialogSubHeading}
            >
              Here are some real-world examples of Strategies we can help
              implement through this package and how much tax savings they could
              generate
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              {[
                {
                  name: "Entity Review & Restructure",
                  saving: " ($4k Avg. Savings)",
                },
                {
                  name: "Creative Home Office Deductions",
                  saving: " ($3k Avg. Savings)",
                },
                {
                  name: "Reasonable Comp Optimization",
                  saving: "($6k Avg. Savings)",
                },
                {
                  name: "Travel Costs Structuring",
                  saving: " (Avg. $2.5k Savings)",
                },
                {
                  name: "Retirement Optimization SEP's vs. Solo's",
                  saving: "(Avg. $4k Savings)",
                },
                {
                  name: "Health Care Costs Planning",
                  saving: " (Avg. $3k Savings)",
                },
                {
                  name: " Asset Structuring- Cars, Electronics, etc.",
                  saving: " (Avg. $2k Savings)",
                },
                {
                  name: "Outsourced Expertise vs. Hiring In-House",
                  saving: "  (Avg. $12k Savings)",
                },
              ].map((example) => {
                return (
                  <>
                    <Grid item xs={8}>
                      <div className={classes.savingContainer}>
                        <Typography className={classes.name} color="primary">
                          {example.name}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.savingContainer}>
                        <Typography
                          className={classes.value}
                          color="textPrimary"
                        >
                          {example.saving}
                        </Typography>
                      </div>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              style={{ fontSize: "2.5rem", fontWeight: 600 }}
              color="primary"
              component={"div"}
            >
              <span>Total "Avg." Savings</span>
              &nbsp;
              <span>=</span>
              &nbsp;
              <span>$36500</span>
              &nbsp;
            </Typography>
            <Typography>
              (This isn't a promise but gives you an idea of the value of
              proactive tax planning)
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

const PotentialSavingsWithDialogBox = withDialogBox(PotentialSavings);

export default withStyles((theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    dialogHeading: {
      fontSize: "3rem",
      fontWeight: "bold",
      margin: "1rem 0.5rem",
    },
    uploadNowButton: {
      margin: "1.5rem 0px",
      width: "20%",
    },
    dialogSubHeading: {
      fontSize: "1.5rem",
      fontWeight: "600",
      color: "#6d6d6d",
    },
    savingContainer: {
      backgroundColor: "#F7F7F7",
      borderRadius: "10px",
      padding: "8px",
    },
    name: {
      fontSize: "1.5rem",
      fontWeight: "600",
    },
    value: {
      fontSize: "1.5rem",
      fontWeight: "500",
      color: "#6d6d6d",
    },
  })
)(PotentialSavingsWithDialogBox);
