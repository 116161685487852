// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./paymentUtils.js";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Button,
  Divider,
  FormLabel,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

const formatCurrencyPayment = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  roundingMode: "ceil",
});
export const annual_packages = [
  "New Business Incubator",
  "IRS Rapid Relief Package",
  "Reporting-Only Package (Individual)",
  "Reporting-Only Package (Business)",
];
export const monthly_packages = [
  "Business Bundle",
  "Business Tax & Advisory",
  "Accounting Success Package",
  "Payroll Success Package",
  "Personal Bundle",
];
export const Thankyou = (props) => {
  let price = null;
  let price_ferq = null;
  if (props.selectedPackage) {
    if (annual_packages.indexOf(props.selectedPackage.name) > -1) {
      price = formatCurrencyPayment.format(props.selectedPackage.price);
      price_ferq = "One Time";
    } else if (monthly_packages.indexOf(props.selectedPackage.name) > -1) {
      price = formatCurrencyPayment.format(props.selectedPackage.price / 12);
      price_ferq = "Monthly";
    }
  }

  return (
    <Grid
      container
      style={{ width: "70%", margin: "0 auto", height: "100vh" }}
      justifyContent="center"
    >
      <Grid xs={10} item>
        <Typography
          color="primary"
          variant="h1"
          style={{ fontSize: "4rem", textAlign: "center" }}
        >
          Thank you
        </Typography>
        <Typography
          color="textPrimary"
          variant="body1"
          gutterBottom
          style={{ fontSize: "1.5rem", textAlign: "center" }}
        >
          Your survey is successfully completed
        </Typography>
        <Typography
          color="primary"
          variant="body1"
          style={{ fontSize: "1rem", textAlign: "center" }}
        >
          your selected package is {props?.selectedPackage?.name} with price $
          {formatCurrencyPayment.format(price)}
          with frequency {price_ferq}{" "}
        </Typography>
      </Grid>
    </Grid>
  );
};
const getSelectedPackage = (props) => {
  let selectedPackage = null;
  selectedPackage = props?.roadmap_id
    ? props.packageList?.find((pkg) => {
        return (
          pkg.paymentTermKey + "_" + pkg?.pkg?.id ==
          props?.payment_term + "_" + props.package_management_id
        );
      })
    : props.packageList?.find((pkg) => {
        return pkg.id == props.package_management_id;
      });
  if (props?.paymentCategory == "cfsSurvey") {
    selectedPackage = props.selectedPackage;
  }
  return selectedPackage;
};
const initialize = (props, creditCardHostedFields, eCheckHostedFields) => {
  const style = {
    color: "#000",
    fontSize: "11px",
    fontWeight: "400",
    padding: "8px",
    width: "100%",
  };
  const cvvstyle = {
    color: "#000",
    fontSize: "11px",
    fontWeight: "400",
    padding: "8px",
    width: "100%",
    "-webkit-text-security": "disc",
  };
  const creditCardHostedFieldsConfig = {
    publicKey: props?.cpaDetail?.cpa_charge_info?.test_accounts[0]?.public_key,
    tokenizationTimeout: 8000,
    fields: [
      {
        selector: "#my_credit_card_field_id",
        input: {
          type: "credit_card_number",
          css: style,
          placeholder: "1223 1234 1234 1234",
          required: true,
        },
      },
      {
        selector: "#my_cvv_field_id",
        input: {
          type: "cvv",
          css: cvvstyle,
          placeholder: "123",
          required: true,
        },
      },
    ],
  };

  const eCheckHostedFieldsConfig = {
    publicKey: props.cpaDetail.cpa_charge_info.test_accounts[0].public_key,
    // css: style,
    tokenizationTimeout: 8000,
    fields: [
      {
        selector: "#my_bank_account_number_id",
        placeholder: "Bank Account Number",
        input: {
          type: "bank_account_number",
          css: style,
        },
      },
      {
        selector: "#my_routing_number_id",
        input: {
          type: "routing_number",
          css: style,
        },
      },
    ],
  };

  eCheckHostedFields.current = window.AffiniPay.HostedFields.initializeFields(
    eCheckHostedFieldsConfig,
    () => {}
  );

  creditCardHostedFields.current = window.AffiniPay.HostedFields.initializeFields(
    creditCardHostedFieldsConfig,
    () => {}
  );
  // }
};
const PackagePay = (props) => {
  const { classes, selectedPackage } = props;
  let price = null;
  let price_ferq = null;
  if (annual_packages.indexOf(selectedPackage?.name) > -1) {
    price = formatCurrencyPayment.format(selectedPackage?.price);
    price_ferq = "One Time";
  } else if (monthly_packages.indexOf(selectedPackage?.name) > -1) {
    price = formatCurrencyPayment.format(selectedPackage?.price / 12);
    price_ferq = "Monthly";
  }
  return (
    <Grid xs={10} md={6} item>
      <Grid container>
        <Grid item xs={10}>
          <Typography color="primary" variant="h2">
            Selected Package Summary
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.cardPackage}>
          <Grid container alignItems="space-between">
            <Grid item xs={12}>
              <Typography
                className={classes.optionTitle}
                color="textSecondary"
                align="center"
              >
                {props?.roadmap_id
                  ? selectedPackage?.pkg?.attributes?.name
                  : selectedPackage?.name}
              </Typography>
              <Typography
                className={
                  !props?.roadmap_id
                    ? classes.optionPriceLarge
                    : classes.optionPriceSmall
                }
                color="primary"
                align="center"
              >
                {props?.roadmap_id ? selectedPackage?.value : `$ ${price}`}
                {!props?.roadmap_id && (
                  <Typography
                    className={classes.optionPriceSmall}
                    color="primary"
                    align="center"
                    display="inline"
                  >
                    {price_ferq}
                  </Typography>
                )}
              </Typography>
              <Divider />
              <div className={classes.featureListContainer}>
                {props?.roadmap_id
                  ? selectedPackage?.pkg?.attributes?.features?.data?.map(
                      (feature, index) => {
                        return (
                          <Typography
                            key={index}
                            color="textSecondary"
                            className={classes.featureText}
                          >
                            {feature?.attributes?.feature_name}
                          </Typography>
                        );
                      }
                    )
                  : selectedPackage?.feature_name_list?.map((feature) => {
                      return (
                        <Typography
                          key={feature}
                          color="textSecondary"
                          className={classes.featureText}
                        >
                          {feature}
                        </Typography>
                      );
                    })}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="flex-end"
              justifyContent="center"
            >
              {/* <Box className={classes.bottomDivider} /> */}
              <Typography
                color="primary"
                align="center"
                className={classes.cardPackageBottomText}
                onClick={() => {
                  props.openPotentialSavings();
                }}
              >
                See Potential Savings = $36,500
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export const PaymentPage = (props) => {
  const [isFirst, setFirst] = useState(true);
  const [expiry, setExpiry] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [cardNoError, setCardNoError] = useState("");
  const [cvvError, setCVVError] = useState("");

  const [routingNumberError, setRoutingNumberError] = useState("");
  const [bankAccountNumberError, setBankAccountNumberErrorError] = useState("");

  const [paymentType, setPaymentType] = useState("creditCard");

  const [business_name, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [account_type, handleAccountTypeChange] = useState("savings");
  const [account_holder_type, handleAccountHolderTypeChange] = useState(
    "personal"
  );
  console.log(isFirst, routingNumberError, bankAccountNumberError);
  const handleEcheckPayment = ({ target }) => {
    if (target.name === "account_holder_type") {
      handleAccountHolderTypeChange(target.value);
    } else if (target.name === "account_type") {
      handleAccountTypeChange(target.value);
    } else if (target.name == "firstName") {
      setFirstName(target.value);
    } else if (target.name == "lastName") {
      setLastName(target.value);
    } else if (target.name == "business_name") {
      setBusinessName(target.value);
    }
  };
  let selectedPackage = getSelectedPackage(props);
  let creditCardHostedFields = React.useRef(null);
  let eCheckHostedFields = React.useRef(null);

  useEffect(() => {
    if (!selectedPackage) {
      props.showToast({
        type: "error",
        message: "please select a package first",
      });
      if(props.handleBack){
         props.handleBack();
      }
    } else {
      initialize(props, creditCardHostedFields, eCheckHostedFields);
      setFirst(false);
    }
  }, []);

  const creaditCardonsubmit = (event) => {
    event.preventDefault();
    if (creditCardHostedFields?.current?.getState()?.isReady) {
      setCardNoError(null);
      setCVVError(null);
      const postal_code = document.getElementById("postal_code").value;
      const exp_year = document
        .getElementById("cardExpiryDate")
        .value.split("/")[1];
      const exp_month = document
        .getElementById("cardExpiryDate")
        .value.split("/")[0];

      creditCardHostedFields?.current
        .getPaymentToken({
          postal_code: postal_code,
          exp_year: exp_year,
          exp_month: exp_month,
        })
        .then(function(result) {
          props.collectPayment(result.id);
          // If getPaymentToken returns successfully you may pass your payment token to your backend service.
        })
        .catch(function(err) {});
    } else {
      const fields = creditCardHostedFields.current?.getState().fields;
      const numberField = fields?.find((f) => {
        return f?.type === "credit_card_number";
      });
      if (numberField?.error) {
        setCardNoError(numberField?.error);
      } else {
        setCardNoError(null);
      }
      const cvvField = fields?.find((f) => {
        return f?.type === "cvv";
      });
      if (cvvField?.error) {
        setCVVError(cvvField?.error);
      } else {
        setCVVError(null);
      }
    }
  };
  const eCheckSubmit = (event) => {
    event.preventDefault();
    if (eCheckHostedFields.current?.getState().isReady) {
      setBankAccountNumberErrorError(null);
      setRoutingNumberError(null);
      const given_name = document.getElementById("given_name")?.value;
      const surname = document.getElementById("surname")?.value;
      const business_name = document.getElementById("business_name")?.value;
      const isBusiness = document.getElementById("business_account_holder_type")
        .checked;
      const isSavings = document.getElementById("savings_account_type").checked;
      const extra_fields = {};
      extra_fields.account_holder_type = isBusiness ? "business" : "individual";
      extra_fields.account_type = isSavings ? "savings" : "checking";
      if (isBusiness) {
        extra_fields.name = business_name;
      } else {
        extra_fields.given_name = given_name;
        extra_fields.surname = surname;
      }
      eCheckHostedFields.current
        .getPaymentToken(extra_fields)
        .then(function(result) {
          props.collectPayment(result.id);
        })
        .catch(function(err) {});
    } else {
      const fields = eCheckHostedFields.current?.getState()?.fields;
      const numberField = fields?.find((f) => {
        return f?.type === "bank_account_number";
      });
      if (numberField?.error) {
        setBankAccountNumberErrorError(numberField?.error);
      } else {
        setBankAccountNumberErrorError(null);
      }
      const cvvField = fields?.find((f) => {
        return f?.type === "routing_number";
      });
      if (cvvField?.error) {
        setRoutingNumberError(cvvField?.error);
      } else {
        setRoutingNumberError(null);
      }
    }
  };
  const handleInputChange = ({ target }) => {
    if (target.name === "cardNumber") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
    } else if (target.name === "cardCvc") {
      target.value = formatCVC(target.value);
    } else if (target.name === "zipcode") {
      setZipCode(target.value);
    }
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value);
  };
  let price = null;
  let price_ferq = null;
  if (selectedPackage) {
    if (annual_packages.indexOf(selectedPackage.name) > -1) {
      price = formatCurrencyPayment.format(selectedPackage.price);
      price_ferq = "One Time";
    } else if (monthly_packages.indexOf(selectedPackage.name) > -1) {
      price = formatCurrencyPayment.format(selectedPackage.price / 12);
      price_ferq = "Monthly";
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ margin: "20px auto", width: "75%" }}
    >
      <Grid md={10} xs={12} item>
        <Typography color="primary" gutterBottom style={{ paddingBottom: 10 }}>
          {!props?.roadmap_id
            ? `your selected package is ${
                selectedPackage?.name
              } with one time charge
            $ ${formatCurrencyPayment.format(selectedPackage?.price)} `
            : `your selected package is ${selectedPackage?.pkg?.attributes?.name} with selected payment term ${selectedPackage?.value}
              `}
        </Typography>
      </Grid>
      <Grid md={10} xs={12} item>
        {props?.isEcheckPaymentAvailable && (
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              id="paymentMethodType"
              name="paymentType"
              onChange={handleRadioChange}
              value={paymentType}
            >
              <FormControlLabel
                value="creditCard"
                control={<Radio id="creditCard_radio_button" color="primary" />}
                label="credit Card"
              />
              <FormControlLabel
                value="eCheck"
                control={<Radio id="eCheck_radio_button" color="primary" />}
                label="eCheck"
              />
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
      <Grid xs={12} md={10} item>
        {/* {paymentType === "creditCard" ? ( */}
        <div
          style={
            paymentType === "creditCard"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <form id="creditCardForm" onSubmit={creaditCardonsubmit}>
            <Grid
              container
              id="creditCardFormcontainer"
              justifyContent="center"
              spacing={3}
            >
              <Grid md={6} xs={12} item>
                <Typography color="primary" variant="h2">
                  Please Enter Your Card Detail Below
                </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Grid item md={9} xs={12}>
                    <label for="my_credit_card_field_id">Credit Card</label>
                    <div id="my_credit_card_field_id" />
                    {cardNoError ? (
                      <div style={{ color: "red" }}>{cardNoError}</div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <div>
                      <label for="expiry">Expiry</label>
                      <input
                        style={{
                          width: "100%",
                          border: "1px solid rgb(204, 204, 204) !important",
                          padding: "8px",
                        }}
                        id="cardExpiryDate"
                        required
                        value={expiry}
                        placeholder="MMYYYY"
                        name="expiry"
                        pattern="(0[1-9]|1[012])/\d\d\d\d"
                        onChange={(event) => {
                          handleInputChange(event);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <div>
                      <label for="my_cvv_field_id">CVV</label>
                      <div id="my_cvv_field_id" />
                      {cvvError ? (
                        <div style={{ color: "red" }}>{cvvError}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <label for="ZipCode">Zip Code</label>
                    <input
                      style={{
                        width: "100%",
                        border: "1px solid rgb(204, 204, 204) !important",
                        padding: "8px",
                      }}
                      id="postal_code"
                      value={zipcode}
                      name="zipcode"
                      pattern="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
                      onChange={(event) => {
                        handleInputChange(event);
                      }}
                    />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      id="creditCardPaymentSubmit"
                      color="primary"
                      type="submit"
                      endIcon={<ArrowForwardOutlinedIcon />}
                      //  disabled={!creditCardHostedFields?.getState()?.isReady}
                    >
                      Pay
                      {props?.roadmap_id
                        ? selectedPackage?.value
                        : `$ ${price + " " + price_ferq}`}
                    </Button>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <PackagePay {...props} selectedPackage={selectedPackage} />
            </Grid>
          </form>
        </div>

        {/* ) : ( */}
        <div
          style={
            paymentType !== "creditCard"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <form id="AccountForm" onSubmit={eCheckSubmit}>
            <Grid
              id="accountdetailFormcontainer"
              container
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography color="primary" variant="h2">
                  Please Enter Your Account Detail
                </Typography>
              </Grid>
              <Grid md={6} xs={12} item>
                <Grid container spacing={1}>
                  <Grid xs={12} item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Account Holder Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="account_holder_type"
                        id="account_holder_type"
                        name="account_holder_type"
                        onChange={handleEcheckPayment}
                        value={account_holder_type}
                      >
                        <FormControlLabel
                          value="business"
                          control={
                            <Radio
                              id="business_account_holder_type"
                              color="primary"
                            />
                          }
                          label="Business"
                        />
                        <FormControlLabel
                          value="personal"
                          control={
                            <Radio
                              id="personal_account_holder_type"
                              color="primary"
                            />
                          }
                          label="Personal"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid md={6} xs={12} item>
                    <div>
                      <label for="my_routing_number_id">Routing Number</label>
                      <div id="my_routing_number_id"></div>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div>
                      <label for="my_bank_account_number_id">
                        Account Number
                      </label>
                      <div id="my_bank_account_number_id"></div>
                    </div>
                  </Grid>
                  <Grid xs={12} item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Account Type</FormLabel>
                      <RadioGroup
                        row
                        aria-label="account_type"
                        id="account_type"
                        name="account_type"
                        onChange={handleEcheckPayment}
                        value={account_type}
                      >
                        <FormControlLabel
                          value="checking"
                          control={
                            <Radio id="checking_account_type" color="primary" />
                          }
                          label="Checking"
                        />
                        <FormControlLabel
                          value="savings"
                          control={
                            <Radio id="savings_account_type" color="primary" />
                          }
                          label="Savings"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {account_holder_type === "personal" && (
                    <>
                      <Grid item md={6} xs={12}>
                        <label for="my_credit_card_name">First Name</label>
                        <input
                          style={{
                            width: "100%",
                            border: "1px solid rgb(204, 204, 204) !important",
                            padding: "8px",
                          }}
                          required
                          pattern="[a-zA-z]+"
                          value={firstName}
                          id="given_name"
                          name="firstName"
                          onChange={handleEcheckPayment}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <label for="my_credit_card_name">Last Name</label>
                        <input
                          style={{
                            width: "100%",
                            border: "1px solid rgb(204, 204, 204) !important",
                            padding: "8px",
                          }}
                          required
                          pattern="[a-zA-z]+"
                          value={lastName}
                          id="surname"
                          name="lastName"
                          onChange={handleEcheckPayment}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                  {account_holder_type === "business" && (
                    <>
                      <Grid item xs={12}>
                        <label for="my_credit_card_name">Business Name</label>
                        <input
                          style={{
                            width: "100%",
                            border: "1px solid rgb(204, 204, 204) !important",
                            padding: "8px",
                          }}
                          required
                          pattern="[a-zA-z]+"
                          value={business_name}
                          id="business_name"
                          name="business_name"
                          onChange={handleEcheckPayment}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      id="echeckpaymentsubmit"
                      fullWidth
                      color="primary"
                      type="submit"
                      endIcon={<ArrowForwardOutlinedIcon />}
                      //  disabled={!creditCardHostedFields?.getState()?.isReady}
                    >
                      Pay ${" "}
                      {props.roadmap_id
                        ? selectedPackage?.value
                        : `$ ${price + " " + price_ferq}`}
                    </Button>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <PackagePay {...props} selectedPackage={selectedPackage} />
            </Grid>
          </form>
        </div>

        {/* )} */}
      </Grid>
    </Grid>
  );
};

export default withStyles((theme) =>
  createStyles({
    cardPackageSelected: {
      borderRadius: "1.8rem",
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: "#ffffff",
      minHeight: "30rem",
      position: "relative",
      padding: "1rem",
      flexGrow: 0,
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      justifyContent: "center",
      maxWidth: "75%",
      margin: "3% 1%",
      display: "flex",
      cursor: "pointer",
      flexBasis: "75%",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
        flexGrow: 0,
        maxWidth: "100%",
        marginRight: 0,
      },
    },
    cardPackage: {
      maxWidth: "75%",
      flexBasis: "75%",
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      minHeight: "30rem",
      borderRadius: "1.8rem",
      border: `2px solid white`,
      margin: "3% 1%",
      display: "flex",
      padding: "1rem",
      flexGrow: 0,
      justifyContent: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexGrow: 0,
        marginRight: 0,
        flexBasis: "100%",
      },
    },
    cardPackageBottomText: {
      fontWeight: 600,
      borderTop: "1px solid rgba(0, 0, 0, 0.08)",
      fontSize: "1.1rem",
      paddingTop: "1rem",
    },
    optionPriceLarge: {
      marginBottom: "0.5rem",
      fontSize: "1.8rem",
      fontWeight: 700,
    },
    optionTitle: {
      fontSize: "1.3rem",
      fontWeight: 700,
    },
    featureText: {
      marginBottom: "0.5rem",
      fontSize: "1.1rem",
      margin: "0.6rem",
      position: "relative",
      paddingLeft: "1.25rem",
      "&::before": {
        content: "''",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        width: "0.625rem",
        borderRadius: "50%",
        left: 0,
        height: "0.625rem",
        top: "0.4rem",
      },
    },
    optionPriceSmall: {
      fontSize: "1rem",
      fontWeight: 600,
      marginBottom: "0.5rem",
      marginLeft: "0.25rem",
    },

    bottomDivider: {
      height: "1px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      width: "90%",
    },
  })
)(PaymentPage);
