//@ts-nocheck
import React from "react";
import ClientsListContainerController, { Props } from "./ClientsListContainerController.web";
import { Typography, Grid, withStyles, createStyles, TextField, InputAdornment, Hidden, Slide } from "@material-ui/core";
import { toggleIn, toggleOut } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import SelectFieldBlue from "../../../../components/src/SelectFieldBlue.web";
import ArchivedClientDialog from "./ArchivedClientDialog.web";
import { SmallMenuItem } from "../../../../components/src/ModifiedComponents.web";
import SmallButton from "../../../../components/src/SmallButton.web";
import AddNewClientDialog from "./AddNewClientDialog.web";
import { Formik, Field, Form } from "formik";
import SearchIcon from "@material-ui/icons/Search";
import ClientsListMinimal from "./ClientsListMinimal.web";
import ClientsListAdvanced from "./ClientsListAdvanced.web";
import ChangeStatusDialog from "./ChangeStatusDialog.web";
import AddMultipleClientsDialogWeb from "./AddMultipleClientsDialog.web";
import withPermissions from "../../../../components/src/withPermissions.web";

const clientListFilterOptions = ["All", "Individual", "Business"];
const bulkActionOptions = ["Bulk Actions", "Send Reminders", "Change Status"];

export class ClientsListContainer extends ClientsListContainerController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const isAdvancedViewOpen = await StorageProvider.get("isAdvancedViewOpen");
    const isMinimalViewOpen = await StorageProvider.get("isMinimalViewOpen");
    const searchValue = this.props.history?.location?.state?.searchValue; 
    if (isAdvancedViewOpen && isMinimalViewOpen) {
      let ad = isAdvancedViewOpen === "true" ? true : false;
      let min = isMinimalViewOpen === "true" ? true : false;

      this.setState(
        {
          isAdvancedViewOpen: ad,
        },
        () => {
          this.setState({
            isMinimalViewOpen: min,
          });
        }
      );
    }

    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        if(!searchValue) {
          this.getClientsList();
        } else {
          this.searchClient(searchValue)
        }
      }
    );
  }

  openAddClientDialog = (filterValue) => {
    this.props
      .openDialogBox({
        dataToPass: {
          showToast: this.props.showToast.bind(this),
          authToken : this.state.token
        },
        width: "45%",
        renderedComponent: AddNewClientDialog,
        disableBackdropClick : true,
        disableEscapeKeyDown : true,

        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          if (filterValue === "Individual") {
            this.getClientsList("Individual");
          } else if (filterValue === "Business") {
            this.getClientsList("Business");
          } else {
            this.getClientsList();
          }
          this.props.showToast({
            type: "success",
            message: "Client added succssfully",
          });
        }
      });
  };

  openMultipleClientAddDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          package: data,
          showToast: this.props.showToast.bind(this),
          downloadSheet: this.downloadSheet.bind(this)
        },
        width: "45%",
        renderedComponent: AddMultipleClientsDialogWeb,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data.submitValue === "Yes") {
          this.addBulkClients(data.csvFile);
        }
      });
  };

  openChangeStatusDialog = (outerData) => {
    this.props
      .openDialogBox({
        dataToPass: {
          selectedClientsList: outerData.selectedClientsList,
        },
        width: "45%",
        renderedComponent: ChangeStatusDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data?.value !== "Archived") {
          this.changeStatus(data.value, outerData.selectedClientsList);
        } else if (data?.value === "Archived") {
          this.openArchivedDialog({
            selectedClientsList: outerData.selectedClientsList,
          });
        }
      });
  };

  openArchivedDialog = (outerData) => {
    this.props
      .openDialogBox({
        dataToPass: {},
        width: "45%",
        renderedComponent: ArchivedClientDialog,
        withCustomDialog: false,
      })
      .then(async (dataFromDialog: any) => {
        if (dataFromDialog) {
          this.changeStatus("Archived", outerData.selectedClientsList);
        }
      });
  };

  handleBulkActionsMenuClick = (opt) => {
    if (opt === "Send Reminders") {
      let temp = [...this.state.clientsList];
      let selectedClientsList = temp.filter((item) => item.selected === true);
      if (selectedClientsList.length === 0) {
        this.props.showToast({
          type: "error",
          message: "Please select a client to send reminder.",
        });
        return;
      }
      if (selectedClientsList.every((item) => item.attributes.profile_status === "Signature & Payment Pending")) {
        this.sendReminders(selectedClientsList);
      } else {
        this.props.showToast({
          type: "error",
          message: "Please select clients with pending status",
        });
      }
    } else if (opt === "Change Status") {
      let temp = [...this.state.clientsList];
      let selectedClientsList = temp.filter((item) => item.selected === true);
      if (selectedClientsList.length === 0) {
        this.props.showToast({
          type: "error",
          message: "Please select a client to change status.",
        });
        return;
      }
      this.openChangeStatusDialog({
        selectedClientsList,
      });
    }
  };

  handleFilterChange = (opt) => {
    if (opt === "Individual") {
      this.getClientsList("Individual");
    } else if (opt === "Business") {
      this.getClientsList("Business");
    } else {
      this.getClientsList();
    }
  };

  setClientsList = (newClientsList) => {
    this.setState(
      {
        clientsList: newClientsList,
      },
      () => {
        let allItemsDeselected = this.state.clientsList.every((item) => item.selected === false);
        let allItemsSelected = this.state.clientsList.every((item) => item.selected === true);
        if (allItemsDeselected) {
          this.setState({
            headerCheckbox: false,
          });
        }
        if (allItemsSelected) {
          this.setState({
            headerCheckbox: true,
          });
        }
      }
    );
  };

  setSelectedClient = (selectedClient) => {
    this.setState(
      {
        selectedClient: selectedClient,
      },
      () => {
        this.getPriorities(+this.state.selectedClient.id);
      }
    );
  };

  setHeaderCheckbox = (e) => {
    this.setState(
      {
        headerCheckbox: e.target.checked,
      },
      () => {
        let temp = [...this.state.clientsList];
        let newClientList = temp.map((mapClient: any) => {
          return {
            ...mapClient,
            selected: this.state.headerCheckbox,
          };
        });
        this.setState({
          clientsList: newClientList,
        });
      }
    );
  };

  togglePageHandler = () => {
    this.setState(
      (prevState) => ({
        isAdvancedViewOpen: !prevState.isAdvancedViewOpen,
        isMinimalViewOpen: !prevState.isMinimalViewOpen,
      }),
      () => {
        StorageProvider.set("isAdvancedViewOpen", this.state.isAdvancedViewOpen);
        StorageProvider.set("isMinimalViewOpen", this.state.isMinimalViewOpen);
      }
    );
  };

  getMenuItemStyles = (opt) => {
    return {
      display: opt === "Bulk Actions" ? "none" : "block",
      borderTop: opt === "Send Reminders" ? "none" : undefined,
    };
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <>
        <Formik
          initialValues={{
            clientListFilter: "All",
            bulkActions: "Bulk Actions",
          }}
          enableReinitialize
        >
          {(formikProps) => {
            const { values } = formikProps;
            const toggleIcon = this.state.isAdvancedViewOpen ? toggleOut : toggleIn;
            return (
              <Form autoComplete="off" noValidate>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    position: "relative",
                  }}
                >
                  {/* fist line */}
                  <Hidden smUp>
                    <Grid item xs={12} className={[classes.gridSpacing]}>
                      <TextField
                        variant="outlined"
                        placeholder="Search..."
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                paddingRight: 0,
                              }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Hidden>
                  {/* second line */}
                  {this.state.isMinimalViewOpen ? (
                    <>
                      <Grid
                        item
                        xs={6}
                        className={[classes.gridSpacing, classes.headingGrid].join(",")}
                        style={{
                          marginTop: "30px",
                          marginBottom: 0,
                          position: "relative",
                        }}
                        container
                        alignItems="center"
                      >
                        <Typography variant="h2" className={classes.clientListHeading}>
                          Client List
                        </Typography>
                        <Field
                          component={SelectFieldBlue}
                          name="clientListFilter"
                          value={values.clientListFilter}
                          select
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {clientListFilterOptions.map((opt) => {
                            return (
                              <SmallMenuItem id="handleFilterChange" key={opt} value={opt} onClick={this.handleFilterChange.bind(this, opt)}>
                                {opt}
                              </SmallMenuItem>
                            );
                          })}
                        </Field>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={[classes.gridSpacing]}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{
                          color: theme.palette.text.blue2,
                          marginTop: "30px",
                          marginBottom: 0,
                        }}
                      >
                        <SmallButton
                          style={{
                            textTransform: "none",
                            marginRight: "1rem",
                          }}
                          id="openMultipleClientAddDialog"
                          onClick={this.openMultipleClientAddDialog}
                        >
                          + Add Clients
                        </SmallButton>
                        <SmallButton
                          style={{
                            textTransform: "none",
                          }}
                          id='openAddClientDialog'
                          onClick={this.openAddClientDialog.bind(this, values.clientListFilter)}
                        >
                          + Add a Client
                        </SmallButton>
                        <Field
                          component={SelectFieldBlue}
                          name="bulkActions"
                          value={values.bulkActions}
                          select
                          style={{
                            display: "inline-block",
                            padding: "0 1rem",
                          }}
                        >
                          {bulkActionOptions.map((opt) => {
                            return (
                              <SmallMenuItem
                                key={opt}
                                value={opt}
                                disabled={opt === "Bulk Actions"}
                                style={this.getMenuItemStyles(opt)}
                                onClick={this.handleBulkActionsMenuClick.bind(this,opt)}
                              >
                                {opt}
                              </SmallMenuItem>
                            );
                          })}
                        </Field>
                        <img src={toggleIcon} className={classes.iconStyle} alt="zoomIcon" onClick={this.togglePageHandler} />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={6}
                        className={[classes.gridSpacing, classes.headingGrid]}
                        style={{
                          marginTop: "30px",
                          marginBottom: 0,
                          position: "relative",
                        }}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item xs={5} container alignItems="center">
                          <Typography variant="h2" className={classes.clientListHeading}>
                            Client List
                          </Typography>
                          <Field
                            component={SelectFieldBlue}
                            name="clientListFilter"
                            select
                            style={{
                              display: "inline-block",
                              width: "100px",
                            }}
                          >
                            {clientListFilterOptions.map((opt) => {
                              return (
                                <SmallMenuItem key={opt} value={opt} onClick={this.handleFilterChange.bind(this, opt)}>
                                  {opt}
                                </SmallMenuItem>
                              );
                            })}
                          </Field>
                        </Grid>
                        <Grid item xs={7} container alignItems="center" justifyContent="flex-end">
                          <SmallButton
                            style={{
                              textTransform: "none",
                              marginRight: "1rem",
                            }}
                            onClick={this.openMultipleClientAddDialog}
                          >
                            + Add Clients
                          </SmallButton>
                          <SmallButton
                            style={{
                              textTransform: "none",
                            }}
                            onClick={this.openAddClientDialog.bind(this, values.clientListFilter)}
                          >
                            + Add a Client
                          </SmallButton>
                          <Field
                            component={SelectFieldBlue}
                            name="bulkActions"
                            select
                            style={{
                              display: "inline-block",
                              padding: "0 1rem",
                            }}
                          >
                            {bulkActionOptions.map((opt) => {
                              return (
                                <SmallMenuItem
                                  key={opt}
                                  value={opt}
                                  disabled={opt === "Bulk Actions"}
                                  style={this.getMenuItemStyles(opt)}
                                  onClick={this.handleBulkActionsMenuClick.bind(this,opt)}
                                >
                                  {opt}
                                </SmallMenuItem>
                              );
                            })}
                          </Field>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        className={[classes.gridSpacing, classes.headingGrid]}
                        style={{
                          marginTop: "30px",
                          marginBottom: 0,
                          position: "relative",
                        }}
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <img
                          src={toggleIcon}
                          className={classes.iconStyle}
                          style={{
                            marginLeft: "2rem",
                          }}
                          alt="zoomIcon"
                          onClick={this.togglePageHandler}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {/* third line */}

        {this.state.isAdvancedViewOpen ? (
          <Slide direction="left" in={this.state.isAdvancedViewOpen} mountOnEnter unmountOnExit>
            <ClientsListAdvanced
              clientsList={this.state.clientsList}
              setClientsList={this.setClientsList}
              selectedClient={this.state.selectedClient}
              setSelectedClient={this.setSelectedClient}
              headerCheckbox={this.state.headerCheckbox}
              setHeaderCheckbox={this.setHeaderCheckbox}
              prioritiesList={this.state.prioritiesList}
              addPriority={this.addPriority}
              getPriorities={this.getPriorities}
              updatePriority={this.updatePriority}
              deletePriority={this.deletePriority}
              dragPriority={this.dragPriority}
            />
          </Slide>
        ) : null}

        {this.state.isMinimalViewOpen ? (
          <Slide direction="right" in={this.state.isMinimalViewOpen} mountOnEnter unmountOnExit>
            <ClientsListMinimal
              clientsList={this.state.clientsList}
              setClientsList={this.setClientsList}
              headerCheckbox={this.state.headerCheckbox}
              setHeaderCheckbox={this.setHeaderCheckbox}
            />
          </Slide>
        ) : null}
      </>
    );
  }
}

const ClientsListContainerWithRouter = withRouter(ClientsListContainer);
const ClientsListContainerAlertBox = withAlertBox(ClientsListContainerWithRouter);
const ClientsListContainerLoader = withLoader(ClientsListContainerAlertBox);
const ClientsListContainerToast = withToast(ClientsListContainerLoader);
const ClientsListContainerWithDialog = withDialog(ClientsListContainerToast);
const ClientsListContainerWithConfirmBox = withConfirmBox(ClientsListContainerWithDialog);
const ClientsListContainerWithPermissions = withPermissions(ClientsListContainerWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      iconStyle: {
        height: "1.875rem",
        width: "1.875rem",
        cursor: "pointer",
      },
      clientListHeading: {
        display: "inline-block",
        color: theme.palette.text.blue2,
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        padding: "0 1rem",
      },
    }),
  { withTheme: true }
)(ClientsListContainerWithPermissions);
