//@ts-nocheck
import React, { useCallback } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';



import { withStyles } from "@material-ui/core";
export type openDialogProps = {
  title: string;
  message: string;
};
export type alertDialogProps = {
  title: string;
  message: string;
  open?: boolean;
  onConfirm: Function;
};
export interface AlertDialogOptions {
  title: string;
  message: any | null;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    borderRadius: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "1.5rem",
    fontFamily: "Lato",
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  message: {
    fontSize: "1.2rem",
    color: theme.palette.text.secondary,
    fontFamily: "Lato",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AlertDialog = withStyles(styles)((props: alertDialogProps) => {
  const { open, onConfirm, title, message, classes } = props;
  return (
    // <Dialog
    //   open={open}
    //   onClose={onConfirm}
    //   maxWidth="sm"
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   className={classes.root}
    // >
    //   <DialogTitle id="alert-dialog-title" className={classes.title}>{title}</DialogTitle>
    //   <DialogContent>
    //     <DialogContentText id="alert-dialog-description" className={classes.message}>
    //       {message}
    //     </DialogContentText>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={() => {
    //         onConfirm(message);
    //       }}
    //       variant="outlined"
    //       autoFocus
    //     >
    //       Ok
    //     </Button>
    //   </DialogActions>
    // </Dialog>

    <Dialog
      open={open}
      onClose={onConfirm}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => onConfirm(message)}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.message}>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
});

export const AlertDialogContext = React.createContext<
  (options: DialogOptions) => Promise<void>
>(Promise.reject);

export const AlertDialogProvider = ({ children }) => {
  const [
    dialogState,
    setDialogState,
  ] = React.useState<AlertDialogOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openDialogBox = (options: AlertDialogOptions) => {
    setDialogState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleCancel = () => {
    if (dialogState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setDialogState(null);
  };

  const handleSubmit = (data: any) => {
    setDialogState(null);
    if (awaitingPromiseRef.current) {
      return awaitingPromiseRef.current.resolve(data);
    }
  };
  return (
    <AlertDialogContext.Provider value={openDialogBox}>
      <AlertDialog
        open={Boolean(dialogState)}
        onConfirm={handleSubmit}
        {...dialogState}
      />
      {children}
    </AlertDialogContext.Provider>
  );
};
