//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  MenuItem,
} from "@material-ui/core";
import ChartDataLabels from "chartjs-plugin-datalabels";
import RevenueGrowthChartDialogController, {
  Props,
} from "./RevenueGrowthChartDialogController.web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import MediumBlueField from "../../../../components/src/MediumBlueField.web";
import { revenueGrowthChartoptions } from "./FirmPerformanceRevenue.web";
import { dialogStyle } from "./CustomerGrowthChartDialog.web";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const revenueGrowthTimePeriodOptions = [
  { name: "Last 12 Months", value: 12 },
  { name: "Last 9 Months", value: 9 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last 3 Months", value: 3 },
];

export class NewDashboardChartDialog extends RevenueGrowthChartDialogController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getOnBoardedClientDataForRev(this.state.revenueGrowthTimePeriod);
        }
      );
    }
  }

  handleTimeChange = (e) => {
    this.setState(
      {
        revenueGrowthTimePeriod: e.target.value,
      },
      () => {
        this.getOnBoardedClientDataForRev(this.state.revenueGrowthTimePeriod);
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ width: "70vw", padding: "2.25rem" }}>
        <Grid item xs={9}>
          <Typography variant="h2" className={classes.dialogHeading}>
            Revenue
          </Typography>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          <Grid item className={classes.textCard}>
            <Typography variant="h2" color="primary" display="inline">
              NA
            </Typography>
            <MediumBlueField
              onChange={this.handleTimeChange.bind(this)}
              select
              value={this.state.revenueGrowthTimePeriod}
              className="selectTime"
              style={{
                marginLeft: "1rem",
                display: "inline-block",
              }}
            >
              {revenueGrowthTimePeriodOptions.map((opt) => {
                return (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                );
              })}
            </MediumBlueField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              minHeight: "31.25rem",
            }}
          >
            <Line
              plugins={[ChartDataLabels]}
              data={this.state.revenueGrowthLineData}
              options={revenueGrowthChartoptions}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const NewDashboardChartDialogWithRouter = withRouter(NewDashboardChartDialog);
const NewDashboardChartDialogWithToast = withToast(
  NewDashboardChartDialogWithRouter
);
const NewDashboardChartDialogWithLoader = withLoader(
  NewDashboardChartDialogWithToast
);
const NewDashboardChartDialogWithAlertBox = withAlertBox(
  NewDashboardChartDialogWithLoader
);
const NewDashboardChartDialogWithDialogBox = withDialogBox(
  NewDashboardChartDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    ...dialogStyle(theme)
  })
)(NewDashboardChartDialogWithDialogBox);
