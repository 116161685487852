//@ts-nocheck
import React from "react";
import { Typography, Grid, Box, withStyles, createStyles, TextField, Button, makeStyles, IconButton, InputAdornment } from "@material-ui/core";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../components/src/withSnackBar.Web";
import withLoader, { withLoaderProps } from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import uuid from "react-uuid";
import { Formik, Field, Form, FieldArray } from "formik";
import CustomGrid from "../../../components/src/CustomGrid.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DialogProps } from "../../../components/src/DialogContext";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

export const resetFormState = {
  currentAnnualRev: "",
  annualRevGoal: "",
  businessClients: "",
  individualClients: "",
  totalClients: "",
  currentAnnualGross: "",
  currentMonthlyGross: "",
  goalAnnualGross: "",
  goalMonthlyGross: "",
  currentAnnualOwner: "",
  currentMonthlyOwner: "",
  goalAnnualOwner: "",
  goalMonthlyOwner: "",
  percentOfClientsNewBusinessIncubator: "",
  revGoalNewBusinessIncubator: "",
  percentOfClientsBusinessBundle: "",
  revGoalBusinessBundle: "",
  percentOfClientsBusinessTax: "",
  revGoalBusinessTax: "",
  percentOfClientsAccountingSuccess: "",
  revGoalAccountingSuccess: "",
  percentOfClientsPayrollSuccess: "",
  revGoalPayrollSuccess: "",
  percentOfClientsPersonalBundle: "",
  revGoalPersonalBundle: "",
  percentOfClientsIRSRapidRelief: "",
  revGoalIRSRapidRelief: "",
  percentOfClientsReportingOnlyBusiness: "",
  revGoalReportingOnlyBusiness: "",
  percentOfClientsReportingOnlyIndividual: "",
  revGoalReportingOnlyIndividual: "",
  operatingSpaceExpense: "",
  technologyExpense: "",
  bizdevMarketingExpense: "",
  insuranceExpense: "",
  othersExpense: "",
  laborTotalExpense: "",
  totalExpenses: "",
  expenseLaborsAttributes: [
    {
      id: uuid(),
      labor_role: "Tax Accountant",
      current_annual: "",
      unit: 1,
      employee_compensation: "",
      _destroy: 0,
    },
    {
      id: uuid(),
      labor_role: "Full-Time Admin",
      current_annual: "",
      unit: 1,
      employee_compensation: "",
      _destroy: 0,
    },
    {
      id: uuid(),
      labor_role: "Seasonal Admin",
      current_annual: "",
      unit: 1,
      employee_compensation: "",
      _destroy: 0,
    },
  ],
  pricingData: [
    {
      package_name: "New Business Incubator",
      package_type: "business",
      time_period: "per unit",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Business Bundle",
      package_type: "business",
      time_period: "per month",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Business Tax & Advisory",
      package_type: "business",
      time_period: "per month",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Accounting Success",
      package_type: "business",
      time_period: "per month",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Payroll Success",
      package_type: "business",
      time_period: "per month",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Reporting-Only Package",
      package_type: "business",
      time_period: "per unit",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Personal Bundle",
      package_type: "Individual",
      time_period: "per month",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "IRS Rapid Relief",
      package_type: "Individual",
      time_period: "per unit",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
    {
      package_name: "Reporting-Only Package",
      package_type: "Individual",
      time_period: "per unit",
      no_of_packages: "",
      rev_goal_percent: "",
      percent_of_client: "",
      strategic_price: "",
      revenue_map: "",
      id: uuid(),
    },
  ],
};

export const SmallTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: (props) => (props.size === "small" ? "30%" : "50%"),
    },
  })
)(TextField);

export const TableRowCustomized = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-of-type": {
        "& td": {
          border: "none",
        },
      },
    },
  })
)(TableRow);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
      borderColor: "#f9f9f9",
    },
    head: {
      backgroundColor: (props) => (props.color ? props.color : theme.palette.common.white),
      color: (props) => (props.textcolor ? props.textcolor : theme.palette.text.secondary),
      textAlign: (props) => (props.align ? props.align : "start"),
      fontSize: 14,
      border: "none",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
    body: {
      backgroundColor: (props) => (props.color ? props.color : theme.palette.common.white),
      color: (props) => (props.textcolor ? props.textcolor : theme.palette.text.secondary),
      textAlign: (props) => (props.align ? props.align : "start"),
      fontSize: "0.875rem",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
  })
)(TableCell);

const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    fontFamily: "Lato",
    fontSize: "0.8rem",
    fontWeight: 600,
    backgroundColor: "#fff",
    color: (props) => {
      return props.color ? props.color : theme.palette.text.blue2;
    },
    border: (props) => {
      return props.color ? `1px solid ${props.color}` : `1px solid ${theme.palette.text.blue2}`;
    },
    maxWidth: 700,
    borderRadius: "8px",
  },
}));

export const BootstrapTooltip = (props: TooltipProps) => {
  const classes = useStylesBootstrap(props);

  return <Tooltip classes={classes} {...props} />;
}

let NF = new Intl.NumberFormat("en-US");

export const pricingData = [
  {
    package_name: "New Business Incubator",
    package_type: "business",
    time_period: "per unit",
    no_of_packages: 0,
    rev_goal_percent: "revGoalNewBusinessIncubator",
    percent_of_client: "percentOfClientsNewBusinessIncubator",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Business Bundle",
    package_type: "business",
    time_period: "per month",
    no_of_packages: 0,
    rev_goal_percent: "revGoalBusinessBundle",
    percent_of_client: "percentOfClientsBusinessBundle",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Business Tax & Advisory",
    package_type: "business",
    time_period: "per month",
    no_of_packages: 0,
    rev_goal_percent: "revGoalBusinessTax",
    percent_of_client: "percentOfClientsBusinessTax",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Accounting Success",
    package_type: "business",
    time_period: "per month",
    no_of_packages: 0,
    rev_goal_percent: "revGoalAccountingSuccess",
    percent_of_client: "percentOfClientsAccountingSuccess",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Payroll Success",
    package_type: "business",
    time_period: "per month",
    no_of_packages: 0,
    rev_goal_percent: "revGoalPayrollSuccess",
    percent_of_client: "percentOfClientsPayrollSuccess",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Reporting-Only Package",
    package_type: "business",
    time_period: "per unit",
    no_of_packages: 0,
    rev_goal_percent: "revGoalReportingOnlyBusiness",
    percent_of_client: "percentOfClientsReportingOnlyBusiness",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Personal Bundle",
    package_type: "Individual",
    time_period: "per month",
    no_of_packages: 0,
    rev_goal_percent: "revGoalPersonalBundle",
    percent_of_client: "percentOfClientsPersonalBundle",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "IRS Rapid Relief",
    package_type: "Individual",
    time_period: "per unit",
    no_of_packages: 0,
    rev_goal_percent: "revGoalIRSRapidRelief",
    percent_of_client: "percentOfClientsIRSRapidRelief",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
  {
    package_name: "Reporting-Only Package",
    package_type: "Individual",
    time_period: "per unit",
    no_of_packages: 0,
    rev_goal_percent: "revGoalReportingOnlyIndividual",
    percent_of_client: "percentOfClientsReportingOnlyIndividual",
    strategic_price: "",
    revenue_map: "",
    id: uuid(),
  },
];

export const TernaryCheck = (props) => {
  const { condition, trueComponent, falseComponent } = props;

  return <>{condition ? trueComponent : falseComponent}</>;
};

const calculateTernary = ({condition, trueValue, falseValue}) => (condition ? trueValue : falseValue);

export const GoalsTable = ({ classes, theme, props, checkIfNumber, rowsforGoals, isDefaultFirmFinancials = false }) => {
  const paddingStyle = { padding: calculateTernary({ condition: props.isCalculated, trueValue: "1.8rem 1rem", falseValue: "1rem" }) };
  return (
    <Grid item xs={12} className={classes.cardPaper}>
      <Grid container direction="row" spacing={1} justifyContent="space-between">
        <Grid item xs={12} container alignItems="center">
          <Typography display="inline" className={classes.goalcardHeading}>
            Goals
          </Typography>
          <BootstrapTooltip
            title="Input the financial goals for your firm. The SmartPath Pricing Engine will calculate the best price for each of your packages so you can reach the goals entered here."
            interactive
            placement="right"
            color={theme.palette.text.blue2}
          >
            <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
              <InfoOutlinedIcon style={{ color: theme.palette.text.blue2, fontSize: "1.3rem" }} />
            </IconButton>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={props.isCalculated && !isDefaultFirmFinancials ? 9 : 12}>
          <TableContainer
            style={{
              width: "100%",
              borderRadius: "16px",
              boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
            }}
          >
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell color={"#f9f9f9"} width="28%" className={classes.columnsHeadingForGoal}>
                    Goals
                  </StyledTableCell>
                  <StyledTableCell color={"#f9f9f9"} className={classes.columnsHeadingForGoal} align="center">
                    Current(Annual)
                  </StyledTableCell>
                  <StyledTableCell color={"#f9f9f9"} className={classes.columnsHeadingForGoal} align="center">
                    Current(Monthly)
                  </StyledTableCell>
                  <StyledTableCell color={"#f9f9f9"} className={classes.columnsHeadingForGoal} align="center">
                    Goal(Annual)
                  </StyledTableCell>
                  <StyledTableCell color={"#f9f9f9"} className={classes.columnsHeadingForGoal} align="center">
                    Goal(Monthly)
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsforGoals.map((row) => (
                  <TableRowCustomized key={row.goalName}>
                    <StyledTableCell scope="row" className={classes.textForGoals} width="28%">
                      {row.goalName}
                    </StyledTableCell>
                    <TernaryCheck
                      condition={props.isCalculated}
                      trueComponent={
                        <StyledTableCell align="center" className={classes.blueTextGoals} style={paddingStyle}>
                          $ {NF.format(props[row.currentAnnual]?.replace(/,/g, ""))}
                        </StyledTableCell>
                      }
                      falseComponent={
                        <StyledTableCell align="center">
                          <TextField
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            id="input-checkIfNumber"
                            className={classes.blueTextField}
                            value={props[`${row.currentAnnual}`]}
                            name={row.currentAnnual}
                            onChange={checkIfNumber}
                            variant="outlined"
                          />
                        </StyledTableCell>
                      }
                    />
                    <StyledTableCell align="center" className={classes.blueTextGoals}>
                      $ {NF.format(Math.round(row.currentMonthly))}
                    </StyledTableCell>
                    <TernaryCheck
                      condition={props.isCalculated}
                      trueComponent={
                        <StyledTableCell align="center" className={classes.blueTextGoals} style={paddingStyle}>
                          $ {NF.format(props[row.goalAnnual]?.replace(/,/g, ""))}
                        </StyledTableCell>
                      }
                      falseComponent={
                        <StyledTableCell align="center">
                          <TextField
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            className={classes.blueTextField}
                            value={props[`${row.goalAnnual}`]}
                            name={row.goalAnnual}
                            onChange={checkIfNumber}
                            variant="outlined"
                          />
                        </StyledTableCell>
                      }
                    />
                    <StyledTableCell align="center" className={classes.blueTextGoals}>
                      $ {NF.format(Math.round(row.goalMonthly))}
                    </StyledTableCell>
                  </TableRowCustomized>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <TernaryCheck
          condition={props.isCalculated}
          trueComponent={
            <Grid container xs={isDefaultFirmFinancials ? 12 : 3} justifyContent="space-between">
              <CustomGrid item width={isDefaultFirmFinancials ? "31%" : "100%"} container justifyContent="center" alignItems="center" direction="column" className={isDefaultFirmFinancials ? classes.blueBoxGoals_Default : classes.blueBoxGoals}>
                <Typography className={calculateTernary({ condition: isDefaultFirmFinancials, trueValue: classes.threeSmallCardsHeading_Default, falseValue: classes.threeSmallCardsHeading })}>Annual Free Cashflow</Typography>
                <Typography className={calculateTernary({ condition: isDefaultFirmFinancials, trueValue: classes.threeSmallCardsPrice_Default, falseValue: classes.threeSmallCardsPrice })}>
                  {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(Math.round(props.calculationsList?.annual_free_cashflow || 0))}
                </Typography>
              </CustomGrid>
              <CustomGrid item width={isDefaultFirmFinancials ? "31%" : "100%"} container justifyContent="center" alignItems="center" direction="column" className={isDefaultFirmFinancials ? classes.blueBoxGoals_Default : classes.blueBoxGoals}>
                <Typography className={calculateTernary({ condition: isDefaultFirmFinancials, trueValue: classes.threeSmallCardsHeading_Default, falseValue: classes.threeSmallCardsHeading })}>Gross Profit Margin</Typography>
                <Typography className={calculateTernary({ condition: isDefaultFirmFinancials, trueValue: classes.threeSmallCardsPrice_Default, falseValue: classes.threeSmallCardsPrice })}>{`${Number(Math.round(props.calculationsList?.gross_profit_margin || 0)).toFixed(2)} %`}</Typography>
              </CustomGrid>
              <CustomGrid item width={isDefaultFirmFinancials ? "31%" : "100%"} container justifyContent="center" alignItems="center" direction="column" className={isDefaultFirmFinancials ? classes.blueBoxGoals_Default : classes.blueBoxGoals}>
                <Typography className={calculateTernary({ condition: isDefaultFirmFinancials, trueValue: classes.threeSmallCardsHeading_Default, falseValue: classes.threeSmallCardsHeading })}>Monthly Revenue Floor</Typography>
                <Typography className={calculateTernary({ condition: isDefaultFirmFinancials, trueValue: classes.threeSmallCardsPrice_Default, falseValue: classes.threeSmallCardsPrice })}>
                  {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(Math.round(props.calculationsList?.monthly_revenue_floor || 0))}
                </Typography>
              </CustomGrid>
            </Grid>
          }
          falseComponent={null}
        />
      </Grid>
    </Grid>
  );
};

export const ClientInfoTable = ({ classes, theme, props, checkIfNumber }) => {
  const paddingStyle = { padding: props.isCalculated ? "1.8rem 1rem" : "1rem" };
  return (
    <Grid item xs={12} className={classes.cardPaper}>
      <Grid container direction="row" spacing={1} justifyContent="space-between">
        <Grid item xs={12} container alignItems="center">
          <Typography display="inline" className={classes.goalcardHeading}>
            Client Info
          </Typography>
          <BootstrapTooltip
            color={theme.palette.text.blue2}
            title="Input how many clients you would ideally want to work with to reach your Revenue Goal. Businesses are clients that earn the majority of their income from working for themselves. Individuals are clients that earn the majority of their income working for someone else."
            placement="right"
          >
            <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
              <InfoOutlinedIcon style={{ color: theme.palette.text.blue2, fontSize: "1.3rem" }} />
            </IconButton>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            style={{
              width: "100%",
              borderRadius: "16px",
              boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
            }}
          >
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell color={"#f9f9f9"} width="85%" className={classes.columnsHeadingForGoal}>
                    Client Info
                  </StyledTableCell>
                  <StyledTableCell color={"#f9f9f9"} width="15%" className={classes.columnsHeadingForGoal} align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRowCustomized>
                  <StyledTableCell scope="row" className={classes.textForGoals}>
                    What is your ideal number of business clients?
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.blueTextGoals} style={paddingStyle}>
                    {props.isCalculated ? (
                      <Typography className={classes.blueText}>{NF.format(Number(("" + props.businessClients)?.replace(/,/g, "")).toFixed(2))}</Typography>
                    ) : (
                      <TextField
                        name="businessClients"
                        value={props.businessClients}
                        onChange={checkIfNumber}
                        variant="outlined"
                        label=""
                        className={classes.blueText}
                      />
                    )}
                  </StyledTableCell>
                </TableRowCustomized>
                <TableRowCustomized>
                  <StyledTableCell className={classes.textForGoals}>What is your ideal number of individual clients?</StyledTableCell>
                  <StyledTableCell align="center" className={classes.blueTextGoals} style={paddingStyle}>
                    {props.isCalculated ? (
                      <Typography className={classes.blueText}>{NF.format(Number(("" + props.individualClients)?.replace(/,/g, "")).toFixed(2))}</Typography>
                    ) : (
                      <TextField
                        name="individualClients"
                        value={props.individualClients}
                        onChange={checkIfNumber}
                        variant="outlined"
                        label=""
                        className={classes.blueText}
                      />
                    )}
                  </StyledTableCell>
                </TableRowCustomized>
                <TableRowCustomized>
                  <StyledTableCell
                    className={classes.textForGoals}
                    style={{
                      color: theme.palette.text.blue2,
                      fontWeight: "bold",
                    }}
                  >
                    Total Clients
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.blueTextGoals}
                    style={{
                      color: theme.palette.text.blue2,
                      fontWeight: "bold",
                      padding: "1.8rem 1rem",
                    }}
                  >
                    {NF.format(Number(("" + props.totalClients)?.replace(/,/g, "")).toFixed(2))}
                  </StyledTableCell>
                </TableRowCustomized>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LaborSubTable = ({
  classes,
  values,
  props,
  setFieldValue,
  handleChange,
  laborTotal,
  getLaborTotal,
  getLaborMonthly,
  setState,
  expenseFieldOnChangeHandler,
}) => {
  const laborRoleChangeHandler = (e) => {
    let regex = /^[A-Za-z-\s]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      handleChange(e);
      setState({
        isCalculated: false,
      });
    }
  };

  const expenseFieldUnitChangeHandler = (event) => {
    const value = event.target.value;
    const newValue = value.replace(/[^1-9]/g, "");
    event.target.value = newValue;

    if (event.target.value === "" || event.target.value.length < 12) {
      setFieldValue(event.target.name, event.target.value);
      setState({
        isCalculated: false,
      });
    }
  };

  const paddingStyle = { padding: props.isCalculated ? "1.8rem 1rem" : "1rem" };
  return (
    <React.Fragment>
      <TableRow>
        <StyledTableCell width="70%" className={[classes.textForGoals, classes.noBorder]}>
          Labor
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              setState((prevState) => ({
                expandLabors: !prevState.expandLabors,
              }))
            }
          >
            {props.expandLabors ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="center" width="15%" className={[classes.blueBoldTextExpense, classes.noBorder]}>
          {laborTotal > 0 || props.isCalculated ? (
            getLaborTotal(values)
          ) : (
            <Field
              as={TextField}
              className={classes.blueTextField}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              name="laborTotalExpense"
              value={values.laborTotalExpense}
              onChange={(e) => {
                expenseFieldOnChangeHandler(e, setFieldValue);
                setFieldValue("totalExpenses", "");
              }}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" width="15%" className={[classes.blueBoldTextExpense, classes.noBorder]}>
          {getLaborMonthly(values)}
        </StyledTableCell>
      </TableRow>

      <TableRow>
        <StyledTableCell style={{ padding: 0 }} colSpan={3} className={classes.noBorder}>
          <Collapse in={props.expandLabors} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      width="20%"
                      style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "bold", fontFamily: "Lato" }}
                      className={classes.columnsHeading}
                      color={"#F9F9F9"}
                    >
                      # In Role
                    </StyledTableCell>
                    <StyledTableCell
                      width="25%"
                      scope="row"
                      style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "bold", fontFamily: "Lato" }}
                      className={classes.columnsHeading}
                      color={"#F9F9F9"}
                    >
                      Role Name
                    </StyledTableCell>
                    <StyledTableCell
                      width="25%"
                      style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "bold", fontFamily: "Lato" }}
                      className={classes.columnsHeading}
                      color={"#F9F9F9"}
                    >
                      Employee Compensation (Non Owner)
                    </StyledTableCell>
                    <StyledTableCell
                      width="15%"
                      align="center"
                      style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "bold", fontFamily: "Lato" }}
                      className={classes.columnsHeading}
                      color={"#F9F9F9"}
                    >
                      Current (Annual)
                    </StyledTableCell>
                    <StyledTableCell
                      width="15%"
                      align="center"
                      style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "bold", fontFamily: "Lato" }}
                      className={classes.columnsHeading}
                      color={"#F9F9F9"}
                    >
                      Current (Monthly)
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="expenseLaborsAttributes">
                    {(fieldArrayProps) => {
                      const { form, push } = fieldArrayProps;
                      const { values } = form;
                      return values.expenseLaborsAttributes.map((expense, expenseIndex) => {
                        return (
                          <React.Fragment key={expense.id}>
                            {expense._destroy === 0 ? (
                              <TableRow>
                                <StyledTableCell className={classes.blueBoldTextExpense}>
                                  <Grid container alignItems="center">
                                    <TernaryCheck
                                      condition={props.isCalculated && !expense?.additionalLaborEntry}
                                      trueComponent={
                                        <Typography className={classes.blueBoldTextExpense}>{values.expenseLaborsAttributes[expenseIndex].unit}</Typography>
                                      }
                                      falseComponent={
                                        <Field
                                          as={TextField}
                                          className={classes.blueTextField}
                                          variant="outlined"
                                          onChange={(e) => expenseFieldUnitChangeHandler(e)}
                                          value={values.expenseLaborsAttributes[expenseIndex].unit}
                                          name={`expenseLaborsAttributes[${expenseIndex}].unit`}
                                        />
                                      }
                                    />
                                    <TernaryCheck
                                      condition={expenseIndex > 2 && props.isCalculated === false}
                                      trueComponent={
                                        <RemoveCircleOutlineIcon
                                          onClick={() => {
                                            setFieldValue(`expenseLaborsAttributes[${expenseIndex}]._destroy`, 1);
                                          }}
                                          style={{ color: "#FF5C5C", cursor: "pointer", marginLeft: "1rem" }}
                                        ></RemoveCircleOutlineIcon>
                                      }
                                      falseComponent={null}
                                    />
                                    <TernaryCheck
                                      condition={expenseIndex === 2 && props.isCalculated === false}
                                      trueComponent={
                                        <AddCircleOutlineIcon
                                          color="secondary"
                                          style={{ cursor: "pointer", marginLeft: "1rem" }}
                                          onClick={() => {
                                            push({
                                              id: uuid(),
                                              labor_role: "",
                                              current_annual: "",
                                              unit: 1,
                                              employee_compensation: "",
                                              additionalLaborEntry: true,
                                              _destroy: 0,
                                            });
                                            setState({
                                              isCalculated: false,
                                            });
                                          }}
                                        />
                                      }
                                      falseComponent={null}
                                    />
                                  </Grid>
                                </StyledTableCell>
                                <StyledTableCell scope="row" style={paddingStyle}>
                                  {props.isCalculated && !expense?.additionalLaborEntry ? (
                                    <Typography className={classes.textForGoals}>{values.expenseLaborsAttributes[expenseIndex].labor_role}</Typography>
                                  ) : (
                                    <Field
                                      as={TextField}
                                      className={classes.fontSizeS}
                                      variant="outlined"
                                      value={values.expenseLaborsAttributes[expenseIndex].labor_role}
                                      onChange={laborRoleChangeHandler}
                                      name={`expenseLaborsAttributes[${expenseIndex}].labor_role`}
                                    />
                                  )}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {props.isCalculated && !expense?.additionalLaborEntry ? (
                                    <Typography className={classes.blueBoldTextExpense}>
                                      $ {values.expenseLaborsAttributes[expenseIndex].employee_compensation}
                                    </Typography>
                                  ) : (
                                    <Field
                                      as={TextField}
                                      className={classes.blueTextField}
                                      variant="outlined"
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                      onChange={(e) => {
                                        expenseFieldOnChangeHandler(e, setFieldValue);
                                        setFieldValue("laborTotalExpense", "");
                                        setFieldValue("totalExpenses", "");
                                      }}
                                      value={values.expenseLaborsAttributes[expenseIndex].employee_compensation}
                                      name={`expenseLaborsAttributes[${expenseIndex}].employee_compensation`}
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center" className={classes.blueBoldTextExpense}>
                                  ${" "}
                                  {NF.format(
                                    Number(("" + expense.employee_compensation)?.replace(/,/g, "") * ("" + expense.unit)?.replace(/,/g, "")).toFixed(2)
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center" className={classes.blueBoldTextExpense}>
                                  ${" "}
                                  {NF.format(
                                    Number(
                                      Number(Math.round(+(("" + expense.employee_compensation)?.replace(/,/g, "") * +("" + expense.unit)?.replace(/,/g, "")) / 12)).toFixed(
                                        2
                                      )
                                    )
                                  )}
                                </StyledTableCell>
                              </TableRow>
                            ) : null}
                          </React.Fragment>
                        );
                      });
                    }}
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const ExpenseTable = ({
  classes,
  theme,
  props,
  values,
  setFieldValue,
  handleChange,
  setState,
  expenseFieldOnChangeHandler,
  showToast
}) => {
  const paddingStyle = { padding: props.isCalculated ? "1.8rem 1rem" : "1rem" };

  const getTotalExpense = () => {
    return (+("" + values.expenses.operatingSpaceExpense)?.replace(/,/g, "") +
    +("" + values.expenses.technologyExpense)?.replace(/,/g, "") +
    +("" + values.expenses.bizdevMarketingExpense)?.replace(/,/g, "") +
    +("" + values.expenses.insuranceExpense)?.replace(/,/g, "") +
    +("" + values.expenses.othersExpense)?.replace(/,/g, ""));
  }
  const getLabourExpense = () => {
    return values.expenseLaborsAttributes.reduce((item, accu) => {
      return +("" + accu.employee_compensation)?.replace(/,/g, "") * +accu.unit + +item;
    }, 0);
  }
  
  let expenseTotal = getTotalExpense();
  

  let mlaborTotal = getLabourExpense();

  const getGrandTotalAnnual = (values) => {

  let expenseTotal =   getTotalExpense();
    let laborTotal = getLabourExpense();
    expenseTotal = +expenseTotal + +laborTotal;

    if (laborTotal === 0) {
      expenseTotal = +expenseTotal + +("" + values.laborTotalExpense)?.replace(/,/g, "");
    }

    if (expenseTotal > 0) {
      return `$ ${NF.format(Number(expenseTotal).toFixed(2))}`;
    } else {
      return `$ ${NF.format(Number(+("" + values.totalExpenses)?.replace(/,/g, "")).toFixed(2))}`;
    }
  };

  const getGrandTotalMonthly = (values) => {

  let expenseTotal = getTotalExpense();
    let laborTotal = getLabourExpense();
    expenseTotal = +expenseTotal + +laborTotal;

    if (laborTotal === 0) {
      expenseTotal = +expenseTotal + +("" + values.laborTotalExpense)?.replace(/,/g, "");
    }
    
    if (expenseTotal > 0) {
      return `$ ${NF.format(Number(Math.round(expenseTotal / 12)).toFixed(2))}`;
    } else {
      return `$ ${NF.format(Number(Math.round(+("" + values.totalExpenses)?.replace(/,/g, "") / 12)).toFixed(2))}`;
    }
  };

  const getLaborTotal = (values) => {
    let laborTotal = getLabourExpense();
    if (laborTotal > 0) {
      return `$ ${NF.format(Number(laborTotal).toFixed(2))}`;
    } else {
      return `$ ${NF.format(Number(values.laborTotalExpense).toFixed(2))}`;
    }
  };

  const getLaborMonthly = (values) => {
    let laborTotal = getLabourExpense();
    if (laborTotal > 0) {
      return `$ ${NF.format(Number(Math.round(laborTotal / 12)).toFixed(2))}`;
    } else {
      return `$ ${NF.format(Number(Math.round(+("" + values.laborTotalExpense)?.replace(/,/g, "") / 12)).toFixed(2))}`;
    }
  };

  return (
    <Grid item xs={12} className={classes.cardPaper}>
      <Grid container direction="row" spacing={1} justifyContent="space-around">
        <Grid item xs={12} container alignItems="center">
          <Typography display="inline" className={classes.goalcardHeading}>
            Expenses
          </Typography>
          <BootstrapTooltip
            title="Input the current expenses for your firm. Your optimized pricing will account for these costs to ensure your packages are profitable."
            placement="right"
            color={theme.palette.text.blue2}
          >
            <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
              <InfoOutlinedIcon style={{ color: theme.palette.text.blue2, fontSize: "1.3rem" }} />
            </IconButton>
          </BootstrapTooltip>
          <Box
            style={{
              marginLeft: "auto",
              marginRight: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomTypography
              color="secondary"
              style={{
                display: "inline-block",
              }}
              fontWeight={700}
              fontSize="1.1rem"
              margin="0 1rem 0 0"
            >
              Total Annual Expenses:
            </CustomTypography>
            {expenseTotal > 0 || props.isCalculated ? (
              <CustomTypography color="secondary" fontSize="1.5rem" fontWeight={600}>
                {getGrandTotalAnnual(values)}
              </CustomTypography>
            ) : (
              <Field
                as={TextField}
                className={classes.blueTextField}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                name="totalExpenses"
                value={values.totalExpenses}
                onChange={(e) => expenseFieldOnChangeHandler(e, setFieldValue)}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ width: "100%", borderRadius: "16px", boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)" }}>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell textcolor={theme.palette.text.secondary} color={"#F9F9F9"} width="70%" className={classes.columnsHeading}>
                    Expenses
                  </StyledTableCell>
                  <StyledTableCell textcolor={theme.palette.text.secondary} color={"#F9F9F9"} align="center" width="15%" className={classes.columnsHeading}>
                    Current(Annual)
                  </StyledTableCell>
                  <StyledTableCell textcolor={theme.palette.text.secondary} color={"#F9F9F9"} align="center" width="15%" className={classes.columnsHeading}>
                    Current(Monthly)
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <StyledTableCell scope="row" width="70%" className={classes.textForGoals}>
                    Operating Space
                  </StyledTableCell>
                  {props.isCalculated ? (
                    <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense} style={paddingStyle}>
                      $ {NF.format(Number(("" + values.expenses.operatingSpaceExpense)?.replace(/,/g, "")).toFixed(2))}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" width="15%">
                      <Field
                        as={TextField}
                        className={classes.blueTextField}
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name={`expenses.operatingSpaceExpense`}
                        value={values.expenses.operatingSpaceExpense}
                        onChange={(e) => {
                          expenseFieldOnChangeHandler(e, setFieldValue);
                          setFieldValue("totalExpenses", "");
                        }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense}>
                    $ {NF.format(Number(Math.round(("" + values.expenses.operatingSpaceExpense)?.replace(/,/g, "") / 12)).toFixed(2))}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell scope="row" width="70%" className={classes.textForGoals}>
                    Technology
                  </StyledTableCell>
                  {props.isCalculated ? (
                    <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense} style={paddingStyle}>
                      $ {NF.format(Number(("" + values.expenses.technologyExpense)?.replace(/,/g, "")).toFixed(2))}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" width="15%">
                      <Field
                        as={TextField}
                        className={classes.blueTextField}
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name={`expenses.technologyExpense`}
                        value={values.expenses.technologyExpense}
                        onChange={(e) => {
                          expenseFieldOnChangeHandler(e, setFieldValue);
                          setFieldValue("totalExpenses", "");
                        }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense}>
                    $ {NF.format(Number(Math.round(("" + values.expenses.technologyExpense)?.replace(/,/g, "") / 12)).toFixed(2))}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell scope="row" width="70%" className={classes.textForGoals}>
                    Business Development/Marketing
                  </StyledTableCell>
                  {props.isCalculated ? (
                    <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense} style={paddingStyle}>
                      $ {NF.format(Number(("" + values.expenses.bizdevMarketingExpense)?.replace(/,/g, "")).toFixed(2))}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" width="15%">
                      <Field
                        as={TextField}
                        className={classes.blueTextField}
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name={`expenses.bizdevMarketingExpense`}
                        value={values.expenses.bizdevMarketingExpense}
                        onChange={(e) => {
                          expenseFieldOnChangeHandler(e, setFieldValue);
                          setFieldValue("totalExpenses", "");
                        }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense}>
                    $ {NF.format(Number(Math.round(("" + values.expenses.bizdevMarketingExpense)?.replace(/,/g, "") / 12)).toFixed(2))}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell scope="row" width="70%" className={classes.textForGoals}>
                    Insurance
                  </StyledTableCell>
                  {props.isCalculated ? (
                    <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense} style={paddingStyle}>
                      $ {NF.format(Number(("" + values.expenses.insuranceExpense)?.replace(/,/g, "")).toFixed(2))}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" width="15%">
                      <Field
                        as={TextField}
                        className={classes.blueTextField}
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name={`expenses.insuranceExpense`}
                        value={values.expenses.insuranceExpense}
                        onChange={(e) => {
                          expenseFieldOnChangeHandler(e, setFieldValue);
                          setFieldValue("totalExpenses", "");
                        }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense}>
                    $ {NF.format(Number(Math.round(("" + values.expenses.insuranceExpense)?.replace(/,/g, "") / 12)).toFixed(2))}
                  </StyledTableCell>
                </TableRow>

                <LaborSubTable
                  classes={classes}
                  values={values}
                  props={props}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  laborTotal={mlaborTotal}
                  getLaborTotal={getLaborTotal}
                  getLaborMonthly={getLaborMonthly}
                  setState={setState}
                  expenseFieldOnChangeHandler={expenseFieldOnChangeHandler}
                />

                <TableRow>
                  <StyledTableCell scope="row" width="70%" className={classes.textForGoals}>
                    Other
                  </StyledTableCell>
                  {props.isCalculated ? (
                    <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense} style={paddingStyle}>
                      $ {NF.format(Number(("" + values.expenses.othersExpense)?.replace(/,/g, "")).toFixed(2))}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center" width="15%">
                      <Field
                        as={TextField}
                        className={classes.blueTextField}
                        variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        name={`expenses.othersExpense`}
                        value={values.expenses.othersExpense}
                        onChange={(e) => {
                          expenseFieldOnChangeHandler(e, setFieldValue);
                          setFieldValue("totalExpenses", "");
                        }}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center" width="15%" className={classes.blueBoldTextExpense}>
                    $ {NF.format(Number(Math.round(("" + values.expenses.othersExpense)?.replace(/,/g, "") / 12)).toFixed(2))}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell
                    width="70%"
                    className={[classes.textForGoals, classes.noBorder]}
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.text.blue2,
                    }}
                  >
                    Total
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    width="15%"
                    className={[classes.blueBoldTextExpense, classes.noBorder]}
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.text.blue2,
                    }}
                  >
                    {getGrandTotalAnnual(values)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    width="15%"
                    className={[classes.blueBoldTextExpense, classes.noBorder]}
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.text.blue2,
                    }}
                  >
                    {getGrandTotalMonthly(values)}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BottomPortion = ({ classes, props, setState, permissions = [], isDefaultFirmFinancials }) => {
  return (
    <TernaryCheck
      condition={isDefaultFirmFinancials}
      trueComponent={
        <CustomGrid container justifyContent="space-between" margin="0 0 2rem 0">
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              backgroundColor: "#f4f4f4",
              padding: "0.75rem 1rem",
              borderRadius: "10px",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            <CustomTypography color="textSecondary" fontSize="1rem" fontWeight="bold">
              Total Revenue
            </CustomTypography>
            <CustomTypography color="textSecondary" fontSize="1.1rem" fontWeight="bold" padding="0 2rem 0 0">
              {calculateTernary({
                condition: props.isCalculated,
                trueValue: `$ ${Math.round(+props.totalRevenue || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`,
                falseValue: "$0.00"
              })}
            </CustomTypography>
          </Grid>
    
          <CustomGrid item xs={4} width="39%" container alignItems="center" className={classes.boxOfArr}>
            <Typography className={classes.arrMrrText}>
              Total Annual Recurring Revenue{" "}
              {calculateTernary({
                condition: props.totalArr && props.isCalculated,
                trueValue: `$ ${Math.round(+props.totalArr).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                falseValue: "$---,---.--"
              })}
            </Typography>
          </CustomGrid>
    
          <CustomGrid className={classes.boxOfArr} alignItems="center" width="39%" item xs={4} container justifyContent="space-between">
            <Typography className={classes.arrMrrText}>
              Total Monthly Recurring Revenue{" "}
              {calculateTernary({
                condition: props.totalMrr && props.isCalculated,
                trueValue: `$ ${Math.round(+props.totalMrr).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                falseValue: "$---,---.--"
              })}
            </Typography>
          </CustomGrid>
    
          <CustomGrid alignItems="center" width="20%" item xs={2} container justifyContent="space-between">
            <Button
              color="primary"
              fullWidth
              variant={props.isCalculated ? "outlined" : "contained"}
              type="submit"
              id="firmFinancials-submitButton"
              disabled={!permissions.includes("View/Edit Firm Financials-Firm Financials")}
            >
              {calculateTernary({
                condition: props.isCalculated,
                trueValue: "Edit",
                falseValue: "Calculate & Save"
              })}
            </Button>
          </CustomGrid>
        </CustomGrid>
      }
      falseComponent={
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            container
            justifyContent={props.isCalculated ? "flex-end" : "space-between"}
            alignItems="center"
            style={{
              backgroundColor: "#f4f4f4",
              padding: "0.75rem 1rem",
              borderRadius: "10px",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            <CustomTypography color={props.isCalculated ? "primary" : "textSecondary"} fontSize="1rem" fontWeight="bold" padding="0 1rem 0 0">
              Total Revenue
            </CustomTypography>
            <CustomTypography color={props.isCalculated ? "primary" : "textSecondary"} fontSize="1.1rem" fontWeight="bold" padding="0 2rem 0 0">
              {calculateTernary({
                condition: props.isCalculated,
                trueValue: `$ ${(Math.round(+props.totalRevenue || 0)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`,
                falseValue: "$0.00"
              })}
            </CustomTypography>
          </Grid>

          <CustomGrid item xs={4} width="34%" container alignItems="center" className={props.isCalculated ? classes.calculatedBoxOfArr : classes.boxOfArr}>
            <Typography className={props.isCalculated ? classes.calculatedArrText : classes.arrMrrText}>
              Total Annual Recurring Revenue{" "}
              {calculateTernary({
                condition: props.totalArr && props.isCalculated,
                trueValue: `$ ${Math.round(+props.totalArr).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                falseValue: "$---,---.--"
              })}
            </Typography>
          </CustomGrid>

          <TernaryCheck
            condition={!props.isCalculated}
            trueComponent={
              <CustomGrid className={classes.boxOfArr} alignItems="center" width="34%" item xs={4} container justifyContent="space-between">
                <Typography className={classes.arrMrrText}>
                  Total Monthly Recurring Revenue{" "}
                  {calculateTernary({
                    condition: props.totalMrr && props.isCalculated,
                    trueValue: `$ ${Math.round(+props.totalMrr).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                    falseValue: "$---,---.--"
                  })}
                </Typography>
              </CustomGrid>
            }
            falseComponent={<CustomGrid width="34%"></CustomGrid>}
          />

          <CustomGrid alignItems="center" width="9%" item xs={2} container justifyContent="space-between">
            <TernaryCheck
              condition={props.isCalculated}
              trueComponent={
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setState({
                      isCalculated: false,
                    });
                  }}
                >
                  Edit
                </Button>
              }
              falseComponent={null}
            />
          </CustomGrid>

          <CustomGrid alignItems="center" width="19%" item xs={2} container justifyContent="space-between">
            <Button color="primary" fullWidth variant="contained" type="submit" id="firmFinancials-submitButton">
              {calculateTernary({
                condition: props.isCalculated,
                trueValue: "Save & Continue",
                falseValue: "Calculate"
              })}
            </Button>
          </CustomGrid>

          <TernaryCheck
            condition={props.isCalculated}
            trueComponent={
              <CustomGrid
                className={classes.boxOfArr}
                alignItems="center"
                width="34%"
                item
                xs={4}
                container
                justifyContent="space-between"
                margin="1rem 0 0 0"
                style={{
                  backgroundColor: "#feca5a",
                }}
              >
                <Typography className={props.isCalculated ? classes.calculatedMrrText : classes.arrMrrText}>
                  Total Monthly Recurring Revenue{" "}
                  {calculateTernary({
                    condition: props.totalMrr && props.isCalculated,
                    trueValue: `$ ${Math.round(+props.totalMrr).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                    falseValue: "$---,---.--"
                  })}
                </Typography>
              </CustomGrid>
            }
            falseComponent={null}
          />
        </Grid>
      }
    />

  );
};

export const PricingDataTable = ({
  classes,
  theme,
  props,
  values,
  checkIfNumberWithMax100Value,
  checkIfFloat,
  getPerOfClientBusinessTotal,
  getRevenueGoalTotal,
  getPerOfClientIndividualTotal,
  pricingData,
  setState,
  permissions = [],
  isDefaultFirmFinancials = false
}) => {
  const defaultValue = "$ 0.00";
  const locale = "en-US";
  const minFractionDigits = 2;
  const maxFractionDigits = 2;

  const formatDollarValue = (isCalculated, value) => {
    return isCalculated
      ? `$ ${(+value || 0).toLocaleString(locale, {
          minimumFractionDigits: minFractionDigits,
          maximumFractionDigits: maxFractionDigits,
        })}`
      : defaultValue;
  };

  return (
    <Grid item xs={12} className={classes.cardPaper}>
      <Grid container direction="row" spacing={1} justifyContent="space-around">
        <Grid item xs={12} container alignItems="center">
          <Typography display="inline" className={classes.goalcardHeading}>
            Pricing Data
          </Typography>
          <BootstrapTooltip
            title="Input how many clients you currently work with in each category. Businesses are clients that earn the majority of their income from working for themselves. Individuals are clients that earn the
                    majority of their income working for someone else."
            placement="right"
            color={theme.palette.text.blue2}
          >
            <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
              <InfoOutlinedIcon style={{ color: theme.palette.text.blue2, fontSize: "1.3rem" }} />
            </IconButton>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ width: "100%", borderRadius: "16px", boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)" }}>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" width="16%" className={classes.columnsHeading}>
                    Packages
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="16%" className={classes.columnsHeading}>
                    # of Packages
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="16%" className={classes.columnsHeading}>
                    Rev Goal 100%
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="16%" className={classes.columnsHeading}>
                    % of Clients
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="12%" className={classes.columnsHeading}>
                    Strategic Price
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="12%" className={classes.columnsHeading}>
                    Time Period
                  </StyledTableCell>
                  <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="12%" className={classes.columnsHeading}>
                    Revenue Map
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    className={classes.columnsHeading}
                    style={{
                      padding: "0.50rem",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    colSpan={7}
                  ></StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell color="#f9f9f9" className={classes.columnsHeading} colSpan={3}>
                    {calculateTernary({
                      condition: isDefaultFirmFinancials || !props.isCalculated,
                      trueValue: "Business Packages",
                      falseValue: ""
                    })}
                  </StyledTableCell>
                  <StyledTableCell color="#f9f9f9" align="center" className={[classes.columnsHeading, classes.largeText]}>
                    {getPerOfClientBusinessTotal(values)} %
                  </StyledTableCell>
                  <TernaryCheck
                    condition={isDefaultFirmFinancials}
                    trueComponent={
                      <>
                        <StyledTableCell color="#f9f9f9" align="center" className={classes.columnsHeading} colSpan={2}></StyledTableCell>
                        <StyledTableCell align="center" color="#f9f9f9" className={[classes.columnsHeading, classes.largeText]}>
                          {formatDollarValue(props.isCalculated, Math.round(props.businessRevenue))}
                        </StyledTableCell>
                      </>
                    }
                    falseComponent={
                      <>
                        <StyledTableCell
                          color="#f9f9f9"
                          align="right"
                          className={classes.columnsHeading}
                          colSpan={2}
                          style={{
                            color: theme.palette.text.blue,
                          }}
                        >
                          {props.isCalculated ? "Business Packages" : ""}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          color="#f9f9f9"
                          className={[classes.columnsHeading, classes.largeText]}
                          style={{
                            color: props.isCalculated ? theme.palette.text.blue2 : theme.palette.text.secondary,
                          }}
                        >
                          {formatDollarValue(props.isCalculated, Math.round(props.businessRevenue))}
                        </StyledTableCell>
                      </>
                    }
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {props.isCalculated
                  ? props.pricingData
                      .filter((item) => {
                        return item.package_type === "business";
                      })
                      .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <StyledTableCell component="th" className={classes.textForGoals} scope="row">
                              {row?.package_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {NF.format(row?.no_of_packages || 0)}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {NF.format(row?.rev_goal_percent || 0)}%
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {NF.format(row?.percent_of_client || 0)}%
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {formatDollarValue(props.isCalculated, Math.round(row?.strategic_price))}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {row?.time_period}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell textcolor={"#fff"} align="center">
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {formatDollarValue(props.isCalculated, Math.round(row?.revenue_map))}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                  : pricingData
                      .filter((item) => {
                        return item.package_type === "business";
                      })
                      .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <StyledTableCell component="th" className={classes.textForGoals} scope="row">
                              {row.package_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {row.no_of_packages}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.fontSizeS}>
                              <SmallTextField
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                className={classes.fontSizeS}
                                value={props[`${row.rev_goal_percent}`]}
                                name={row.rev_goal_percent}
                                onChange={(e) => checkIfNumberWithMax100Value(e, pricingData)}
                                variant="outlined"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.fontSizeS}>
                              <SmallTextField
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                className={classes.fontSizeS}
                                value={props[`${row.percent_of_client}`]}
                                name={row.percent_of_client}
                                onChange={checkIfFloat}
                                variant="outlined"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {formatDollarValue(props.isCalculated, Math.round(row?.strategic_price))}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {row?.time_period}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell textcolor={"#fff"} align="center">
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {formatDollarValue(props.isCalculated, Math.round(row?.revenue_map))}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                <TableRow>
                  <StyledTableCell color="#f9f9f9" className={[classes.columnsHeading, classes.noBorder]} colSpan={3}>
                    {calculateTernary({
                      condition: isDefaultFirmFinancials || !props.isCalculated,
                      trueValue: "Individual Packages",
                      falseValue: ""
                    })}
                  </StyledTableCell>
                  <StyledTableCell color="#f9f9f9" align="center" className={[classes.columnsHeading, classes.noBorder, classes.largeText]}>
                    {getPerOfClientIndividualTotal(values)} %
                  </StyledTableCell>
                  <TernaryCheck
                    condition={isDefaultFirmFinancials}
                    trueComponent={
                      <>
                        <StyledTableCell color="#f9f9f9" align="center" className={[classes.columnsHeading, classes.noBorder]} colSpan={2}></StyledTableCell>
                        <StyledTableCell align="center" color="#f9f9f9" className={[classes.columnsHeading, classes.noBorder, classes.largeText]}>
                          {formatDollarValue(props.isCalculated, Math.round(props.individualRevenue))}
                        </StyledTableCell>
                      </>
                    }
                    falseComponent={
                      <>
                        <StyledTableCell
                          color="#f9f9f9"
                          align="right"
                          className={[classes.columnsHeading, classes.noBorder]}
                          colSpan={2}
                          style={{
                            color: theme.palette.text.blue,
                          }}
                        >
                          {props.isCalculated ? "Individual Packages" : ""}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          color="#f9f9f9"
                          className={[classes.columnsHeading, classes.noBorder, classes.largeText]}
                          style={{
                            color: props.isCalculated ? theme.palette.text.blue2 : theme.palette.text.secondary,
                          }}
                        >
                          {formatDollarValue(props.isCalculated, Math.round(props.individualRevenue))}
                        </StyledTableCell>
                      </>
                    }
                  />
                </TableRow>
                {props.isCalculated
                  ? props.pricingData
                      .filter((item) => {
                        return item.package_type === "Individual";
                      })
                      .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <StyledTableCell component="th" className={classes.textForGoals} scope="row">
                              {row?.package_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {NF.format(row?.no_of_packages || 0)}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {NF.format(row?.rev_goal_percent || 0)}%
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {NF.format(row?.percent_of_client || 0)}%
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {`$ ${Math.round(+row?.strategic_price || 0).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {row?.time_period}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell textcolor={"#fff"} align="center">
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {`$ ${Math.round(+row?.revenue_map || 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                  : pricingData
                      .filter((item) => {
                        return item.package_type === "Individual";
                      })
                      .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <StyledTableCell component="th" className={classes.textForGoals} scope="row">
                              {row.package_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              {row.no_of_packages}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.fontSizeS}>
                              <SmallTextField
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                className={classes.fontSizeS}
                                value={props[`${row.rev_goal_percent}`]}
                                name={row.rev_goal_percent}
                                onChange={(e) => checkIfNumberWithMax100Value(e, pricingData)}
                                variant="outlined"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.fontSizeS}>
                              <SmallTextField
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                className={classes.fontSizeS}
                                value={props[`${row.percent_of_client}`]}
                                name={row.percent_of_client}
                                onChange={checkIfFloat}
                                variant="outlined"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {`$ ${Math.round(+row?.strategic_price || 0).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.textForGoals}>
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {row?.time_period}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell textcolor={"#fff"} align="center">
                              <Typography display="inline" className={classes.clientInfoPrice}>
                                {`$ ${Math.round(+row?.revenue_map || 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                <TableRow>
                  <StyledTableCell className={[classes.columnsHeading, classes.noBorder]} colSpan={2} color="#f9f9f9"></StyledTableCell>
                  <StyledTableCell color="#f9f9f9" align="center" className={[classes.clientInfoPrice, classes.noBorder]}>
                    <Typography className={[classes.clientInfoPrice, classes.largeText]}>{getRevenueGoalTotal(values)} %</Typography>
                  </StyledTableCell>
                  <StyledTableCell color="#f9f9f9" align="center" className={[classes.clientInfoPrice, classes.noBorder]}>
                    <Typography className={[classes.clientInfoPrice, classes.largeText]}>
                      {getPerOfClientIndividualTotal(values) + getPerOfClientBusinessTotal(values)} %
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell color="#f9f9f9" align="center" className={[classes.textForGoals, classes.noBorder]}></StyledTableCell>
                  <StyledTableCell color="#f9f9f9" align="center" className={[classes.textForGoals, classes.noBorder]} colSpan={2}></StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell
                    colSpan={7}
                    className={classes.textForGoals}
                    scope="row"
                    style={{
                      border: "none",
                      padding: "0.5rem 0",
                    }}
                  ></StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <BottomPortion classes={classes} props={props} setState={setState} permissions={permissions} isDefaultFirmFinancials={isDefaultFirmFinancials}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export class FirmFinancialsNew extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.props.getRoadMap();
    }
  }

  checkIfNumber = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    e.target.value = inputValue;
    const regex = /\d+(\.\d\d?)?/;
    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length < 12)) {
      let t = e.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      let nf = new Intl.NumberFormat("en-US");
      this.props.setState(
        {
          [e.target.name]: nf.format(t?.replace(/,/g, "")) === "0" ? "" : nf.format(t?.replace(/,/g, "")),
          isCalculated: false,
        },
        () => {
          if (this.props.individualClients === "" && this.props.businessClients === "") {
            this.props.setState({
              totalClients: "",
            });
          } else {
            this.props.setState({
              totalClients: +("" + this.props.businessClients)?.replace(/,/g, "") + +(this.props.individualClients + "")?.replace(/,/g, ""),
            });
          }
        }
      );
    }
  };

  checkIfFloat = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^\d.]+/g, ".");
    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount > 1 || inputValue.startsWith(".")) {
      inputValue = inputValue.slice(0, -1);
    }
    e.target.value = inputValue;
    const regex = /\d+(\.\d\d?)?/;
    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length < 12)) {
      let t = e.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      this.props.setState(
        {
          [e.target.name]: t,
          isCalculated: false,
        },
        () => {
          this.props.setState({
            totalClients: +("" + this.props.businessClients)?.replace(/,/g, "") + +(this.props.individualClients + "")?.replace(/,/g, ""),
          });
        }
      );
    }
  };

  checkIfNumberWithMax100Value = (e, pricingData) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^\d.]+/g, ".");
    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount > 1 || inputValue.startsWith(".")) {
      inputValue = inputValue.slice(0, -1);
    }
    e.target.value = inputValue;
    let revGoalInPercent = 0;
    pricingData.forEach((client) => {
      if (client.rev_goal_percent !== e.target.name) {
        revGoalInPercent += +this.props[`${client.rev_goal_percent}`];
      }
    });
    if (revGoalInPercent + +e.target.value > 100) {
      this.props.showToast({ message: "your total revenue goal should be less then equal to 100", type: "error" });
      return false;
    }
    const regex = /\d+(\.\d\d?)?/;

    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length < 12)) {
      let t = e.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;

      this.props.setState(
        {
          [e.target.name]: t,
          isCalculated: false,
        },
        () => {
          this.props.setState({
            totalClients: +("" + this.props.businessClients)?.replace(/,/g, "") + +(this.props.individualClients + "")?.replace(/,/g, ""),
          });
        }
      );
    }
  };

  onSubmitCalculated = (values) => {
    this.props.handleNext();
  };

  onSubmit = (values) => {
    const revGoalInPercent = pricingData.reduce((accu, client) => {
      return +this.props[`${client.rev_goal_percent}`] + accu;
    }, 0);

    if (revGoalInPercent < 100) {
      this.props.showToast({ message: "your total revenue goal should be 100", type: "error" });
      return false;
    }

    let tempCount = 0;

    values.expenseLaborsAttributes
      .filter((item) => item?._destroy === 0)
      .map((expenseItem) => {
        if (expenseItem.labor_role === "") {
          tempCount += 1;
        }
      });

    if (tempCount > 0) {
      this.props.showToast({ message: "Expense name can't be blank", type: "error" });
      return false;
    }

    this.props.setState(
      {
        formValues: values,
      },
      () => {
        this.props.createFirmFinancials(values);
      }
    );
  };

  getPerOfClientBusinessTotal = () => {
    const percentTotal = pricingData
      .filter((item) => item.package_type === "business")
      .reduce((accu, item) => {
        return +this.props[`${item.percent_of_client}`] + accu;
      }, 0);
    return Math.round(percentTotal);
  };

  getPerOfClientIndividualTotal = () => {
    const percentTotal = pricingData
      .filter((item) => item.package_type === "Individual")
      .reduce((accu, item) => {
        return +this.props[`${item.percent_of_client}`] + accu;
      }, 0);
    return Math.round(percentTotal);
  };

  getRevenueGoalTotal = () => {
    const percentTotal = pricingData.reduce((accu, item) => {
      return +this.props[item.rev_goal_percent] + accu;
    }, 0);
    return percentTotal;
  };

  expenseFieldOnChangeHandler = (e, setFieldValue) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    e.target.value = inputValue;

    let regex = /\d+(\.\d\d?)?/;

    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length < 12)) {
      let t = e.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      let nf = new Intl.NumberFormat("en-US");
      t ? setFieldValue(e.target.name, nf.format(t?.replace(/,/g, ""))) : setFieldValue(e.target.name, "");
      this.props.setState({
        isCalculated: false,
      });
    }
  };

  resetForm = (setFieldValue) => {
    this.props.setState({
      ...resetFormState,
      isCalculated: false,
    });
    setFieldValue("expenses", {
      operatingSpaceExpense: resetFormState.operatingSpaceExpense,
      technologyExpense: resetFormState.technologyExpense,
      bizdevMarketingExpense: resetFormState.bizdevMarketingExpense,
      insuranceExpense: resetFormState.insuranceExpense,
      othersExpense: resetFormState.othersExpense,
    });
    setFieldValue("expenseLaborsAttributes", resetFormState.expenseLaborsAttributes);
    setFieldValue("laborTotalExpense", resetFormState.laborTotalExpense);
    setFieldValue("totalExpenses", resetFormState.totalExpenses);
    setFieldValue("pricingData", resetFormState.pricingData);
  };

  render() {
    const { classes, theme } = this.props;
    const rowsforGoals = [
      {
        goalName: "Gross Revenue",
        currentAnnual: "currentAnnualGross",
        currentMonthly: Number(("" + this.props.currentAnnualGross)?.replace(/,/g, "") / 12).toFixed(2),
        goalAnnual: "goalAnnualGross",
        goalMonthly: Number(("" + this.props.goalAnnualGross)?.replace(/,/g, "") / 12).toFixed(2),
      },
      {
        goalName: "Owner's Compensation",
        currentAnnual: "currentAnnualOwner",
        currentMonthly: Number(("" + this.props.currentAnnualOwner)?.replace(/,/g, "") / 12).toFixed(2),
        goalAnnual: "goalAnnualOwner",
        goalMonthly: Number(("" + this.props.goalAnnualOwner)?.replace(/,/g, "") / 12).toFixed(2),
      },
    ];

    return (
      <Formik
        initialValues={{
          expenses: {
            operatingSpaceExpense: this.props.operatingSpaceExpense,
            technologyExpense: this.props.technologyExpense,
            bizdevMarketingExpense: this.props.bizdevMarketingExpense,
            insuranceExpense: this.props.insuranceExpense,
            othersExpense: this.props.othersExpense,
          },
          expenseLaborsAttributes: this.props.expenseLaborsAttributes,
          laborTotalExpense: this.props.laborTotalExpense,
          totalExpenses: this.props.totalExpenses,
          pricingData: this.props.pricingData,
        }}
        onSubmit={this.props.isCalculated ? this.onSubmitCalculated : this.onSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue } = formikProps;

          return (
            <Form autoComplete="off" noValidate>
              <Grid container direction="row" alignItems="center">
                <Grid container direction="row" justifyContent="space-between" item xs={12}>
                  <CustomTypography color="primary" fontSize="1.625rem" fontWeight={600}>
                    Firm Financials
                  </CustomTypography>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={this.resetForm.bind(this, setFieldValue)}
                    style={{
                      marginLeft: "auto",
                      marginRight: "1rem",
                    }}
                  >
                    Clear All
                  </Button>
                  <Button color="primary" variant="outlined" onClick={() => this.props.updateStep(2)}>
                    Skip to Package Overview
                  </Button>
                </Grid>

                <GoalsTable
                  classes={classes}
                  theme={theme}
                  props={this.props}
                  checkIfNumber={this.checkIfNumber}
                  rowsforGoals={rowsforGoals}
                />

                <ExpenseTable
                  classes={classes}
                  theme={theme}
                  props={this.props}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  setState={(values) => {
                    this.props.setState(values);
                  }}
                  expenseFieldOnChangeHandler={this.expenseFieldOnChangeHandler}
                />

                <ClientInfoTable classes={classes} theme={theme} props={this.props} checkIfNumber={this.checkIfNumber} />

                <PricingDataTable
                  classes={classes}
                  theme={theme}
                  props={this.props}
                  values={values}
                  checkIfNumberWithMax100Value={this.checkIfNumberWithMax100Value}
                  checkIfFloat={this.checkIfFloat}
                  getPerOfClientBusinessTotal={this.getPerOfClientBusinessTotal}
                  getRevenueGoalTotal={this.getRevenueGoalTotal}
                  getPerOfClientIndividualTotal={this.getPerOfClientIndividualTotal}
                  pricingData={pricingData}
                  setState={(values) => {
                    this.props.setState(values);
                  }}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const FirmFinancialsWithRouter = withRouter(FirmFinancialsNew);
const FirmFinancialsAlertBox = withAlertBox(FirmFinancialsWithRouter);
const FirmFinancialsLoader = withLoader(FirmFinancialsAlertBox);
const FirmFinancialsToast = withToast(FirmFinancialsLoader);
const FirmFinancialsWithDialog = withDialog(FirmFinancialsToast);

export const firmFinancialsStyles = (theme) => {
  return {
    input: {
      "&:invalid": {
        border: "red solid 2px",
      },
    },
    headingH2: {
      fontSize: "1.125rem",
      paddingLeft: 5,
      fontWeight: 600,
    },
    headingH22: {
      fontSize: "1.125rem",
      fontWeight: 600,
      color: "#282c2c",
      marginTop: theme.spacing(4),
    },
    pushingLeft: {
      paddingRight: 20,
      color: theme.palette.text.secondary,
    },
    root: {
      height: "calc(100vh - 70px)!important",
      overflowX: "hidden",
    },
    flexEnd: {
      textAlign: "end",
      [theme.breakpoints.down("xs")]: {
        width: "30%",
      },
    },
    fontSizeSS: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    packageFont: {
      fontSize: "1rem",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("md")]: {
        padding: "16px",
      },
    },
    blueText: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    blueTextTotal: {
      "& .MuiTypography-body1": {
        fontSize: "1.125rem!important",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
      },
    },
    fontSizeS: {
      fontSize: "0.875rem",
      "& input": {
        fontSize: "0.875rem",
      },
      color: theme.palette.text.secondary,
    },
    blueTextField: {
      fontSize: "0.875rem",
      "& input": {
        color: theme.palette.text.secondary,
        fontSize: "0.875rem",
      },
    },
    blueTextGoals: {
      fontSize: "0.875rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.9375rem",
      },
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    textForGoals: {
      fontSize: "0.9375rem",
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    blueBoldTextExpense: {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "0.9375rem",
      },
    },
    fontSizeTS: {
      fontSize: "0.75rem",
    },
    fontBold: {
      fontWeight: "bold",
    },
    numberField: {
      width: "50%",
    },
    numberFieldSmall: {
      width: "30%",
    },
    cardPaper: {
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(2, 0),
      },
      margin: theme.spacing(1, 0),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    card: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      height: theme.spacing(25),
      width: "96%",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "98%",
        margin: theme.spacing(2, 0),
      },
    },
    cardImage: {
      padding: theme.spacing(1, 1),
      width: "100%",
      textAlign: "center",
    },
    cardTitle: {
      fontFamily: "Lato",
      fontSize: "1.25rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
    },
    buttonFont: {
      fontSize: 18,
    },
    goalcardHeading: {
      fontFamily: "Lato",
      fontSize: "1.625rem",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: theme.palette.text.blue2,
    },
    noBorderInputField: {
      border: "none",
      "&:active": {
        border: "none",
      },
    },
    columnsHeading: {
      fontFamily: "Lato",
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      fontWeight: "bold",
    },
    columnsHeadingForClientInfo: {
      fontFamily: "Lato",
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    columnsBodyForClientInfo: {
      fontSize: "0.875rem",
    },
    columnsHeadingForGoal: {
      fontFamily: "Lato",
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      fontWeight: "bold",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
    mainContainer: {
      padding: theme.spacing(6.2),
    },
    clientInfoPrice: {
      color: theme.palette.text.secondary,
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    revenueTotalLarge: {
      color: theme.palette.text.blue2,
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    clientInfoUnitOrMonth: {
      color: theme.palette.text.secondary,
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    primaryGoalName: {
      fontSize: "0.9375rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
    },
    boxOfArr: {
      backgroundColor: "#f4f4f4",
      color: "white",
      padding: "0.5rem",
      borderRadius: "0.5rem",
    },
    calculatedBoxOfArr: {
      backgroundColor: theme.palette.text.blue2,
      color: "white",
      padding: "0.5rem",
      borderRadius: "0.5rem",
    },
    arrMrrText: {
      display: "inline-block",
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    },
    calculatedArrText: {
      display: "inline-block",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#fff",
    },
    calculatedMrrText: {
      display: "inline-block",
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
    totalRevenueText: {
      display: "inline-block",
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    clientInfoSectionHeaders: {
      padding: "1rem",
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: theme.palette.text.primary,
      border: "none",
    },
    noBorder: {
      border: "none",
    },
    largeText: {
      fontSize: "1.1rem",
      fontWeight: "bold",
    },
    blueBoxGoals: {
      backgroundColor: "#FECA5A",
      color: "#fff",
      borderRadius: "10px",
      margin: "0.25rem 0.5rem",
    },
    threeSmallCardsHeading: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: "1rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8125rem",
      },
    },
    threeSmallCardsPrice: {
      color: theme.palette.text.primary,
      fontSize: "1rem",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.9375rem",
      },
    },
    blueBoxGoals_Default: {
      backgroundColor: theme.palette.text.blue2,
      marginTop: "1.5rem",
      color: "#fff",
      borderRadius: "10px",
    },
    threeSmallCardsHeading_Default: {
      color: "#fff",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginTop: "2rem",
      marginBottom: "1rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8125rem",
      },
    },
    threeSmallCardsPrice_Default: {
      color: "#fff",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.9375rem",
      },
    },
  };
};

export default withStyles(
  (theme) =>
    createStyles({
      ...firmFinancialsStyles(theme)
    }),
  { withTheme: true }
)(withConfirmBox(FirmFinancialsWithDialog));
