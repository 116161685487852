//@ts-nocheck
import React from "react";
import StartRoadMapController, { Props } from "./StartRoadMapController.web";
import {
  withStyles,
  Grid,
  createStyles,
  Box,
  makeStyles,
  Divider,
  Button,
  StepContent,
  Hidden,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Prompt } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import clsx from "clsx";
import {
  packageSelect,
  accountingSuccess,
  businessBundle,
  businessTaxAdvisory,
  irsRapidRelief,
  newBusinessIncubator,
  payrollSuccess,
  personalBundle,
  reportingOnly,
} from "../assets";
import { Redirect, withRouter } from "react-router";

import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import ClientInfo from "./ClientInfo.web";
import PackageList from "./PackageList.web";
import RoadMapCustomization2Web from "./RoadMapCustomization2.web";
import RoadMapWeb from "./RoadMap.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import StartRoadmapDeleteDialog from "./StartRoadmapDeleteDialog.web";
import withDialogBox from "../../../../components/src/withDialog.web";
import FirmFinancialsNewWeb from "../FirmFinancialsNew.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import CustomPackageCreationLandingPage from "../CustomPackage/CustomPackageCreationLandingPage.web.tsx";
import CustomPackageCreationSelectFeatures from "../CustomPackage/CustomPackageCreationSelectFeaturesPage.web";
import CustomPackageCreationPricing from "../CustomPackage/CustomPackageCreationPricing.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import TernaryCheck from "../TernaryCheck.web";
import TakeQuizWeb from "./TakeQuiz.web";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const steps = [
  "Firm Financials",
  "Client Information",
  "Package Overview",
  "Package Customization",
  "Roadmap Customization",
  "Roadmap",
];
let checkSteps = 0;
const drawerWidth = 240;
const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 48,
    left: "calc(-50% + -40px)",
    right: "calc(50% + -40px)",
    display: "none",
  },
  active: {
    "& $line": {
      borderColor: "#54A4A6",
    },
    left: "calc(-50% + -5px)",
    right: "calc(50% + -5px)",
  },
  completed: {
    "& $line": {
      borderColor: "#54A4A6",
    },
  },
  line: {
    borderColor: "#54A4A6",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const ColorStepLabel = withStyles((theme) => ({
  active: {
    color: "#54A4A6!important",
    fontWeight: "bold!important",
    paddingBottom: "1rem",
    textTransform: "capitalize",
    fontSize: "1.2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
  completed: {
    color: `${theme.palette.primary.light}!important`,
    fontWeight: "bold!important",
    paddingBottom: "1rem",
    borderBottom: "0.3125rem solid #54A4A6!important",
    textTransform: "capitalize",
    fontSize: "1.2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
  label: {
    cursor: "pointer",
    fontSize: "1.2rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
    color: "#54A4A6",
    paddingBottom: "1rem",
    borderBottom: "0.3125rem solid #54A4A6",
    textTransform: "capitalize",
    width: "100%",
    transition: "all 200ms ease",
  },
}))(StepLabel);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    zIndex: 1,
    width: "1.25rem",
    height: "1.25rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAF0FC",
    position: "absolute",
    bottom: "-5px",
    right: "calc(50% - 0.5rem)",
    border: "1px solid #54A4A6",
    [theme.breakpoints.down("md")]: {
      bottom: "-5px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-7px",
    },
  },
  active: {
    border: "1px solid #fff",
    backgroundColor: "#60CA85",
    width: "1.25rem",
    height: "1.25rem",
    right: "calc(50% - 0.625rem)",
    bottom: "-5px",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      bottom: "-5px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-7px",
    },
  },
  completed: {
    backgroundColor: "#60CA85",
    border: "1px solid #fff",
    display: "flex",
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    bottom: "-5px",
    color: "#fff",
    right: "calc(50% - 0.5rem)",
    [theme.breakpoints.down("md")]: {
      bottom: "-5px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-7px",
    },
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {(completed || active) && (
        <Check color="#fff" style={{ fontSize: "0.75rem" }} />
      )}
    </div>
  );
}

const ColorStep = withStyles((theme) => {
  return {
    horizontal: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  };
})(Step);

export class StartRoadMap extends StartRoadMapController {
  constructor(props: Props) {
    super(props);
  }
  unloadCallback = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };
  componentWillUnmount(): Promise<void> {
    if (!this.state.isRoadMapSaved && !this.state.editingRoadmap) {
      this.deleteRoadMap(this.state?.roadMapInfo?.id);
    }
    window.removeEventListener("beforeunload", this.unloadCallback);
  }
  async componentDidMount() {
    window.addEventListener("beforeunload", this.unloadCallback);
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    const { permissions } = this.props;
    if (!permissions.includes("View/Edit Firm Financials-Firm Financials")) {
      this.setState({
        activeStep: 1,
        mobileOpen: false,
        accountId: accountId,
      });
    }
    if (token) {
      this.setState(
        {
          token: token,
          roadMapInfo: this.props.history.location.state?.roadmap,
          loading: false,
          editingRoadmap: this.props.history.location.state.editingRoadmap,
          accountId: accountId,
        },
        async () => {
          this.state.roadMapInfo && this.getRoadMap(this.state.roadMapInfo.id);
          await StorageProvider.remove("roadmap");
          await StorageProvider.set(
            "roadmap",
            JSON.stringify(this.state.roadmap)
          );
        }
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.history.location.state?.roadmap?.id !=
      prevState?.roadMapInfo?.id
    ) {
      const token = await StorageProvider.get("authToken");
      if (token) {
        this.setState(
          {
            token: token,
            roadMapInfo: this.props.history.location.state?.roadmap,
            loading: false,
            editingRoadmap: this.props.history.location.state.editingRoadmap,
          },
          async () => {
            this.state.roadMapInfo &&
              this.getRoadMap(this.state.roadMapInfo.id);
            await StorageProvider.remove("roadmap");
            await StorageProvider.set(
              "roadmap",
              JSON.stringify(this.state.roadmap)
            );
          }
        );
      }
    }
  }

  openTakeQuiz = (values) => {
    this.props
      .openDialogBox({
        dataToPass: {
          values: this.state,
        },
        width: "45%",
        renderedComponent: TakeQuizWeb,
        withCustomDialog: false,
      })
      .then(this.preSubmitQuiz.bind(this, values));
  };
  preSubmitQuiz = (values, data: any) => {
    this.setState(
      {
        q1: data?.q1,
        q2: data?.q2,
        q3: data?.q3,
        q4: data?.q4,
        q5: data?.q5,
        q6: data?.q6,
      },
      () => {
        this.submitQuiz(values);
      }
    );
  };

  getQuizText = () => {
    if (
      this.state.q1?.value ||
      this.state.q2?.value ||
      this.state.q3?.value ||
      this.state.q4?.value ||
      this.state.q5?.value ||
      this.state.q6?.value
    ) {
      return "Quiz Saved";
    }
    return "Take Quiz";
  };

  openDeleteDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          // roadmapId: data,
        },
        width: "40%",
        renderedComponent: StartRoadmapDeleteDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.deleteRoadMap(this.state.roadMapInfo.id);
        }
      });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
      isSidebarVisible: true,
      isEditRoadMapPackageTemplate: false,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      isSidebarVisible: true,
      isEditRoadMapPackageTemplate: false,
    });
  };

  updateStep = (step) => {
    this.resetPakcageCustomizationStep();
    const { permissions } = this.props;
    if (
      step === 0 &&
      !permissions.includes("View/Edit Firm Financials-Firm Financials")
    ) {
      this.props.showToast({
        type: "error",
        message: "AccessDenied for Current User.",
      });
      this.setState({
        activeStep: 1,
        mobileOpen: false,
        isEditRoadMapPackageTemplate: false,
      });
    } else if (
      (step === 3 || step === 4 || step === 5) &&
      this.state.selectedPaymentTerms.length === 0
    ) {
      this.props.showToast({
        type: "error",
        message:
          "Please select a package with atleast 1 payment term to proceed further.",
      });
      this.setState({
        activeStep: 2,
        mobileOpen: false,
        isEditRoadMapPackageTemplate: false,
      });
    } else {
      if (step != 3) {
        this.setState({
          activeStep: step,
          mobileOpen: false,
          isEditRoadMapPackageTemplate: false,
        });
      }
    }
  };

  setSelectedPackageList = (pkg: any) => {
    if (this.state.selectedPaymentTerms.length < 3) {
      this.setState({
        activeStep: 3,
        isCustomPackageTemplate:
          pkg?.type == "custom_package_management" ? true : false,
        defaultPackageTemplate: pkg?.id,
        isCreateCustomPackage: false,
        currentPackageCustomizationIndex: 1,
        packageType: pkg?.type,
      });
    } else {
      this.setState({
        activeStep: 4,
      });
    }
  };
  moveToRoadCustomizationPage = () => {
    this.setState({
      activeStep: 4,
    });
  };
  setUpdatedSelectedPackageList = async (packages) => {
    this.setState({
      selectedPackageList: packages,
    });
  };
  selectedPackage = async (pkg, index) => {
    this.setState({
      selectPackageIndex: index,
      selectPackage: pkg,
    });
  };
  selectedCustomPackage = async (pkg, index) => {
    this.setState({
      selectPackageIndex: index,
      selectPackage: pkg,
    });
  };

  goToNextPackageCustomizationStep = (
    values?: any,
    totalContractValue?: number,
    totalContractValueAfterRemovingUnfrontAmount?: number
  ) => {
    if (this.state.currentPackageCustomizationIndex == 0) {
      this.setState({
        currentPackageCustomizationIndex: 1,
      });
    } else if (this.state.currentPackageCustomizationIndex == 1) {
      this.createCustomPackageInRoadMap(
        values,
        totalContractValue,
        totalContractValueAfterRemovingUnfrontAmount
      );
    }
  };
  setFeaturePrice = (values: any) => {
    let totalContractValue = this.getTotalContractValue(values);
    let totalContractValueAfterRemovingUnfrontAmount = this.removeUnfrontValue(
      values,
      totalContractValue
    );

    this.setState(
      {
        description: values.description,
        name: values.name,
        contract_period: values.contract_period,
        unfront_lump_suump_amount: values.unfront_lump_suump_amount,
        totalContractValue: totalContractValue,
        stratagic_rate: values.stratagic_rate,
        is_unfront_payment: values.is_unfront_payment,
        unfront_percentage: values.unfront_percentage,
        package_detail_link: values.package_detail_link,
        totalContractValueAfterRemovingUnfrontAmount: totalContractValueAfterRemovingUnfrontAmount,
        value_margin: values.value_margin,
        is_monthly_payment_term: values.is_monthly_payment_term,
        is_yearly_payment_term: values.is_yearly_payment_term,
        is_quarterly_payment_term: values.is_quarterly_payment_term,
        unfront_payment_type: values.unfront_payment_type,
        custom_package_features: values.custom_package_features,
      },
      () => {
        if (this.state.isCreateCustomPackage) {
          this.createCustomPackageInRoadMap(
            values,
            totalContractValue,
            totalContractValueAfterRemovingUnfrontAmount
          );
        } else {
          this.goToNextPackageCustomizationStep(
            values,
            totalContractValue,
            totalContractValueAfterRemovingUnfrontAmount
          );
        }
      }
    );
  };

  goToPreviousStepWithCustomPackage = () => {
    this.resetPakcageCustomizationStep();
    this.handleBack();
  };
  goToNextStepWithCustomPackage = () => {
    this.setState({
      currentPackageCustomizationIndex: 1,
    });
  };
  goToNextStep = () => {
    this.setState({
      currentPackageCustomizationIndex: 2,
    });
  };

  createPackageFlow = () => {
    if (this.state.currentPackageCustomizationIndex == 0) {
      return (
        <CustomPackageCreationLandingPage
          isFromRoadMap={true}
          goToPreviousStep={this.goToPreviousStepWithCustomPackage.bind(this)}
          goToNextStep={this.goToNextStepWithCustomPackage.bind(this)}
          packageList={this.state.packageListForDropDown}
          getPackages={this.getPackages.bind(this)}
          handleCreatedPackageType={this.handleCreatedPackageType.bind(this)}
          isCreateFromDefaultTemplate={this.state.isCreateFromDefaultTemplate}
          defaultPackageTemplate={this.state.defaultPackageTemplate}
          setDefaultPackageTemplate={this.setDefaultPackageTemplateINRoadmap.bind(
            this
          )}
        />
      );
    } else if (this.state.currentPackageCustomizationIndex == 1) {
      return (
        <CustomPackageCreationSelectFeatures
        goToPreviousStep={() => {
          this.setState({
            currentPackageCustomizationIndex: 0,
          });
        }}
        onFeatureDragEnd={this.onFeatureDragEnd.bind(this)}
        goToNextStep={this.goToNextStep}
        moveToNextStep={this.moveToNextStep.bind(this)}
        isFromRoadMap={true}
        isCustomPackageTemplate={false}
        getAllFeaturesofSelectedCustomPackage={this.getAllFeaturesofSelectedCustomPackage.bind(
            this
          )}
          getAllFeaturesofSelectedPackage={this.getAllFeaturesofSelectedPackage.bind(
            this
            )}
            getAllFeatures={this.getAllFeatures.bind(this)}
          searchFeature={this.searchFeature.bind(this)}
          selectedPackageDetail={this.state.selectedPackageDetail}
          setAllFeatureList={this.setAllFeatureList.bind(this)}
          setSelectedFeatureList={this.setSelectedFeatureList.bind(this)}
          previousQuestion={this.previousQuestion.bind(this)}
          nextQuestion={this.nextQuestion.bind(this)}
          handleQuestionAnswerChange={this.handleQuestionAnswerChange.bind(
            this
          )}
          defaultPackageTemplate={this.state.defaultPackageTemplate}
          removeSelectedFeatures={this.removeSelectedFeatures.bind(this)}
          setSelectedFeatureAndRemoveFromAllFilterList={this.setSelectedFeatureAndRemoveFromAllFilterList.bind(
            this
          )}
          filterQuestionList={this.state.filterQuestionList}
          currentQuestionIndex={this.state.currentQuestionIndex}
          selectedFeatureList={this.state.selectedFeatureList}
          allFeatureList={this.state.allFeatureList}
          handleFilterDelete={this.handleFilterDelete.bind(this)}
          chipData={this.state.chipData}
          totalRecurringFess={this.state.totalRecurringFess}
          totalAvailalbeFeatures={this.state.totalAvailalbeFeatures}
          removeFeatureFromSelectedFeatureListAndAddtoAllFilterList={this.removeFeatureFromSelectedFeatureListAndAddtoAllFilterList.bind(
            this
          )}
          selectedCategory={this.state.selectedCategory}
          handleCategoryChange={this.handleCategoryChange.bind(this)}
          totalOneTimeFess={this.state.totalOneTimeFess}
          selectedSearchField={this.state.selectedSearchField}
          cancelSearch={this.cancelSearch.bind(this)}
          name={this.state.name}
          handleNameChange={this.handleNameChange.bind(this)}
          custom_package_features={this.state.custom_package_features}
          handleChipWhenFilterApplied={this.handleChipWhenFilterApplied.bind(
            this
          )}
        />
      );
    } else if (this.state.currentPackageCustomizationIndex == 2) {
      return (
        <CustomPackageCreationPricing
          getFeaturesForAutoComplete={this.getFeaturesForAutoComplete.bind(
            this
          )}
          sortFeature={this.sortFeature.bind(this)}
          handleNewFeature={this.handleNewFeature.bind(this)}
          getOneTimeFess={this.getOneTimeFess.bind(this)}
          getRecurringFess={this.getRecurringFess.bind(this)}
          getBumpPrice={this.getBumpPrice.bind(this)}
          isCustomPackageTemplate={false}
          allFeatureListForPricingScreen={
            this.state.allFeatureListForPricingScreen
          }
          getAllFeaturesForPriceingScreen={this.getAllFeaturesForPriceingScreen.bind(
            this
          )}
          isFromRoadMap={true}
          goToPreviousStep={() => {
            this.setState({
              currentPackageCustomizationIndex: 1,
            });
          }}
          isCreateCustomPackage={this.state.isCreateCustomPackage}
          goToNextStep={(
            values?: any,
            totalContractValue?: number,
            totalContractValueAfterRemovingUnfrontAmount?: number
          ) => {
            this.createCustomPackageInRoadMap(
              values,
              totalContractValue,
              totalContractValueAfterRemovingUnfrontAmount
            );
          }}
          description={this.state.description}
          name={this.state.name}
          package_detail_link={this.state.package_detail_link}
          selectedFeatureList={this.state.selectedFeatureList}
          contract_period={this.state.contract_period}
          is_monthly_payment_term={this.state.is_monthly_payment_term}
          allFeatureList={this.state.allFeatureList}
          getBumps={this.getBumps.bind(this)}
          unfront_percentage={this.state.unfront_percentage}
          stratagic_rate={this.state.stratagic_rate}
          value_margin={this.state.value_margin}
          is_yearly_payment_term={this.state.is_yearly_payment_term}
          bumpList={this.state.bumpList}
          custom_package_features={this.state.custom_package_features}
          defaultPackageTemplate={this.state.defaultPackageTemplate}
          unfront_lump_suump_amount={this.state.unfront_lump_suump_amount}
          getAllFeaturesofSelectedCustomPackage={this.getAllFeaturesofSelectedCustomPackage.bind(
            this
          )}
          getAllFeaturesofSelectedPackage={this.getAllFeaturesofSelectedPackage.bind(
            this
          )}
          setFeaturePrice={this.setFeaturePrice.bind(this)}
          is_unfront_payment={this.state.is_unfront_payment}
          unfront_payment_type={this.state.unfront_payment_type}
          is_quarterly_payment_term={this.state.is_quarterly_payment_term}
          getAllFeatures={this.getAllFeatures.bind(this)}
          deleteSelectedPackage={(feature: any) => {
            this.removeSelectedFeatures({
              ...feature,
              id: feature?.package_feature_id,
            });
          }}
          updateSelectedFeatureList={(feat: any) => {
            const selectedFeatureList = [this.state.selectedFeatureList];
            const ddd = selectedFeatureList.find((dd) => {
              return dd.id == feat.id;
            });
            if (!ddd) {
              selectedFeatureList.push(ddd);
            }
          }}
          setSelectedFeatureAndRemoveFromAllFilterList={this.setSelectedFeatureAndRemoveFromAllFilterList.bind(
            this
          )}
          handleNumberChangeForFeatures={this.handleNumberChangeForFeatures.bind(
            this
          )}
          handlePercent={this.handlePercent.bind(this)}
          handleUpFrontValue={this.handleUpFrontValue.bind(this)}
          deleteFeature={this.deleteFeature.bind(this)}
          getCurrentIndex={this.getCurrentIndex.bind(this)}
          handleNumberChangeForFeatureBump={this.handleNumberChangeForFeatureBump.bind(
            this
          )}
          handleNumberChange={this.handleNumberChange.bind(this)}
          handleStratagicRateChange={this.handleStratagicRateChange.bind(this)}
          onFeatureDragEndForPricing={this.onFeatureDragEndForPricing.bind(
            this
          )}
        />
      );
    }
  };
  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FirmFinancialsNewWeb
            updateStep={this.updateStep}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            roadMap={this.state.roadMapInfo}
            isCalculated={this.state.isCalculated}
            currentAnnualRev={this.state.currentAnnualRev}
            annualRevGoal={this.state.annualRevGoal}
            businessClients={this.state.businessClients}
            individualClients={this.state.individualClients}
            totalClients={this.state.totalClients}
            firmFinanceId={this.state.firmFinanceId}
            expandLabors={this.state.expandLabors}
            businessRevenue={this.state.businessRevenue}
            individualRevenue={this.state.individualRevenue}
            totalRevenue={this.state.totalRevenue}
            totalArr={this.state.totalArr}
            totalMrr={this.state.totalMrr}
            operatingSpaceExpense={this.state.operatingSpaceExpense}
            technologyExpense={this.state.technologyExpense}
            bizdevMarketingExpense={this.state.bizdevMarketingExpense}
            insuranceExpense={this.state.insuranceExpense}
            othersExpense={this.state.othersExpense}
            laborTotalExpense={this.state.laborTotalExpense}
            totalExpenses={this.state.totalExpenses}
            expenseLaborsAttributes={this.state.expenseLaborsAttributes}
            pricingData={this.state.pricingData}
            pricingIdObject={this.state.pricingIdObject}
            formValues={this.state.formValues}
            currentAnnualGross={this.state.currentAnnualGross}
            currentMonthlyGross={this.state.currentMonthlyGross}
            goalAnnualGross={this.state.goalAnnualGross}
            goalMonthlyGross={this.state.goalMonthlyGross}
            currentAnnualOwner={this.state.currentAnnualOwner}
            currentMonthlyOwner={this.state.currentMonthlyOwner}
            goalAnnualOwner={this.state.goalAnnualOwner}
            goalMonthlyOwner={this.state.goalMonthlyOwner}
            percentOfClientsNewBusinessIncubator={
              this.state.percentOfClientsNewBusinessIncubator
            }
            revGoalNewBusinessIncubator={this.state.revGoalNewBusinessIncubator}
            percentOfClientsBusinessBundle={
              this.state.percentOfClientsBusinessBundle
            }
            revGoalBusinessBundle={this.state.revGoalBusinessBundle}
            percentOfClientsBusinessTax={this.state.percentOfClientsBusinessTax}
            revGoalBusinessTax={this.state.revGoalBusinessTax}
            percentOfClientsAccountingSuccess={
              this.state.percentOfClientsAccountingSuccess
            }
            revGoalAccountingSuccess={this.state.revGoalAccountingSuccess}
            percentOfClientsPayrollSuccess={
              this.state.percentOfClientsPayrollSuccess
            }
            revGoalPayrollSuccess={this.state.revGoalPayrollSuccess}
            percentOfClientsPersonalBundle={
              this.state.percentOfClientsPersonalBundle
            }
            revGoalPersonalBundle={this.state.revGoalPersonalBundle}
            percentOfClientsIRSRapidRelief={
              this.state.percentOfClientsIRSRapidRelief
            }
            revGoalIRSRapidRelief={this.state.revGoalIRSRapidRelief}
            percentOfClientsReportingOnlyBusiness={
              this.state.percentOfClientsReportingOnlyBusiness
            }
            revGoalReportingOnlyBusiness={
              this.state.revGoalReportingOnlyBusiness
            }
            percentOfClientsReportingOnlyIndividual={
              this.state.percentOfClientsReportingOnlyIndividual
            }
            revGoalReportingOnlyIndividual={
              this.state.revGoalReportingOnlyIndividual
            }
            calculationsList={this.state.calculationsList}
            rowForCllient={this.state.rowForCllient}
            getRoadMap={this.getRoadMap}
            createFirmFinancials={this.createFirmFinancials}
            getDefaultFirmFinancialsByAccount={
              this.getDefaultFirmFinancialsByAccount
            }
            setState={(data, afterFunc) => {
              this.setState(data, () => {
                afterFunc && afterFunc();
              });
            }}
          />
        );
      case 1:
        return (
          <ClientInfo
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            roadMap={this.state.roadMapInfo}
            getRoadMap={this.getRoadMap}
            clientInformation={this.state.clientInformation}
            createAndUpdateClientInformation={
              this.createAndUpdateClientInformation
            }
            isClientAligned={this.state.isClientAligned}
            scheduleDateTime={this.state.scheduleDateTime}
            openTakeQuiz={this.openTakeQuiz}
            getQuizText={this.getQuizText}
            setState={(data) => {
              this.setState(data);
            }}
            searchClient={this.searchClient}
            searchedClientsList={this.state.searchedClientsList}
          />
        );
      case 2:
        return (
          <PackageList
            setSelectedPackageList={this.setSelectedPackageList}
            selectedPackageList={this.state?.selectedPackageList}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            roadMap={this.state?.roadMapInfo}
            getRoadMap={this.getRoadMap}
            selectedPackage={this.selectedPackage}
            selectedPaymentTerms={this.state.selectedPaymentTerms}
            moveToRoadCustomizationPage={this.moveToRoadCustomizationPage.bind(
              this
            )}
            createCustomPackage={this.createCustomPackage.bind(this)}
            openTakeQuiz={this.openTakeQuiz}
            getQuizText={this.getQuizText}
            recommendedPackages={this.state.recommendedPackages}
            selectedCurrentPackage={this.state.selectedCurrentPackage}
            setState={(data, afterFunc) => {
              this.setState(data, () => {
                afterFunc && afterFunc();
              });
            }}
            packageList={this.state.packageList}
            customPackages={this.state.customPackages}
            selectedCustomPaclage={this.state.selectedCustomPaclage}
            isErrorPresent={this.state.isErrorPresent}
            getPackages={this.getPackages}
            deletePackagefromRoadmap={this.deletePackagefromRoadmap}
          />
        );
      case 3:
        if (this.state.isCreateCustomPackage) {
          return this.createPackageFlow();
        } else {
          if (this.state.currentPackageCustomizationIndex == 1) {
            return (
              <CustomPackageCreationPricing
                sortFeature={this.sortFeature.bind(this)}
                handleNumberChange={this.handleNumberChange.bind(this)}
                handlePercent={this.handlePercent.bind(this)}
                handleUpFrontValue={this.handleUpFrontValue.bind(this)}
                deleteFeature={this.deleteFeature.bind(this)}
                handleStratagicRateChange={this.handleStratagicRateChange.bind(
                  this
                )}
                onFeatureDragEndForPricing={this.onFeatureDragEndForPricing.bind(
                  this
                )}
                handleNumberChangeForFeatures={this.handleNumberChangeForFeatures.bind(
                  this
                )}
                handleNumberChangeForFeatureBump={this.handleNumberChangeForFeatureBump.bind(
                  this
                )}
                getFeaturesForAutoComplete={this.getFeaturesForAutoComplete.bind(
                  this
                )}
                getCurrentIndex={this.getCurrentIndex.bind(this)}
                handleNewFeature={this.handleNewFeature.bind(this)}
                getOneTimeFess={this.getOneTimeFess.bind(this)}
                getRecurringFess={this.getRecurringFess.bind(this)}
                getBumpPrice={this.getBumpPrice.bind(this)}
                isCustomPackageTemplate={this.state.isCustomPackageTemplate}
                allFeatureListForPricingScreen={
                  this.state.allFeatureListForPricingScreen
                }
                packageType={this.state.packageType}
                getAllFeaturesForPriceingScreen={this.getAllFeaturesForPriceingScreen.bind(
                  this
                )}
                isFromRoadMap={true}
                goToPreviousStep={this.goToPreviousStep.bind(this)}
                goToNextStep={this.goToNextPackageCustomizationStep.bind(this)}
                package_detail_link={this.state.package_detail_link}
                setFeaturePrice={this.setFeaturePrice.bind(this)}
                selectedFeatureList={this.state.selectedFeatureList}
                name={this.state.name}
                value_margin={this.state.value_margin}
                getBumps={this.getBumps.bind(this)}
                bumpList={this.state.bumpList}
                is_unfront_payment={this.state.is_unfront_payment}
                description={this.state.description}
                unfront_percentage={this.state.unfront_percentage}
                is_monthly_payment_term={this.state.is_monthly_payment_term}
                allFeatureList={this.state.allFeatureList}
                stratagic_rate={this.state.stratagic_rate}
                custom_package_features={this.state.custom_package_features}
                defaultPackageTemplate={this.state.defaultPackageTemplate}
                contract_period={this.state.contract_period}
                unfront_payment_type={this.state.unfront_payment_type}
                unfront_lump_suump_amount={this.state.unfront_lump_suump_amount}
                is_quarterly_payment_term={this.state.is_quarterly_payment_term}
                is_yearly_payment_term={this.state.is_yearly_payment_term}
                deleteSelectedPackage={(feature: any) => {
                  this.removeSelectedFeatures({
                    ...feature,
                    id: feature?.package_feature_id,
                  });
                }}
                getAllFeaturesofSelectedPackage={this.getAllFeaturesofSelectedPackage.bind(
                  this
                )}
                getAllFeaturesofSelectedCustomPackage={this.getAllFeaturesofSelectedCustomPackage.bind(
                  this
                )}
                updateSelectedFeatureList={(feat: any) => {
                  const selectedFeatureList = [this.state.selectedFeatureList];
                  const ddd = selectedFeatureList.find((dd) => {
                    return dd.id == feat.id;
                  });
                  if (!ddd) {
                    selectedFeatureList.push(ddd);
                  }
                }}
                getAllFeatures={this.getAllFeatures.bind(this)}
                default_firm_finance_strategic_price={
                  this.state.default_firm_finance_strategic_price
                }
                default_strategic_price={this.state.default_strategic_price}
                setSelectedFeatureAndRemoveFromAllFilterList={this.setSelectedFeatureAndRemoveFromAllFilterList.bind(
                  this
                )}
              />
            );
          }
        }

        break;
      case 4:
        return (
          <RoadMapCustomization2Web
            selectedPackageList={this.state?.selectedPackageList}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            roadMap={this.state?.roadMapInfo}
            getRoadMap={this.getRoadMap}
            logoAsFile={this.state.logoAsFile}
            logo={this.state.logo}
            email={this.state.email}
            firm_name={this.state.firm_name}
            phone_number={this.state.phone_number}
            primaryColor={this.state.primaryColor}
            secondaryColor={this.state.secondaryColor}
            web_url={this.state.web_url}
            brandingId={this.state.brandingId}
            updateRoadMap={this.updateRoadMap}
            getBranding={this.getBranding}
            createAndUpdateBranding={this.createAndUpdateBranding}
            handleLogoImage={this.handleLogoImage}
            removeLogo={this.removeLogo}
            handlePrimaryColorChange={this.handlePrimaryColorChange}
            handleSecondaryColorChange={this.handleSecondaryColorChange}
            handleColorInputChange={this.handleColorInputChange}
            showCorrectImage={this.showCorrectImage}
            confidential_statement={this.state.confidential_statement}
          />
        );
      case 5:
        return (
          <RoadMapWeb
            isRoadMapSaved={this.state?.isRoadMapSaved}
            setRoamMapComplete={this.setRoamMapComplete}
            handleNext={this.handleNext}
            roadmap={this.state?.roadMapInfo}
            handleBack={this.handleBack}
            isSidebarVisible={this.state?.isSidebarVisible}
            getRoadMap={this.getRoadMap}
            selectedPaymentTerms={this.state.selectedPaymentTerms}
            selectedPaymentTermsLength={this.state.selectedPaymentTerms.length}
            roadMapName={this.state.roadMapName}
            setState={(data, afterFunc) => {
              this.setState(data, () => {
                afterFunc && afterFunc();
              });
            }}
            updateRoadmapWithName={this.updateRoadmapWithName}
            deleteRoadMap={this.deleteRoadMap}
            logo={this.state.logo}
            getGSuiteData={this.getGSuiteData.bind(this)}
            googleObj={this.state.googleObj}
            roadmap_client_link={this.state.roadmap_client_link}
            client_id={this.state.roadMapInfo?.attributes?.client_information?.data?.id}
          />
        );
      default: {
        return `this is ${steps[step]} content`;
      }
    }
  };

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  };

  getPackageImage = (pkg) => {
    switch (pkg.attributes.name) {
      case "New Business Incubator": {
        return newBusinessIncubator;
      }
      case "Business Bundle": {
        return businessBundle;
      }
      case "Business Tax & Advisory": {
        return businessTaxAdvisory;
      }
      case "Accounting Success Package": {
        return accountingSuccess;
      }
      case "Payroll Success Package": {
        return payrollSuccess;
      }
      case "Personal Bundle": {
        return personalBundle;
      }
      case "IRS Rapid Relief Package": {
        return irsRapidRelief;
      }
      case "Reporting-Only Package (Individual)": {
        return reportingOnly;
      }
      case "Reporting-Only Package (Business)": {
        return reportingOnly;
      }
      default: {
        return packageSelect;
      }
    }
  };

  goBackToHome = () => {
    if (this.props?.history?.location?.state?.editingRoadmap) {
      this.props.history.push("/");
    } else {
      this.openDeleteDialog();
    }
  };

  packageBoxClickHandler = (pkg, index) => {
    this.setState({
      activeStep: 3,
      isCustomPackageTemplate:
        pkg?.type == "custom_package_management" ? true : false,
      defaultPackageTemplate: pkg?.id,
      currentPackageCustomizationIndex: 1,
      isCreateCustomPackage: false,
      isEditRoadMapPackageTemplate: true,
      packageType: pkg?.type,
    });
  };

  toggleSidebarHandler = () => {
    this.setState({
      isSidebarVisible: !this.state.isSidebarVisible,
    });
  };
  deletePaymentTerm = (pkg, event) => {
    event.stopPropagation();
    this.deletePaymentTermApi(pkg);
  };
  createCustomPackage = () => {
    if (this.state.selectedPaymentTerms.length < 3) {
      this.setState({
        isCreateCustomPackage: true,
        activeStep: 3,
        currentPackageCustomizationIndex: 0,
        name: "Custom Package",
      });
    }
  };
  setDefaultPackageTemplateINRoadmap = (event: any) => {
    this.setState(
      {
        defaultPackageTemplate: event?.target.value,
        selectedFeatureList: [],
      },
      () => {
        const pkg = this.state.packageListForDropDown.find((pkg: any) => {
          return pkg?.id == event?.target.value;
        });
        if (pkg) {
          this.setState({
            name: "custom " + pkg?.attributes?.name,
            description: pkg?.attributes?.description,
            selectedFeatureList: [],
          });
        }
      }
    );
  };
  move_feature_by_index = (tempCustomPackage, source, destination) => {
    const customPackage = [...tempCustomPackage];
    let moveEle = source.index;
    let moveToIndx = destination.index;
    while (moveEle < 0) {
      moveEle += customPackage.length;
    }
    while (moveToIndx < 0) {
      moveToIndx = moveToIndx + customPackage.length;
    }
    if (moveToIndx >= customPackage.length) {
      let un = moveToIndx - customPackage.length + 1;
      while (un--) {
        customPackage.push(undefined);
      }
    }
    customPackage.splice(
      moveToIndx,
      0,
      customPackage.splice(moveEle, 1)[0]
    );
    return customPackage;
  };
  onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    const selectedPaymentTerms = this.move_feature_by_index(
      [...this.state.selectedPaymentTerms],
      source,
      destination
    );

    const selectedPaymentTermsWithorder_number = selectedPaymentTerms.map(
      (item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            selectedPaymentTerm: {
              ...item.attributes.selectedPaymentTerm,
              order_number: index,
            },
          },
        };
      }
    );

    this.setState({
      selectedPaymentTerms: selectedPaymentTermsWithorder_number,
    });
    this.orderOfPackage(selectedPaymentTermsWithorder_number);
  };

  render() {
    const { classes, permissions } = this.props;
    const handleCondition = !!this.state.roadMapInfo && !this.state.isRoadMapSaved;

    let selectedPaymentTermsCopy = this.state.selectedPaymentTerms;

    selectedPaymentTermsCopy.sort((a, b) => {
      let fa = a?.attributes.selectedPaymentTerm?.order_number,
        fb = b?.attributes.selectedPaymentTerm?.order_number;

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    let handleArray = selectedPaymentTermsCopy;


    return (
      <div>
        <Prompt
          when={handleCondition}
          message="Changes that you made may not be saved"
        />
        <Grid container alignItems="stretch" justifyContent="space-between">
          <Grid item xs={12} className={classes.sideBar}>
            <Grid
              container
              justifyContent="space-around"
              className={classes.sideBarContain}
            >
              <Grid item xs={12}>
                <TernaryCheck
                  condition={this.state.isSidebarVisible}
                  trueComponent={
                    <Stepper
                      alternativeLabel
                      style={{ borderRadius: 12, padding: "24px 0" }}
                      connector={<ColorlibConnector />}
                      activeStep={this.state.activeStep}
                      orientation="horizontal"
                    >
                      {steps.map((step, index) => (
                        <ColorStep
                          active={this.state.activeStep === index}
                          key={step}
                          disabled={
                            !permissions.includes(
                              "View/Edit Firm Financials-Firm Financials"
                            )
                          }
                        >
                          <ColorStepLabel
                            active={this.state.activeStep === index}
                            onClick={this.updateStep.bind(this, index)}
                            StepIconComponent={ColorlibStepIcon}
                            disabled={
                              !permissions.includes(
                                "View/Edit Firm Financials-Firm Financials"
                              )
                            }
                          >
                            {step}
                          </ColorStepLabel>
                        </ColorStep>
                      ))}
                    </Stepper>
                  }
                  falseComponent={null}
                />
              </Grid>
              <Grid item xs={12}>
                <TernaryCheck
                  condition={this.state.activeStep === 2}
                  trueComponent={
                    <Grid item xs={12}>
                      <CustomTypography
                        color="primary"
                        fontSize="1.8rem"
                        margin="0 0 1rem 0"
                        fontWeight={600}
                      >
                        Selected Packages (
                        {this.state.selectedPaymentTerms.length}/3)
                      </CustomTypography>
                    </Grid>
                  }
                  falseComponent={null}
                />
                {this.state.selectedPaymentTerms.length > 0 &&
                  this.state.activeStep === 2 ? (
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable
                      droppableId={`payment-terms-list`}
                      direction="horizontal"
                    >
                      {(provided) => (
                        <Grid
                          container
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {handleArray.map((pkg, index) => (
                            <Draggable
                              key={index}
                              draggableId={`payment-term-${index}`}
                              index={index}
                              type="COLUMN"
                              direction="horizontal"
                            >
                              {(provided) => (

                                <Grid
                                  item
                                  xs={4}
                                  className={classes.packageCardStyles}
                                  onClick={this.packageBoxClickHandler.bind(
                                    this,
                                    pkg,
                                    index
                                  )}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListItem
                                    classes={{
                                      container: classes.listcontainer,
                                    }}
                                    style={{
                                      padding: "0",
                                      listStyle: "none",
                                    }}
                                  >
                                    <ListItemText
                                      classes={{
                                        primary: classes.packageName,
                                      }}
                                      primary={`${pkg?.attributes?.name} ${pkg?.attributes?.selectedPaymentTerm?.simpleValue}`}
                                    />
                                    <ListItemSecondaryAction
                                      className={classes.crossIconBox}
                                    >
                                      <IconButton
                                        edge="end"
                                        id="startRoadmap-deletepackagebtn"
                                        onClick={this.deletePaymentTerm.bind(
                                          this,
                                          pkg
                                        )}
                                        aria-label="delete"
                                        className={
                                          classes.crossIconButton
                                        }
                                      >
                                        <Box
                                          className={classes.crossIcon}
                                        >
                                          X
                                        </Box>
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              <Grid
                className={classes.sideBar}
                container
                justifyContent="space-around"
              >
                <Grid item xs={12}>
                  <Stepper
                    style={{ borderRadius: 12 }}
                    connector={<ColorlibConnector />}
                    activeStep={this.state.activeStep}
                    orientation="vertical"
                  >
                    {steps.map((step, index) => (
                      <ColorStep
                        active={this.state.activeStep === index}
                        key={step}
                        disabled={
                          !permissions.includes(
                            "View/Edit Firm Financials-Firm Financials"
                          )
                        }
                      >
                        <ColorStepLabel
                          active={this.state.activeStep === index}
                          onClick={this.updateStep.bind(this, index)}
                          StepIconComponent={ColorlibStepIcon}
                          style={{
                            backgroundColor:
                              this.state.activeStep === index
                                ? "#54A4A6"
                                : "transparent",
                            borderRadius: "8px",
                            overflow: "hidden",
                            margin: "0px 16px",
                          }}
                          disabled={
                            !permissions.includes(
                              "View/Edit Firm Financials-Firm Financials"
                            )
                          }
                        >
                          {step}
                        </ColorStepLabel>
                        {step === "Package Customization" &&
                          this.state.activeStep === 3 && (
                            <StepContent
                              className={classes.stepperContentClass}
                            >
                              {this.state.selectedPaymentTerms.map(
                                (pkg, index) => {
                                  return (
                                    <Box
                                      key={index}
                                      className={classes.packageCardStyles}
                                      textAlign="center"
                                    >
                                      <ListItem style={{ padding: "0" }}>
                                        <ListItemText
                                          classes={{
                                            primary: classes.packageName,
                                          }}
                                          primary={`${pkg?.attributes?.name} ${pkg?.attributes?.selectedPaymentTerm?.simpleValue}`}
                                        />
                                      </ListItem>
                                    </Box>
                                  );
                                }
                              )}
                            </StepContent>
                          )}
                      </ColorStep>
                    ))}
                  </Stepper>
                  <Divider variant="middle" />
                  <div style={{ textAlign: "center" }}>
                    <Button color="primary" onClick={this.goBackToHome}>
                      Back to Home
                    </Button>
                  </div>
                  <TernaryCheck
                    condition={this.state.activeStep == 5}
                    trueComponent={
                      <Box
                        className={
                          this.state.isSidebarVisible
                            ? classes.toggleSideBarButton
                            : classes.toggleSideBarButton2
                        }
                        onClick={this.toggleSidebarHandler}
                      >
                        <TernaryCheck
                          condition={this.state.isSidebarVisible}
                          trueComponent={<ArrowBackIosIcon />}
                          falseComponent={<ArrowForwardIosIcon />}
                        />
                      </Box>
                    }
                    falseComponent={null}
                  />
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} className={classes.root}>
              <Grid
                item
                xs={12}
                md={12}
                className={
                  this.state.activeStep !== 5
                    ? classes.rootChildContainer
                    : classes.rootChildContainerpdf
                }
              >
                {this.state.activeStep !== steps.length && (
                  <>
                    {this.state.roadMapInfo
                      ? this.getStepContent(this.state.activeStep)
                      : !this.state.loading && <Redirect to="/"></Redirect>}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const StartRoadMapWithRouter = withRouter(StartRoadMap);
const StartRoadMapWithToast = withToast(StartRoadMapWithRouter);
const StartRoadMapWithLoader = withLoader(StartRoadMapWithToast);
const StartRoadMapWithAlertBox = withAlertBox(StartRoadMapWithLoader);
const StartRoadMapWithDialogBox = withDialogBox(StartRoadMapWithAlertBox);
const StartRoadMapWithPermissions = withPermissions(StartRoadMapWithDialogBox);

export default withStyles(
  (theme) =>
    createStyles({
      root: {
        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
      rootChildContainer: {
        margin: "1.5rem 2rem 2rem 2rem",
      },
      rootChildContainerpdf: {},
      sideBar: {
        position: "relative",
        backgroundColor: "#ffffff",
        margin: "0 2rem",
      },
      toggleSideBarButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.main,
        right: "-10px",
        top: "29%",
        color: "#fff",
      },
      toggleSideBarButton2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        backgroundColor: theme.palette.primary.main,
        left: "-10px",
        top: "29%",
        color: "#fff",
      },
      navButton: {
        zIndex: 1,
        position: "absolute",
        right: 0,
        top: "50%",
        color: "black",
      },
      appBar: {
        width: "100%",
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
        backgroundColor: " #ffffff",
        height: "10vh",
      },
      toolbarLogo: {
        flexGrow: 1,
      },
      stepperContentClass: {
        padding: theme.spacing(1, 0),
        borderLeft: `3px solid #eaeaf0`,
        marginLeft: "50px",
      },
      packageName: {
        fontFamily: "Lato",
        fontSize: "1rem",
        fontWeight: "bold",
        color: theme.palette.common.white,
        textAlign: "center",
        marginLeft: "1rem",
      },
      drawer: {
        [theme.breakpoints.up("sm")]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      closeMenuButton: {
        marginRight: "auto",
        marginLeft: 0,
      },
      listcontainer: {
        listStyle: "none",
        display: "flex",
      },
      hiddenClass: {
        width: "100%",
      },
      packageCardStyles: {
        flexGrow: 0,
        flexBasis: "31%",
        maxWidth: "31%",
        marginRight: "1.5%",
        cursor: "pointer",
        borderRadius: "4px",
        position: "relative",
        backgroundColor: theme.palette.primary.main,
        padding: "0.5rem 0",
      },
      crossIconBox: {
        position: "unset",
        transform: "unset",
      },
      crossIconButton: {
        marginRight: "unset",
      },
      crossIcon: {
        color: "white",
        backgroundColor: theme.palette.error.main,
        borderRadius: "100%",
        width: "1.5rem",
        height: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.9rem",
        fontWeight: "bold",
      },
      crossOverImage: {
        right: "-5px",
        top: "-5px",
        position: "absolute",
        backgroundColor: "red",
        color: "white",
        padding: "3px",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        cursor: "pointer",
      },
    }),
  { withTheme: true }
)(StartRoadMapWithPermissions);
