//@ts-nocheck
import React from "react";
import { withStyles, Grid, createStyles, Button, Checkbox, Box } from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import DocViewer from "@cyntler/react-doc-viewer";


const MyCustomPNGRenderer: any = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;
  return (
    <div style={{ width: "100%", height: 500 }} id="my-png-renderer">
      <iframe
        style={{ width: "100%", height: 500 }}
        src={`https://docs.google.com/viewer?url=${currentDocument.uri}&embedded=true`}
      />
    </div>
  );
};
MyCustomPNGRenderer.fileTypes = ["doc", "docx"];
MyCustomPNGRenderer.weight = 1;

export class ViewDocumentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      token: null,
    };
  }
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({ token, checked: this.props.dataToPass.checks });
    }
  }


  handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    this.setState({ checked });
  };
  handleSubmit = () => {
    if (this.props.dataToPass.onCheckboxSubmit) {
      this.props.dataToPass.onCheckboxSubmit(this.state.checked, this.props.dataToPass.survey_id, this.props.dataToPass.document_template_id);
    }
    this.props.onCancel();
  };
  open = () => {
    window.open(
      this.props.dataToPass?.documentItem?.attributes?.document,
      "_blank",
      "noopener"
    );
  };

  render() {
    const { classes, dataToPass } = this.props;
    const { checked } = this.state;
    let contentToRender;
    const docs = [
      {

        uri: dataToPass.btnshow ? dataToPass.documentItem : dataToPass.documentItem.attributes.document,
        fileType: "doc",
      },
    ];

    if (dataToPass.view) {
      contentToRender = null;
    } else if (dataToPass.btnshow) {
      contentToRender = (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "16px",
              flexBasis: "30%",
              marginTop: "1rem",
            }}
          >
            <Checkbox
              variant="contained"
              color="primary"
              checked={checked}
              onChange={this.handleCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            Sign
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: "25%",
              marginTop: "1.5rem",
            }}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </>
      );
    } else {
      contentToRender = (
        <Button
          variant="contained"
          color="primary"
          onClick={this.open.bind(this)}
          style={{
            width: "25%",
            marginTop: "1.5rem",
          }}
        >
          Download
        </Button>
      );
    }
    return (
      <Grid container justifyContent="center" className={classes.rootContainer}>
        <Grid item xs={12}>

          <DocViewer
            documents={docs}
            prefetchMethod="GET"
            config={{
              header: { disableHeader: true },
            }}
            pluginRenderers={[MyCustomPNGRenderer]}
            style={{ width: "100%", height: 500 }}
          />
        </Grid>
        {contentToRender}
      </Grid>
    );
  }
}

const ViewDocumentDialogWithRouter = withRouter(ViewDocumentDialog);
const ViewDocumentDialogWithToast = withToast(ViewDocumentDialogWithRouter);
const ViewDocumentDialogWithLoader = withLoader(ViewDocumentDialogWithToast);
const ViewDocumentDialogWithAlertBox = withAlertBox(
  ViewDocumentDialogWithLoader
);
const ViewDocumentDialogWithDialogBox = withDialogBox(
  ViewDocumentDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      width: "60vw",
      paddingBottom: "2rem",
      marginTop: "2.5rem",
    },
  })
)(ViewDocumentDialogWithDialogBox);
