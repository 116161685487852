//@ts-nocheck
import React from "react";
import FirmPerformanceOnboardingController, { Props } from "./FirmPerformanceOnboardingController.web";
import { Typography, Grid, Box, withStyles, createStyles, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import MediumBlueField from "../../../../components/src/MediumBlueField.web";
import { commonStyles } from "../commonStyles";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { largeChartOptions } from "../chartOptions";
import { Line } from "react-chartjs-2";
import { firmPerformanceStyles } from "./FirmPerformanceRevenue.web";
import uuid from "react-uuid";

export const lineChartData = {
  lables: [],
  datasets: [
    {
      label: "",
      data: [],
      borderColor: "#9CBE6A",
      backgroundColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 4,
    },
  ],
};

const StyledTableCell = withStyles((theme) => ({
  root: {
    width: (props) => (props.width ? props.width : "auto"),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: "0.75rem",
      borderBottomLeftRadius: "0.75rem",
    },
    "&:last-of-type": {
      borderTopRightRadius: "0.75rem",
      borderBottomRightRadius: "0.75rem",
    },
  },
  head: {
    color: theme.palette.text.secondary,
    fontSize: "1.1rem",
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
    border: "none",
    backgroundColor: "#F3F3F3",
    padding: "1.2rem 0rem",
  },
  body: {
    fontSize: "1.1rem",
    color: theme.palette.text.secondary,
    border: "none",
    fontFamily: "Nunito Sans",
    backgroundColor: "#fff",
    padding: "1.2rem 0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}))(TableCell);

const clientsOnboardedTimePeriodOptions = [
  { name: "Last 12 Months", value: 12 },
  { name: "Last 9 Months", value: 9 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last 3 Months", value: 3 },
];

export class FirmPerformanceOnboarding extends FirmPerformanceOnboardingController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getOnBoardedClientData(this.state.clientsOnboardedTimePeriod);
      }
    );
  }

  selectChangeHandler = (e) => {
    this.setState({
      clientsOnboardedTimePeriod: e.target.value,
    });
    this.getOnBoardedClientData(e.target.value);
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          style={{
            position: "relative",
          }}
        >
          <Grid item xs={8} className={[classes.gridSpacing, classes.sectionHeadings]}>
            <Typography
              variant="h2"
              style={{
                color: theme.palette.text.blue2,
              }}
              id="page-heading-onboarding"
            >
              Onboarding
            </Typography>
          </Grid>
          <Grid container alignItems="center" justifyContent="flex-end" item xs={4}>
            <Typography color="primary" className={[classes.weight700, classes.size22, classes.textCard]}>
              (NA){" "}
              <Typography display="inline" color="textSecondary" className={[classes.size18]}>
                Avg. time from proposal sent to signature
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} className={[classes.sectionHeadings, classes.gridSpacing]} container justifyContent="space-between" alignItems="center">
                <Typography
                  variant="h2"
                  style={{
                    color: theme.palette.text.blue2,
                  }}
                >
                  Clients Onboarded
                </Typography>
                <MediumBlueField
                  onChange={this.selectChangeHandler}
                  select
                  value={this.state.clientsOnboardedTimePeriod}
                  style={{
                    width: "12%",
                    marginRight: "2rem",
                  }}
                >
                  {clientsOnboardedTimePeriodOptions.map((opt) => {
                    return (
                      <MenuItem key={opt?.value} value={opt?.value}>
                        {opt?.name}
                      </MenuItem>
                    );
                  })}
                </MediumBlueField>
              </Grid>
              <Grid
                item
                xs={10}
                className={[classes.gridSpacing]}
                style={{
                  paddingLeft: theme.spacing(6),
                }}
              >
                <Box
                  style={{
                    height: "21.875rem",
                  }}
                >
                  <Line plugins={[ChartDataLabels]} data={this.state.lineChartData} options={largeChartOptions} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.onboardingTableHeading}>Number of Clients Onboarded by Package</Typography>
              </Grid>

              <Grid item xs={12} className={[classes.gridSpacing]}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table
                        aria-label="simple table"
                        style={{
                          borderCollapse: "separate",
                          borderSpacing: "0px 8px",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell width="2%" />
                            <StyledTableCell width="78%">Package Names</StyledTableCell>
                            <StyledTableCell width="20%" align="center">
                              No. of Client Enrolled
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} className={[classes.clientListCard]}>
                    <TableContainer>
                      <Table
                        aria-label="simple table"
                        style={{
                          borderCollapse: "separate",
                          borderSpacing: "0px 8px",
                        }}
                      >
                        <TableBody>
                          {this?.state?.clients_onboarded_by_packages.length > 0 ? (
                            this?.state?.clients_onboarded_by_packages.map((item, index) => {
                              return (
                                <TableRow className={classes.clientRow} key={uuid()}>
                                  <StyledTableCell width="2%">
                                    <Box
                                      className={classes.clientListColorBar}
                                      style={{
                                        backgroundColor: theme.palette.primary.main,
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                    width="78%"
                                  >
                                    {item?.name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      fontWeight: 600,
                                    }}
                                    width="20%"
                                  >
                                    {item?.count}
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow className={classes.clientRow}>
                              <StyledTableCell align="center" colSpan={3}>
                                <Typography align="center">No data to display</Typography>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const FirmPerformanceOnboardingWithRouter = withRouter(FirmPerformanceOnboarding);
const FirmPerformanceOnboardingAlertBox = withAlertBox(FirmPerformanceOnboardingWithRouter);
const FirmPerformanceOnboardingLoader = withLoader(FirmPerformanceOnboardingAlertBox);
const FirmPerformanceOnboardingToast = withToast(FirmPerformanceOnboardingLoader);
const FirmPerformanceOnboardingWithDialog = withDialog(FirmPerformanceOnboardingToast);

export default withStyles(
  (theme) =>
    createStyles({
      ...commonStyles(theme),
      ...firmPerformanceStyles(theme),
    }),
  { withTheme: true }
)(withConfirmBox(FirmPerformanceOnboardingWithDialog));
