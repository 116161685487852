//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialogBox from "../../../components/src/withDialog.web";

export class MySubscriptionsFeatureDialog extends React.Component {
  

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
       this.setState({
        token: token,
      });
    }
  }


  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.rootContainer}>
          <Typography variant="h3" align="center" className={classes.headingText}>
            {this.props.dataToPass.dialogTitle}
          </Typography>

          {this.props.dataToPass.dialogMessage.split(" .").map((descriptionItem, index) => {
            return (
              <Typography align="center" key={descriptionItem} className={classes.subHeadingText}>
                {descriptionItem}
              </Typography>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

const MySubscriptionsFeatureDialogWithRouter = withRouter(MySubscriptionsFeatureDialog);
const MySubscriptionsFeatureDialogWithToast = withToast(MySubscriptionsFeatureDialogWithRouter);
const MySubscriptionsFeatureDialogWithLoader = withLoader(MySubscriptionsFeatureDialogWithToast);
const MySubscriptionsFeatureDialogWithAlertBox = withAlertBox(MySubscriptionsFeatureDialogWithLoader);
const MySubscriptionsFeatureDialogWithDialogBox = withDialogBox(MySubscriptionsFeatureDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      padding: "4rem 5rem",
    },
    headingText: {
      fontSize: "2.2rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "2rem",
    },
    subHeadingText: {
      fontSize: "1.6rem",
      // fontWeight: "bold",
      color: theme.palette.text.blue2,
      lineHeight: "2.5rem",
      marginBottom: "1rem",
    },
  })
)(MySubscriptionsFeatureDialogWithDialogBox);
