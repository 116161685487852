//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import InputField from "../../../../components/src/InputFieldWithFormik.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";

const validationSchema = Yup.object().shape({
  roadMapName: Yup.string().required("Roadmap name is required"),
});
export class RoadmapSaveDialog extends React.Component {
  closeDialog = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          roadMapName:
            this.props?.dataToPass?.roadMapName,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={this.closeDialog}
      >
        {(formikProps) => {
          const { values, handleChange } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                justifyContent="space-between"
                spacing={4}
                className={classes.rootContainer}
              >
                <Grid item xs={12} style={{ marginBottom: "50px" }}>
                  <Typography color="primary" variant="h1">
                    Name Your Roadmap:
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginBottom: "50px" }}>
                  <Field
                    component={InputField}
                    name="roadMapName"
                    id="roadMapName"
                    onChange={handleChange}
                    value={values.roadMapName}
                    fullWidth
                  />
                </Grid>
                <Grid item container justifyContent="space-between" xs={12}>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={this.closeDialog.bind(this,"cancel")}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      id="roadMapNameButton"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const RoadmapSaveDialogWithRouter = withRouter(RoadmapSaveDialog);
const RoadmapSaveDialogWithToast = withToast(RoadmapSaveDialogWithRouter);
const RoadmapSaveDialogWithLoader = withLoader(RoadmapSaveDialogWithToast);
const RoadmapSaveDialogWithAlertBox = withAlertBox(RoadmapSaveDialogWithLoader);
const RoadmapSaveDialogWithDialogBox = withDialogBox(
  RoadmapSaveDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      padding: theme.spacing(4),
      color: "black",
    },
  })
)(RoadmapSaveDialogWithDialogBox);
