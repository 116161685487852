// @ts-nocheck
export const dialogStyle = (theme) => {
    return {
        rootContainer: {
          padding: theme.spacing(4),
          color: "black",
        },
        iconStyles: {
          height: theme.spacing(10),
          width: theme.spacing(10),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid black",
          borderRadius: theme.spacing(2),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(5),
          cursor: "pointer",
        },
        deactivateButton: {
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.alert.main,
          color: theme.palette.white,
          fontWeight: "bold",
          fontFamily: "Lato",
          textTransform: "capitalize",
          "&:hover": {
            borderRadius: theme.spacing(1),
            backgroundColor: "#b40505",
            fontWeight: "bold",
            fontFamily: "Lato",
            textTransform: "capitalize",
          },
        },
      }
}