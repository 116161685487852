//@ts-nocheck
import React from "react";
import { Grid, withStyles, createStyles, IconButton } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Formik, Form } from "formik";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import StartRoadMapController from "../roadmap/StartRoadMapController.web";
import {
  GoalsTable,
  ClientInfoTable,
  ExpenseTable,
  PricingDataTable,
  BootstrapTooltip,
  pricingData,
  Props,
  firmFinancialsStyles,
} from "../FirmFinancialsNew.web";

let NF = new Intl.NumberFormat("en-US");

export class DefaultFirmFinancials extends StartRoadMapController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    const { permissions } = this.props;

    if (token) {
      this.setState(
        {
          token: token,
          accountId: accountId,
        },
        () => {
          if (!permissions.includes("View/Edit Firm Financials-Firm Financials")) {
            this.props.showToast({
              type: "error",
              message: "AccessDenied for Current User",
            });
            this.props.history.push("/home/settings/pricing/firmPricing");
          } else {
            this.getDefaultFirmFinancialsByAccount();
          }
        }
      );
    }
  }

  checkIfNumber = (event) => {
    let valueInput = event.target.value;
    valueInput = valueInput.replace(/\D/g, "");
    event.target.value = valueInput;
    const regex = /\d+(\.\d\d?)?/;
    if (event.target.value === "" || (regex.test(event.target.value) && event.target.value.length < 12)) {
      let t = event.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      let nf = new Intl.NumberFormat("en-US");
      this.setState(
        {
          [event.target.name]: nf.format(t?.replace(/,/g, "")) === "0" ? "" : nf.format(t?.replace(/,/g, "")),
          isCalculated: false,
        },
        () => {
          if (this.state.individualClients === "" && this.state.businessClients === "") {
            this.setState({
              totalClients: "",
            });
          } else {
            this.setState({
              totalClients: +("" + this.state.businessClients)?.replace(/,/g, "") + +(this.state.individualClients + "")?.replace(/,/g, ""),
            });
          }
        }
      );
    }
  };

  checkIfFloat = (event) => {
    let valueInput = event.target.value;
    valueInput = valueInput.replace(/[^\d.]+/g, ".");
    const decimalCount = (valueInput.match(/\./g) || []).length;
    if (decimalCount > 1 || valueInput.startsWith(".")) {
      valueInput = valueInput.slice(0, -1);
    }
    event.target.value = valueInput;
    const regex = /\d+(\.\d\d?)?/;
    if (event.target.value === "" || (regex.test(event.target.value) && event.target.value.length < 12)) {
      let t = event.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      this.setState(
        {
          [event.target.name]: t,
          isCalculated: false,
        },
        () => {
          this.setState({
            totalClients: +("" + this.state.businessClients)?.replace(/,/g, "") + +(this.state.individualClients + "")?.replace(/,/g, ""),
          });
        }
      );
    }
  };

  checkIfNumberWithMax100Value = (event, pricingData) => {
    let valueInput = event.target.value;
    valueInput = valueInput.replace(/[^\d.]+/g, ".");
    const decimalCount = (valueInput.match(/\./g) || []).length;
    if (decimalCount > 1 || valueInput.startsWith(".")) {
      valueInput = valueInput.slice(0, -1);
    }
    event.target.value = valueInput;
    let revGoalInPercent = 0;
    pricingData.forEach((client) => {
      if (client.rev_goal_percent !== event.target.name) {
        revGoalInPercent += +this.state[`${client.rev_goal_percent}`];
      }
    });
    if (revGoalInPercent + +event.target.value > 100) {
      this.props.showToast({ message: "your total revenue goal should be less then equal to 100", type: "error" });
      return false;
    }
    const regex = /\d+(\.\d\d?)?/;

    if (event.target.value === "" || (regex.test(event.target.value) && event.target.value.length < 12)) {
      let t = event.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;

      this.setState(
        {
          [event.target.name]: t,
          isCalculated: false,
        },
        () => {
          this.setState({
            totalClients: +("" + this.state.businessClients)?.replace(/,/g, "") + +(this.state.individualClients + "")?.replace(/,/g, ""),
          });
        }
      );
    }
  };

  handleInputChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        isCalculated: false,
      },
      () => {
        this.setState({
          totalClients: +this.state.businessClients + +this.state.individualClients,
        });
      }
    );
  };

  onEdit = () => {
    this.setState({
      isCalculated: false,
    });
  };

  onSubmit = (values) => {
    const revGoalInPercent = pricingData.reduce((accu, client) => {
      return +this.state[`${client.rev_goal_percent}`] + accu;
    }, 0);

    if (revGoalInPercent < 100) {
      this.props.showToast({ message: "your total revenue goal should be 100", type: "error" });
      return false;
    }

    let tempCount = 0;

    values.expenseLaborsAttributes.filter(item => item?._destroy === 0).map((expenseItem) => {
        if (expenseItem.labor_role === "") {
          tempCount += 1;
        }
      });

    if (tempCount > 0) {
      this.props.showToast({ message: "Expense name can't be blank", type: "error" });
      return false;
    }

    this.setState(
      {
        formValues: values,
      },
      () => {
        this.createFirmFinancials(values, true);
      }
    );
  };

  getPerOfClientBusinessTotal = () => {
    const percentTotal = pricingData
      .filter((item) => item.package_type === "business")
      .reduce((accu, item) => {
        return +this.state[`${item.percent_of_client}`] + accu;
      }, 0);
    return percentTotal;
  };

  getPerOfClientIndividualTotal = () => {
    const percentTotal = pricingData
      .filter((item) => item.package_type === "Individual")
      .reduce((accu, item) => {
        return +this.state[`${item.percent_of_client}`] + accu;
      }, 0);
    return percentTotal;
  };

  getRevenueGoalTotal = () => {
    const percentTotal = pricingData.reduce((accu, item) => {
      return +this.state[item.rev_goal_percent] + accu;
    }, 0);
    return percentTotal;
  };

  expenseFieldOnChangeHandler = (e, setFieldValue) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    e.target.value = inputValue;

    let regex = /\d+(\.\d\d?)?/;

    if (e.target.value === "" || (regex.test(e.target.value) && e.target.value.length < 12)) {
      let t = e.target.value;
      t = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      let nf = new Intl.NumberFormat("en-US");
      t ? setFieldValue(e.target.name, nf.format(t?.replace(/,/g, ""))) : setFieldValue(e.target.name, "");
      this.setState({
        isCalculated: false,
      });
    }
  };

  render() {
    const { classes, theme, permissions } = this.props;
    const rowsforGoals = [
      {
        goalName: "Gross Revenue",
        currentAnnual: "currentAnnualGross",
        currentMonthly: Number(("" + this.state.currentAnnualGross)?.replace(/,/g, "") / 12).toFixed(2),
        goalAnnual: "goalAnnualGross",
        goalMonthly: Number(("" + this.state.goalAnnualGross)?.replace(/,/g, "") / 12).toFixed(2),
      },
      {
        goalName: "Owner's Compensation",
        currentAnnual: "currentAnnualOwner",
        currentMonthly: Number(("" + this.state.currentAnnualOwner)?.replace(/,/g, "") / 12).toFixed(2),
        goalAnnual: "goalAnnualOwner",
        goalMonthly: Number(("" + this.state.goalAnnualOwner)?.replace(/,/g, "") / 12).toFixed(2),
      },
    ];

    return (
      <Formik
        initialValues={{
          expenses: {
            operatingSpaceExpense: this.state.operatingSpaceExpense,
            technologyExpense: this.state.technologyExpense,
            bizdevMarketingExpense: this.state.bizdevMarketingExpense,
            insuranceExpense: this.state.insuranceExpense,
            othersExpense: this.state.othersExpense,
          },
          expenseLaborsAttributes: this.state.expenseLaborsAttributes,
          laborTotalExpense: this.state.laborTotalExpense,
          totalExpenses: this.state.totalExpenses,
          pricingData: this.state.pricingData,
        }}
        onSubmit={this.state.isCalculated ? this.onEdit : this.onSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue } = formikProps;

          return (
            <Form autoComplete="off" noValidate>
              <Grid container direction="row" alignItems="center">
                <Grid container direction="row" alignItems="center" item xs={12}>
                  <CustomTypography color="primary" fontSize="1.625rem" fontWeight={600}>
                    Firm Financials
                  </CustomTypography>
                  <BootstrapTooltip
                    title={
                      <>
                        These are the defaults being used <br /> when SmartPath calculates your ideal.
                      </>
                    }
                    interactive
                    placement="right"
                    color={theme.palette.primary.main}
                  >
                    <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
                      <InfoOutlinedIcon style={{ color: theme.palette.primary.main, fontSize: "1.3rem" }} />
                    </IconButton>
                  </BootstrapTooltip>
                </Grid>

                <GoalsTable
                  classes={classes}
                  theme={theme}
                  props={this.state}
                  checkIfNumber={this.checkIfNumber}
                  rowsforGoals={rowsforGoals}
                  isDefaultFirmFinancials={true}
                />

                <ExpenseTable
                  classes={classes}
                  theme={theme}
                  props={this.state}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  setState={(values) => {
                    this.setState(values);
                  }}
                  expenseFieldOnChangeHandler={this.expenseFieldOnChangeHandler}
                />

                <ClientInfoTable classes={classes} theme={theme} props={this.state} checkIfNumber={this.checkIfNumber} />

                <PricingDataTable
                  classes={classes}
                  theme={theme}
                  props={this.state}
                  values={values}
                  checkIfNumberWithMax100Value={this.checkIfNumberWithMax100Value}
                  checkIfFloat={this.checkIfFloat}
                  getPerOfClientBusinessTotal={this.getPerOfClientBusinessTotal}
                  getRevenueGoalTotal={this.getRevenueGoalTotal}
                  getPerOfClientIndividualTotal={this.getPerOfClientIndividualTotal}
                  pricingData={pricingData}
                  setState={(values) => {
                    this.setState(values);
                  }}
                  permissions={permissions}
                  isDefaultFirmFinancials={true}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const DefaultFirmFinancialsWithRouter = withRouter(DefaultFirmFinancials);
const DefaultFirmFinancialsAlertBox = withAlertBox(DefaultFirmFinancialsWithRouter);
const DefaultFirmFinancialsLoader = withLoader(DefaultFirmFinancialsAlertBox);
const DefaultFirmFinancialsToast = withToast(DefaultFirmFinancialsLoader);
const DefaultFirmFinancialsWithDialog = withDialog(DefaultFirmFinancialsToast);
const DefaultFirmFinancialsWithPermissions = withPermissions(DefaultFirmFinancialsWithDialog);

export default withStyles(
  (theme) =>
    createStyles({
      ...firmFinancialsStyles(theme),
    }),
  { withTheme: true }
)(withConfirmBox(DefaultFirmFinancialsWithPermissions));
