//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  withStyles,
  createStyles,
  Theme,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { CheckOutlined, CloseOutlined } from "@material-ui/icons";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Fade from "@material-ui/core/Fade";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { surveyStyles,aboutUsStyle } from "./surveyStyles";
const StyledTableRowNew = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "none",
    },
  })
)(TableRow);

const StyleTableCellNew = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 4,
    },
    head: {
      color: theme.palette.text.secondary,
      lineHeight: 0,
      fontSize: 10,
      fontWeight: 400,
      border: "none",
    },
    body: {
      fontSize: 12,
      color: "#000",
      fontWeight: 400,
      border: "none",
    },
  })
)(TableCell);

const TaxFillingSchema = Yup.object().shape({
  taxFillingStatus: Yup.string().required("This field is required"),
  isRealStateBusinessOutSideResidense: Yup.string().required(
    "This field is required"
  ),
  isTradeOnMonthlyBasis: Yup.string().required("This field is required"),
  isTradeCrypto: Yup.string().required("This field is required"),
  isOwnerShipInAnotherBusiness: Yup.string().required("This field is required"),
  isIncomeInAnotherStateOrCountary: Yup.string().required(
    "This field is required"
  ),
  isActiveIssueWithIRS: Yup.string().required("This field is required"),
  isAllIncomeinOneState: Yup.string().required("This field is required"),
  noOfStateIncomeEarnIn: Yup.string().required("This field is required"),
  no_of_countries_earn: Yup.string().required("This field is required"),
});
export const TaxFillingComponent = (props) => {
  const { classes, titleMessage, gotoNextStep } = props;
  const questions = [
    {
      name: "I own real estate outside of my primary residence.",
      key: "isRealStateBusinessOutSideResidense",
    },
    {
      name: "I actively trade investments (stocks, bonds, etc.)",
      key: "isTradeOnMonthlyBasis",
    },
    {
      name: "I own/ trade cryptocurrencies.",
      key: "isTradeCrypto",
    },
    {
      name: "I'm a partner or shareholder in a business other than my own.",
      key: "isOwnerShipInAnotherBusiness",
    },
    {
      name: "I earn income in multiple countries.",
      key: "isIncomeInAnotherStateOrCountary",
    },
    {
      name: "I have an active issues with the IRS.",
      key: "isActiveIssueWithIRS",
    },
    {
      name: "All my income is earned in 1 state.",
      key: "isAllIncomeinOneState",
    },
  ];
  const renderOptions = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
      return item === 1 ? (
        <MenuItem disabled={true} value={0} key={item}>
          {item}
        </MenuItem>
      ) : (
        <MenuItem value={item} key={item}>
          {item}
        </MenuItem>
      );
    });
  };
  const handleDropdownChange = (event, keyName, handleChange) => {
    props.setValueByDynamicName(keyName, event.target.value);
    handleChange(event);
  };
  const renderStatusSelectBar = (
    classes,
    props,
    handleChange,
    handleBlur,
    errors,
    touched
  ) => {
    return (
      <Grid
        item
        xs={12}
        style={{
          marginBottom: "5rem",
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={11} sm={3}>
            <Typography className={classes.statusHeading}>
              Select your status
            </Typography>
          </Grid>
          <Grid item xs={11} sm={4}>
            <FormControl className={classes.formControl}>
              <TextField
                id="taxFillingStatus"
                color="primary"
                value={props.taxFillingStatus}
                name="taxFillingStatus"
                onChange={(event) => {
                  handleDropdownChange(event, "taxFillingStatus", handleChange);
                }}
                onBlur={handleBlur}
                error={errors.taxFillingStatus && touched.taxFillingStatus}
                helperText={
                  errors.taxFillingStatus && touched.taxFillingStatus
                    ? errors.taxFillingStatus
                    : ""
                }
                variant="outlined"
                select
              >
                {[
                  "Single",
                  "Head of household",
                  "Married filing Single",
                  "Married filing joint",
                  "Not Sure/Need Recommendation",
                ].map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderNumberSelectBar = (classes, handleChange, handleBlur, values) => {
    return (
      <>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "1.5rem",
          }}
        >
          <Grid container alignItems="center">
            <Grid xs={9} item>
              <Typography
                style={{
                  marginLeft: "8px",
                  fontSize: "1.6rem",
                  fontWeight: 600,
                }}
                className={classes.pageSubHeading}
              >
                How many states do you earn income in?
              </Typography>
            </Grid>
            <Grid sm={3} xs={11} item>
              <TextField
                disabled={values.isAllIncomeinOneState === "true"}
                color="primary"
                value={values.noOfStateIncomeEarnIn}
                variant="outlined"
                fullWidth
                name="noOfStateIncomeEarnIn"
                onChange={(event) =>
                  handleDropdownChange(
                    event,
                    "noOfStateIncomeEarnIn",
                    handleChange
                  )
                }
                onBlur={handleBlur}
                select
              >
                {renderOptions()}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "1.5rem",
          }}
        >
          <Grid container alignItems="center">
            <Grid xs={9} item>
              <Typography
                style={{
                  marginLeft: "8px",
                  fontSize: "1.6rem",
                  fontWeight: 600,
                }}
                className={classes.pageSubHeading}
              >
                How many countries do you earn income in?
              </Typography>
            </Grid>
            <Grid sm={3} xs={11} item>
              <TextField
                disabled={values.isIncomeInAnotherStateOrCountary !== "true"}
                color="primary"
                className="no_of_countries_earn"
                value={values.no_of_countries_earn}
                variant="outlined"
                fullWidth
                name="no_of_countries_earn"
                select
                onChange={(event) =>
                  handleDropdownChange(
                    event,
                    "no_of_countries_earn",
                    handleChange
                  )
                }
                onBlur={handleBlur}
              >
                {renderOptions()}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  const handleFalseClick = (props, row, setFieldValue) => {
    props.setValueByDynamicName(row.key, "false");
    setFieldValue(row.key, "false");
    if (row.key === "isAllIncomeinOneState") {
      setTimeout(() => {
        props.setValueByDynamicName("noOfStateIncomeEarnIn", 2);
        setFieldValue("noOfStateIncomeEarnIn", 2);
      }, 0);
    }
    if (row.key === "isIncomeInAnotherStateOrCountary") {
      setTimeout(() => {
        props.setValueByDynamicName("no_of_countries_earn", 0);
        setFieldValue("no_of_countries_earn", 0);
      }, 0);
    }

    // setFieldTouched(row?.key, true)
  };
  
  return (
    <Fade timeout={3000} in={true}>
      <Formik
        initialValues={{
          taxFillingStatus: props.taxFillingStatus || "Single",
          isRealStateBusinessOutSideResidense:
            props.isRealStateBusinessOutSideResidense || "",
          isTradeOnMonthlyBasis: props.isTradeOnMonthlyBasis || "",
          isTradeCrypto: props.isTradeCrypto || "",
          isOwnerShipInAnotherBusiness:
            props.isOwnerShipInAnotherBusiness || "",
          isIncomeInAnotherStateOrCountary:
            props.isIncomeInAnotherStateOrCountary || "",
          isActiveIssueWithIRS: props.isActiveIssueWithIRS || "",
          isAllIncomeinOneState: props.isAllIncomeinOneState || "",
          noOfStateIncomeEarnIn: props.noOfStateIncomeEarnIn || 0,
          no_of_countries_earn: props.no_of_countries_earn || 0,
        }}
        validationSchema={TaxFillingSchema}
        onSubmit={gotoNextStep}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            isValid,
            errors,
            touched,
          } = formikProps;
          const goBack = () => {
            setFieldValue("taxFillingStatus", "Single");
            setFieldValue("isRealStateBusinessOutSideResidense", "");
            setFieldValue("isTradeOnMonthlyBasis", "");
            setFieldValue("isTradeCrypto", "");
            setFieldValue("isOwnerShipInAnotherBusiness", "");
            setFieldValue("isIncomeInAnotherStateOrCountary", "");
            setFieldValue("isActiveIssueWithIRS", "");
            setFieldValue("isAllIncomeinOneState", "");
            setFieldValue("noOfStateIncomeEarnIn", 1);
            setFieldValue("no_of_countries_earn", 1);
            props.setValueByDynamicName("taxFillingStatus", "Single");
            props.setValueByDynamicName("isRealStateBusinessOutSideResidense", "");
            props.setValueByDynamicName("isTradeOnMonthlyBasis", "");
            props.setValueByDynamicName("isTradeCrypto", "");
            props.setValueByDynamicName("isOwnerShipInAnotherBusiness", "");
            props.setValueByDynamicName("isIncomeInAnotherStateOrCountary", "");
            props.setValueByDynamicName("isActiveIssueWithIRS", "");
            props.setValueByDynamicName("isAllIncomeinOneState", "");
            props.setValueByDynamicName("noOfStateIncomeEarnIn", 1);
            props.setValueByDynamicName("no_of_countries_earn", 1);
            props.gotoBackStep();
          };
          const getFalseButtonStyle = (row) => {
            let falseButtonStyle = {
              color: "#ccc",
              background: "#f4f4f4",
              boxShadow: "none",
              // width: "60%",
            };

            if (props[row?.key] === "false") {
              falseButtonStyle = {
                background: "#F96064",
                color: "#fff",
                boxShadow: "none",
                // width: "60%",
              };
            }
            return falseButtonStyle;
          };

          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
                className={classes.mainGrid}
              >
                <Grid item xs={10}>
                  <Typography className={classes.pageHeading} gutterBottom>
                    {titleMessage}
                  </Typography>
                </Grid>
                {renderStatusSelectBar(
                  classes,
                  props,
                  handleChange,
                  handleBlur,
                  errors,
                  touched
                )}

                <Grid item xs={12}>
                  <Typography
                    className={classes.statusHeading}
                    style={{ margin: 0, marginBottom: "1rem" }}
                    gutterBottom
                  >
                    Select all statements that apply to you:
                  </Typography>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <StyledTableRowNew>
                          <StyleTableCellNew width="84%" />
                          <StyleTableCellNew width="8%" align="center">
                            <Typography className={classes.yesNoText}>
                              Yes
                            </Typography>
                          </StyleTableCellNew>
                          <StyleTableCellNew width="8%" align="center">
                            <Typography className={classes.yesNoText}>
                              No
                            </Typography>
                          </StyleTableCellNew>
                        </StyledTableRowNew>
                      </TableHead>
                      <TableBody>
                        {questions.map((row) => (
                          <StyledTableRowNew key={row.key}>
                            <StyleTableCellNew scope="row">
                              <Typography
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  padding: "8px",
                                  borderRadius: "10px",
                                  width: "80%",
                                }}
                                className={classes.pageSubHeading}
                              >
                                {row.name}
                              </Typography>
                              {errors[row.key] && touched[row.key] ? (
                                <Typography color="error">
                                  {errors[row.key]}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </StyleTableCellNew>
                            {/* <StyleTableCellNew>
                                <div style={{ visibility: "hidden" }}>
                                  aaaaaaa
                                </div>
                              </StyleTableCellNew> */}
                            <StyleTableCellNew align="right">
                              <Button
                                fullWidth
                                className={classes.yesButton}
                                id={`${row.key}-${"yes"}`}
                                style={
                                  props[row?.key] === "true"
                                    ? {
                                        background:
                                          localStorage.getItem(
                                            "themePrimaryColor"
                                          ) || "#5BA8AA",
                                        color: "#fff",
                                        boxShadow: "none",
                                        // width: "60%",
                                      }
                                    : {
                                        color: "#ccc",
                                        background: "#f4f4f4",
                                        boxShadow: "none",
                                        // width: "60%",
                                      }
                                }
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  props.setValueByDynamicName(row.key, "true");
                                  setFieldValue(row.key, "true");
                                  if (row.key === "isAllIncomeinOneState") {
                                    setTimeout(() => {
                                      props.setValueByDynamicName(
                                        "noOfStateIncomeEarnIn",
                                        0
                                      );
                                      setFieldValue("noOfStateIncomeEarnIn", 0);
                                    }, 0);
                                  }
                                  if (
                                    row.key ===
                                    "isIncomeInAnotherStateOrCountary"
                                  ) {
                                    setTimeout(() => {
                                      props.setValueByDynamicName(
                                        "no_of_countries_earn",
                                        2
                                      );
                                      setFieldValue("no_of_countries_earn", 2);
                                    }, 0);
                                  }

                                  // setFieldTouched(row?.key, true)
                                }}
                              >
                                <CheckOutlined />
                              </Button>
                            </StyleTableCellNew>
                            <StyleTableCellNew align="right">
                              <Button
                                fullWidth
                                style={getFalseButtonStyle(row)}
                                variant="contained"
                                color="default"
                                id={`${row.key}-${"no"}`}
                                onClick={() =>
                                  handleFalseClick(props, row, setFieldValue)
                                }
                              >
                                <CloseOutlined />
                              </Button>
                            </StyleTableCellNew>
                          </StyledTableRowNew>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                {renderNumberSelectBar(
                  classes,
                  handleChange,
                  handleBlur,
                  values
                )}

                <Grid item xs={12} className={classes.buttonContainer}>
                  <Grid container justifyContent="flex-end">
                    <Grid item sm={4} xs={11} style={{ textAlign: "end" }}>
                      <Button
                        color="default"
                        id="taxFillingBackButton"
                        style={{ marginRight: "16px" }}
                        startIcon={<ArrowBackOutlinedIcon />}
                        variant="text"
                        className={[
                          classes.backButton,
                          "statucBackButton",
                        ].join(" ")}
                        onClick={goBack}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        endIcon={<ArrowForwardOutlinedIcon />}
                        disabled={!isValid}
                        type="submit"
                        variant="text"
                        className={[
                          classes.nextButton,
                          "statucNextButton",
                        ].join(" ")}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Fade>
  );
};
export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    ...aboutUsStyle(theme)
  })
)(TaxFillingComponent);
