//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
export const configJSON = require("../config");

export type Customfields = Customfield[] | { errors: string[] };

export interface Customfield {
  id: string;
  type: string;
  attributes: Customfieldattributes;
}

export type Customfieldattributes = {
  id: number;
  key: string;
  value: any;
  allow_field: boolean;
  account_id: number;
};

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  token: any;
  thumbnailImage: any;
  customFields: [];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddNewClientDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  uploadPackageImageApiCallId: string = "";
  addNewClientApiCallId: string = "";
  getCustomFieldApiCallId: string = "";
  deleteCustomFieldForAllClientApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: undefined,
      thumbnailImage: null,
      customFields: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        this.addNewClientApiCallId === apiRequestCallId ||
        this.editClientApiCallId === apiRequestCallId
      ) {
        this.handleAddEditClientResponse(responseJson);
      } else if (this.getCustomFieldApiCallId === apiRequestCallId) {
        this.handleCustomFieldResponse(responseJson);
      } else if (
        this.deleteCustomFieldForAllClientApiCallId === apiRequestCallId
      ) {
        this.deleteCustomFieldForAllClientResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (
        msg === "Session TimeOut,Please Login Again." ||
        msg === "User Account Not Found"
      ) {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }
  deleteCustomFieldForAllClientResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message?.length > 0) {
      this.props.dataToPass?.showToast({
        type: "success",
        message: responseJson?.message,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  customField = (field) => {
    return {
      ...field.attributes?.custom_field,
      value: field?.attributes?.custom_field?.value || field?.attributes?.value,
      newid: field.id,
    };
  };
  customnewField = (field) => {
    return { ...field?.attributes, value: null };
  };
  handleCustomFieldResponse = (responseJson: Customfields) => {
    this.props.hideLoader();
    if (responseJson?.data?.length > 0) {
      if (this.props?.dataToPass?.clientInfo) {
        this.setState({
          customFields: this.props.dataToPass?.clientInfo?.attributes?.client_custom_fields?.data.map(
            this.customField
          ),
        });
      } else {
        this.setState({
          customFields: responseJson.data?.map(this.customnewField),
        });
      }
    } else {
      if (this.props?.dataToPass?.clientInfo) {
        this.setState({
          customFields: this.props.dataToPass?.clientInfo?.attributes?.client_custom_fields?.data.map(
            this.customField
          ),
        });
      }
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleAddEditClientResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      let modifiedClient = {
        ...responseJson.data,
        selected: false,
      };
      this.props.onSubmit(modifiedClient);
      if (this.props.dataToPass.clientInfo) {
        this.props.dataToPass.onSubmit(modifiedClient);
      }
    } else {
      if (typeof responseJson?.errors === "string") {
        this.props.showToast({
          type: "error",
          message: responseJson.errors,
        });
      } else {
        this.props.dataToPass.showToast({
          type: "error",
          message:
            responseJson?.message?.length > 0
              ? responseJson?.message[0]
              : "Some internal error",
        });
      }
    }
  };

  addNewClient = (values: any) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewClientApiCallId = requestMessage.messageId;

    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      primary_email: values.email || "",
      profile_status: "Prospect",
      primary_phone: values.mobileNumber || "",
      firm_name: values.businessName,
      business_name: values.businessName,
      client_type: values.clientType,
      avatar: this.state?.thumbnailImage?.file,
      custom_fields: values?.custom_fields,
      accountId: this.props.dataToPass?.isFromRoadMap
        ? this.props.dataToPass?.accountId
        : undefined,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props.dataToPass?.isFromRoadMap
        ? `bx_block_clients/client_informations/client_info_creation?account_id=${this.props.dataToPass?.accountId}`
        : `bx_block_clients/client_informations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editClient = (values) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editClientApiCallId = requestMessage.messageId;

    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      primary_email: values.email || "",
      profile_status:
        this.props.dataToPass?.clientInfo?.attributes?.profile_status ||
        "Prospect",
      primary_phone: values.mobileNumber || "",
      firm_name: values.businessName,
      business_name: values.businessName,
      client_type: values.clientType,
      avatar: this.state?.thumbnailImage?.base64,
      custom_fields: values?.custom_fields.map((cstmField) => {
        return cstmField.newid
          ? {
              key: cstmField.key,
              values: cstmField.value,
              id: cstmField?.newid,
            }
          : { ...cstmField, values: cstmField.value, value: undefined };
      }),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${this.props?.dataToPass?.clientInfo?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomField = () => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomFieldApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/custom_fields`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteCustomFieldForAllClient = (customFieldId, clientId) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCustomFieldForAllClientApiCallId = requestMessage.messageId;
    let url = `bx_block_pricingcalculator/custom_fields/${customFieldId}`;
    if (clientId) {
      url += `?client_information_id=${clientId}`;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
