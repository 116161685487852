//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Box,
  Tooltip,
  TypographyProps,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ColorBar from "./ColorBar.web";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
    boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
    fontFamily: "Nunito Sans",
    fontSize: "18px",
    maxWidth: 500,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

type SubscriptionFeatureCardProps = {
  featureName: string;
  // featureDetail?: string;
  featureNameAlignment?: TypographyProps["align"];
  color?: TypographyProps["color"];
  padding?: boolean;
  onInfoIconClick: () => void;
  // children?: React.ReactNode;
};

class SubscriptionFeatureCard extends React.Component<
  SubscriptionFeatureCardProps
> {
  constructor(props: SubscriptionFeatureCardProps) {
    super(props);
  }

  render() {
    const {
      classes,
      theme,
      featureName,
      // featureDetail,
      featureNameAlignment,
      padding,
      color,
      onInfoIconClick,
    } = this.props;
    return (
      <Grid item xs={12} className={classes.subscriptionFeatureCard}>
        <Grid container alignItems="center">
          {/* <ColorBar /> */}
          <Grid item xs={11} className={classes.textContainer}>
            <Typography
              color={color ? color : "textSecondary"}
              align={featureNameAlignment ? featureNameAlignment : "left"}
              className={[
                classes.smallCardsText,
                padding ? classes.paddingLeft : "",
              ]}
            >
              {featureName}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            xs={11}
            className={classes.iconContainer}
          >
            {/* <CustomTooltip arrow interactive title={featureDetail}> */}
            <InfoIcon className={classes.icon} onClick={onInfoIconClick} />
            {/* </CustomTooltip> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      subscriptionFeatureCard: {
        position: "relative",
        flexBasis: "96%",
        maxWidth: "96%",
        margin: "0.5rem 0px",
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "#ffffff",
        boxShadow: "rgba(149, 157, 165, 0.35) 0px 0px 16px",
      },
      smallCardsText: {
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        // fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        // paddingLeft: (props) =>
        //   props.featureNameAlignment === "center" ? "10%" : 0,
        paddingLeft: "10%"
      },
      clientListColorBar: {
        position: "absolute",
        left: 0,
        top: "30%",
        width: "4px",
        height: "40%",
        backgroundColor: theme.palette.primary.main,
      },
      textContainer: {
        flexBasis: "90%",
        maxWidth: "90%",
      },
      iconContainer: {
        flexBasis: "10%",
        maxWidth: "10%",
      },
      icon: {
        color: theme.palette.primary.main,
      },
      paddingLeft: {
        paddingLeft: theme.spacing(4),
      },
    }),
  { withTheme: true }
)(SubscriptionFeatureCard);
