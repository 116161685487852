// @ts-nocheck
import React, { Component } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles, createStyles, Typography } from "@material-ui/core";
import closeIcon from "../../blocks/LandingPage2/assets/phase2/close.png";

const DialogBox = withStyles((theme) => {
  return {
    dialogPaper: {
      maxWidth: (props) => {
        return theme.spacing(100);
      },
      minWidth: (props) => {
        return theme.spacing(60);
      },
      borderRadius: 16,
    },
  };
})((props) => {
  const { children, classes: ab, ...other } = props;
  return (
    <MuiDialog {...other} classes={{ paper: ab.dialogPaper }}>
      {children}
    </MuiDialog>
  );
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    closeImage: {
      height: "20px",
      width: "20px",
    },
  });

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="body2">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img src={closeIcon} className={classes.closeImage} alt="X" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const Actionstyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "block",
    },
  });
const DialogActions = withStyles(Actionstyles)((props: any) => {
  const { classes, children } = props;
  return (
    <MuiDialogActions className={classes.root}>{children}</MuiDialogActions>
  );
});

function withConfirmBox(
  OriginalComponent,
  yesbuttonText = "Yes",
  noButtonText = "No"
) {
  return class extends Component {
    state = {
      open: false,
      title: "",
      message: "",
      onClose: null,
      yesbuttonText: "Yes",
      noButtonText: "No",
    };
    openConfrimBox = (
      title: string,
      message: string,
      onClose: Function,
      yesbuttonText? = "Yes",
      noButtonText? = "No"
    ) => {
      this.setState({
        title: title,
        message: message,
        yesbuttonText: yesbuttonText,
        noButtonText: noButtonText,
        open: true,
        onClose: onClose,
      });
    };
    handleClose = (data?: any) => {
      this.setState({ open: false }, () => {
        this.state.onClose(data);
      });
    };
    render() {
      return (
        <>
          <DialogBox
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.handleClose("No");
              }}
            >
              {this.state.title}
            </DialogTitle>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  this.handleClose("Yes");
                }}
                color="primary"
              >
                {this.state.yesbuttonText}
              </Button>
              <Button
                onClick={() => {
                  this.handleClose("No");
                }}
                color="primary"
                variant="outlined"
              >
                {this.state.noButtonText}
              </Button>
            </DialogActions>
          </DialogBox>
          <OriginalComponent
            {...this.props}
            openConfirmDialog={this.openConfrimBox}
          />
        </>
      );
    }
  };
}
export default withConfirmBox;
