//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import { surveyStyles } from "./surveyStyles";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
export const TaxSavingSvgIcon = ({ iconColor }) => {
  const defaultColor = '#54a4a6';
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 834 834"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2
      }}
    >
      <g>
        <path
          d="M804.476,430.178l-205.939,296.092c-12.04,17.31 -35.868,21.589 -53.178,9.55l-115.011,-79.993c-3.999,-2.782 -7.302,-6.192 -9.867,-10.004l-16.592,23.856l177.739,123.622l242.873,-349.195l-20.025,-13.928Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M796.149,378.535l-177.739,-123.622l-235.488,338.577l177.739,123.622l235.488,-338.577Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M456.947,210.454l27.069,-41.913l46.956,0l-49.841,79.36l46.877,72.584l-49.66,-0l-21.401,-34.076l-21.4,34.076l-49.66,-0l46.876,-72.584l-49.841,-79.36l46.957,0l27.068,41.913Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M56.749,565.472l0,-455.526c0,0 549.609,0 549.609,0c0,0 0,77.079 0,77.079c0,10.924 8.869,19.792 19.792,19.792c10.923,-0 19.792,-8.868 19.792,-19.792l-0,-96.87c-0,-10.931 -8.861,-19.792 -19.792,-19.792l-589.192,0c-10.931,0 -19.792,8.861 -19.792,19.792l-0,517.894c-0,8.004 4.822,15.221 12.218,18.285c7.395,3.063 15.908,1.37 21.568,-4.291l10.236,-10.235c0,-0 54.219,45.501 54.219,45.501c7.269,6.101 17.845,6.183 25.208,0.197l56.232,-45.717c0,0 56.985,47.98 56.985,47.98c7.86,6.618 19.477,6.12 26.742,-1.145l66.465,-66.465c7.724,-7.724 7.724,-20.266 0,-27.989c-7.724,-7.724 -20.265,-7.724 -27.989,-0l-53.62,53.619c0,-0 -55.614,-46.826 -55.614,-46.826c-7.269,-6.12 -17.86,-6.211 -25.233,-0.217l-56.253,45.733c-0,0 -55.589,-46.651 -55.589,-46.651c-4.592,-3.855 -10.461,-5.284 -15.992,-4.356Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M166.816,203.491l-36.137,-0l-0,-34.95l115.521,0l0,35.195l-36.809,-0l0,116.749l-42.098,-0l-0.477,-116.994Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M240.13,320.485l46.385,-151.944l54.01,0l42.397,151.944l-51.368,-0l-0,-23.321l-42.441,0.11l0,23.211l-48.983,-0Zm91.424,-62.48l-37.44,-0l19.178,-46.974l18.262,46.974Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M425.515,467.487l-281.184,-0c-7.535,-0 -13.652,5.683 -13.652,12.684c-0,7 6.117,12.684 13.652,12.684l262.193,-0l18.991,-25.368Z"
          style={{ fill: iconColor || defaultColor }}
        />
        <path
          d="M482.827,388.694l-338.496,-0c-7.535,-0 -13.652,5.684 -13.652,12.684c-0,7.001 6.117,12.684 13.652,12.684l319.505,0l18.991,-25.368Z"
          style={{ fill: iconColor || defaultColor }}
        />
      </g>
    </svg>
  );
};

class TaxSavingStepStep extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    let stepNo = this.props.taxPayerType === "individual" ? '7.2' : '7.1';
    this.props.getQuestionAndOptionsByStepNo(stepNo);
  }
  render() {
    const { classes,iconColor } = this.props;
    return (
      <Grid container justifyContent="space-around" alignItems="start">
        <Grid xs={11} sm={3} item>
          <Box my={8}>
          <div style={{paddingLeft : '3rem'}} className={classes.leftSideImageContainer}>
              {/* <img src={TaxSaving} style={{display:'block',width : '100%',height : '100%'}} /> */}
              <TaxSavingSvgIcon iconColor={iconColor} />
            </div>
            <Typography
              gutterBottom
              className={classes.sidebarHeadingWithoutMargin}
              color="primary"
            >
              Tax Savings
            </Typography>
            <Typography className={classes.sidebarDescription}>
               Most of us want to pay the absolute least amount of tax possible (legally)...
            </Typography>

            <Typography className={classes.sidebarDescription}>
            For that to happen, you can't wait until it's time to file your taxes to look for tax savings.
            </Typography>
            <Typography className={classes.sidebarDescription}>
            You create tax savings by making the right decisions throughout the year that take advantage of tax rules that apply to your unique situation.
            </Typography>
            <Typography className={classes.sidebarDescription}>
               Tax rules change constantly. The only way to take full advantage of the rules that apply to you is to proactively implement a tax plan throughout the year.
            </Typography>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid xs={8} item>
          <Grid container justifyContent="center" >
            <Grid item xs={9}>
              <Typography className={classes.pageHeading} gutterBottom>
                   Select any elements you would like to include in your custom package.
              </Typography>
              {this.props.taxPayerType === "individual" && <Typography className={classes.pageSubHeading}>
                If you could minimize your personal taxes what would you do with the money you saved?"

              </Typography>}
            </Grid>

            <Grid item xs={9}>
              <Grid container justifyContent="space-between">
              {this.props?.stepContent?.options?.data?.map((option) => {
                        return (
                          <Grid
                            key={option.id}
                            item
                            xs={12}
                            md={4}
                            className={
                              this.props.taxSavingSelection
                                ?.map((step) => {
                                  return step?.id;
                                })
                                .filter((opt) => {
                                  return opt === option.id;
                                }).length > 0
                                ? [
                                    classes.selectedPackageCard1,
                                    classes.minHeight13rem,
                                  ]
                                : [classes.packageCard1, classes.minHeight13rem]
                            }
                            onClick={() => {
                              this.props.setTaxSavingSelection(option);
                            }}
                          >
                            <Typography
                              className={classes.cardDescription}
                              color="textPrimary"
                            >
                            {option?.attributes?.displaydescription.indexOf("Maximizing") > -1 ? <> <span className={classes.cardDescriptionHighlight}>{"Maximizing "}</span><span>{option?.attributes?.displaydescription.substring(option?.attributes?.displaydescription.indexOf("Maximizing")+1+"Maximizing".length)}</span></>  : option?.attributes?.displaydescription}
                            </Typography>
                          </Grid>
                        );
                      })}
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.buttonContainer}>
              <Grid container justifyContent="flex-end">
                <Grid item sm={4}  xs={11} container justifyContent="flex-end">
                  <Button
                    startIcon={<ArrowBackOutlinedIcon />}
                    color="primary"
                    onClick={() => {
                      this.props.handleBack();
                    }}
                    variant="text"
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    endIcon={<ArrowForwardOutlinedIcon />}
                    color="primary"
                    disabled={!this.props.taxSavingSelection.length > 0}
                    onClick={() => {
                      this.props.handleNext();
                    }}
                    variant="text"
                    className={classes.nextButton}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const TaxSavingStepStepWithRouter = withRouter(TaxSavingStepStep);
const TaxSavingStepStepAlertBox = withAlertBox(TaxSavingStepStepWithRouter);
const TaxSavingStepStepLoader = withLoader(TaxSavingStepStepAlertBox);
const TaxSavingStepStepToast = withToast(TaxSavingStepStepLoader);
const TaxSavingStepStepWithDialog = withDialog(TaxSavingStepStepToast);

export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    incomeContainer: {
      width: "60%",
      margin: "auto",
      height: "50vh",
    },
  })
)(withConfirmBox(TaxSavingStepStepWithDialog));
