import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import { DEFAULT_PERMISSIONS_ARRAY } from "../../../../components/src/PermissionsContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    setPermissions: (permissions: any) => {};
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  token: any;
  accountId: any;
  role: any;
  isPasswordVisible: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  loginApiCallID: string = "";
  loginApiSocialEmailCallId: string = "";
  getProfileDetailApiCallID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      role: "",
      isPasswordVisible: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  formatAndSetPermissions = async (permissions: string[]) => {
    let simplePermissionsArray: string[] = [];
    permissions.forEach((category: any) => {
      category?.permissions.forEach((per: any) => {
        if (per?.is_permitted) {
          simplePermissionsArray.push(`${per.permission}-${per.category}`);
        }
      });
    });
    localStorage.setItem("permissions", JSON.stringify(simplePermissionsArray));
    this.props.setPermissions(simplePermissionsArray);
  };

  handleLoginResponse = async (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.meta?.token) {
      if (responseJson.meta?.role === "Firm Employee User") {
        this.formatAndSetPermissions(responseJson.meta?.permissions);

      } else {
        const allPermissions = DEFAULT_PERMISSIONS_ARRAY;
        localStorage.setItem("permissions", JSON.stringify(allPermissions));

        this.props.setPermissions(allPermissions);
      }
      await StorageProvider.set("authToken", responseJson?.meta?.token);
      await StorageProvider.set("accountId", responseJson?.meta?.account_id);
      await StorageProvider.set("role", responseJson?.meta?.role);
      if (responseJson?.meta?.employee_id) {
        await StorageProvider.set("employee_id", responseJson?.meta?.employee_id);
      }
      this.setState(
        {
          token: responseJson?.meta?.token,
          accountId: responseJson?.meta?.account_id,
          role: responseJson?.meta?.role,
        },
        () => {
          this.getProfile();
          
        }
      );
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.loginApiCallID) {
        this.handleLoginResponse(responseJson);
      } else if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.handleGetProfileResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      await this.props.showAlert({ title: "Error", message: AlertBodyMessage });
    }
  }

  handleGetProfileResponse = async (responseJson: any) => {
    this.props.hideLoader();
    await StorageProvider.set("currentUser", JSON.stringify(responseJson));
    if (
      this.state.role === "Firm Employee User" ||
      this.state.role === "Firm Owner User"
    ) {
      this.props.history.push("/home");
    } else if (
      this.state.role === "Admin User" ||
      this.state.role === "Super Admin User"
    ) {
      this.props.history.push("/adminLandingPage");
    }
  };

  handleLogin = async (values: any) => {
    await StorageProvider.clearStorage();
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          primary_email: values.email,
          password: values.password,
        },
      },
    };

    this.loginApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `login/login`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProfile = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
      skipSubscriptionCheck: true,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileDetailApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${"account_block/accounts"}/${this.state.accountId}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
