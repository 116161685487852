//@ts-nocheck
import React from "react";
import HelpCenterController, { Props } from "./HelpCenterPageController.web";
import {
  Typography,
  Grid,
  Button
} from "@material-ui/core";
import { withRouter } from "react-router";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
class Collectionofhelpcenter extends HelpCenterController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    this.listOfSection()
    const sectionId = this.props.history.location.state?.collectionId;
    if (sectionId) {
      this.showOfCollection(sectionId);
    }
  }
  render() {
    const collectionOnedata = this.props.history.location.state?.showCollectionData;
    const sectionData = this.state.sectionData;
    const sectionId = this.props.history.location.state?.collectionId;
    return <Grid style={{ marginBottom: "30px" }}>
      <div key={collectionOnedata?.collection?.id} data-test-id="test1">
        <Typography style={styles.mainheading}>{collectionOnedata?.collection?.name}{" "}
          <span style={styles.descriptionStyle2}>
            {collectionOnedata?.collection?.description}
          </span>
        </Typography>
      </div>
      {sectionData?.list_all_section?.data.map((data, id) => {
        return <div key={data.id} style={styles.mainBox} data-test-id="test2" onClick={() => { this.showOfSection(data.id, sectionId) }}>
          <Typography style={styles.heading}>{data.name}</Typography>
        </div>
      })
      }
      <Button onClick={() => {
        this.handleBackButtonHelp()
      }} style={styles.buttonstyle} data-test-id="backButton2">
        <ArrowBackIcon style={{marginRight:"5px"}} />back
      </Button>
    </Grid>
  }
}
export const Show_collectionWithRouter = withRouter(Collectionofhelpcenter);
export class Section extends HelpCenterController {
  render() {
    const data = this.props.history.location.state?.showSectionOneData;
    const id = this.props.history.location.state?.collectionId

    return <Grid style={{ marginBottom: "30px" }}>
      <div key={data?.section?.id} style={styles.mainBox2} >
        <Typography style={styles.heading}>{data?.section?.name}</Typography>
      </div>
      <Button onClick={() => {
        this.handleBackSectionButton(id)
      }} style={styles.buttonstyle} data-test-id="backButton1">
        <ArrowBackIcon style={{marginRight:"5px"}}/>  back
      </Button>
    </Grid>
  }
}
export const SectionWithRouter = withRouter(Section);

export class HelpCenter extends HelpCenterController {
  constructor(props: Props) {
    super(props);

  }
  async componentDidMount() {
    this.listOfCollection()
  }

  render() {
    const data = this.state.collectionData
    return (
      <Grid style={{ marginBottom: "30px" }}>
        <Typography style={styles.mainheading} >
          Categories
        </Typography>
        {data && data?.list_all_collection?.data.map((item: any) => {
          return (
            <div key={item.id} style={styles.mainBox} data-test-id="test123" onClick={() => { this.showOfCollection(item.id) }}>
              <Typography style={styles.heading}>{item?.name}</Typography>
              <Typography style={styles.descriptionStyle}>{item?.description}</Typography>
            </div>
          );
        })}

      </Grid>
    );
  }
}

const styles = {
  mainBox: {
    backgroundColor: 'rgba(231, 231, 231, 0.33)',
    marginTop: "10px",
    borderRadius: "5px",
    padding: "5px 5px 5px 10px",
    cursor: "pointer"
  },
  mainBox2: {
    borderRadius: "5px",
    backgroundColor: '#f4f4f4',
    padding: "5px 5px 5px 10px",
    marginTop: "10px",
  },
  mainheading: {
    borderBottom: "1px solid rgba(72, 100, 132, 1)",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "rgba(72, 100, 132, 1)",

  },
  heading: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#6d6d6d",
    fontFamily: "Nunito Sans"
  },
  buttonstyle: {
    color:'rgb(109,109,109)',
    marginTop: "10px",
    textTransform: "capitalize",
    justifyContent: "center"
  },
  descriptionStyle: {
    fontWeight: 300,
    fontSize: "12px",
    color: "#6d6d6d",
    marginTop: "4px",
    fontFamily: "Nunito Sans"
  },
  descriptionStyle2: {
    color: "#6d6d6d",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Nunito Sans"
  }
}
export default withRouter(HelpCenter);

