//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  token : any,
  selectedChecklists: [],
  checklistNamesAndIds: [],
  checklists: []
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SendSurveyDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  getChecklistsForAddPageApiCallId : string = "";
  assignChecklitsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: '',
      selectedChecklists: [],
      checklistNamesAndIds: [],
      checklists: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleError = (responseJson) => {
    if(typeof responseJson?.errors === 'string'){
      this.props.showToast({
        type : 'error',
        message : responseJson.errors
      })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleGetChecklistApiResponseForAddPage = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      let assignedChecklists = [];
      this.props.dataToPass.packageItem.attributes.package_checklists?.data?.map(assignedChecklist => {
        assignedChecklists.push({
          checklistName: assignedChecklist.attributes.checklist_name,
          id: +assignedChecklist.attributes.bx_block_checklist_checklist_id
        })
      });
      let checklistNamesAndIds = [];
      responseJson?.data?.map((checklistItem) => {
        let tempValue = assignedChecklists.findIndex(item => item.id === checklistItem.attributes.id)
        if(tempValue !==  -1) {
          return
        }
        checklistNamesAndIds.push({
          checklistName: checklistItem.attributes.checklist_name,
          id: +checklistItem.attributes.id,
          selected: false,
        });
      })
      this.setState({
        checklistNamesAndIds : checklistNamesAndIds,
        checklists: responseJson.data
      })
    } else {
      this.handleError(responseJson);
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

       if(this.getChecklistsForAddPageApiCallId === apiRequestCallId){
        this.handleGetChecklistApiResponseForAddPage(responseJson);
      } else if(this.assignChecklitsApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson?.data) {
          this.props.onSubmit("Yes");
        } else {
          this.handleError(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      let AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountId")  
        this.props.history.push("/")
      }
    }
  }

  
  getChecklistsForAddPage = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token : this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChecklistsForAddPageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_checklist/checklists"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   assignChecklits = (packageId, packageType) => {
    this.props.showLoader();

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignChecklitsApiCallId = requestMessage.messageId;

    let checklistIdArray = [];

    this.state.checklists.map(checklistItem => {
      if(this.state.selectedChecklists.some(item => item.id === checklistItem.attributes.id)) {
        checklistIdArray.push({
          bx_block_checklist_checklist_id: checklistItem.attributes.id
        })
      }
    });

    const httpBody = {
      package_management: [
        ...checklistIdArray
      ],
      custome_package: packageType === "custom_package_management" ? true : undefined,
    };
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      packageType === "custom_package_management" ? `package_managements/${packageId}/assigned_checklist?custome_package=true` : `package_managements/${packageId}/assigned_checklist`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
