import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

//#345E7D - blue
//#54A4A6 - primary green
//#FFD88A - secondary yellow

// Lato
// Helvetica

// rgba(84, 164, 166, 1)

// rgba(255, 161, 117, 1)

// rgba(255, 216, 138, 1)

// rgba(195, 227, 187, 1)

// rgba(72, 100, 132, 1)

// rgba(58, 63, 99, 1)

// rgba(52, 94, 125, 1)

// rgba(75, 66, 68, 1)

// rgba(102, 103, 105, 1)

export const COLOR_PALETTE = {
  palette: {
    type: 'light',
    white: '#fff',
    black: '#000',
    primary: {
      main: '#54A4A6',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FFD88A',
      contrastText: '#fff'
    },
    text: {
      primary: '#4b4244',
      secondary: '#666769',
      blue: '#3a3f63',
      blue2: 'rgba(72, 100, 132, 1)',
    },
    background: {
      default: '#fff',
      paper: '#fff'
    },
    error: {
      main: '#FF5C5C' // orange
      // #ffa175
    },
    warning: {
      main: '#FCC65A' //'yellow'
    },
    info: {
      main: '#345E7D' // blue
    },
    success: {
      main: '#54A4A6' // cyan
    },
    alert: {
      main: '#E10707'
    }
    // error: {
    //   main: '#ef3e22'
    // }
    // #3a3f63
    // #272b2b
  }
};
const theme = createTheme({
  ...COLOR_PALETTE,
  props: {
    MuiDivider: {
      light: true
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1600,
      xl: 1800
    }
  },
  typography: {
    fontFamily: ['Lato'].join(','),
    textTransform: 'none',
    h1: {
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: 1.25,
      letterSpacing: '0.27px'
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1.33,
      letterSpacing: '0.2px'
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: 1.2,
      letterSpacing: '0.2px'
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: 1.78,
      letterSpacing: 'normal'
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: 1.38,
      letterSpacing: '0.2px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          "& #proxy-renderer": {
            overflowY: "hidden"
          }
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Lato',
        fontSize: "0.8rem",
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        color: '#000000'
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'Lato',
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: '0.2px',
        color: '#183B56',
        textTransform: 'capitalize'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px'
      }
    },
    MuiButton: {
      root: {
        height: '2.8rem',
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
      },
      containedPrimary: {
        height: '2.8rem',
        borderRadius: '0.5rem',
        padding: '8px 30px',
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: 'capitalize'
      },
      containedSecondary: {
        backgroundColor: "#8F92A1",
        height: '2.8rem',
        borderRadius: '0.5rem',
        padding: '8px 30px',
        fontFamily: 'Nunito Sans',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        "&:hover": {
          backgroundColor: "#b0b2bc",
        }
      },
      outlinedPrimary: {
        height: '2.8rem',
        borderRadius: '0.5rem',
        padding: '8px 30px',
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#54A4A6!important',
        textTransform: 'capitalize'
      },
      textPrimary: {
        height: '2.8rem',
        borderRadius: '0.5rem',
        padding: '8px 30px',
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#54A4A6!important',
        textTransform: 'capitalize'
      },
      endIcon: {
        marginLeft: '12px'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&&[class*="MuiOutlinedInput-root"]': {
          padding: '5px 2px 5px 0px'
        }
      }
    },
    MuiSelect: {
      outlined: {
        borderRadius: '4px'
      }
    },
    // MuiTableCell: {
    //   head: {
    //     borderBottom: '2px solid #54A4A6'
    //   },
    // },
    MuiOutlinedInput: {
      root: {
        outline: 'none',
        border: `2px solid #f4f4f4`,
        borderRadius: '0.75rem',
        backgroundColor: '#f4f4f4',
        // 'label + &': {
        //   marginTop: 30,
        //   marginBottom: 20,
        // },
        '& > fieldset': {
          border: 'none',
          '& active': {
            border: 'none'
          },
          '& focus': {
            border: 'none'
          }
        },
        '&.Mui-focused': {
          border: `2px solid #54A4A6`,
          backgroundColor: '#fff'
        },
        '&.Mui-error': {
          border: `2px solid #FF5C5C`,
          backgroundColor: '#f4f4f4',
          '&.Mui-focused': {
            backgroundColor: '#fff'
          }
        },
        '&.MuiSelect-root': {
          border: 'none',
          outline: 'none'
        }
      },
      input: {
        borderRadius: '0.75rem',
        position: 'relative',
        fontSize: '1rem',
        fontFamily: 'Nunito Sans',
        // fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        border: 'none',
        '&:focus': {
          border: 'none',
          borderRadius: '0.75rem',
          backgroundColor: '#fff',
          '&::placeholder': {
            color: 'rgba(72, 100, 132, 1)'
          }
        }
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        position: 'relative',
        // backgroundColor: '#f4f4f4',
        border: 'none',
        fontSize: '1rem',
        fontFamily: 'Nunito Sans',
        // fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '0.75rem 0.75rem',
        '&:focus': {
          border: 'none',
          borderRadius: '0.75rem',
          backgroundColor: '#fff',
          '&::placeholder': {
            color: 'rgba(72, 100, 132, 1)'
          }
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Lato',
        fontSize: '0.875rem',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: "rgba(58, 63, 99, 1)",
      }
    },
    MuiListItem: {
      root: {
        padding: 0
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiMenuItem: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        borderRadius: "16px",
        minWidth: "20vw"
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "#929494",
        "&.Mui-checked": {
          color: "#929494",
        }
      }
    }
  }
});

export default responsiveFontSizes(theme);
