//@ts-nocheck

import React from "react";
import LoginController, { Props } from "./LoginController.web";
import { withStyles, Grid, Typography, createStyles, Button, Box, InputAdornment } from "@material-ui/core";
import { profileInactive, lockInactive } from "../assets";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withPermissions from "../../../../components/src/withPermissions.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Redirect } from "react-router-dom";
import { Logo } from "./CodeVerified.web";
import TernaryCheck from "../TernaryCheck.web";

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format.")
    .required("This field is required."),
  password: Yup.string().required("This field is required"),
});

export class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const role = await StorageProvider.get("role");
    if (token) {
      this.setState({
        token: token,
        role,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const role = this.state.role;
    let path = "/home/dashboard";
    if (role === "Admin User" || role === "Super Admin User") {
      path = "/adminDashboard";
    }
    return !this.state.token ? (
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          this.handleLogin(values);
        }}
      >
        {(formikProps) => {
          const { values, isValid, dirty } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "100vh",
                }}
              >
                <Grid item md={3} xs={12} className={classes.loginBox}>
                  <Box py={4}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Logo />
                      <Grid item xs={10}>
                        <Field
                          component={InputField}
                          fullWidth
                          name="email"
                          value={values.email}
                          placeholder="Enter Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={profileInactive} className={classes.inputLogos} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Field
                          type={this.state.isPasswordVisible ? "text" : "password"}
                          component={InputField}
                          fullWidth
                          name="password"
                          value={values.password}
                          placeholder="Enter Password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={lockInactive} className={classes.inputLogos} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                onClick={() => {
                                  this.setState((prevState) => ({
                                    isPasswordVisible: !prevState.isPasswordVisible,
                                  }));
                                }}
                              >
                                <TernaryCheck
                                  condition={this.state.isPasswordVisible}
                                  trueComponent={<VisibilityOffIcon className={classes.visibilityIcon} />}
                                  falseComponent={<VisibilityIcon className={classes.visibilityIcon} />}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          align="center"
                          onClick={() => {
                            this.props.history.push("forgotPassword");
                          }}
                          color="textPrimary"
                          className={classes.buttonText}
                        >
                          Forgot Password?
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Button color="primary" type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)}>
                          Sign In
                        </Button>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography align="center" color="textPrimary" className={classes.likeButtonText}>
                          Or
                        </Typography>
                      </Grid>
                      <Grid item md={10} xl={10} container alignItems="center" justifyContent="center">
                        <Typography style={{ paddingLeft: "8px" }} align="center" display="inline" color="textPrimary" className={classes.likeButtonText}>
                          Create an Account
                        </Typography>
                        <Typography
                          onClick={() => {
                            this.props.history.push("signup");
                          }}
                          display="inline"
                          color="primary"
                          style={{ paddingLeft: "10px" }}
                          className={classes.buttonText}
                        >
                          Sign Up
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    ) : (
      <Redirect to={path} />
    );
  }
}

const LoginWithRouter = withRouter(Login);
const LoginWithToast = withToast(LoginWithRouter);
const LoginWithLoader = withLoader(LoginWithToast);
const LoginWithAlertBox = withAlertBox(LoginWithLoader);

export const authFlowStyles = (theme) => {
  return {
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Nunito Sans",
      fontSize: "1.75rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Nunito Sans",
      fontSize: "0.75rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundRepeat: "no-repeat",
      backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: "0.5rem",
    },
    likeButtonText: {
      fontFamily: "Nunito Sans",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      cursor: "pointer",
    },
    buttonText: {
      fontFamily: "Nunito Sans",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      cursor: "pointer",
    },
    loginBox: {
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      borderRadius: "1.125rem",
      [theme.breakpoints.down("xs")]: {
        boxShadow: "none",
        borderRadius: theme.spacing(0),
      },
    },
    inputLogos: {
      height: "1.25rem",
      width: "1.25rem",
    },
    visibilityIcon: {
      color: "#9c9c9c",
      cursor: "pointer",
    },
    termsAndConditionsText: {
      fontFamily: "Nunito Sans",
      fontSize: "0.875rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      cursor: "pointer",
    },
    withButtonText: {
      fontFamily: "Nunito Sans",
      fontSize: "0.875rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    inputFieldStyles: {
      "& div": {
        borderRadius: "10px",
        border: "none",
      },
      "& input": {
        backgroundColor: "#f7f7f7",
      },
    },
    notchedOutline: {},
    otpContainerStyle: {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(4, "auto"),
      width: "92%",
    },
    otpInputStyle: {
      margin: "0px 0.8rem",
      width: "2.5rem !important",
      height: "2.5rem !important",
      borderRadius: "8px",
      border: "none",
      backgroundColor: "#f7f7f7",
      "&:focus-visible": {
        border: `1.5px solid ${theme.palette.text.blue2}`,
        outline: "none",
        "&::placeholder": {
          color: "transparent",
        },
      },
    },
    qLabel: {
      fontSize: "1rem",
      fontWeight: "bold",
      marginLeft: "10px",
    },
    focused: {
      "& $notchedOutline": {
        borderColor: "yellow",
      },
    },
    rootBox: {
      padding: theme.spacing(15, 10),
      [theme.breakpoints.down("xs")]: {
        padding: "1rem",
      },
    },
    rootContainer: {
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      borderRadius: "1rem",
      overflow: "auto",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        boxShadow: "none",
        borderRadius: theme.spacing(0),
      },
    },
    secondContainer: {
      margin: theme.spacing(6, 4),
      backgroundColor: "#f8f8f8",
      padding: theme.spacing(4, 4, 8, 4),
      borderRadius: "1rem",
      [theme.breakpoints.up("md")]: {
        overflowY: "scroll",
        height: "65vh",
        "&::-webkit-scrollbar": {
          width: "10px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#ECECEC",
          margin: "30px 0px",
          borderRadius: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "0.5rem",
        },
      },
      [theme.breakpoints.down("xs")]: {
        margin: "1rem",
        backgroundColor: "#f7f7f7",
        padding: "1rem",
        height: "100vh",
      },
    },
    crossButton: {
      position: "absolute",
      right: 30,
      top: 10,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "6px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "1.5rem",
      width: "1.5rem",
      cursor: "pointer",
      fontSize: "1rem",
    },
    divider: {
      backgroundColor: "#c8c8c8",
    },
    bottomMargin: {
      marginBottom: "1rem",
    },
    topMargin: {
      marginTop: "1rem",
    },
    introText: {
      fontWeight: 600,
      textAlign: "justify",
      marginBottom: "2rem",
    },
    orderedHeadings: {
      fontSize: "1.4rem",
      fontWeight: 600,
      marginBottom: "0.7rem",
    },
    termsText: {
      textAlign: "justify",
      marginBottom: "1.5rem",
    },
    primaryColorHeading: {
      fontWeight: "bold",
      fontSize: "1.25rem",
      lineHeight: 1.38,
      letterSpacing: "0.7px",
    },
    headerText: {
      fontSize: "1.0625rem",
      lineHeight: 1.38,
      letterSpacing: "0.7px",
    },
    subHeading: {
      fontWeight: "bold",
      fontSize: "1.125rem",
      lineHeight: 1.38,
      letterSpacing: "0.7px",
    },
    bodyText: {
      fontSize: "1rem",
      lineHeight: 1.38,
      letterSpacing: "0.2px",
    },
  };
};

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
  })
)(withPermissions(LoginWithAlertBox));
