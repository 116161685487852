// @ts-nocheck
import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import UploadSvg from "../assets/svg/image_upload.svg";

export type UploadBoxProps = withToastProps & {
  onSelectFile: (Files: FileList) => {};
  onTouched: () => {};
  multiple: boolean;
  classes: any;
  uploadMsg?: string;
  uploadSubMsg?: string;
  acceptedFormat: string;
  uploadimg?: any;
  height?: React.CSSProperties["height"];
};
export class UploadBoxWhite extends React.Component<UploadBoxProps> {
  fileInputRef = React.createRef<HTMLInputElement>();

  fileInputClicked() {
    this.props.onTouched();
    this.fileInputRef?.current?.click();
  }

  filesSelected() {
    if (this.fileInputRef?.current?.files?.length) {
      this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

  handleFiles(files: FileList) {
    if (files[0].name.includes(".doc") || files[0].name.includes(".DOC") || files[0].name.includes(".docx") || files[0].name.includes(".DOCX")) {
      this.props.onSelectFile(files);
    } else {
      this.props.showToast({
        type: "error",
        message: "invalid file type",
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Box display="flex" className={classes.boxOutline} id="boxOutline" onClick={this.fileInputClicked.bind(this)}>
          <Typography
            style={{
              width: "100%",
            }}
            variant="body1"
            noWrap
            className={classes.boldText}
          >
            {this.props.uploadMsg ? this.props.uploadMsg : ""}
          </Typography>
          <img src={UploadSvg} className={classes.uploadIcon} alt='mySvgImage' />
          <Typography variant="caption">{this.props.uploadSubMsg ? this.props.uploadSubMsg : ""}</Typography>
        </Box>
        <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          id="fileInput"
          type="file"
          accept={this.props.acceptedFormat}
          multiple={this.props.multiple}
          onChange={this.filesSelected.bind(this)}
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    fileInput: {
      display: "none",
    },
    boxOutline: {
      display: "flex",
      alignItems: "center",
      borderRadius: "0.50rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.09)",
      cursor: "pointer",
      width: "100%",
      padding: "1rem 0.85rem",
    },
    boldText: {
      cursor: "pointer",
      fontSize: "1rem",
      fontFamily: "Nunito Sans",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    uploadIcon: {
      height: "1.5rem",
    },
  });

export default withStyles(styles, { withTheme: true })(UploadBoxWhite);
