//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  showCollection: any;
  collectionData: any;
  showSection: any;
  sectionData: any;
  collectionId: any;
  sectionId: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HelpCenterController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCollectionId: string = "";
  getShowCollectionId: string = "";
  getListSectionId: string = "";
  getShowSectionId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showCollection: {},
      collectionData: {},
      showSection: {},
      sectionData: {},
      token: "",
      collectionId: null,
      sectionId: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getListCollectionId === apiRequestCallId) {
        this.handleListData(responseJson)
      }
      else if (this.getShowCollectionId === apiRequestCallId) {
        this.handleCollectionData(responseJson)
      }
      else if (this.getListSectionId === apiRequestCallId) {
        this.setState({ sectionData: responseJson })
      }
      else if (this.getShowSectionId === apiRequestCallId) {
        this.handleSectionOneData(responseJson)
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  handleListData = (responseJson: any) => {
    this.setState({ collectionData: responseJson })
  }
  handleCollectionData = (responseJson: any) => {
    this.setState({ showCollection: responseJson })
    this.props.history.push({
      pathname: "/home/helpcenter/collection",
      state: {
        showCollectionData: responseJson,
        collectionId:this.state.collectionId
      }
    })
  }

  handleSectionOneData = (responseJson: any) => {
    this.setState({ showSection: responseJson })
    this.props.history.push({
      pathname: "/home/helpcenter/collection/showsection",
      state: {
        showSectionOneData: responseJson,
        collectionId:this.state.collectionId
      }
    })
  }
  handleBackSectionButton = (id:any) => {
    this.props.history.push({
      pathname: "/home/helpcenter/collection",
      state: {
        showCollectionData: this.state.showCollection,
        collectionId:id
      }
    })
  }
  handleBackButtonHelp=()=>{
    this.props.history.push({
      pathname: "/home/helpcenter",
    })
  }
  listOfCollection = () => {
    const headers = {
      "content-type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getListCollectionId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${"bx_block_intercom/list_all_collection"}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  showOfCollection = (id: any) => {
    this.setState({collectionId:id})
    const headers = {
      "content-type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getShowCollectionId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${"bx_block_intercom/show_collection"}/${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  showOfSection = (id: any,sectionId:any) => {
    this.setState({collectionId:sectionId})
    const headers = {
      "content-type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getShowSectionId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${"bx_block_intercom/show_section"}/${id}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  listOfSection = () => {
    const headers = {
      "content-type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getListSectionId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${"bx_block_intercom/list_all_section"}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

}
