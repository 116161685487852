//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  MenuItem,
} from "@material-ui/core";
import CustomerGrowthChartDialogController, {
  Props,
} from "./CustomerGrowthChartDialogController.web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import MediumBlueField from "../../../../components/src/MediumBlueField.web";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { customerGrowthChartOptions } from "./FirmPerformanceRevenue.web";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { revenueGrowthTimePeriodOptions } from "./RevenueGrowthChartDialog.web";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export class NewDashboardChartDialog extends CustomerGrowthChartDialogController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getOnBoardedClientData(this.state.customerGrowthTimePeriod);
        }
      );
    }
  }
  handleTimeChange = (e) => {
    this.setState(
      {
        customerGrowthTimePeriod: e.target.value,
      },
      () => {
        this.getOnBoardedClientData(this.state.customerGrowthTimePeriod);
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ width: "70vw", padding: "2.25rem" }}>
        <Grid item xs={9}>
          <Typography variant="h2" className={classes.dialogHeading}>
            Customer Growth
          </Typography>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          <Grid item className={classes.textCard}>
            <Typography variant="h2" color="primary" display="inline">
              NA
            </Typography>
            <MediumBlueField
              onChange={this.handleTimeChange.bind(this)}
              select
              value={this.state.customerGrowthTimePeriod}
              style={{
                marginLeft: "1rem",
                display: "inline-block",
              }}
            >
              {revenueGrowthTimePeriodOptions.map((opt) => {
                return (
                  <MenuItem key={opt?.value} value={opt?.value}>
                    {opt?.name}
                  </MenuItem>
                );
              })}
            </MediumBlueField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              minHeight: "31.25rem",
            }}
          >
            <Line
              plugins={[ChartDataLabels]}
              data={this.state.customerGrowthLineData}
              options={customerGrowthChartOptions}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const NewDashboardChartDialogWithRouter = withRouter(NewDashboardChartDialog);
const NewDashboardChartDialogWithToast = withToast(
  NewDashboardChartDialogWithRouter
);
const NewDashboardChartDialogWithLoader = withLoader(
  NewDashboardChartDialogWithToast
);
const NewDashboardChartDialogWithAlertBox = withAlertBox(
  NewDashboardChartDialogWithLoader
);
const NewDashboardChartDialogWithDialogBox = withDialogBox(
  NewDashboardChartDialogWithAlertBox
);
export const dialogStyle = (theme) => {
  return {
    imgContainer: {
      borderRadius: "5px",
      backgroundColor: "#f5f5f5",
      maxHeight: "250px",
      height: "250px",
      overflowY: "auto",
    },
    fileInput: {
      display: "none",
    },
    crossOverImage: {
      right: "0px",
      top: "-2px",
      position: "absolute",
      backgroundColor: "#fff",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
    },
    dialogHeading: {
      fontSize: "1.4rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      margin: "1rem 0px",
    },
    textCard: {
      padding: "0.5rem 1rem",
      borderRadius: "0.75rem",
      backgroundColor: "#ffffff",
      boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.09)",
    },
  };
};

export default withStyles((theme) =>
  createStyles({
    ...dialogStyle(theme),
  })
)(NewDashboardChartDialogWithDialogBox);
