//@ts-nocheck
import React from "react";
import RolePermissionController, { Props } from "./RolePermissionController.web";
import { Typography, Grid, withStyles, createStyles } from "@material-ui/core";
import { blackEditIconPng } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { TableRowCustomized, StyledTableCell } from "../../LandingPage2/src/Settings/ManageUsers.web";
import moment from "moment";

export class RolePermission extends RolePermissionController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState(
        {
          token: token,
        },
        () => {
          this.getUsers();
        }
      );
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={3}>
              <Typography className={classes.pageHeading} color="primary" id="manage-users-heading">
                Manage Users
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginTop: "1.5rem",
          }}
        >
          <TableContainer
            style={{
              width: "100%",
              borderRadius: "16px",
              boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
            }}
          >
            <Table style={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRowCustomized>
                  <StyledTableCell width="46%">User List</StyledTableCell>
                  <StyledTableCell width="12%" align="center">
                    Joined
                  </StyledTableCell>
                  <StyledTableCell width="12%" align="center">
                    Subscription Plan
                  </StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    Sub Users
                  </StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    Clients
                  </StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    Manage User
                  </StyledTableCell>
                </TableRowCustomized>
              </TableHead>
              <TableBody>
                {this.state.usersList.length > 0 ? (
                  this.state.usersList.map((user) => (
                    <TableRowCustomized key={user.attributes.id}>
                      <StyledTableCell className={classes.nameOfUser}>{`${user.attributes.first_name} ${user.attributes.last_name}`}</StyledTableCell>
                      <StyledTableCell align="center" className={classes.nameOfUser}>{moment(user.attributes.created_at).format("MM-DD-YYYY")}</StyledTableCell>
                      <StyledTableCell align="center" className={classes.nameOfUser}>{this.formatPlan(user.attributes.product_type) || "Not Subscribed"}</StyledTableCell>
                      <StyledTableCell align="center" className={classes.nameOfUser}>{user.attributes.employee_count || "0"}</StyledTableCell>
                      <StyledTableCell align="center" className={classes.nameOfUser}>{user.attributes.client_count || "0"}</StyledTableCell>
                      <StyledTableCell align="center">
                        <img
                          src={blackEditIconPng}
                          alt="blackEditIconPng"
                          className={classes.editIconStyles}
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/adminDashboard/manageUsers/editUser",
                              state: { user: user },
                            });
                          }}
                        />
                      </StyledTableCell>
                    </TableRowCustomized>
                  ))
                ) : (
                  <TableRowCustomized>
                    <StyledTableCell colSpan={6} align="center">
                      No users available
                    </StyledTableCell>
                  </TableRowCustomized>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

const RolePermissionWithRouter = withRouter(RolePermission);
const RolePermissionAlertBox = withAlertBox(RolePermissionWithRouter);
const RolePermissionLoader = withLoader(RolePermissionAlertBox);
const RolePermissionToast = withToast(RolePermissionLoader);
const RolePermissionWithDialog = withDialog(RolePermissionToast);

export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontWeight: 600,
      fontSize: "1.5rem",
    }
  })
)(withConfirmBox(RolePermissionWithDialog));
