import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps,RouteComponentProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  RouteComponentProps &
  withLoaderProps & {
    id: string;
    classes : any
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  profile: any;
  uploadedImage: null | string;
  client : any
  clientId : null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientDetailsContainerController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateProfileApiCallId: string = "";
  getSingleClientApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      profile: {},
      uploadedImage: null,
      client : null,
      clientId : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSingleClientApiCallId) {
        this.props.hideLoader();
        if (responseJson?.data?.attributes) {
          this.setState({
            client: responseJson?.data?.attributes,
          });
        } else {
          this.handleApiError(responseJson)
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (AlertBodyMessage === 'Session TimeOut,Please Login Again.' || AlertBodyMessage === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }
  handleApiError = (responseJson:any) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  
  getSingleClient = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
