//@ts-nocheck
import React from "react";
import {
  Typography,
  withStyles,
  createStyles,
  TypographyProps,
} from "@material-ui/core";
import clsx from "clsx";

type CustomTypographyProps = TypographyProps & {
  otherColor?: React.CSSProperties["color"];
  fontSize?: React.CSSProperties["fontSize"];
  fontWeight?: React.CSSProperties["fontWeight"];
  margin?: React.CSSProperties["margin"];
  padding?: React.CSSProperties["padding"];
  children?: React.ReactNode;
};

class CustomTypography extends React.Component<CustomTypographyProps> {
  constructor(props: CustomTypographyProps) {
    super(props);
  }

  render() {
    const { classes, theme, otherColor, fontSize, fontWeight, margin, padding, className, ...rest } =
      this.props;

    const classList = [
      classes?.root,
      otherColor ? classes.otherColor : "",
      fontSize ? classes.fontSize : "",
      fontWeight ? classes.fontWeight : "",
      margin ? classes.margin : "",
      padding ? classes.padding : "",
    ];

    return (
      <Typography className={clsx(classList, className)} {...rest}>
        {this.props.children}
      </Typography>
    );
  }
}

export default withStyles(
  (theme) =>
    createStyles({
      root: {
        fontFamily: "Nunito Sans",
      },
      otherColor: {
        color: (props) => props?.otherColor,
      },
      fontSize: {
        fontSize: (props) => props?.fontSize,
      },
      padding: {
        padding: (props) => props?.padding,
      },
      margin: {
        margin: (props) => props?.margin,
      },
      fontWeight: {
        fontWeight: (props) => props?.fontWeight,
      },
    }),
  { withTheme: true }
)(CustomTypography);
