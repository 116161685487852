//@ts-nocheck

import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Theme,
  Collapse,
  TableCellProps,
  createStyles, WithStyles
} from "@material-ui/core";
import { LinkIcon } from "../assets";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter,RouteComponentProps } from "react-router";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TernaryCheck from "../TernaryCheck.web";
import withAlertBox, {
  withAlertBoxProps,
} from "../../../../components/src/withAlertBox.Web";
import withToast, {
  withToastProps,
} from "../../../../components/src/withSnackBar.Web";
import withLoader, {
  withLoaderProps,
} from "../../../../components/src/withLoader.Web";
import withDialog, {
  DialogProps,
} from "../../../../components/src/withDialog.web";
import { formatCurrency } from "./CustomPackageCreationSelectFeaturesPage.web";

export const getComponentStyle = (theme: Theme): any => {
  return createStyles({
    columnsHeadingForGoal: {
      fontFamily: "Lato",
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      fontWeight: "bold",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.5rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: theme.palette.primary.main,
      fontWeight: 600,
      margin: "0.5rem 0",
    },
    listPrimary: {
      fontFamily: "Nunito Sans",
      fontSize: "1.2rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    listSecondary: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.13,
      letterSpacing: "normal",
      color: theme.palette.text.secondary,
      fontWeight: 500,
    },
    buttonContainer: {
      display: "flex",
      gap: "1.5rem",
    },
    expandButtonBox: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0.25rem",
      padding: "0.25rem",
      color: "#fff",
      marginLeft: "1rem",
      marginBottom: "1rem",
      cursor: "pointer",
    },
    containerTable: {
      width: "100%",
      borderRadius: "16px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    buttonLikeGrid: {
      fontSize: '1.1rem',
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.primary.main,
      padding: '0.5rem',
      color: '#fff',
      fontWeight: 'bold',
      textAlign: 'center'
    }
  });
};
export type Props = RouteComponentProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  WithStyles<typeof getComponentStyle> & {
    id: string;

    // Customizable Area Start
    // Customizable Area End
  };

type StyledTableCellProps = TableCellProps & {
  color?: React.CSSProperties["color"];
  textcolor?: React.CSSProperties["color"];
  align?: React.CSSProperties["align"];
};

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
      borderColor: "#e9e9e9",
    },
    head: {
      backgroundColor: (props: StyledTableCellProps) =>
        props.color ? props.color : theme.palette.common.white,
      color: (props: StyledTableCellProps) =>
        props.textcolor ? props.textcolor : theme.palette.text.secondary,
      textAlign: (props: StyledTableCellProps) =>
        props.align ? props.align : "start",
      fontSize: "1rem",
      fontWeight: "bold",
      border: "none",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
    body: {
      backgroundColor: (props: StyledTableCellProps) =>
        props.color ? props.color : theme.palette.common.white,
      color: (props: StyledTableCellProps) =>
        props.textcolor ? props.textcolor : theme.palette.text.secondary,
      textAlign: (props: StyledTableCellProps) =>
        props.align ? props.align : "start",
      fontSize: "0.95rem",
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
        borderBottomLeftRadius: "1rem",
      },
      "&:last-of-type": {
        borderTopRightRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
  })
)(TableCell);

export const TableRowCustomized = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-of-type": {
        "& td": {
          border: "none",
        },
      },
    },
  })
)(TableRow);

export class CustomPackageCreationSummary extends React.PureComponent {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEngagementTableVisible : true,
      isBumpTableVisible : true
    }
  }
  
  async componentDidMount() {
    const params = new URLSearchParams(this.props.history.location.search);
    const account_id = params.get("account_id");
    this.setState({
      accountId: account_id,
    });
  }

  handleExpandButtonClick = (stateName) => {
    this.setState((prevState) => ({
      [stateName]: !prevState[stateName],
    }));
  };
  getOneTimeFess = (hour,stratagic_rate,default_cost) => {
    let featuresPriceTotal = 0;
    if (hour) {
      featuresPriceTotal += hour * stratagic_rate;
    } else {
      featuresPriceTotal += default_cost;
    }
    return featuresPriceTotal;
  }
  getRecurringFess = (payment_frequency,hour,stratagic_rate,default_cost) => {
    let featuresPriceTotal = 0;
    if (payment_frequency == "Monthly") {
      if (hour) {
        featuresPriceTotal +=
          hour *
          (stratagic_rate) *
          +this.props.contract_period;
      } else {
        featuresPriceTotal +=
          default_cost * +this.props.contract_period;
      }
    } else if (payment_frequency == "Quarterly") {
      if (hour) {
        featuresPriceTotal +=
          hour *
          (stratagic_rate) *
          (+this.props.contract_period / 3);
      } else {
        featuresPriceTotal +=
          default_cost * (+this.props.contract_period / 3);
      }
    } else if (payment_frequency == "Yearly" || payment_frequency == "Annually") {
      if (hour) {
        featuresPriceTotal +=
          hour *
          (stratagic_rate) *
          (+this.props.contract_period / 12);
      } else {
        featuresPriceTotal +=
          default_cost * (+this.props.contract_period / 12);
      }
    }
    return featuresPriceTotal;
  }
  renderSelectedFeature = () => {
    let totalContractValue = 0;
    const featuresToRender = this.props.custom_package_features.filter((a)=>{return !a?._destroy}).map(
      (feature: any) => {
        let featuresPriceTotal = 0;
        const stratagic_rate = +feature?.stratagic_rate || +this.props?.stratagic_rate;
        const default_cost = +feature?.default_cost;
        const payment_period = feature?.payment_period
        const payment_frequency = feature?.payment_frequency
        const hour = +feature?.hour
        if(!feature._destroy){
          if (payment_period == "One Time") {
            featuresPriceTotal += this.getOneTimeFess(hour,stratagic_rate,default_cost)
          } else if (payment_period == "Recurring") {
            featuresPriceTotal += this.getRecurringFess(payment_frequency,hour,stratagic_rate,default_cost) 
          }
        }
        totalContractValue += +featuresPriceTotal;

        return (
          <TableRowCustomized key={feature.feature_id}>
            <StyledTableCell>
              {feature.feature_name_new || feature.feature_name }
            </StyledTableCell>
            <StyledTableCell>
              $ {formatCurrency.format(featuresPriceTotal)}
            </StyledTableCell>
          </TableRowCustomized>
        );
      }
    )
    return {featuresToRender,totalContractValue}
  }
  renderSelectedBump = () => {
    let totalBumpCost = 0;
    const bumpToRender = this.props.custom_package_features.map((feature) => {
      if(!feature._destroy){
        return feature.custom_package_bumps_attributes.map(
          (bump, bumpIndex) => {
            totalBumpCost +=
              (+feature.custom_package_bumps_attributes[
                bumpIndex
              ].bump_multiplier || 1) *
              (+feature.custom_package_bumps_attributes[
                bumpIndex
              ].bump_amount);
            return (
              <TableRowCustomized key={bump?.id}>
                <StyledTableCell>
                  {bump.bump_name_new}
                </StyledTableCell>
                <StyledTableCell>
                  X {bump.bump_multiplier}
                </StyledTableCell>
                <StyledTableCell>
                  $ {bump.bump_amount}
                </StyledTableCell>
                <StyledTableCell>
                  ${" "}
                  {(+feature.custom_package_bumps_attributes[
                    bumpIndex
                  ].bump_multiplier || 1) *
                    (+feature.custom_package_bumps_attributes[
                      bumpIndex
                    ].bump_amount)}
                </StyledTableCell>
              </TableRowCustomized>
            );
          }
        );
      }
      
    })
    return {totalBumpCost,bumpToRender}
  }

  render() {
    const { classes } = this.props;
    const {featuresToRender,totalContractValue} = this.renderSelectedFeature()
    const {bumpToRender,totalBumpCost}  = this.renderSelectedBump()
    return (
      <Grid container style={{ marginBottom: "48px" }} spacing={3}>
        <Grid xs={12} item>
          <Typography color="primary" variant="h1">
            Custom Package Summary
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={5}>
              <Grid container>
                <Grid xs={12} item>
                  <ListItem style={{ padding: 0 }}>
                    <ListItemText
                      classes={{
                        primary: classes.listPrimary,
                        secondary: classes.listSecondary,
                      }}
                      primary="Name"
                      secondary={this.props.name}
                    />
                  </ListItem>
                </Grid>
                <Grid xs={12} item>
                  <ListItem style={{ padding: 0 }}>
                    <ListItemText
                      classes={{
                        primary: classes.listPrimary,
                        secondary: classes.listSecondary,
                      }}
                      primary="Description"
                      secondary={this.props.description}
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1}>
                <Grid xs={10} item>
                  <Grid container alignItems="center">
                    <Grid xs={5} item>
                      <Typography
                        color="primary"
                        className={classes.listPrimary}
                      >
                        Strategic Rate
                      </Typography>
                    </Grid>
                    <Grid xs={7} item>
                      <Typography className={classes.listSecondary}>
                        $ {formatCurrency.format(this.props.stratagic_rate)} per hour
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={10} item>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <Typography
                        color="primary"
                        className={classes.listPrimary}
                      >
                        Contract Period
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.listSecondary}>
                        {this.props.contract_period} months
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={10} item>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                    <Typography
                        color="primary"
                        className={classes.listPrimary}
                        display="inline"
                      >
                        Package Details Link
                      </Typography>
                      <img height={8} width={8} style={{marginLeft : '4px'}} src={LinkIcon} />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.listSecondary}>
                        {this.props.package_detail_link || "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container spacing={1}>
            <Grid xs={12} item container alignItems="center">
              <CustomTypography
                color="primary"
                margin="0 0 1rem 0"
                variant="h1"
              >
                Engagement Features
              </CustomTypography>
              <TernaryCheck
                condition={this.state.isEngagementTableVisible}
                trueComponent={
                  <ExpandLessIcon
                    className={classes.expandButtonBox}
                    onClick={this.handleExpandButtonClick.bind(
                      this,
                      "isEngagementTableVisible"
                    )}
                  />
                }
                falseComponent={
                  <ExpandMoreIcon
                    className={classes.expandButtonBox}
                    onClick={this.handleExpandButtonClick.bind(
                      this,
                      "isEngagementTableVisible"
                    )}
                  />
                }
              />
            </Grid>
            <Collapse
              in={this.state.isEngagementTableVisible}
              timeout="auto"
              unmountOnExit
              style={{
                width: "100%",
              }}
            >
              <Grid xs={12} item>
                <TableContainer className={classes.containerTable}>
                  <Table
                    style={{ width: "100%" }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          color={"#f9f9f9"}
                          width="85%"
                          className={classes.columnsHeadingForGoal}
                        >
                          Feature Name
                        </StyledTableCell>
                        <StyledTableCell
                          color={"#f9f9f9"}
                          width="15%"
                          className={classes.columnsHeadingForGoal}
                        >
                          Feature Cost
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {featuresToRender}
                      <TableRowCustomized>
                        <StyledTableCell>
                          <CustomTypography
                            color="primary"
                            fontWeight={700}
                            fontSize="1rem"
                          >
                            Total Feature Cost
                          </CustomTypography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomTypography
                            color="primary"
                            fontWeight={700}
                            fontSize="1rem"
                          >
                            $ {formatCurrency.format(totalContractValue)}
                          </CustomTypography>
                        </StyledTableCell>
                      </TableRowCustomized>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container spacing={1}>
            <Grid xs={12} item container alignItems="center">
              <CustomTypography
                color="primary"
                margin="0 0 1rem 0"
                variant="h1"
              >
                Bump Summary
              </CustomTypography>
              <TernaryCheck
                condition={this.state.isBumpTableVisible}
                trueComponent={
                  <ExpandLessIcon
                    className={classes.expandButtonBox}
                    onClick={this.handleExpandButtonClick.bind(
                      this,
                      "isBumpTableVisible"
                    )}
                  />
                }
                falseComponent={
                  <ExpandMoreIcon
                    className={classes.expandButtonBox}
                    onClick={this.handleExpandButtonClick.bind(
                      this,
                      "isBumpTableVisible"
                    )}
                  />
                }
              />
            </Grid>
            <Collapse
              in={this.state.isBumpTableVisible}
              timeout="auto"
              unmountOnExit
              style={{
                width: "100%",
              }}
            >
              <Grid xs={12} item>
                <TableContainer className={classes.containerTable}>
                  <Table
                    style={{ width: "100%" }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          color={"#f9f9f9"}
                          width="55%"
                          className={classes.columnsHeadingForGoal}
                        >
                          Bump Type
                        </StyledTableCell>
                        <StyledTableCell
                          color={"#f9f9f9"}
                          width="15%"
                          className={classes.columnsHeadingForGoal}
                        >
                          Bump Multiplier
                        </StyledTableCell>
                        <StyledTableCell
                          color={"#f9f9f9"}
                          width="15%"
                          className={classes.columnsHeadingForGoal}
                        >
                          Bump Amount
                        </StyledTableCell>
                        <StyledTableCell
                          color={"#f9f9f9"}
                          width="15%"
                          className={classes.columnsHeadingForGoal}
                        >
                          Bump Cost
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                       {bumpToRender}
                      <TableRowCustomized>
                        <StyledTableCell colSpan={3}>
                          <CustomTypography
                            color="primary"
                            fontWeight={700}
                            fontSize="1rem"
                          >
                            Total Bump Cost
                          </CustomTypography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <CustomTypography
                            color="primary"
                            fontWeight={700}
                            fontSize="1rem"
                          >
                            $ {formatCurrency.format(totalBumpCost)}
                          </CustomTypography>
                        </StyledTableCell>
                      </TableRowCustomized>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container justifyContent="center">
            <Grid item xs={4} className={classes.buttonLikeGrid}>
                Total Contract Value: $ {formatCurrency.format(this.props.totalContractValue)}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <CustomTypography
                color="primary"
                margin="0 0 1rem 0"
                variant="h1"
              >
                Payment Terms
              </CustomTypography>
              <CustomTypography
                color="textSecondary"
                margin="0 0 0.5rem 0"
                fontSize="1.1rem"
                fontWeight={700}
              >
                Monthly Pricing Option
              </CustomTypography>
            </Grid>

            <Grid xs={12} item>
              <TableContainer className={classes.containerTable}>
                <Table style={{ width: "100%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        color={"#f9f9f9"}
                        width="85%"
                        className={classes.columnsHeadingForGoal}
                      >
                        Total Contract Value:
                      </StyledTableCell>
                      <StyledTableCell
                        color={"#f9f9f9"}
                        width="15%"
                        className={classes.columnsHeadingForGoal}
                      >
                        $ {formatCurrency.format(this.props.totalContractValue)}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRowCustomized>
                      <StyledTableCell>Contract Period</StyledTableCell>
                      <StyledTableCell>
                        {this.props.contract_period} Months
                      </StyledTableCell>
                    </TableRowCustomized>
                    <TableRowCustomized>
                      <StyledTableCell>
                        Up Front Amount to be Collected
                      </StyledTableCell>
                      <StyledTableCell>
                        ${" "}
                        {this.props.unfront_payment_type == "percentage"
                          ? formatCurrency.format(this.props.totalContractValue * (this.props.unfront_percentage / 100))
                          : formatCurrency.format(this.props.unfront_lump_suump_amount)}
                      </StyledTableCell>
                    </TableRowCustomized>
                    <TableRowCustomized>
                      <StyledTableCell>
                        Ongoing Fees after Upfront Payment
                      </StyledTableCell>
                      <StyledTableCell>
                        $ {formatCurrency.format(this.props.totalContractValueAfterRemovingUnfrontAmount)}
                      </StyledTableCell>
                    </TableRowCustomized>

                    <TableRowCustomized>
                      <StyledTableCell>
                        <CustomTypography
                          color="primary"
                          align="right"
                          fontWeight={700}
                          fontSize="1.1rem"
                        >
                          Monthly Payment
                        </CustomTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <CustomTypography
                          color="primary"
                          fontWeight={700}
                          fontSize="1.1rem"
                        >
                          $ {formatCurrency.format(this.props.totalContractValueAfterRemovingUnfrontAmount / 12)}
                        </CustomTypography>
                      </StyledTableCell>
                    </TableRowCustomized>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid xs={12} item>
              <CustomTypography
                color="textSecondary"
                margin="1rem 0 0.5rem 0"
                fontSize="1.1rem"
                fontWeight={700}
              >
                Quarterly Pricing Option
              </CustomTypography>
            </Grid>

            <Grid xs={12} item>
              <TableContainer className={classes.containerTable}>
                <Table style={{ width: "100%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        color={"#f9f9f9"}
                        width="85%"
                        className={classes.columnsHeadingForGoal}
                      >
                        Total Contract Value:
                      </StyledTableCell>
                      <StyledTableCell
                        color={"#f9f9f9"}
                        width="15%"
                        className={classes.columnsHeadingForGoal}
                      >
                        $ {formatCurrency.format(this.props.totalContractValue)}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRowCustomized>
                      <StyledTableCell>Contract Period</StyledTableCell>
                      <StyledTableCell>
                        {this.props.contract_period} Months
                      </StyledTableCell>
                    </TableRowCustomized>
                    <TableRowCustomized>
                      <StyledTableCell>
                        Up Front Amount to be Collected
                      </StyledTableCell>
                      <StyledTableCell>
                        ${" "}
                        {this.props.unfront_payment_type == "percentage"
                          ? formatCurrency.format(this.props.totalContractValue *
                            (this.props.unfront_percentage / 100))
                          : formatCurrency.format(this.props.unfront_lump_suump_amount)}{" "}
                      </StyledTableCell>
                    </TableRowCustomized>
                    <TableRowCustomized>
                      <StyledTableCell>
                        Ongoing Fees after Upfront Payment
                      </StyledTableCell>
                      <StyledTableCell>
                        ${" "}
                        {
                          formatCurrency.format(this.props
                            .totalContractValueAfterRemovingUnfrontAmount)
                        }{" "}
                      </StyledTableCell>
                    </TableRowCustomized>

                    <TableRowCustomized>
                      <StyledTableCell>
                        <CustomTypography
                          color="primary"
                          align="right"
                          fontWeight={700}
                          fontSize="1.1rem"
                        >
                          Quarterly Payment
                        </CustomTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <CustomTypography
                          color="primary"
                          fontWeight={700}
                          fontSize="1.1rem"
                        >
                          ${" "}
                          {formatCurrency.format(this.props
                            .totalContractValueAfterRemovingUnfrontAmount / 4)}
                        </CustomTypography>
                      </StyledTableCell>
                    </TableRowCustomized>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid xs={12} item>
              <CustomTypography
                color="textSecondary"
                margin="1rem 0 0.5rem 0"
                fontSize="1.1rem"
                fontWeight={700}
              >
                Yearly Pricing Option
              </CustomTypography>
            </Grid>

            <Grid xs={12} item>
              <TableContainer className={classes.containerTable}>
                <Table style={{ width: "100%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        color={"#f9f9f9"}
                        width="85%"
                        className={classes.columnsHeadingForGoal}
                      >
                        Total Contract Value:
                      </StyledTableCell>
                      <StyledTableCell
                        color={"#f9f9f9"}
                        width="15%"
                        className={classes.columnsHeadingForGoal}
                      >
                        $ {formatCurrency.format(this.props.totalContractValue)}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRowCustomized>
                      <StyledTableCell>Contract Period</StyledTableCell>
                      <StyledTableCell>
                        {this.props.contract_period} Months
                      </StyledTableCell>
                    </TableRowCustomized>
                    <TableRowCustomized>
                      <StyledTableCell>
                        Up Front Amount to be Collected
                      </StyledTableCell>
                      <StyledTableCell>
                        ${" "}
                        {this.props.unfront_payment_type == "percentage"
                          ? formatCurrency.format(this.props.totalContractValue *
                            (this.props.unfront_percentage / 100))
                          : formatCurrency.format(this.props.unfront_lump_suump_amount)}{" "}
                      </StyledTableCell>
                    </TableRowCustomized>
                    <TableRowCustomized>
                      <StyledTableCell>
                        Ongoing Fees after Upfront Payment
                      </StyledTableCell>
                      <StyledTableCell>
                        ${" "}
                        {
                          formatCurrency.format(this.props
                            .totalContractValueAfterRemovingUnfrontAmount)
                        }{" "}
                      </StyledTableCell>
                    </TableRowCustomized>

                    <TableRowCustomized>
                      <StyledTableCell>
                        <CustomTypography
                          color="primary"
                          align="right"
                          fontWeight={700}
                          fontSize="1.1rem"
                        >
                          Yearly Payment
                        </CustomTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <CustomTypography
                          color="primary"
                          fontWeight={700}
                          fontSize="1.1rem"
                        >
                          ${" "}
                          {formatCurrency.format(this.props
                            .totalContractValueAfterRemovingUnfrontAmount)}
                        </CustomTypography>
                      </StyledTableCell>
                    </TableRowCustomized>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Grid xs={2} container item>
              <Button
                onClick={this.props.goToPreviousStep.bind(this)}
                fullWidth
                variant="outlined"
                color="primary"
              >
                Back
              </Button>
            </Grid>
            <Grid xs={3} container item>
              <Button
                onClick={this.props.createCustomPackage}
                fullWidth
                variant="contained"
                color="primary"
              >
                {this.props.packageType ? 'Update Package' : "Add Custom Package to your firm"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const CustomPackageCreationSummaryWithRouter = withRouter(
  CustomPackageCreationSummary
);
const CustomPackageCreationSummaryWithToast = withToast(
  CustomPackageCreationSummaryWithRouter
);
const CustomPackageCreationSummaryWithLoader = withLoader(
  CustomPackageCreationSummaryWithToast
);
const CustomPackageCreationSummaryWithAlertBox = withAlertBox(
  CustomPackageCreationSummaryWithLoader
);
const CustomPackageCreationSummaryWithDialogBox = withDialog(
  CustomPackageCreationSummaryWithAlertBox
);

export default withStyles((theme: Theme) => getComponentStyle(theme))(
  CustomPackageCreationSummaryWithDialogBox
);
