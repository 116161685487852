//@ts-nocheck
import React from "react";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { withStyles, Grid, createStyles, Button } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Redirect } from "react-router-dom";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { ForgotPasswordContainer } from "./ForgotPasswordOTP.web";
import { authFlowStyles } from "./Login.web";

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
});

export class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return this.state.token ? (
      <Redirect to="/home" />
    ) : (
      <Formik
        initialValues={{
          email: this.props.history.location?.state?.values?.email || "",
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          this.setState({
            formValues: values,
          });
          this.sendVerificationCode(values);
        }}
      >
        {(formikProps) => {
          const { isValid, dirty } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <ForgotPasswordContainer {...formikProps} classes={classes}>
                <Grid item xs={10}>
                  <Field type="email" component={InputField} variant="outlined" fullWidth name="email" placeholder="Email Address" />
                </Grid>

                <Grid item xs={10}>
                  <Button color="primary" type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)}>
                    Send Verification Code
                  </Button>
                </Grid>
              </ForgotPasswordContainer>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ForgotPasswordWithRouter = withRouter(ForgotPassword);
const ForgotPasswordWithToast = withToast(ForgotPasswordWithRouter);
const ForgotPasswordWithLoader = withLoader(ForgotPasswordWithToast);
const ForgotPasswordWithAlertBox = withAlertBox(ForgotPasswordWithLoader);
const ForgotPasswordWithDialogBox = withDialog(ForgotPasswordWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
  })
)(ForgotPasswordWithDialogBox);
