//@ts-nocheck
import React from "react";
import { Typography, Button, Grid, Box, withStyles, createStyles, TextField, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form } from "formik";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { spaceMask, mobileNumberMask } from "../masks";
import { roadmapSchema } from "../roadmap/RoadMapCustomization2.web";
import StartRoadMapController, { Props } from "../roadmap/StartRoadMapController.web";

const swatchColors = ["#6EA6E8", "#74c283", "#f9d949", "#f59540", "#f66c6c", "#f08bb9", "#696cd4", "#f5f5f5", "#6d6d6d"];

export class Branding extends StartRoadMapController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getBranding();
      }
    );
  }

  renderColor = (classes) => {
    return (
      <>
        <Grid item md={5} xs={12}>
          <Grid container>
            <Grid item md={12}>
              <Typography className={classes.customLabel}>Add Primary Color</Typography>
              <Grid container className={classes.whiteCard}>
                <Typography className={classes.swatchTitle}>PICK A SWATCH:</Typography>
                <Grid item xs={12}>
                  {swatchColors.map((color) => {
                    return (
                      <Box
                        key={color}
                        className={classes.colorSwatch}
                        onClick={this.handlePrimaryColorChange.bind(this, color)}
                        style={{
                          backgroundColor: color,
                        }}
                      />
                    );
                  })}
                </Grid>
                <TextField
                  variant="outlined"
                  name="primaryColor"
                  value={this.state.primaryColor}
                  onChange={this.handleColorInputChange}
                  placeholder="Enter Hex Code"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FiberManualRecordIcon
                          style={{
                            fill: this.state.primaryColor,
                          }}
                          size="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <Grid container>
            <Grid item md={12}>
              <Typography className={classes.customLabel}>Add Secondary Color</Typography>
              <Grid container className={classes.whiteCard}>
                <Typography className={classes.swatchTitle}>PICK A SWATCH:</Typography>
                <Grid item xs={12}>
                  {swatchColors.map((color) => {
                    return (
                      <Box
                        key={color}
                        className={classes.colorSwatch}
                        onClick={this.handleSecondaryColorChange.bind(this, color)}
                        style={{
                          backgroundColor: color,
                        }}
                      />
                    );
                  })}
                </Grid>
                <TextField
                  variant="outlined"
                  name="secondaryColor"
                  value={this.state.secondaryColor}
                  onChange={this.handleColorInputChange}
                  placeholder="Enter Hex Code"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FiberManualRecordIcon
                          style={{
                            fill: this.state.secondaryColor,
                          }}
                          size="small"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  renderSaveButton = (classes, isValid) => {
    return (
      <Grid container justify="flex-end" className={classes.footerMargin}>
        <Grid item md={3} xs={12}>
          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  renderEmail = (values, handleChange, errors, touched) => {
    return (
      <Grid item md={5} xs={12}>
        <Field
          helperText={errors.email && touched.email ? errors.email : ""}
          error={errors.email && touched.email ? errors.email : ""}
          component={InputField}
          name="email"
          label="Firm Email"
          onChange={(e) => handleChange(spaceMask(e))}
          value={values.email}
          fullWidth
        />
      </Grid>
    );
  };

  renderFirmDetail = (values, handleChange, errors, touched) => {
    return (
      <>
        <Grid item md={5} xs={12}>
          <Field
            helperText={errors.firm_name && touched.firm_name ? errors.firm_name : ""}
            error={errors.firm_name && touched.firm_name ? errors.firm_name : ""}
            component={InputField}
            name="firm_name"
            label="Firm Name"
            onChange={(e) => handleChange(spaceMask(e))}
            value={values.firm_name}
            fullWidth
          />
        </Grid>
        {this.renderEmail(values, handleChange, errors, touched)}
        <Grid item md={5} xs={12}>
          <Field
            helperText={errors.phone_number && touched.phone_number ? errors.phone_number : ""}
            error={errors.phone_number && touched.phone_number ? errors.phone_number : ""}
            component={InputField}
            name="phone_number"
            label="Firm Phone Number"
            onChange={(e) => handleChange(mobileNumberMask(e))}
            value={values.phone_number}
            fullWidth
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <Field
            helperText={errors.web_url && touched.web_url ? errors.web_url : ""}
            error={errors.web_url && touched.web_url ? errors.web_url : ""}
            component={InputField}
            name="web_url"
            label="Enter Web URL"
            onChange={(e) => handleChange(spaceMask(e))}
            value={values.web_url}
            fullWidth
          />
        </Grid>
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          email: this.state?.email,
          firm_name: this.state?.firm_name,
          phone_number: this.state?.phone_number,
          web_url: this.state?.web_url,
        }}
        validationSchema={roadmapSchema}
        enableReinitialize
        onSubmit={this.createAndUpdateBranding}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue, setFieldTouched, isValid, errors, touched } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item md={12}>
                  <Typography className={classes.title}>Branding</Typography>
                  <Typography className={classes.subTitle}>Update your logo,colors and contact information below</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle2" className={classes.customLabel}>
                    Add Logo
                  </Typography>
                  {this.showCorrectImage(values, setFieldValue, setFieldTouched, errors, touched)}
                </Grid>
                <Grid item md={6} />
                {this.renderColor(classes, values, setFieldValue, handleChange, errors, touched)}
                {this.renderFirmDetail(values, handleChange, errors, touched)}
              </Grid>
              {this.renderSaveButton(classes, isValid)}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const BrandingWithRouter = withRouter(Branding);
const BrandingAlertBox = withAlertBox(BrandingWithRouter);
const BrandingLoader = withLoader(BrandingAlertBox);
const BrandingToast = withToast(BrandingLoader);
const BrandingWithDialog = withDialog(BrandingToast);

export const style = (theme) => {
  return {
    whiteCard: {
      padding: "2rem",
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
      boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
    },
    title: {
      fontFamily: "Nunito Sans",
      fontSize: "1.75rem",
      color: "#54A4A6",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      marginBottom: "1rem",
    },
    subTitle: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      color: theme.palette.text.secondary,
    },
    customLabel: {
      fontFamily: "Nunito Sans",
      fontSize: "1.6rem",
      fontWeight: 700,
      color: theme.palette.text.blue2,
      marginBottom: "1rem",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: "red",
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    footerMargin: {
      margin: theme.spacing(6, 0),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    swatchTitle: {
      fontSize: "1rem",
      marginBottom: "0.75rem",
      color: theme.palette.text.secondary,
    },
    colorSwatch: {
      borderRadius: "100%",
      width: "2rem",
      height: "2rem",
      marginRight: "0.5rem",
      marginBottom: "0.5rem",
      display: "inline-block",
      cursor: "pointer",
    },
  }
}


export default withStyles((theme) =>
  createStyles({
    ...style(theme)
  })
)(withConfirmBox(BrandingWithDialog));
