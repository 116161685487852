//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Button, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
export class TakeQuiz extends React.Component {
  
    state = {
      q1: {
        tag: "Business",
        value: "",
      },
      q2: {
        tag: "Accounting",
        value: "",
      },
      q3: {
        tag: "Payroll",
        value: "",
      },
      q4: {
        tag: "New Business",
        value: "",
      },
      q5: {
        tag: "IRS",
        value: "",
      },
      q6: {
        tag: "Advisory",
        value: "",
      },
    };
  

  componentDidMount() {
    this.setState({
      q1: this.props?.dataToPass?.values?.q1 || "",
      q2: this.props?.dataToPass?.values?.q2 || "",
      q3: this.props?.dataToPass?.values?.q3 || "",
      q4: this.props?.dataToPass?.values?.q4 || "",
      q5: this.props?.dataToPass?.values?.q5 || "",
      q6: this.props?.dataToPass?.values?.q6 || "",
    });
  }

  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  quizChangeHandler = (e) => {
    this.setState(
      {
        [e.target.name]: {
          ...this.state[e.target.name],
          value: e.target.value,
        },
      },
      () => {
        if (this.state.q1.value === "No") {
          this.setState({
            q2: {
              tag: "Accounting",
              value: "No",
            },
            q3: {
              tag: "Payroll",
              value: "No",
            },
          });
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container justifyContent="space-between" spacing={4} className={classes.rootContainer}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h2">
            Answer these questions to see recommended packages.
          </Typography>
        </Grid>
        <Grid item xs={12} container alignItems="center" justifyContent="space-between">
          <Typography className={classes.formLabel}>1. Does the client currently own business?</Typography>
          <RadioGroup className={classes.radioGroup} row name="q1" value={this.state.q1?.value} id="businessRadioGroup" onChange={this.quizChangeHandler}>
            <FormControlLabel
              className={classes.yesRadio}
              value="Yes"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="Yes"
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              className={classes.noRadio}
              value="No"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="No"
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
          <Typography className={classes.formLabel}>2. Does the client need help with accounting?</Typography>
          <RadioGroup className={classes.radioGroup} row name="q2" value={this.state.q2?.value} onChange={this.quizChangeHandler}>
            <FormControlLabel
              className={classes.yesRadio}
              value="Yes"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="Yes"
              disabled={this.state.q1?.value === "No"}
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              className={classes.noRadio}
              value="No"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="No"
              disabled={this.state.q1?.value === "No"}
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
          <Typography className={classes.formLabel}>3. Does the client need help with payroll?</Typography>
          <RadioGroup className={classes.radioGroup} row name="q3" value={this.state.q3?.value} onChange={this.quizChangeHandler}>
            <FormControlLabel
              className={classes.yesRadio}
              value="Yes"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="Yes"
              disabled={this.state.q1?.value === "No"}
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              className={classes.noRadio}
              value="No"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="No"
              disabled={this.state.q1?.value === "No"}
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
          <Typography className={classes.formLabel}>4. Does the client want to start a new business?</Typography>
          <RadioGroup className={classes.radioGroup} row name="q4" value={this.state.q4?.value} onChange={this.quizChangeHandler}>
            <FormControlLabel
              className={classes.yesRadio}
              value="Yes"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="Yes"
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              className={classes.noRadio}
              value="No"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="No"
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
          <Typography className={classes.formLabel}>5. Does the client have IRS issue?</Typography>
          <RadioGroup className={classes.radioGroup} row name="q5" value={this.state.q5?.value} onChange={this.quizChangeHandler}>
            <FormControlLabel
              className={classes.yesRadio}
              value="Yes"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="Yes"
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              className={classes.noRadio}
              value="No"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="No"
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
          <Typography className={classes.formLabel}>6. Does the client want strategic advisory help?</Typography>
          <RadioGroup className={classes.radioGroup} row name="q6" value={this.state.q6?.value} onChange={this.quizChangeHandler}>
            <FormControlLabel
              className={classes.yesRadio}
              value="Yes"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="Yes"
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              className={classes.noRadio}
              value="No"
              control={<Radio color="primary" icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} />}
              label="No"
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                this.closeDialog(this.state);
              }}
              disabled={
                !this.state.q1.value || !this.state.q2.value || !this.state.q3.value || !this.state.q4.value || !this.state.q5.value || !this.state.q6.value
              }
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const TakeQuizWithRouter = withRouter(TakeQuiz);
const TakeQuizWithToast = withToast(TakeQuizWithRouter);
const TakeQuizWithLoader = withLoader(TakeQuizWithToast);
const TakeQuizWithAlertBox = withAlertBox(TakeQuizWithLoader);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      padding: "2rem",
      color: theme.palette.text.blue,
    },
    formLabel: {
      fontSize: "1.25rem",
      fontWeight: 600,
      color: theme.palette.text.blue2,
    },
    yesRadio: {
      paddingRight: 10,
      paddingLeft: 10,
      "& .MuiIconButton-label": {
        color: "#89c0c1",
      },
    },
    noRadio: {
      "& .MuiIconButton-label": {
        color: "#ffa3a3",
      },
    },
    radioLabel: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
    },
  })
)(TakeQuizWithAlertBox);
