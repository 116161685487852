//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import withDialogBox from "../../../components/src/withDialog.web";
import { LightenDarkenColor } from "./utils";
import PlusMinusButton from "../../../components/src/PlusMinusButton.web";

export class AddSignatureDialog extends React.Component {
  state = {
    signatureCount: 1,
  };
  handleDecreaseSignatureCount = () => {
    this.setState({
      signatureCount: this.state.signatureCount - 1,
    });
  };
  handleIncrementSignatureCount = () => {
    this.setState({
      signatureCount: this.state.signatureCount + 1,
    });
  };
  checkout = (data) => {
    const { onSubmit } = this.props;
    onSubmit({signatureCount:this.state.signatureCount});
  };
  closeDialog = () => {
    const { onSubmit } = this.props;
    onSubmit();
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            align="center"
            className={classes.headingText}
          >
            Add Signature
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item  style={{textAlign:'center'}} xs={12}>
              <Typography
                display="inline"
                align="center"
                color="textPrimary"
                style={{
                  fontSize: "1.5rem",
                }}
              >
                Signature Bundles $22 for{" "}
                <PlusMinusButton
                  initialValue={this.state.signatureCount}
                  disableValue={1}
                  isDisable={false}
                  iconStyle={{width:'2rem',height:'2rem'}}
                  padding={'6px'}
                  onDecrement={this.handleDecreaseSignatureCount}
                  onIncrement={this.handleIncrementSignatureCount}
                />{" "}
                Automated Client Enrollments (per bundle 20)
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={4}
          container
          justifyContent="center"
          className={classes.buttonContainer}
        >
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.uploadNowButton}
            id="closeDialog"
            onClick={this.closeDialog}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={[classes.uploadNowButton]}
            id="addSignature"
            onClick={this.checkout}
          >
            Add Signature
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const AddSignatureDialogWithDialogBox = withDialogBox(AddSignatureDialog);

export default withStyles((theme) =>
  createStyles({
    featureCard: {
      flexGrow: 0,
      maxWidth: "30%",
      flexBasis: "30%",
      margin: "0.5rem 1.5%",
      backgroundColor: "#fff",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      borderRadius: "1.25rem",
      padding: "1.1rem",
    },
    redCloseIcon: {
      display: "inline-block",
      color: "#FC5A64",
    },
    featureName: {
      fontSize: "1.1rem",
      paddingRight: "16.66%",
      color: theme.palette.text.secondary,
    },
    headingText: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: "2rem",
      marginTop: "3rem",
    },
    subHeadingText: {
      fontSize: "1.6rem",
      fontWeight: "bold",
      color: theme.palette.text.blue2,
      marginBottom: "1rem",
    },
    uploadNowButton: {
      marginTop: "3rem",
      marginBottom: "3rem",
      height: "4rem",
      borderRadius: "1rem",
      fontSize: "1.2rem",
    },
    redButton: {
      backgroundColor: "#FC5A64",
      "&:hover": {
        backgroundColor: LightenDarkenColor("#FC5A64", -20),
      },
    },
    buttonContainer: {
      margin: "0 0.5rem",
    },
  })
)(AddSignatureDialogWithDialogBox);
