//@ts-nocheck
import React from "react";
import { withStyles, Grid, Typography, createStyles, Box } from "@material-ui/core";
import { logo, checkMarkPhaseTwo } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { Redirect } from "react-router-dom";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { authFlowStyles } from "./Login.web";
import TernaryCheck, { calculateTernary } from "../TernaryCheck.web";
import { Props } from "../FirmFinancialsNew.web";

export const Logo = () => {
  return (
    <Grid item xs={12} container alignItems="center" justifyContent="center">
      <img
        src={logo}
        alt="logo"
        style={{
          width: "65%",
          margin: "0 auto",
        }}
      />
    </Grid>
  );
};

export class CodeVerified extends React.Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      token: "",
      redirect: false,
    };
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
    this.id = setTimeout(() => this.setState({ redirect: true }), 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.id);
  }

  render() {
    const { classes } = this.props;
    return (
      <TernaryCheck
        condition={this.state.redirect}
        trueComponent={
          <Redirect
            to={{
              pathname: calculateTernary({
                condition: this.props.title === "Code Verified!",
                trueValue: "/setNewPassword",
                falseValue: "/",
              }),
              state: { email: this.props?.history?.location?.state?.email },
            }}
          />
        }
        falseComponent={
          <TernaryCheck
            condition={this.state.token}
            trueComponent={<Redirect to="/home" />}
            falseComponent={
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "100vh",
                }}
              >
                <Grid item md={3} xs={12} className={classes.loginBox}>
                  <Box py={3}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Logo />

                      <Grid item xs={10} container alignItems="center" justifyContent="center">
                        <img
                          src={checkMarkPhaseTwo}
                          alt="checkMark"
                          height="100px"
                          style={{
                            marginBottom: "2rem",
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TernaryCheck
                          condition={this.props.title === "Code Verified!"}
                          trueComponent={
                            <Typography variant="h1" align="center" className={classes.welcomeBack}>
                              Code Verified!
                            </Typography>
                          }
                          falseComponent={
                            <Typography variant="h1" align="center" className={classes.welcomeBack}>
                              Password Reset <br /> Successfully!
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            }
          />
        }
      />
    );
  }
}
const CodeVerifiedWithRouter = withRouter(CodeVerified);
const CodeVerifiedWithToast = withToast(CodeVerifiedWithRouter);
const CodeVerifiedWithLoader = withLoader(CodeVerifiedWithToast);
const CodeVerifiedWithAlertBox = withAlertBox(CodeVerifiedWithLoader);
const CodeVerifiedWithDialogBox = withDialog(CodeVerifiedWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    ...authFlowStyles(theme),
  })
)(CodeVerifiedWithDialogBox);
