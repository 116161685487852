//@ts-nocheck
import React from "react";
import SurveyLandingPageController, {
  Props,
} from "./SurveylandingPageController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  ThemeProvider,
  Box,
} from "@material-ui/core";
import { CheckIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import { createTheme } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';

export class SurveyLandingPage extends SurveyLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const params = new URLSearchParams(this.props.history.location.search);
    const state = this.props.history.location.state;
    const access_token = params.get("access_token");
    const email = params.get("email");
    const first_name = params.get("first_name");
    const last_name = params.get("last_name");
    const phone_number = params.get("phone_number");
    const account_id = params.get("account_id");
    const surveyLogId = params.get("log");
    const type = params.get("type");
    if (state?.survey_id) {
      this.setState({
        isEnable: true,
      });
    } else {
      this.setState(
        {
          accountId: account_id,
          paymentaccesstoken: access_token,
          clientDetail: {
            email,
            first_name,
            last_name,
            phone_number,
          },
          surveyLogId: surveyLogId,
          type: type,
        },
        () => {
          if (access_token || account_id) {
            this.getCPADetialbyAccessToken();
            this.isEcheckPaymentAvailable();
            if (type != "generalCFS") {
              this.updateSurveyLog();
            }
          }
        }
      );
    }
  }
  navigateToStepperPage = () => {
    this.props.history.push("/startSurveySteps", {
      state: {
        accountId: this.state.accountId,
        cpaDetail: this.state.cpaDetail,
        clientDetail: this.state.clientDetail,
        access_token: this.state.paymentaccesstoken,
        cpaSurveySetting: this.state.cpaSurveySetting,
        surveyLogId: this.state.surveyLogId,
        type: this.state.type,
        isEcheckPaymentAvailable: this.state.IseCheckPaymentAvailable,
      },
    });
  };
  render() {
    const { classes } = this.props;
    const {cpaDetail={theme:{}}} = this.state;
    const data = [
      { id: 1, heading: "Tell us what progress you want to make " },
      { id: 2, heading: "We'll show you the services and costs that match " },
      { id: 3, heading: "See your enrollment options or book a time to chat " },
    ];
    return (
      <ThemeProvider theme={createTheme(this.state.theme)}>
        <getImage />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh", overflow: "auto" }}
        >
          <Grid item md={6} xs={12} className={classes.loginBox}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} className={classes.logoContainer}>
                {cpaDetail?.theme?.logo ? (
                  <img
                    src={cpaDetail?.theme.logo}
                    alt="logo"
                    className={classes.logoImage}
                  />
                ) : (
                  <Typography>
                    {cpaDetail?.theme?.firm_name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{ color: localStorage.getItem("themePrimaryColor") }}
                  className={classes.heading}
                >
                  Get The Exact Help You Need
                </Typography>
              </Grid>
              <Box>
                {data.map((item) => {
                  return (
                    <Grid
                      item
                      container
                      alignItems="center"
                      key={item.id}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <span className={classes.checkedIcon}>
                        <Checkbox
                          name="checkedI"
                          color="primary"
                          checked={true}
                          
                        />
                      </span>
                      <Typography className={classes.subHeading}>
                        {item.heading}
                      </Typography>
                    </Grid>
                  );
                })}
              </Box>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  id="navigateToStepperPage"
                  variant="contained"
                  color="primary"
                  disabled={this.state.isEnable}
                  onClick={this.navigateToStepperPage}
                  className={classes.largeButton}
                >
                  Pick Your Priorities
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.bottomText}>
                  Takes less than three minutes
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
const SurveyLandingPageWithRouter = withRouter(SurveyLandingPage);
const SurveyLandingPageWithToast = withToast(SurveyLandingPageWithRouter);
const SurveyLandingPageWithLoader = withLoader(SurveyLandingPageWithToast);
const SurveyLandingPageWithAlertBox = withAlertBox(SurveyLandingPageWithLoader);
const SurveyLandingPageWithDialogBox = withDialog(
  SurveyLandingPageWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    logoContainer: {
      textAlign: "center",
      marginBottom: "1rem",
    },
    logoImage: {
      width: "20%",
      height: "auto",
    },
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "2.5rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.primary.main,
      fontWeight: 900,
      marginBottom: "2rem",
    },
    subHeading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.3rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "start",
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    checkedIcon: {
      borderRadius: "50%",
      minWidth: 20,
      height: 25,
      backgroundColor: "#d3d3d357",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
    },
    largeButton: {
      fontFamily: "Nunito Sans",
      fontSize: "1.5rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5rem",
      letterSpacing: "normal",
      cursor: "pointer",
      width: "50%",
      height: "54px",
      padding: "3px",
      marginTop: "2rem",
    },
    bottomText: {
      fontFamily: "Nunito Sans",
      fontSize: "0.9rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontWeight: 600,
      marginTop: "1rem",
    },
    loginBox: {
      backgroundColor: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      borderRadius: "1.125rem",
      padding: "2rem",
    },
  })
)(SurveyLandingPageWithDialogBox);
