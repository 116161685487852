//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  Box,
  withStyles,
  createStyles,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export class ClientsListMinimal extends React.Component {
  

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");

    this.setState(
      {
        token: token,
        accountId: accountId,
      }
    );
  }

  renderNoClient = (classes) => {
    return (
      <Grid item xs={12} className={classes.tableBody}>
        <Grid container>
          <Grid item xs={12} container justifyContent="center">
            <Typography
              align="center"
              color="textSecondary"
              className={classes.packageName}
              style={{
                fontWeight: "bold",
                maxWidth: "18.75rem",
                // fontSize: "0.875rem",
              }}
            >
              No Clients Available
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  renderName = (item) => {
     if(item?.attributes?.first_name && item?.attributes?.last_name){
       return `${item.attributes.first_name} ${item.attributes.last_name}`
     } else if(item?.attributes?.first_name){
      return `${item.attributes.first_name}`
     } else {
      return 'NA'
     }
  }
  renderPhone = (item) => {
    if(item.attributes.primary_phone && item.attributes.primary_email){
      return `${item.attributes.primary_phone} | ${item.attributes.primary_email}` 
    } else if(item.attributes.primary_phone){
      return `${item.attributes.primary_phone}`
    } else if(item.attributes.primary_email){
      return `${item.attributes.primary_email}`
    } else {
      return "NA"
    }
  }
  renderClientRow = (item,classes,theme) => {
    return (
      <Grid
        key={item.id}
        item
        xs={12}
        className={
          item.selected ? classes.selectedTableBody : classes.tableBody
        }
      >
        <Grid container>
          <Grid
            item
            xs={3}
            container
            alignItems="center"
            className={classes.gridColumnCheckbox}
          >
            <Checkbox
              classes={{
                root: classes.checkboxRoot,
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon color="primary" />}
              checked={item?.selected}
              onChange={(e) => {
                let newClientList = this.props.clientsList.map(
                  (mapClient: any) => {
                    if (mapClient.id === item.id) {
                      return {
                        ...mapClient,
                        selected: !mapClient.selected,
                      };
                    }
                    return mapClient;
                  }
                );
                this.props.setClientsList(newClientList);
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            container
            alignItems="center"
            className={classes.gridColumn1}
          >
            <Box
              className={classes.clientListColorBar}
              style={{
                backgroundColor:
                  item.attributes.profile_status.includes("Pending") ||
                  item.attributes.profile_status.includes("Declined")
                    ? "#ed1867"
                    : theme.palette.text.blue2,
              }}
            />
            <Typography
              display="inline"
              style={{
                fontWeight: "bold",
                fontSize: "1.0625rem",
                cursor: "pointer",
                maxWidth: "10rem",
              }}
              onClick={() => {
                StorageProvider.set("clientId", +item.id);
                StorageProvider.set("surveyId", "");
                StorageProvider.set("surveyType", "");
                StorageProvider.set("package_management_id", "");
                StorageProvider.set("roadMapId", null);

                this.props.history.push({
                  pathname: "/home/clientsList/details/overview",
                  state: { clientId: +item.id },
                });
              }}
              noWrap
            >
              {this.renderName(item)}
            </Typography>
            <Box className={classes.individualTypography}>
              {item?.attributes?.client_type}
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            container
            alignItems="center"
            className={classes.gridColumn2}
          >
            <Typography
              color="textSecondary"
              className={classes.packageName}
              style={{
                fontWeight: "bold",
                maxWidth: "18.75rem",
              }}
              noWrap
            >
               {this.renderPhone(item)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            container
            alignItems="center"
            className={classes.gridColumn3}
          >
            <Typography
              style={{
                fontSize: "0.875rem",
              }}
            >
              {item?.attributes?.enrolledPackage || "No package enrolled"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            container
            alignItems="center"
            className={classes.gridColumn4}
          >
            <Typography
              color="textSecondary"
              className={classes.packageName}
              style={{
                color:
                  item.attributes.profile_status.includes("Pending") ||
                  item.attributes.profile_status.includes("Declined")
                    ? "#ed1867"
                    : theme.palette.text.blue2,
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {item.attributes.profile_status}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        className={[classes.gridSpacing]}
      >
        <Grid item xs={12} className={[classes.gridSpacing]}>
          <Grid container>
            <Grid item xs={12} className={classes.tableHead}>
              <Grid container>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="center"
                  className={classes.gridColumnCheckbox}
                >
                  <Checkbox
                    classes={{
                      root: classes.checkboxRoot,
                    }}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon color="primary" />}
                    checked={this.props.headerCheckbox}
                    onChange={(event) => {
                      this.props.setHeaderCheckbox(event);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="center"
                  className={classes.gridColumn1}
                >
                  <Typography
                    color="textSecondary"
                    className={classes.headingCellText}
                  >
                    {`Client Name (${this.props.clientsList.length} Clients)`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="center"
                  className={classes.gridColumn2}
                >
                  <Typography
                    color="textSecondary"
                    className={classes.headingCellText}
                  >
                    Phone | Email
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="center"
                  className={classes.gridColumn3}
                >
                  <Typography
                    color="textSecondary"
                    className={classes.headingCellText}
                  >
                    Packages Enrolled
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  alignItems="center"
                  className={classes.gridColumn4}
                >
                  <Typography
                    color="textSecondary"
                    className={classes.headingCellText}
                  >
                    Status
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.clientListCard}>
              <Grid container>
                {this.props.clientsList && this.props.clientsList.length > 0
                  ? this.props.clientsList.map((item, clientListIndex) => {
                      return this.renderClientRow(item,classes,theme);
                    })
                  : this.renderNoClient(classes)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ClientsListMinimalWithRouter = withRouter(ClientsListMinimal);
const ClientsListMinimalAlertBox = withAlertBox(ClientsListMinimalWithRouter);
const ClientsListMinimalLoader = withLoader(ClientsListMinimalAlertBox);
const ClientsListMinimalToast = withToast(ClientsListMinimalLoader);
const ClientsListMinimalWithDialog = withDialog(ClientsListMinimalToast);

export default withStyles(
  (theme) =>
    createStyles({
      // root: {
      //   padding: theme.spacing(3, 3),
      //   maxHeight: "calc(100vh - 60px)",
      //   overflowY: "auto",
      // },
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: theme.shadows[25],
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      selectedClientRow: {
        border: `2px solid ${theme.palette.primary.main}`,
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 0),
        borderRadius: theme.spacing(1.5),
        backgroundColor: "#fff",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      },
      clientListColorBar: {
        position: "absolute",
        left: "-2px",
        top: "30%",
        width: "4px",
        height: "40%",
      },
      individualTypography: {
        display: "inline-block",
        backgroundColor: "#fff",
        border: `1px solid ${theme.palette.text.blue2}`,
        borderRadius: "20% / 50%",
        marginLeft: "0.5rem",
        padding: "0.25rem 0.4375rem 0.25rem 0.5625rem",
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: theme.palette.text.blue2,
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9rem",
        },
      },
      gridColumnCheckbox: {
        flexGrow: 0,
        maxWidth: "2%",
        flexBasis: "2%",
      },
      gridColumn1: {
        flexGrow: 0,
        maxWidth: "20%",
        flexBasis: "20%",
      },
      gridColumn2: {
        flexGrow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      gridColumn3: {
        flexGrow: 0,
        maxWidth: "36%",
        flexBasis: "36%",
      },
      gridColumn4: {
        flexGrow: 0,
        maxWidth: "17%",
        flexBasis: "17%",
      },
      tableHead: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        fontFamily: "Nunito Sans",
        backgroundColor: "#F3F3F3",
        border: `2px solid #F3F3F3`,
        padding: "1rem",
        borderRadius: "0.75rem",
        marginBottom: "0.5rem",
      },
      tableBody: {
        border: `2px solid ${theme.palette.common.white}`,
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: "0.75rem",
        fontSize: "1rem",
        color: theme.palette.text.secondary,
        fontFamily: "Nunito Sans",
        padding: "1rem",
        margin: "0.25rem 0",
        boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.09)",
      },
      selectedTableBody: {
        border: `2px solid ${theme.palette.primary.main}`,
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: "0.75rem",
        fontSize: "1rem",
        color: theme.palette.text.secondary,
        fontFamily: "Nunito Sans",
        padding: "1rem 1rem",
        margin: "0.25rem 0",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      },
      headingCellText: {
        fontSize: "1.125rem",
        fontWeight: "bold",
        color: theme.palette.text.blue2,
      },
      checkboxRoot: {
        padding: 0,
      },
      clientListCard: {
        maxHeight: "65vh",
        overflow: "auto",
        // padding: theme.spacing(2),
        "&::-webkit-scrollbar": {
          width: "2px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          // marginTop: "30px",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
    }),
  { withTheme: true }
)(withConfirmBox(ClientsListMinimalWithDialog));
