//@ts-nocheck
import React from "react";
import { withStyles, Grid, AppBar, Toolbar, createStyles, Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { logo } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RolePermission from "./RolePermission.web";
import CreateRole from "./CreateRole.web";
import { Props } from "../../LandingPage2/src/FirmFinancialsNew.web";
import ManageUsersCommonDialogWeb from "../../LandingPage2/src/Settings/ManageUsersCommonDialog.web";
import withDialogBox from "../../../components/src/withDialog.web";

const sideBarLinks = [
  {
    displayName: "Manage Users",
    route: "/adminDashboard/manageUsers",
  },
];

export class AdminDashboard extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  openLogoutDialogForAdmin = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want to sign out from Admin Portal?",
          yesButtonText: "Yes",
          noButtonText: "No",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialogWeb,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          await StorageProvider.clearStorage();
          localStorage.clear();
          window.location.replace("/")
        }
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container>
          <Hidden xsDown>
            <Grid item xs={2} className={classes.sideBar}>
              {this.state.currentUser && <IntercomStep />}
              <Grid container justifyContent="center" className={classes.sideBarContain}>
                <Grid item md={8}>
                  <img src={logo} alt="logo" style={{ width: "100%", marginBottom: "2rem" }} />
                </Grid>
                <Grid item xs={12} md={12} className={classes.sideBarLinksContainer}>
                  <Grid container justifyContent="flex-start">
                    {sideBarLinks.map((archive) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            position: "relative",
                          }}
                          key={archive.displayName}
                        >
                          <NavLink to={archive.route} className={classes.sideLink_AdminDashboard} activeClassName={classes.activeSideLink_AdminDashboard}>
                            {archive.displayName}
                          </NavLink>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          <Grid item md={10} xs={12}>
            <AppBar position="sticky" color="default" elevation={0} className={classes.appBar}>
              <Toolbar className={classes.toolbar}>
                <Grid container justifyContent="flex-end">
                  <Button
                    startIcon={<ExitToAppIcon />}
                    variant="contained"
                    color="primary"
                    onClick={this.openLogoutDialogForAdmin}
                  >
                    Logout
                  </Button>
                </Grid>
              </Toolbar>
            </AppBar>
            <Grid container justifyContent="center" className={classes.root_AdminDashboard}>
              <Grid item xs={12} md={12} className={classes.rootChildContainer_AdminDashboard}>
                <Switch>
                  <Route path="/adminDashboard/manageUsers/editUser" exact>
                    <CreateRole />
                  </Route>
                  <Route path="/adminDashboard/manageUsers">
                    <RolePermission />
                  </Route>

                  <Redirect exact from="/adminDashboard" to="/adminDashboard/manageUsers" />
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const AdminDashboardWithRouter = withRouter(AdminDashboard);
const AdminDashboardAlertBox = withAlertBox(AdminDashboardWithRouter);
const AdminDashboardLoader = withLoader(AdminDashboardAlertBox);
const AdminDashboardToast = withToast(AdminDashboardLoader);
const AdminDashboardWithDialog = withDialogBox(AdminDashboardToast);

export default withStyles((theme) =>
  createStyles({
    root_AdminDashboard: {
      height: "calc(100vh - 10vh)",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F4F4F6",
        borderRadius: theme.spacing(1),
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BBBEC6",
        borderRadius: theme.spacing(1),
      },
    },
    rootChildContainer_AdminDashboard: {
      margin: theme.spacing(3, 4, 4, 4),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2.2),
        margin: 0,
      },
    },
    appBar: {
      width: "100%",
      backgroundColor: " #ffffff",
      marginTop: "3vh",
    },
    toolbar: {
      flexWrap: "nowrap",
      margin: theme.spacing(0, 4, 0, 4),
      minHeight: 0,
      padding: "10px 0px",
    },
    sideBar: {
      position: "relative",
      backgroundColor: "#ffffff",
      margin: "3vh 0vh",
    },
    sideBarContain: {
      height: "94vh",
      position: "relative",
      borderRight: `2px solid #dfdee0`,
      alignContent: "flex-start",
    },
    sideLink_AdminDashboard: {
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: theme.palette.text.secondary,
      cursor: "pointer",
      textAlign: "left",
      display: "inline-block",
      textDecoration: "none",
      margin: "0.75rem 2.5rem",
    },
    activeSideLink_AdminDashboard: {
      color: "#ed1867",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
      margin: "0.75rem 2.5rem",
      fontWeight: "bold",
      display: "inline-block",
      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        backgroundColor: "#ed1867",
        height: "100%",
        width: "4px",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
  })
)(withConfirmBox(AdminDashboardWithDialog));
