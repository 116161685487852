//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string,
  formValues: object;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendVerificationCodeApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      formValues: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.sendVerificationCodeApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
          this.props.showToast({
            type: "success",
            message: "Verification Code Sent Successfully."
          });
          this.props.history.push({
            pathname: "/forgotPasswordOtp",
            state: {
              email: this.state.formValues.email,
            },
          });
        } else {
          this.props.showAlert({
            title: "Error",
            message: responseJson?.message,
          });
        }
      } 
  } else if (getName(MessageEnum.AlertMessage) === message.id) {
    const AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );
    this.props.showAlert({
      title: "System Error",
      message: AlertBodyMessage,
    });
  }
}
  
  sendVerificationCode = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendVerificationCodeApiCallId = requestMessage.messageId;

    const httpBody = {
      "data": {
        "attributes": {
            "primary_email": values.email,
          }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_login/send_verification_code'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
